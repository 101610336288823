import { Box, Grid } from "@mui/material";
import CustomPagination from "../../../Atoms/Pagination/Pagination";
import React, { useEffect, useState } from "react";
import RateFreelancersModel from "../Models/RateFreelancers";
import {
  completeJopOffer,
  deleteJopOffer,
} from "../../../../Contexts/APIs/Job-Offers/JobOffers";
import CompleteJopOfferModel from "../Models/CompleteJopOffer";
import DeleteOfferModal from "../Models/DeleteOffer.jsx";

import JobOfferlist from "./List";
import { useTransition } from "react";
import { withTranslation } from "react-i18next";

function ListJobOffers({
  t,
  count,
  offers,
  joboffersPrivilages,
  page,
  handlePageChange,
  getJobOffers,
  filterObj,
  jobRequestsPrivilages,
  setOffers
}) {
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [openRateModel, setOpenRateModel] = useState(false);
  const [openCompleteModel, setOpenCompleteModel] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [selectedData, setSelectedData] = useState(false);
  const [offerData, setOfferData] = useState([]);

  const handelCompleteOffer = async () => {
    setLoadingStatus(true);
    const { res, err } = await completeJopOffer(
      selectedData?.offer?.id,
      selectedData?.offer
    );
    if (res) {
      let newData = await offers;
      newData[selectedData?.index].status = await "completed";
      await setOfferData(newData);
      await setOpenCompleteModel(false);
    }
    setLoadingStatus(false);
  };
  /*const pendingOptions = (userType !== 'descendant_user') ||
        (userType === 'descendant_user' && joboffersPrivilages?.can_delete) ? [
        { title: 'Delete job offer', action: () => handelDeleteJopOffer() }
    ] : []

    const completedOptions = (selectedData.offer && selectedData?.offer?.accepted_job_request?.dive_center_review === null && (userType !== 'descendant_user') ||
        (userType === 'descendant_user' && joboffersPrivilages?.can_update) )? [
        { title: 'Rate freelancer', action: (review) => setOpenRateModel(true) }
    ] : []    

    const scannedOptions = (userType !== 'descendant_user') ||
        (userType === 'descendant_user' && joboffersPrivilages?.can_update) ? [
        { title: 'Mark as completed', action: () => setOpenCompleteModel(true) }
    ] : []*/

  const handelDeleteJopOffer = async () => {
    setLoadingStatus(true);
    const { res, err } = await deleteJopOffer(
      selectedData?.offer?.id,
      selectedData?.offer
    );
    if (res) {
      let newData = await offers;
      newData[selectedData?.index].status = "deleted";

      await setOfferData(newData);
      setOpenDeleteModel(false);
    }
    setLoadingStatus(false);
  };

  useEffect(() => {
    if (offers) setOfferData(offers);
  }, [offers]);
  return (
    <Grid container>
      <Grid container className="table-header mb-20px">
        <Grid md={1} px={2}>
          ID.
        </Grid>
        <Grid md={1.2}>{t("joboffer.jobTitle")}</Grid>
        <Grid sm={1.5}>{t("joboffer.freelancerTitle")}</Grid>
        <Grid sm={1.2}>{t("joboffer.activityType")}</Grid>
        <Grid sm={1.2}>{t("joboffer.fare")}</Grid>
        <Grid sm={1.2}>{t("joboffer.earlyNotification")}</Grid>
        <Grid sm={1.2}>{t("joboffer.highPriority")}</Grid>
        <Grid sm={1}>{t("reservations.status")}</Grid>
        <Grid sm={1.5}>{t("joboffer.applicantsHired")}</Grid>
        <Grid sm={1} paddingLeft={"20px"}>
        {t("joboffer.action")}
        </Grid>
      </Grid>
      {offers?.length === 0 && (
        <Grid container display="block" textAlign="center">
          There are no records
        </Grid>
      )}
      {offerData?.map((offer, index) => {
        return (
          <Box
            key={index}
            width="100%"
            onClick={() => setSelectedData({ offer: offer, index: index })}
          >
            <JobOfferlist
              offer={offer}
              loadingStatus={loadingStatus}
              selectedData={selectedData}
              // menuOptions={menuOptions}
              joboffersPrivilages={joboffersPrivilages}
              setOpenRateModel={setOpenRateModel}
              setOpenCompleteModel={setOpenCompleteModel}
              jobRequestsPrivilages={jobRequestsPrivilages}
              setOpenDeleteModel={setOpenDeleteModel}
            />
          </Box>
        );
      })}
      {count > 10 && (
        <Grid container justifyContent="center" mb={7.5} mt={5}>
          <CustomPagination
            count={Math.ceil(count / 10)}
            size="large"
            shape="rounded"
            page={page}
            handleChange={handlePageChange}
            className="pagination"
          />
        </Grid>
      )}
      {openRateModel && (
        <RateFreelancersModel
          data={selectedData?.offer?.id}
          open={openRateModel}
          setOpen={setOpenRateModel}
          getJobOffers={getJobOffers}
          filterObj={filterObj}
          setOffers={setOffers}
          offers={offers}
        />
      )}
      {openCompleteModel && (
        <CompleteJopOfferModel
          handelCompleteOffer={handelCompleteOffer}
          open={openCompleteModel}
          setOpen={setOpenCompleteModel}
        />
      )}
      {openDeleteModel && (
        <DeleteOfferModal
          handelDeleteRequest={handelDeleteJopOffer}
          open={openDeleteModel}
          setOpen={setOpenDeleteModel}
        />
      )}
    </Grid>
  );
}

export default withTranslation()(ListJobOffers);
