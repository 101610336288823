import { Grid, Typography, Stack } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useState, useEffect } from "react";
import ImagesPanel from "../../General/Panels/ImagesPanel.jsx";
import DescriptionPanel from "../../General/Panels/DescriptionPanel.jsx";
import InformationPanel from "../../General/Panels/InformationPanel.jsx";
import "./InHouseActivityDetailsPanel.css";
import { withTranslation } from "react-i18next";
import moment from "moment";

const InHouseActivityDetailsPanel = (props) => {
  const { t, activityDetails } = props;
  const lang = localStorage.getItem("lang");

  const [features, setFeatures] = useState([]);
  const [daysStr, setdaysStr] = useState([]);

  useEffect(() => {
    const array = [];
    const languageStr = activityDetails?.inhouse_activity_languages?.map(
      (language, ndx) => {
        return (
          language.language.name +
          `${
            ndx < activityDetails?.inhouse_activity_languages?.length - 1
              ? ", "
              : ""
          }`
        );
      }
    );
    if (activityDetails.scheduling_option === "some_weekdays") {
      setdaysStr(
        activityDetails?.scheduled_days?.map((day, ndx) => {
          return (
            day.weekday +
            `${ndx < activityDetails?.scheduled_days?.length - 1 ? ", " : ""}`
          );
        })
      );
    }
    array.push(
      // {
      //   item: `${t("salesmen.instantConfirmation")}`,
      //   value:
      //     activityDetails?.confirmation_option === "instant_confirmation"
      //       ? true
      //       : false,
      // },
      // {
      //   item: `${t("activity.freeCancelation")}`,
      //   value:
      //     activityDetails?.owner?.cancellation_duration == 0 ? true : false,
      // },

      {
        item: languageStr?.length > 0 ? languageStr : "Languages",
        value: languageStr?.length > 0 ? true : false,
      }
    );

    setFeatures(array);
  }, [activityDetails]);

  return (
    <Grid container>
      <Grid
        columnSpacing={2}
        container
        lg={11}
        margin="auto"
        paddingTop="20px"
        width="100%"
      >
        <Grid columnSpacing={2} item md={8} width="100%" px="16px">
          <ImagesPanel
            title={t("activity.activityImages")}
            DetailsImages={activityDetails}
            mediaType="default"
          />
          {activityDetails?.description && (
            <DescriptionPanel
              title={t("help.description")}
              description={activityDetails?.description}
            />
          )}
        </Grid>
        <Grid item md={4} width="100%" px="16px">
          <InformationPanel
            title={t("activity.information")}
            features={features}
          />

          <Grid container className="details-panel">
            <Typography className="mb-10px" component="h1">
              {t("activity.dateAndTime")}
            </Typography>
            <Stack
              flexDirection="row"
              gap={1}
              my={1}
              className="details-info"
              alignItems="flex-start"
              width="100%"
            >
              <CheckIcon
                fontSize="1.1rem"
                style={{
                  color: "#f6b119",
                  marginTop: "4px",
                  fontSize: "15px",
                }}
              />
              <Grid>
                <Typography className="details-text">
                  {activityDetails?.scheduling_option === "everyday"
                    ? `${t("activity.Everyday")}`
                    : activityDetails?.scheduling_option === "some_weekdays"
                    ? `${t("activity.repeatWeekly")}`
                    : activityDetails?.scheduling_option === "some_dates"
                    ? `${t("activity.someDates")}`
                    : null}
                </Typography>
              </Grid>
            </Stack>
            {/* <Stack flexDirection="row" gap={1} width={"100%"}>
                <Typography color="#aaa" textTransform="capitalize" className="details-text">
                  {daysStr}
                </Typography>
            </Stack> */}
            {activityDetails.scheduling_option === "everyday" ? (
              <>
                <Stack flexDirection="row" gap={1} mb={1.5} width={"100%"}>
                  <Typography color="#333132" className="details-text">
                    {`${t("activity.startDate")} : `}
                  </Typography>
                  <Typography color="#333132" className="details-text">
                    {moment(new Date(activityDetails?.start_date)).format(
                      "DD/MM/YYYY"
                    )}
                  </Typography>
                </Stack>
                <Stack flexDirection="row" gap={1} mb={1.5} width={"100%"}>
                  <Typography color="#333132" className="details-text">
                    {`${t("activity.endDate")} : `}
                  </Typography>
                  <Typography color="#333132" className="details-text">
                    {moment(new Date(activityDetails?.end_date)).format(
                      "DD/MM/YYYY"
                    )}
                  </Typography>
                </Stack>
                <Stack flexDirection="row" gap={1} mb={1.5} width={"100%"}>
                  <Typography color="#333132" className="details-text">
                    {`${t("activity.startTime")} : `}
                  </Typography>
                  <Typography color="#333132" className="details-text">
                    {activityDetails?.scheduled_days[0]?.start_time
                      ? `${activityDetails?.scheduled_days[0]?.start_time}`
                      : "N/A"}
                  </Typography>
                </Stack>
              </>
            ) : activityDetails.scheduling_option === "some_weekdays" ? (
              <>
                <Stack flexDirection="row" gap={1} width={"100%"}>
                  <Typography
                    color="#aaa"
                    textTransform="capitalize"
                    className="details-text"
                  >
                    {`(${daysStr})`}
                  </Typography>
                </Stack>
                <Grid container mb={1}>
                  <Grid item xs={12} md={6}>
                    <Typography color="#333132" className="details-text">
                      {`${t("activity.startDate")} : ${moment(
                        new Date(activityDetails?.start_date)
                      ).format("DD/MM/YYYY")}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography color="#333132" className="details-text">
                      {`${t("activity.endDate")} : ${moment(
                        new Date(activityDetails?.end_date)
                      ).format("DD/MM/YYYY")}`}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ) : activityDetails?.scheduling_option === "some_dates" ? (
              activityDetails?.activity_groups?.map((day, index) => {
                return (
                  <Grid container key={index} mb={0.5}>
                    <Grid item xs={6}>
                      <Typography color="#333132" className="details-text">
                        {`${t("activity.date")} : ${day?.start_date}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography color="#333132" className="details-text">
                        {`${t("activity.startTime")} : ${day?.start_time}`}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })
            ) : null}
          </Grid>
          <Grid container className="details-panel">
            <Typography className="mb-10px" component="h1">
              {t("activity.requirement")}
            </Typography>
            <Stack
              flexDirection="column"
              alignItems="flex-start"
              width="100%"
              gap={2}
            >
              <Stack container flexDirection="row" width="100%">
                <Typography width="50%">
                  {t("activity.organizationName")}
                </Typography>
                <Typography width="50%">
                  {activityDetails?.completion_certificate?.organization
                    ?.name || "-"}
                </Typography>
              </Stack>
              <Stack container flexDirection="row" width="100%" gap={4}>
                <Typography width="50%">
                  {t("activity.certificationName")}
                </Typography>

                <Typography width="50%">
                  {activityDetails?.completion_certificate?.name || "-"}
                </Typography>
              </Stack>
              <Stack container flexDirection="row" width="100%" gap={4}>
                <Typography width="50%">
                  {t("activity.minimumDives")}
                </Typography>

                <Typography width="50%">
                  {activityDetails?.no_of_dives || "-"}
                </Typography>
              </Stack>
              <Stack container flexDirection="row" width="100%" gap={4}>
                <Typography width="50%">{t("activity.minimumAge")}</Typography>

                <Typography width="50%">
                  {activityDetails?.min_age || "-"}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(InHouseActivityDetailsPanel);
