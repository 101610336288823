import { Grid, Typography } from "@mui/material";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import { icons } from "../../../../Assets/AssetHelper.js";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomPagination from "../../../Atoms/Pagination/Pagination.js";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import DeleteModal from "../../../Molecules/General/Models/DeleteModal.jsx";

const AssignedActivitiesList = (props) => {
  const {
    assignedActivitiesList,
    handelUnassignActivity,
    count,
    page,
    handlePageChange,
    handleGetAssignedActivity,
    setModalType,
    salesmanId,
    salesmanName,
    setSelectedAssignedActivityId,
    setOpenAssignActivityModal,
    salesmenPrivilages,
    t,
  } = props;
  const Navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  const userType = localStorage.getItem("userType");
  const selectedLang = localStorage.getItem("lang");

  const [SelectedActivity, setSelectedActivity] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);

  const handleNavigate = (activity_item) => {
    Navigate(
      `/salesmen/salesmen-list/${salesmanId}/assigned-activities/activity-groups/${activity_item?.activity?.id}?page=${pageNumber}`,
      {
        state: {
          assignedActivityName: activity_item?.activity?.name,
          salesmanId: salesmanId,
          salesmanName: salesmanName,
        },
      }
    );
  };

  return (
    <>
      <Grid container>
        <Grid container className="table-header mb-20px">
          <Grid item md={1.5} textAlign="center">
            {t("users.no")}
          </Grid>
          <Grid item md={1.5}>
            {t("activity.name")}
          </Grid>
          <Grid item sm={2}>
            {t("activity.branch")}
          </Grid>
          <Grid item sm={2}>
            {t("activity.price")}
          </Grid>
          <Grid item sm={2}>
            {t("salesmen.maxDiscountAllowed")}
          </Grid>
          <Grid item sm={3} style={{ padding: "0 20px" }}>
            {t("activity.action")}
          </Grid>
        </Grid>

        {assignedActivitiesList?.length === 0 ? (
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <img
              src={icons.InHouseActivity}
              style={{ margin: "80px 0px 20px", width: "70px", height: "72px" }}
              alt="no in house activities img"
            />
            <Typography
              sx={{ fontWeight: 700, fontSize: "22px", color: "#F6B119" }}
            >
              {t("salesmen.noActivitiesAssignedYet")}
            </Typography>
          </Grid>
        ) : null}

        <Grid container className="table-items-container">
          {assignedActivitiesList?.map((activityItem, index) => (
            <Grid
              container
              item
              lg={12}
              className="activity-item mb-20px"
              key={index}
              onClick={() => {
                setSelectedActivity({ activity: activityItem, index: index });
                handleGetAssignedActivity(activityItem.id);
              }}
              sx={{ cursor: "pointer" }}
            >
              <Grid item md={1.5} textAlign="center">
                {activityItem?.id}
              </Grid>

              <Grid
                item
                md={1.5}
                sx={{ fontWeight: "700" }}
                onClick={() => handleNavigate(activityItem)}
              >
                {activityItem?.activity?.name}
              </Grid>

              <Grid item sm={2} onClick={() => handleNavigate(activityItem)}>
                "{activityItem?.activity?.branch?.name}"
              </Grid>

              <Grid item sm={2} onClick={() => handleNavigate(activityItem)}>
                {activityItem?.activity?.price}{" "}
                {activityItem?.activity?.currency?.lookup_key}
              </Grid>

              <Grid item sm={2} onClick={() => handleNavigate(activityItem)}>
                {activityItem?.max_discount_percentage_allowed} %
              </Grid>

              <Grid container item sm={3}>
                {((userType === "descendant_user" &&
                  salesmenPrivilages?.can_update) ||
                  userType !== "descendant_user") && (
                  <PrimaryButton
                    btnType="icon"
                    startIcon={
                      <img width="35" src={icons.EditIcon} alt="edit" />
                    }
                    click={() => {
                      setModalType("edit");
                      setTimeout(() => {
                        setSelectedAssignedActivityId(activityItem?.id);
                        setOpenAssignActivityModal(true);
                      }, 500);
                    }}
                  />
                )}
                {((userType === "descendant_user" &&
                  salesmenPrivilages?.can_delete) ||
                  userType !== "descendant_user") && (
                  <PrimaryButton
                    btnType="icon"
                    startIcon={
                      <img width="35" src={icons.DeleteIcon} alt="delete" />
                    }
                    click={() => setOpenDeleteModel(true)}
                  />
                )}
              </Grid>
            </Grid>
          ))}
          {count > 10 && (
            <Grid container justifyContent="center" mb={7.5} mt={5}>
              <CustomPagination
                count={Math.ceil(count / 10)}
                size="large"
                shape="rounded"
                page={page}
                handleChange={handlePageChange}
                className="pagination"
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      {openDeleteModel && (
        <DeleteModal
          open={openDeleteModel}
          setOpen={setOpenDeleteModel}
          handelDeleteRequest={() => {
            handelUnassignActivity(
              salesmanId,
              SelectedActivity?.activity?.activity?.id
            );
            setOpenDeleteModel(false);
          }}
          isUnassignActivity={true}
          text={[
            `${t("salesmen.unassign")} ${
              SelectedActivity?.activity?.activity?.name
            }`,
            `${t("salesmen.unassignMsg1")} ${
              SelectedActivity?.activity?.activity?.name
            } ${t("salesmen.unassignMsg2")} ${salesmanName}`,
          ]}
          buttonText={[t("profile.cancel"), t("salesmen.unassign")]}
        />
      )}
    </>
  );
};

export default withTranslation()(AssignedActivitiesList);
