import React from "react";
import AssignedActivitiesHeader from "../../../../Components/Organisms/Salesmen/AssignedActivities/AssignedActivitiesHeader";
import AssignedActivitiesList from "../../../../Components/Organisms/Salesmen/AssignedActivities/AssignedActivitiesList";
import CustomSeparator from "../../../../Components/Atoms/BreadCrumb";
import { Link, useSearchParams } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import { withTranslation } from "react-i18next";
import "./AssignedActivities.css";
import AssignActivityModal from "../../../../Components/Molecules/General/Models/AssignActivityModal";

const AssignedActivitiesTemplate = (props) => {
  const {
    t,
    assignedActivitiesList,
    activitiesNo,
    count,
    searchKeywords,
    page,
    handlePageChange,
    getKeywords,
    handelUnassignActivity,
    branchesList,
    inHouseActivitiesList,
    formik,
    initialValuesObject,
    modalType,
    setModalType,
    salesmanId,
    salesmanName,
    setSelectedAssignedActivityId,
    openAssignActivityModal,
    setOpenAssignActivityModal,
    handleGetAssignedActivity,
    handleGetInHouseActivity,
    salesmenPrivilages,
    getBranches,
    getInHouseActivities,
    branchesCount,
    inHouseActivitiesCount,
    profileData
  } = props;
  let [searchParams, setSearchParams] = useSearchParams();
  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");

  return (
    <>
      <Stack className="assigned-activities">
        <CustomSeparator
          breadcrumbs={[
            <Link
              style={{ color: "#bebebe", textDecoration: "none" }}
              underline="hover"
              key="1"
              color="inherit"
              to={`/salesmen?page=${pageNumber}&salesmanName=${salesmanName}`}
            >
              {t("salesmen.salesmen")}
            </Link>,
            <Link
              style={{ color: "#bebebe", textDecoration: "none" }}
              underline="hover"
              key="2"
              color="inherit"
              to={`/salesmen/salesmen-list?page=${pageNumber}`}
            >
              {t("salesmen.salesmenList")}
            </Link>,
            // <Link
            //     style={{ color: '#bebebe', textDecoration: 'none' }}
            //     underline="hover"
            //     key="3"
            //     color="inherit"
            //     to={`/salesmen/salesmen-list/${salesmanId}`}
            // >
            //     {salesmanName}
            // </Link>,
            <Typography
              key="3"
              style={{ color: "#bebebe", textDecoration: "none" }}
            >
              {salesmanName}
            </Typography>,
            <Typography
              key="4"
              style={{ color: "#bebebe", textDecoration: "none" }}
            >
              {t("salesmen.assignedActivities")}
            </Typography>,
          ]}
        />
        <AssignedActivitiesHeader
          activitiesNo={activitiesNo}
          searchKeywords={searchKeywords}
          getKeywords={getKeywords}
          setModalType={setModalType}
          setOpenAssignActivityModal={setOpenAssignActivityModal}
          salesmenPrivilages={salesmenPrivilages}
        />
        <AssignedActivitiesList
          assignedActivitiesList={assignedActivitiesList}
          handelUnassignActivity={handelUnassignActivity}
          count={count}
          page={page}
          handlePageChange={handlePageChange}
          handleGetAssignedActivity={handleGetAssignedActivity}
          setModalType={setModalType}
          salesmanId={salesmanId}
          salesmanName={salesmanName}
          setSelectedAssignedActivityId={setSelectedAssignedActivityId}
          setOpenAssignActivityModal={setOpenAssignActivityModal}
          handleGetInHouseActivity={handleGetInHouseActivity}
          salesmenPrivilages={salesmenPrivilages}
        />
      </Stack>

      {openAssignActivityModal && (
        <AssignActivityModal
          open={openAssignActivityModal}
          setOpen={setOpenAssignActivityModal}
          formik={formik}
          initialValuesObject={initialValuesObject}
          branchesList={branchesList}
          branchesCount={branchesCount}
          inHouseActivitiesCount={inHouseActivitiesCount}
          inHouseActivitiesList={inHouseActivitiesList}
          getInHouseActivities={getInHouseActivities}
          modalType={modalType}
          handleGetInHouseActivity={handleGetInHouseActivity}
          getBranches={getBranches}
          profileData={profileData}
        />
      )}
    </>
  );
};
export default withTranslation()(AssignedActivitiesTemplate);
