import React from 'react'
import ReportAppIssuseTemplate from '../../../../Templates/Main/Help/ReportAppIssuse/ReportAppIssuse.template';

export const userDataReport = React.createContext();
function ReportAppIssusePage({profileData}) {
  return (
    <userDataReport.Provider value={ profileData?.email }>
      <ReportAppIssuseTemplate />
    </userDataReport.Provider>
  )
}

export default ReportAppIssusePage