import React from 'react'
import PhoneInput from 'react-phone-input-2';
import './ReactPhoneInput2.css';

const ReactPhoneInput2 = ({ formik, id, name, formatType, phoneValue, codeValue, 
    phoneProperty, codeProperty, customContainerClass, customClass, placeholder,  
    specialLabel, disableDropdown, enableSearch, showBorder, defaultCountryCode }) => {
    return (
        <>
            <PhoneInput
                inputProps={{
                    name: name ? name : null,
                    required: true,
                    // autoFocus: true,
                    id: id ? id : null
                }}
                inputStyle={{
                    color: 'black',
                    border: showBorder != true && 'none',
                    width: '100%',
                    height: '57.88px',
                    padding: '7px 15px',
                    direction: 'ltr',
                    fontSize: formatType === 'read' ? '20px' : '18px',
                    cursor: 'text'
                }}
                containerClass={`${customContainerClass} ${disableDropdown ? 'hide-flag' : ''}
                    ${(formik?.values[phoneProperty]?.toString()?.length == 0 || phoneValue == '') ? 'hide-special-label' : ''}
                `}
                inputClass={`phone-input-class ${customClass}`}
                specialLabel={formatType !== 'read' && specialLabel}                
                placeholder={formatType !== 'read' && placeholder}
                defaultMask={ 
                    parseInt(formik?.values[codeProperty]) != 20 || codeValue != 20 
                    ? '... ... ....' : ''               
                }
                // country={defaultCountryCode}
                value={(formatType === 'read' && phoneValue && codeValue == '') ? `+20${phoneValue}`
                    : (formatType === 'read' && phoneValue && codeValue) ? `${codeValue}${phoneValue}`
                    : `${formik?.values[codeProperty]}${formik?.values[phoneProperty]}` }
                onChange={(value, data) => {
                    formik.setValues({
                        ...formik.values,
                        [phoneProperty]: value.slice(data?.dialCode?.length),
                        [codeProperty]: data.dialCode
                    });
                }}
                disabled={formatType === 'read' ? true : false}
                disableDropdown={disableDropdown}
                enableSearch={enableSearch}
            />

            {
                (formik?.errors && formik?.errors[phoneProperty]) &&
                <p
                    style={{
                        fontSize: "0.75rem",
                        color: "#d32f2f",
                        textAlign: "left",
                        margin: "3px 14px 0px"
                    }}
                >
                    {formik?.errors[phoneProperty]}
                </p>
            }
        </>
    )
}

export default ReactPhoneInput2