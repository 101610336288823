import { Divider, Grid } from "@mui/material";
import JobOfferDetailsBody from "../../Molecules/JobOffers/JobOfferDetailsBody";

import JobOfferDetailsHeader from "../../Molecules/JobOffers/JobOfferDetailsHeader";

const JobOfferDetails = ({ jobOfferData, language }) => {

    return (
        <Grid container sx={{
            boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.1)',
            backgroundColor: 'var(--white)',
            borderRadius: '10px'
        }}>
            <JobOfferDetailsHeader
                jobOfferData={jobOfferData}
                language={language}
            />
            <Divider sx={{ width: '100%' }} />
            <JobOfferDetailsBody
                jobOfferData={jobOfferData}
                language={language}
            />
        </Grid>
    );
}

export default JobOfferDetails;