import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.min.css";
import "./App.css";
// import { LogoutAPI } from './Contexts/APIs/Auth/Logout'
import RoutesPage from "./RoutesPage.js";
import moment from "moment";
import dayjs from 'dayjs';
import 'moment/locale/de'; 
import 'moment/locale/fr';
import 'dayjs/locale/de';
import 'dayjs/locale/fr';

function App(props) {
  const { t } = useTranslation();
  // const [value, setvalue] = useState("en");
  // const [localizationAr, setlocalizationAr] = useState(true);

  const lang = localStorage.getItem("lang");
  window.maxFileSize = 1024 * 1024;
  window.fileSize = "* Maximum Upload Size is 1 MB Per File";

  useEffect(() => {
    if (lang) {
      // setvalue(lang);
      props.i18n.changeLanguage(lang);
      moment.locale(lang);
      dayjs.locale(lang)
    } else {
      localStorage.setItem("lang", "en");
      props.i18n.changeLanguage("en");
    }
    // if (lang) {
    //   if (lang === "ar") {
    //     setlocalizationAr(true);
    //     setvalue("ar");
    //     props.i18n.changeLanguage("ar");
    //   } else {
    //     setlocalizationAr(false);
    //     setvalue(lang);
    //     props.i18n.changeLanguage(lang);
    //   }
    // } else {
    //   localStorage.setItem("lang", "en");
    //   props.i18n.changeLanguage("en");
    // }
  }, [lang]);

  return (
    <div
      className="App"
    // className={localizationAr ? "ar" : "App"}
    >
      {/* <LocalizationProvider localizationAr={localizationAr}> */}
      {/* <RoutesPage t={t} profileData={data} /> */}
      
      <RoutesPage t={t} />
      {/* </LocalizationProvider> */}
    </div>
  );
}

export default App;

// async function handlePermission() {
//   try {
//     const result = await navigator.permissions.query({
//       name: "notifications",
//     });
//     return permissionQuery(result);
//   } catch (er) {
//     return er;
//   }
// }

// function permissionQuery(result) {
//   console.debug({ result });
//   var newPrompt;

//   if (result.state == "granted") {
//     // notifications allowed, go wild
//     // Firebase.getFCMToken();
//     if (localStorage.getItem("Token")) {
//       setTimeout(() => {
//         SetDeviceFCMAPI({
//           device: {
//             fcm_token: localStorage.getItem("fcm_token"),
//           },
//         });
//       }, 5000);
//     }
//   }
//   // else if (result.state == 'prompt') {
//   //   // we can ask the user
//   //   newPrompt = Notification.requestPermission();
//   //   console.log(result.state)
//   // }
//   else if (result.state == "denied") {
//     // notifications were disabled
//     // localStorage.setItem("fcm_token", window.navigator.userAgent)
//   }

//   result.onchange = () => console.debug({ updatedPermission: result });

//   return newPrompt || result;
// }

// const Logout = async () => {
//   const { res, err } = await LogoutAPI()
//   if (res) {
//     localStorage.clear()
//     window.location.reload()
//   }
//   if (err) toast.error(err)
// }

// const userType = localStorage.getItem("userType");
// const userId = localStorage.getItem("userId");
// const [data, setData] = useState({});

// const DiveCenterDetails = async () => {
//   const { res, err } = await getDiveCenterDetails(userId);
//   if (res) {
//     setData(res?.data.user);
//   }
// };

// useEffect(() => {
//   handlePermission();
// }, [Notification.permission]);

// useEffect(() => {
//   const checkCookie = () => {
//     const FAT = Cookies.get("firebase_auth_token");
//     if (!FAT && localStorage.getItem("accessToken")) {
//       handleNewFirebaseAuthToken();
//     }
//   };

//   checkCookie();
//   const interval = setInterval(() => {
//     checkCookie();
//   }, 60000);
//   return () => clearInterval(interval);
// }, []);

// useEffect(() => {
//   // Firebase.getFCMToken();
//   const reg = async () => {
//     await Firebase.SignInFirebase();
//   };
//   if (localStorage.getItem("Token")){
//     setTimeout(() => {
//       reg();
//     }, 5000);
//   }
// }, []);
