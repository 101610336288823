import React from "react";
import AddInHouseActivityForm from "../../../../Components/Organisms/Salesmen/InHouseActivities/AddInHouseActivityForm";
import { Grid } from "@mui/material";

const AddInHouseActivityTemplate = (props) => {
  const {
    t,
    uploadActivityImages,
    supportedLanguages,
    branchesList,
    currencies,
    getBranchOrganizations,
    getOrganization,
    organizationsList,
    getCertificates,
    certificatesList,
    languagesList,
    activityImages,
    removeActivityImage,
    type,
    activityDetails,
    ActivitiesTypes,
    getLanguagesAPI,
    getCurrencies,
    getBranches,
    languagesCount,
    setCertificatesList,
    certificatesCount,
    organizationsCount,
    profileData
  } = props;

  return (
    <Grid container>
      <Grid container>
        <AddInHouseActivityForm
          getLanguagesAPI={getLanguagesAPI}
          languagesCount={languagesCount}
          ActivitiesTypes={ActivitiesTypes}
          uploadActivityImages={uploadActivityImages}
          supportedLanguages={supportedLanguages}
          branchesList={branchesList}
          getBranchOrganizations={getBranchOrganizations}
          getOrganization={getOrganization}
          currencies={currencies}
          getCurrencies={getCurrencies}
          organizationsList={organizationsList}
          getCertificates={getCertificates}
          certificatesList={certificatesList}
          languagesList={languagesList}
          activityImages={activityImages}
          removeActivityImage={removeActivityImage}
          type={type}
          activityDetails={activityDetails}
          getBranches={getBranches}
          setCertificatesList={setCertificatesList}
          certificatesCount={certificatesCount}
          organizationsCount={organizationsCount}
          profileData={profileData}
        />
      </Grid>
    </Grid>
  );
};

export default AddInHouseActivityTemplate;
