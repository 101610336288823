import { axiosFn, handleError } from "../index";

export async function confirmPassword(dive_center_id, password) {
    return await axiosFn("patch", `/dive_centers/${dive_center_id}/check_password?password=${password}`)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}