import * as yup from "yup";
import { getBranchTranslations } from "../../../Contexts/APIs/Lookups/Help";
import { useEffect, useState } from "react";
export const ActivityValidationSchema = (mapType) => {
  const activity = getBranchTranslations("activity");
  const [validationObject, setValidatationObject] = useState(false);
  const mapTypeFirstValidation = yup.object({
    branch_name: yup.string().required(activity.branchNameReq),
    no_dives: yup.number().required(activity.noDivesReq),
    currency: yup.string().required(activity.currencyReq),
    price: yup
      .number()
      .positive(activity.priceNotNegative)
      .required(activity.priceReq),
    number_of_tickets: yup.number().required(activity.noTicketsReq),
    days: yup.number().required(activity.daysReq),
    hours: yup.number().required(activity.HoursDayRequired),
    country_id:  yup.string().required(activity.countryReq),
    city_id: yup.string().required(activity.provinceReq),
    area_id: yup.string().required(activity.cityReq),
    confirmation_state: yup.string().required(activity.confirmationStateReq),
    max_divers: yup.number().required(activity.maxDiversReq),
    cancelation_lead_time: yup.number().required(activity.cancelationTimeReq),
    last_time_booking: yup.number().required(activity.lastTimeBookingReq),
    time_unit: yup.string().required(activity.required).default("hour"),
  });
  const mapTypeSecondValidation = yup.object({
    branch_name: yup.string().required(activity.branchNameReq),
    no_dives: yup.number().required(activity.noDivesReq),
    currency: yup.string().required(activity.currencyReq),
    price: yup
      .number()
      .positive(activity.priceNotNegative)
      .required(activity.priceReq),
    number_of_tickets: yup.number().required(activity.noTicketsReq),
    days: yup.number().required(activity.daysReq),
    hours: yup.number().required(activity.HoursDayRequired),
    confirmation_state: yup.string().required(activity.confirmationStateReq),
    max_divers: yup.number().required(activity.maxDiversReq),
    cancelation_lead_time: yup.number().required(activity.cancelationTimeReq),
    last_time_booking: yup.number().required(activity.lastTimeBookingReq),
    time_unit: yup.string().required(activity.required).default("hour"),
  });
   useEffect(() => {
     if (mapType === 0) {
       setValidatationObject(mapTypeFirstValidation);
     } else {
       setValidatationObject(mapTypeSecondValidation);
     }
   }, [mapType]);

  return validationObject
};

export const ActivityIntialValues = {
  branch_id: null,
  branch_name: "",
  no_dives: "",
  currency: 1,
  price: "",
  number_of_tickets: "",
  days: "",
  hours: "",
  country_id: "",
  city_id: "",
  area_id: "",
  confirmation_state: "",
  max_divers: "",
  cancelation_lead_time: "",
  last_time_booking: "",
  organization: "",
  certificates: "",
  min_dives: "",
  min_age: "",
  allow_cash: false,
};
