import { Grid, Stack, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import JobOffersHeader from "../../Molecules/JobOffers/JobOffersHeader";
import ListJobOffers from "../../Molecules/JobOffers/Lists/ListJobOffers";
import { icons } from "../../../Assets/AssetHelper";
import { withTranslation } from "react-i18next";

const JobOffersList = ({
  t,
  count,
  offers,
  getJobOffers,
  title,
  is_navigate,
  joboffersPrivilages,
  jobRequestsPrivilages,
  setFilterObj,
  filterObj,
  profileData,
  setOffers
}) => {
  const [page, setPage] = useState(1);

  const handleChange = (name, value) => {
    setFilterObj({ ...filterObj, page_number: 1, [name]: value });
    setPage(1);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    setFilterObj({ ...filterObj, page_number: value });
  };

  return (
    <Grid container>
      <JobOffersHeader
        handleChange={handleChange}
        title={title}
        is_navigate={is_navigate}
        joboffersPrivilages={joboffersPrivilages}
        profileData={profileData}
        filterObj={filterObj}
        setFilterObj={setFilterObj}
      />
      {offers?.length == 0 ? (
        <Stack
          width="100%"
          alignItems="center"
          justifyContent="center"
          height="500px"
          gap={2}
        >
          <img src={icons?.Freelancer} width="113px" />
          <Typography fontSize="30px" fontWeight="700">
            {t("joboffer.emptyPage")}
          </Typography>
        </Stack>
      ) : (
        <ListJobOffers
          count={count}
          offers={offers}
          joboffersPrivilages={joboffersPrivilages}
          jobRequestsPrivilages={jobRequestsPrivilages}
          page={page}
          handlePageChange={handlePageChange}
          getJobOffers={getJobOffers}
          filterObj={filterObj}
          setOffers={setOffers}
        />
      )}
    </Grid>
  );
};

export default withTranslation()(JobOffersList);
