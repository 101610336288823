import { Grid, Typography, Divider } from "@mui/material";
import "./Form.css";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import { useState, useEffect } from "react";
import { removeObjectWithId } from "../../../../Contexts/APIs/Helper/Helper.js";
import { Fragment } from "react";

const PriceIncludeOrNot = (props) => {
  const {
    title,
    updatePriceTable,
    updatedList,
    type,
    list,
    getListOfPrices,
    editableList,
    formType,
    setAllList,
    allList,
  } = props;
  const [tempPriceList, setTempPriceList] = useState([]);
  const [allActiveClass, setAllActiveClass] = useState([]);
  const [priceActiveClass, setPriceActiveClass] = useState([]);
  const [priceIncludesList, setPriceIncludesList] = useState([]);

  const handleToggleItem = (item) => {
    // Toggle tempPriceList
    if (tempPriceList.includes(item)) {
      setTempPriceList(tempPriceList.filter((i) => i !== item));
    } else {
      setTempPriceList([...tempPriceList, item]);
    }
  };
  
  const handleToggleClass = (i, position) => {
    if (position === "right") {
      // Toggle for priceActiveClass
      setPriceActiveClass((prev) =>
        prev.includes(i) ? prev.filter((item) => item !== i) : [...prev, i]
      );
    } else {
      // Toggle for allActiveClass
      setAllActiveClass((prev) =>
        prev.includes(i) ? prev.filter((item) => item !== i) : [...prev, i]
      );
    }
  };
  
  const selectItem = (i, item, position) => {
    // Toggle the class (right or left side) and the item in tempPriceList
    handleToggleClass(i, position);
    handleToggleItem(item);
  };

  const addToRightList = () => {
    var uniqueArray = [
      ...new Map(tempPriceList.map((item) => [item["id"], item])).values(),
    ];
    var concatenated = [...priceIncludesList, ...uniqueArray];
    setPriceIncludesList(concatenated);
    var arr = allList.filter((el) => !uniqueArray.includes(el));
    setAllList(arr);
    setAllActiveClass([]);
    setTempPriceList([]);
    setPriceActiveClass([]);
  };

  const addToLeftList = () => {
    var uniqueArray = [
      ...new Map(tempPriceList.map((item) => [item["id"], item])).values(),
    ];
    var concatenated = [...allList, ...uniqueArray];
    setAllList(concatenated);
    var arr = priceIncludesList.filter((el) => !uniqueArray.includes(el));
    setPriceIncludesList(arr);
    setAllActiveClass([]);
    setTempPriceList([]);
    setPriceActiveClass([]);
  };

  const moveAllToRight = () => {
    if (priceIncludesList.length === 0) {
      setPriceIncludesList(allList);
    } else {
      const newArr = [...priceIncludesList, ...allList];
      setPriceIncludesList(newArr);
    }
    setAllList([]);
    setTempPriceList([]);
    setAllActiveClass([]);
    setPriceActiveClass([]);
  };

  const moveAllToLeft = () => {
    if (allList.length === 0) {
      setAllList(priceIncludesList);
    } else {
      const newArr = [...allList, ...priceIncludesList];
      setAllList(newArr);
    }
    setPriceIncludesList([]);
    setTempPriceList([]);
    setAllActiveClass([]);
    setPriceActiveClass([]);
  };

  useEffect(() => {
    var position = 1;
    var finalList = priceIncludesList.map((price) => {
      return {
        price_include_id: price?.id,
        position: position++,
      };
    });
    updatePriceTable(finalList, allList, type);
  }, [priceIncludesList, editableList]);

  useEffect(() => {
    setAllList(updatedList);
  }, [updatedList]);

  useEffect(() => {
    setAllList(list);
  }, [list]);

  useEffect(() => {
    if (formType === "edit") {
      var arr = [];
      editableList?.map((price) => {
        arr = removeObjectWithId(allList, price.id, "id");
      });

      setPriceIncludesList(editableList);
      setAllList(list);
    }
  }, [formType, editableList]);
  return (
    <Grid container className="activity-form">
      <Grid container>
        <Typography
          component="h1"
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            borderBottom: "2px solid var(--primary-color)",
          }}
          className="mb-20px"
        >
          {title}
        </Typography>
      </Grid>
      <Grid item md={5} className="price-items-panel">
        {allList?.map((item, index) => (
          <Fragment key={index}>
            <Grid item md={12} height="40px" key={index}>
              <Typography
                onClick={() => selectItem(index, item, "left")}
                component="p"
                lineHeight="2.5"
                style={{
                  cursor: "pointer",
                  padding: "0 15px",
                  background: allActiveClass.includes(index)
                    ? "#ddd"
                    : "transparent",
                }}
              >
                {item.name}
              </Typography>
            </Grid>
            <Divider style={{ width: "100%" }} />
          </Fragment>
        ))}
      </Grid>
      <Grid item md={2} textAlign="center" position="relative">
        <div className="icons-container">
          <KeyboardDoubleArrowRightIcon
            onClick={() => moveAllToRight()}
            className="mb-10px icon-style"
          />
          <ExpandCircleDownOutlinedIcon
            onClick={() => addToRightList()}
            className="mb-10px icon-style right-arrow-icon"
          />
          <ExpandCircleDownOutlinedIcon
            onClick={() => addToLeftList()}
            className="mb-10px icon-style left-arrow-icon"
          />
          <KeyboardDoubleArrowLeftIcon
            onClick={() => moveAllToLeft()}
            className="mb-10px icon-style"
          />
        </div>
      </Grid>
      <Grid item md={5} className="price-items-panel">
        {priceIncludesList?.map((item, index) => (
          <Fragment key={index}>
            <Grid item md={12} height="40px" key={index}>
              <Typography
                onClick={() => selectItem(index, item, "right")}
                component="p"
                lineHeight="2.5"
                style={{
                  cursor: "pointer",
                  padding: "0 15px",
                  background: priceActiveClass.includes(index)
                    ? "#ddd"
                    : "transparent",
                }}
              >
                {item.name}
              </Typography>
            </Grid>
            <Divider style={{ width: "100%" }} />
          </Fragment>
        ))}
      </Grid>
    </Grid>
  );
};

export default PriceIncludeOrNot;
