import { Grid, Typography, Divider, Stack } from "@mui/material";
import "./Form.css";
import SelectBox from "../../../Atoms/Select/Select.jsx";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput.jsx";
import SwitcheLabel from "../../../Atoms/Buttons/Switch/SwitchButton.js";
import { withTranslation } from "react-i18next";
import { Fragment } from "react";

const ActivityMoreInfo = (props) => {
  const { t, formik, supportedLanguages, supportLang, isInHouseActivity } =
    props;

  const switchedOptions = [
    {
      title: t("activity.guidedDives"),
      value: false,
      name: "guided_dives",
    },
    {
      title: t("activity.wheelchairAccessible"),
      value: false,
      name: "wheelchair_accessible",
    },
  ];

  return (
    <Grid container className="activity-form">
      <Grid container>
        <Typography
          component="h1"
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            borderBottom: "2px solid var(--primary-color)",
          }}
          className="mb-20px"
        >
          {t("activity.moreInformation")}
        </Typography>
      </Grid>
      {isInHouseActivity !== true ? (
        <>
          <Grid container className="mb-20px" alignItems="center">
            <Grid item sm={5.8}>
              <SelectBox
                id="confirmation_state"
                name="confirmation_state"
                items={[
                  { name: t("activity.manualConfirmation"), id: 1 },
                  { name: t("activity.autoConfirmation"), id: 2 },
                ]}
                title={t("activity.confirmationState")}
                className="select"
                value={formik.values.confirmation_state}
                error={Boolean(formik.errors.confirmation_state)}
                helperText={formik.errors.confirmation_state}
                onchange={formik.handleChange}
              />
            </Grid>
            <Grid item sm={0.4}></Grid>
            <Grid item sm={5.8}>
              <TextFieldInput
                name="max_divers"
                id="max_divers"
                placeholder={t("activity.maxDiversGuides")}
                className="login-input"
                type="number"
                value={formik.values.max_divers}
                error={
                  Boolean(formik.errors.max_divers)
                }
                helperText={
                  formik.errors.max_divers
                }
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
          <Grid container className="mb-20px">
            <Grid container item sm={5.8} gap={1} alignItems="center">
              <Grid item sm={8}>
                <TextFieldInput
                  id="cancelation_lead_time"
                  name="cancelation_lead_time"
                  type="number"
                  placeholder={t("activity.cancelationLeadTime")}
                  className="login-input"
                  value={formik.values.cancelation_lead_time}
                  error={
                    
                    Boolean(formik.errors.cancelation_lead_time)
                  }
                  helperText={
                    
                    formik.errors.cancelation_lead_time
                  }
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item sm={3.5}>
                <SelectBox
                  id="time_unit"
                  name="time_unit"
                  items={[
                    { name: t("activity.day"), id: "day" },
                    { name: t("activity.hour"), id: "hour" },
                  ]}
                  defaultValue={"hour"}
                  title={t("activity.timeUnit")}
                  className="select"
                  value={formik.values.time_unit}
                  error={
                    Boolean(formik.errors.time_unit)
                  }
                  helperText={
                    formik.errors.time_unit
                  }
                  // onchange={formik.handleChange}
                  onchange={() => {
                    document.querySelector("#cancelation_lead_time").value =
                      null;
                    formik.setValues({
                      ...formik?.values,
                      time_unit:
                        formik.values.time_unit == "hour" ? "day" : "hour",
                      cancelation_lead_time: null,
                    });
                  }}
                />
              </Grid>
            </Grid>
            <Grid item sm={0.4}></Grid>
            <Grid container item sm={5.8} gap={1} alignItems="center">
              <Grid item sm={8}>
                <TextFieldInput
                  id="last_time_booking"
                  name="last_time_booking"
                  type="number"
                  placeholder={t("activity.lastTimeBooking")}
                  className="login-input"
                  value={formik.values.last_time_booking}
                  error={
                    
                    Boolean(formik.errors.last_time_booking)
                  }
                  helperText={
                    
                    formik.errors.last_time_booking
                  }
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item sm={3.5}>
                <SelectBox
                  id="time_unit"
                  name="time_unit"
                  items={[
                    { name: t("activity.day"), id: "day" },
                    { name: t("activity.hour"), id: "hour" },
                  ]}
                  defaultValue={"hour"}
                  title={t("activity.timeUnit")}
                  className="select"
                  value={formik.values.deadline_time_unit}
                  error={
                    
                    Boolean(formik.errors.deadline_time_unit)
                  }
                  helperText={
                    
                    formik.errors.deadline_time_unit
                  }
                  // onchange={formik.handleChange}
                  onchange={() => {
                    document.querySelector("#last_time_booking").value = null;
                    formik.setValues({
                      ...formik?.values,
                      deadline_time_unit:
                        formik.values.deadline_time_unit == "hour"
                          ? "day"
                          : "hour",
                      last_time_booking: null,
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : null}
      {isInHouseActivity !== true &&
        switchedOptions?.map((option, index) => (
          <Grid container key={index}>
            <Grid item sm={11}>
              <Typography
                component="h1"
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  lineHeight: "3",
                }}
              >
                {option?.title}
              </Typography>
            </Grid>
            <Grid item sm={1}>
              <SwitcheLabel
                style={{ padding: "15px" }}
                name={option?.name}
                checked={
                  formik.values[option?.name] == "on" ||
                  formik.values[option?.name] == true
                    ? true
                    : false
                }
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
        ))}
      <Divider style={{ width: "100%", margin: "10px 0" }} />
      {isInHouseActivity === true ? (
        <Grid container className="mb-20px">
          <Grid item sm={3}>
            <Typography component="p">
              {t("activity.shortDescription")}
            </Typography>
          </Grid>
          <Grid item sm={9}>
            <TextFieldInput
              placeholder={t("activity.whatThisActivityAbout")}
              className="textarea"
              multiline={true}
              name={"description"}
              id={"description"}
              rows={4}
              inputProps={{
                style: {
                  position: "absolute",
                  top: "10px",
                  width: "95%",
                },
              }}
              value={formik?.values.description}
              error={
                Boolean(formik.errors.description)
              }
              helperText={
                formik.errors.description
              }
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>
      ) : null}
      {supportedLanguages.map((lang, index) => (
        <Fragment key={index}>
          {supportLang && supportLang === lang.id && (
            <>
              {isInHouseActivity !== true ? (
                <>
                  <Grid container className="mb-20px" key={index}>
                    <Grid item sm={3}>
                      <Typography component="p">
                        {t("activity.shortDescription")}
                      </Typography>
                    </Grid>
                    <Grid item sm={9}>
                      <TextFieldInput
                        placeholder={t("activity.whatThisActivityAbout")}
                        className="textarea"
                        multiline={true}
                        name={`description_${lang?.id}`}
                        id={"description_" + lang.id}
                        rows={4}
                        inputProps={{
                          style: {
                            position: "absolute",
                            top: "10px",
                            width: "95%",
                          },
                        }}
                        value={formik?.values[`description_${lang?.id}`]}
                        error={
                          
                          Boolean(formik.errors.description_en)
                        }
                        helperText={
                          
                          formik.errors.description_en
                        }
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container className="mb-20px">
                    <Grid item sm={3}>
                      <Typography component="p">
                        {t("activity.cancelationPolicy")}
                      </Typography>
                    </Grid>
                    <Grid item sm={9}>
                      <TextFieldInput
                        placeholder={t("activity.conditionsOfCancel")}
                        className="textarea"
                        multiline={true}
                        name={`cancellation_policy_${lang?.id}`}
                        id={"cancellation_policy_" + lang.id}
                        rows={4}
                        inputProps={{
                          style: {
                            position: "absolute",
                            top: "10px",
                            width: "95%",
                          },
                        }}
                        value={
                          formik?.values[`cancellation_policy_${lang?.id}`]
                        }
                        error={
                          
                          Boolean(formik.errors.cancellation_policy_en)
                        }
                        helperText={
                         
                          formik.errors.cancellation_policy_en
                        }
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Grid>
                  {/* <Grid container md={12} className="mb-20px">
                                    <Grid sm={3}>
                                        <Typography component="p">
                                            Confirmation
                                        </Typography>
                                    </Grid>
                                    <Grid sm={9}>
                                        <TextFieldInput
                                            placeholder="What are confirmation criteria?"
                                            className='textarea'
                                            multiline={true}
                                            name={`confirmation_text_${lang?.id}`}
                                            id={'confirmation_text_' + lang.id}
                                            rows={4}
                                            inputProps={{
                                                style: {
                                                    position: 'absolute',
                                                    top: '10px',
                                                    width: '95%'
                                                }
                                            }}
                                            value={formik?.values[`confirmation_text_${lang?.id}`]}

                                            // error={formik.touched.cancelation_policy && Boolean(formik.errors.cancelation_policy)}
                                            // helperText={formik.touched.cancelation_policy && formik.errors.cancelation_policy}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>
                                </Grid> */}
                  <Grid container className="mb-20px">
                    <Grid item sm={3}>
                      <Typography component="p">
                        {t("activity.confirmation")}
                      </Typography>
                    </Grid>
                    <Grid item sm={9}>
                      <TextFieldInput
                        placeholder={t("activity.confirmationCriteria")}
                        className="textarea"
                        multiline={true}
                        name={"confirmation_text_" + lang.id}
                        id={"confirmation_text_" + lang.id}
                        rows={4}
                        inputProps={{
                          style: {
                            position: "absolute",
                            top: "10px",
                            width: "95%",
                          },
                        }}
                        value={formik?.values[`confirmation_text_${lang?.id}`]}
                        error={
                          
                          Boolean(formik.errors.confirmation_text_en)
                        }
                        helperText={
                          
                          formik.errors.confirmation_text_en
                        }
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container className="mb-20px">
                    <Grid item sm={3}>
                      <Typography component="p">
                        {t("activity.pickupInfo")}
                      </Typography>
                    </Grid>
                    <Grid item sm={9}>
                      <TextFieldInput
                        placeholder={t("activity.howPickupProcessDone")}
                        className="textarea"
                        multiline={true}
                        name={"pickup_info_" + lang.id}
                        id={"pickup_info_" + lang.id}
                        rows={4}
                        inputProps={{
                          style: {
                            position: "absolute",
                            top: "10px",
                            width: "95%",
                          },
                        }}
                        value={formik?.values[`pickup_info_${lang?.id}`]}
                        error={
                          
                          Boolean(formik.errors.pickup_info_en)
                        }
                        helperText={
                          
                          formik.errors.pickup_info_en
                        }
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container className="mb-20px">
                    <Grid item sm={3}>
                      <Typography component="p">
                        {t("activity.clientExpectation")}
                      </Typography>
                    </Grid>
                    <Grid item sm={9}>
                      <TextFieldInput
                        placeholder={t("activity.whatAreClientExpectations")}
                        className="textarea"
                        multiline={true}
                        name={"client_expectation_" + lang.id}
                        id={"client_expectation_" + lang.id}
                        rows={4}
                        inputProps={{
                          style: {
                            position: "absolute",
                            top: "10px",
                            width: "95%",
                          },
                        }}
                        value={formik?.values[`client_expectation_${lang?.id}`]}
                        error={
                          
                          Boolean(formik.errors.client_expectation_en)
                        }
                        helperText={
                          
                          formik.errors.client_expectation_en
                        }
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container className="mb-20px">
                    <Grid item sm={3}>
                      <Typography component="p">
                        {t("activity.otherInformation")}
                      </Typography>
                    </Grid>
                    <Grid item sm={9}>
                      <TextFieldInput
                        placeholder={t("activity.whatElse")}
                        className="textarea"
                        multiline={true}
                        name={"other_information_" + lang.id}
                        id={"other_information_" + lang.id}
                        rows={4}
                        inputProps={{
                          style: {
                            position: "absolute",
                            top: "10px",
                            width: "95%",
                          },
                        }}
                        value={formik?.values[`other_information_${lang?.id}`]}
                        // error={formik.touched.cancelation_policy && Boolean(formik.errors.cancelation_policy)}
                        // helperText={formik.touched.cancelation_policy && formik.errors.cancelation_policy}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : null}
            </>
          )}
        </Fragment>
      ))}
    </Grid>
  );
};

export default withTranslation()(ActivityMoreInfo);
