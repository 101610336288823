import { Grid, Typography } from "@mui/material";
import ReservationsDetails from "../../../Components/Organisms/Reservations/ReservationsDetails.jsx";

const ReservationsDetailsTemplate = (props) => {
    const { t, getGroupReservationsList, count, reservationsData, setReservationsData, reservationsDuration, setOpenLoader, updatePayment, ordersPrivilages } = props;

    return (
        <Grid container className="reservation_temp">
            <ReservationsDetails 
                getGroupReservationsList={getGroupReservationsList}
                count={count}
                reservationsData={reservationsData}
                setReservationsData={setReservationsData}
                reservationsDuration={reservationsDuration}
                setOpenLoader={setOpenLoader}
                updatePayment={updatePayment}
                ordersPrivilages={ordersPrivilages}
            />
        </Grid>
    )
}

export default ReservationsDetailsTemplate