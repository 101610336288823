import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";
import { makeFilterString } from "../Helper/Helper.js";

export async function getBranchesList(filter_obj) {
  return await axiosFn("get", URL.branches + makeFilterString(filter_obj))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function createNewBranch(branch_data) {
  return await axiosFn("post", URL.branches, branch_data)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function getBranchDetails(branch_id) {
  return await axiosFn("get", URL.branches + "/" + branch_id)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function EditBranch(branch_id, data, action) {
  return await axiosFn("put", URL.branches + "/" + branch_id, data)
    .then((res) => {
      action && action();
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function DeleteBranch(branch_id) {
  return await axiosFn("delete", URL.branches + "/" + branch_id)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
