import { Box, Grid } from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import passwordCheck from '../../../Assets/Images/passwordcheck.png';
import PrimaryButton from '../../Atoms/Buttons/Primary/PrimaryButton';
import TextFieldInput from '../../Atoms/Input/TextField/TextFieldInput';
import ForgetPasswordHead from './ForgetPasswordHead';
import * as yup from "yup";
import { ConfirmPass } from '../../../Contexts/APIs/ForgotPassword/forgetPassword';
import { useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import './ForgetPassword.css';
import PasswordCriteria from '../../Atoms/SharedComponents/PasswordCriteria';

function ConfirmPassword({t}) {
    const navigate = useNavigate();

    const validationSchemaObject = yup.object({         
        password: yup.string()
        .trim()
        .required(t("validation.password"))
        .min(8, t("validation.password_digits")) // Minimum 8 characters
        .matches(/[a-zA-Z]/, t("validation.password_letter")) // At least one letter
        .matches(/[0-9]/, t("validation.password_numbers")),
        passwordConfirmation: yup.string().oneOf([yup.ref("password"), null], t("forgotPassword.passwordConfirmationValid")).required(t("profile.passwordConfirmationRequired"))
    });

    const formik = useFormik({
        initialValues: {            
            password: '',
            passwordConfirmation: ''
        },
        validationSchema: validationSchemaObject,
        onSubmit:async (values) => {
            const data = {
                "user": {
                    "email": localStorage.getItem("email"),
                    "password": values.password,
                    "password_confirmation": values.passwordConfirmation
                },
                "device": {
                    "uuid": "",
                    "device_type": "web",
                    "fcm_token": localStorage.getItem("fcm_token")
                }
            };
            // await ConfirmPass(data, 'customer', navigate);
            await ConfirmPass(data, 'dive_center', navigate);
            await navigate('/login');
        }
    });
  return (
    <Grid container lg={4} md={4} sm={5} xs={8} className='password-form-wrap'>
        <ForgetPasswordHead imgSrc={passwordCheck} imgAlt='passwordCheck' text='Forget password' />

        <Grid lg={12} md={12} sm={12} xs={12} mt={2}>
            <form style={{ margin: "auto", width: "100%" }} className='password-form'>    
                <Box width={'100%'} >
                    
                <TextFieldInput
                    name="password"
                    id="password"
                    placeholder={t("forgotPassword.passwordPlaceholder")}
                    type="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                    clickEnter={formik.handleSubmit}
                />
                <PasswordCriteria/>
                    </Box>            
                <TextFieldInput                    
                    name="passwordConfirmation"
                    id="passwordConfirmation"
                    placeholder={t("forgotPassword.passwordConfirmPlaceholder")}
                    type="password"
                    onChange={formik.handleChange}
                    value={formik.values.passwordConfirmation}
                    error={formik.touched.passwordConfirmation && Boolean(formik.errors.passwordConfirmation)}
                    helperText={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation}
                    clickEnter={formik.handleSubmit}
                />
                
                <PrimaryButton
                    text={t("forgotPassword.changePassword")}
                    classBtn="primary"
                    style={{ padding: "5px 50px", margin: "auto" }}
                    click={formik.handleSubmit}
                />
            </form>
        </Grid>
    </Grid>
  )
}

export default withTranslation() (ConfirmPassword)