import { Grid } from "@mui/material";
import BranchDetails from "../../../Components/Organisms/Branches/BranchDetails.jsx";

const BranchDetailsTemplate = (props) => {
    const { t, branchDetails, branchesPrivilages } = props;

    return (
        <Grid container>
            <BranchDetails
                branchDetails={branchDetails}
                branchesPrivilages={branchesPrivilages}
            />
        </Grid>
    )
}

export default BranchDetailsTemplate