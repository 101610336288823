import { Grid } from "@mui/material";
import InHouseActivityDetails from "../../../../Components/Organisms/Salesmen/InHouseActivities/InHouseActivityDetails";

const InHouseActivityDetailsTemplate = (props) => {
  const { t, activityDetails, RemoveActivity, inHouseActivitiesPrivilages } =
    props;

  return (
    <Grid container>
      <InHouseActivityDetails
        activityDetails={activityDetails}
        RemoveActivity={RemoveActivity}
        inHouseActivitiesPrivilages={inHouseActivitiesPrivilages}
      />
    </Grid>
  );
};

export default InHouseActivityDetailsTemplate;
