import { Grid } from "@mui/material";
import CustomPagination from "../../../Atoms/Pagination/Pagination.js";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import RejectionModal from "../../General/Models/RejectionModal.jsx";
import "./ListBranches.css";
import { useEffect } from "react";

const ListBranches = (props) => {
  const { t, page, branchesList, count, branchesPrivilages, updatePagination } =
    props;
  const Navigate = useNavigate();

  const userType = localStorage.getItem("userType");
  const selectedLang = localStorage.getItem("lang");
  let [searchParams, setSearchParams] = useSearchParams();

  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");

  const [openRejectModel, setOpenRejectModel] = useState(false);
  const [rejectHeadName, setRejectHeadName] = useState();
  const [rejectionReason, setRejectionReason] = useState("");
  const [branchId, setBranchId] = useState("");
  const [branchType, setBranchType] = useState("");

  const handelOpenRejectReason = (bId, bName, bType, bReason) => {
    setBranchId(bId);
    setRejectHeadName(bName);
    setBranchType(bType);
    setRejectionReason(bReason);
    setOpenRejectModel(true);
  };

  return (
    <Grid container>
      <Grid container className="table-header mb-20px">
        <Grid item md={1} textAlign="center">
          {t("branch.ID")}.
        </Grid>
        <Grid item md={2}>
          {t("branch.branchName")}
        </Grid>
        <Grid item md={2}>
          {t("branch.branchManager")}
        </Grid>
        <Grid item sm={2.5}>
          {t("branch.branchEmail")}
        </Grid>
        <Grid item sm={1.5}>
          {t("branch.branchNumber")}
        </Grid>
        <Grid item sm={1.5}>
          {t("branch.type")}
        </Grid>
        <Grid item sm={1.5}>
          {t("branch.status")}
        </Grid>
        {/* <Grid sm={1.5} style={{ padding: "0 20px" }}>Main branch</Grid> */}
      </Grid>
      <Grid container className="table-items-container">
        {branchesList?.map((branchItem, index) => (
          <Grid
            key={index}
            container
            item
            lg={12}
            className="branch-item mb-20px"
            sx={
              branchItem.status != t("reservations.rejected")
                ? { cursor: "pointer" }
                : null
            }
            onClick={() => {
              branchItem.status != t("reservations.rejected") &&
                Navigate(
                  `/branches/details/${branchItem?.id}?branchType=${branchItem?.branch_type}&page=${pageNumber}`
                );
            }}
          >
            <Grid item md={1} className="branch-item-no">
              {branchItem?.id}
            </Grid>
            <Grid item md={2} className="branch-item-name">
              {branchItem?.localized_data?.[selectedLang]?.name || "-"}
            </Grid>
            <Grid item sm={2} className="branch-item-name">
              {branchItem?.manager_name}
            </Grid>
            <Grid item sm={2.5} className="branch-item-email">
              {branchItem?.email}
            </Grid>
            <Grid item sm={1.5}>
              {branchItem?.branch_country_code} {branchItem?.branch_number}
            </Grid>
            <Grid item sm={1.5}>
              {branchItem?.branch_type == "office"
                ? t("branch.diveCenter")
                : branchItem?.branch_type.replace("_", " ")}
            </Grid>
            <Grid
              item
              sm={1.25}
              sx={{
                color: `${
                  branchItem.status === "approved"
                    ? "#00b906"
                    : branchItem.status ===  t("reservations.pending") ||
                      branchItem.status === t("reservations.suspended") 
                    ? "#f6b119"
                    : branchItem.status === t("reservations.rejected") ||
                      branchItem.status === t("reservations.deleted") 
                    ? "#e10007"
                    : "#333132"
                }`,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {branchItem.status === "dive_expert"
                ? "dive expert"
                : branchItem.status}
              {branchItem.status === t("reservations.rejected") && (
                <button
                  className="branch-item-reject-btn"
                  onClick={() =>
                    handelOpenRejectReason(
                      branchItem?.id,
                      branchItem.name,
                      branchItem?.branch_type,
                      branchItem.rejection_reason !== null
                        ? branchItem.rejection_reason
                        : ""
                    )
                  }
                >
                  {" "}
                  ( View reasons ){" "}
                </button>
              )}
            </Grid>
          </Grid>
        ))}
        {count > 10 && (
          <Grid container justifyContent="center" mb={7.5} mt={5}>
            <CustomPagination
              count={Math.ceil(count / 10)}
              size="large"
              shape="rounded"
              page={page}
              className="pagination"
              updatePagination={updatePagination}
            />
          </Grid>
        )}
        {openRejectModel === true && (
          <RejectionModal
            open={openRejectModel}
            setOpen={setOpenRejectModel}
            text={[`Rejection reason  ( ${rejectHeadName} )`, rejectionReason]}
            buttons={["Cancel", "Edit branch info"]}
            handelRejectRequest={() => {
              ((userType === "descendant_user" &&
                branchesPrivilages.can_update) ||
                userType !== "descendant_user") &&
                Navigate(
                  `/branches/edit-branch/${branchId}?branchType=${branchType}&page=${pageNumber}`
                );
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default withTranslation()(ListBranches);
