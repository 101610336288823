import { Box, Grid, Stack, Typography } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { icons } from "../../../../Assets/AssetHelper";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput";
import * as yup from "yup";
import { withTranslation } from "react-i18next";
import PasswordCriteria from "../../../Atoms/SharedComponents/PasswordCriteria.jsx";

const ChangePasswordForm = ({ ChangePassword, t }) => {
  const fcm_token = localStorage.getItem("fcm_token");
  const ChangePasswordIntialValues = {
    current_password: "",
    password: "",
    password_confirmation: "",
  };

  const ChangePasswordValidationSchema = yup.object({
    current_password: yup
      .string()
      .trim()
      .required(t("profile.currentPasswordRequired"))
      .min(8),
    password: yup
      .string()
      .trim()
      .required(t("validation.password"))
      .min(8, t("validation.password_digits")) // Minimum 8 characters
      .matches(/[a-zA-Z]/, t("validation.password_letter")) // At least one letter
      .matches(/[0-9]/, t("validation.password_numbers")),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], t("profile.passwordMatch"))
      .required(t("profile.confirmPasswordRequired")),
  });
  const formik = useFormik({
    initialValues: ChangePasswordIntialValues,
    validationSchema: ChangePasswordValidationSchema,
    onSubmit: (values) => {
      const data = {
        user: {
          current_password: values.current_password,
          password: values.password,
          password_confirmation: values.password_confirmation,
        },
        device: {
          uuid: "dummy",
          device_type: "web",
          fcm_token: fcm_token,
        },
      };
      const user_id = localStorage.getItem("userId");
      ChangePassword("dive_centers", data, user_id);
    },
  });
  return (
    <Grid container lg={12} md={12} sm={12} xs={12} mt={10}>
      <Grid
        className="mb-20px"
        lg={9}
        md={9}
        sm={9}
        xs={9}
        style={{ margin: "auto" }}
      >
        <TextFieldInput
          name="current_password"
          id="current_password"
          placeholder={t("profile.password")}
          className="login-input"
          type="password"
          onChange={formik.handleChange}
          value={formik.values.current_password}
          error={
            formik.touched.current_password &&
            Boolean(formik.errors.current_password)
          }
          helperText={
            formik.touched.current_password && formik.errors.current_password
          }
          clickEnter={formik.handleSubmit}
        />
      </Grid>
      <Grid
        className="mb-30px"
        lg={9}
        md={9}
        sm={9}
        xs={9}
        style={{ margin: "auto" }}
      >
        <TextFieldInput
          name="password"
          id="password"
          placeholder={t("profile.newPassword")}
          className="login-input"
          type="password"
          onChange={formik.handleChange}
          value={formik.values.password}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          clickEnter={formik.handleSubmit}
        />
        {/* <PasswordCriteria /> */}
        <Grid lg={10} md={9} sm={9} xs={9}>
          <PasswordCriteria />
        </Grid>
      </Grid>
      <Grid
        className="mb-30px"
        lg={9}
        md={9}
        sm={9}
        xs={9}
        style={{ margin: "auto" }}
      >
        <TextFieldInput
          name="password_confirmation"
          id="password_confirmation"
          placeholder={t("profile.confirmPassword")}
          className="login-input"
          type="password"
          onChange={formik.handleChange}
          value={formik.values.password_confirmation}
          error={
            formik.touched.password_confirmation &&
            Boolean(formik.errors.password_confirmation)
          }
          helperText={
            formik.touched.password_confirmation &&
            formik.errors.password_confirmation
          }
          clickEnter={formik.handleSubmit}
        />
      </Grid>
      <Grid className="mb-30px" container>
        <PrimaryButton
          text={t("profile.save")}
          classBtn="primary"
          style={{ padding: "5px 50px", margin: "auto" }}
          click={formik.handleSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default withTranslation()(ChangePasswordForm);
