import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { icons } from "../../../../Assets/AssetHelper";
import "./UsersList.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import DeleteModal from "../../General/Models/DeleteModal";
import { deleteDescendantUser } from "../../../../Contexts/APIs/Users/UsersList";
import CustomPagination from "../../../Atoms/Pagination/Pagination";
import { withTranslation } from "react-i18next";
import ReactPhoneInput2 from "../../../Atoms/Input/PhoneInput/ReactPhoneInput2";

function UsersList({
  t,
  usersList,
  setUsersList,
  setOpenLoader,
  count,
  page,
  handlePageChange,
  usersPrivilages,
}) {
  const navigate = useNavigate();
  const userType = localStorage.getItem("userType");
  let [searchParams, setSearchParams] = useSearchParams();

  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [selectedUser, setSelectedUser] = useState(false);

  const handelDeleteUser = async () => {
    const { res, err } = await deleteDescendantUser(selectedUser?.user?.id);
    setOpenLoader(true);
    if (res) {
      let newData = usersList?.filter(
        (val) => val?.id != selectedUser?.user?.id
      );
      setUsersList(newData);
      setOpenLoader(false);
    }
  };

  return (
    <Grid container>
      <Grid container className="table-header mb-20px">
        <Grid item md={1} textAlign="center">
          {t("users.no")}
        </Grid>
        <Grid item md={0.75}>
          {t("users.image")}
        </Grid>
        <Grid item sm={2}>
          {t("users.name")}
        </Grid>
        <Grid item sm={2}>
          {t("users.emailAddress")}
        </Grid>
        <Grid item sm={2} px={'15px'}>
          {t("users.mobilePhone")}
        </Grid>
        <Grid item sm={2} px={'15px'}>
          {t("reservations.branch")}
        </Grid>
        <Grid item sm={1}>
          {t("users.userRole")}
        </Grid>
        <Grid item sm={1} sx={{ pl: 2 }}>
          {t("users.action")}
        </Grid>
      </Grid>

      {usersList?.length === 0 && (
        <Grid container display="block" textAlign="center">
          <img src={icons.EmptyUsers} alt="Users" />
          <Typography fontSize="30px" fontWeight="bold" color="#333132">
            {t("users.noUsers")}
          </Typography>
        </Grid>
      )}

      {usersList?.length >= 1 && (
        <>
          {usersList?.map((item, index) => {
            return (
              <Grid
                key={index}
                container
                item
                lg={12}
                className="user-item mb-20px"
                onClick={() => setSelectedUser({ user: item, index: index })}
              >
                <Grid item md={1} className="user-item-no" textAlign="center">
                  {item.id}
                </Grid>
                <Grid item md={0.75}>
                  <img src={item.avatar} alt="user" />
                </Grid>
                <Grid item sm={2} className="user-item-name">
                  {item.name}
                </Grid>
                <Grid item sm={2}>
                  {item.email}
                </Grid>
                <Grid item sm={2}>
                  <ReactPhoneInput2
                    phoneValue={item.phone_number}
                    codeValue={item.country_code}
                    disableDropdown={true}
                    formatType={'read'}
                  />
                </Grid>
                <Grid item sm={2}>
                  {item?.branch?.name}
                </Grid>
                <Grid item sm={1}>
                  {item.role.name}
                </Grid>
                <Grid item container sm={1} columnGap={1}>
                  <Grid item xs={3} textAlign="center">
                    {userType !== "descendant_user" && (
                      <PrimaryButton
                        style={{ paddingTop: "0px" }}
                        btnType="icon"
                        startIcon={
                          <img width="35" src={icons.EditIcon} alt="edit" />
                        }
                        click={() =>
                          navigate(`/users/edit/${item.id}?page=${pageNumber}`)
                        }
                      />
                    )}
                    {userType === "descendant_user" &&
                      usersPrivilages?.can_update && (
                        <PrimaryButton
                          style={{ paddingTop: "0px" }}
                          btnType="icon"
                          startIcon={
                            <img width="35" src={icons.EditIcon} alt="edit" />
                          }
                          click={() =>
                            navigate(
                              `/users/edit/${item.id}?page=${pageNumber}`
                            )
                          }
                        />
                      )}
                  </Grid>
                  <Grid item xs={3} textAlign="center">
                    {userType !== "descendant_user" && (
                      <PrimaryButton
                        style={{ paddingTop: "0px" }}
                        btnType="icon"
                        startIcon={
                          <img width="35" src={icons.DeleteIcon} alt="delete" />
                        }
                        click={() => setOpenDeleteModel(true)}
                      />
                    )}
                    {userType === "descendant_user" &&
                      usersPrivilages?.can_delete && (
                        <PrimaryButton
                          style={{ paddingTop: "0px" }}
                          btnType="icon"
                          startIcon={
                            <img
                              width="35"
                              src={icons.DeleteIcon}
                              alt="delete"
                            />
                          }
                          click={() => setOpenDeleteModel(true)}
                        />
                      )}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
          {count > 10 && (
            <Grid
              container
              item
              justifyContent={"center"}
              xs={12}
              mb={7.5}
              mt={5}
            >
              <CustomPagination
                count={Math.ceil(count / 10)}
                size="large"
                shape="rounded"
                page={page}
                handlePageChange={handlePageChange}
                className="pagination"
              />
            </Grid>
          )}
        </>
      )}

      {openDeleteModel && (
        <DeleteModal
          open={openDeleteModel}
          setOpen={setOpenDeleteModel}
          handelDeleteRequest={() => {
            handelDeleteUser(selectedUser?.user?.id);
            setOpenDeleteModel(false);
          }}
          text={[t("users.deleteUser"), t("users.deleteUserMsg")]}
          buttonText={[t("users.cancel"), t("users.delete")]}
        />
      )}
    </Grid>
  );
}

export default withTranslation()(UsersList);
