import { Grid, Typography } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";

const PasswordCriteria = ({ t }) => {
  return (
    <>
      <Typography
        sx={{
          fontSize: "12px",
          color: "#FF6868",
          lineHeight: "19.68px",
          fontFamily: "Catamaran",
        }}
      >
        {t("validation.passowrd_criteria")}
      </Typography>
    </>
  );
};

export default withTranslation()(PasswordCriteria);
