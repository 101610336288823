import React, { useEffect, useReducer, useState } from "react";
import { Route, Routes as Switch, Navigate } from "react-router-dom";
import { Grid } from "@mui/material";

import ActivitiesPage from "../../Pages/Main/Acticities/Activities.page.jsx";
import AddActivityPage from "../../Pages/Main/Acticities/AddActivity.page.jsx";
import ReservationsPage from "../../Pages/Main/Reservations/Reservations.page.jsx";
import ReservationsDetailsPage from "../../Pages/Main/Reservations/ReservationsDetails.page.jsx";
import AddReservationPage from "../../Pages/Main/Reservations/AddReservation.page.jsx";
import ActivityGroupsPage from "../../Pages/Main/Reservations/ActivityGroups.page.jsx";
import FreelancersPage from "../../Pages/Main/Reservations/Freelancers.page.jsx";
import FreelancersListPage from "../../Pages/Main/Freelancers/FreelancersList.Pages.jsx";
import JobOffersPage from "../../Pages/Main/Freelancers/JopOffers/JobOffers.Page.jsx";
import JobOfferDetailsPage from "../../Pages/Main/Freelancers/JopOffers/JobOfferDetails.Page.jsx";
import BranchesPage from "../../Pages/Main/Branches/Branches.page.jsx";
import AddBranchPage from "../../Pages/Main/Branches/AddBranch.page.jsx";
import BranchDetailsPage from "../../Pages/Main/Branches/BranchDetails.page.jsx";
import ActivityDetailsPage from "../../Pages/Main/Acticities/ActivityDetails.page.jsx";
import MainLayout from "../../Components/Organisms/Layout/index.jsx";
import AccountDataPage from "../../Pages/Main/Account/AccountData.page.jsx";
import AccountUpdatePage from "../../Pages/Main/Account/AccountUpdate.page.jsx";
import AccountBankDetailsPage from "../../Pages/Main/Account/AccountBankDetails.page.jsx";
import BranchReviewsPage from "../../Pages/Main/Branches/BranchReviews.page.jsx";
import EditBranchPage from "../../Pages/Main/Branches/EditBranch.page.jsx";
import AddJopForPage from "../../Pages/Main/Freelancers/JopOffers/AddJopOffer.Page.jsx";
import OfferApplicantsPage from "../../Pages/Main/Freelancers/JopOffers/OfferApplicants.Page.jsx";
import FreelancerDetailsPage from "../../Pages/Main/Freelancers/FreelancerDetails.Page.jsx";
import FreelancerJopOfferHistoryPage from "../../Pages/Main/Freelancers/FreelancerJopOfferHistory.Page.jsx";
import FreelancerReviewsPage from "../../Pages/Main/Freelancers/FreelancerReviews.Page.jsx";
import ResetPasswordPage from "../../Pages/Main/Account/ResetPassword.page.jsx";
import OffersPage from "../../Pages/Main/Offers/index.jsx";
import AddOfferPage from "../../Pages/Main/Offers/AddOffer.page.jsx";
import PointsPage from "../../Pages/Main/Points/Points.page.jsx";
import HomePage from "../../Pages/Main/Home.Page.jsx";
import FaqPage from "../../Pages/Main/Help/Faq/Faq.page.jsx";
import CustomerSupportPage from "../../Pages/Main/Help/CustomerSupport/CustomerSupport.page.jsx";
import ReportAppIssusePage from "../../Pages/Main/Help/ReportAppIssuse/ReportAppIssuse.page.jsx";
import CheckEmailPage from "../../Pages/Auth/ForgotPassword/CheckEmail.page.jsx";
import UsersPage from "../../Pages/Main/Users/Users.page.jsx";
import RolesPage from "../../Pages/Main/Users/Roles/Roles.page.jsx";
import UserFormPage from "../../Pages/Main/Users/UserForm.page.jsx";
import RoleFormPage from "../../Pages/Main/Users/Roles/RoleForm.page.jsx";
import { ProtectedRoute } from "../../Components/Organisms/ProtectedRoute.jsx";
import ActivityReviewsPage from "../../Pages/Main/Acticities/ActivityReviews.page.jsx";
import WalletPage from "../../Pages/Main/Wallet/Wallet.page.jsx";
import SalesmenReservationsPage from "../../Pages/Main/Salesmen/SalesmenReservations/SalesmenReservations.Page.jsx";
import SalesmenPage from "../../Pages/Main/Salesmen/SalesmenList/Salesmen.page.jsx";
import SalesmenFormPage from "../../Pages/Main/Salesmen/SalesmenList/SalesmenForm.page.jsx";
import AssignedActivitiesPage from "../../Pages/Main/Salesmen/SalesmenList/AssignedActivities.page.jsx";
import AssignedActivityGroupsPage from "../../Pages/Main/Salesmen/SalesmenList/AssignedActivityGroup.page.jsx";
import InHouseActivitiesPage from "../../Pages/Main/Salesmen/InHouseActivities/InHouseActivities.Page.jsx";
import InHouseActivityGroupsPage from "../../Pages/Main/Salesmen/InHouseActivities/InHouseActivityGroups.Page.jsx";
import InHouseActivityDetailsPage from "../../Pages/Main/Salesmen/InHouseActivities/InHouseActivityDetails.Page.jsx";
import AddInHouseActivityPage from "../../Pages/Main/Salesmen/InHouseActivities/AddInHouseActivity.Page.jsx";
import RequestedActivitiesPage from "../../Pages/Main/RequestedActivity/Activities.page.jsx";
import ApprovedActivitiesPage from "../../Pages/Main/ApprovedActivity/ApprovedActivity.page.jsx";
import SalesmenDashboardPage from "../../Pages/Main/Salesmen/SalesmenDashboard/SalesmenDashboard.Page.jsx";
import PrivateGuideReservationsPage from "../../Pages/Main/PrivateGuideReservations/PrivateGuideReservations.page.jsx";
import PrivateGuideReservationsDetailsPage from "../../Pages/Main/PrivateGuideReservations/PrivateGuideReservationsDetails.Page.jsx";

const MainTemplate = (props) => {
  const { t, profileData, privilages, setProfileData } = props;
  const initialPrivilages = {
    can_create: true,
    can_delete: true,
    can_read: true,
    can_update: true,
  };
  const userType = localStorage.getItem("userType");

  const [activitiesPrivilages, setActivitiesPrivilages] =
    useState(initialPrivilages);
  const [offersPrivilages, setOffersPrivilages] = useState(initialPrivilages);
  const [freelancersPrivilages, setFreelancersPrivilages] =
    useState(initialPrivilages);
  const [branchesPrivilages, setBranchesPrivilages] =
    useState(initialPrivilages);
  const [joboffersPrivilages, setJoboffersPrivilages] =
    useState(initialPrivilages);
  const [ordersPrivilages, setOrdersPrivilages] = useState(initialPrivilages);
  const [usersPrivilages, setUsersPrivilages] = useState(initialPrivilages);
  const [jobRequestsPrivilages, setJobRequestsPrivilages] =
    useState(initialPrivilages);
  const [salesmenPrivilages, setSalesmenPrivilages] =
    useState(initialPrivilages);
  const [salesmenReservationsPrivilages, setSalesmenReservationsPrivilages] =
    useState(initialPrivilages);
  const [salesmenStatisticsPrivilages, setStatisticsPrivilages] =
    useState(initialPrivilages);
  const [
    salesmenInhouseActivitiesPrivilages,
    setSalesmenInhouseActivitiesPrivilages,
  ] = useState(initialPrivilages);
  const [diveExpertActivityRequests, setDiveExpertActivityRequests] =
    useState(initialPrivilages);
  useEffect(() => {
    if (userType === "descendant_user") {
      privilages?.map((privilage) => {
        if (privilage.resource.name === "activities")
          setActivitiesPrivilages(privilage);
        else if (privilage.resource.name === "activity_offers")
          setOffersPrivilages(privilage);
        else if (privilage.resource.name === "dive_experts")
          setFreelancersPrivilages(privilage);
        else if (privilage.resource.name === "branches")
          setBranchesPrivilages(privilage);
        else if (privilage.resource.name === "job_offers")
          setJoboffersPrivilages(privilage);
        else if (privilage.resource.name === "orders")
          setOrdersPrivilages(privilage);
        else if (privilage.resource.name === "descendant_users")
          setUsersPrivilages(privilage);
        else if (privilage.resource.name === "job_requests")
          setJobRequestsPrivilages(privilage);
        else if (privilage.resource.name === "salesmen")
          setSalesmenPrivilages(privilage);
        else if (privilage.resource.name === "salesman_reservations")
          setSalesmenReservationsPrivilages(privilage);
        else if (privilage.resource.name === "inhouse_activities")
          setSalesmenInhouseActivitiesPrivilages(privilage);
        else if (privilage.resource.name === "salesman_statistics")
        setStatisticsPrivilages(privilage);
        else if (privilage.resource.name === "dive_expert_activity_requests")
          setDiveExpertActivityRequests(privilage);
      });
    }
  }, [privilages]);
  return (
    <Grid container>
      <Switch>
        <Route
          path="/*"
          element={
            <MainLayout
              profileData={profileData}
              setProfileData={setProfileData}
              canReadActivities={activitiesPrivilages?.can_read}
              canReadBranches={userType === "descendant_user" ? (branchesPrivilages?.can_read && profileData?.branch === null) : branchesPrivilages?.can_read}
              canReadOffers={offersPrivilages?.can_read}
              canReadOrders={ordersPrivilages?.can_read}
              canReadFreelancers={freelancersPrivilages?.can_read}
              canReadJobOffers={joboffersPrivilages?.can_read}
              canReadUsers={usersPrivilages?.can_read}
              canReadJobRequests={jobRequestsPrivilages?.can_read}
              canReadSalesmen={salesmenPrivilages?.can_read}
              canReadSalesmenReservations={
                salesmenReservationsPrivilages?.can_read
              }
              canReadSalesmenInhouseActivities={
                salesmenInhouseActivitiesPrivilages?.can_read
              }
              canReadSalesmenStatistics={salesmenStatisticsPrivilages?.can_read}
              diveExpertActivityRequests={diveExpertActivityRequests?.can_read}
            >
              <Grid style={{ width: "90%", margin: "auto" }}>
                <Switch>
                  <Route path="*" element={<Navigate to="/" />} />
                  <Route
                    path="/"
                    element={
                      <HomePage
                        profileData={profileData}
                        setProfileData={setProfileData}
                      />
                    }
                  />
                  <Route
                    path="/profile"
                    element={<AccountDataPage profileData={profileData} />}
                  />
                  <Route
                    element={
                      <ProtectedRoute
                        isAllowed={userType !== "descendant_user"}
                      />
                    }
                  >
                    <Route
                      path="/update-account"
                      element={<AccountUpdatePage profileData={profileData} />}
                    />
                    <Route
                      path="/reset-password"
                      exact
                      element={<ResetPasswordPage t={t} />}
                    />
                    <Route
                      path="/bank-details"
                      element={
                        <AccountBankDetailsPage profileData={profileData} />
                      }
                    />
                    <Route
                      path="/points"
                      element={<PointsPage profileData={profileData} />}
                    />
                  </Route>
                  {/* Requested Activites Routes */}
                  <Route
                    element={
                      <ProtectedRoute
                        isAllowed={
                          (userType === "descendant_user" &&
                            diveExpertActivityRequests?.can_read) ||
                          userType !== "descendant_user"
                        }
                      />
                    }
                  >
                    <Route
                      path="/requested-activites"
                      element={
                        <RequestedActivitiesPage
                          profileData={profileData}
                          requestedActivitiesPrivilages={
                            diveExpertActivityRequests
                          }
                        />
                      }
                    />
                    <Route
                      path="/approved-activites"
                      element={
                        <ApprovedActivitiesPage
                          profileData={profileData}
                          requestedActivitiesPrivilages={
                            diveExpertActivityRequests
                          }
                        />
                      }
                    />
                    <Route
                      path="/requested-activites/details/:id"
                      element={
                        <ActivityDetailsPage
                          activitiesPrivilages={diveExpertActivityRequests}
                          type="requested-activites"
                        />
                      }
                    />
                    <Route
                      path="/approved-activites/details/:id"
                      element={
                        <ActivityDetailsPage
                          activitiesPrivilages={diveExpertActivityRequests}
                        />
                      }
                    />
                  </Route>
                  {/* Activities Routes */}
                  <Route
                    element={
                      <ProtectedRoute
                        isAllowed={
                          (userType === "descendant_user" &&
                            activitiesPrivilages?.can_read) ||
                          userType !== "descendant_user"
                        }
                      />
                    }
                  >
                    <Route
                      path="/activities"
                      element={
                        <ActivitiesPage
                          profileData={profileData}
                          activitiesPrivilages={activitiesPrivilages}
                        />
                      }
                    />
                    <Route
                      path="/activities/details/:id"
                      element={
                        <ActivityDetailsPage
                          activitiesPrivilages={activitiesPrivilages}
                        />
                      }
                    />

                    <Route
                      path="/activity-reviews/:id"
                      element={
                        <ActivityReviewsPage profileData={profileData} />
                      }
                    />
                  </Route>
                  <Route
                    path="/activities/add-new-activity"
                    element={
                      <ProtectedRoute
                        isAllowed={
                          (userType === "descendant_user" &&
                            activitiesPrivilages?.can_create) ||
                          userType !== "descendant_user"
                        }
                      >
                        <AddActivityPage type="add" profileData={profileData} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/activities/edit-activity/:id"
                    element={
                      <ProtectedRoute
                        isAllowed={
                          (userType === "descendant_user" &&
                            activitiesPrivilages?.can_update) ||
                          userType !== "descendant_user"
                        }
                      >
                        <AddActivityPage
                          type="edit"
                          profileData={profileData}
                        />
                      </ProtectedRoute>
                    }
                  />
                  {/* Orders Routes */}
                  <Route
                    element={
                      <ProtectedRoute
                        isAllowed={
                          (userType === "descendant_user" &&
                            ordersPrivilages?.can_read) ||
                          userType !== "descendant_user"
                        }
                      />
                    }
                  >
                    <Route
                      path="/reservations"
                      element={
                        <ReservationsPage ordersPrivilages={ordersPrivilages} profileData={profileData} />
                      }
                    />
                    <Route
                      path="/reservations/:id"
                      element={
                        <ReservationsPage ordersPrivilages={ordersPrivilages} />
                      }
                    />
                    <Route
                      path="/private-guide-reservations"
                      element={
                        <PrivateGuideReservationsPage
                          ordersPrivilages={ordersPrivilages}
                          joboffersPrivilages={joboffersPrivilages}
                        />
                      }
                    />
                    <Route
                      path="/private-guide-reservations/:id"
                      element={
                        <PrivateGuideReservationsDetailsPage
                          ordersPrivilages={ordersPrivilages}
                        />
                      }
                    />

                    <Route
                      path="/reservations/freelancers"
                      element={<FreelancersPage />}
                    />
                    <Route
                      path="/activities/activity-groups/reservations-list/:id"
                      element={
                        <ReservationsDetailsPage
                          ordersPrivilages={ordersPrivilages}
                        />
                      }
                    />
                    <Route
                      path="/activities/activity-groups/:id"
                      element={<ActivityGroupsPage />}
                    />
                  </Route>
                  <Route
                    path="/reservations/activities/add-reservation/:id"
                    element={
                      <ProtectedRoute
                        isAllowed={
                          (userType === "descendant_user" &&
                            ordersPrivilages?.can_create) ||
                          userType !== "descendant_user"
                        }
                      >
                        <AddReservationPage />
                      </ProtectedRoute>
                    }
                  />
                  {/* Freelancers Routes */}
                  <Route
                    element={
                      <ProtectedRoute
                        isAllowed={
                          (userType === "descendant_user" &&
                            freelancersPrivilages?.can_read) ||
                          userType !== "descendant_user"
                        }
                      />
                    }
                  >
                    <Route
                      path="/freelancers"
                      element={
                        <Navigate
                          to={
                            joboffersPrivilages?.can_read
                              ? "/freelancers/job-offers"
                              : freelancersPrivilages?.can_read
                              ? "/freelancers/list"
                              : "/freelancers/job-offers"
                          }
                          replace
                        />
                      }
                    />
                    <Route
                      exact
                      path="/freelancers/list"
                      element={
                        <FreelancersListPage
                          freelancersPrivilages={freelancersPrivilages}
                          profileData={profileData}
                        />
                      }
                    />
                  </Route>
                  {/* Joboffers Routes */}
                  <Route
                    element={
                      <ProtectedRoute
                        isAllowed={
                          (userType === "descendant_user" &&
                            joboffersPrivilages?.can_read) ||
                          userType !== "descendant_user"
                        }
                      />
                    }
                  >
                    <Route
                      exact
                      path="/freelancers/job-offers"
                      element={
                        <JobOffersPage
                          joboffersPrivilages={joboffersPrivilages}
                          jobRequestsPrivilages={jobRequestsPrivilages}
                          profileData={profileData}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/freelancers/job-offers/:jop_offer_id/applicant/:applicant_id"
                      element={<FreelancerDetailsPage />}
                    />
                    <Route
                      exact
                      path="/freelancers/:applicant_id"
                      element={<FreelancerDetailsPage />}
                    />
                    <Route
                      exact
                      path="/requested-activites/freelancers/:applicant_id"
                      element={<FreelancerDetailsPage />}
                    />
                    <Route
                      exact
                      path="/approved-activites/freelancers/:applicant_id"
                      element={<FreelancerDetailsPage />}
                    />
                    <Route
                      exact
                      path="/private-guide-reservations/freelancers/:applicant_id"
                      element={<FreelancerDetailsPage />}
                    />
                    <Route
                      exact
                      path="/freelancers/job-offers/:id"
                      element={<JobOfferDetailsPage />}
                    />
                    <Route
                      exact
                      path="/freelancers/jop-offers-applicants/:id"
                      element={
                        <OfferApplicantsPage
                          jobRequestsPrivilages={jobRequestsPrivilages}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/freelancer/jop-offers-history/:jop_offer_id/:applicant_id"
                      element={<FreelancerJopOfferHistoryPage />}
                    />
                    <Route
                      exact
                      path="/freelancer-reviews/:jop_offer_id/:applicant_id"
                      element={<FreelancerReviewsPage />}
                    />
                  </Route>
                  <Route
                    exact
                    path="/freelancers/add-job-offers"
                    element={
                      <ProtectedRoute
                        isAllowed={
                          (userType === "descendant_user" &&
                            joboffersPrivilages?.can_create) ||
                          userType !== "descendant_user"
                        }
                      >
                        <AddJopForPage type="add" profileData={profileData} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    exact
                    path="/freelancers/edit-job-offers/:id"
                    element={
                      <ProtectedRoute
                        isAllowed={
                          (userType === "descendant_user" &&
                            joboffersPrivilages?.can_update) ||
                          userType !== "descendant_user"
                        }
                      >
                        <AddJopForPage type="edit" />
                      </ProtectedRoute>
                    }
                  />
                  {/* Branches Routes */}
                  {/* profileData?.branch === null : means that descendant user has multi||all branches, if descendant user has 1 branch should branches pages removed */}
                  <Route
                    element={
                      <ProtectedRoute
                        isAllowed={
                          (userType === "descendant_user" &&
                            branchesPrivilages?.can_read && 
                            profileData?.branch === null) ||
                          userType !== "descendant_user"
                        }
                      />
                    }
                  >
                    <Route
                      path="/branches"
                      element={
                        <BranchesPage branchesPrivilages={branchesPrivilages} />
                      }
                    />
                    <Route
                      path="/branches/details/:id"
                      element={
                        <BranchDetailsPage
                          branchesPrivilages={branchesPrivilages}
                        />
                      }
                    />
                    <Route
                      path="/branch-reviews/:id"
                      element={<BranchReviewsPage profileData={profileData} />}
                    />
                  </Route>
                  <Route
                    exact
                    path="/branches/add-new-branch"
                    element={
                      <ProtectedRoute
                        isAllowed={
                          (userType === "descendant_user" &&
                            branchesPrivilages?.can_create && 
                            profileData?.branch === null) ||
                          userType !== "descendant_user"
                        }
                      >
                        <AddBranchPage formType="add" />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/branches/edit-branch/:id"
                    element={
                      <ProtectedRoute
                        isAllowed={
                          (userType === "descendant_user" &&
                            branchesPrivilages?.can_update && 
                            profileData?.branch === null) ||
                          userType !== "descendant_user"
                        }
                      >
                        <EditBranchPage formType="edit" />
                      </ProtectedRoute>
                    }
                  />
                  {/* Offers Routes */}
                  <Route
                    path="/offers"
                    element={
                      <ProtectedRoute
                        isAllowed={
                          (userType === "descendant_user" &&
                            offersPrivilages?.can_read) ||
                          userType !== "descendant_user"
                        }
                      >
                        <OffersPage offersPrivilages={offersPrivilages} profileData={profileData} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    exact
                    path="/offers/add-new-offer"
                    element={
                      <ProtectedRoute
                        isAllowed={
                          (userType === "descendant_user" &&
                            offersPrivilages?.can_create) ||
                          userType !== "descendant_user"
                        }
                      >
                        <AddOfferPage formType="add" profileData={profileData}/>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/offers/edit-offer/:offer_id"
                    element={
                      <ProtectedRoute
                        isAllowed={
                          (userType === "descendant_user" &&
                            offersPrivilages?.can_update) ||
                          userType !== "descendant_user"
                        }
                      >
                        <AddOfferPage formType="edit" profileData={profileData} />
                      </ProtectedRoute>
                    }
                  />
                  {/* Users Rotes */}
                  <Route
                    element={
                      <ProtectedRoute
                        isAllowed={
                          (userType === "descendant_user" &&
                            usersPrivilages?.can_create) ||
                          userType !== "descendant_user"
                        }
                      />
                    }
                  >
                    <Route
                      exact
                      path="/users/add"
                      element={
                        <UserFormPage
                          formType="Add"
                          profileData={profileData}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/users/roles/add"
                      element={<RoleFormPage formType="Add" />}
                    />
                  </Route>
                  <Route
                    element={
                      <ProtectedRoute
                        isAllowed={
                          (userType === "descendant_user" &&
                            usersPrivilages?.can_update) ||
                          userType !== "descendant_user"
                        }
                      />
                    }
                  >
                    <Route
                      exact
                      path="/users/edit/:user_id"
                      element={<UserFormPage formType="Edit" />}
                    />
                    <Route
                      exact
                      path="/users/roles/edit/:role_id"
                      element={<RoleFormPage formType="Edit" />}
                    />
                  </Route>
                  <Route
                    element={
                      <ProtectedRoute
                        isAllowed={
                          (userType === "descendant_user" &&
                            usersPrivilages?.can_read) ||
                          userType !== "descendant_user"
                        }
                      />
                    }
                  >
                    <Route
                      exact
                      path="/users"
                      element={<UsersPage usersPrivilages={usersPrivilages} profileData={profileData} />}
                    />
                    <Route
                      exact
                      path="/users/roles"
                      element={<RolesPage usersPrivilages={usersPrivilages} />}
                    />
                  </Route>
                  {/* Salesmen */}
                  <Route
                    path="/salesmen/dashboard"
                    element={
                      <ProtectedRoute
                        isAllowed={
                          (userType === "descendant_user" &&
                            profileData?.ancestor?.salesman_feature &&
                            salesmenStatisticsPrivilages?.can_read) ||
                          (userType !== "descendant_user" &&
                            profileData?.salesman_feature)
                        }
                      >
                        <SalesmenDashboardPage
                          salesmenPrivilages={salesmenStatisticsPrivilages}
                          profileData={profileData}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/salesmen/in-house-activities"
                    element={
                      <ProtectedRoute
                        isAllowed={
                          (userType === "descendant_user" &&
                            profileData?.ancestor?.salesman_feature &&
                            salesmenInhouseActivitiesPrivilages?.can_read) ||
                          (userType !== "descendant_user" &&
                            profileData?.salesman_feature)
                        }
                      >
                        <InHouseActivitiesPage
                          profileData={profileData}
                          inHouseActivitiesPrivilages={
                            salesmenInhouseActivitiesPrivilages
                          }
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/salesmen/in-house-activities/activity-groups/:id"
                    element={
                      <ProtectedRoute
                        isAllowed={
                          (userType === "descendant_user" &&
                            profileData?.ancestor?.salesman_feature &&
                            salesmenInhouseActivitiesPrivilages?.can_read) ||
                          (userType !== "descendant_user" &&
                            profileData?.salesman_feature)
                        }
                      >
                        <InHouseActivityGroupsPage
                          inHouseActivitiesPrivilages={
                            salesmenInhouseActivitiesPrivilages
                          }
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/salesmen/in-house-activities/details/:id"
                    element={
                      <ProtectedRoute
                        isAllowed={
                          (userType === "descendant_user" &&
                            profileData?.ancestor?.salesman_feature &&
                            salesmenInhouseActivitiesPrivilages?.can_read) ||
                          (userType !== "descendant_user" &&
                            profileData?.salesman_feature)
                        }
                      >
                        <InHouseActivityDetailsPage
                          inHouseActivitiesPrivilages={
                            salesmenInhouseActivitiesPrivilages
                          }
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/salesmen/in-house-activities/add-new-activity"
                    element={
                      <ProtectedRoute
                        isAllowed={
                          (userType === "descendant_user" &&
                            profileData?.ancestor?.salesman_feature &&
                            salesmenInhouseActivitiesPrivilages?.can_read &&
                            salesmenInhouseActivitiesPrivilages?.can_create) ||
                          (userType !== "descendant_user" &&
                            profileData?.salesman_feature)
                        }
                      >
                        <AddInHouseActivityPage type="add" profileData={profileData} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/salesmen/in-house-activities/edit-activity/:id"
                    element={
                      <ProtectedRoute
                        isAllowed={
                          (userType === "descendant_user" &&
                            profileData?.ancestor?.salesman_feature &&
                            salesmenInhouseActivitiesPrivilages?.can_read &&
                            salesmenInhouseActivitiesPrivilages?.can_update) ||
                          (userType !== "descendant_user" &&
                            profileData?.salesman_feature)
                        }
                      >
                        <AddInHouseActivityPage type="edit"  profileData={profileData}/>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/salesmen/reservations"
                    element={
                      <ProtectedRoute
                        isAllowed={
                          (userType === "descendant_user" &&
                            profileData?.ancestor?.salesman_feature &&
                            salesmenReservationsPrivilages?.can_read) ||
                          (userType !== "descendant_user" &&
                            profileData?.salesman_feature)
                        }
                      >
                        <SalesmenReservationsPage
                          salesmenReservationsPrivilages={
                            salesmenReservationsPrivilages
                          }
                          profileData={profileData}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    exact
                    path="/salesmen/salesmen-list"
                    element={
                      <ProtectedRoute
                        isAllowed={
                          (userType === "descendant_user" &&
                            profileData?.ancestor?.salesman_feature &&
                            salesmenPrivilages?.can_read) ||
                          (userType !== "descendant_user" &&
                            profileData?.salesman_feature)
                        }
                      >
                        <SalesmenPage privilages={salesmenPrivilages} profileData={profileData} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    exact
                    path="/salesmen/salesmen-list/:id/assigned-activities"
                    element={
                      <ProtectedRoute
                        isAllowed={
                          (userType === "descendant_user" &&
                            profileData?.ancestor?.salesman_feature &&
                            salesmenPrivilages?.can_read) ||
                          (userType !== "descendant_user" &&
                            profileData?.salesman_feature)
                        }
                      >
                        <AssignedActivitiesPage
                          salesmenPrivilages={salesmenPrivilages}
                          profileData={profileData}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    exact
                    path="/salesmen/salesmen-list/:id/assigned-activities/activity-groups/:group_id"
                    element={
                      <ProtectedRoute
                        isAllowed={
                          (userType === "descendant_user" &&
                            profileData?.ancestor?.salesman_feature &&
                            salesmenPrivilages?.can_read) ||
                          (userType !== "descendant_user" &&
                            profileData?.salesman_feature)
                        }
                      >
                        <AssignedActivityGroupsPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    exact
                    path="/salesmen/salesmen-list/add-salesman"
                    element={
                      <ProtectedRoute
                        isAllowed={
                          (userType === "descendant_user" &&
                            profileData?.ancestor?.salesman_feature &&
                            salesmenPrivilages?.can_read &&
                            salesmenPrivilages?.can_create) ||
                          (userType !== "descendant_user" &&
                            profileData?.salesman_feature)
                        }
                      >
                        <SalesmenFormPage
                          privilages={salesmenPrivilages}
                          type="add"
                          profileData={profileData}
                        />
                      </ProtectedRoute>
                    }
                  />{" "}
                  <Route
                    exact
                    path="/salesmen/salesmen-list/edit/:id"
                    element={
                      <ProtectedRoute
                        isAllowed={
                          (userType === "descendant_user" &&
                            profileData?.ancestor?.salesman_feature &&
                            salesmenPrivilages?.can_read &&
                            salesmenPrivilages?.can_update) ||
                          (userType !== "descendant_user" &&
                            profileData?.salesman_feature)
                        }
                      >
                        <SalesmenFormPage
                          privilages={salesmenPrivilages}
                          type="edit"
                          profileData={profileData}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    exact
                    path="/salesmen/salesmen-list/edit-password/:id"
                    element={
                      <ProtectedRoute
                        isAllowed={
                          (userType === "descendant_user" &&
                            profileData?.ancestor?.salesman_feature &&
                            salesmenPrivilages?.can_read &&
                            salesmenPrivilages?.can_update) ||
                          (userType !== "descendant_user" &&
                            profileData?.salesman_feature)
                        }
                      >
                        <SalesmenFormPage
                          privilages={salesmenPrivilages}
                          type="edit-password"
                        />
                      </ProtectedRoute>
                    }
                  />
                  {/* Wallet */}
                  <Route
                    exact
                    path="/wallet"
                    element={<WalletPage profileData={profileData} />}
                  />
                  {/* Help Routes */}
                  <Route
                    path="/help"
                    element={<Navigate to="/help/customer-support" replace />}
                  />
                  <Route
                    exact
                    path="/help/customer-support"
                    element={<CustomerSupportPage profileData={profileData} />}
                  />
                  <Route
                    path="/help/report-app-issue"
                    element={<ReportAppIssusePage profileData={profileData} />}
                  />
                  <Route exact path="/help/faq" element={<FaqPage />} />
                </Switch>
              </Grid>
            </MainLayout>
          }
        />
      </Switch>
    </Grid>
  );
};
export default MainTemplate;
