import { Grid } from "@mui/material";
import moment from "moment";
import React from "react";

const PoinstActionsList = ({ pointData ,t}) => {
  return (
    <Grid container className="table-item mb-20px mt-10px" sx={{ px: 2 }}>
      <Grid container item xs={12}>
        <Grid container item xs={6} fontSize="18px" fontWeight="bold">
          {pointData?.extra_data}
        </Grid>
        <Grid
          container 
          item
          xs={6}
          color="#f6b119"
          fontWeight="bold"
          fontSize="18px"
          sx={{ justifyContent: "end" }}
        >
          {pointData?.amount}
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid container item xs={6} fontSize="16px" fontWeight="bold">
          {pointData?.description}
        </Grid>
        <Grid
          container 
          item
          xs={6}
          color="#848383"
          fontSize="16px"
          fontWeight="bold"
          sx={{ justifyContent: "end" }}
        >
          {moment(pointData?.date).format("DD MMM YYYY")}
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid container item xs={6} fontSize="16px" fontWeight="bold">
          {t('points.reservationId')}
        </Grid>
        <Grid
          container 
          item
          xs={6}
          color="#848383"
          fontSize="16px"
          fontWeight="bold"
          sx={{ justifyContent: "end" }}
        >
         {pointData?.record_id}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PoinstActionsList;
