import { Grid, Typography, Divider, Stack, Rating, Box } from "@mui/material";
import { icons } from "../../../../Assets/AssetHelper.js";
import BranchInfoPanel from "../Details/Panels/BranchInfoPanel.jsx";
import Map from "../../Maps/GoogleMap.jsx";
import BoatFeatures from "../Details/Panels/BoatFeatures.jsx";
import ImagesPanel from "../../General/Panels/ImagesPanel.jsx";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import BranchRating from "./Panels/BranchRating.jsx";
import "./BranchDetailsPanel.css";
import Download from "../../../Atoms/SharedComponents/Download.jsx";

const BranchDetailsPanel = (props) => {
  const { branchDetails, t } = props;
  const [search] = useSearchParams();
  let localGovLicense = {
    url: branchDetails?.local_gov_license?.url,
    title: t("branch.governmentalLicense"),
  };

  const [location, setLocation] = useState({
    lat: "",
    lng: "",
  });
  const getCurrentLocation = (lat, lng) => {
    setLocation({
      lat: lat,
      lng: lng,
    });
  };
  return (
    <Grid
      container
      item
      columnSpacing={2}
      lg={11}
      margin="auto"
      paddingTop="20px"
    >
      <Grid columnSpacing={2} item md={8}>
        <BranchRating branchDetails={branchDetails} />
      </Grid>

      <Grid
        columnSpacing={2}
        item
        md={7}
        width="100%"
        px="16px"
        className="branch-details-slider-container"
      >
        <ImagesPanel
          title={
            search?.get("branchType") == "office"
              ? t("branch.diveCenterImages")
              : t("branch.safariBoatImages")
          }
          DetailsImages={branchDetails}
          mediaType="default"
        />

        <Grid container item className="details-panel" flexDirection="column">
          <Typography className="mb-20px" component="h1" width="fit-content">
            {t("branch.license")}
          </Typography>
          <Stack flexDirection="row" flexWrap={"wrap"} gap={2}>
            {localGovLicense?.url && (
              <Download href={localGovLicense?.url}>
                <Stack width="100%" height="200px" gap={1}>
                  <Stack
                    border="1px solid #faa100"
                    gap={1}
                    height="100%"
                    borderRadius="6px"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <img
                      src={icons?.downloadImg}
                      alt="download icon"
                      width="50px"
                    />
                    <Typography color="#faa100">
                      {t("branch.download")}
                    </Typography>
                  </Stack>

                  <Typography
                    textAlign="center"
                    color="#333132"
                    fontSize="14px"
                    fontWeight="bold"
                  >
                    {localGovLicense?.title}
                  </Typography>
                </Stack>
              </Download>
            )}
            {branchDetails?.branch_organizations?.map((val, index) => {
              if (val?.license_url)
                return (
                  <Download href={val?.license_url}>
                    <Stack width="100%" height="200px" gap={1} key={index}>
                      <Stack
                        border="1px solid #faa100"
                        gap={1}
                        height="100%"
                        borderRadius="6px"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <img
                          src={val?.license_url}
                          alt="licenses url"
                          width="100%"
                        />
                      </Stack>
                      <Box>
                        <Typography
                          textAlign="center"
                          color="#333132"
                          fontSize="14px"
                          fontWeight="bold"
                        >
                          {val?.organization?.name}
                        </Typography>
                        <Typography
                          textAlign="center"
                          color="#BEBEBE"
                          fontSize="14px"
                          fontWeight="bold"
                        >
                          {val?.license_number}
                        </Typography>
                      </Box>
                    </Stack>
                  </Download>
                );
            })}
          </Stack>
        </Grid>
      </Grid>
      <Grid item md={5} width="100%" px="16px">
        <BranchInfoPanel
          title={
            search?.get("branchType") == "office"
              ? t("branch.diveCenterInformation")
              : t("branch.SafariBoatInformation")
          }
          type="branch_info"
          branchDetails={branchDetails}
        />
        <Map
          getCurrentLocation={getCurrentLocation}
          lat={branchDetails?.lat}
          lng={branchDetails?.long}
          type="view"
        />
      </Grid>

      <Grid columnSpacing={2} container item lg={12} paddingBottom="20px">
        <Grid item md={4} width="100%" px="16px">
          {branchDetails?.branch_features?.length != 0 && (
            <BoatFeatures boatFeatures={branchDetails?.branch_features} />
          )}
          {branchDetails?.organizations?.length > 0 && (
            <Grid
              container
              item
              className="details-panel"
              display="flex"
              flexDirection="column"
            >
              <Typography
                className="mb-20px"
                width="fit-content"
                component="h1"
              >
                {t("branch.organization")}
              </Typography>
              {branchDetails?.organizations?.map((orgniz, idx) => {
                return (
                  <Typography key={idx} fontSize="18px" color="#333132">
                    {orgniz?.name}
                  </Typography>
                );
              })}
            </Grid>
          )}
        </Grid>

        {branchDetails?.branch_type === "safari_boat" && (
          <Grid item md={7} width="100%" px="16px">
            <BranchInfoPanel
              title={t("branch.SafariBoatInformation")}
              type="boat_info"
              branchDetails={branchDetails}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default withTranslation()(BranchDetailsPanel);
