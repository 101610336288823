import React, { useState } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import CustomPagination from "../../../Atoms/Pagination/Pagination";
import ReservationDetailsModel from "../../Reservations/Models/ReservationDetails.jsx";
import SalesmenReservationListItem from "./SalesmenReservationListItem";
import { withTranslation } from "react-i18next";
import { icons } from "../../../../Assets/AssetHelper.js";

const SalesmenReservationList = ({
  t,
  reservationsList,
  setOpenLoader,
  count,
  page,
  handlePageChange,
  getReservations,
  salesmenReservationsPrivilages,
}) => {
  const [selectedData, setSelectedData] = useState(false);
  const [openReservationDetails, setOpenReservationDetails] = useState(false);

  return (
    <Box width={"100%"}>
      <Stack
        container
        flexDirection="row"
        width="100%"
        className="table-header mb-20px"
      >
        <Grid item md={0.5} textAlign="center" minWidth="61px">
          {t("reservations.id")}
        </Grid>
        <Grid item sm={2} minWidth="140px">
          {t("reservations.customerName")}
        </Grid>
        <Grid item sm={2} minWidth="180px">
          {t("reservations.activityName")}
        </Grid>
        <Grid item sm={2} minWidth="122px">
          {t("salesmen.activityDate")}
        </Grid>
        <Grid item sm={2} minWidth="122px">
          {t("reservations.branch")}
        </Grid>
        <Grid item sm={2} minWidth="183px">
          {t("salesmen.reservationDateTime")}
        </Grid>
        <Grid item sm={2} minWidth="170px">
          {t("salesmen.reservationNumber")}
        </Grid>
        <Grid item sm={2} minWidth="81px">
          {t("reservations.seats")}
        </Grid>
        <Grid item sm={2} minWidth="122px">
          {t("salesmen.discount")}
        </Grid>
        <Grid item sm={2} minWidth="250px">
          {t("salesmen.salesmanName")}
        </Grid>
        <Grid item sm={2} minWidth="122px">
          {t("reservations.totalPrice")}
        </Grid>
        <Grid item sm={2} minWidth="122px">
          {t("reservations.status")}
        </Grid>
        <Grid item sm={2}>
          {t("reservations.action")}
        </Grid>
      </Stack>
      {reservationsList?.length === 0 && (
        <Grid
          item
          xs={12}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <img
            src={icons.InHouseActivity}
            style={{
              margin: "80px 0px 20px",
              width: "70px",
              height: "72px",
            }}
            alt="no in house activities img"
          />
          <Typography
            sx={{ fontWeight: 700, fontSize: "22px", color: "#F6B119" }}
          >
            {t("offer.thereAreNoRecords")}
          </Typography>
        </Grid>
      )}
      {reservationsList?.map((listItem, index) => {
        return (
          <Stack
            flexDirection="row"
            width="100%"
            className="activity-item mb-20px"
            key={index}
            onClick={() =>
              setSelectedData({ listItem: listItem, index: index })
            }
          >
            <SalesmenReservationListItem
              listItem={listItem}
              setOpenReservationDetails={setOpenReservationDetails}
              getReservations={getReservations}
              setOpenLoader={setOpenLoader}
              salesmenReservationsPrivilages={salesmenReservationsPrivilages}
            />
          </Stack>
        );
      })}
      {/* {count > 10 && (
        <Grid container justifyContent="center" mb={7.5} mt={5}>
          <CustomPagination
            count={Math.ceil(count / 10)}
            size="large"
            shape="rounded"
            page={page}
            handleChange={handlePageChange}
            className="pagination"
          />
        </Grid>
      )} */}

      {openReservationDetails && (
        <ReservationDetailsModel
          open={openReservationDetails}
          setOpen={setOpenReservationDetails}
          customerData={selectedData?.listItem?.participants}
          participants={
            selectedData?.listItem?.participants?.related_participants
          }
          isSalesMenReservation={true}
        />
      )}
    </Box>
  );
};

export default withTranslation()(SalesmenReservationList);
