import React from 'react'
import { Button, Modal, Stack, Typography } from '@mui/material';
import { images } from '../../../../Assets/AssetHelper';
import './ReviewModal.css';

function ReviewModal({open, setOpen, handleRequest, logo, text, buttons, hasReject}) {
    const handleClose = () => { 
        setOpen(false);        
    }

  return (
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Stack alignItems='center' justifyContent='center' className='review-modal'>
            <img src={logo || images.ReviewLogo} alt='attention-logo' />

            <Typography component="h1" className='modal-head'> { text[0] } </Typography>
            <Typography className='modal-description' maxWidth={'648px'}> { text[1] } </Typography>
            {/* <Typography className='modal-description' maxWidth={'648px'}> { text[2] } </Typography> */}
            {
                hasReject ? <>
                    <Typography className='modal-description modal-rejection' mb={2}> { text[2] } </Typography>
                    <Typography className='modal-description' maxWidth={'648px'}> { text[3] } </Typography>
                </>: <Typography className='modal-description' maxWidth={'648px'}> { text[2] } </Typography>
            }
            
            <Stack flexDirection='row' alignItems='center' mt={4} gap={3} className='buttons-wrap'>                
                {
                hasReject ? <>
                    <Button className='r-25px' onClick={handleClose}>{buttons[1]}</Button>
                    <Button className='primary r-25px' onClick={handleRequest}>{buttons[0]}</Button>
                </>:<Button className='primary r-25px' onClick={handleRequest}>{buttons[0]}</Button>
                }
            </Stack>
        </Stack>

    </Modal>
  )
}

export default ReviewModal