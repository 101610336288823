import PrivateGuideReservationsList from "../../../Components/Organisms/PrivateGuideReservations/List";

const PrivateGuideReservationsTemplate = (props) => {
  const {
    t,
    activitesList,
    count,
    ordersPrivilages,
    setOpenLoader,
    setFilterObj,
    filterObj,
    joboffersPrivilages,
  } = props;

  return (
    <PrivateGuideReservationsList
      activitesList={activitesList}
      count={count}
      ordersPrivilages={ordersPrivilages}
      setOpenLoader={setOpenLoader}
      setFilterObj={setFilterObj}
      filterObj={filterObj}
      joboffersPrivilages={joboffersPrivilages}
    />
  );
};

export default PrivateGuideReservationsTemplate;
