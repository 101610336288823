import { Grid, Typography } from "@mui/material";
// import AddActivityForm from "../../../Components/Organisms/Activities/AddActivityForm.jsx";
import SalesmanForm from "../../../../Components/Organisms/Salesmen/form/index.jsx";

const SalesmenFormTemplate = (props) => {
  const {
    formik,
    handleSubmit,
    type,
    privilages,
    image,
    uploadImages,
    removeImage,
    getBranches,
    branchesList,
    branchesCount,
    profileData,
    salesmanData
  } = props;

  return (
    <Grid container>
      <Grid container>
        <SalesmanForm
          formik={formik}
          handleSubmit={handleSubmit}
          type={type}
          privilages={privilages}
          image={image}
          uploadImages={uploadImages}
          removeImage={removeImage}
          getBranches={getBranches}
          branchesList={branchesList}
          branchesCount={branchesCount}
          profileData={profileData}
          salesmanData={salesmanData}
        />
      </Grid>
    </Grid>
  );
};

export default SalesmenFormTemplate;
