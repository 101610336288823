import React, { useEffect } from "react";
import ActivityTabs from "../../../Molecules/Activities/ActivityTabs";
import { Grid, Typography } from "@mui/material";
import "./InHouseActivitiesList.css";
import CustomSeparator from "../../../Atoms/BreadCrumb";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import DeleteModal from "../../../Molecules/General/Models/DeleteModal";
import CustomPagination from "../../../Atoms/Pagination/Pagination";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import {
  deleteInHouseActivity,
  updateInHouseActivityAvilabilty,
} from "../../../../Contexts/APIs/Salsemen/InHouseActivities";
import { icons } from "../../../../Assets/AssetHelper";
import SwitcheLabel from "../../../Atoms/Buttons/Switch/SwitchButton";
import { useState } from "react";

const InHouseActivitiesList = (props) => {
  const {
    t,
    inHouseActivitiesList,
    count,
    getInHouseActivities,
    inHouseActivitiesPrivilages,
    setOpenLoader,
    page,
    setPage,
    updatePagination,
    getKeywords,
    setOpen,
    filterObj,
    setFilterObj,
    cancelDur,
    profileData,
    setInHouseActivitiesList,
  } = props;
  const Navigate = useNavigate();
  const userType = localStorage.getItem("userType");
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [isAvilable, setAvailable] = useState([
    {
      active: false,
      index: null,
    },
  ]);
  const [SelectedActivity, setSelectedActivity] = useState(false);

  let [searchParams, setSearchParams] = useSearchParams();
  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");

  useEffect(() => {
    setPage(searchParams.get("page"));
    updatePagination(searchParams.get("page"));
  }, [searchParams.get("page")]);

  useEffect(() => {
    setOpenLoader(true);
    let mappedData = isAvilable;
    inHouseActivitiesList?.map((data, index) => {
      mappedData[index] = { active: data?.is_available, index: index };
    });
    setAvailable(mappedData);
    setTimeout(() => {
      setOpenLoader(false);
    }, [500]);
  }, [inHouseActivitiesList]);

  const handelDeleteActivity = async (id) => {
    setOpenLoader(true);
    const { res, err } = await deleteInHouseActivity(id, {
      inhouse_activity: {
        is_deleted: true,
      },
    });
    if (res) {
      // getInHouseActivities(filterObj);
      setInHouseActivitiesList((prev) =>
        prev?.filter((inhouse) => inhouse?.id !== id)
      );
    }
    setOpenLoader(false);
  };
  const handelInHouseNav = (id) => {
    Navigate(
      `/salesmen/in-house-activities/activity-groups/${id}?page=${pageNumber}`
    );
  };

  const handelNav = (id) => {
    Navigate(`/activities/activity-groups/${id}?page=${pageNumber}`);
  };

  const handelChangeAvilablty = async (val, id, index) => {
    let mappedData = isAvilable;
    let newOperators = inHouseActivitiesList;
    mappedData?.map(() => {
      mappedData[index] = { active: val, index: index };
      newOperators[index] = { ...newOperators[index], is_available: val };
    });

    const { res, err } = await updateInHouseActivityAvilabilty(id, {
      inhouse_activity: {
        is_available: val,
      },
    });
    if (res) {
      setAvailable(mappedData);
    }
  };
  return (
    <>
      <Grid
        container
        className="activities-list-container house-activities-list-container"
      >
        <Grid container m={"0px 0px 10px"}>
          <CustomSeparator
            breadcrumbs={[
              <Link
                style={{ color: "#bebebe", textDecoration: "none" }}
                underline="hover"
                key="2"
                color="inherit"
                to={`/salesmen?page=${pageNumber}`}
              >
                {t("salesmen.salesmen")}
              </Link>,
              <Typography
                key="3"
                style={{ color: "#bebebe", textDecoration: "none" }}
              >
                {t("salesmen.inHouseActivity")}
              </Typography>,
            ]}
          />
        </Grid>
        <ActivityTabs
          getKeywords={getKeywords}
          activitiesNo={inHouseActivitiesList?.length}
          activitiesPrivilages={inHouseActivitiesPrivilages}
          isInHouseActivity={true}
          filterObj={filterObj}
          setFilterObj={setFilterObj}
          profileData={profileData}
        />
        {userType === "dive_center" && (
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="row"
            alignItems="center"
            mt={2}
            mb={4}
          >
            <Typography
              sx={{ fontWeight: 400, fontSize: "18px", color: "#bebebe" }}
            >
              Cancelation Duration in Hour :{" "}
            </Typography>
            <Typography
              sx={{ fontWeight: 400, fontSize: "18px", color: "#333132" }}
            >
              {" "}
              {cancelDur} Hour
            </Typography>
            <button
              style={{
                color: "#333132",
                backgroundColor: "transparent",
                border: "none",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => setOpen(true)}
            >
              {cancelDur === 0 ? "add" : "edit"}
              {/* add */}
            </button>
          </Grid>
        )}

        <Grid container>
          <Grid container className="table-header mb-20px">
            <Grid md={1} sm={2} textAlign="center">
              {t("users.no")}
            </Grid>
            <Grid md={1.5} sm={2.5}>
              {t("activity.name")}
            </Grid>
            <Grid md={1.5} sm={2.5}>
              {t("activity.branch")}
            </Grid>
            <Grid md={1.5} sm={2.5}>
              {t("activity.price")}
            </Grid>
            <Grid md={4} sm={7}>
              {t("reservations.activityGroups")}
            </Grid>
            <Grid md={2.5} sm={5} style={{ padding: "0 20px" }}>
              {t("activity.action")}
            </Grid>
          </Grid>

          {inHouseActivitiesList?.length === 0 ? (
            <Grid
              item
              xs={12}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <img
                src={icons.InHouseActivity}
                style={{
                  margin: "80px 0px 20px",
                  width: "70px",
                  height: "72px",
                }}
                alt="no in house activities img"
              />
              <Typography
                sx={{ fontWeight: 700, fontSize: "22px", color: "#F6B119" }}
              >
                No House Activities Added yet
              </Typography>
            </Grid>
          ) : null}

          <Grid container className="table-items-container">
            {inHouseActivitiesList?.map((activityItem, index) => (
              <Grid
                container
                lg={12}
                className="activity-item mb-20px"
                key={index}
                onClick={() =>
                  setSelectedActivity({
                    activity: activityItem,
                    index: index,
                  })
                }
                sx={{
                  cursor: "pointer",
                }}
              >
                <Grid
                  md={1}
                  sm={2}
                  textAlign="center"
                  onClick={() => handelNav(activityItem?.id)}
                >
                  {activityItem?.id}
                </Grid>
                <Grid
                  md={1.5}
                  sm={2.5}
                  style={{
                    fontWeight: "700",
                  }}
                  sx={{
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    Navigate(
                      `/salesmen/in-house-activities/details/${activityItem?.id}?page=${pageNumber}`
                    )
                  }
                >
                  {activityItem?.name || "-"}
                </Grid>

                <Grid
                  md={1.5}
                  sm={2.5}
                  onClick={() => handelNav(activityItem?.id)}
                >
                  "{activityItem?.branch?.name}"
                </Grid>

                <Grid
                  md={1.5}
                  sm={2.5}
                  onClick={() => handelNav(activityItem?.id)}
                >
                  {activityItem?.price}
                  {activityItem?.currency?.lookup_key}
                </Grid>

                <Grid
                  md={4}
                  sm={7}
                  sx={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontWeight: "700 !important",
                  }}
                  onClick={() =>
                    activityItem?.number_of_groups > 0 &&
                    handelInHouseNav(activityItem?.id)
                  }
                >
                  {activityItem?.number_of_groups}
                </Grid>
                <Grid
                  container
                  md={2.5}
                  sm={5}
                  alignItems="center"
                  justifyContent="start"
                >
                  <Grid xs={3}>
                    {userType === "descendant_user" &&
                      inHouseActivitiesPrivilages?.can_update && (
                        <SwitcheLabel
                          checked={
                            isAvilable[index]?.index == index &&
                            isAvilable[index]?.active
                          }
                          style={{ margin: "auto", padding: "12px" }}
                          change={(data) =>
                            handelChangeAvilablty(data, activityItem?.id, index)
                          }
                        />
                      )}
                    {userType !== "descendant_user" && (
                      <SwitcheLabel
                        checked={
                          isAvilable[index]?.index == index &&
                          isAvilable[index]?.active
                        }
                        style={{ margin: "auto", padding: "12px" }}
                        change={(data) =>
                          handelChangeAvilablty(data, activityItem?.id, index)
                        }
                      />
                    )}
                  </Grid>
                  <Grid xs={3} textAlign="center">
                    {userType === "descendant_user" &&
                      inHouseActivitiesPrivilages?.can_update && (
                        <PrimaryButton
                          btnType="icon"
                          startIcon={
                            <img width="35" src={icons.EditIcon} alt="edit" />
                          }
                          click={() =>
                            Navigate(
                              `/salesmen/in-house-activities/edit-activity/${activityItem?.id}?page=${pageNumber}`
                            )
                          }
                        />
                      )}
                    {userType !== "descendant_user" && (
                      <PrimaryButton
                        btnType="icon"
                        startIcon={
                          <img width="35" src={icons.EditIcon} alt="edit" />
                        }
                        click={() =>
                          Navigate(
                            `/salesmen/in-house-activities/edit-activity/${activityItem?.id}?page=${pageNumber}`
                          )
                        }
                      />
                    )}
                  </Grid>

                  {userType === "descendant_user" &&
                    inHouseActivitiesPrivilages?.can_delete && (
                      <PrimaryButton
                        btnType="icon"
                        startIcon={
                          <img width="35" src={icons.DeleteIcon} alt="delete" />
                        }
                        click={() => setOpenDeleteModel(true)}
                      />
                    )}
                  {userType !== "descendant_user" && (
                    <PrimaryButton
                      btnType="icon"
                      startIcon={
                        <img width="35" src={icons.DeleteIcon} alt="delete" />
                      }
                      click={() => setOpenDeleteModel(true)}
                    />
                  )}
                </Grid>
              </Grid>
            ))}
            {count > 10 && (
              <Grid container justifyContent="center" mb={7.5} mt={5}>
                <CustomPagination
                  count={Math.ceil(count / 10)}
                  size="large"
                  shape="rounded"
                  page={page}
                  className="pagination"
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        {openDeleteModel && (
          <DeleteModal
            open={openDeleteModel}
            setOpen={setOpenDeleteModel}
            handelDeleteRequest={() => {
              handelDeleteActivity(SelectedActivity?.activity?.id);
              setOpenDeleteModel(false);
            }}
            // text={[t("activity.deleteActivity"), t("activity.deleteActivityMsg")]}
            text={[
              `${t("users.delete")} ${SelectedActivity?.activity?.name}`,
              t("activity.deleteActivityMsg"),
            ]}
            buttonText={[t("offer.cancel"), t("offer.delete")]}
          />
        )}
      </Grid>
    </>
  );
};

export default withTranslation()(InHouseActivitiesList);
