import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { Button, Modal, Rating, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Badges } from "../../../../Contexts/APIs/Lookups/badges";
import { createReview } from "../../../../Contexts/APIs/Reviews/Reviews";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput";

const RateFreelancersModel = ({
  setOffers,
  open,
  setOpen,
  data,
  getJobOffers,
  filterObj,
  offers
}) => {
  const [badgesData, setBagesData] = useState([]);
  const lang = localStorage.getItem("lang");
  const [reviewdata, setReviewData] = useState({
    dive_expert_rating: 0,
    dive_expert_comment: "",
    badge_id: 0,
  });
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 607,
    height: "90vh",
    overflow: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "25px",
    p: 4,
  };
  const StyledRating = styled(Rating)({
    width: "fit-content",
    "& .MuiRating-iconFilled": {
      color: "#3ed181",
    },
    "& .MuiRating-iconHover": {
      color: "#3ed181",
    },
  });

  const handleClose = () => setOpen(false);
  const getBadges = async () => {
    let { res, err } = await Badges();
    if (res) {
      setBagesData(res?.data?.badges);
    }
  };

  useEffect(() => {
    getBadges();
  }, []);

  const handelReviewRequest = async () => {
    let { res, err } = await createReview(data, { review: reviewdata });
    if (res) {
      handleClose();
      setOffers((prevOffers) =>
        prevOffers.map((offer) => 
          offer.id === res?.data?.review?.id ? { ...offer, ...res?.data?.review } : offer
        )
      );
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack sx={style} gap={5} className="RateFreelancersModel">
        <Stack>
          <Typography fontSize="25px" color="#f6b119" fontWeight="bold">
            Rate freelancer/s
          </Typography>
          <StyledRating
            name="customized-color"
            defaultValue={2}
            value={reviewdata?.dive_expert_rating}
            onChange={(event, newValue) => {
              setReviewData({ ...reviewdata, dive_expert_rating: newValue });
            }}
            icon={
              <CircleIcon fontSize="inherit" style={{ fontSize: "62px" }} />
            }
            emptyIcon={
              <CircleOutlinedIcon
                fontSize="inherit"
                style={{ fontSize: "62px", color: "#3ed181" }}
              />
            }
          />
        </Stack>

        <Stack>
          <Typography fontSize="25px" color="#f6b119" fontWeight="bold">
            Select badge
          </Typography>
          <Stack
            flexDirection="row"
            gap={4}
            alignItems="flex-start"
            flexWrap="wrap"
          >
            {badgesData?.map((val, index) => {
              return (
                <Stack
                  key={index}
                  style={{ cursor: "pointer", padding: "5px" }}
                  borderRadius="8px"
                  border={
                    reviewdata?.badge_id == val?.id ? "solid 1px #f6b119" : "0"
                  }
                  alignItems="center"
                  justifyContent="center"
                  onClick={() =>
                    setReviewData({ ...reviewdata, badge_id: val?.id })
                  }
                >
                  <img src={val?.image} width="83px" height="83px" />
                  <Typography width="50px" textAlign="center">
                    {val?.localized_data[lang]?.name}
                  </Typography>
                </Stack>
              );
            })}
          </Stack>
        </Stack>

        <TextFieldInput
          name={"description"}
          id={"description"}
          placeholder="Leave comment"
          className="textarea"
          multiline={true}
          rows={5}
          inputProps={{
            style: {
              position: "absolute",
              top: "10px",
              width: "95%",
            },
          }}
          value={reviewdata?.dive_expert_comment}
          onChange={(e) =>
            setReviewData({
              ...reviewdata,
              dive_expert_comment: e.target.value,
            })
          }
        />
        <Stack
          flexDirection="row"
          alignSelf="center"
          alignItems="center"
          mt={4}
        >
          <Button
            style={{ width: "150px", color: "black" }}
            onClick={handleClose}
          >
            Cancel
          </Button>

          <Button
            style={{ width: "150px" }}
            className="primary r-25px"
            onClick={handelReviewRequest}
          >
            Done
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default RateFreelancersModel;
