import { Grid, Typography, Divider } from "@mui/material";
import SelectBox from "../../../Atoms/Select/Select.jsx";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput.jsx";
import "./Form.css";
import { withTranslation } from "react-i18next";
import { Fragment, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import SearchableSelect from "../../../Atoms/Select/SearchableSelect.jsx";

const ActivityInfo = (props) => {
  const {
    getBranchOrganizations,
    setOrganizationsList,
    setCertificatesList,
    branchesList,
    formik,
    currencies,
    supportedLanguages,
    supportLang,
    getBranches,
    getCurrentLocation,
    isInHouseActivity,
    t,
    activityDetails,
    branchesCount,
    profileData,
    formType,
    isAssignedToOneBranch,
  } = props;
  const [searchParams] = useSearchParams(),
    activityType = searchParams.get("type");
  useEffect(() => {
    if (isAssignedToOneBranch && formType === "add") {
      formik.setValues({
        ...formik.values,
        branch_id: profileData?.branch?.id,
        branch_name: profileData?.branch?.name,
      });
      isInHouseActivity === true &&
        getBranchOrganizations(profileData?.branch?.id);
      getCurrentLocation(profileData?.branch?.lat, profileData?.branch?.long);
    }
  }, [isAssignedToOneBranch, formType, profileData]);
  return (
    <Grid container className="activity-form">
      <Grid container>
        <Typography
          component="h1"
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            borderBottom: "2px solid var(--primary-color)",
          }}
          className="mb-20px"
        >
          {t("activity.activityInformation")}
        </Typography>
      </Grid>
      <Grid container className="mb-20px" gap={1} alignItems="baseline">
        <Grid item sm={5.8}>
          <SearchableSelect
            name="branch_name"
            options={branchesList}
            getList={getBranches}
            handelMenuOpen={getBranches}
            count={branchesCount}
            type="branch"
            defaultValues={activityDetails?.branch}
            label={t("branch.branchName")}
            className="searchable-select searchable-select-branches"
            disabled={isAssignedToOneBranch}
            value={{
              value:
                isAssignedToOneBranch && formType == "add"
                  ? profileData?.branch?.id
                  : formik?.values?.branch_id,
              label:
                isAssignedToOneBranch && formType == "add"
                  ? profileData?.branch?.name
                  : formik?.values?.branch_name,
            }}
            error={
              Boolean(formik.errors.branch_name)
            }
            helperText={formik.errors.branch_name}
            onChange={(val) => {
              formik.setValues({
                ...formik?.values,
                branch_id: val?.value,
                branch_name: val?.label,
                organization: "",
                organization_name: "",
                certificates: "",
                certificates_name: "",
              });
              isInHouseActivity === true && getBranchOrganizations(val?.value);
              const currentBranch = branchesList.find(
                (item) => item.id === val?.value
              );
              console.log(currentBranch);
              getCurrentLocation(currentBranch.lat, currentBranch.long);
            }}
            onClear={() => {
              formik.setValues({
                ...formik?.values,
                branch_id: "",
                branch_name: "",
                organization: "",
                organization_name: "",
                certificates: "",
                certificates_name: "",
              });
              if (isInHouseActivity === true) {
                getBranchOrganizations(null);
              } else {
                setOrganizationsList([]);
                setCertificatesList([]);
              }
              const currentBranch = branchesList.find(
                (item) => item.id === null
              );
              getCurrentLocation(currentBranch?.lat, currentBranch?.long);
            }}
          />
        </Grid>
        <Grid item sm={5.8}>
          {isInHouseActivity === true ? (
            <Grid item sm={12}>
              <TextFieldInput
                name={"name"}
                id={"name"}
                placeholder={
                  activityType == 1
                    ? t("activity.courseName")
                    : t("activity.tripName")
                }
                className="login-input"
                type="text"
                value={formik?.values.name}
                error={Boolean(formik.errors.name)}
                helperText={formik.errors.name}
                onChange={formik.handleChange}
              />
            </Grid>
          ) : (
            supportedLanguages.map((lang, index) => (
              <Fragment key={index}>
                {supportLang && supportLang === lang.id && (
                  <Grid item sm={12} key={index}>
                    <TextFieldInput
                      name={`name_${lang?.id}`}
                      id={`name_${lang?.id}`}
                      placeholder={
                        activityType == 1
                          ? t("activity.courseName")
                          : t("activity.tripName")
                      }
                      className="login-input"
                      type="text"
                      value={formik?.values[`name_${lang?.id}`]}
                      error={
                        
                        Boolean(formik.errors[`name_${lang?.id}`])
                      }
                      helperText={
                        
                        formik.errors[`name_${lang?.id}`]
                      }
                      onChange={formik.handleChange}
                    />
                  </Grid>
                )}
              </Fragment>
            ))
          )}
        </Grid>
      </Grid>
      {isInHouseActivity !== true ? (
        <>
          <Grid container className="mb-20px" gap={1}>
            <Grid item sm={5.8}>
              <TextFieldInput
                name="no_dives"
                id="no_dives"
                placeholder={t("activity.noOfDives")}
                className="login-input"
                type="number"
                value={formik.values.no_dives}
                error={
                  Boolean(formik.errors.no_dives)
                }
                helperText={formik.errors.no_dives}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid container item sm={5.8} alignItems="flex-start">
              <Grid item xs={8}>
                <TextFieldInput
                  name="price"
                  id="price"
                  placeholder={t("activity.price")}
                  className="login-input"
                  type="number"
                  value={formik.values.price}
                  error={Boolean(formik.errors.price)}
                  helperText={formik.errors.price}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={0.5}></Grid>
              <Grid item xs={3.5} padding={"8px 0px"}>
                <SelectBox
                  id="currency"
                  name="currency"
                  items={currencies}
                  title={t("activity.currency")}
                  className="select"
                  value={formik.values.currency}
                  error={
                    Boolean(formik.errors.currency)
                  }
                  helperText={formik.errors.currency}
                  onchange={formik.handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container className="mb-20px">
            <Grid item sm={5.8}>
              <TextFieldInput
                name="number_of_tickets"
                id="number_of_tickets"
                placeholder={t("activity.availableSpots")}
                className="login-input"
                type="text"
                value={formik.values.number_of_tickets}
                error={
                  
                  Boolean(formik.errors.number_of_tickets)
                }
                helperText={
                  
                  formik.errors.number_of_tickets
                }
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid container className="mb-20px" gap={1}>
          <Grid container item sm={5.8} alignItems="center">
            <Grid item xs={8}>
              <TextFieldInput
                name="price"
                id="price"
                placeholder={t("activity.price")}
                className="login-input"
                type="number"
                value={formik.values.price}
                error={Boolean(formik.errors.price)}
                helperText={formik.errors.price}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={0.5}></Grid>
            <Grid item xs={3.5}>
              <SelectBox
                id="currency"
                name="currency"
                items={currencies}
                title={t("activity.currency")}
                className="select"
                value={formik.values.currency}
                error={
                  Boolean(formik.errors.currency)
                }
                helperText={formik.errors.currency}
                onchange={formik.handleChange}
              />
            </Grid>
          </Grid>
          <Grid item sm={5.8}>
            <TextFieldInput
              name="number_of_tickets"
              id="number_of_tickets"
              placeholder={t("activity.availableSpots")}
              className="login-input"
              type="text"
              value={formik.values.number_of_tickets}
              error={
                
                Boolean(formik.errors.number_of_tickets)
              }
              helperText={
                
                formik.errors.number_of_tickets
              }
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default withTranslation()(ActivityInfo);
