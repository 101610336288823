import { Grid, Typography, Divider } from "@mui/material";
import SelectBox from "../../Atoms/Select/Select.jsx";
import { useState } from "react";
import { GroupsStatus } from "../../../Contexts/APIs/Helper/Helper.js";
import ReplayIcon from '@mui/icons-material/Replay';
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import { withTranslation } from "react-i18next";

const FreelancersHeader = (props) => {
    const { updateGroupStatus, t } = props;

    const [groupStatus, setGroupStatus] = useState("");

    const getGroupStatus = (e) => {
        setGroupStatus(e.target.value);
        updateGroupStatus(e.target.value);
    };

    return (
        <Grid container>
            <Grid container md={12}>
                <Grid container sm={12} className="mb-20px">
                    <Grid>
                        <Typography component="h1" style={{ fontWeight: "bold", fontSize: "30px" }}>
                            {t("reservations.freelancersRequests")}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container sm={8} >
                    <Grid xs={3}>
                        <SelectBox
                            id="status"
                            name="status"
                            items={GroupsStatus}
                            title={t("reservations.country")}
                            className="select"
                            value={groupStatus}
                            style={{ width: "90%" }}
                            onchange={(e) => getGroupStatus(e)}
                        />
                    </Grid>
                    <Grid xs={3}>
                        <SelectBox
                            id="status"
                            name="status"
                            items={GroupsStatus}
                            title={t("reservations.province")}
                            className="select"
                            value={groupStatus}
                            style={{ width: "90%" }}
                            onchange={(e) => getGroupStatus(e)}
                        />
                    </Grid>
                    <Grid xs={3}>
                        <SelectBox
                            id="status"
                            name="status"
                            items={GroupsStatus}
                            title={t("reservations.type")}
                            className="select"
                            value={groupStatus}
                            style={{ width: "90%" }}
                            onchange={(e) => getGroupStatus(e)}
                        />
                    </Grid>
                    <Grid xs={3}>
                        <PrimaryButton
                            startIcon={<ReplayIcon />}
                            text={t("reservations.clear")}
                            classBtn="secoundry clear"
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Divider style={{ width: "100%", margin: "20px 0" }} />
        </Grid>
    )
}

export default withTranslation()(FreelancersHeader)