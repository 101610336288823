import React from "react";

export default function Download({ children, href }) {
  return (
    <a
      href={href}
      download
      style={{
        textDecoration: "none",
        height: "200px",
        width: "158px",
      }}
    >
      {children}
    </a>
  );
}
    