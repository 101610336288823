import { Grid, Typography, Avatar } from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import CustomSeparator from "../../Atoms/BreadCrumb";
import { withTranslation } from "react-i18next";

const ActivityGroupInfo = (props) => {
  const {
    activityInfo,
    t,
    isInHouseActivity,
    isAssignedActivity,
    assignedActivityName,
    salesmanId,
    salesmanName,
  } = props;
  let [searchParams, setSearchParams] = useSearchParams();
  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");

  return (
    <Grid item xs={12} gap={2}>
      <Grid container m={"0px 0px 10px"}>
        {isInHouseActivity === true ? (
          <CustomSeparator
            breadcrumbs={[
              <Link
                style={{ color: "#bebebe", textDecoration: "none" }}
                underline="hover"
                key="2"
                color="inherit"
                to={`/salesmen?page=${pageNumber}`}
              >
                {t("salesmen.salesmen")}
              </Link>,
              <Link
                style={{
                  color: "#bebebe",
                  textDecoration: "none",
                  textTransform: "capitalize",
                }}
                underline="hover"
                key="2"
                color="inherit"
                to={`/salesmen/in-house-activities?page=${pageNumber}`}
              >
                {t("salesmen.inHouseActivity")}
              </Link>,
              <Link
                style={{
                  color: "#bebebe",
                  textDecoration: "none",
                  textTransform: "capitalize",
                }}
                underline="hover"
                key="2"
                color="inherit"
                to={`/salesmen/in-house-activities/details/${activityInfo?.id}?page=${pageNumber}`}
              >
                {activityInfo?.name}
              </Link>,

              <Typography
                key="3"
                style={{
                  color: "#bebebe",
                  textDecoration: "none",
                  textTransform: "capitalize",
                }}
              >
                {t("salesmen.activityGroup")}
              </Typography>,
            ]}
          />
        ) : isAssignedActivity === true ? (
          <CustomSeparator
            breadcrumbs={[
              <Link
                style={{ color: "#bebebe", textDecoration: "none" }}
                underline="hover"
                key="1"
                color="inherit"
                to={`/salesmen?page=${pageNumber}`}
              >
                {t("salesmen.salesmen")}
              </Link>,
              <Link
                style={{ color: "#bebebe", textDecoration: "none" }}
                underline="hover"
                key="2"
                color="inherit"
                to={`/salesmen/salesmen-list?page=${pageNumber}`}
              >
                {t("salesmen.salesmenList")}
              </Link>,
              // <Link
              //     style={{ color: '#bebebe', textDecoration: 'none' }}
              //     underline="hover"
              //     key="3"
              //     color="inherit"
              //     to={`/salesmen/salesmen-list/${salesmanId}`}
              // >
              //     {salesmanName}
              // </Link>,
              <Typography
                key="3"
                style={{ color: "#bebebe", textDecoration: "none" }}
              >
                {salesmanName}
              </Typography>,
              <Link
                style={{ color: "#bebebe", textDecoration: "none" }}
                underline="hover"
                key="4"
                color="inherit"
                to={`/salesmen/salesmen-list/${salesmanId}/assigned-activities?page=${pageNumber}`}
                state={{ salesmanId: salesmanId, salesmanName: salesmanName }}
              >
                {t("salesmen.assignedActivities")}
              </Link>,
              <Typography
                key="5"
                style={{ color: "#bebebe", textDecoration: "none" }}
              >
                {assignedActivityName}
              </Typography>,
            ]}
          />
        ) : (
          <CustomSeparator
            breadcrumbs={[
              <Link
                style={{ color: "#bebebe", textDecoration: "none" }}
                underline="hover"
                key="2"
                color="inherit"
                to={`/activities?page=${pageNumber}`}
              >
                {t("activity.activities")}
              </Link>,
              <Typography
                key="3"
                style={{ color: "#bebebe", textDecoration: "none" }}
              >
                {activityInfo?.name}
              </Typography>,
            ]}
          />
        )}
      </Grid>

      {isInHouseActivity !== true ? (
        <Grid container className="activity-info-panel mb-30px" mt={2}>
          <Grid item container lg={6} gap={2}>
            <Grid item md={2}>
              {activityInfo?.logo === "url" ||
              activityInfo?.logo === "" ||
              activityInfo?.logo === null ? (
                <Avatar
                  src=""
                  height={"80px"}
                  width={"80px"}
                  sx={{
                    width: "80px",
                    height: "80px",
                    bgcolor: "grey",
                    borderRadius: "10px",
                  }}
                />
              ) : (
                <img
                  src={activityInfo?.logo}
                  style={{
                    width: "80px",
                    height: "80px",
                    borderRadius: "10px",
                  }}
                />
              )}
            </Grid>
            <Grid item md={9} gap={1}>
              <Typography
                className="mb-10px"
                component="h1"
                style={{ fontSize: "25px", fontWeight: "bold" }}
              >
                {activityInfo?.name}
              </Typography>
              <Typography
                component="p"
                style={{ fontSize: "17px", color: "var(--primary-color)" }}
              >
                {activityInfo?.price} {activityInfo?.currency?.lookup_key}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default withTranslation()(ActivityGroupInfo);
