import { Box, Chip, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import ClearIcon from "@mui/icons-material/Clear";
import "./SearchableSelect.css";

const SearchableSelect = ({
  options,
  onChange,
  onClear,
  value,
  label,
  className,
  error,
  id,
  name,
  handelMenuOpen,
  helperText,
  index,
  moreFilterData,
  defaultValues,
  getList,
  count = 10,
  type,
  disabled = false,
}) => {
  const colourStyles = {
    option: (styles) => {
      return {
        ...styles,
        color: "#333132",
      };
    },
    menuList: (styles) => {
      return {
        ...styles,
        background: "#ffff",
      };
    },
  };
  const [searchFilterTimeout, setSearchFilterTimeout] = useState(null);
  // used inputIndicator to appear clear indicator icon where typing in search input
  const [inputIndicator, setInputIndicator] = useState(null);

  const handleInputChange = (value, event) => {
    if (searchFilterTimeout) {
      clearTimeout(searchFilterTimeout);
    }
    if (value?.length > 0) {
      setInputIndicator(true);
    } else {
      setInputIndicator(false);
    }
    const timeout = setTimeout(() => {
      if (event?.action === "input-change")
        getList({ keyword: value, page_number: 1, ...moreFilterData });
    }, 700);
    setSearchFilterTimeout(timeout);
  };
  let filteredItems = options?.filter((data) => data?.id != defaultValues?.id);
  let concatedArr = defaultValues
    ? options
    : filteredItems?.concat(defaultValues);

  const handelOptions = concatedArr?.map((val) => {
    return {
      label: val?.name,
      value: val?.id,
    };
  });

  const ClearIndicator = () => {
    return (
      <IconButton
        onClick={onClear}
        style={{
          position: "absolute",
          top: "calc(50% - 10px)",
          right: "35px",
          padding: "0",
          zIndex: "99",
          width: "20px",
        }}
      >
        <ClearIcon
          sx={{
            color: "hsl(0, 0%, 80%) !important",
            fontSize: "20px !important",
            "&:hover": { color: "hsl(0, 0%, 60%) !important" },
            "&:focus": { color: "hsl(0, 0%, 20%) !important" },
          }}
        />
      </IconButton>
    );
  };
  const SelectLabel = () => {
    return (
      <Chip
        label={label}
        style={{
          position: "absolute",
          top: "-12px",
          fontSize: "13px",
          margin: "0px 20px",
          background: "#fff",
          zIndex: "99",
          padding: "0px 10px",
          color: "black"
        }}
      />
    );
  };
  const handleMenuScrollToBottom = () => {
    if (options?.length < count) {
      getList({
        ...moreFilterData,
        page_number: Math?.floor(options?.length / 10) + 1,
      });
    }
  };
  return (
    <>
      <Box
        sx={{
          p: 0,
          m: 0,
          boxShadow: "none",
          border: "none",
          position: "relative",
          width:'100%'
        }}
      >
        <Select
          id={id}
          name={name}
          onMenuScrollToBottom={handleMenuScrollToBottom}
          className={`${className} ${error && "select-error"}`}
          options={handelOptions}
          onChange={onChange}
          value={value?.label ? value : null}
          placeholder={label}
          onInputChange={(value, event) => {
            handleInputChange(value, event);
          }}
          onMenuOpen={() => handelMenuOpen && handelMenuOpen()}
          styles={colourStyles}
          isDisabled={disabled}
          
        />
        {(value?.label && !disabled) || inputIndicator ? (
          <ClearIndicator />
        ) : null}
        {value?.label ? <SelectLabel /> : null}
      </Box>
      {error && (
        <Typography
          component={"p"}
          className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1sb723f-MuiFormHelperText-root"
        >
          {helperText ? helperText : `${label} is Required`}
        </Typography>
      )}
    </>
  );
};
export default SearchableSelect;
