  import { Box, Stack, Typography } from "@mui/material";
  import React from "react";
  import {
    Bar,
    BarChart,
    CartesianGrid,
    ResponsiveContainer,
    XAxis,
    YAxis,
    Tooltip,
    Rectangle,
  } from "recharts";

  const Charts = ({ data, ticks, title, total, height, chartBarDataKey }) => {
    return (
      <Box
        sx={{
          boxShadow: "0px 2px 15px 0px #0000001A",
          borderRadius: "20px",
          p: "40px 10px",
        }}
      >
        <Typography
          sx={{
            fontSize: "22px",
            fontWeight: 700,
            mx: 3,
            pb: 1,
            borderBottom: "3px solid #F6B119",
            width: "fit-content",
            mb: "20px",
          }}
        >
          {title}
        </Typography>
        <Stack gap={3} direction={"row"}>
          <Stack sx={{ width: total ? "85%" : "100%", height: height || 450 }}>
            <ResponsiveContainer>
              <BarChart data={data}>
                <CartesianGrid strokeDasharray="0 0" />
                <XAxis dataKey="name" />
                <YAxis ticks={ticks} />
                <Tooltip />
                <Bar
                  dataKey={chartBarDataKey || "graphPoints"}
                  fill="#F6B119"
                  barSize={20}
                  radius={[10, 10, 0, 0]}
                  // activeBar={<Rectangle fill="#F6B119" stroke="red" />} 
                />
              </BarChart>
            </ResponsiveContainer>
          </Stack>
          {total && (
            <Stack
              alignSelf={"flex-end"}
              justifySelf={"flex-start"}
              sx={{ mb: "40px" }}
            >
              <Typography sx={{ color: "#AAAAAA" }}>Total</Typography>
              <Typography
                sx={{ color: "#333132", fontWeight: 700, fontSize: "18px" }}
              >
                {total}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Box>
    );
  };

  export default Charts;
