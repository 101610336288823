import { Grid } from '@mui/material'
import React from 'react'
import { useContext } from 'react'
import { userDataReport } from '../../../../Pages/Main/Help/ReportAppIssuse/ReportAppIssuse.page'
import FormHead from '../../../Atoms/FormHead/FormHead'
import HelpForms from '../../Forms/HelpForms'
import { withTranslation } from 'react-i18next'

function ReportAppIssuseWrap({t}) {
  return (
    <Grid container md={8} className='customer-support-wrap'
      sx={{ mt: '1rem', mb: '2rem', p: '20.5px 212px 37px 30px', borderRadius: "10px", boxShadow: "0 2px 15px 0 rgba(0, 0, 0, 0.1)" }}
    >
      <FormHead text={t("help.reportAppTitle")} />
      
      <HelpForms targetPage={'ReportAppIssuse'} userCData={useContext(userDataReport)} />
    </Grid>
  )
}

export default withTranslation()(ReportAppIssuseWrap)