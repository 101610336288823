import BranchesTemplate from "../../../Templates/Main/Branches/Branches.template.jsx";
import { getBranchesList } from "../../../Contexts/APIs/Branches/Branches.js";
import { useState, useEffect } from "react";
import Loader from "../../../Components/Atoms/Loader/Loader.js";
import { useSearchParams } from "react-router-dom";

const BranchesPage = (props) => {
  const { t, branchesPrivilages } = props;
  const [branchesList, setbranchesList] = useState([]);
  const [count, setCount] = useState(0);
  const [openLoader, setOpenLoader] = useState(false);
  const [filterObj, setFilterObj] = useState(null);
  const [page, setPage] = useState(1);
  let [searchParams, setSearchParams] = useSearchParams();
  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  const getBranches = async (filterObj) => {
    setOpenLoader(true);
    const userId =
      localStorage.getItem("userType") !== "descendant_user"
        ? localStorage.getItem("userId")
        : localStorage.getItem("ancestorId");
    const defaultFilterObject = {
      page_size: 10,
      page_number: pageNumber,
      branch_type: "",
      keyword: "",
      sort_by: "id",
    };
    const { res, err } = await getBranchesList({
      ...defaultFilterObject,
      ...filterObj,
      dive_center_id: userId,
    });
    if (res) {
      setCount(res?.extra?.total_count);
      setbranchesList(res?.data?.branches);
    }
    setOpenLoader(false);
  };

  const updatePagination = (page) => {

    setPage(page);
    setFilterObj((data) => {
      return { ...data, page_number: page };
    });
  };
  useEffect(() => {
    const timeId = setTimeout(() => {
      getBranches(filterObj);
    }, 500);
    return () => clearTimeout(timeId);
  }, [filterObj]);

  return (
    <>
      <Loader open={openLoader} />
      <BranchesTemplate
        branchesList={branchesList}
        updatePagination={updatePagination}
        page={page}
        count={count}
        branchesPrivilages={branchesPrivilages}
        setFilterObj={setFilterObj}
        filterObj={filterObj}
      />
    </>
  );
};

export default BranchesPage;
