import React, { useEffect, useState } from "react";
import SearchableSelect from "../../Atoms/Select/SearchableSelect";
import { withTranslation } from "react-i18next";
import { getBranchesList } from "../../../Contexts/APIs/Branches/Branches";
import "./DiveCenterStatistics.css";

const BranchesFilter = ({
  t,
  setFilterObj,
  filterObj,
  profileData,
  hideOptionAll,
  handleChange,
  defaultValue,
  handleClear,
}) => {
  const [branchesList, setBranchesList] = useState([]);
  const [branchesCount, setBranchesCount] = useState(10);
  const [branch, setBranch] = useState({});
  const userId = localStorage.getItem("userId");
  const isAssignedToOneBranch = profileData?.branch?.name;

  const getBranches = async (filter) => {
    const filterData = {
      page_size: "10",
      page_number: 1,
      dive_center_id: userId,
      ...filter,
    };
    const { res, err } = await getBranchesList({
      ...filterData,
    });
    if (res) {
      filterData?.page_number == 1
        ? setBranchesList(res?.data?.branches)
        : setBranchesList((s) => [...s, ...res?.data?.branches]);
      setBranchesCount(res?.extra?.total_count);
    }
  };
  const updateBranchesList = (name, value) => {
    filterObj?.[name] != value && setFilterObj({ ...filterObj, [name]: value });
  };

  const updateBranch = (branch_val) => {
    setBranch({ label: branch_val?.label, id: branch_val?.value });
    handleChange
      ? handleChange(branch_val)
      : updateBranchesList("branch_id", branch_val?.value);
  };
  useEffect(() => {
    isAssignedToOneBranch &&
      setFilterObj({ ...filterObj, branch_id: profileData?.branch?.id });
  }, [isAssignedToOneBranch]);
  return (
    <SearchableSelect
      handelMenuOpen={branchesList?.length === 0 && getBranches}
      name="branches"
      getList={getBranches}
      count={branchesCount}
      // options={[{ name: "All", id: 0 }, ...branchesList]}
      options={
        hideOptionAll === true
          ? branchesList
          : [{ name: "All", id: 0 }, ...branchesList]
      }
      label={t("branch.branchName")}
      className="statistics-branches-filter searchable-select branches-filter border-r-10px"
      onChange={(val) => updateBranch(val)}
      value={{
        label:
          profileData?.branch?.name || defaultValue?.label || branch?.label,
        value: profileData?.branch?.id || defaultValue?.id || branch?.id,
      }}
      disabled={isAssignedToOneBranch}
      onClear={() => {
        handleClear && handleClear();
        setBranch({});
        updateBranchesList("branch_id", null);
        isAssignedToOneBranch && getBranches({ branch_id: null });
      }}
    />
  );
};

export default withTranslation()(BranchesFilter);
