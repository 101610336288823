import { Grid, Typography } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import BasicDatePicker from "../../../Atoms/Input/DatePicker/DatePicker";

const OfferTime = ({
  t,
  endDate = null,
  setEndDate,
  startDate = null,
  setStartDate,
  createStartDate,
  createEndDate,
}) => {
  return (
    <Grid container item lg={12} className="activity-form">
      <Grid container item md={12}>
        <Typography
          component="h1"
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            borderBottom: "2px solid var(--primary-color)",
          }}
          className="mb-30px"
        >
          {t("offer.dateAndTime")}
        </Typography>
      </Grid>
      <Grid container item md={12} position="relative" zIndex={10}>
        <Grid item sm={5.8}>
          <BasicDatePicker
            placeholder={t("offer.dateMsg")}
            dateMsgSelect={t("offer.dateMsg")}
            value={startDate}
            className="border-r-10px basic-date-picker mb-20px"
            errorText={"Date field is required"}
            setValue={setStartDate}
            createLis={createStartDate}
            redBorder={createStartDate && "red-border"}
          />
        </Grid>
        <Grid item sm={0.4}></Grid>
        <Grid item sm={5.8}>
          <BasicDatePicker
            placeholder={t("offer.dateMsg")}
            dateMsgSelect={t("offer.dateMsg")}
            value={endDate}
            className="border-r-10px basic-date-picker mb-20px"
            errorText={"Date field is required"}
            setValue={setEndDate}
            createLis={createEndDate}
            redBorder={createEndDate && "red-border"}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(OfferTime);
