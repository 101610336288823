import * as URL from "../URLs";
import { axiosFn, handleError } from "../index";

export async function ScanQrCode(data, action) {
    return await axiosFn("post", URL.scanQr, data)
        .then((res) => {
            action && action()
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}