import AddActivityTemplate from "../../../Templates/Main/Acticities/AddActivity.template.jsx";
import { useState, useEffect } from "react";
import {
  getPresignedLinkAPI,
  uploadFileAPI,
} from "../../../Contexts/APIs/General/upload.js";
import { getSupportedLanguages } from "../../../Contexts/APIs/Lookups/SupportedLanguages.js";
import { getBranchesList } from "../../../Contexts/APIs/Branches/Branches.js";
import { CurrencyAPI } from "../../../Contexts/APIs/Lookups/Currency.js";
import { OrganizationsAPI } from "../../../Contexts/APIs/Lookups/Certificates/Organizations.js";
import { getCertificates } from "../../../Contexts/APIs/Lookups/OrganizationCertificates.js";
import { LanguageAPI } from "../../../Contexts/APIs/Lookups/Language.js";
import {
  createNewActivity,
  getActivityDetails,
} from "../../../Contexts/APIs/Activities/Activities.js";
import { PriceIncludes } from "../../../Contexts/APIs/Lookups/PriceInclude.js";
import Loader from "../../../Components/Atoms/Loader/Loader.js";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

const AddActivityPage = (props) => {
  const { t, type, profileData } = props;
  const [supportedLanguages, setSupportedLanguages] = useState([]);
  const [branchesList, setbranchesList] = useState([]);
  const [branchesCount, setbranchesCount] = useState(10);

  const [currencies, setCurrencies] = useState([]);
  const [organizationsList, setOrganizationsList] = useState([]);
  const [organizationsCount, setOrganizationsCount] = useState(10);

  const [certificatesList, setCertificatesList] = useState([]);
  const [certificatesCount, setCertificatesCount] = useState(10);

  const [languagesList, setLanguagesList] = useState([]);
  const [languagesCount, setLanguagesCount] = useState(10);

  const [priceIncludesList, setPriceIncludesList] = useState([]);
  const [activityImages, setActivityImages] = useState([]);
  const [openLoader, setOpenLoader] = useState(false);
  const [imageArr, setImagesArray] = useState([]);

  const Navigate = useNavigate();
  const { id } = useParams();
  const [search] = useSearchParams();
  let pageNumber = +search.get("page") == 0 ? 1 : +search.get("page");
  const [activityDetails, setActivityDetails] = useState({});
  const userId =
    localStorage.getItem("userType") !== "descendant_user"
      ? localStorage.getItem("userId")
      : localStorage.getItem("ancestorId");

  const uploadActivityImages = async (event) => {
    const numberOfImages = event.target.files?.length + activityImages?.length;
    if (numberOfImages <= 10) {
      if (event.target.files?.length == 1) {
        const file = event.target.files[0];
        const fileSize = file.size / window.maxFileSize; // in MiB
        if (fileSize > 1) {
          toast.warning(window.fileSize);
        } else {
          setOpenLoader(true);

          const files = [{ extension: "png" }];
          const res = await getPresignedLinkAPI(files);
          const presignedUrl = res?.data[0].presigned_url;
          const upload = await uploadFileAPI(presignedUrl, file);
          if (upload) {
            setActivityImages([
              ...activityImages,
              {
                media_type: "photo",
                url: res?.data[0]?.file_url,
              },
            ]);
          }
          setOpenLoader(false);
        }
      } else {
        setOpenLoader(true);

        Array.from(event.target.files).forEach(async (file) => {
          const fileSize = file.size / window.maxFileSize; // in MiB
          if (fileSize > 1) {
            toast.warning(window.fileSize);
          } else {
            const files = [{ extension: "png" }];
            const res = await getPresignedLinkAPI(files);
            const presignedUrl = await res?.data[0].presigned_url;
            const upload = await uploadFileAPI(presignedUrl, file);
            if (upload) {
              setActivityImages((s) => {
                return [
                  ...s,
                  {
                    media_type: "photo",
                    url: res?.data[0]?.file_url,
                  },
                ];
              });
            }
          }
        });
        setTimeout(() => {
          setOpenLoader(false);
        }, 500);
      }
    } else {
      toast.error("you exceeded the maximum number of images");
    }
  };

  const getLanguages = async () => {
    const { res, err } = await getSupportedLanguages();
    if (res) {
      setSupportedLanguages(res?.data?.supported_locales);
    }
  };

  const getBranches = async (filter) => {
    const { res, err } = await getBranchesList({
      page_size: 10,
      page_number: 1,
      dive_center_id: userId,
      branch_type: search.get("type") == 2 ? "safari_boat" : "",
      ...filter,
    });
    if (res) {
      (filter?.page_number == 1 || filter?.page_number == undefined)
        ? setbranchesList(res?.data?.branches)
        : setbranchesList((s) => [...s, ...res?.data?.branches]);
      setbranchesCount(res?.extra?.total_count);
    }
  };
  const getCurrencies = async () => {
    const { res, err } = await CurrencyAPI({
      page_number: 1,
    });
    if (res) {
      res?.data?.currencies.map((currency) => {
        setCurrencies((currencies) => [
          ...currencies,
          { name: currency?.lookup_key?.toUpperCase(), id: currency?.id },
        ]);
      });
    }
  };

  const getOrganization = async (filter) => {
    const { res, err } = await OrganizationsAPI({
      page_size: 10,
      page_number: 1,
      ...filter,
    });
    if (res) {
      filter?.page_number == 1 || filter?.branch_id
        ? setOrganizationsList(res?.data?.organizations)
        : setOrganizationsList((s) => [...s, ...res?.data?.organizations]);
      setOrganizationsCount(res?.extra?.total_count);
    }
    if (err) {
    }
  };

  const getSearchCertificatesList = async (filter) => {
    const { res, err } = filter?.organization_id
      ? await getCertificates({
          page_size: 10,
          page_number: 1,
          ...filter,
        })
      : { res: null, err: null };
    if (res) {
      if (filter?.page_number == 1) {
        setCertificatesList(res?.data?.organization_certificates);
      } else {
        setCertificatesList((s) => [
          ...s,
          ...res?.data?.organization_certificates,
        ]);
      }
      setCertificatesCount(res?.extra?.total_count);
    }
  };
  const getLanguagesAPI = async (filter) => {
    const { res, err } = await LanguageAPI({
      page_size: 10,
      page_number: 1,
      ...filter,
    });
    if (res) {
      filter?.page_number == 1
        ? setLanguagesList(res?.data?.languages)
        : setLanguagesList((s) => [...s, ...res?.data?.languages]);

      setLanguagesCount(res?.extra?.total_count);
    }
  };

  const getPriceList = async () => {
    const { res, err } = await PriceIncludes({
      page_number: 1,
    });
    if (res) setPriceIncludesList(res?.data?.price_includes);
  };

  const removeActivityImage = (i) => {
    if (i > -1) {
      activityImages.splice(i, 1);
      setActivityImages([...activityImages]);
    }
  };

  const addNewActivity = async (activityObj) => {
    setOpenLoader(true);
    const { res, err } = await createNewActivity(activityObj);
    if (res) {
      Navigate(`/activities?page=${pageNumber}`);
    }
    setOpenLoader(false);
  };

  const ActivityDetails = async () => {
    setOpenLoader(true);
    const { res, err } = await getActivityDetails(id);
    if (res) {
      setActivityDetails(res?.data?.activity);
      setOpenLoader(false);
    }
  };

  useEffect(() => {
    getLanguages();
    getCurrencies();
    getPriceList();
    if (type === "edit") {
      ActivityDetails();
    }
  }, []);

  useEffect(() => {
    if (type === "edit") {
      const newList = [];
      activityDetails?.media?.map((image) => {
        newList.push({
          url: image.url,
          media_type: image.media_type,
        });
      });
      setActivityImages(newList);
    }
  }, [type, activityDetails]);

  /*  
  const resetFields = () => {
    setOpenLoader(true);
    const inps = document.querySelectorAll("input");
    console.log('inps', inps);
    setTimeout(() => {
      (window.location.href.indexOf("edit") === -1) && inps.forEach(elem => {
        elem.value = "";
      });
      setOpenLoader(false);
    }, 4000);
  }

  useEffect(() => {
    window.location.href.indexOf("edit") === -1 && resetFields();
  }, [document.querySelectorAll("input")])
*/  
  return (
    <>
      <Loader open={openLoader} />
      <AddActivityTemplate
        uploadActivityImages={uploadActivityImages}
        supportedLanguages={supportedLanguages}
        branchesList={branchesList}
        currencies={currencies}
        getLanguagesAPI={getLanguagesAPI}
        languagesCount={languagesCount}
        organizationsList={organizationsList}
        setOrganizationsList={setOrganizationsList}
        branchesCount={branchesCount}
        certificatesCount={certificatesCount}
        organizationsCount={organizationsCount}
        getOrganization={getOrganization}
        getCertificates={getSearchCertificatesList}
        getBranches={getBranches}
        certificatesList={certificatesList}
        setCertificatesList={setCertificatesList}
        languagesList={languagesList}
        priceIncludesList={priceIncludesList}
        activityImages={activityImages}
        removeActivityImage={removeActivityImage}
        getListOfPrices={getPriceList}
        createNewActivity={addNewActivity}
        type={type}
        activityDetails={activityDetails}
        profileData={profileData}
      />
    </>
  );
};

export default AddActivityPage;
