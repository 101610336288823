import { Grid, Typography, Divider } from "@mui/material";
import SelectBox from "../../../Atoms/Select/Select.jsx";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput.jsx";
import "./Form.css";
import { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const BranchInfo = (props) => {
  const {
    t,
    type,
    formik,
    supportedLanguages,
    getBranchLocalized,
    supportLang,
  } = props;
  const [branchName, setBranchName] = useState([]);
  const [nameValidation, setNameValidation] = useState(false);
  const updateBranchName = (event, i) => {
    if (event.target.value == "") {
      setNameValidation(true);
    } else {
      setNameValidation(false);
    }
    let tempArray = [...branchName];
    let tempIndex = tempArray[i];
    tempIndex = event.target.value;
    const newList = branchName.map((obj, index) => {
      if (index === i) {
        return tempIndex;
      }
      return obj;
    });
    setBranchName(newList);
  };

  useEffect(() => {
    getBranchLocalized(branchName);
  }, [branchName]);

  useEffect(() => {
    setBranchName([formik?.values?.name]);
  }, [formik?.values?.name]);

  return (
    <Grid container lg={12} className="activity-form">
      <Grid container md={12}>
        <Typography
          component="h1"
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            borderBottom: "2px solid var(--primary-color)",
          }}
          className="mb-20px"
        >
          {type !== "safari_boat"
            ? t("branch.diveCenterInformation")
            : t("branch.SafariBoatInformation")}
        </Typography>
      </Grid>
      <Grid container md={12} className="mb-20px">
        {supportedLanguages.map((lang, index) => (
          <>
            {supportLang && supportLang === lang.id && (
              <Grid sm={5.8} key={index}>
                <TextFieldInput
                  placeholder={
                    type === "safari_boat"
                      ? t("branch.safariBoatName")
                      : t("branch.branchName")
                  }
                  name={"name_" + lang.id}
                  id={"name_" + lang.id}
                  className="login-input"
                  type="text"
                  value={formik?.values[`name_${lang?.id}`]}
                  // error={formik.touched.description && Boolean(formik.errors.description)}
                  // helperText={formik.touched.description && formik.errors.description}
                  onChange={formik.handleChange}
                  error={
                    formik.touched[`name_${lang?.id}`] && Boolean(formik.errors[`name_${lang?.id}`])
                  }
                  helperText={
                    formik.touched[`name_${lang?.id}`]&& formik.errors[`name_${lang?.id}`]
                  }
                />
              </Grid>
            )}
          </>
        ))}
        {/* {!supportLang && (
                    <Grid sm={5.8}>
                        <TextFieldInput
                            name="name"
                            id="name"
                            placeholder={type === 'safari_boat' ? 'Safari boat name' : 'Branch Name'}
                            className='login-input'
                            type="text"
                            value={formik.values.name}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                )} */}
        <Grid sm={0.4}></Grid>
        <Grid sm={5.8}>
          <TextFieldInput
            name="manager_name"
            id="manager_name"
            placeholder={
              type === "safari_boat"
                ? t("branch.safariBoatManagerName")
                : t("branch.branchManagerName")
            }
            className="login-input"
            type="text"
            value={formik.values.manager_name}
            error={
              formik.touched.manager_name && Boolean(formik.errors.manager_name)
            }
            helperText={
              formik.touched.manager_name && formik.errors.manager_name
            }
            onChange={formik.handleChange}
          />
        </Grid>
      </Grid>
      <Grid container md={12} className="mb-20px">
        <Grid sm={5.8}>
          <TextFieldInput
            name="email"
            id="email"
            placeholder={
              type === "safari_boat"
                ? t("branch.safariBoatEmail")
                : t("branch.branchEmail")
            }
            className="login-input"
            type="text"
            value={formik.values.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid sm={0.4}></Grid>
        <Grid
          sm={5.8}
          justifyItems={"center"}
          alignItems={"center"}
          sx={{ mt: "10px" }}
        >
          {/* <TextFieldInput
            name="number"
            id="number"
            placeholder={
              type === "safari_boat"
                ? t("branch.safariBoatNumber")
                : t("branch.branchNumber")
            }
            className="login-input"
            type="text"
            value={formik.values.number}
            error={formik.touched.number && Boolean(formik.errors.number)}
            helperText={formik.touched.number && formik.errors.number}
            onChange={formik.handleChange}
          /> */}
          <PhoneInput
            inputProps={{
              name: "phone",
              required: true,
              autoFocus: true,
              style: {
                width: "100%",
                fontSize: "14px",
                height: "100% !important",
              },
            }}
            value={
              (formik.values.branch_country_code || "") +
              (formik.values.branch_number || "")
            }
            onChange={(value, data) => {
              formik.setValues({
                ...formik?.values,
                branch_number: value.slice(data?.dialCode?.length),
                branch_country_code: `+${data?.dialCode}`,
              });
            }}
            enableSearch={true}
            containerStyle={{ height: "56px" }}
            // dropdownStyle={{ width: "300px !important" }}
            searchStyle={{ width: "90%" }}
          />
        </Grid>
      </Grid>
      <Grid container md={12} className="mb-20px">
        <Grid sm={5.8}>
          <TextFieldInput
            name="insurance_name"
            id="insurance_name"
            placeholder={t("branch.insuranceName")}
            className="login-input"
            type="text"
            value={formik.values.insurance_name}
            error={
              formik.touched.insurance_name &&
              Boolean(formik.errors.insurance_name)
            }
            helperText={
              formik.touched.insurance_name && formik.errors.insurance_name
            }
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid sm={0.4}></Grid>
        <Grid sm={5.8}>
          <TextFieldInput
            name="policy_number"
            id="policy_number"
            placeholder={t("branch.policyNumber")}
            className="login-input"
            type="text"
            value={formik.values.policy_number}
            error={
              formik.touched.policy_number &&
              Boolean(formik.errors.policy_number)
            }
            helperText={
              formik.touched.policy_number && formik.errors.policy_number
            }
            onChange={formik.handleChange}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(BranchInfo);
