import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import QrScan from "react-qr-reader";

const ScanQrcodeModel = ({ open, setOpen, handelScanCode, t }) => {
  const [qrscan, setQrscan] = useState("No result");
  const handleScan = (data) => {
    setQrscan(data);
    if (data) {
      handelScanCode(data);
    }
  };
  const handleError = (err) => {
    console.error(err);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: "50%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "25px",
  };

  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack sx={style} alignItems="center" justifyContent="center">
        <Typography
          component="h1"
          style={{
            color: "#f6b119",
            fontWeight: "bold",
            fontSize: "30px",
            marginTop: "-75px",
          }}
        >
          {t("joboffer.scanCode")}
        </Typography>
        <QrScan
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ height: 240, width: 320 }}
        />
      </Stack>
    </Modal>
  );
};

export default withTranslation()(ScanQrcodeModel);
