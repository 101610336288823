import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import AccountUpdateTemplate from "../../../Templates/Main/Account/AccountUpdate.template";
import { toast } from "react-toastify";
import { completeAccount } from "../../../Contexts/APIs/Auth/CompleteAccount";
import {
  getPresignedLinkAPI,
  uploadFileAPI,
} from "../../../Contexts/APIs/General/upload";
import { CityAPI } from "../../../Contexts/APIs/Lookups/City";
import { CountryAPI } from "../../../Contexts/APIs/Lookups/Country";
import { CurrencyAPI } from "../../../Contexts/APIs/Lookups/Currency";
import { LanguageAPI } from "../../../Contexts/APIs/Lookups/Language";

const AccountUpdatePage = ({ profileData }) => {
  const fcm_token = localStorage.getItem("fcm_token");

  const [languagesList, setLanguagesList] = useState([]);
  const [languagesCount, setLanguagesCount] = useState(10);

  const [currencies, setCurrencies] = useState([]);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);

  const [activityImages, setActivityImages] = useState();
  const [openLoader, setOpenLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [primaryLanguage, setPrimaryLanguage] = useState({});
  const dive_center_id = localStorage.getItem("userId");

  const getLanguagesAPI = async (filter) => {
    const filterData = {
      page_size: 10,
      page_number: 1,
      ...filter,
    };
    const { res, err } = await LanguageAPI({
      ...filterData,
    });
    if (res) {
      filterData?.page_number == 1
        ? setLanguagesList(res?.data?.languages)
        : setLanguagesList((s) => [...s, ...res?.data?.languages]);

      setLanguagesCount(res?.extra?.total_count);
    }
  };

  const getCurrencies = async () => {
    const { res, err } = await CurrencyAPI({
      page_size: 10000,
      page_number: 1,
    });
    if (res) {
      res?.data?.currencies.map((currency) => {
        setCurrencies((currencies) => [
          ...currencies,
          { name: currency?.lookup_key?.toUpperCase(), id: currency?.id },
        ]);
      });
    }
  };
  const getCities = async (country_id) => {
    setCities([]);
    const { res, err } = await CityAPI({
      page_size: 10000,
      page_number: 1,
      country_id: country_id,
    });
    if (res) {
      res?.data?.cities.map((currency) => {
        setCities((cities) => [
          ...cities,
          { name: currency?.name, id: currency?.id },
        ]);
      });
    }
  };
  const getCountries = async () => {
    const { res, err } = await CountryAPI({ page_size: 10000, page_number: 1 });
    if (res) {
      res?.data?.countries.map((currency) => {
        setCountries((countries) => [
          ...countries,
          { name: currency?.name, id: currency?.id },
        ]);
      });
    }
  };

  const CompleteAccount = async (type, data, user_id, redirect) => {
    const { res, err } = await completeAccount(type, data, user_id);
    if (res) {
      localStorage.setItem("accessToken", localStorage.getItem("Token"));
      window.location.replace("/");
    }
  };

  useEffect(() => {
    // getLanguagesAPI();
    getCurrencies();
    getCountries();
    if (profileData) {
      setPrimaryLanguage({
        label: profileData?.primary_language?.name,
        value: profileData?.primary_language?.id,
      });
    }
  }, []);

  const uploadActivityImages = async (event) => {
    const file = event.target.files[0];
    const fileSize = file.size / window.maxFileSize; // in MiB
    if (fileSize > 1) {
      toast.warning(window.fileSize);
    } else {
      setOpenLoader(true);
      const files = [{ extension: "png" }];
      const res = await getPresignedLinkAPI(files);
      const presignedUrl = res?.data[0].presigned_url;
      const upload = await uploadFileAPI(presignedUrl, file);
      if (upload) {
        setActivityImages({
          media_type: "photo",
          url: res?.data[0]?.file_url,
        });
      }
      setOpenLoader(false);
    }
  };
  const removeActivityImage = (i) => {
    setActivityImages();
  };

  const formik = useFormik({
    // validationSchema: AccountSchema,
    enableReinitialize: true,
    initialValues: {
      logo: profileData?.logo,
      en: {
        name: profileData?.name,
        about_us: profileData?.about_us,
      },
      country_code: profileData?.country_code,
      phone_number: profileData?.phone_number,
      main_office_address: profileData?.main_office_address,
      owner_email: profileData?.owner_email,
      email: profileData?.email,
      owner_name: profileData?.owner_name,
      facebook: profileData?.facebook,
      website: profileData?.website,
      contact_person_name: profileData?.contact_person_name,
      contact_person_email: profileData?.contact_person_email,
      contact_person_phone_number: profileData?.contact_person_phone_number,
      contact_person_country_code: profileData?.contact_person_country_code,
      primary_language_id: primaryLanguage,
      owner_phone_number: profileData?.owner_phone_number,
      owner_country_code: profileData?.owner_country_code,
      allow_outsider_freelancers: profileData?.allow_outsider_freelancers,
    },

    onSubmit: (values) => {
      const data = {
        user: {
          ...values,
          logo: activityImages?.url,
          primary_language_id: values.primary_language_id.value,
        },
        device: {
          uuid: "dummy",
          device_type: "web",
          fcm_token: fcm_token,
        },
      };
      const user_id = localStorage.getItem("userId");
      CompleteAccount("dive_centers", data, user_id);
    },
  });

  useEffect(() => {
    if (formik?.values?.bank_account_attributes?.country_id)
      getCities(formik?.values?.bank_account_attributes?.country_id);
  }, [formik?.values?.bank_account_attributes?.country_id]);

  useEffect(() => {
    setActivityImages({
      media_type: "photo",
      url: profileData?.logo,
    });
  }, [profileData]);
  return (
    <>
      <AccountUpdateTemplate
        diveCenterDetails={profileData}
        formik={formik}
        activityImages={activityImages}
        uploadActivityImages={uploadActivityImages}
        removeActivityImage={removeActivityImage}
        cities={cities}
        countries={countries}
        languagesList={languagesList}
        currencies={currencies}
        CompleteAccount={CompleteAccount}
        getLanguagesAPI={getLanguagesAPI}
        languagesCount={languagesCount}
      />
    </>
  );
};

export default AccountUpdatePage;
