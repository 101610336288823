import { Grid, Typography } from "@mui/material";
import ReservationForm from "../../../Components/Organisms/Reservations/ReservationForm.jsx";

const AddReservationTemplate = (props) => {
    const { t, searchUsers, usersList, activityGroups, createReservation } = props;

    return (
        <Grid container>
            <ReservationForm
                searchUsers={searchUsers}
                usersList={usersList}
                activityGroups={activityGroups}
                createReservation={createReservation}
            />
        </Grid>
    )
}

export default AddReservationTemplate