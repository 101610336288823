import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";
import { makeFilterString } from "../Helper/Helper.js";

export async function getJobOffersList(filter_obj) {
    return await axiosFn("get", URL.JOB_OFFERS + makeFilterString(filter_obj))
        .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}

export async function showJobOffer(id, filter) {
    return await axiosFn("get", `${URL.JOB_OFFERS}/${id}?${filter}`)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}

export async function createJobOffer(data, action) {
    return await axiosFn("post", URL.JOB_OFFERS, data)
        .then((res) => {
            action && action()

            return {
                res: res.data,
                err: null,
            };

        })
        .catch(handleError);
}

export async function acceptJopRequest(id, data) {
    return await axiosFn("put", `/job_requests/${id}/accept`, data)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}



export async function completeJopOffer(id, data) {
    return await axiosFn("patch", `job_offers/${id}`, data)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}


export async function deleteJopOffer(id, data) {
    return await axiosFn("delete", `job_offers/${id}`, data)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}
