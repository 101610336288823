import React from 'react'
import FreelancerReviews from '../../../Components/Organisms/Freelancers/FreelancerReviews'

const FreelancerReviewsTemplate = (
    { reviewsList,
        reviewsDetails,
        applicantData,
        count,
        handleChange }
) => {
    return (
        <FreelancerReviews
            reviewsList={reviewsList}
            reviewsDetails={reviewsDetails}
            applicantData={applicantData}
            count={count}
            handleChange={handleChange} />
    )
}

export default FreelancerReviewsTemplate