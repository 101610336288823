/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography, Divider } from "@mui/material";
import SelectBox from "../../../Atoms/Select/Select.jsx";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput.jsx";
import "./Form.css";
import BasicDatePicker from "../../../Atoms/Input/DatePicker/DatePicker.js";
import { useEffect, useState } from "react";
import BasicTimePicker from "../../../Atoms/Input/TimePicker/TimePicker.js";
import LanguagesSelect from "../../Forms/LanguagesSelect.jsx";
import SearchableSelect from "../../../Atoms/Select/SearchableSelect.jsx";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";

const JopOfferInfo = (props) => {
  const {
    organizationsList, 
    branchesList,
    certificatesList,
    orgId,
    setOrgId,
    formik,
    currencies,
    endDate,
    setEndDate,
    setStartDate,
    startDate,
    startTime,
    setStartTime,
    supportedLanguages,
    startBorder,
    supportLang,
    endBorder,
    setSupportLang,
    setConcatTime,
    activityType,
    setActivityType,
    t,
    getBranches,
    userId,
    getOrganization,
    getCertificatesList,
    branchesCount,
    organizationsCount,
    certificatesCount,
    profileData
  } = props;
  const isAssignedToOneBranch = profileData?.branch?.name;

  const [nameValidation, setNameValidation] = useState(false);
  const [filteredOrgnizationList, setOrganizationList] = useState([]);
  const [filteredCertificateList, setCertificateList] = useState([]);

  const activitesList =
    formik?.values?.dive_expert_professional_level != "dive_guide"
      ? [
          {
            name: t("joboffer.diveTrip"),
            id: "dive_trip",
          },
          ,
          {
            name: t("joboffer.diveCourse"),
            id: "dive_course",
          },
          {
            name: t("joboffer.liveaboard"),
            id: "live_aboard",
          },
        ]
      : [
          {
            name: t("joboffer.diveTrip"),
            id: "dive_trip",
          },

          {
            name: t("joboffer.liveaboard"),
            id: "live_aboard",
          },
        ];

  const professionalLevel =
    activityType === "dive_course"
      ? [
          { id: "instructor", name: t("joboffer.Instructor") },
          {
            id: "speciality_instructor",
            name: t("joboffer.specialityInstructor"),
          },
        ]
      : [
          { id: "dive_guide", name: t("joboffer.diveGuide") },
          { id: "instructor", name: t("joboffer.Instructor") },
          {
            id: "speciality_instructor",
            name: t("joboffer.specialityInstructor"),
          },
        ];
  const job_type = [
    { id: "full_day", name: t("joboffer.fullDay") },
    { id: "half_day", name: t("joboffer.halfDay") },
  ];

  const updateSupportedLang = (e, languages) => {
    setSupportLang(e.target.value);
  };

  const filterOrganizationsList = () => {
    if (
      formik.values.activity_type == "dive_trip" ||
      formik.values.activity_type == "live_aboard"
    ) {
      setOrganizationList([
        { name: t("joboffer.all"), id: "all" },
        ...organizationsList,
      ]);
      setCertificateList([
        { name: t("joboffer.all"), id: "all" },
        ...certificatesList,
      ]);
    }

    if (formik.values.activity_type == "dive_course") {
      if (
        formik.values.dive_expert_professional_level == "speciality_instructor"
      ) {
        setOrganizationList([...organizationsList]);
        setCertificateList([...certificatesList]);
      } else {
        setOrganizationList([...organizationsList]);
        setCertificateList([
          { name: t("joboffer.all"), id: "all" },
          ...certificatesList,
        ]);
      }
    }
  };
  useEffect(() => {
    filterOrganizationsList();
  }, [
    organizationsList,
    certificatesList,
    formik.values.dive_expert_professional_level,
    formik.values.activity_type,
  ]);

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      organization_id: "",
      organization_name: "",
      organization_certificate_id: "",
      organization_certificate_name: "",
    });
  }, [
    formik.values.dive_expert_professional_level,
    formik.values.activity_type,
  ]);

  useEffect(() => {
    if (typeof isAssignedToOneBranch == 'string') {
      formik.setValues({
        ...formik.values,
        branch_id: profileData?.branch?.id,
        branch_name: profileData?.branch?.name,
      });
    }
  }, [isAssignedToOneBranch, profileData]);

  return (
    <Grid container lg={12} className="activity-form">
      <Grid container md={12}>
        <LanguagesSelect
          value={supportLang}
          supportedLanguages={supportedLanguages}
          onChange={(e) => updateSupportedLang(e, supportedLanguages)}
        />
      </Grid>
      <Grid container md={12} className="mb-20px">
        <Typography
          component="h1"
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            borderBottom: "2px solid var(--primary-color)",
          }}
          className="mb-20px"
        >
          {t("joboffer.offerInformation")}
        </Typography>
      </Grid>
      <Grid container md={12} gap={1} className="mb-20px">
        <Grid sm={5.8}>
          <SearchableSelect
            label={t("activity.branchName")}
            name="branch_id"
            getList={getBranches}
            count={branchesCount}
            className="searchable-select branches-drop-down"
            options={branchesList}
            error={formik.touched.branch_id && Boolean(formik.errors.branch_id)}
            helperText={formik.touched.branch_id && formik.errors.branch_id}
            disabled={isAssignedToOneBranch}
            value={{
              value:
                isAssignedToOneBranch 
                  ? profileData?.branch?.id
                  : formik?.values?.branch_id,
              label:
                isAssignedToOneBranch 
                  ? profileData?.branch?.name
                  : formik?.values?.branch_name,
            }}
            handelMenuOpen={getBranches}
            onChange={(val) => {
              formik.setValues({
                ...formik?.values,
                branch_id: val?.value,
                branch_name: val?.label,
              });
            }}
            onClear={() => {
              formik.setValues({
                ...formik?.values,
                branch_id: "",
                branch_name: "",
              });
              getBranches({});
            }}
          />
        </Grid>
        <Grid sm={5.8}>
          <SelectBox
            id="activity_type"
            name="activity_type"
            items={activitesList}
            title={t("joboffer.activityType")}
            className="select"
            value={formik.values.activity_type}
            error={
              formik.touched.activity_type &&
              Boolean(formik.errors.activity_type)
            }
            helperText={
              formik.touched.activity_type && formik.errors.activity_type
            }
            onchange={formik.handleChange}
            onclick={(val) => {
              formik.setValues({ ...formik?.values, activity_type: val?.id });
              setActivityType(val);
            }}
          />
        </Grid>
      </Grid>
      <Grid container md={12} className="mb-20px" gap={1}>
        {supportedLanguages.map(
          (lang, index) =>
            supportLang &&
            supportLang === lang.id && (
              <Grid sm={5.8} key={index}>
                <TextFieldInput
                  name={"title_" + lang.id}
                  id={"title_" + lang.id}
                  placeholder={t("joboffer.jobTitle")}
                  className="login-input"
                  type="text"
                  value={formik?.values[`title_${lang.id}`]}
                  onChange={formik.handleChange}
                  error={nameValidation && Boolean(nameValidation)}
                  helperText={nameValidation ? "name is a required field" : ""}
                />
              </Grid>
            )
        )}

        <Grid sm={5.8} mt={1}>
          <SelectBox
            id="dive_expert_professional_level"
            name="dive_expert_professional_level"
            items={professionalLevel}
            title={t("joboffer.freelanceProfessionalLevel")}
            className="select"
            value={formik.values.dive_expert_professional_level}
            error={
              formik.touched.dive_expert_professional_level &&
              Boolean(formik.errors.dive_expert_professional_level)
            }
            helperText={
              formik.touched.dive_expert_professional_level &&
              formik.errors.dive_expert_professional_level
            }
            onchange={formik.handleChange}
            onclick={(val) =>
              formik.setValues({
                ...formik?.values,
                dive_expert_professional_level: val?.id,
              })
            }
          />
        </Grid>
      </Grid>
      <Grid
        container
        md={12}
        gap={1}
        className="mb-20px"
        style={{ position: "relative", zIndex: "10" }}
      >
        <Grid sm={5.8}>
          <SearchableSelect
            name="organization_id"
            options={filteredOrgnizationList}
            error={formik.touched.organization_id && Boolean(formik.errors.organization_id)}
            helperText={formik.touched.organization_id && formik.errors.organization_id}
            getList={getOrganization}
            handelMenuOpen={() => {
              if(!formik?.values?.activity_type?.length){
                toast.error(t("joboffer.selectActivityTypeFirst"));
              }else{
                getOrganization();
              }
            }}
            count={organizationsCount}
            label={t("branch.organization")}
            className="searchable-select"
            onChange={(val) => {
              val?.value == "all" ? setOrgId(null) : setOrgId(val?.value);
              formik.setValues({
                ...formik?.values,
                organization_certificate_id: "",
                organization_certificate_name: "",
                organization_id: val?.value,
                organization_name: val?.label,
              });
            }}
            value={{
              label: formik?.values?.organization_name,
              value: formik?.values?.organization_id,
            }}
            onClear={() => {
              setOrgId(null);
              formik.setValues({
                ...formik?.values,
                organization_id: "",
                organization_name: "",
                organization_certificate_id: "",
                organization_certificate_name: "",
              });
            }}
          />
        </Grid>
        <Grid sm={5.8}>
          <SearchableSelect
            name="organization_certificate_id"
            error={formik.touched.organization_certificate_id && Boolean(formik.errors.organization_certificate_id)}
            helperText={formik.touched.organization_certificate_id && formik.errors.organization_certificate_id}
            getList={getCertificatesList}
            handelMenuOpen={() => {
              if(!formik?.values?.activity_type?.length){
                toast.error(t("joboffer.selectActivityTypeFirst"));
              }else{
                getCertificatesList();
              }
            }}
            count={certificatesCount}
            moreFilterData={{
              organization_id:
                formik?.values?.organization_id == "all"
                  ? null
                  : formik?.values?.organization_id,
            }}
            options={
              orgId
                ? (formik.values.activity_type == "dive_course" && formik.values.dive_expert_professional_level == "speciality_instructor") 
                  ? certificatesList 
                  : [{ name: t("joboffer.all"), id: "all" }, ...certificatesList]
                : filteredCertificateList
            }
            label={t("activity.certificate")}
            className="searchable-select"
            onChange={(val) => {
              formik.setValues({
                ...formik?.values,
                organization_certificate_id: val?.value,
                organization_certificate_name: val?.label,
              });
            }}
            value={{
              label: formik?.values?.organization_certificate_name,
              value: formik?.values?.organization_certificate_id,
            }}
            onClear={() => {
              // setOrgId(null);
              formik.setValues({
                ...formik?.values,
                organization_certificate_id: "",
                organization_certificate_name: "",
              });
            }}
          />
        </Grid>
      </Grid>
      <Grid container md={12} className="mb-20px" gap={1}>
        <Grid container sm={5.8} gap={1}>
          <Grid xs={5.75}>
            <TextFieldInput
              name="rate"
              id="rate"
              placeholder={t("joboffer.offerRate")}
              className="login-input"
              type="text"
              value={formik.values.rate}
              error={formik.touched.rate && Boolean(formik.errors.rate)}
              helperText={formik.touched.rate && formik.errors.rate}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid xs={5.75} mt={1}>
            <SelectBox
              id="currency_id"
              name="currency_id"
              items={currencies}
              title={t("activity.currency")}
              className="select"
              value={formik.values.currency_id}
              error={
                formik.touched.currency_id && Boolean(formik.errors.currency_id)
              }
              helperText={
                formik.touched.currency_id && formik.errors.currency_id
              }
              onchange={formik.handleChange}
              onclick={(val) =>
                formik.setValues({ ...formik?.values, currency_id: val?.id })
              }
            />
          </Grid>
        </Grid>
        <Grid sm={5.8} mt={1}>
          <SelectBox
            id="job_type"
            name="job_type"
            items={job_type}
            title={t("joboffer.jobDuration")}
            className="select"
            value={formik.values.job_type}
            error={formik.touched.job_type && Boolean(formik.errors.job_type)}
            helperText={formik.touched.job_type && formik.errors.job_type}
            onchange={formik.handleChange}
            onclick={(val) =>
              formik.setValues({ ...formik?.values, job_type: val?.id })
            }
          />
        </Grid>
      </Grid>
      <Grid container sm={12} gap={1}>
        <Grid xs={5.8}>
          <BasicDatePicker
            placeholder={t("activity.startDate")}
            value={startDate}
            className="border-r-10px basic-date-picker mb-20px"
            redBorder={startBorder}
            errorText={"Date field is required"}
            setValue={setStartDate}
          />
        </Grid>

        <Grid xs={5.8}>
          <BasicDatePicker
            placeholder={t("activity.endDate")}
            value={endDate}
            className="border-r-10px basic-date-picker mb-20px"
            redBorder={endBorder}
            errorText={"Date field is required"}
            setValue={setEndDate}
          />
        </Grid>
      </Grid>
      <Grid container sm={12}>
        <Grid sm={5.8}>
          <BasicTimePicker
            startDate={startDate}
            className="border-r-10px basic-time-picker mb-20px"
            value={startTime}
            setConcatTime={setConcatTime}
            placeholder={t("activity.startTime")}
            default
            setValue={(time) => setStartTime(time)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(JopOfferInfo);
