import { Grid, Typography, Divider } from "@mui/material";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput.jsx";
import { withTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import PasswordCriteria from "../../../Atoms/SharedComponents/PasswordCriteria.jsx";

const ChangePassword = (props) => {
  const {
    getOrganization,
    branchesList,
    formik,
    currencies,
    supportedLanguages,
    supportLang,
    getBranchInformation,
    getCurrentLocation,
    t,
  } = props;
  const [searchParams] = useSearchParams(),
    activityType = searchParams.get("type");

  return (
    <Grid container className="activity-form">
      <Grid container>
        <Typography
          component="h1"
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            borderBottom: "2px solid var(--primary-color)",
          }}
          className="mb-20px"
        >
          {t("salesmen.salesmanPassword")}
        </Typography>
      </Grid>

      {/* <Grid container className="mb-20px" gap={1}>
        <Grid item sm={5.8}>
          <TextFieldInput
            name="name"
            id="fullName"
            placeholder={t("activity.fullName")}
            className="login-input"
            type="text"
            value={formik.values.name}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item sm={5.8}></Grid>{" "}
      </Grid> */}

      <Grid container item md={12} className="mb-20px">
        <Grid item sm={5.8}>
          <TextFieldInput
            name="password"
            id="password"
            placeholder={t("salesmen.password")}
            type="password"
            className="login-input"
            onChange={formik.handleChange}
            value={formik.values.password}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <PasswordCriteria />
        </Grid>
        <Grid item sm={0.4}></Grid>
        <Grid item sm={5.8}>
          <TextFieldInput
            name="password_confirmation"
            id="password_confirmation"
            placeholder={t("salesmen.confirmPassword")}
            type="password"
            className="login-input"
            onChange={formik.handleChange}
            value={formik.values.password_confirmation}
            error={
              formik.touched.password_confirmation &&
              Boolean(formik.errors.password_confirmation)
            }
            helperText={
              formik.touched.password_confirmation &&
              formik.errors.password_confirmation
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(ChangePassword);
