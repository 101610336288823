import { Grid } from "@mui/material";
import ListOffers from "../../Molecules/Offers/List/OffersList";

const OffersList = ({ count, offers, handleChange, is_navigate, title, offersPrivilages }) => {
    return (
        <Grid container>
            <ListOffers
                count={count}
                offers={offers}
                handleChange={handleChange}
                offersPrivilages={offersPrivilages}
            />
        </Grid>
    );
}

export default OffersList;