import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Loader from "../../../../Components/Atoms/Loader/Loader";
import AssignedActivityGroupsTemplate from "../../../../Templates/Main/Salesmen/SalesmenList/AssignedActivityGroups.template";
import { getSalesmenDetails } from "../../../../Contexts/APIs/Salsemen/Salesmen";
import moment from "moment";
import { getInHouseActivityGroupsList } from "../../../../Contexts/APIs/Salsemen/InHouseActivities";

const AssignedActivityGroupsPage = (props) => {
  const { t } = props;
  const { id, group_id } = useParams();
  const [count, setCount] = useState();
  const [openLoader, setOpenLoader] = useState(false);
  const [activityGroups, setActivityGroups] = useState([]);
  const [activityInfo, setActivityInfo] = useState({});

  const { state } = useLocation();
  const { assignedActivityName, salesmanId, salesmanName } = state;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [page, setPage] = useState(1);

  const getDiff = (date1, date2) => {
    return moment(moment(new Date(date1)).format("YYYY-MM-DD")).diff(
      moment(
        moment(
          new Date(
            `${date2}`.replaceAll("/", "-").split("-").reverse().join("-")
          )
        ).format("YYYY-MM-DD")
      ),
      "days"
    );
  };

  const handleDatesChange = (arrayTemp) => {
    arrayTemp.length > 0
      ? setActivityGroups(
          arrayTemp.filter(
            (item, ndx) =>
              ndx >= 0 + (page - 1) * 10 && ndx <= 9 + (page - 1) * 10
          )
        )
      : setActivityGroups([]);
    arrayTemp.length > 0 ? setCount(arrayTemp.length) : setCount();
  };

  const handleDatesRes = (startDt, endDt, result) => {
    let activityGroupsTemp = [];

    if (startDt && endDt) {
      result?.find((item) => {
        if (item.id == group_id) {
          item?.activity?.inhouse_activity_groups?.forEach((groItem) => {
            if (
              getDiff(groItem?.start_date, startDt) >= 0 &&
              getDiff(groItem?.end_date, endDt) <= 0
            ) {
              activityGroupsTemp = [...activityGroupsTemp, groItem];
              handleDatesChange(activityGroupsTemp);
            }
          });
        }
      });
    } else if (
      (startDt?.length == 0 || startDt?.length == undefined) &&
      endDt
    ) {
      result?.find((item) => {
        if (item.id == group_id) {
          item?.activity?.inhouse_activity_groups?.forEach((groItem) => {
            if (getDiff(groItem?.end_date, endDt) <= 0) {
              activityGroupsTemp = [...activityGroupsTemp, groItem];
              handleDatesChange(activityGroupsTemp);
            }
          });
        }
      });
    } else if (startDt && (endDt?.length == 0 || endDt?.length == undefined)) {
      result?.find((item) => {
        if (item.id == group_id) {
          item?.activity?.inhouse_activity_groups?.forEach((groItem) => {
            if (getDiff(groItem?.start_date, startDt) >= 0) {
              activityGroupsTemp = [...activityGroupsTemp, groItem];
              handleDatesChange(activityGroupsTemp);
            }
          });
        }
      });
    } else if (!startDt && !endDt) {
      result?.find((item) => {
        if (item.id == group_id) {
          activityGroupsTemp = [...item?.activity?.inhouse_activity_groups];
          handleDatesChange(activityGroupsTemp);
        }
      });
    }
  };

  const GetActivityGroups = async (filterObj) => {
    setOpenLoader(true);
    const { res, err } = await getInHouseActivityGroupsList(
      group_id,
      filterObj
    );
    if (res) {
      setActivityGroups(res?.data?.inhouse_activity_groups);
      // setCount();
      // handleDatesRes(
      //   startDate,
      //   endDate,
      //   res?.data?.salesman?.assigned_activities
      // );
      // setActivityInfo(
      //   res?.data?.salesman?.assigned_activities?.find(
      //     (item) => item.id == group_id
      //   )?.activity
      // );

      setOpenLoader(false);
    }
    if (err) {
      setOpenLoader(false);
    }
  };

  useEffect(() => {
    GetActivityGroups({});
  }, []);
  return (
    <>
      <Loader open={openLoader} />
      <AssignedActivityGroupsTemplate
        getActivityGroups={GetActivityGroups}
        count={count}
        activityGroups={activityGroups}
        activityInfo={activityInfo}
        assignedActivityName={assignedActivityName}
        salesmanId={salesmanId}
        salesmanName={salesmanName}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        page={page}
        setPage={setPage}
      />
    </>
  );
};
export default AssignedActivityGroupsPage;
