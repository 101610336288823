import * as React from "react";
import { useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Grid, TextField } from "@mui/material";
import "./TimePicker.css";
// import moment from 'moment';
import dayjs from "dayjs";

export default function BasicTimePicker(props) {
  const {
    className,
    placeholder,
    setValue,
    value,
    startDate,
    setConcatTime,
    ...rest
  } = props;

  useEffect(() => {
    const timeInput = document.querySelector(".basic-time-picker-elem input");
    timeInput?.setAttribute("placeholder", "hh:mm (am/pm)");
  }, []);

  return (
    // <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Grid className={`${className} basic-time-picker-elem`}>
      <TimePicker
        label={placeholder}
        value={value}
        onChange={(newValue) => {
          const time = dayjs(newValue).format("HH:mm");
          const newDate = startDate ? startDate : dayjs().format("MM/DD/YYYY");
          const dateTime = dayjs(newDate + time).format();
          setValue(newValue);
          setConcatTime && setConcatTime(dateTime);
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </Grid>
    // </LocalizationProvider>
  );
}
