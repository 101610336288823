import { Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import ResetPasswordForm from '../../../Components/Organisms/Auth/ResetPassword/ResetPasswordForm'
import CustomSeparator from '../../../Components/Atoms/BreadCrumb'
import { withTranslation } from 'react-i18next'

const ResetPasswordTemplate = ({ t, ChangePassword }) => {
    return (

            <Stack container gap={2}>
                    <CustomSeparator breadcrumbs={[
                    <Link style={{ color: '#bebebe', textDecoration: 'none' }} underline="hover" key="1" color="inherit" to="/profile" >
                        {t('profile.profile')}
                    </Link>,
             
                    <Typography key="3" style={{ color: '#bebebe', textDecoration: 'none' }}>
                        {t('profile.resetPassword')}
                    </Typography>,
                ]} />
                <Typography component='h1'fontWeight='bold' fontSize='30px' > 
                {t('profile.resetPassword')}
                </Typography>
                <Grid
                className='activity-form' 
                    container
                    xl={7}
                    lg={7}
                    md={7}
                    sm={11}
                    xs={12}
          
                >
                    <ResetPasswordForm ChangePassword={ChangePassword} />
                </Grid>

            </Stack>
    )
}

export default withTranslation()(ResetPasswordTemplate)