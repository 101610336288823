import FreelancersList from "../../../Components/Organisms/Freelancers/FreelancersList";

const FreelancersListTemplate = ({
  count,
  freelancers,
  getFreelancers,
  freelancersPrivilages,
  filterObj,
  setFilterObj,
  profileData
}) => {
  return (
    <FreelancersList
      count={count}
      freelancers={freelancers}
      getFreelancers={getFreelancers}
      freelancersPrivilages={freelancersPrivilages}
      filterObj={filterObj}
      setFilterObj={setFilterObj}
      profileData={profileData}
    />
  );
};

export default FreelancersListTemplate;
