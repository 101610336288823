import { getActivitiesList } from "../../../../Contexts/APIs/Activities/Activities.js";
import { useEffect, useState } from "react";
import Loader from "../../../../Components/Atoms/Loader/Loader.js";
import SalesmenTemplate from "../../../../Templates/Main/Salesmen/SalesmenList/Salesmen.template.jsx";
import { getSalesmenList } from "../../../../Contexts/APIs/Salsemen/Salesmen.js";

const SalesmenPage = (props) => {
  const { t, profileData, privilages } = props;
  const [salesmenList, setSalesmenList] = useState([]);
  const [count, setCount] = useState();
  const [openLoader, setOpenLoader] = useState(false);

  const [filterObj, setFilterObj] = useState({
    "page_size": 10,
    "page_number": 1,
    "sort_by": "id"
  })

  const getSalesmenListing = async (filterObj) => {
    setOpenLoader(true);
    const { res, err } = await getSalesmenList(filterObj);
    if (res) {
      setSalesmenList(res?.data?.salesmen);
      setCount(res?.extra?.total_count);
    }
    if (err) {
    }
    setOpenLoader(false);
  };

  useEffect(() => {
    getSalesmenListing(filterObj);
  }, [filterObj]);
  return (
    <>
      <Loader open={openLoader} />
      <SalesmenTemplate
        salesmenList={salesmenList}
        count={count}
        privilages={privilages}
        setOpenLoader={setOpenLoader}
        getSalesmenListing={getSalesmenListing}
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        profileData={profileData}
        setSalesmenList={setSalesmenList}
      />
    </>
  );
};

export default SalesmenPage;
