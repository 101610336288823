import {
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
} from "@mui/material";
import React from "react";
import Select from "@mui/material/Select";
import "./Select.css";
import ClearIcon from "@mui/icons-material/Clear";

function SelectBox({
  items,
  disabledItemsState,
  title,
  value,
  onchange,
  id,
  name,
  error,
  helperText,
  handelOpen,
  defaultValue,
  style,
  disabled,
  className,
  placeholder,
  onclick = () => {},
  onClear = () => {},
}) {
  const ClearIndicator = () => {
    return (
      <IconButton
        onClick={(_) => {
          onClear();
        }}
        style={{
          position: "absolute",
          top: "calc(50% - 10px)",
          right: "35px",
          padding: "0",
          zIndex: "99",
          width: "30px",
        }}
      >
        <ClearIcon
          sx={{
            color: "hsl(0, 0%, 80%) !important",
            fontSize: "20px !important",
          }}
        />
      </IconButton>
    );
  };
  // const [value, setValue] = React.useState("");
  // const handleChange = (event) => {
  //   setValue(event.target.value);
  // };
  return (
    <FormControl
      disabled={disabled}
      fullWidth
      style={{ ...style, color: "black", position: "relative" }}
      error={error ? true : false}
    >
      <InputLabel style={{ color: "gray", fontSize: "18px" }}>
        {title}
      </InputLabel>

      <Select
        labelId="demo-simple-select-label"
        defaultValue={defaultValue}
        placeholder={placeholder}
        id={id}
        name={name}
        className={className}
        defaultChecked=""
        style={{ color: "black" }}
        value={value}
        label={title}
        onChange={onchange}
        onOpen={() => handelOpen && handelOpen()}
      >
        <MenuItem
          value=""
          disabled
          style={{
            direction: localStorage.getItem("lang") === "ar" ? "rtl" : "ltr",
          }}
        >
          {title}
        </MenuItem>
        {items?.map((item, index) => (
          <MenuItem
            value={item?.id}
            key={item?.id}
            onClick={() => onclick(item?.id, item?.name)}
            style={{
              direction: localStorage.getItem("lang") === "en" ? "ltr" : "rtl",
            }}
            disabled={disabledItemsState ? disabledItemsState[index] : null}
          >
            {item?.icon ? (
              <img
                style={{ margin: "0 10px" }}
                src={item?.icon}
                alt="item icon"
              />
            ) : (
              ""
            )}{" "}
            {item?.name}
          </MenuItem>
        ))}
      </Select>
      {(value !== undefined && value !== "") && !disabled && <ClearIndicator />}
      {error ? <FormHelperText>{helperText}</FormHelperText> : ""}
    </FormControl>
  );
}

export default SelectBox;
