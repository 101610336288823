import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Loader from "../../../../Components/Atoms/Loader/Loader.js";
import { showJobOffer } from "../../../../Contexts/APIs/Job-Offers/JobOffers";
import { toast } from "react-toastify";
import OfferApplicantTamplate from "../../../../Templates/Main/Freelancers/JopOffers/OfferApplicants.tamplate.jsx";

const OfferApplicantsPage = ({ jobRequestsPrivilages }) => {
  const { id } = useParams();
  const [jobOfferData, setJobOfferData] = useState({});
  const [openLoader, setOpenLoader] = useState(false);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState("");
  const getJobOfferData = async () => {
    setOpenLoader(true);
    const { res, err } = await showJobOffer(id, filter);
    if (res) setJobOfferData(res?.data?.job_offer);
    setOpenLoader(false);
  };

  useEffect(() => {
    getJobOfferData();
  }, [filter]);
  return (
    <>
      <Loader open={openLoader} />
      <OfferApplicantTamplate
        setFilter={setFilter}
        jobOfferData={jobOfferData}
        count={count}
        applicants={jobOfferData?.job_requests}
        getJobOffers={() => {}}
        jobRequestsPrivilages={jobRequestsPrivilages}
        is_navigate={false}
        title={`${
          jobOfferData?.title
        } - ${jobOfferData?.dive_expert_professional_level?.replace("_", " ")}`}
      />
    </>
  );
};

export default OfferApplicantsPage;
