/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import ActivityInfo from "../../Activities/Forms/ActivityInfo.jsx";
import ActivityDatesTimes from "../../Activities/Forms/ActivityDatesTimes.jsx";
import ActivityRequirement from "../../Activities/Forms/ActivityRequirement.jsx";
import ActivityMoreInfo from "../../Activities/Forms/ActivityMoreInfo.jsx";
import UploadImagesForm from "../../Forms/UploadImagesForm.jsx";
import LanguagesForm from "../../Forms/LanguagesForm.jsx";
import { InHouseActivityValidationSchema } from "./InHouseActivityFormValidation.js";
import { updateLocalizationsValues } from "../../../../Contexts/APIs/Helper/Helper.js";
import "./InHouseActivityForm.css";
import {
  createInHouseActivity,
  updateInHouseActivity,
} from "../../../../Contexts/APIs/Salsemen/InHouseActivities.js";

const InHouseActivityForm = (props) => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  const {
    t,
    uploadActivityImages,
    supportedLanguages,
    handleFormSubmit,
    branchesList,
    currencies,
    organizationsList,
    getCertificates,
    certificatesList,
    languagesList,
    activityImages,
    removeActivityImage,
    formType,
    activityDetails,
    getBranchOrganizations,
    getOrganization,
    getLanguagesAPI,
    getCurrencies,
    getBranches,
    languagesCount,
    setCertificatesList,
    certificatesCount,
    organizationsCount,
    profileData
  } = props;
  const [location, setLocation] = useState({
    lat: "",
    lng: "",
  });
  const [supportLang, setSupportLang] = useState("en");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startBorder, setStartBorder] = useState("");
  const [endBorder, setEndBorder] = useState("");
  const [dateType, setDateType] = useState(0);
  const [activityLanguages, setActivityLanguages] = useState([]);
  const [everyDayList, setEveryDayList] = useState([]);
  const [editableDateType, setEditableDateType] = useState();
  const [editableStartTime, setEditableStartTime] = useState();
  const [someWeekDays, setSomeWeekDays] = useState([]);
  const [everydaysStartTime, setEverydaysStartTime] = useState("");
  const [someDates, setSomeDates] = useState([]);
  const [someDatesData, setSomeDatesData] = useState([]);
  const [concatTime, setConcatTime] = useState("");
  const [tripName, setTripName] = useState([]);
  const ActivityIntialValues = {
    branch_name: "",
    currency: 1,
    price: "",
    number_of_tickets: "",
    days: "",
    hours: "",
    country_id: "",
    city_id: "",
    area_id: "",
    confirmation_state: "",
    max_divers: "",
    cancelation_lead_time: "",
    last_time_booking: "",
    organization: "",
    certificates: "",
    min_dives: "",
    min_age: "",
    name: "",
    description: "",
  };

  const getCurrentLocation = (lat, lng) => {
    setLocation({
      lat: lat,
      lng: lng,
    });
  };

  const DateValidation = (startDate, endDate) => {
    if (editableDateType != 2 && startDate === null) {
      setStartBorder("red-border");
    } else {
      setStartBorder("");
    }
    if (editableDateType != 2 && endDate === null) {
      setEndBorder("red-border");
    } else {
      setEndBorder("");
    }
  };
  const formik = useFormik({
    initialValues: formType == "add" ? {} : ActivityIntialValues,
    validationSchema: InHouseActivityValidationSchema(),
    onSubmit: async (values) => {
      const activityObj = {
        activity_language: activityLanguages,
        inhouse_activity_groups: dateType === 2 ? someDatesData : "",
        media: activityImages,
        scheduled_days:
          dateType == 0
            ? everyDayList
            : dateType == 1
            ? someWeekDays?.filter((val) => val?.start_time != "")
            : someDatesData,
        inhouse_activity: {
          organization_certificate_id: values.certificates,
          branch_id: values.branch_id,
          need_confirmation: values.confirmation_state === 1 ? true : false,
          capacity: values.number_of_tickets,
          min_age: values.min_age,
          scheduling_option: dateType, //scheduling_option: { everyday: 0, some_weekdays: 1, some_dates: 2 }
          reserve_before:
            values.deadline_time_unit == "day"
              ? values.last_time_booking * 24
              : values.last_time_booking,
          start_date:
            dateType != 2
              ? moment(new Date(startDate), "DD/MM/YYYY").format("DD-MM-YYYY")
              : "",
          end_date:
            dateType != 2
              ? moment(new Date(endDate), "DD/MM/YYYY").format("DD-MM-YYYY")
              : "",
          currency_id: values.currency,
          price: values.price,
          cancellation_notice_period:
            values?.time_unit == "day"
              ? values.cancelation_lead_time * 24
              : values.cancelation_lead_time,
          duration_days: values.days,
          duration_hours: values.hours,
          no_of_dives: values.no_of_dives,
          max_no_of_students: values.max_divers,
          is_available: true,
          allow_cash: true, //values?.allow_cash
          mobile_voucher: values?.mobile_voucher,

          name: values?.name,
          description: values?.description,
        },
      };
      if (formType === "add") {
        const { res } = await createInHouseActivity(activityObj);
        if (res) {
          navigate(`/salesmen/in-house-activities?page=${pageNumber}`);
        }
      } else {
        const { res, err } = await updateInHouseActivity(
          activityDetails?.id,
          activityObj
        );
        if (res) {
          navigate(`/salesmen/in-house-activities?page=${pageNumber}`);
        }
      }
    },
  });

  const handelErrorMsgs = () => {
    if (!formik?.values?.name) toast.error(t("activity.nameReq"));
    if (!formik?.values?.description) toast.error(t("activity.descriptionReq"));
  };

  const validationMethod = () => {
    DateValidation(startDate, endDate);
    const filteredDates = someWeekDays?.filter((val) => val?.start_time != "");
    if (activityLanguages.length == 0) {
      toast.error(t("activity.PleaseAddOneOrMoreLanguages"));
    } else if (activityImages?.length == 0) {
      toast.error(t("activity.pleaseUploadActivityImage"));
    } else if (!formik?.values?.name || !formik?.values?.description) {
      handelErrorMsgs();
    } else if (filteredDates?.length == 0 && dateType == 1) {
      toast.error(t("activity.pleaseChooseDays"));
    } else if (someDatesData?.length == 0 && dateType == 2) {
      toast.error(t("activity.pleaseChooseDates"));
    } else if (dateType != 2 && !moment(endDate).isSameOrAfter(startDate)) {
      toast.error(t("activity.PleaseAddCorrectDates"));
    } else {
      formik.handleSubmit();
    }
  };

  const getBranchInformation = (value) => {
    const branch_info = branchesList.filter((branch) => {
      return branch.id === value;
    });
  };
  const setOldData = () => {
    getBranchOrganizations(activityDetails?.branch_id);
    getCertificates({
      organization_id: activityDetails?.minimum_certificate?.organization?.id,
    });
    formik.values.name = activityDetails?.name;
    formik.values.description = activityDetails?.description;

    formik.values.branch_name = activityDetails?.branch?.name;
    formik.values.branch_id = activityDetails?.branch?.id;

    formik.values.price = activityDetails?.price;
    formik.values.number_of_tickets = activityDetails?.capacity;
    formik.values.currency = activityDetails?.currency_id;
    formik.values.days = activityDetails?.duration_days;
    formik.values.hours = activityDetails?.duration_hours;
    formik.values.confirmation_state =
      activityDetails?.need_confirmation === false ? 2 : 1;
    formik.values.max_divers = activityDetails?.max_no_of_students;
    formik.values.cancelation_lead_time =
      activityDetails?.cancellation_notice_period;
    formik.values.last_time_booking = activityDetails?.reserve_before;
    formik.values.country_id = activityDetails?.pickup_location?.country_id;
    formik.values.country_name =
      activityDetails?.pickup_location?.country?.name;
    formik.values.city_id = activityDetails?.pickup_location?.city_id;
    formik.values.city_name = activityDetails?.pickup_location?.city?.name;
    formik.values.area_id = activityDetails?.pickup_location?.area_id;
    formik.values.area_name = activityDetails?.pickup_location?.area_name;
    formik.values.organization =
      activityDetails?.completion_certificate?.organization?.id;
    formik.values.certificates = activityDetails?.completion_certificate?.id;
    formik.values.organization_name =
      activityDetails?.completion_certificate?.organization?.name;
    formik.values.certificates_name =
      activityDetails?.completion_certificate?.name;
    formik.values.min_age = activityDetails?.min_age;
    formik.values.no_of_dives = activityDetails?.no_of_dives;

    formik.values.mobile_voucher = activityDetails?.mobile_voucher;
    formik.values.allow_cash = activityDetails?.allow_cash;
    formik.values.time_unit = "hour";
    formik.values.deadline_time_unit = "hour";
    setTimeout(() => {
      if (activityDetails?.scheduled_days?.length) {
        setStartDate(activityDetails?.scheduled_days[0]?.start_date);
        setEverydaysStartTime(
          moment(activityDetails?.scheduled_days[0]?.start_time, "hh:mm a")
        );
      }
      setEndDate(activityDetails?.end_date);
      setStartDate(activityDetails?.start_date);
    }, 1000);
    setLocation({
      lat: activityDetails?.pickup_location?.lat,
      lng: activityDetails?.pickup_location?.long,
    });
    if (activityDetails?.scheduling_option === "everyday") {
      setEditableDateType(0);
      setEditableStartTime(activityDetails?.scheduled_days[0]?.start_time);
    }
    if (activityDetails?.scheduling_option === "some_dates") {
      setEditableDateType(2);
      if (activityDetails?.scheduled_days) {
        const obj = activityDetails?.inhouse_activity_groups?.map((val) => {
          return {
            start_date: val?.start_date,
            start_time: val?.start_time,
            reservations_count: val?.reservations_count,
          };
        });
        const obj_2 = activityDetails?.inhouse_activity_groups?.map((val) => {
          return val?.start_date;
        });
        setSomeDates(obj_2);
        setSomeDatesData(obj);
      }
    }
    if (activityDetails?.localized_data) {
      const obj = activityDetails?.localized_data[supportLang];
      for (let key in obj) {
        formik.values[`${key}_${supportLang}`] = obj[key];
      }
    }
    if (activityDetails?.scheduling_option === "some_weekdays") {
      setEditableDateType(1);
      if (activityDetails?.scheduled_days) {
        const obj = activityDetails?.scheduled_days?.map((val) => {
          return {
            weekday: val?.weekday,
            start_time: val?.start_time,
          };
        });
        setSomeWeekDays(obj);
      }
    }
  };
  useEffect(() => {
    handleFormSubmit.current = validationMethod;
  }, [
    activityImages,
    formik.values.name,
    formik.values.description,
    activityLanguages,
    startDate,
    endDate,
    dateType,
    someDatesData,
  ]);
  // }, [activityImages, formik.values, activityLanguages, startDate, endDate]);
  useEffect(() => {
    DateValidation(startDate, endDate);
  }, [startDate, endDate]);
  useEffect(() => {
    !formik.values.organization && setCertificatesList([]);
  }, [formik.values.organization]);
  useEffect(() => {
    setTimeout(() => {
      if (formType === "edit") {
        setOldData();
      }
    }, 4000);
  }, [formType, supportLang, activityDetails]);
  // useEffect(() => {
  //   if (formik?.values.organization?.toString().length > 0) {
  //     setTimeout(() => {
  //       getCertificates({
  //         organization_id: formik?.values?.organization,
  //         page_number: 1,
  //       });
  //     }, 1000);
  //   } else {
  //     getCertificates(null);
  //   }
  // }, [formik?.values?.organization]);
  return (
    <Grid
      container
      rowSpacing={1}
      columnSpacing={{ xs: 2 }}
      className="in-house-activity-form"
    >
      {/* <LanguagesSelect
        value={supportLang}
        supportedLanguages={supportedLanguages}
        onChange={(e) => updateSupportedLang(e, supportedLanguages)}
      /> */}
      <Grid item sm={12} lg={6}>
        <ActivityInfo
          branchesList={branchesList}
          getBranchOrganizations={getBranchOrganizations}
          formik={formik}
          supportedLanguages={supportedLanguages}
          supportLang={supportLang}
          currencies={currencies}
          getCurrencies={getCurrencies}
          tripName={tripName}
          setTripName={(e, i) =>
            setTripName(updateLocalizationsValues(e, i, tripName))
          }
          getBranchInformation={getBranchInformation}
          getCurrentLocation={getCurrentLocation}
          isInHouseActivity={true}
          activityDetails={activityDetails}
          getBranches={getBranches}
          profileData={profileData}
          isAssignedToOneBranch={profileData?.branch?.name}
          formType={formType}
        />

        <ActivityDatesTimes
          activityDetails={activityDetails}
          formik={formik}
          startDate={startDate}
          endDate={endDate}
          setStartDate={(date) => setStartDate(date)}
          setEndDate={(date) => setEndDate(date)}
          concatTime={concatTime}
          setConcatTime={setConcatTime}
          startBorder={startBorder}
          endBorder={endBorder}
          dateType={dateType}
          setDateType={(value) => setDateType(value)}
          setEveryDayList={(list) => setEveryDayList(list)}
          editableDateType={editableDateType}
          formType={formType}
          editableStartTime={editableStartTime}
          setSomeWeekDays={setSomeWeekDays}
          someWeekDays={someWeekDays}
          everydaysStartTime={everydaysStartTime}
          setEverydaysStartTime={setEverydaysStartTime}
          someDates={someDates}
          setSomeDates={setSomeDates}
          setSomeDatesData={setSomeDatesData}
          someDatesData={someDatesData}
          type="inhouse"
        />

        <ActivityMoreInfo
          formik={formik}
          supportedLanguages={supportedLanguages}
          supportLang={supportLang}
          isInHouseActivity={true}
        />
      </Grid>
      <Grid item md={6}>
        <UploadImagesForm
          uploadImages={uploadActivityImages}
          formTitle={t("activity.activityImages")}
          Images={activityImages}
          RemoveImage={removeActivityImage}
        />

        <ActivityRequirement
          formik={formik}
          organizationsList={organizationsList}
          getCertificates={getCertificates}
          certificatesList={certificatesList}
          getOrganization={getOrganization}
          certificatesCount={certificatesCount}
          organizationsCount={organizationsCount}
          setCertificatesList={setCertificatesList}
        />
        <LanguagesForm
          formTitle={t("activity.activityLanguages")}
          languagesList={languagesList}
          getLanguagesAPI={getLanguagesAPI}
          languagesCount={languagesCount}
          activityLanguages={activityDetails?.inhouse_activity_languages}
          getActivityLanguages={(languages) => setActivityLanguages(languages)}
        />
      </Grid>
    </Grid>
  );
};

export default withTranslation()(InHouseActivityForm);
