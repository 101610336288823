import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../Components/Atoms/Loader/Loader";
import { AccountSchema } from "../../Components/Organisms/Auth/Account/Forms/validation";
import { completeAccount } from "../../Contexts/APIs/Auth/CompleteAccount";
import { getDiveCenterDetails } from "../../Contexts/APIs/DiveCenter/DiveCenter";
import {
  getPresignedLinkAPI,
  uploadFileAPI,
} from "../../Contexts/APIs/General/upload";
import { CityAPI } from "../../Contexts/APIs/Lookups/City";
import { CountryAPI } from "../../Contexts/APIs/Lookups/Country";
import { CurrencyAPI } from "../../Contexts/APIs/Lookups/Currency";
import { LanguageAPI } from "../../Contexts/APIs/Lookups/Language";
import CompleteAccountTemplate from "../../Templates/Auth/CompleteAccount.Template";
import { withTranslation } from "react-i18next";
import ReviewModal from "../../Components/Molecules/General/Models/ReviewModal";
import { images } from "../../Assets/AssetHelper";

const CompleteAccountPage = ({ t }) => {
  const fcm_token = localStorage.getItem("fcm_token");

  const [searchParams, setSearchParams] = useSearchParams();
  const [languagesList, setLanguagesList] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  // const [countryKey, setCountryKey] = useState('');
  const [uploadedContract, setUploadedContract] = useState(null);

  const [activityImages, setActivityImages] = useState();
  const [openLoader, setOpenLoader] = useState(false);
  const [diveCenterDetails, setDiveCenterDetails] = useState({});
  const [open, setOpen] = useState(false);
  const [primaryLanguage, setPrimaryLanguage] = useState({});
  const [contractName, setContractName] = useState(null);
  const handleClose = () => setOpen(false);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const getLanguagesAPI = async () => {
    const { res, err } = await LanguageAPI({
      page_size: 10000,
      page_number: 1,
    });
    if (res) setLanguagesList(res?.data?.languages);
  };
  const getCurrencies = async () => {
    const { res, err } = await CurrencyAPI({
      page_size: 10000,
      page_number: 1,
      is_payout_currency: true,
    });
    if (res) {
      res?.data?.currencies.map((currency) => {
        setCurrencies((currencies) => [
          ...currencies,
          { name: currency?.lookup_key, id: currency?.id },
        ]);
      });
    }
  };
  const getCities = async (filter) => {
    setCities([]);
    const { res, err } = await CityAPI({
      page_size: 10000,
      page_number: 1,
      // country_id: id,
      ...filter
    });
    if (res) {
      res?.data?.cities.map((currency) => {
        setCities((cities) => [
          ...cities,
          { name: currency?.name, id: currency?.id },
        ]);
      });
    }
  };
  const getCountries = async (filter) => {
    const { res, err } = await CountryAPI({ page_size: 10000, page_number: 1, ...filter });
    if (res) {
      setCountries(res?.data?.countries);
    }
  };

  const CompleteAccount = async (type, data, user_id) => {
    const { res, err } = await completeAccount(type, data, user_id);
    if (res) {
      // window.location.replace("/pending-review");
      setOpenReviewModal(true);
    }
  };

  const DiveCenterDetails = async () => {
    const { res, err } = await getDiveCenterDetails(
      localStorage.getItem("userId"),{}
    );
    if (res) {
      setDiveCenterDetails(res?.data.user);
    }
  };

  useEffect(() => {
    getLanguagesAPI();
    getCurrencies();
    getCountries();
  }, []);

  useEffect(() => {
    if (
      searchParams.get("status") == "recomplete" &&
      localStorage.getItem("userId")
    ) {
      DiveCenterDetails();
    }
  }, [
    searchParams.get("status") == "recomplete",
    localStorage.getItem("userId"),
  ]);

  useEffect(() => {
    if (diveCenterDetails) {
      setActivityImages({
        media_type: "photo",
        url: diveCenterDetails?.logo,
      });
      setUploadedContract({
        media_type: "file",
        url: diveCenterDetails?.signed_contract,
      });
      setPrimaryLanguage({
        label: diveCenterDetails?.primary_language?.name,
        value: diveCenterDetails?.primary_language?.id,
      });
    }
  }, [diveCenterDetails]);
  const uploadActivityImages = async (event) => {
    const file = event.target.files[0];
    const fileSize = file.size / window.maxFileSize; // in MiB
    if (fileSize > 1) {
      toast.warning(window.fileSize);
    } else {
      setOpenLoader(true);
      const files = [{ extension: "png" }];
      const res = await getPresignedLinkAPI(files);
      const presignedUrl = res?.data[0].presigned_url;
      const upload = await uploadFileAPI(presignedUrl, file);
      if (upload) {
        setActivityImages({
          media_type: "photo",
          url: res?.data[0]?.file_url,
        });
      }
      setOpenLoader(false);
    }
  };

  // uploadActivityImagesDimension : function for test image size and dimension
  /*const uploadActivityImagesDimension = async (event, str = "") => {
        const file = event.target.files[0];
        const fileSize = file.size / window.maxFileSize; // in MiB
        
        let fileHeight = 0, fileWidth = 0;
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = function (e) {
            var image = new Image();
            const maxImageDimension = 1000;
            image.src = e.target.result;
            image.onload = function () {
                fileHeight = this.height;
                fileWidth = this.width;
                if( fileHeight <= maxImageDimension && fileWidth <= maxImageDimension && fileSize > 1 ){
                    toast.warning(`${window.fileSize}`);
                }
                if( (fileHeight > maxImageDimension || fileWidth > maxImageDimension) && fileSize <= 1 ){
                    toast.warning(`Image width & height must less than ${maxImageDimension}px`);
                }
                if( (fileHeight > maxImageDimension || fileWidth > maxImageDimension) && fileSize > 1 ){
                    toast.warning(`${window.fileSize}, Image width & height must less than ${maxImageDimension}px`);
                }
            };
            fileHeight = 0; fileWidth = 0;
        };

        if (fileHeight <= 1000 && fileWidth <= 1000 && fileSize <= 1){
          setOpenLoader(true);
          const files = [{ extension: "png" }];
          const res = await getPresignedLinkAPI(files);
          const presignedUrl = res?.data[0].presigned_url;
          const upload = await uploadFileAPI(presignedUrl, file);
          if (upload) {
            setActivityImages({
              media_type: "photo",
              url: res?.data[0]?.file_url,
            });
          }
          setOpenLoader(false);
        }
    };*/

  const uploadContract = async (event) => {
    const file = event.target.files[0];
    const fileSize = file.size / window.maxFileSize; // in MiB
    if (fileSize > 1) {
      toast.warning(window.fileSize);
    } else {
      setOpenLoader(true);
      const files = [{ extension: "pdf" }];
      const res = await getPresignedLinkAPI(files);
      const presignedUrl = res?.data[0].presigned_url;

      const upload = await uploadFileAPI(presignedUrl, file);
      if (upload) {
        setUploadedContract({
          media_type: "file",
          url: res?.data[0]?.file_url,
        });
        setContractName(event?.target?.files[0]?.name);
      }
      setOpenLoader(false);
    }
  };
  const removeActivityImage = (i) => {
    setActivityImages();
  };

  const getPropsHasValueLength = (obj, counter) => {
    for (let prop in obj) {
      if (obj[prop] != undefined && obj[prop] != null && obj[prop] != "") {
        counter++;
      }
    }
    return counter;
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: AccountSchema,
    initialValues: {
      // "logo": activityImages?.url,
      fr: {
        name: diveCenterDetails?.name,
        about_us: diveCenterDetails?.about_us,
      },
      en: {
        name: diveCenterDetails?.name,
        about_us: diveCenterDetails?.about_us,
      },
      country_code: diveCenterDetails?.country_code,
      phone_number: diveCenterDetails?.phone_number,
      primary_language_id: primaryLanguage,
      name: diveCenterDetails?.name,
      email: diveCenterDetails?.email,
      main_office_address: diveCenterDetails?.main_office_address,
      allow_outsider_freelancers:
        diveCenterDetails?.allow_outsider_freelancers || false,
      owner_email: diveCenterDetails?.owner_email,
      owner_name: diveCenterDetails?.owner_name,
      owner_country_code: diveCenterDetails?.owner_country_code,
      owner_phone_number: diveCenterDetails?.owner_phone_number,

      facebook: diveCenterDetails?.facebook,
      website: diveCenterDetails?.website,
      contact_person_name: diveCenterDetails?.contact_person_name,
      contact_person_email: diveCenterDetails?.contact_person_email,
      contact_person_phone_number:
        diveCenterDetails?.contact_person_phone_number,
      contact_person_country_code:
        diveCenterDetails?.contact_person_country_code,
      bank_account_attributes: {
        beneficiary_name: diveCenterDetails?.bank_account?.beneficiary_name,
        beneficiary_address:
          diveCenterDetails?.bank_account?.beneficiary_address,
        iban: diveCenterDetails?.bank_account?.iban,
        swift_code: diveCenterDetails?.bank_account?.swift_code,
        country_id: diveCenterDetails?.bank_account?.country?.id,
        city_id: diveCenterDetails?.bank_account?.city?.id,
        currency_id: diveCenterDetails?.bank_account?.currency?.id,
        beneficiary_account_number:
          diveCenterDetails?.bank_account?.beneficiary_account_number,
        bank_name: diveCenterDetails?.bank_account?.bank_name,
        bank_address: diveCenterDetails?.bank_account?.bank_address,
      },
    },

    onSubmit: (values) => {
      const data = {
        user: {
          ...values,
          logo: activityImages?.url,
          signed_contract: uploadedContract?.url,
          primary_language_id: values.primary_language_id.value,
        },
        device: {
          uuid: "dummy",
          device_type: "web",
          fcm_token: fcm_token,
        },
      };
      const user_id = localStorage.getItem("userId");

      let propsCounter = 0;
      const bankPropsHasValueLength = getPropsHasValueLength(
        values.bank_account_attributes,
        propsCounter
      );

      if (
        activityImages?.url &&
        uploadedContract?.url &&
        (bankPropsHasValueLength ===
          Object.keys(values.bank_account_attributes).length ||
          bankPropsHasValueLength === 0)
      ) {
        if (bankPropsHasValueLength == 0) {
          delete data.user.bank_account_attributes;
        }
        CompleteAccount("dive_centers", data, user_id);
      } else if (!uploadedContract?.url) {
        toast.error(t("profile.pleaseUploadContract"));
      } else if (
        bankPropsHasValueLength !==
          Object.keys(values.bank_account_attributes).length &&
        bankPropsHasValueLength !== 0
      ) {
        toast.error(t("profile.pleaseFillAllBank"));
      } else toast.error(t("profile.pleaseUploadLogo"));
    },
  });

  useEffect(() => {
    setTimeout(() => {
      getCities({country_id: formik?.values?.bank_account_attributes?.country_id});
    }, 1000);
  },[formik?.values?.bank_account_attributes?.country_id])

  return (
    <>
      <Loader open={openLoader} />
      <CompleteAccountTemplate
        formik={formik}
        activityImages={activityImages}
        uploadActivityImages={uploadActivityImages}
        removeActivityImage={removeActivityImage}
        setContract={uploadContract}
        setUploadedContract={setUploadedContract}
        contractName={contractName}
        setContractName={setContractName}
        cities={cities}
        countries={countries}
        languagesList={languagesList}
        getLanguagesAPI={getLanguagesAPI}
        currencies={currencies}
        CompleteAccount={CompleteAccount}
        getCountries={getCountries}
        getCities={getCities}
        setCities={setCities}
        // setCountryKey={setCountryKey}
      />

      {openReviewModal === true && (
        <ReviewModal
          open={openReviewModal}
          setOpen={setOpenReviewModal}
          handleRequest={() => {
            localStorage.setItem("Token", localStorage.getItem("accessToken"));
            window.location.replace("/");
            setOpenReviewModal(false);
          }}
          logo={images.CompleteLogo}
          text={[
            t("profile.accountSubmittedReviewed"),
            t("profile.accountSubmittedReviewedMsg1"),
            `${t("profile.accountSubmittedReviewedMsg2")} "${t(
              "profile.branches"
            )}" ,"${t("profile.activities")}" ${t("profile.and")} "${t(
              "profile.jobOffers"
            )}" ${t("profile.accountSubmittedReviewedMsg3")}`,
          ]}
          buttons={[t("profile.ok")]}
        />
      )}
    </>
  );
};

export default withTranslation()(CompleteAccountPage);
