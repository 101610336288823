import React from "react";
import AddInHouseActivityTemplate from "../../../../Templates/Main/Salesmen/InHouseActivities/AddInHouseActivity.template";
import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import Loader from "../../../../Components/Atoms/Loader/Loader";
import { toast } from "react-toastify";
import {
  getPresignedLinkAPI,
  uploadFileAPI,
} from "../../../../Contexts/APIs/General/upload";
import { getSupportedLanguages } from "../../../../Contexts/APIs/Lookups/SupportedLanguages";
import { getBranchesList } from "../../../../Contexts/APIs/Branches/Branches";
import { CurrencyAPI } from "../../../../Contexts/APIs/Lookups/Currency";
import { OrganizationsAPI } from "../../../../Contexts/APIs/Lookups/Certificates/Organizations";
import { getCertificates } from "../../../../Contexts/APIs/Lookups/OrganizationCertificates";
import { LanguageAPI } from "../../../../Contexts/APIs/Lookups/Language";

import {
  createInHouseActivity,
  getInHouseActivityDetails,
} from "../../../../Contexts/APIs/Salsemen/InHouseActivities";

const AddInHouseActivityPage = (props) => {
  const { t, type,profileData } = props;
  const [supportedLanguages, setSupportedLanguages] = useState([]);
  const [branchesList, setbranchesList] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [organizationsList, setOrganizationsList] = useState([]);
  const [certificatesList, setCertificatesList] = useState([]);
  const [languagesList, setLanguagesList] = useState([]);
  const [languagesCount, setLanguagesCount] = useState(10);
  const [certificatesCount, setCertificatesCount] = useState(10);
  const [organizationsCount, setOrganizationsCount] = useState(10);

  const [activityImages, setActivityImages] = useState([]);
  const [openLoader, setOpenLoader] = useState(false);
  const Navigate = useNavigate();
  const { id } = useParams();
  const [search] = useSearchParams();
  const [activityDetails, setActivityDetails] = useState({});
  const userId =
    localStorage.getItem("userType") !== "descendant_user"
      ? localStorage.getItem("userId")
      : localStorage.getItem("ancestorId");

  const uploadActivityImages = async (event) => {
    const file = event.target.files[0];
    const fileSize = file.size / window.maxFileSize; // in MiB
    if (fileSize > 1) {
      toast.warning(window.fileSize);
    } else {
      setOpenLoader(true);
      const files = [{ extension: "png" }];
      const res = await getPresignedLinkAPI(files);
      const presignedUrl = res?.data[0].presigned_url;
      const upload = await uploadFileAPI(presignedUrl, file);
      if (upload) {
        setActivityImages([
          ...activityImages,
          {
            media_type: 0,
            url: res?.data[0]?.file_url,
          },
        ]);
      }
      setOpenLoader(false);
    }
  };

  const getLanguages = async () => {
    const { res, err } = await getSupportedLanguages();
    if (res) {
      setSupportedLanguages(res?.data?.supported_locales);
    }
  };

  const getBranches = async (filter) => {
    const { res, err } = await getBranchesList({
      page_size: 10,
      page_number: 1,
      dive_center_id: userId,
      branch_type: search.get("type") == 2 ? "safari_boat" : "",
      // status: "approved",
      ...filter,
    });
    if (res) {
      setbranchesList(res?.data?.branches);
    }
  };

  const getCurrencies = async (filter) => {
    const { res, err } = await CurrencyAPI({
      page_size: 100,
      page_number: 1,
      ...filter,
    });
    if (res) {
      res?.data?.currencies.map((currency) => {
        setCurrencies((currencies) => [
          ...currencies,
          { name: currency?.lookup_key?.toUpperCase(), id: currency?.id },
        ]);
      });
    }
  };

  const getBranchOrganizations = async (id) => {
    const { res, err } = await OrganizationsAPI({
      page_size: 10,
      branch_id: id,
    });
    if (res) {
      setOrganizationsList(res?.data?.organizations);
    }
    if (err) {
    }
  };

  const getOrganization = async (filter) => {
    const { res, err } = await OrganizationsAPI({
      page_size: 10,
      page_number: 1,
      ...filter,
    });
    if (res) {
      filter?.page_number == 1
        ? setOrganizationsList(res?.data?.organizations)
        : setOrganizationsList((s) => [...s, ...res?.data?.organizations]);
      setOrganizationsCount(res?.extra?.total_count);
    }
    if (err) {
    }
  };

  const getCertificatesList = async (filter) => {
    const { res, err } = filter?.organization_id
      ? await getCertificates({
          page_size: 10,
          page_number: 1,
          ...filter,
        })
      : { res: null, err: null };
    if (res) {
      if (filter?.page_number == 1) {
        setCertificatesList(res?.data?.organization_certificates);
      } else {
        setCertificatesList((s) => [
          ...s,
          ...res?.data?.organization_certificates,
        ]);
      }
      setCertificatesCount(res?.extra?.total_count);
    }
  };

  const getLanguagesAPI = async (filter) => {
    const { res, err } = await LanguageAPI({
      page_size: 10,
      page_number: 1,
      ...filter,
    });
    if (res) {
      filter?.page_number == 1
        ? setLanguagesList(res?.data?.languages)
        : setLanguagesList((s) => [...s, ...res?.data?.languages]);

      setLanguagesCount(res?.extra?.total_count);
    }
  };

  const removeActivityImage = (i) => {
    if (i > -1) {
      activityImages.splice(i, 1);
      setActivityImages([...activityImages]);
    }
  };

  const ActivityDetails = async () => {
    const { res, err } = await getInHouseActivityDetails(id);
    if (res) {
      setActivityDetails(res?.data?.inhouse_activity);
    }
  };

  useEffect(() => {
    getLanguages();

    getCurrencies();
    // organizationsList.length == 0 && getOrganization();
    getLanguagesAPI();
    if (type === "edit") {
      ActivityDetails();
    }
  }, []);

  useEffect(() => {
    if (type === "edit") {
      const newList = [];
      activityDetails?.media?.map((image) => {
        newList.push({
          url: image.url,
          media_type: image.media_type,
        });
      });
      setActivityImages(newList);
    }
  }, [type, activityDetails]);

  return (
    <>
      <Loader open={openLoader} />

      <AddInHouseActivityTemplate
        getLanguagesAPI={getLanguagesAPI}
        languagesCount={languagesCount}
        uploadActivityImages={uploadActivityImages}
        supportedLanguages={supportedLanguages}
        branchesList={branchesList}
        currencies={currencies}
        getCurrencies={getCurrencies}
        organizationsList={organizationsList}
        getBranchOrganizations={getBranchOrganizations}
        getOrganization={getOrganization}
        getCertificates={getCertificatesList}
        certificatesList={certificatesList}
        languagesList={languagesList}
        activityImages={activityImages}
        removeActivityImage={removeActivityImage}
        type={type}
        activityDetails={activityDetails}
        getBranches={getBranches}
        setCertificatesList={setCertificatesList}
        certificatesCount={certificatesCount}
        organizationsCount={organizationsCount}
        profileData={profileData}
      />
    </>
  );
};

export default AddInHouseActivityPage;
