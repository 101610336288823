import { Grid, Typography, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";

import SelectBox from "../../Atoms/Select/Select.jsx";
import TextFieldInput from "../../Atoms/Input/TextField/TextFieldInput.jsx";
import { FreeLancerStatus } from "../../../Contexts/APIs/Lookups/freelancerStatus.js";
import { withTranslation } from "react-i18next";
import BranchesFilter from "../../Organisms/Statistics/BranchesFilter.jsx";

const FreelancersHeaders = ({ t, handleChange, filterObj, setFilterObj,profileData }) => {
  const [statusList, setStatusList] = useState([]);
  const getStatus = async () => {
    const { res, err } = await FreeLancerStatus();
    if (res) setStatusList([{ name: "All", id: "All" }, ...res.statuses]);
  };

  return (
    <Grid container>
      <Grid container>
        <Grid item sm={4}>
          <Typography
            component="h1"
            style={{ fontWeight: "bold", fontSize: "30px" }}
          >
            {t("joboffer.freelancers")}
          </Typography>
        </Grid>
        <Grid container item sm={8} justifyContent={'space-between'}>
          <Grid xs={4}>
            <TextFieldInput
              type="search"
              placeholder={t("joboffer.searchFreelancers")}
              StartAdornment={<SearchIcon />}
              className="reservation-search"
              onChange={(e) => handleChange("keyword", e.target.value)}
              sx={{ padding: "0px!important" }}
            />
          </Grid>
          <Grid xs={0.5}></Grid>
          <Grid xs={2}>
            <Grid sx={{ mb: "20px" }} className="border-r-10px">
              <BranchesFilter setFilterObj={setFilterObj} filterObj={filterObj} profileData={profileData} />
            </Grid>
          </Grid>
          <Grid xs={0.5}></Grid>
          <Grid xs={3}>
            <SelectBox
              id="branches"
              name="branches"
              items={statusList}
              title={t("joboffer.selectStatus")}
              className="select"
              onchange={(e) => handleChange("status", e.target.value)}
              handelOpen={() => statusList?.length == 0 && getStatus()}
            />
          </Grid>
        </Grid>
      </Grid>
      <Divider style={{ width: "100%", margin: "20px 0" }} />
    </Grid>
  );
};

export default withTranslation()(FreelancersHeaders);
