import { Grid, Typography } from "@mui/material";
import { useRef } from "react";
import "../../Activities/ActivitiesList.css";
import { Link, useSearchParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import InHouseActivityTabs from "../../../Molecules/Salesmen/InHouseActivities/InHouseActivityTabs.jsx";
import AddInHouseActivityPanel from "../../../Molecules/Salesmen/InHouseActivities/AddInHouseActivityPanel.jsx";
import CustomSeparator from "../../../Atoms/BreadCrumb";

const AddInHouseActivityForm = (props) => {
  const {
    t,
    uploadActivityImages,
    supportedLanguages,
    branchesList,
    currencies,
    organizationsList,
    getCertificates,
    certificatesList,
    languagesList,
    activityImages,
    removeActivityImage,
    type,
    activityDetails,
    getBranchOrganizations,
    getOrganization,
    getLanguagesAPI,
    getCurrencies,
    getBranches,
    languagesCount,
    setCertificatesList,
    certificatesCount,
    organizationsCount,
    profileData
  } = props;
  let [searchParams, setSearchParams] = useSearchParams();
  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");

  const handleFormSubmit = useRef(null);
  
  return (
    <Grid container className="add-activity-container">
      <Grid container m={"0px 0px 10px"}>
        <CustomSeparator
          breadcrumbs={[
            <Link
              style={{ color: "#bebebe", textDecoration: "none" }}
              underline="hover"
              key="2"
              color="inherit"
              to={`/salesmen?page=${pageNumber}`}
            >
              {t("salesmen.salesmen")}
            </Link>,
            <Link
              style={{ color: "#bebebe", textDecoration: "none" }}
              underline="hover"
              key="2"
              color="inherit"
              to={`/salesmen/in-house-activities?page=${pageNumber}`}
            >
              {t("salesmen.inHouseActivity")}
            </Link>,
            <Typography
              key="3"
              style={{ color: "#bebebe", textDecoration: "none" }}
            >
              {type === "add"
                ? t("salesmen.addNewInhouseActivity")
                : type === "edit"
                ? `${t("salesmen.editInHouseActivity")}`
                : null}
            </Typography>,
          ]}
        />
      </Grid>

      <InHouseActivityTabs
        type={type}
        handleSubmit={() => handleFormSubmit.current()}
      />
      <AddInHouseActivityPanel
        uploadActivityImages={uploadActivityImages}
        supportedLanguages={supportedLanguages}
        handleFormSubmit={handleFormSubmit}
        branchesList={branchesList}
        currencies={currencies}
        getCurrencies={getCurrencies}
        getBranchOrganizations={getBranchOrganizations}
        getOrganization={getOrganization}
        organizationsList={organizationsList}
        getCertificates={getCertificates}
        certificatesList={certificatesList}
        languagesList={languagesList}
        getLanguagesAPI={getLanguagesAPI}
        languagesCount={languagesCount}
        activityImages={activityImages}
        removeActivityImage={removeActivityImage}
        type={type}
        activityDetails={activityDetails}
        getBranches={getBranches}
        setCertificatesList={setCertificatesList}
        certificatesCount={certificatesCount}
        organizationsCount={organizationsCount}
        profileData={profileData}
      />
    </Grid>
  );
};

export default withTranslation()(AddInHouseActivityForm);
