import { Stack, Typography } from "@mui/material";
// import ActivitiesList from "../../../Components/Organisms/Activities/ActivitiesList.jsx";
import SalesmenList from "../../../../Components/Organisms/Salesmen/SalesmenList.jsx";
import ListHeader from "../../../../Components/Organisms/Salesmen/ListHeader.jsx";
import CustomSeparator from "../../../../Components/Atoms/BreadCrumb/index.jsx";
import { Link, useSearchParams } from "react-router-dom";
import { withTranslation } from "react-i18next";

const SalesmenTemplate = (props) => {
  const {
    t,
    salesmenList,
    count,
    getSalesmen,
    privilages,
    setOpenLoader,
    getSalesmenListing,
    filterObj,
    setFilterObj,
    profileData,
    setSalesmenList
  } = props;
  let [searchParams, setSearchParams] = useSearchParams();
  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  return (
    <Stack>
      <CustomSeparator
        breadcrumbs={[
          <Link
            style={{ color: "#bebebe", textDecoration: "none" }}
            underline="hover"
            key="1"
            color="inherit"
            to={`/salesmen?page=${pageNumber}`}
          >
            {t("salesmen.salesmen")}
          </Link>,
          <Typography
            key="2"
            style={{ color: "#bebebe", textDecoration: "none" }}
          >
            {t("salesmen.salesmenList")}
          </Typography>,
        ]}
      />
      <ListHeader
        privilages={privilages}
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        profileData={profileData}
      />
      <SalesmenList
        salesmenList={salesmenList}
        count={count}
        getSalesmen={getSalesmen}
        privilages={privilages}
        setOpenLoader={setOpenLoader}
        getSalesmenListing={getSalesmenListing}
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        setSalesmenList={setSalesmenList}
      />
    </Stack>
  );
};

export default withTranslation()(SalesmenTemplate);
