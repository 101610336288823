import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loader from "../../../../Components/Atoms/Loader/Loader";
import { getJobOffersList } from "../../../../Contexts/APIs/Job-Offers/JobOffers";
import JobOffersTemplate from "../../../../Templates/Main/Freelancers/JopOffers/JobOffers.Template";

const JobOffersPage = ({ joboffersPrivilages, jobRequestsPrivilages,profileData}) => {
  const [count, setCount] = useState(0);
  const [openLoader, setOpenLoader] = useState(false);
  const [offers, setOffers] = useState([]);
  const [filterObj, setFilterObj] = useState({
    page_number: 1,
    job_type: "",
    language_ids: "",
    keyword: "",
    "price[from]": "",
    "price[to]": "",
    city_id: "",
    dive_center_id: "",
    "date[from]": "",
    "date[to]": "",
    organization_id: "",
    review_stars: "",
    sort_by: "id",
  });
  const getJobOffers = async (filter) => {
    setOpenLoader(true);
    const { res, err } = await getJobOffersList(filter);
    if (res) {
      setCount(res?.extra?.total_count);
      setOffers(res?.data?.job_offers);
    }
    setOpenLoader(false);
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      getJobOffers(filterObj);
    }, 500);
    return () => clearTimeout(timeId);
  }, [filterObj]);
  return (
    <>
      <Loader open={openLoader} />
      <JobOffersTemplate
        count={count}
        offers={offers}
        getJobOffers={getJobOffers}
        joboffersPrivilages={joboffersPrivilages}
        jobRequestsPrivilages={jobRequestsPrivilages}
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        profileData={profileData}
        setOffers={setOffers}
      />
    </>
  );
};

export default JobOffersPage;
