import { Grid } from "@mui/material";
import React from "react";
import PrimaryButton from "../../../../Atoms/Buttons/Primary/PrimaryButton";
import SearchableSelect from "../../../../Atoms/Select/SearchableSelect";
import "./SelectUserSuper.css";
import { withTranslation } from "react-i18next";

function SelectUserSuper(props) {
  const { formik, usersListSuper, t, getUsersData ,userListCount} = props;

  return (
    <Grid item xs={12} mt={10}>
      <form
        style={{ margin: "auto", width: "100%" }}
        className="select-user-form"
      >
        <Grid item className="mb-20px" xs={9} style={{ margin: "auto" }}>
          <SearchableSelect
            name="user_id"
            getList={getUsersData}
            options={usersListSuper}
            label={t("profile.selectUser")}
            className="searchable-select"
            handelMenuOpen={getUsersData}
            error={formik?.touched?.user_id && Boolean(formik?.errors?.user_id)}
            helperText={formik?.touched?.user_id && formik?.errors?.user_id}
            count={userListCount}
            onChange={(val) => {
              formik.setValues({
                ...formik?.values,
                user_id: val?.value,
                user_name: val?.label,
              });
            }}
            value={{
              label: formik?.values?.user_name,
              value: formik?.values?.user_id,
            }}
            onClear={() => {
              formik.setValues({
                ...formik?.values,
                user_id: "",
                user_name: "",
              });
            }}
          />
        </Grid>

        <PrimaryButton
          text={t("profile.logIn")}
          classBtn="primary select-user-form-submit"
          style={{ padding: "5px 50px", margin: "auto", disblay: "block" }}
          click={formik.handleSubmit}
        />
      </form>
    </Grid>
  );
}

export default withTranslation()(SelectUserSuper);
