import React from 'react'
import Users from '../../../Components/Organisms/Users/Users.jsx'

function UsersTemplate(props) {
  const { filterObj, setFilterObj, usersList, setUsersList, openLoader, setOpenLoader, count, page, handlePageChange, usersPrivilages,profileData } = props;
  return (
    <>
      <Users
        filterObj={filterObj} setFilterObj={setFilterObj}
        usersList={usersList} setUsersList={setUsersList}
        openLoader={openLoader} setOpenLoader={setOpenLoader}
        count={count} page={page}
        handlePageChange={handlePageChange}
        usersPrivilages={usersPrivilages}
        profileData={profileData}
      />
    </>
  )
}

export default UsersTemplate