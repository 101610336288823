import React, { useState, useEffect } from "react"
import { Grid, LinearProgress, linearProgressClasses, Rating, styled, Typography } from "@mui/material"
import { PanoramaFishEye, Circle } from '@mui/icons-material'

const ProgressRating = ({reviews}) => {
    const [total, setTotal] = useState()
    const [star5, setStar5] = useState()
    const [star4, setStar4] = useState()
    const [star3, setStar3] = useState()
    const [star2, setStar2] = useState()
    const [star1, setStar1] = useState() 

    const rating = [
        {
            star: 5,
            rate: star5
        },
        {
            star: 4,
            rate: star4
        },
        {
            star: 3,
            rate: star3
        },
        {
            star: 2,
            rate: star2
        },
        {
            star: 1,
            rate: star1
        }
    ];

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 8,
        borderRadius: 4.1,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: `#e1e7ec`,
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 4.1,
            backgroundColor: '#3ed181',
        },
    }))

    useEffect(() => {
        if (JSON.stringify(reviews) !== '{}') {
            setTotal(reviews?.total_reviews)
            setStar5(reviews['5'])
            setStar4(reviews['4'])
            setStar3(reviews['3'])
            setStar2(reviews['2'])
            setStar1(reviews['1'])
        }
    }, [reviews])

  return (
    <Grid container xs={12} gap={1}>
        <Grid container item xs={5} sx={{
            width: '100%',
            height:'140px',
            backgroundColor: '#f8f8f8',
            gap:1
        }} alignContent='center'>
            <Grid container item xs={12}  justifyContent='center'>
                <Typography sx={{ color: "var(--font-color)", fontSize: "36.2px", fontWeight: "bold" }}>
                    {Math.ceil(reviews?.avg_rate)}/5
                </Typography>
            </Grid>
            <Grid container item xs={12}  justifyContent='center'>
                <Rating
                    readOnly
                    value={parseFloat(reviews?.avg_rate)}
                    sx={{ color: '#3ed181' }}
                    size='small'
                    precision={0.5}
                    icon={<Circle fontSize="inherit" />}
                    emptyIcon={<PanoramaFishEye fontSize="inherit" sx={{ color: '#3ed181' }} />}
                />
            </Grid>
            <Grid container item xs={12}  justifyContent='center'>
                <Typography color='#3ed181' sx={{ paddingInlineStart: '8px', fontSize: 18 }} display='inline'>
                    {reviews?.total_reviews}
                </Typography>
            </Grid>
        </Grid>

        <Grid container item xs={6} >
            {rating.map((obj, i) => {
                return (
                    <React.Fragment key={i}>
                        <Grid container item xs={1} alignContent='start'>
                            <Typography display='inline' sx={{ fontSize: '16px', color: '#424242' }}>
                                {obj.star}
                            </Typography>
                        </Grid>
                        <Grid item xs={9} sx={{ paddingTop: '2%' }}>
                            <BorderLinearProgress variant="determinate" value={total !== 0 ? (obj.rate / total) * 100 : 0} />
                        </Grid>
                        <Grid container item xs={2} justifyContent='end'>
                            <Typography display='inline' sx={{ fontSize: '16px', color: '#424242' }}>
                                ({obj.rate})
                            </Typography>
                        </Grid>
                    </React.Fragment>
                )
            })}
        </Grid>
    </Grid>
  )
}

export default ProgressRating