import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import "./Details.css";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { Link, useSearchParams } from "react-router-dom";
import CustomSeparator from "../../Atoms/BreadCrumb";
const PrivateGuideReservationsDetails = ({ reservationData, t }) => {
  let [searchParams, setSearchParams] = useSearchParams();
  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");

  const lang = localStorage.getItem("lang");
  const info_1 = [
    {
      label: t("reservations.customerName"),
      value: reservationData?.user_info?.name,
    },

    {
      label: t("reservations.diveExpertName"),
      value:
        reservationData?.order_item?.purchasable?.dive_activity?.owner
          ?.firstname,
    },

    {
      label: t("reservations.branchName"),
      value: reservationData?.branch?.name,
    },

    {
      label: t("reservations.activityName"),
      value: reservationData?.order_item?.purchasable?.dive_activity?.name,
    },
    {
      label: t("reservations.activityDate"),
      value: moment(
        new Date(reservationData?.order_item?.purchasable?.start_date)
      ).format("MMM DD,YYYY"),
    },
    {
      label: t("reservations.status"),
      value: reservationData?.status_display?.display_name,
    },
    {
      label: t("reservations.reservationDateTime"),
      value: moment(reservationData?.created_at).format("MMM DD,YYYY / hh a"),
    },
    {
      label: t("reservations.paymentDateTime"),
      value: moment(reservationData?.payment_time).format("MMM DD,YYYY / hh a"),
    },
    {
      label: t("reservations.payment"),
      value: reservationData?.is_paid ? "Paid" : "not Paid",
    },
  ];
  const info_2 = [
    {
      label: t("reservations.activityPrice"),
      value: `${reservationData?.payment_transaction?.activity_price?.toFixed(
        2
      )} ${reservationData?.payment_transaction?.currency?.lookup_key} `,
    },
    {
      label: t("reservations.privateDiveExpertFees"),
      value: `${reservationData?.payment_transaction?.dive_expert_rate?.toFixed(2)} ${reservationData?.payment_transaction?.currency?.lookup_key} `,
    },
    ...(reservationData?.payment_transaction?.vat_amount !== 0
      ? [
          {
            label: t("reservations.vat"),
            value: `${reservationData?.payment_transaction?.vat_amount} ${reservationData?.payment_transaction?.currency?.lookup_key}`,
          },
        ]
      : []),
    {
      label: t("reservations.totalPrice"),
      value: ` ${reservationData?.payment_transaction?.amount_with_vat} ${reservationData?.payment_transaction?.currency?.lookup_key}`,
    },
  ];

  const info_3 = [
    {
      label: t("reservations.reservationStatus"),
      value: reservationData?.status_display?.display_name,
      color: reservationData?.status_display?.display_color,
    },
    {
      label: t("reservations.diveCenterApprovalStatus"),
      value: reservationData?.is_confirmed_by_dive_center
        ? "approved"
        : "pending",
      color: reservationData?.is_confirmed_by_dive_center
        ? "#00B906"
        : "#F7B500",
    },
    {
      label: t("reservations.diveExpertApprovalStatus"),
      value: reservationData?.is_confirmed_by_dive_expert
        ? "approved"
        : "pending",
      color: reservationData?.is_confirmed_by_dive_expert
        ? "#00B906"
        : "#F7B500",
    },
  ];
  return (
    <Stack gap={3}>
      <Grid>
        <CustomSeparator
          breadcrumbs={[
            <Link
              style={{ color: "#bebebe", textDecoration: "none" }}
              underline="hover"
              key="1"
              color="inherit"
              to={`/reservations?page=${pageNumber}`}
            >
              {t("reservations.resrvations")}
            </Link>,
            <Link
              style={{ color: "#bebebe", textDecoration: "none" }}
              underline="hover"
              key="1"
              color="inherit"
              to={`/private-guide-reservations?page=${pageNumber}`}
            >
              {t("reservations.PrivateGuideResrvations")}
            </Link>,
            <Typography
              key="3"
              style={{ color: "#bebebe", textDecoration: "none" }}
            >
              {t("reservations.reservation")}{" "}
              {reservationData?.reservation_number} {t("general.details")}
            </Typography>,
          ]}
        />
      </Grid>
      <Grid sm={12} container>
        <Typography
          width="fit-content"
          fontSize="30px"
          fontWeight="700"
          textTransform="capitalize"
        >
          {t("reservations.reservation")} {reservationData?.reservation_number}{" "}
          {t("general.details")}
        </Typography>
      </Grid>
      <Divider width="100%" />
      <Stack
        className="details-panel"
        p={0}
        bgcolor="white"
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
      >
        <Stack
          gap={2}
          width={{ xs: "100%", md: "57%" }}
          bgcolor="#F9F9F9"
          borderRadius="10px"
          p={2}
        >
          <Typography
            width="fit-content"
            className="mb-20px"
            component="h1"
            textTransform="capitalize"
          >
            {t("reservations.reservationInformation")}
          </Typography>

          <Stack
            width="100%"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Stack width="49%" gap={3}>
              {info_1?.map((data, index) => {
                return (
                  <Stack key={index} flexDirection="row" gap={2}>
                    <Typography
                      width="fit-content"
                      textTransform="capitalize"
                      fontSize="18px"
                      fontWeight="500"
                    >
                      {data?.label}
                    </Typography>
                    <Typography
                      width="fit-content"
                      textTransform="capitalize"
                      fontSize="18px"
                      fontWeight="400"
                      color="#AAAAAA"
                    >
                      {data?.value}
                    </Typography>
                  </Stack>
                );
              })}
            </Stack>
            <Stack width="49%" gap={2}>
              {info_2?.map((data, index) => {
                return (
                  <Stack key={index} flexDirection="row" gap={2}>
                    <Typography
                      width="fit-content"
                      textTransform="capitalize"
                      fontSize="18px"
                      fontWeight="500"
                    >
                      {data?.label}
                    </Typography>
                    <Typography
                      width="fit-content"
                      textTransform="capitalize"
                      fontSize="18px"
                      fontWeight="400"
                      color="#AAAAAA"
                    >
                      {data?.value}
                    </Typography>
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        </Stack>

        <Stack gap={2} width={{ xs: "100%", md: "37%" }}>
          <Stack bgcolor="#F9F9F9" borderRadius="10px" p={2}>
            <Typography
              width="fit-content"
              className="mb-20px"
              component="h1"
              textTransform="capitalize"
            >
              {t("reservations.status")}
            </Typography>

            <Grid container gap={1} flexWrap="wrap">
              <Stack width="100%" gap={3}>
                {info_3?.map((data, index) => {
                  return (
                    <Stack key={index} flexDirection="row" gap={2}>
                      <Typography
                        width="fit-content"
                        textTransform="capitalize"
                        fontSize="18px"
                        fontWeight="500"
                      >
                        {data?.label}
                      </Typography>
                      <Typography
                        width="fit-content"
                        textTransform="capitalize"
                        fontSize="18px"
                        fontWeight="400"
                        color={data?.color}
                      >
                        {data?.value}
                      </Typography>
                    </Stack>
                  );
                })}
              </Stack>
            </Grid>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default withTranslation()(PrivateGuideReservationsDetails);
