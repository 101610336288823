import { Grid, Stack, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { findInObject } from "../../../Contexts/APIs/Helper/Helper";
import DescriptionPanel from "../General/Panels/DescriptionPanel";
import InformationPanel from "../General/Panels/InformationPanel";
import { withTranslation } from "react-i18next";

const JobOfferDetailsBody = ({ jobOfferData, language, t }) => {
  const featuresArray = [
    "transportation_included",
    "meals_included",
    "reach_x_applicants",
    "reach_favorite_first",
  ];
  const lang = localStorage.getItem("lang");

  const [features, setFeatures] = useState([]);
  const [selectedLang, setSelectedLang] = useState(lang);

  useEffect(() => {
    setSelectedLang(lang);
  }, [lang]);

  useEffect(() => {
    setSelectedLang(language);
  }, [language]);

  useEffect(() => {
    const array = [];
    featuresArray.forEach((item) => {
      const result = findInObject(jobOfferData, item);
      array.push(result);
    });
    setFeatures(array);
  }, [jobOfferData]);
  return (
    <Grid
      container
      sx={{
        padding: "30px 60px",
      }}
      gap={1}
    >
      <Grid container item xs={12} md={7.5}>
        {jobOfferData?.localized_data &&
          jobOfferData?.localized_data[selectedLang]?.description && (
            <DescriptionPanel
              title={t("joboffer.jobDescription")}
              description={
                jobOfferData?.localized_data &&
                jobOfferData?.localized_data[selectedLang]?.description
              }
            />
          )}
        <Stack
          container
          className="details-panel"
          width="100%"
          bgcolor="rgba(0, 0, 0, 0.01)"
          borderRadius="10px"
          p={2}
        >
          <Typography width="fit-content" className="mb-20px" component="h1">
            {t("joboffer.languages")}
          </Typography>
          {jobOfferData?.languages?.map((val, index) => {
            return <Typography key={index}>{val?.name}</Typography>;
          })}
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        bgcolor="rgba(0, 0, 0, 0.01)"
        borderRadius="10px"
        p={2}
      >
        <InformationPanel
          title={t("activity.information")}
          features={features}
          jobOfferData={jobOfferData}
          isDate={true}
        />
      </Grid>
    </Grid>
  );
};

export default withTranslation()(JobOfferDetailsBody);
