import React, { useState } from "react";
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import TextFieldInput from "../../Atoms/Input/TextField/TextFieldInput";
import * as yup from "yup";
import {
  addAppComplaint,
  addGeneralComplaint,
} from "../../../Contexts/APIs/Complaints/Support";
import SuccessModal from "../General/Models/SuccessModal";
import "./HelpForms.css";
import { withTranslation } from "react-i18next";

function HelpForms({ targetPage, userCData, t }) {
  const validationSchemaObject = yup.object({
    sender_email: yup.string().trim().required(t("help.emailReq")).email(),
    description: yup.string().trim().min(15).required(t("help.descriptionReq")),
  });

  const [open, setOpen] = React.useState(false);

  const handleAddGeneralComplaint = async (values) => {
    const { res, err } = await addGeneralComplaint({ complaint: values });
    if (Object.keys(res).length > 1) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const handleAddAppComplaint = async (values) => {
    const { res, err } = await addAppComplaint({ complaint: values });
    if (Object.keys(res).length > 1) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      sender_email: userCData,
      description: "",
    },
    validationSchema: validationSchemaObject,
    onSubmit: (values) => {
      targetPage === "CustomerSupport" && handleAddGeneralComplaint(values);
      targetPage === "ReportAppIssuse" && handleAddAppComplaint(values);
    },
  });

  return (
    <>
      <Grid container md={12} style={{ margin: "auto" }}>
        <form
          onSubmit={formik.handleSubmit}
          style={{ margin: "auto", width: "100%" }}
          className="form-element"
        >
          <TextFieldInput
            name="sender_email"
            id="sender_email"
            placeholder={t("help.emailAddress")}
            type="email"
            onChange={formik.handleChange}
            value={formik.values.sender_email}
            error={
              formik.touched.sender_email && Boolean(formik.errors.sender_email)
            }
            helperText={
              formik.touched.sender_email && formik.errors.sender_email
            }
          />
          <TextFieldInput
            name="description"
            id="description"
            placeholder={t("help.description")}
            type="text"
            multiline={true}
            rows={4}
            onChange={formik.handleChange}
            value={formik.values.description}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
          />

          <PrimaryButton
            text={t("profile.submit")}
            classBtn="primary"
            style={{ padding: "5px 50px", margin: "auto" }}
            click={formik.handleSubmit}
          />
        </form>
      </Grid>

      {open === true && (
        <SuccessModal
          formik={formik}
          open={open}
          setOpen={setOpen}
          text={[t("help.successModalTitle"), t("help.successModalDesc")]}
        />
      )}
    </>
  );
}

export default withTranslation()(HelpForms);
