import React from "react";
import InHouseActivitiesList from "../../../../Components/Organisms/Salesmen/InHouseActivities/InHouseActivitiesList";

const InHouseActivitiesTemplate = (props) => {
  const {
    inHouseActivitiesList,
    count,
    getInHouseActivities,
    inHouseActivitiesPrivilages,
    setOpenLoader,
    searchKeywords,
    setSearchKeywords,
    page,
    setPage,
    updatePagination,
    getKeywords,

    handelDeleteInHouseActivity,
    setOpen,
    filterObj,
    setFilterObj,
    cancelDur,
    profileData,
    setInHouseActivitiesList
  } = props;

  return (
    <InHouseActivitiesList
      filterObj={filterObj}
      setFilterObj={setFilterObj}
      inHouseActivitiesList={inHouseActivitiesList}
      count={count}
      getInHouseActivities={getInHouseActivities}
      inHouseActivitiesPrivilages={inHouseActivitiesPrivilages}
      setOpenLoader={setOpenLoader}
      searchKeywords={searchKeywords}
      setSearchKeywords={setSearchKeywords}
      page={page}
      setPage={setPage}
      updatePagination={updatePagination}
      getKeywords={getKeywords}
      handelDeleteInHouseActivity={handelDeleteInHouseActivity}
      setOpen={setOpen}
      cancelDur={cancelDur}
      profileData={profileData}
      setInHouseActivitiesList={setInHouseActivitiesList}
    />
  );
};

export default InHouseActivitiesTemplate;
