import { Grid, Typography } from "@mui/material";
import ActivityGroups from "../../../Components/Organisms/Reservations/ActivityGroups.jsx";

const ActivityGroupsTemplate = (props) => {
    const { t, getActivityGroups, count, activityGroups, activityInfo } = props;

    return (
        <Grid container>
            <ActivityGroups
                getActivityGroups={getActivityGroups}
                count={count}
                activityGroups={activityGroups}
                activityInfo={activityInfo}
            />
        </Grid>
    )
}

export default ActivityGroupsTemplate