import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import TextFieldInput from "../../../../Atoms/Input/TextField/TextFieldInput";
import "react-phone-input-2/lib/material.css";
import { withTranslation } from "react-i18next";
import SearchableSelect from "../../../../Atoms/Select/SearchableSelect";
import ReactPhoneInput2 from "../../../../Atoms/Input/PhoneInput/ReactPhoneInput2";

const DiveOperationInformation = ({
  t,
  formik,
  languagesList = [{}],
  getLanguagesAPI,
  languagesCount,
}) => {
  return (
    <Grid container className="activity-form" gap={2} width="100%">
      <Typography
        component="h1"
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          borderBottom: "2px solid var(--primary-color)",
        }}
        className="mb-20px"
      >
        {t("profile.operatorInformation")}
      </Typography>

      <Grid container columnSpacing={2} alignItems={"center"}>
        <Grid item xs={6}>
          <TextFieldInput
            name="en.name"
            id="name"
            style={{ width: "100%" }}
            disabled
            placeholder={t("profile.operaterName")}
            className="login-input"
            type="text"
            onChange={formik?.handleChange}
            value={localStorage.getItem("name")}
          />
        </Grid>
        <Grid item xs={6}>
          <SearchableSelect
            id="primary_language_id"
            name="primary_language_id"
            label={t("profile.language")}
            options={languagesList}
            count={languagesCount}
            getList={getLanguagesAPI}
            className="searchable-select"
            value={
              formik?.values?.primary_language_id
                ? formik?.values?.primary_language_id
                : ""
            }
            error={
              formik?.touched?.primary_language_id &&
              Boolean(formik?.errors?.primary_language_id)
            }
            onChange={(val) => {
              // formik?.handleChange();
              formik.setValues({ ...formik?.values, primary_language_id: val });
            }}
            onClear={() => {
              formik.setValues({ ...formik?.values, primary_language_id: "" });
            }}
          />
        </Grid>
      </Grid>
      <Stack flexDirection="row" width="100%" gap={2}>
        <ReactPhoneInput2
          formik={formik}
          name="phone_number"
          customContainerClass="dive-oper-phone-input"
          placeholder={`${t("users.phoneNumber")}`}
          specialLabel={`${t("users.phoneNumber")}`}
          phoneProperty="phone_number"
          codeProperty="country_code"
          enableSearch={true}
          showBorder={true}
        />
        <TextFieldInput
          style={{ width: "100%" }}
          name="email"
          id="email"
          placeholder={t("profile.emailAddress")}
          className="login-input"
          type="email"
          onChange={formik?.handleChange}
          value={localStorage.getItem("email")}
          disabled
        />
      </Stack>
      <Stack flexDirection="row" width="100%" gap={2}>
        <TextFieldInput
          style={{ width: "100%" }}
          name="website"
          id="website"
          placeholder={t("profile.websiteLink")}
          className="login-input"
          type="text"
          onChange={formik?.handleChange}
          value={formik?.values?.website}
          error={formik?.touched?.website && Boolean(formik?.errors?.website)}
          helperText={formik?.touched?.website && formik?.errors?.website}
          clickEnter={formik?.handleSubmit}
        />
        <TextFieldInput
          style={{ width: "100%" }}
          name="facebook"
          id="facebook"
          placeholder={t("profile.socialLink")}
          className="login-input"
          type="text"
          onChange={formik?.handleChange}
          value={formik?.values?.facebook}
          error={formik?.touched?.facebook && Boolean(formik?.errors?.facebook)}
          helperText={formik?.touched?.facebook && formik?.errors?.facebook}
          clickEnter={formik?.handleSubmit}
        />
      </Stack>
      <TextFieldInput
        name="main_office_address"
        id="main_office_address"
        placeholder={t("profile.addMainOfficeAddress")}
        className="login-input "
        type="text"
        rows={4}
        multiline
        style={{ width: "100%" }}
        onChange={formik?.handleChange}
        value={formik?.values?.main_office_address}
        error={
          formik?.touched?.main_office_address &&
          Boolean(formik?.errors?.main_office_address)
        }
        helperText={
          formik?.touched?.main_office_address &&
          formik?.errors?.main_office_address
        }
        clickEnter={formik?.handleSubmit}
      />
    </Grid>
  );
};

export default withTranslation()(DiveOperationInformation);
