import { Grid, Typography, Divider, Box } from "@mui/material";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput.jsx";
import "./Form.css";
import { useEffect, useState } from "react";
import { DaysList } from "../../../../Contexts/APIs/Helper/Helper.js";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BasicDatePicker from "../../../Atoms/Input/DatePicker/DatePicker.js";
import BasicTimePicker from "../../../Atoms/Input/TimePicker/TimePicker.js";
import { icons } from "../../../../Assets/AssetHelper.js";
import { withTranslation } from "react-i18next";
import dayjs from "dayjs";
import moment from "moment";

const ActivityDatesTimes = (props) => {
  const {
    t,
    formik,
    startDate,
    endDate,
    startBorder,
    setStartDate,
    setEndDate,
    concatTime,
    setConcatTime,
    dateType,
    setDateType,
    endBorder,
    setEveryDayList,
    editableDateType,
    formType,
    activityDetails,
    setSomeWeekDays,
    someWeekDays,
    everydaysStartTime,
    setEverydaysStartTime,
    someDates,
    setSomeDates,
    someDatesData,
    setSomeDatesData,
    type,
  } = props;
  const [isActive, setActive] = useState(0);
  const [activeClasses, setActiveClasses] = useState([]);
  const [startTimeWithDays, setStartTimeWithDays] = useState([]);
  const [startTime, setStartTime] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const DatesTimes = [
    {
      label: t("activity.everyDay"),
      value: 0,
      disable:
        formType == "edit" && activityDetails?.scheduling_option == "some_dates"
          ? true
          : false,
    },
    {
      label: t("activity.someWeekdays"),
      value: 1,
      disable:
        formType == "edit" && activityDetails?.scheduling_option == "some_dates"
          ? true
          : false,
    },
    {
      label: t("activity.someDates"),
      value: 2,
    },
  ];
  const handleChange = (i, val) => {
    setActive(i);
    setDateType(val);
  };
  const getSelectedDays = (i, day) => {
    setLoading(true);

    if (!startTimeWithDays.includes(day)) {
      setActiveClasses((activeClasses) => [...activeClasses, i]);
      // setStartTimeWithDays((startTimeWithDays) => [...startTimeWithDays, day]);
      setStartTimeWithDays((prevState) => [...prevState, day]);
      setStartTime([
        ...startTime,
        {
          weekday: day,
          start_time: "",
        },
      ]);
      setSomeWeekDays([
        ...someWeekDays,
        {
          weekday: day,
          start_time: "",
        },
      ]);
    } else {
      // setStartTimeWithDays((startTimeWithDays) =>
      //   startTimeWithDays?.filter((val) => val != day)
      // );
      setStartTimeWithDays((prevState) =>
        prevState?.filter((val) => val != day)
      );
      setStartTime((startTime) =>
        startTime?.filter((val) => val?.weekday != day)
      );
      setSomeWeekDays((someDays) =>
        someDays?.filter((val) => val?.weekday != day)
      );

      const Actives = [...activeClasses];
      var newActiveClasses = Actives.filter((val) => val !== i);
      setActiveClasses([...newActiveClasses]);
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  useEffect(() => {
    const newList = [];
    DaysList.map((day) => {
      newList.push({
        weekday: day?.name?.toLowerCase(),
        start_time: everydaysStartTime
          ? dayjs(new Date(concatTime)).format(`HH:mm ZZ`)
          : "",
      });
    });
    setEveryDayList(newList);
  }, [DaysList, everydaysStartTime]);
  useEffect(() => {
    if (formType === "edit") {
      setActive(editableDateType);
      setDateType(editableDateType);
      const newList = [];
      const timeZoneNum = new Date(concatTime)
        .toLocaleTimeString("en-us", { timeZoneName: "short" })
        .split(" ")[2];
      DaysList.map((day) => {
        newList.push({
          weekday: day?.name?.toLowerCase(),
          start_time: everydaysStartTime
            ? dayjs(new Date(concatTime)).format(
                `HH:mm${timeZoneNum?.slice(
                  timeZoneNum.indexOf("+"),
                  timeZoneNum.length
                )}`
              )
            : "",
        });
      });
      setEveryDayList(newList);
    }
  }, [editableDateType]);
  useEffect(() => {
    const daysIdx = {
      saturday: 0,
      sunday: 1,
      monday: 2,
      tuesday: 3,
      wednesday: 4,
      thursday: 5,
      friday: 6,
    };
    if (
      formType === "edit" &&
      activityDetails?.scheduling_option == "some_weekdays"
    ) {
      const days = activityDetails?.scheduled_days?.map((val) => {
        return val?.weekday;
      });
      const times = activityDetails?.scheduled_days?.map((val) => {
        getSelectedDays(daysIdx[val?.weekday], val?.weekday);

        return {
          start_time: val?.start_time,
          weekday: val?.weekday,
        };
      });
      setStartTimeWithDays(days);
      setStartTime(times);
    }
  }, [activityDetails]);
  useEffect(() => {
    if (
      formType === "edit" &&
      activityDetails?.scheduling_option == "some_dates"
    ) {
      const times =
        type == "inhouse"
          ? activityDetails?.inhouse_activity_groups?.map((val) => {
              return {
                start_time: val?.start_time,
                start_date: val?.start_date,
              };
            })
          : activityDetails?.activity_groups?.map((val) => {
              return {
                start_time: val?.start_time,
                start_date: val?.start_date,
              };
            });

      setStartTime(times);
    }
  }, [activityDetails]);

  const handelDates = (value) => {
    if (
      moment(new Date(value), "DD/MM/YYYY").format("DD-MM-YYYY") !=
      "Invalid date"
    ) {
      setSomeDates([
        ...someDates,
        moment(new Date(value), "DD/MM/YYYY").format("DD-MM-YYYY"),
      ]);
    }
  };

  const handelFormatTime = (time) => {
    return dayjs(
      `${+time?.split(":")[0] + 12} : ${time?.split(":")[1]}`,
      "HH:mm a"
    );
  };
  return (
    <Grid container className="activity-form">
      <Grid container>
        <Typography
          component="h1"
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            borderBottom: "2px solid var(--primary-color)",
          }}
          className="mb-20px"
        >
          {t("activity.dateAndTime")}
        </Typography>
      </Grid>
      <Grid container className="mb-20px">
        <Grid item sm={5.8}>
          <TextFieldInput
            name="days"
            id="days"
            placeholder={t("activity.durationInDays")}
            className="login-input"
            type="number"
            value={formik.values.days}
            error={Boolean(formik.errors.days)}
            helperText={formik.errors.days}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item sm={0.4}></Grid>
        <Grid item sm={5.8}>
          <TextFieldInput
            name="hours"
            id="hours"
            placeholder={t("activity.durationHoursPerDay")}
            className="login-input"
            type="number"
            value={formik.values.hours}
            error={Boolean(formik.errors.hours)}
            helperText={formik.errors.hours}
            onChange={formik.handleChange}
          />
        </Grid>
      </Grid>
      <Divider style={{ width: "100%", margin: "10px 0" }} />
      <Grid container className="mb-20px">
        {DatesTimes.map((date, index) => {
          return (
            <Grid
              container
              item
              sm={3}
              key={index}
              className={`${
                index === isActive ? "custom-radio-btn-disabled" : null
              }`}
            >
              <Grid item xs={2}>
                <Typography
                  onClick={() =>
                    !date?.disable && handleChange(index, date.value)
                  }
                  component="span"
                  className={
                    index === isActive
                      ? "custom-radio-btn active-select"
                      : "custom-radio-btn"
                  }
                ></Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography component="label">{date.label}</Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <Grid container>
        {dateType === 0 && (
          <Grid container>
            <Grid item xs={5.8}>
              <BasicDatePicker
                placeholder={t("activity.startDate")}
                value={startDate}
                className="border-r-10px basic-date-picker mb-20px"
                redBorder={startBorder}
                errorText={"Date field is required"}
                setValue={setStartDate}
              />
            </Grid>
            <Grid item xs={0.4}></Grid>
            <Grid item xs={5.8}>
              <BasicDatePicker
                placeholder={t("activity.endDate")}
                value={endDate}
                className="border-r-10px basic-date-picker mb-20px"
                redBorder={endBorder}
                errorText={"Date field is required"}
                setValue={setEndDate}
                disablePast={true}
              />
            </Grid>
            <Grid item xs={5.8}>
              <BasicTimePicker
                className="border-r-10px basic-time-picker mb-20px basic-time-picker-startTime"
                value={everydaysStartTime}
                placeholder={t("activity.startTime")}
                default
                setValue={(time) => setEverydaysStartTime(time)}
                startDate={startDate}
                setConcatTime={setConcatTime}
              />
            </Grid>
          </Grid>
        )}

        {dateType === 1 && (
          <>
            <Grid container padding="10px 0" className="mb-20px">
              {DaysList.map((day, index) => (
                <PrimaryButton
                  key={index}
                  text={day?.name}
                  classBtn={`${"day-btn"} ${
                    startTimeWithDays.includes(day.id) ? "active-btns" : ""
                  }`}
                  click={() => getSelectedDays(index, day?.id)}
                  endIcon={
                    startTimeWithDays.includes(day?.id) ? (
                      <CheckCircleIcon
                        style={{
                          position: "absolute",
                          top: "-10px",
                          right: "-5px",
                        }}
                      />
                    ) : null
                  }
                  style={{ overflow: "initial" }}
                />
              ))}
            </Grid>
            <Grid container>
              <Grid item xs={5.8}>
                <BasicDatePicker
                  placeholder={t("activity.startDate")}
                  value={startDate}
                  className="border-r-10px basic-date-picker mb-20px"
                  redBorder={startBorder}
                  errorText={"Date field is required"}
                  setValue={setStartDate}
                />
              </Grid>
              <Grid item xs={0.4}></Grid>
              <Grid item xs={5.8}>
                <BasicDatePicker
                  placeholder={t("activity.endDate")}
                  value={endDate}
                  className="border-r-10px basic-date-picker mb-20px"
                  redBorder={endBorder}
                  errorText={"Date field is required"}
                  setValue={setEndDate}
                  disablePast={true}
                />
              </Grid>
            </Grid>
            <Grid container>
              {startTimeWithDays?.map((day, index) => {
                const time = startTime[index]?.start_time?.includes("PM")
                  ? handelFormatTime(startTime[index]?.start_time)
                  : dayjs(startTime[index]?.start_time, "HH:mm a");
                return (
                  <Grid container item sm={8} className="mb-20px" key={index}>
                    <Grid item xs={4}>
                      <Typography
                        component="label"
                        style={{ lineHeight: "3.5" }}
                      >
                        {day}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <BasicTimePicker
                        className="border-r-10px basic-time-picker mb-20px"
                        value={
                          startTime[index] && startTime[index].start_time
                            ? time
                            : null
                        }
                        placeholder={t("activity.startTime")}
                        setValue={(time) => {
                          setLoading(true);
                          let newTime = startTime;

                          newTime[index] = {
                            start_time: dayjs(new Date(time)).format(
                              `HH:mm ZZ`
                            ),
                            weekday: String(day).toLowerCase(),
                          };

                          setStartTime(newTime);
                          setSomeWeekDays(newTime);
                          setTimeout(() => {
                            setLoading(false);
                          }, 500);
                        }}
                      />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </>
        )}
        {dateType === 2 && (
          <>
            <BasicDatePicker
              placeholder={t("activity.chooseDates")}
              value={someDates?.[someDates?.length - 1]}
              className="special-date border-r-10px basic-date-picker mb-20px"
              redBorder={startDate}
              setValue={handelDates}
              // changeListener used to reset value after change date by typeing not select from calendar
              changeListener={someDates}
              disablePast={true}
            />
            {someDates?.map((date, index) => {
              const time = startTime[index]?.start_time?.includes("PM")
                ? handelFormatTime(startTime[index]?.start_time)
                : dayjs(startTime[index]?.start_time, "HH:mm a");

              return (
                <Grid container item sm={8} className="mb-20px" key={index}>
                  <Grid item xs={4}>
                    <Typography component="label" style={{ lineHeight: "3.5" }}>
                      {date}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Grid item xs={8}>
                      <BasicTimePicker
                        className="border-r-10px basic-time-picker mb-20px"
                        value={
                          startTime[index] && startTime[index].start_time
                            ? time
                            : null
                        }
                        placeholder={t("activity.startTime")}
                        setValue={(time) => {
                          setLoading(true);
                          let newTime = startTime;
                          newTime[index] = {
                            start_date: date,
                            start_time: dayjs(new Date(time)).format(
                              `HH:mm ZZ`
                            ),
                          };
                          setStartTime(newTime);
                          setSomeDatesData(newTime);
                          setTimeout(() => {
                            setLoading(false);
                          }, 500);
                        }}
                      />
                    </Grid>
                    {(someDatesData[index]?.reservations_count == 0 ||
                      someDatesData[index]?.reservations_count ==
                        undefined) && (
                      <Grid item xs={4} mx={2}>
                        <img
                          src={icons.DeleteIcon}
                          onClick={(e) => {
                            e.stopPropagation();
                            setSomeDates((s) =>
                              s?.filter((val, idx) => idx != index)
                            );
                            setSomeDatesData((s) =>
                              s?.filter((val, idx) => idx != index)
                            );
                            setStartTime((s) =>
                              s?.filter((val, idx) => idx != index)
                            );
                            // setStartTime((s) =>
                            //   s?.filter((val, idx) => val.start_date !== date)
                            // );
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              );
            })}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default withTranslation()(ActivityDatesTimes);
