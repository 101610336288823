import { Divider, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import SwitcheLabel from '../../../Atoms/Buttons/Switch/SwitchButton';
import TextFieldInput from '../../../Atoms/Input/TextField/TextFieldInput';
import "./Form.css";
import { withTranslation } from 'react-i18next';

function MoreInfo  ({t, formik, switchedOptions, supportedLanguages, supportLang }) {
    const [nameValidation, setNameValidation] = useState(false);

    return (
        <Grid container lg={12} className="activity-form">
            <Grid container md={12}>
                <Typography
                    component="h1"
                    style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        borderBottom: "2px solid var(--primary-color)"
                    }}
                    className="mb-20px"
                >
                    {t('branch.moreInformation')}
                </Typography>
            </Grid>
            {switchedOptions?.map((option , index) => (
                <Grid container md={12} key={index} >
                    <Grid sm={11}>
                        <Typography
                            component="h1"
                            style={{
                                fontSize: "18px",
                                fontWeight: "bold",
                                lineHeight: "3"
                            }}
                        >
                            {option?.title}
                        </Typography>
                    </Grid>
                    <Grid sm={1}>
                        <SwitcheLabel style={{ padding: "15px" }}
                            name={option?.name}
                            checked={formik.values[option?.name]}
                            onChange={formik.handleChange} /> 
                    </Grid>
                </Grid>
            ))}
            <Divider style={{ background: '#d9d9d9', width: '100%', marginBlock: '20px' }} />
            <Grid container md={12}>
                <Grid sm={3}>
                    <Typography style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        lineHeight: "3"
                    }}>
                        {t('joboffer.jobDescription')}
                    </Typography>
                </Grid>
                <Grid sm={9}>
                    {supportedLanguages.map((lang, index) => (
                        <>
                            {supportLang && supportLang === lang.id && (
                                <Grid sm={12} key={index} >
                                    <TextFieldInput
                                        name={"description_" + lang.id}
                                        id={"description_" + lang.id}

                                        placeholder="What is in this offer?"
                                        className='textarea'
                                        multiline={true}
                                        rows={4}
                                        inputProps={{
                                            style: {
                                                position: 'absolute',
                                                top: '10px',
                                                width: '95%'
                                            }
                                        }}
                                        value={formik?.values[`description_${lang.id}`]}
                                        onChange={formik.handleChange}
                                        error={nameValidation && Boolean(nameValidation)}
                                        helperText={nameValidation ? "name is a required field" : ""}
                                    />
                                </Grid>
                            )}
                        </>
                    ))}

                </Grid>
            </Grid>

        </Grid>
    )
}

export default withTranslation()(MoreInfo)