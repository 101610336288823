import { Divider, Typography } from '@mui/material'
import React from 'react'
import CustomerSupportWrap from '../../../../Components/Molecules/Help/CustomerSupport/CustomerSupportWrap'
import { withTranslation } from 'react-i18next'

function CustomerSupportTemplate({t}) {
  return (
    <>
        <Typography component="h1" style={{ fontWeight: "bold", fontSize: "30px", marginBlock: "1rem", textTransform: "capitalize" }}>
          {t("help.help")}
        </Typography>
        <Divider />
        <CustomerSupportWrap /> 
    </>
  )
}

export default withTranslation()(CustomerSupportTemplate)