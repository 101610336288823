import { Grid, Typography, Divider } from "@mui/material";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import AddIcon from "@mui/icons-material/Add";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import SearchableSelect from "../../Atoms/Select/SearchableSelect.jsx";
import { useState } from "react";
import "./ListHeader.css";
import CustomSeparator from "../../Atoms/BreadCrumb/index.jsx";
import BranchesFilter from "../Statistics/BranchesFilter.jsx";

const ListHeader = (props) => {
  const {
    t,
    type,
    activityType,
    getKeywords,
    privilages,
    filterObj,
    setFilterObj,
    profileData
  } = props;
  const Navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  const userType = localStorage.getItem("userType");
  const [Status, setStatus] = useState({});

  const updateStatus = (status_val) => {
    setStatus({ label: status_val?.label, value: status_val?.id });
    setFilterObj({ ...filterObj, page_number: 1, status: status_val?.label });
  };

  const GroupsStatus = [
    { id: "", name: "All" },
    { id: 0, name: "active" },
    { id: 1, name: "deactivated" },
  ];

  return (
    <>
      <Grid container>
        <Grid container item sm={3} justifyContent={"start"}>
          <Grid container item sm={type === "edit" ? 4 : 1.5}>
            <Grid
              item
              xs={type === "add" || type === "edit" ? 12 : 6}
              flexDirection="row"
              display="flex"
              alignItems="center"
            >
              <Typography
                component="h1"
                style={{ fontWeight: "bold", fontSize: "30px" }}
              >
                {type === "add"
                  ? t("activity.addNewActivity")
                  : type === "edit"
                    ? `${t("activity.editActivity")} (${activityType
                      ?.split("_")
                      .join(" ")})`
                    : t("salesmen.salesmen")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container item sm={9} justifyContent={"end"}>
          <Grid className="border-r-10px" sx={{ mx: '20px' }} >
            <BranchesFilter setFilterObj={setFilterObj} filterObj={filterObj} profileData={profileData} />
          </Grid>
          <Grid
            item
            width={"202px"}
            // position="relative"
            // zIndex={10}
            bgcolor="white"
          >
            <SearchableSelect
              name="status"
              options={GroupsStatus}
              label={t("reservations.status")}
              // className="salesmen-list searchable-select"
              onChange={(val) => updateStatus(val)}
              value={{ label: Status?.label, value: Status?.value }}
              onClear={() => updateStatus({})}
            />
          </Grid>

          <Grid
            item
            maxWidth={"335px"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"end"}
            margin={'0 10px'}
          >
            {((userType === "descendant_user" && privilages?.can_create) ||
              userType !== "descendant_user") && (
                <PrimaryButton
                  fontSize="14px"
                  startIcon={<AddIcon />}
                  text={t("salesmen.addNew")}
                  classBtn="primary r-25px"
                  style={{
                    padding: "5px 20px",
                    height: "48px",
                    textTransform: "none",
                    fontSize: "18px",
                  }}
                  click={() =>
                    Navigate(
                      `/salesmen/salesmen-list/add-salesman?page=${pageNumber}`
                    )
                  }
                />
              )}
          </Grid>
        </Grid>
      </Grid>
      <Divider style={{ width: "100%", margin: "20px 0" }} />
    </>
  );
};

export default withTranslation()(ListHeader);
