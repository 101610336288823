import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import modalImg from '../../../../Assets/Images/modalImg.png';
import './SuccessModal.css';

function SuccessModal({formik, open, setOpen, text}) {
    const handleClose = () => { 
        setOpen(false); 
        formik.handleReset();        
    }
  
    return (      
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"        
      >
        <Box className='success-modal'>
          <img src={modalImg} alt='modalImg' />
          <Typography id="modal-modal-title" variant="h6" component="h2"> {text[0]} </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 1 }}> {text[1]} </Typography>
        </Box>
      </Modal>
    );
}

export default SuccessModal