import { Grid, Typography, Divider } from "@mui/material";
import "./Form.css";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput.jsx";
import SearchableSelect from "../../../Atoms/Select/SearchableSelect";
import { withTranslation } from "react-i18next";

const ActivityRequirement = (props) => {
  const {
    t,
    organizationsList,
    setOrganizationsList,
    formik,
    getCertificates,
    certificatesList,
    setCertificatesList,
    getOrganization,
    IsDiveActivity,
    certificatesCount,
    organizationsCount,
  } = props;
  return (
    <Grid container className="activity-form">
      <Grid container>
        <Typography
          component="h1"
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            borderBottom: "2px solid var(--primary-color)",
          }}
          className="mb-20px"
        >
          {t("activity.requirement")} ({t("activity.optional")})
        </Typography>
      </Grid>
      <Grid container className="mb-20px">
        <Grid item sm={5.8}>
          <SearchableSelect
            name="organization"
            options={organizationsList}
            getList={getOrganization}
            count={organizationsCount}
            moreFilterData={{
              branch_id: formik?.values?.branch_id,
            }}
            handelMenuOpen={getOrganization}
            label={t("activity.organizationName")}
            className="searchable-select"
            onChange={(val) => {
              formik.setValues({
                ...formik?.values,
                organization: val?.value,
                organization_name: val?.label,
                certificates: "",
                certificates_name: "",
              });
            }}
            value={{
              label: formik?.values?.organization_name,
              value: formik?.values?.organization,
            }}
            onClear={() => {
              formik.setValues({
                ...formik?.values,
                organization: "",
                organization_name: "",
                certificates: "",
                certificates_name: "",
              });
              setCertificatesList([]);
            }}
          />
        </Grid>
        <Grid item sm={0.4}></Grid>
        <Grid item sm={5.8}>
          <SearchableSelect
            name="certificates"
            options={[{ name: "none", id: "" }, ...certificatesList]}
            label={t("activity.certificationName")}
            getList={getCertificates}
            count={certificatesCount}
            moreFilterData={{ organization_id: formik?.values?.organization }}
            className="searchable-select"
            handelMenuOpen={(_) =>
              getCertificates({
                organization_id: formik?.values?.organization,
                page_number: 1,
              })
            }
            onChange={(val) => {
              formik.setValues({
                ...formik?.values,
                certificates: val?.value,
                certificates_name: val?.label,
              });
            }}
            value={{
              label: formik?.values?.certificates_name,
              value: formik?.values?.certificates,
            }}
            onClear={() => {
              formik.setValues({
                ...formik?.values,
                certificates: "",
                certificates_name: "",
              });
            }}
          />
        </Grid>
      </Grid>
      <Grid container className="mb-20px">
        <Grid item sm={5.8}>
          <TextFieldInput
            name={IsDiveActivity == true ? "min_dives" : "no_of_dives"}
            id={IsDiveActivity == true ? "min_dives" : "no_of_dives"}
            placeholder={t("activity.minimumDives")}
            className="login-input"
            type="number"
            value={
              IsDiveActivity == true
                ? formik.values.min_dives
                : formik.values.no_of_dives
            }
            error={
              IsDiveActivity == true && formik.touched.min_dives
                ? Boolean(formik.errors.min_dives)
                : formik.touched.no_of_dives &&
                  Boolean(formik.errors.no_of_dives)
            }
            helperText={
              IsDiveActivity == true && formik.touched.min_dives
                ? formik.errors.min_dives
                : formik.touched.no_of_dives && formik.errors.no_of_dives
            }
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item sm={0.4}></Grid>
        <Grid item sm={5.8}>
          <TextFieldInput
            name="min_age"
            id="min_age"
            placeholder={t("activity.minimumAge")}
            className="login-input"
            type="number"
            value={formik.values.min_age}
            error={Boolean(formik.errors.min_age)}
            helperText={formik.errors.min_age}
            onChange={formik.handleChange}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(ActivityRequirement);
