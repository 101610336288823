import { useEffect } from "react";
import { useState } from "react";
import { getDiveCenterDetails } from "../../Contexts/APIs/DiveCenter/DiveCenter.js";
import MainTemplate from "../../Templates/Main/Main.template.jsx";
import { ShowUser } from "../../Contexts/APIs/DescendantUsers/DescendantUsers.js";
import { toast } from "react-toastify";
import Loader from "../../Components/Atoms/Loader/Loader.js";
import { checkToken } from "../../Contexts/APIs/Auth/Login.js";
import { useId } from "react";
import { SetDeviceFCMAPI } from "../../Contexts/APIs/Auth/SetDeviceFCM.js";
import { getNewFirebaseAuthToken } from "../../Contexts/APIs/Auth/Firebase.js";
import { Firebase, auth } from "../../Contexts/Firebase/Firebase.js";
import dayjs from "dayjs";

import { getDatabase, ref, child, get } from "firebase/database";
const Main = (props) => {
  const { t } = props;
  const [data, setData] = useState({});
  const [privilages, setPrivilages] = useState([]);
  const [openLoading, setOpenLoading] = useState(true);
  const userType = localStorage.getItem("userType");
  const userId = localStorage.getItem("userId");

  const token = localStorage.getItem("accessToken");
  const fcm_token = localStorage.getItem("fcm_token");
  const id = useId();
  const DiveCenterDetails = async () => {
    const { res, err } = await getDiveCenterDetails(userId,{});
    if (res) {
      setData(res?.data.user);
      // Firebase.getFCMToken();
    }
  };

  const getDescendentDetails = async () => {
    const { res, err } = await ShowUser(userId);
    if (res) {
      setData(res?.data.descendant_user);
      setPrivilages(res?.data.descendant_user?.permissions);
      // Firebase.getFCMToken();
    }
  };

  const handleCheckToken = async () => {
    if (localStorage.getItem("Token") || localStorage.getItem("accessToken")) {
      setOpenLoading(true);
      const { res, err } = await checkToken();
      if (res?.message == "success") {
        if (userType === "descendant_user") {
          await getDescendentDetails();
        } else if (userType !== "descendant_user") {
          await DiveCenterDetails();
        }
      }
      setOpenLoading(false);
    }
  };

  // const createAuthToken = async () => {
  //   const { res, err } = await getNewFirebaseAuthToken();
  //   if (res) {
  //     localStorage.setItem("firebase_auth_token", res?.data?.firebase_auth_token);
  //     await Firebase.SignInFirebase();
  //   }
  // };

  useEffect(() => {
    handleCheckToken();
  }, []);

  // fcm
  const getFCMToken = async () => {
    const updateNewFCMToken = (newFCMToken) => {
      const new_fcm_token = newFCMToken || id;
      SetDeviceFCMAPI({
        device: {
          fcm_token: new_fcm_token,
        },
      });
      localStorage.setItem("fcm_token", new_fcm_token);
    };

    const newFCMToken = await Firebase.requestForFCMToken();

    if (fcm_token !== newFCMToken) {
      updateNewFCMToken(newFCMToken);
    }
  };

  useEffect(() => {
    if ("Notification" in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted" && !fcm_token && token) {
          getFCMToken();
        } else {
          getFCMToken();
        }
      });
    }
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged(async (user) => {
      if (user) {
        const refreshedToken = await user.getIdToken();
        localStorage.setItem("firebase_auth_token", refreshedToken);
      } else {
        Firebase.SignInFirebase();
      }
    });
    return () => unsubscribe();
  }, []);

  //! for testing
  // useEffect(() => {
  //   const userNode = localStorage.getItem("firebase_node_path");
  //   const dbRef = ref(getDatabase());
  //   get(child(dbRef, userNode))
  //     .then((snapshot) => {
  //       if (snapshot.exists()) {
  //         console.log(snapshot.val());
  //       } else {
  //         console.log("No data available");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }, []);

  return (
    <>
      {openLoading ? (
        <Loader open={openLoading} />
      ) : (
        <MainTemplate profileData={data} privilages={privilages} setProfileData={setData} />
      )}
    </>
  );
};

export default Main;
