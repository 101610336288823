import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import { icons } from "../../../Assets/AssetHelper";
import { withTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";

const DisplayLicense = ({
  t,
  handleRemove,
  data,
  title,
  licenseNumber,
  image,
  isLoading = false,
}) => {
  return (
    <>
      <Grid item sm={5.8}>
        <Stack
          pb={3}
          px={2}
          sx={{
            border: "1px solid #BEBEBE",
            borderRadius: "8px",
          }}
        >
          <Stack
            direction={"row"}
            mb="12px"
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              sx={{ fontSize: "18px", fontWeight: 700, color: "#333132" }}
            >
              {title}
            </Typography>
            <PrimaryButton
              btnType={"icon"}
              startIcon={
                isLoading ? (
                  <Stack sx={{ pt: "10px" }}>
                    <CircularProgress size={25} />
                  </Stack>
                ) : (
                  <img src={icons.DeleteIcon} alt="delete" />
                )
              }
              click={() => {
                handleRemove();
              }}
            />
          </Stack>
          <Stack>
            <img
              src={
                data?.file_type?.includes("pdf") ? icons?.pdfPlaceholder : image instanceof File ? URL.createObjectURL(image) : image
              }
              alt="license"
              style={{
                maxWidth: "248px",
                maxHeight: "137px",
              }}
            />
          </Stack>
          {licenseNumber && (
            <Stack justifyContent={"center"} mt="8px" alignItems={"center"}>
              <Typography sx={{ fontWeight: 700, color: "#333132" }}>
                {licenseNumber}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Grid>
    </>
  );
};

export default withTranslation()(DisplayLicense);
