import { Grid, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { icons } from '../../../../../Assets/AssetHelper'
import { getDiveCenterDetails } from '../../../../../Contexts/APIs/DiveCenter/DiveCenter'

const PendingReview = ({ t }) => {
    const [diveCenterDetails, setDiveCenterDetails] = useState({})

    const DiveCenterDetails = async () => {
        const { res, err } = await getDiveCenterDetails(localStorage.getItem('userId'),{});
        if (res) {
            setDiveCenterDetails(res?.data.user);
        }
    };
    useEffect(() => {
        DiveCenterDetails()
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (diveCenterDetails?.profile_status == "pending_approval") {
                return
            }
            else if (diveCenterDetails.profile_status == "rejected") {
                localStorage.setItem("Msg", diveCenterDetails?.rejection_reason)
                window.location.href = "/profile-rejected"
            } 
    
            else if (diveCenterDetails?.profile_status == 'approved') {
                localStorage.setItem("Token", localStorage.getItem('accessToken'));
                localStorage.removeItem('accessToken');
                window.location.href = "/profile"
            }            
        }, 1000);
    }, [diveCenterDetails])
    return (
        <Stack alignItems='center' justifyContent='center' height='100vh'>
            <Grid container className='activity-form' bgcolor={'white'} alignItems='center' justifyContent='center' width={'50%'}>
                <Grid className="mb-20px" >
                    <img src={icons.LoginLogo} alt="Logo" />
                </Grid>
                <Grid className="mb-30px" >
                    <Typography component="h1" style={{ fontSize: "27px", color: "#333132", fontWeight: 'bold', textAlign: 'center' }}>
                        Your Account is Submitted and Being Reviewed
                    </Typography>
                    <Typography style={{ fontSize: "18px", color: "#333132", marginTop: '10px', textAlign: 'center' }}>
                        We are doing our best to make it worthy
                    </Typography>
                </Grid>
            </Grid>
        </Stack>


    )
}

export default PendingReview