import React from 'react'
import FaqAccordion from '../../../Molecules/Help/Faq/FaqAccordion'

function FaqAccordiosWrap({acrData}) {
  return (
    <div style={{ padding: "1rem 1rem 0rem", boxShadow: "0 -2px 15px 0 rgba(0, 0, 0, 0.1)", borderRadius: "1rem", marginTop: "1rem" }}>
    {
        acrData.length !== 0 && typeof acrData !== null && <div>{
            acrData.map( item => {
                return(
                <FaqAccordion key={item.id} index={item.id} head={item.question} 
                    text={item.answer} 
                />    
                )
            } )
        }</div>
    }
    </div>
  )
}

export default FaqAccordiosWrap