import { Grid, Typography, Divider, Stack } from "@mui/material";
import SelectBox from "../../Atoms/Select/Select.jsx";
import { useNavigate } from "react-router-dom";
import BasicDatePicker from "../../Atoms/Input/DatePicker/DatePicker.js";
import { useState } from "react";
import { GroupsStatus } from "../../../Contexts/APIs/Helper/Helper.js";
import moment from "moment";
import { withTranslation } from "react-i18next";

const ActivityGroupHeader = (props) => {
    const { updateStartDate, updateEndDate, updateGroupStatus, t, isInHouseActivity, isAssignedActivity } = props;
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [groupStatus, setGroupStatus] = useState("");

    const handleStartDate = (start_date) => {
        setStartDate(start_date);
        updateStartDate(start_date !== null ? moment(start_date).format("DD/MM/YYYY") : '');
    };
    const handleEndDate = (end_date) => {
        setEndDate(end_date);
        updateEndDate(end_date !== null ? moment(end_date).format("DD/MM/YYYY") : '');
    };
    const getGroupStatus = (e) => {
        setGroupStatus(e.target.value);
        updateGroupStatus(e.target.value);
    };

    return (
        <Grid container>
            <Grid container item md={12}>
                <Grid container item sm={6}>
                    <Grid>
                        <Typography component="h1" style={{ fontWeight: "bold", fontSize: "30px" }}>
                        {t("reservations.activityGroups")}
                        </Typography>
                    </Grid>
                </Grid>
                <Stack container width='50%' gap={1} flexDirection='row' justifyContent='end'>
                    <Grid item xs={4} className={(isInHouseActivity === true || isAssignedActivity === true) ? 'border-r-10px' : ''} 
                        sx={(isInHouseActivity === true || isAssignedActivity === true) ? { backgroundColor: '#fff' } : null}>
                        <BasicDatePicker
                            value={startDate}
                            setValue={handleStartDate}
                            placeholder={t("reservations.from")}
                            className="border-r-10px basic-date-picker"
                        />
                    </Grid>
                    <Grid item xs={4} className={(isInHouseActivity === true || isAssignedActivity === true) ? 'border-r-10px' : ''} 
                        sx={(isInHouseActivity === true || isAssignedActivity === true) ? { backgroundColor: '#fff' } : null}>
                        <BasicDatePicker
                            value={endDate}
                            setValue={handleEndDate}
                            placeholder={t("reservations.to")}
                            className="border-r-10px basic-date-picker"
                        />
                    </Grid>
                    {
                        isAssignedActivity !== true && <Grid item xs={4} className={isInHouseActivity === true ? 'border-r-10px' : ''} 
                            sx={isInHouseActivity === true ? { backgroundColor: '#fff' } : null}>
                            <SelectBox
                                id="status"
                                name="status"
                                items={GroupsStatus}
                                title={t("reservations.status")}
                                className="select"
                                value={groupStatus}
                                // style={{ width: "90%" }}
                                onchange={(e) => getGroupStatus(e)}
                            />
                        </Grid>
                    }
                </Stack>
            </Grid>
            <Divider style={{ width: "100%", margin: "20px 0" }} />
        </Grid>
    )
}

export default withTranslation()(ActivityGroupHeader)