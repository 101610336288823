import { Button, Modal, Stack, Typography } from '@mui/material';
import React from 'react';
import './RejectionModal.css';
import FormHead from '../../../Atoms/FormHead/FormHead';

function RejectionModal({open, setOpen, text, buttons, handelRejectRequest}) {
    const handleClose = () => { 
        setOpen(false);         
    }

  return (
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Stack alignItems='center' justifyContent='center' className='rejection-modal'>
            <FormHead text={ text[0] }></FormHead>
                {/* <Typography style={{ fontWeight: "bold", textAlign: 'left', width: '100%' }}>
                    {text[1]}
                </Typography> */}
                {
                    text[1].indexOf("\n") !== -1 ? <>
                    {
                        text[1].split("\n").map( separatedText => {
                            return <Typography style={{ fontWeight: "bold", textAlign: 'left', width: '100%' }}>
                                {separatedText}
                            </Typography>
                        } )
                    }
                    </>
                    : <Typography style={{ fontWeight: "bold", textAlign: 'left', width: '100%' }}>
                        {text[1]}
                    </Typography>
    
                }
            <Stack flexDirection='row' alignItems='center' mt={4} className='buttons-wrap'>
                <Button style={{ width: '150px', color: 'black' }} onClick={() => setOpen(false)}>{buttons[0]}</Button>                
                <Button style={{ width: '150px' }} className='primary r-25px' onClick={handelRejectRequest}>{buttons[1]}</Button>
            </Stack>
        </Stack>

    </Modal>
  )
}

export default RejectionModal