import { Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { withTranslation } from "react-i18next";
const FreelancePolicy = ({ t, formik }) => {
  const [isChecked, setChecked] = useState(false);
  const CustomSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      checked={isChecked}
      onChange={(e) => {
        setChecked(e.target.checked);
        formik?.setValues({
          ...formik?.values,
          allow_outsider_freelancers: e.target.checked,
          allow_dive_experts_requests: e.target.checked,
        });
      }}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  useEffect(() => {
    setChecked(formik?.values?.allow_outsider_freelancers);
  }, [formik?.values?.allow_outsider_freelancers]);
  return (
    <Grid
      container
      width="100%"
      className="activity-form"
      gap={2}
      flexDirection="column"
    >
      <Typography
        width="fit-content"
        component="h1"
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          borderBottom: "2px solid var(--primary-color)",
        }}
      >
        {t("profile.freelancePolicy")}
      </Typography>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography>{t("profile.freelancerDesc")}</Typography>

        <FormControlLabel control={<CustomSwitch sx={{ m: 1 }} />} />
      </Stack>
    </Grid>
  );
};

export default withTranslation()(FreelancePolicy);
