import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";
import { makeFilterString } from "../Helper/Helper.js";

export async function getSalesmenList(filter_obj = {}) {
  return await axiosFn("get", URL.Salesmen + makeFilterString(filter_obj))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function createNewSalesmen(branch_data) {
  return await axiosFn("post", URL.Salesmen, branch_data)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function getSalesmenDetails(branch_id, filter_obj = {}) {
  return await axiosFn(
    "get",
    URL.Salesmen + "/" + branch_id + makeFilterString(filter_obj)
  )
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function getSalesmenAssignActivities(branch_id, filter_obj = {}) {
  return await axiosFn(
    "get",
    URL.Salesmen +
      "/" +
      branch_id +
      "/" +
      URL.Inhouse_activities +
      makeFilterString(filter_obj)
  )
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function EditSalesmen(branch_id, data) {
  return await axiosFn("put", URL.Salesmen + "/" + branch_id, data)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function deleteSalesman(id) {
  return await axiosFn("delete", `${URL.Salesmen}/${id}`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function deleteAssignedActivity(id, inhouse_activity_id) {
  return await axiosFn(
    "delete",
    `${URL.Salesmen}/${id}/${URL.Inhouse_activities}/${inhouse_activity_id}`
  )
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
