import { Grid, Typography, Divider } from "@mui/material";
import "./Form.css";
import SelectBox from "../../Atoms/Select/Select.jsx";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import { icons } from "../../../Assets/AssetHelper.js";
import { useEffect, useState } from "react";
import SearchableSelect from "../../Atoms/Select/SearchableSelect.jsx";
import { withTranslation } from "react-i18next";

function LanguagesForm(props) {
  const {
    formTitle,
    languagesList,
    activityLanguages,
    getActivityLanguages,
    getLanguagesAPI,
    addButtonTitle,
    languagesCount,
    t,
  } = props;

  const [languages, setLanguages] = useState([]);
  const [templanguages, setTempLanguages] = useState([]);
  const [language, setLanguage] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState({});
  const setLanguageInTemp = (id, name) => {
    // setLanguage(id);
    setLanguage({ value: id, label: name });
    // setTempLanguages(templanguages => [...templanguages, { name: name, id: id }]);
    setSelectedLanguage({ name: name, id: id });
  };

  const addNewLanguage = () => {
    Object.keys(selectedLanguage).length !== 0 &&
      setTempLanguages((templanguages) => [...templanguages, selectedLanguage]);
    // var uniqueArray = [...new Map(templanguages.map(item => [item["name"], item])).values()];
    // setLanguages([...uniqueArray]);

    setLanguage({});
    setSelectedLanguage({});
  };

  useEffect(() => {
    var uniqueArray = [
      ...new Map(templanguages.map((item) => [item["name"], item])).values(),
    ];
    setLanguages([...uniqueArray]);
  }, [templanguages]);

  useEffect(() => {
    if (activityLanguages?.length > 0) {
      const data = activityLanguages?.map((val) => {
        return {
          name: val?.language?.name || val?.name,
          id: val?.language?.id || val?.id,
        };
      });
      setLanguages(data);
      setTempLanguages(data);
    }
  }, [activityLanguages]);

  useEffect(() => {
    var activityLanguages = languages.map((language) => {
      return {
        language_id: language?.id,
      };
    });
    getActivityLanguages(activityLanguages);
  }, [languages]);

  const RemoveActivityLanguage = (i) => {
    if (i > -1) {
      const filterLang = languages?.filter((val, ind) => ind != i);
      setLanguages(filterLang);
      setTempLanguages(filterLang);
    }
  };
  return (
    <Grid
      container
      className="activity-form"
      zIndex={"98"}
      position={"relative"}
    >
      <Grid container>
        <Typography
          component="h1"
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            borderBottom: "2px solid var(--primary-color)",
          }}
          className="mb-20px"
        >
          {formTitle}
        </Typography>
      </Grid>
      {languages?.map((lang, index) => (
        <Grid container>
          <Grid item sm={6}>
            <Typography
              component="h1"
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                lineHeight: "3",
              }}
            >
              {lang?.name}
            </Typography>
          </Grid>
          <Grid item sm={6} textAlign="end">
            <PrimaryButton
              btnType="icon"
              startIcon={<img src={icons.DeleteIcon} alt="delete" />}
              click={() => RemoveActivityLanguage(index)}
            />
          </Grid>
          <Divider style={{ width: "100%", margin: "10px 0" }} />
        </Grid>
      ))}
      <Grid container>
        <Grid item sm={5.8}>
          {/* <SelectBox
                        id="language"
                        name="language"
                        items={languagesList}
                        title="Select"
                        className="select"
                        value={language}
                        onclick={(id, name) => setLanguageInTemp(id, name)}
                    /> */}
          <SearchableSelect
            name="language"
            options={languagesList}
            getList={getLanguagesAPI}
            handelMenuOpen={getLanguagesAPI}
            count={languagesCount}
            label={t("profile.select")}
            className="searchable-select"
            onChange={(val) => setLanguageInTemp(val?.value, val?.label)}
            // value={{ label: language?.label, value: language?.value }}
            value={language}
            onClear={() => {
              setLanguage({});
              setSelectedLanguage({});
            }}
          />
        </Grid>
        <Grid item sm={0.4}></Grid>
        <Grid item sm={5.8}>
          <PrimaryButton
            text={addButtonTitle || t("profile.addLanguage")}
            classBtn="grey-btn"
            click={addNewLanguage}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withTranslation()(LanguagesForm);
