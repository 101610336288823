import RequestedActivitiesList from "../../../Components/Organisms/RequestedActivity/List";

const RequestedActivitiesTemplate = (props) => {
  const {
    t,
    activitesList,
    setActivitiesList,
    count,
    getActivities,
    requestedActivitiesPrivilages,
    setOpenLoader,
    profileData
  } = props;

  return (
    <RequestedActivitiesList
      activitesList={activitesList}
      setActivitiesList={setActivitiesList}
      count={count}
      getActivities={getActivities}
      activitiesPrivilages={requestedActivitiesPrivilages}
      setOpenLoader={setOpenLoader}
      profileData={profileData}
    />
  );
};

export default RequestedActivitiesTemplate;
