import { makeFilterString } from "../Helper/Helper";
import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";

export async function getUsersListSuper(filter_obj) {
  return await axiosFn("get", URL.SelectUserSuper + makeFilterString(filter_obj) )
      .then((res) => {
        return {
          res: res.data,
          err: null,
        };
      })
      .catch(handleError); 
}


export async function superLogin(user_type, user_id) {
    return await axiosFn("post", URL.auth + user_type + '/' + user_id + '/super_login')
      .then((res) => {
        return {
          res: res.data,
          err: null,
        };
      })
      .catch(handleError);
}