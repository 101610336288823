import { Grid, Typography, Divider } from "@mui/material";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import { useNavigate, useSearchParams } from "react-router-dom";
import { withTranslation } from "react-i18next";

const InHouseActivityTabs = (props) => {
  const { t, type, handleSubmit } = props;
  const Navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  return (
    <Grid container>
      <Grid container>
        <Grid container item xs={12} md={6}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Typography
              component="h1"
              style={{ fontWeight: "bold", fontSize: "30px" }}
            >
              {type === "add"
                ? t("salesmen.addNewInhouseActivity")
                : type === "edit"
                ? `${t("salesmen.editInHouseActivity")}`
                : null}
            </Typography>
          </Grid>
        </Grid>

        {type === "edit" ? (
          <>
            <Grid item xs={12} md={6} textAlign="end">
              <PrimaryButton
                text={t("activity.cancel")}
                classBtn="secoundry r-25px"
                style={{
                  padding: "5px 20px",
                  height: "48px",
                  margin: "0 10px",
                }}
                click={() =>
                  Navigate(`/salesmen/in-house-activities?page=${pageNumber}`)
                }
              />
              <PrimaryButton
                text={t("activity.applyChange")}
                classBtn="primary r-25px"
                style={{
                  padding: "5px 20px",
                  height: "48px",
                  margin: "0 10px",
                }}
                click={() => handleSubmit()}
              />
            </Grid>
          </>
        ) : type === "add" ? (
          <>
            <Grid item xs={12} md={6} textAlign="end">
              <PrimaryButton
                text={t("activity.cancel")}
                classBtn="secoundry r-25px"
                style={{
                  padding: "5px 20px",
                  height: "48px",
                  margin: "0 10px",
                }}
                click={() =>
                  Navigate(`/salesmen/in-house-activities?page=${pageNumber}`)
                }
              />
              <PrimaryButton
                fontSize="14px"
                text={t("activity.addActivity")}
                classBtn="primary r-25px"
                style={{
                  padding: "5px 20px",
                  height: "48px",
                  margin: "0 10px",
                }}
                click={() => handleSubmit()}
              />
            </Grid>
          </>
        ) : null}
      </Grid>
      <Divider style={{ width: "100%", margin: "20px 0" }} />
    </Grid>
  );
};

export default withTranslation()(InHouseActivityTabs);
