import { Grid, Typography, Skeleton } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const ImagesPanel = (props) => {
  const { type, title, DetailsImages, mediaType } = props;
  const images = DetailsImages?.media?.filter(
    (img) => img?.media_option == mediaType
  );
  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    variableWidth: true,
    infinite: images?.length > 4 ? true : false,
  };

  return images?.length > 0 ? (
    <Grid container className="details-panel">
      <Typography className="mb-20px" component="h1">
        {title}
      </Typography>
      <Grid container overflow='hidden'>
        <Slider
          {...settings}
          className={images?.length < 2 ? "carosal cond-carosal" : "carosal"}
        >
          {images?.map((img, index) => {
            return (
              <Grid item key={index} style={{ margin: "5px" }} md={3}>
                <Grid width="100%" p={1}>
                  <img
                    src={img?.url}
                    alt=""
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </Grid>
                {type === "certificates" && (
                  <Typography style={{ textAlign: "center" }}>
                    {img?.name}
                  </Typography>
                )}
              </Grid>
            );
          })}
        </Slider>
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};

export default ImagesPanel;
