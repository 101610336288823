import { Grid, Typography } from "@mui/material";
import FreelancersHeader from "../../Molecules/Reservations/FreelancersHeader.jsx";
import FreelancersRequests from "../../Molecules/Reservations/List/FreelancersRequests.jsx";

const FreelancersList = (props) => {
    

    return (
        <Grid container className="reservations-container">
            <FreelancersHeader/>
            <FreelancersRequests/>
        </Grid>
    )
}

export default FreelancersList