import {
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CustomPagination from "../../../Atoms/Pagination/Pagination";
import { useNavigate, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import AcceptApplicantModal from "../Models/AcceptApplicant";
import { acceptJopRequest } from "../../../../Contexts/APIs/Job-Offers/JobOffers";
import moment from "moment";
import { withTranslation } from "react-i18next";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import InfoModal from "../../General/Models/InfoModal";

const ListJobApplicatnts = ({
  jobOfferData,
  count,
  applicants,
  handleChange,
  jobRequestsPrivilages,
  t,
}) => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedData, setSelectedData] = useState({});
  const [applicantsData, setApplicantsData] = useState([]);
  const [jobOffer, setJobOffer] = useState({});
  const userType = localStorage.getItem("userType");
  const [openInfoModal, setOpenInfoModal] = useState({
    status: false,
    data: "",
  });

  useEffect(() => {
    setApplicantsData(applicants);
  }, [applicants]);

  useEffect(() => {
    setJobOffer(jobOfferData);
  }, [jobOfferData]);

  const handlePageChange = (event, value) => {
    setPage(value);
    handleChange("page_number", value);
  };

  const handelAcceptRequest = async () => {
    const { res, error } = await acceptJopRequest(selectedData?.data?.id);
    if (res) {
      let newData = applicantsData;
      let newJopOffer = jobOffer;
      newData[selectedData?.index].status = "accepted";
      newData[selectedData?.index].dive_expert_status = "Currently Working";
      newJopOffer.status = "accepted";
      setApplicantsData(newData);
      setJobOffer(newJopOffer);
    }
    setOpen(false);
  };
  const frrelanserStatusColors = {
    accepted: "#00b906",
    completed: "#00b906",
    pending: "#f6b119",
    canceled: "#e10007",
  };
  return (
    <Grid container>
      <Grid container className="table-header mb-20px">
        <Grid md={1} textAlign="center">
          {t("joboffer.id")}
        </Grid>
        <Grid md={2.5}>{t("joboffer.freelancerName")}</Grid>
        <Grid sm={1.5}>{t("joboffer.applyIn")}</Grid>
        <Grid sm={2}>{t("joboffer.freelancerState")}</Grid>
        <Grid sm={0.5}>{t("joboffer.fare")}</Grid>
        <Grid sm={1}>{t("joboffer.status")}</Grid>
        <Grid sm={1.5}>{t("joboffer.cancelationReason")}</Grid>
        {userType !== "descendant_user" ||
        (userType === "descendant_user" &&
          jobRequestsPrivilages?.can_update) ? (
          <Grid sm={2}>{t("joboffer.action")}</Grid>
        ) : null}
      </Grid>
      {applicants?.length === 0 && (
        <Grid container display="block" textAlign="center">
          {t("offer.thereAreNoRecords")}
        </Grid>
      )}
      {applicantsData?.map((applicant, index) => {
        return (
          <Grid
            borderRadius="10px"
            border="solid 1px #d9d9d9"
            container
            lg={12}
            className="activity-item mb-20px"
            key={applicant?.id}
            sx={{ cursor: "auto" }}
          >
            <Grid md={1} textAlign="center">
              {applicant?.id}
            </Grid>
            <Grid
              md={2.5}
              display="flex"
              alignItems="center"
              flexDirection="row"
              gap={1}
              sx={{ cursor: "pointer" }}
              onClick={() =>
                navigate(
                  `/freelancers/job-offers/${jobOffer?.id}/applicant/${applicant?.dive_expert?.id}?page=${pageNumber}`
                )
              }
            >
              <img
                src={applicant?.dive_expert?.avatar}
                width="53px"
                height="53px"
                style={{ borderRadius: "10px" }}
                alt="dive expert"
              />
              <Typography>{`${applicant?.dive_expert?.firstname} ${applicant?.dive_expert?.lastname}`}</Typography>
            </Grid>
            <Grid sm={1.5}>
              {moment(applicant?.applied_at).format("MMM , Do YYYY")}
            </Grid>
            <Grid
              sm={2}
              color={
                applicant?.dive_expert_status == "X Freelancer"
                  ? "#e10007"
                  : "#00b906"
              }
            >
              {applicant?.dive_expert_status}
            </Grid>
            <Grid sm={0.5}>{applicant?.dive_expert?.rate}</Grid>
            <Grid
              sm={1.5}
              textTransform="capitalize"
              color={frrelanserStatusColors[applicant?.status]}
            >
              {applicant?.status}
            </Grid>
            <Grid sm={1} textTransform="capitalize">
              {applicant?.cancelation_reason ? (
                <IconButton
                  className="reservation-modal-button"
                  onClick={() =>
                    setOpenInfoModal({
                      status: true,
                      data: applicant?.cancelation_reason,
                    })
                  }
                  sx={{ mb: "5px" }}
                >
                  <ErrorOutlineOutlinedIcon
                    style={{ color: "#e10007", fontSize: "1.4rem" }}
                  />
                </IconButton>
              ) : (
                "-"
              )}
            </Grid>
            {jobOffer?.status == "pending" &&
            applicant?.status != "canceled" ? (
              userType !== "descendant_user" ||
              (userType === "descendant_user" &&
                jobRequestsPrivilages?.can_create) ? (
                <Grid
                  sm={2}
                  onClick={() => {
                    setOpen(true);
                    setSelectedData({ data: applicant, index: index });
                  }}
                >
                  <Button className="primary r-25px" sx={{ width: "80%" }}>
                    {t("joboffer.accept")}
                  </Button>
                </Grid>
              ) : null
            ) : (
              <Typography mt={1.5}>-</Typography>
            )}
          </Grid>
        );
      })}
      {count > 10 && (
        <Grid container justifyContent="center" mb={7.5} mt={5}>
          <CustomPagination
            count={Math.ceil(count / 10)}
            size="large"
            shape="rounded"
            page={page}
            handleChange={handlePageChange}
            className="pagination"
          />
        </Grid>
      )}
      {open && (
        <AcceptApplicantModal
          handelAcceptRequest={handelAcceptRequest}
          open={open}
          setOpen={setOpen}
          data={selectedData?.data}
          jobOfferData={jobOfferData}
        />
      )}
      {openInfoModal?.status && (
        <InfoModal
          open={openInfoModal?.status}
          setOpen={setOpenInfoModal}
          text={[t("joboffer.cancelationReason"), openInfoModal?.data]}
        />
      )}
    </Grid>
  );
};

export default withTranslation()(ListJobApplicatnts);
