import { axiosFn, handleError } from "../index"
import * as URL from "../URLs"

export async function getDocuments(search_key) {
    return await axiosFn("get", URL.lookups + `/get_documents?search_key=${search_key}`)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}