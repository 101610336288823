import React, { useState } from 'react'
import { useEffect } from 'react'
import Loader from '../../../Components/Atoms/Loader/Loader'
import { getOffersList } from '../../../Contexts/APIs/Offers/Offers'
import OffersTemplate from '../../../Templates/Main/Offers/offers.template'

const OffersPage = ({ offersPrivilages ,profileData }) => {
    const [count, setCount] = useState(0);
    const [offers, setOffers] = useState([])
    const [filterValue, setFilterValue] = useState(0);

    const [openLoader, setOpenLoader] = useState(false);

    const getOffers = async (filters) => {
        setOpenLoader(true)
        const { res, error } = await getOffersList(filters)
        if (res) {
            setOffers(res?.data?.activity_offers);
            setCount(res?.extra?.total_count);
            setOpenLoader(false);
        }else{
            setOpenLoader(false);
        }
    }

    const [filterObj, setFilterObj] = useState({
        'page_size': 10,
        'page_number': 1,
        'sort_by': 'id',
        'job_type': '',
        'language_ids': '',
        'keyword': '',
        'price[from]': '',
        'price[to]': '',
        'city_id': '',
        'dive_center_id': '',
        'date[from]': '',
        'date[to]': '',
        'organization_id': '',
        'review_stars': ''
    });

    const handleChange = (name, value) => {
        setFilterObj({ ...filterObj, [name]: value })
    };
  
    useEffect(() => {
        const timeId = setTimeout(()=>{
            getOffers(filterObj);
        },500)
       return ()=>clearTimeout(timeId)
    }, [filterObj]);

    return (
        <>
            <Loader open={openLoader} />
            <OffersTemplate
                count={count}
                offers={offers}
                filterValue={filterValue}
                setFilterValue={setFilterValue}
                handleChange={handleChange}
                offersPrivilages={offersPrivilages}
                filterObj={filterObj}
                setFilterObj={setFilterObj}
                profileData={profileData}
            />
        </>
    )
}

export default OffersPage