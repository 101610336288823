import ActivityDetailsTemplate from "../../../Templates/Main/Acticities/ActivityDetails.template.jsx";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { getActivityDetails } from "../../../Contexts/APIs/Activities/Activities.js";
import { deleteActivity } from "../../../Contexts/APIs/Activities/Activities.js";
import { useNavigate } from "react-router-dom";
import Loader from "../../../Components/Atoms/Loader/Loader.js";
import { getRequestedActivityDetails } from "../../../Contexts/APIs/RequestedActivities/Activities.js";

const ActivityDetailsPage = (props) => {
  const { t, activitiesPrivilages, type } = props;
  const { id } = useParams();
  const [activityDetails, setActivityDetails] = useState({});
  let [searchParams, setSearchParams] = useSearchParams();
  const [openLoader, setOpenLoader] = useState(true);
  const { pathname } = useLocation();

  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  const ActivityDetails = async () => {
    setOpenLoader(true);
    const { res, err } = pathname?.includes("requested-activites")
      ? await getRequestedActivityDetails(id)
      : await getActivityDetails(id);
    if (res) {
      setActivityDetails(
        res?.data?.activity || {
          ...res?.data?.activity_request?.dive_activity,
          status: res?.data?.activity_request?.status,
        }
      );
      setOpenLoader(false);
    }
  };

  const Navigate = useNavigate();

  const RemoveActivity = async (activity_id) => {
    const { res, err } = await deleteActivity(activity_id);
    if (res) Navigate(`/activities?page=${pageNumber}`);
  };

  useEffect(() => {
    ActivityDetails();
  }, []);

  return (
    <>
      <Loader open={openLoader} />

      {!openLoader && (
        <ActivityDetailsTemplate
          activityDetails={activityDetails}
          RemoveActivity={RemoveActivity}
          activitiesPrivilages={activitiesPrivilages}
          type={type}
        />
      )}
    </>
  );
};

export default ActivityDetailsPage;
