import { Grid, Stack, Typography } from "@mui/material";
import { icons } from "../../../Assets/AssetHelper.js";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import "./Layout.css";
import { useEffect, useState } from "react";
import Notifications from "./Notifications.jsx";
import { getSupportedLanguages } from "../../../Contexts/APIs/Lookups/SupportedLanguages.js";
import ReviewModal from "../../Molecules/General/Models/ReviewModal.jsx";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { withTranslation } from "react-i18next";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const Navbar = (props) => {
  const { t, profileData, changeLanguage, setProfileData, canReadOrders } =
    props;
  const ITEM_HEIGHT = 48;
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [openReviewRejectModal, setOpenReviewRejectModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [supportedLanguages, setSupportedLanguages] = useState([]);
  const selectedLang = localStorage.getItem("lang");
  const open = Boolean(anchorEl);
  // This is a temporary array until translation files are ready
  const tempLanguages = [
    { id: "en", name: "English" },
    { id: "de", name: "German" },
    { id: "fr", name: "French" },
  ];
  const langs = {
    en: "English",
    de: "German",
    fr: "French",
  };
  console.log(langs[selectedLang], selectedLang, "selectedLang");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (val) => {
    setAnchorEl(null);
    if (typeof val == "string") {
      changeLanguage(val);
    } 
  };
  const getLanguages = async () => {
    const { res, err } = await getSupportedLanguages();
    if (res) {
      setSupportedLanguages(res?.data?.supported_locales);
    }
  };

  const userId = localStorage.getItem("userId");
  useEffect(() => {
    getLanguages();
  }, []);
  return (
    <>
      <Grid
        container
        item
        className="navbar-container"
        xs={12}
        marginBottom={4}
        sx={{ position: "sticky", top: "0px", zIndex: "999" }}
      >
        <Stack
          style={{ padding: "0px 20px" }}
          width="100%"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
          gap={1}
        >
          <Grid
            container
            gap={1}
            style={{ padding: "10px 0", alignItems: "center" }}
            width="fit-content"
          >
            <Link to="/profile">
              <img
                src={profileData?.logo || profileData?.avatar}
                width="50px"
                height="50px"
                style={{ borderRadius: "50%" }}
                alt=""
              />
            </Link>
            <Grid>
              <Link style={{ textDecoration: "none" }} to="/profile">
                <Typography
                  component="h1"
                  style={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    color: "#333132",
                  }}
                >
                  {profileData?.owner_name || profileData?.name}
                </Typography>
                <Typography
                  component="p"
                  style={{ fontSize: "14px", color: "#aaaaaa" }}
                >
                  {profileData?.localized_data?.en?.name}
                </Typography>
              </Link>
              {profileData?.profile_status === "pending_approval" ? (
                <Typography
                  component="h1"
                  style={{
                    fontSize: "14px",
                    fontWeight: "normal",
                    color: "#f6b119",
                  }}
                >
                  Being reviewed
                  <IconButton
                    className="reservation-modal-button"
                    onClick={() => setOpenReviewModal(true)}
                  >
                    <ErrorOutlineOutlinedIcon
                      style={{ color: "#e10007", fontSize: "1.4rem" }}
                    />
                  </IconButton>
                </Typography>
              ) : profileData?.profile_status === "rejected" ? (
                <Typography
                  component="h1"
                  style={{
                    fontSize: "14px",
                    fontWeight: "normal",
                    color: "#e10007",
                  }}
                >
                  {t("reservations.rejected")}
                  <IconButton
                    className="reservation-modal-button"
                    onClick={() => setOpenReviewRejectModal(true)}
                  >
                    <ErrorOutlineOutlinedIcon
                      style={{ color: "#e10007", fontSize: "1.4rem" }}
                    />
                  </IconButton>
                </Typography>
              ) : null}
            </Grid>
          </Grid>
          <Grid>
            <Notifications canReadOrders={canReadOrders} />
          </Grid>
          <Grid>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <Stack direction={"row"} alignItems={"center"}>
                <Typography> {langs[selectedLang]}</Typography>
                <KeyboardArrowDownIcon />
              </Stack>
              {/* <img src={icons.Language} alt="" /> */}
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "20ch",
                },
              }}
            >
              {tempLanguages.map((option, index) => (
                <MenuItem
                  key={index}
                  selected={option === "Pyxis"}
                  onClick={() => handleClose(option?.id)}
                >
                  {option.name}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        </Stack>

        {profileData?.status == "deactivated" && (
          <Stack
            width="100%"
            padding={1}
            bgcolor="#FFF0C5"
            color="#333132"
            fontSize="18px"
            fontWeight="500"
            flexDirection="row"
          >
            <img
              src={icons.Warning}
              width="15px"
              height="30px"
              style={{ marginInline: "5px" }}
            />
            Profile info,activities and job offers for your center will not
            appear until you activate it again
          </Stack>
        )}
      </Grid>

      {openReviewModal === true && (
        <ReviewModal
          open={openReviewModal}
          setOpen={setOpenReviewModal}
          handleRequest={() => {
            setOpenReviewModal(false);
          }}
          text={[
            t("profile.accountSubmittedReviewed"),
            t("profile.accountSubmittedReviewedMsg1"),
            `${t("profile.accountSubmittedReviewedMsg2")} "${t(
              "profile.branches"
            )}" ,"${t("profile.activities")}" ${t("profile.and")} "${t(
              "profile.jobOffers"
            )}" ${t("profile.accountSubmittedReviewedMsg3")}`,
          ]}
          buttons={[t("profile.ok")]}
        />
      )}
      {openReviewRejectModal === true && (
        <ReviewModal
          open={openReviewRejectModal}
          setOpen={setOpenReviewRejectModal}
          handleRequest={() => {
            window.location.href = "/profile";
            setOpenReviewRejectModal(false);
          }}
          text={[
            t("profile.accountSubmittedReviewedRejected"),
            `${t("profile.accountSubmittedReviewedMsg2")} "${t(
              "profile.branches"
            )}" ,"${t("profile.activities")}" ${t("profile.and")} "${t(
              "profile.jobOffers"
            )}" ${t("profile.accountSubmittedReviewedMsg3")}`,
            t("profile.rejectionReasons"),
            profileData?.rejection_reason,
          ]}
          buttons={[t("profile.completeYouProfile"), t("profile.cancel")]}
          hasReject={true}
        />
      )}
    </>
  );
};

export default withTranslation()(Navbar);
