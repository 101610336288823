import { Grid, IconButton, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import ClearIcon from "@mui/icons-material/Clear";

import "./DatePicker.css";
import { useEffect, useState } from "react";
import moment from "moment";

export default function BasicDatePicker({
  inputFormat = "DD/MM/YYYY",
  value,
  setValue,
  placeholder,
  dateMsgSelect,
  className,
  name,
  id,
  redBorder,
  errorText,
  createLis = false,
  maxDate = null,
  handelClose,
  changeListener,
  disableFuture,
  disablePast
}) {
  const [selectedDate, setSelectedDate] = useState(value ? value : null);

  const [view, setView] = useState("finish");

  const dateNow = moment(new Date()).format("YYYY-MM-DD");

  useEffect(() => {
    const timeId = setTimeout(() => {
      if (view == "finish") setValue(dayjs(selectedDate).format("YYYY/MM/DD"));
    }, 1000);
    return () => clearTimeout(timeId);
  }, [selectedDate, view]);

  useEffect(() => {
    setSelectedDate(value || null);
  }, [value]);

  // changeListener used to reset value(setSelectedDate(null)) after change date by typeing not select from calendar
  useEffect(() => {
    setSelectedDate(null);
  }, [changeListener])

  return (
    <Grid className={className} width="100%">
      <DatePicker
        name={name}
        disableHighlightToday={false}
        id={id}
        // maxDate={maxDate}
        maxDate={dayjs(maxDate)}
        value={dayjs(selectedDate)}
        onChange={(newValue) => {
          setSelectedDate(newValue);
        }}
        onViewChange={(e) => setView(e)}
        onClose={() => {
          setView("finish");
          handelClose && handelClose();
        }}
        // slotProps={{
        //   field: { clearable: selectedDate != 'Invalid Date' && selectedDate, onClear: (e) => {          
        //     e.stopPropagation();
        //     e.preventDefault();
        //     setSelectedDate(null);
        //     setValue(null);
        //   }},
        // }}
        // renderInput={(params) => (
        //   <div
        //     style={{
        //       position: "relative",
        //       display: "inline-block",
        //       width: "100%",
        //     }}
        //   >
        //     <TextField {...params} />
        //     {value && (
        //       <IconButton
        //         style={{
        //           position: "absolute",
        //           top: ".5rem",
        //           margin: "auto",
        //           right: "40px",
        //         }}
        //         onClick={(e) => {
        //           e.stopPropagation();
        //           e.preventDefault();
        //           setSelectedDate(null);
        //           setValue(null);
        //         }}
        //       >
        //         <ClearIcon />
        //       </IconButton>
        //     )}
        //   </div>
        // )}
        label={
          typeof value === "undefined" && createLis === true
            ? dateMsgSelect
            : placeholder
        }
        format={inputFormat}
        openTo={"year"}
        views={["year", "month", "day"]}
        className={`${
          typeof value === "undefined" && createLis === true && redBorder
        }`}
        disableFuture={disableFuture}
        disablePast={disablePast}
        // referenceDate={!createLis && dayjs(dateNow)}
        // referenceDate={!createLis && dayjs("2023-07-02")}
      />
        { (selectedDate != 'Invalid Date' && selectedDate) && (
          <IconButton
            style={{
              position: "absolute",
              top: ".5rem",
              margin: "auto",
              right: "40px",
            }}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setSelectedDate(null);
              setValue(null);
            }}
          >
            <ClearIcon />
          </IconButton>
        )}
    </Grid>
  );
}
