import { useEffect, useMemo, useRef, useState } from "react";
import { PropTypes } from "prop-types";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import "./TextFieldInput.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Grid } from "@mui/material";
import Loader from "../../Loader/Loader";
function TextFieldInput({
  type = "text",
  placeholder = "",
  StartAdornment = null,
  EndAdornment = null,
  multiline = false,
  rows,
  className,
  onChange,
  error,
  value,
  labelStyle,
  label,
  clickEnter,
  InputProps,
  disabled,
  justifyContent,
  helperText,
  gridClass,
  style = { width: "100%" },
  defaultValue = "",
  ...props
}) {
  const [showPassword, setshowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const inputRef = useRef();

  const textfield = {
    position: "absolute",
    top: "-12px",
    fontSize: "13px",
    margin: "0 20px",
    background: "#fff",
    zIndex: "1",
    padding: "0 10px",
    color: "black",
  };
  return (
    <Grid
      container
      sx={{ position: "relative", width: "fit-content", ...style }}
      justifyContent={justifyContent}
      className={gridClass}
    >
      {value
        ? placeholder && <span style={textfield}>{placeholder}</span>
        : null}
      <TextField
        aria-autocomplete="none"
        autoComplete={false}
        ref={inputRef}
        sx={{ p: 1 }}
        defaultValue={defaultValue}
        disabled={disabled}
        fullWidth
        multiline={multiline}
        rows={rows}
        type={type === "password" ? (showPassword ? "text" : "password") : type}
        className={className}
        style={{ padding: "8px 0" }}
        error={error ? true : false}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            clickEnter && clickEnter();
          }
          // to prevent typing character e on input type number
          if (type === "number" && ["e", "E"].includes(e.key)) {
            e.preventDefault();
          }
          if (type === "number" && e.key === "ArrowDown" && value <= 1) {
            e.preventDefault();
          }
        }}
        helperText={helperText}
        inputProps={{
          autocomplete: "new-password",
          form: {
            autocomplete: "off",
          },
        }}
        onFocus={(e) =>
          e.target.addEventListener(
            "wheel",
            function (e) {
              if (type === "number") {
                e.preventDefault(); // Prevent scrolling from changing the input value
              }
            },
            { passive: false }
          )
        }
        InputProps={
          InputProps
            ? { ...InputProps, autoComplete: "off" }
            : {
                startAdornment: (
                  <InputAdornment position="start" style={{ height: "auto" }}>
                    <>{StartAdornment}</>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end" style={{ height: "auto" }}>
                    {type === "password" ? (
                      <>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? (
                            <Visibility className="password-icon" />
                          ) : (
                            <VisibilityOff className="password-icon" />
                          )}
                        </IconButton>
                      </>
                    ) : (
                      EndAdornment
                    )}
                  </InputAdornment>
                ),
              }
        }
        {...props}
      />
    </Grid>
  );
}

TextFieldInput.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  StartAdornment: PropTypes.element,
  EndAdornment: PropTypes.element,
  className: PropTypes.string,
};
export default TextFieldInput;
