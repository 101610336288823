import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";

export async function ShowUser(user_id) {
    return await axiosFn("get", URL.Decendent + user_id)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}