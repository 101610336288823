import {
  Box,
  Grid,
  LinearProgress,
  linearProgressClasses,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import CustomSeparator from "../../Atoms/BreadCrumb";
import FreelancerReviewsList from "../../Molecules/Freelancers/Lists/FreelancerReviewsList";
import { styled } from "@mui/material/styles";
import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { AssignmentReturnRounded } from "@mui/icons-material";
import { t } from "i18next";
import { withTranslation } from "react-i18next";

const FreelancerReviews = ({
  reviewsList,
  reviewsDetails,
  count,
  applicantData,
  handleChange,
  t,
}) => {
  let [searchParams, setSearchParams] = useSearchParams();
  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");

  const lang = localStorage.getItem("lang");
  const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
      color: "#3ed181",
    },
    "& .MuiRating-iconHover": {
      color: "#3ed181",
    },
  });
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#3ed181" : "#308fe8",
    },
  }));
  const arr = [5, 4, 3, 2, 1];
  return (
    <Stack gap={3}>
      <Grid sm={12} container>
        <CustomSeparator
          breadcrumbs={[
            <Link
              style={{ color: "#bebebe", textDecoration: "none" }}
              underline="hover"
              key="1"
              color="inherit"
              to={`/freelancers/list?page=${pageNumber}`}
            >
              {t("joboffer.freelancer")}
            </Link>,
            <Link
              style={{ color: "#bebebe", textDecoration: "none" }}
              underline="hover"
              key="2"
              color="inherit"
              to={`/freelancers/job-offers?page=${pageNumber}`}
            >
              {t("joboffer.jopOffers")}
            </Link>,

            <Typography
              key="3"
              style={{ color: "#bebebe", textDecoration: "none" }}
            >
              {applicantData?.name}
            </Typography>,
          ]}
        />
      </Grid>

      <Typography as="h1" fontSize="30px" fontWight="bold">
        {t("branch.ratingReview")}{" "}
        <span style={{ fontSize: "14px", color: "#3ed181" }}>
          ({reviewsDetails?.total_reviews} {t("joboffer.reviews")})
        </span>
      </Typography>
      <Stack flexDirection="row" justifyContent="space-between">
        <Box
          width="50%"
          borderRadius="10px"
          boxShadow="0 2px 15px 0 rgba(0, 0, 0, 0.1)"
          background="white"
          padding={2}
        >
          <FreelancerReviewsList
            reviewsList={reviewsList}
            count={count}
            handleChange={handleChange}
          />
        </Box>
        <Stack
          width="40%"
          height="170px"
          borderRadius="10px"
          boxShadow="0 2px 15px 0 rgba(0, 0, 0, 0.1)"
          background="white"
          flexDirection="row"
          justifyContent="space-between"
          padding={2}
        >
          <Stack
            bgcolor="#f8f8f8"
            alignItems="center"
            justifyContent="center"
            p={2}
            width="40%"
          >
            <Typography fontSize="34px" fontWeight="bold">
              {reviewsDetails?.avg_rate} / 5
            </Typography>
            <StyledRating
              name="customized-color"
              value={reviewsDetails?.avg_rate}
              readOnly={true}
              icon={
                <CircleIcon fontSize="inherit" style={{ fontSize: "12px" }} />
              }
              emptyIcon={
                <CircleOutlinedIcon
                  fontSize="inherit"
                  style={{ fontSize: "12px", color: "#3ed181" }}
                />
              }
            />
            <Typography color="#3ed181">
              {reviewsDetails?.total_reviews}
            </Typography>
          </Stack>
          <Stack>
            {arr?.map((val, index) => {
              const totalReviews = reviewsDetails.total_reviews;
              const percentage =
                totalReviews > 0
                  ? (reviewsDetails[val] / totalReviews) * 100
                  : 0;

              return (
                <Stack
                  key={index}
                  style={{ flexGrow: 1, width: "200px" }}
                  flexDirection="row"
                  gap={1}
                  alignItems="center"
                >
                  <Typography style={{ color: "#424242" }}>{val}</Typography>
                  <Box style={{ width: "180px" }}>
                    <BorderLinearProgress
                      variant="determinate"
                      value={percentage}
                    />
                  </Box>
                  <Typography style={{ color: "#424242" }}>
                    ({reviewsDetails[val] || 0})
                  </Typography>
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default withTranslation()(FreelancerReviews);
