import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { withTranslation } from "react-i18next";
import "react-phone-input-2/lib/material.css";
import { NavLink, useNavigate } from "react-router-dom";
import TextFieldInput from "../../../Components/Atoms/Input/TextField/TextFieldInput";
import UploadImagesForm from "../../../Components/Molecules/Forms/UploadImagesForm";
import AboutOperator from "../../../Components/Organisms/Auth/Account/Forms/aboutOperator";
import ContactPersonInfo from "../../../Components/Organisms/Auth/Account/Forms/ContactPersonInfo";
import DiveOperationInformation from "../../../Components/Organisms/Auth/Account/Forms/diveOperationInformation";
import FreelancePolicy from "../../../Components/Organisms/Auth/Account/Forms/freelancePolicy";
import "./AccountUpdate.css";
import { useEffect, useState } from "react";
import ReactPhoneInput2 from "../../../Components/Atoms/Input/PhoneInput/ReactPhoneInput2";
const AccountUpdateTemplate = ({
  t,
  languagesList,
  formik,
  uploadActivityImages,
  diveCenterDetails,
  removeActivityImage,
  activityImages,
  languagesCount,
  getLanguagesAPI,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (formik?.values.primary_language_id?.toString().length === 0) {
      getLanguagesAPI(null);
    }
  }, [formik?.values.primary_language_id]);
  return (
    <Grid className="update-profile">
      <Container maxWidth="XL">
        <Stack flexDirection="row" alignItems="center" gap={1} mb={2}>
          <NavLink to="/profile" style={{ textDecoration: "none" }}>
            <Typography fontSize="18px" color="#bebebe">
              {t("profile.profile")} {">"}
            </Typography>
          </NavLink>
          <Typography fontSize="18px" color="#bebebe">
            {t("profile.editProfile")}
          </Typography>
        </Stack>

        <form onSubmit={formik.handleSubmit}>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography fontSize="30px" fontWeight="bold">
              {t("profile.editProfile")}
            </Typography>

            <Stack flexDirection="row">
              <Button
                onClick={() => navigate("/profile")}
                style={{
                  borderRadius: "26px",
                  color: "black",
                  border: "1px solid #bebebe",
                  padding: "5px 20px",
                  height: "48px",
                  margin: "0 10px",
                }}
              >
                {t("profile.cancel")}
              </Button>
              <Button
                style={{
                  borderRadius: "26px",
                  backgroundColor: "var(--primary-color)",
                  color: "white",
                  padding: "5px 20px",
                  height: "48px",
                  margin: "0 10px",
                }}
                type="submit"
              >
                {t("profile.saveChanges")}
              </Button>
            </Stack>
          </Stack>

          <Divider
            style={{
              marginBlock: "20px",
              width: "100%",
              background: "#d9d9d9",
            }}
          />
          <Stack flexDirection="row" width="100%" gap={2}>
            <Box className="update-profile-img-box">
              <UploadImagesForm
                type="single"
                uploadImages={uploadActivityImages}
                formTitle={t("profile.logoUpload")}
                Images={activityImages}
                RemoveImage={removeActivityImage}
                hideSizeTip={true}
              />
            </Box>

            <Box width="48%">
              <Grid container className="activity-form" gap={2}>
                <Typography
                  component="h1"
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    borderBottom: "2px solid var(--primary-color)",
                  }}
                  className="mb-20px"
                >
                  {t("profile.ownerInformation")}
                </Typography>
                <Stack flexDirection="row" width="100%" gap={2}>
                  <TextFieldInput
                    style={{ width: "100%" }}
                    name="owner_name"
                    id="owner_name"
                    placeholder={t("profile.ownerName")}
                    className="login-input"
                    type="text"
                    defaultValue={diveCenterDetails?.owner_name}
                    onChange={formik.handleChange}
                    value={formik.values.owner_name}
                    error={
                      formik.touched.owner_name &&
                      Boolean(formik.errors.owner_name)
                    }
                    helperText={
                      formik.touched.owner_name && formik.errors.owner_name
                    }
                    clickEnter={formik.handleSubmit}
                  />
                  <ReactPhoneInput2
                    formik={formik}
                    name="phone_number"
                    customContainerClass="owner-info-phone-input"
                    placeholder={t("profile.ownermobile")}
                    specialLabel={t("profile.ownermobile")}
                    phoneProperty="owner_phone_number"
                    codeProperty="owner_country_code"
                    enableSearch={true}
                    showBorder={true}
                  />
                </Stack>
                <TextFieldInput
                  style={{ width: "100%" }}
                  name="owner_email"
                  id="owner_email"
                  placeholder={t("profile.ownerEmailAddress")}
                  className="login-input "
                  type="email"
                  defaultValue={diveCenterDetails?.owner_email}
                  onChange={formik.handleChange}
                  value={formik.values.owner_email}
                  error={
                    formik.touched.owner_email &&
                    Boolean(formik.errors.owner_email)
                  }
                  helperText={
                    formik.touched.owner_email && formik.errors.owner_email
                  }
                  clickEnter={formik.handleSubmit}
                />
              </Grid>
            </Box>
          </Stack>
          <Stack flexDirection="row" gap={2} width="100%">
            <Box width="48%">
              <DiveOperationInformation
                formik={formik}
                languagesList={languagesList}
                getLanguagesAPI={getLanguagesAPI}
                languagesCount={languagesCount}
              />
              <FreelancePolicy formik={formik} />
            </Box>

            <Box width="48%">
              <AboutOperator formik={formik} />
              <ContactPersonInfo formik={formik} />
            </Box>
          </Stack>
        </form>
      </Container>
    </Grid>
  );
};

export default withTranslation()(AccountUpdateTemplate);
