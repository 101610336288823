import { Grid, Typography, Divider, Stack } from "@mui/material";
import NormalTabs from "../../Atoms/Tabs/NormalTabs.js";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import AddIcon from "@mui/icons-material/Add";
import TextFieldInput from "../../Atoms/Input/TextField/TextFieldInput.jsx";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate, useSearchParams } from "react-router-dom";
import { withTranslation } from "react-i18next";

const BranchesTabs = (props) => {
  const {
    t,
    options,
    handleBranchValue,
    BranchValue,
    type,
    getBranchType,
    getKeywords,
    searchKeywords,
    count,
    handleSubmit,
    formType,
    branchesPrivilages,
  } = props;
  const navigate = useNavigate();
  const userType = localStorage.getItem("userType");
  let [searchParams, setSearchParams] = useSearchParams();
  // const [submitCounter, setSubmitCounter] = useState(0);

  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
    
  // useEffect(()=>{
  //   if(localStorage.getItem("add-branch") == "pending"){ setSubmitCounter(1) }
  //   if(localStorage.getItem("add-branch") == "fail"){ 
  //     setTimeout(() => {
  //       setSubmitCounter(0);        
  //     }, 1000);
  //   }
  // },[localStorage.getItem("add-branch")])
  return (
    <Grid container>
      <Stack
        container
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="space-between"
        width="100%"
      >
        <Stack
          container
          flexDirection="row"
          width="fit-content"
          gap={2}
          alignItems="center"
        >
          <Typography
            component="h1"
            style={{ fontWeight: "bold", fontSize: "30px" }}
          >
            {formType === "add"
              ? t("branch.addNewBranch")
              : t("branch.branches")}
            {formType !== "add" && (
              <Typography
                component="p"
                style={{
                  color: "#bebebe",
                  fontSize: "18px",
                  lineHeight: "2.5",
                }}
              >
                {count} {t("branch.branches")}
              </Typography>
            )}
          </Typography>
        </Stack>
        <NormalTabs
          type={type}
          formType={formType}
          TabsOptions={options}
          value={BranchValue}
          getBranchType={getBranchType}
          change={handleBranchValue}
          ContainerStyle="switch-container"
          TabStyle="switch-btn mb-20px"
          onclick={(value) => handleBranchValue(value)}
        />
        {formType != "add" && formType != "edit" ? (
          <>
            <Stack container flexDirection="row" gap={1}>
              <TextFieldInput
                style={{ width: "250px" }}
                type="search"
                placeholder={t("branch.searchBranches")}
                StartAdornment={<SearchIcon />}
                className="activity-search"
                onChange={(e) => getKeywords(e.target.value)}
                value={searchKeywords}
                sx={{ padding: "0px!important" }}
              />
              {userType !== "descendant_user" && (
                <PrimaryButton
                  startIcon={<AddIcon />}
                  text={t("branch.addBranch")}
                  classBtn="primary r-25px"
                  style={{
                    padding: "5px 10px",
                    height: "48px",
                    width: "220px",
                  }}
                  click={() =>
                    navigate(`/branches/add-new-branch?page=${pageNumber}`)
                  }
                />
              )}
              {userType === "descendant_user" &&
                branchesPrivilages.can_create && (
                  <PrimaryButton
                    startIcon={<AddIcon />}
                    text={t("branch.addBranch")}
                    classBtn="primary r-25px"
                    style={{
                      padding: "5px 10px",
                      height: "48px",
                      width: "220px",
                    }}
                    click={() =>
                      navigate(`/branches/add-new-branch?page=${pageNumber}`)
                    }
                  />
                )}
            </Stack>
          </>
        ) : formType == "add" ? (
          <>
            <Grid sm={5} textAlign="end" md={4}>
              <PrimaryButton
                text={t("branch.cancel")}
                classBtn="secoundry r-25px"
                style={{
                  padding: "5px 20px",
                  height: "48px",
                  margin: "0 10px",
                }}
                click={() => navigate(`/branches?page=${pageNumber}`)}
              />
              <PrimaryButton
                text={t("branch.addBranch")}
                classBtn="primary r-25px"
                style={{
                  padding: "5px 20px",
                  height: "48px",
                  margin: "0 10px",
                }}
                // disabled={submitCounter === 0}
                click={() => handleSubmit()}
              />
            </Grid>
          </>
        ) : formType == "edit" ? (
          <Stack container flexDirection="row" gap={2}>
            <PrimaryButton
              text={t("branch.cancel")}
              classBtn="secoundry r-25px"
              style={{ padding: "5px 20px", height: "48px", margin: "0 10px" }}
              click={() => navigate(`/branches?page=${pageNumber}`)}
            />

            <PrimaryButton
              text={t("branch.editBranch")}
              classBtn="primary r-25px"
              style={{ padding: "5px 20px", height: "48px", width: "300px" }}
              click={() => handleSubmit()}
            />
          </Stack>
        ) : null}
      </Stack>
      <Divider style={{ width: "100%", margin: "20px 0" }} />
    </Grid>
  );
};

export default withTranslation()(BranchesTabs);
