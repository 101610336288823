import { Grid, Typography, Divider } from "@mui/material";
import { withTranslation } from "react-i18next";
import BasicDatePicker from "../../../Atoms/Input/DatePicker/DatePicker.js";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput.jsx";
import "./Form.css";

const BoatInfo = (props) => {
  const {
    formik,
    yearBuilt,
    setYearBuilt,
    renovaled,
    setRenovated,
    startBorder,
    endBorder,
    t,
  } = props;

  return (
    <Grid container lg={12} className="activity-form">
      <Grid container md={12}>
        <Typography
          component="h1"
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            borderBottom: "2px solid var(--primary-color)",
          }}
          className="mb-20px"
        >
          {t("branch.safariBoatSpecifications")}
        </Typography>
      </Grid>
      <Grid container md={12} className="mb-20px">
        <Grid sm={5.8}>
          <TextFieldInput
            name="boat_length"
            id="boat_length"
            placeholder={t("branch.boatLength")}
            className="login-input"
            type="text"
            value={formik.values.boat_length}
            error={
              formik.touched.boat_length && Boolean(formik.errors.boat_length)
            }
            helperText={formik.touched.boat_length && formik.errors.boat_length}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid sm={0.4}></Grid>
        <Grid sm={5.8}>
          <TextFieldInput
            name="boat_width"
            id="boat_width"
            placeholder={t("branch.boatWidth")}
            className="login-input"
            type="text"
            value={formik.values.boat_width}
            error={
              formik.touched.boat_width && Boolean(formik.errors.boat_width)
            }
            helperText={formik.touched.boat_width && formik.errors.boat_width}
            onChange={formik.handleChange}
          />
        </Grid>
      </Grid>

      <Grid container md={12} className="mb-20px">
        <Grid sm={5.8}>
          <BasicDatePicker
            placeholder={t("branch.yearBuilt")}
            value={yearBuilt}
            className="border-r-10px basic-date-picker mb-20px"
            setValue={setYearBuilt}
            redBorder={endBorder}
            errorText={t("branch.dateError")}
            maxDate={new Date()}
            createLis={true}
          />
          <TextFieldInput
            name="total_cabins"
            id="total_cabins"
            placeholder={t("branch.NoOfCabins")}
            className="login-input"
            type="text"
            value={formik.values.total_cabins}
            error={
              formik.touched.total_cabins && Boolean(formik.errors.total_cabins)
            }
            helperText={
              formik.touched.total_cabins && formik.errors.total_cabins
            }
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid sm={0.4}></Grid>
        <Grid sm={5.8}>
          <BasicDatePicker
            placeholder={t("branch.yearRenovated")}
            value={renovaled}
            setValue={setRenovated}
            className="border-r-10px basic-date-picker mb-20px"
            redBorder={startBorder}
            errorText={t("branch.dateError")}
            maxDate={new Date()}
            createLis={true}
          />
        </Grid>
      </Grid>
      <Grid container md={12} className="mb-20px">
        <Grid sm={12}>
          <TextFieldInput
            name="boat_features"
            id="boat_features"
            placeholder={t("branch.additionalInformation")}
            className="login-input"
            type="text"
            rows={4}
            multiline
            value={formik.values.boat_features}
            error={
              formik.touched.boat_features &&
              Boolean(formik.errors.boat_features)
            }
            helperText={
              formik.touched.boat_features && formik.errors.boat_features
            }
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid sm={0.4}></Grid>
        <Grid sm={5.8}></Grid>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(BoatInfo);
