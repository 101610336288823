import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { checkToken } from "../../../Contexts/APIs/Auth/Login";
import Navbar from "./Navbar";
import SideBar from "./SideBar";

const MainLayout = ({
  children,
  setProfileData,
  profileData,
  canReadActivities,
  canReadBranches,
  canReadOffers,
  canReadOrders,
  canReadFreelancers,
  canReadJobOffers,
  canReadUsers,
  canReadJobRequests,
  canReadSalesmen,
  canReadSalesmenReservations,
  canReadSalesmenInhouseActivities,
  canReadSalesmenStatistics,
  diveExpertActivityRequests,
}) => {
  const [open, setOpen] = useState(true);

  /*const DiveCenterDetails = async () => {
    const { res, err } = await checkToken();
    if (res) {
    }
  };
  useEffect(() => {
    DiveCenterDetails();
  }, []);*/
  const changeLanguage = (val) => {
    localStorage.setItem("lang", val);
    localStorage.setItem("i18nextLng", val);
    window.location.reload();
  };
  return (
    <Grid container sx={{ minWidth: { md: "100%", xs: "1600px" } }}>
      <Grid
        item
        xl={open ? 1.7 : 0.5}
        md={open ? 2.5 : 0.5}
        xs={open ? 3 : 0.5}
      >
        <SideBar
          canReadActivities={canReadActivities}
          canReadBranches={canReadBranches}
          canReadOffers={canReadOffers}
          canReadOrders={canReadOrders}
          canReadFreelancers={canReadFreelancers}
          canReadJobOffers={canReadJobOffers}
          canReadUsers={canReadUsers}
          canReadJobRequests={canReadJobRequests}
          canReadSalesmen={canReadSalesmen}
          canReadSalesmenReservations={canReadSalesmenReservations}
          canReadSalesmenInhouseActivities={canReadSalesmenInhouseActivities}
          canReadSalesmenStatistics={canReadSalesmenStatistics}
          diveExpertActivityRequests={diveExpertActivityRequests}
          salesmanFeature={
            profileData?.user_type == "descendant_user"
              ? profileData?.ancestor?.salesman_feature
              : profileData?.salesman_feature
          }
          open={open}
          setOpen={setOpen}
        />
      </Grid>
      <Grid
        item
        xl={open ? 10.3 : 11.5}
        md={open ? 9.5 : 11.5}
        xs={open ? 9 : 11.5}
        style={{ background: "#fcfcfc" }}
      >
        <Navbar
          profileData={profileData}
          changeLanguage={changeLanguage}
          setProfileData={setProfileData}
          canReadOrders={canReadOrders}
        />
        <Grid
          sx={{
            minHeight: "90vh",
            padding: "40px 0",
            overflowX: "hidden",
          }}
        >
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MainLayout;
