import { useState } from "react";

import Loader from "../../../Components/Atoms/Loader/Loader.js";
import FreelancersListTemplate from "../../../Templates/Main/Freelancers/FreelancersList.Template.jsx";
import { getFreelancersList } from "../../../Contexts/APIs/Freelancers/Freelancers.js";
import { toast } from "react-toastify";
import { useEffect } from "react";

const FreelancersListPage = ({ freelancersPrivilages,profileData }) => {
  const [count, setCount] = useState();
  const [openLoader, setOpenLoader] = useState(false);
  const [freelancers, setFreelancers] = useState([]);
  const [filterObj, setFilterObj] = useState({
    page_number: 1,
    keyword: "",
  });
  const getFreelancers = async (filter) => {
    setOpenLoader(true);
    const { res, err } = await getFreelancersList(filter);
    if (res) {
      setCount(res?.extra?.total_count);
      setFreelancers(res?.data?.dive_experts);
    }
    setOpenLoader(false);
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      getFreelancers(filterObj);
    }, 500);
    return () => clearTimeout(timeId);
  }, [filterObj]);
  return (
    <>
      <Loader open={openLoader} />
      <FreelancersListTemplate
        count={count}
        freelancers={freelancers}
        getFreelancers={getFreelancers}
        freelancersPrivilages={freelancersPrivilages}
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        profileData={profileData}
      />
    </>
  );
};

export default FreelancersListPage;
