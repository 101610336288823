import { Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import FaqAccordiosWrap from "../../../../Components/Organisms/Help/Faq/FaqAccordiosWrap";
import { getFaqAccordionsData } from "../../../../Contexts/APIs/Lookups/Help";
import CustomPagination from "../../../../Components/Atoms/Pagination/Pagination";

function FaqTemplate({ head }) {
  const [dt, setDt] = useState([]);
  const [count, setCount] = useState();
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState({
    page_size: 10,
    page_number: page,
    for: "dive_center",
  });
  const handlePageChange = (event, value) => {
    setPage(value);
    setFilterObj({ ...filterObj, page_number: value });
  };
  const getDt = async () => {
    const { res, err } = await getFaqAccordionsData(filterObj);
    setDt(res.data.faqs);
    setCount(res?.extra?.total_count);
  };
  useEffect(() => {
    getDt();
  }, [filterObj]);
  return (
    <>
      <Typography
        component="h1"
        style={{
          fontWeight: "bold",
          fontSize: "30px",
          marginBlock: "1rem",
          color: "#333132",
        }}
      >
        {head}
      </Typography>
      <Divider />
      <FaqAccordiosWrap acrData={dt} />
      {count > 10 && (
        <Grid container justifyContent="center" mb={7.5} mt={5}>
          <CustomPagination
            count={Math.ceil(count / 10)}
            size="large"
            shape="rounded"
            page={page}
            handleChange={handlePageChange}
            className="pagination"
          />
        </Grid>
      )}
    </>
  );
}

export default FaqTemplate;
