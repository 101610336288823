import React, { useState } from "react";
import SearchableSelect from "../../Atoms/Select/SearchableSelect";
import { getSalesmenList } from "../../../Contexts/APIs/Salsemen/Salesmen";
import { withTranslation } from "react-i18next";
import "./DiveCenterStatistics.css";

const Salesmanfilter = ({ t, setFilterObj, filterObj }) => {
  const [salesmenList, setSalesmenList] = useState([]);
  const [salesmen, setSalesmen] = useState({});
  const [salesmenCount, setSalesmenCount] = useState(10);
  const [openLoader, setOpenLoader] = useState(false);

  const getSalesmenListing = async (filter) => {
    setOpenLoader(true);
    const filterData = {
      page_number: "1",
      page_size: "10",
      ...filter,
    };
    const { res, err } = await getSalesmenList({
      ...filterData,
    });
    if (res) {
      filterData?.page_number == 1
        ? setSalesmenList(res?.data?.salesmen)
        : setSalesmenList((s) => [...s, ...res?.data?.salesmen]);

      setSalesmenCount(res?.extra?.total_count);
    }
    if (err) {
    }
    setOpenLoader(false);
  };
  const updateSalesmenReservationsList = (name, value) => {
    filterObj[name] != value && setFilterObj({ ...filterObj, [name]: value });
  };
  const updateSalesmen = (salesmen_val) => {
    setSalesmen({ label: salesmen_val?.label, value: salesmen_val?.id });
    updateSalesmenReservationsList("salesman_id", salesmen_val?.value);
  };
  return (
    <SearchableSelect
      name="salesmen"
      handelMenuOpen={salesmenList?.length == 0 && getSalesmenListing}
      options={[{ name: "All", id: 0 }, ...salesmenList]}
      getList={getSalesmenListing}
      count={salesmenCount}
      label={t("salesmen.salesmenName")}
      className="searchable-select branches-filter  border-r-10px"
      onChange={(val) => updateSalesmen(val)}
      value={{ label: salesmen?.label, value: salesmen?.value }}
      onClear={() => {
        setSalesmen({});
        updateSalesmenReservationsList("salesman_id", null);
        getSalesmenListing({ salesman_id: null });
      }}
    />
  );
};

export default withTranslation()(Salesmanfilter);
