import { Grid } from '@mui/material';
import React from 'react';
import verifyCodeImg from './../../../Assets/Images/verificationcode.png';
import PrimaryButton from '../../Atoms/Buttons/Primary/PrimaryButton';
import ForgetPasswordHead from './ForgetPasswordHead';

import { useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import './ForgetPassword.css';

function EmailSuccess({t}) {
    const navigate = useNavigate();

    const handleSubmit = () => {
        navigate('/email-verify');
    }
  return (
    <Grid container lg={4} md={4} sm={5} xs={8} className='password-form-wrap'>
        <ForgetPasswordHead imgSrc={verifyCodeImg} imgAlt='verificationCode' text={t("forgotPassword.emailSuccessHead")} />

        <Grid lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'center', marginTop: '1rem' }}>
            <PrimaryButton
                text={t("forgotPassword.done")}
                classBtn="primary"
                style={{ padding: "5px 50px", margin: "auto" }}
                click={handleSubmit}
            />
        </Grid>
    </Grid>
  )
}

export default withTranslation() (EmailSuccess)