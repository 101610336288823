import { Grid, Typography, Divider, Rating, Stack } from "@mui/material";
import PhoneTwoToneIcon from "@mui/icons-material/PhoneTwoTone";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import { icons } from "../../../../Assets/AssetHelper.js";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import { useNavigate, useSearchParams } from "react-router-dom";

import { withTranslation } from "react-i18next";
import SwitcheLabel from "../../../Atoms/Buttons/Switch/SwitchButton.js";
import AttentionModal from "../../General/Models/AttentionModal.jsx";
import {
  DeleteBranch,
  EditBranch,
} from "../../../../Contexts/APIs/Branches/Branches.js";
import { useEffect, useState } from "react";
import DeleteBranchModal from "../Models/DeleteBranch.jsx";
const BranchDetailsHeader = (props) => {
  const { branchDetails, t, branchesPrivilages } = props;
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  const userType = localStorage.getItem("userType");
  const lang = localStorage.getItem("lang");

  const [activeState, setActiveState] = useState(
    branchDetails?.status === "approved" ? true : false
  );
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setActiveState(branchDetails?.status === "approved" ? true : false);
  }, [branchDetails]);

  const handelDeleteBranch = async (id) => {
    const { res, err } = await DeleteBranch(id);
    if (res) {
      navigate("/branches?page=1", { replace: true });
    }
  };
  return (
    <Grid container>
      <Grid
        container
        item
        lg={11}
        margin="auto"
        padding="20px 0"
        justifyContent="space-between"
      >
        <Grid container item md={9}>
          <Grid item md={"auto"} mr={2}>
            <Typography fontWeight="bold" component="h1" fontSize="28px">
              {branchDetails?.localized_data?.[lang]?.name}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography
              component="p"
              lineHeight="3"
              color="#bebebe"
              textTransform="capitalize"
            >
              {branchDetails?.branch_type?.replace("_", " ") == "office"
                ? t('branch.diveCenter')
                : branchDetails?.branch_type?.replace("_", " ")}
            </Typography>
          </Grid>

          <Grid container item md={12}>
            <PrimaryButton
              startIcon={<PhoneTwoToneIcon />}
              text={branchDetails?.branch_number}
              classBtn="branch-details-info"
            />
            <PrimaryButton
              startIcon={<MailOutlineOutlinedIcon />}
              text={branchDetails?.email}
              classBtn="branch-details-info"
              style={{ textTransform: "none" }}
            />
          </Grid>
        </Grid>
        <Grid container item md={3} paddingTop="10px">
          <Stack flexDirection="row">
            {userType === "descendant_user" &&
              branchesPrivilages.can_update && (
                <PrimaryButton
                  click={() =>
                    navigate(
                      `/branches/edit-branch/${
                        branchDetails?.id
                      }?branchType=${searchParams.get(
                        "branchType"
                      )}&page=${pageNumber}`
                    )
                  }
                  btnType="icon"
                  startIcon={<img width="35" src={icons.EditIcon} alt="edit" />}
                />
              )}

            {userType !== "descendant_user" && (
              <PrimaryButton
                click={() =>
                  navigate(
                    `/branches/edit-branch/${
                      branchDetails?.id
                    }?branchType=${searchParams.get(
                      "branchType"
                    )}&page=${pageNumber}`
                  )
                }
                btnType="icon"
                startIcon={<img width="35" src={icons.EditIcon} alt="edit" />}
              />
            )}
            {userType === "descendant_user" &&
              branchesPrivilages.can_update &&
              (branchDetails?.status === "approved" ||
                branchDetails?.status === "deactivated") && (
                <Stack flexDirection="row" gap={1} alignItems="center">
                  <Stack
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <SwitcheLabel
                      checked={activeState}
                      style={{ margin: "auto", padding: "12px" }}
                      change={(data) => {
                        if (data === false) setOpen(true);
                        else {
                          EditBranch(branchDetails?.id, {
                            branch: { status: "approved" },
                          });
                          setActiveState(true);
                        }
                      }}
                      className={"dive-center-active-switch"}
                    />
                  </Stack>
                  <Stack
                    flexDirection="column"
                    py="4px"
                    justifyContent="space-between"
                  >
                    <Typography
                      fontSize="16px"
                      fontWeight="bold"
                      color="#f6b119"
                      display="block"
                    >
                      {activeState ? "Active" : "Deactive"}
                    </Typography>
                  </Stack>
                </Stack>
              )}
            {userType != "descendant_user" &&
              (branchDetails?.status === "approved" ||
                branchDetails?.status === "deactivated") && (
                <Stack flexDirection="row" gap={1} alignItems="center">
                  <Stack
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <SwitcheLabel
                      checked={activeState}
                      style={{ margin: "auto", padding: "12px" }}
                      change={(data) => {
                        if (data === false) setOpen(true);
                        else {
                          EditBranch(branchDetails?.id, {
                            branch: { status: "approved" },
                          });
                          setActiveState(true);
                        }
                      }}
                      className={"dive-center-active-switch"}
                    />
                  </Stack>
                  <Stack
                    flexDirection="column"
                    py="4px"
                    justifyContent="space-between"
                  >
                    <Typography
                      fontSize="16px"
                      fontWeight="bold"
                      color="#f6b119"
                      display="block"
                    >
                      {activeState ? "Active" : "Deactive"}
                    </Typography>
                  </Stack>
                </Stack>
              )}

            {userType === "descendant_user" &&
              branchesPrivilages.can_delete && (
                <PrimaryButton
                  click={() => setOpenDeleteModel(true)}
                  btnType="icon"
                  startIcon={
                    <img width="35" src={icons.DeleteIcon} alt="edit" />
                  }
                />
              )}

            {userType !== "descendant_user" && (
              <PrimaryButton
                click={() => setOpenDeleteModel(true)}
                btnType="icon"
                startIcon={<img width="35" src={icons.DeleteIcon} alt="edit" />}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
      <Divider style={{ width: "100%" }} />
      {open === true && (
        <AttentionModal
          open={open}
          setOpen={setOpen}
          handleRequest={() => {
            EditBranch(branchDetails?.id, {
              branch: {
                status: "deactivated",
              },
            });
            setOpen(false);
            setActiveState(false);
          }}
          text={[
            branchDetails?.name,
            t("branch.branchDeactivated"),
            t("branch.branchDeactivatedMsg"),
          ]}
          buttons={[t("branch.deactivate"), t("branch.cancel")]}
        />
      )}
      {openDeleteModel && (
        <DeleteBranchModal
          open={openDeleteModel}
          setOpen={setOpenDeleteModel}
          handelDeleteRequest={() => {
            handelDeleteBranch(branchDetails?.id);
            setOpenDeleteModel(false);
          }}
          text={[t("branch.deleteThisBranch"), t("branch.deleteMsg")]}
        />
      )}
    </Grid>
  );
};

export default withTranslation()(BranchDetailsHeader);
