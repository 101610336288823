import React from 'react'
import { Grid, Typography, Divider } from "@mui/material";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import TextFieldInput from '../../../Atoms/Input/TextField/TextFieldInput.jsx';
import { withTranslation } from 'react-i18next';

const AssignedActivitiesHeader = ({t, activitiesNo, getKeywords, setModalType, setOpenAssignActivityModal, salesmenPrivilages}) => {
    const userType = localStorage.getItem("userType");
  return (
    <>
    <Grid container className='assigned-activities-header'>
      <Grid item sm={12} md={6} display={'flex'} flexDirection="row" justifyContent={'start'} alignItems="center" flexWrap={'wrap'} gap={'10px 50px'}>
        <Typography component="h1" style={{ fontWeight: "bold", fontSize: "30px" }}>
          {t("salesmen.assignedInHouseActivities")}
        </Typography>
        <Typography component="p" style={{ color: "#bebebe", fontSize: "16px", lineHeight: "2.5" }}>
          {activitiesNo} {t("activity.activities")}
        </Typography>
      </Grid>  

      <Grid container item sm={12} md={6} justifyContent={'end'} gap={'10px 30px'}>
        <Grid item width={'330px'}>
            <TextFieldInput
                onChange={(e) => getKeywords(e?.target?.value)}
                type="search"
                placeholder={t("joboffer.searchActivities")}
                StartAdornment={<SearchIcon />}
                className="activity-search"
                sx={{padding: '0px!important'}}
            />
        </Grid>

        <Grid item margin={'0px 10px'} maxWidth={'270px'} display={'flex'} alignItems={'center'} justifyContent={'end'}>
          {((userType === "descendant_user" && salesmenPrivilages?.can_create) || userType !== "descendant_user") && (
            <PrimaryButton              
              startIcon={<AddIcon />}
              text={t("activity.addNewActivity")}
              classBtn="primary r-25px"
              style={{ padding: "10px 20px", height: "48px", textTransform: "none", width: "inherit" }}
              click={()=>{
                setModalType('add')
                setOpenAssignActivityModal(true);
              }}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
    <Divider style={{ width: "100%", margin: "20px 0" }} />
    </>
  )
}

export default withTranslation()(AssignedActivitiesHeader)