import React, { useState } from "react";
import { Grid, Typography, Divider, Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput";
import BasicDatePicker from "../../../Atoms/Input/DatePicker/DatePicker";
import SearchableSelect from "../../../Atoms/Select/SearchableSelect";
import CustomSeparator from "../../../Atoms/BreadCrumb";
import "./SalesmenReservationsHeader.css";
import { Link, useSearchParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import moment from "moment";
import BranchesFilter from "../../../Organisms/Statistics/BranchesFilter.jsx";

const SalesmenReservationsHeader = ({
  t,
  updateSalesmenReservationsList,
  branchesList,
  salesmenList,
  statusList,
  getBranches,
  getSalesmenListing,
  branchesCount,
  salesmenCount,
  filterObj,
  setFilterObj,
  profileData,
}) => {
  let [searchParams, setSearchParams] = useSearchParams();
  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [Status, setStatus] = useState({});
  const [branch, setBranch] = useState({});
  const [salesmen, setSalesmen] = useState({});

  const updateStartDate = (start_date) => {
    setStartDate(start_date);
    updateSalesmenReservationsList(
      "from_date",
      start_date !== null && start_date !== "Invalid Date"
        ? moment(start_date).format("YYYY-MM-DD")
        : null
    );
  };
  const updateEndDate = (end_date) => {
    setEndDate(end_date);
    updateSalesmenReservationsList(
      "to_date",
      end_date !== null && end_date !== "Invalid Date"
        ? moment(end_date).format("YYYY-MM-DD")
        : null
    );
  };
  const updateStatus = (status_val) => {
    setStatus({ label: status_val?.label, value: status_val?.id });
    updateSalesmenReservationsList("status", status_val?.value);
  };

  const updateBranch = (branch_val) => {
    setBranch({ label: branch_val?.label, value: branch_val?.id });
    updateSalesmenReservationsList("branch_id", branch_val?.value);
  };

  const updateSalesmen = (salesmen_val) => {
    setSalesmen({ label: salesmen_val?.label, value: salesmen_val?.id });
    updateSalesmenReservationsList("salesman_id", salesmen_val?.value);
  };

  return (
    <Grid container className="salesmen-reservations-header" mb={4}>
      <CustomSeparator
        breadcrumbs={[
          <Link
            style={{ color: "#bebebe", textDecoration: "none" }}
            underline="hover"
            key="1"
            color="inherit"
            to={`/salesmen?page=${pageNumber}`}
          >
            {t("salesmen.salesmen")}
          </Link>,
          <Typography
            key="2"
            style={{ color: "#bebebe", textDecoration: "none" }}
          >
            {t("reservations.activitiesReservations")}
          </Typography>,
        ]}
      />
      <Stack width="100%" justifyContent="space-between" flexDirection="column">
        <Stack
          flexDirection="row"
          width="100%"
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography
            component="h1"
            style={{ fontWeight: "bold", fontSize: "30px" }}
          >
            {t("reservations.activitiesReservations")}
          </Typography>
          <TextFieldInput
            type="search"
            placeholder={t("reservations.searchReservation")}
            StartAdornment={<SearchIcon />}
            className="salesmen-reservations-search"
            style={{ width: "424px" }}
            onChange={(e) =>
              updateSalesmenReservationsList(
                "reservation_number",
                e.target.value
              )
            }
          />
        </Stack>

        <Divider style={{ width: "100%", margin: "20px 0" }} />

        <Stack
          className="salesmen-reservations-filters"
          flexDirection="row"
          gap={2.5}
          width={"100%"}
          alignItems={"center"}
          justifyContent={"flex-end"}
          flexWrap={"wrap"}
        >
          <Grid width={"165px"} className="border-r-10px">
            <BasicDatePicker
              value={startDate}
              setValue={updateStartDate}
              placeholder={t("reservations.from")}
              className={"basic-date-picker"}
            />
          </Grid>
          <Grid width={"165px"} className="border-r-10px">
            <BasicDatePicker
              value={endDate}
              setValue={updateEndDate}
              placeholder={t("reservations.to")}
              className={"basic-date-picker"}
            />
          </Grid>
          <Grid width={"202px"} className={`border-r-10px ${profileData?.branch?.name ? 'searchable-select-branches-grid' : ''}`}>
            <BranchesFilter
              setFilterObj={setFilterObj}
              filterObj={filterObj}
              profileData={profileData}
            />
          </Grid>
          <Grid
            width={"202px"}
            className="border-r-10px"
            style={{ position: "relative", zIndex: 100, background: "white" ,marginInline:'30px'}}
          >
            <SearchableSelect
              name="salesmen"
              handelMenuOpen={salesmenList?.length == 0 && getSalesmenListing}
              options={[{ name: "All", id: 0 }, ...salesmenList]}
              getList={getSalesmenListing}
              count={salesmenCount}
              label={t("salesmen.salesmenName")}
              className="searchable-select border-r-10px"
              onChange={(val) => updateSalesmen(val)}
              value={{ label: salesmen?.label, value: salesmen?.value }}
              onClear={() => {
                setSalesmen({});
                updateSalesmenReservationsList("salesman_id", null);
                getSalesmenListing({ salesman_id: null });
              }}
            />
          </Grid>
          <Grid width={"202px"} className="border-r-10px">
            <SearchableSelect
              name="status"
              options={[{ name: "All", id: 0 }, ...statusList]}
              label={t("reservations.status")}
              className="searchable-select border-r-10px"
              onChange={(val) => updateStatus(val)}
              value={{ label: Status?.label, value: Status?.value }}
              onClear={() => {
                setStatus({});
                updateSalesmenReservationsList("status", null);
              }}
            />
          </Grid>
        </Stack>
      </Stack>
    </Grid>
  );
};

export default withTranslation()(SalesmenReservationsHeader);
