import { Button, Grid, Menu, MenuItem } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { deleteOffer } from "../../../../Contexts/APIs/Offers/Offers";
import "./style.css";
import CustomPagination from "../../../Atoms/Pagination/Pagination";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteOfferModal from "../Models/DeleteOffer";
import { withTranslation } from "react-i18next";
import dayjs from "dayjs";

const ListOffers = ({ t, count, offers, handleChange, offersPrivilages }) => {
  let [searchParams, setSearchParams] = useSearchParams();

  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  const [loadingStatus, setLoadingStatus] = React.useState(false);
  const [openDeleteModel, setOpenDeleteModel] = React.useState(false);
  const userType = localStorage.getItem("userType");

  const [selectedData, setSelectedData] = useState(false);
  const [offerData, setOfferData] = useState([]);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const handlePageChange = (event, value) => {
    setPage(value);
    handleChange("page_number", value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handelDeleteOffer = async () => {
    setLoadingStatus(true);
    const { res, err } = await deleteOffer(selectedData?.offer?.id);
    if (res) {
      setOfferData((prevData) =>
        prevData.filter((val) => val?.id != selectedData?.offer?.id)
      );
      handleClose();
    }
    setLoadingStatus(false);
  };

  const statusColors = {
    active: "#3ed181",
    ended: "#e10007",
  };

  useEffect(() => {
    if (offers) setOfferData(offers);
  }, [offers]);

  return (
    <Grid container>
      <Grid container className="table-header mb-20px">
        <Grid md={0.65} textAlign="center">
          {t("offer.ID")}.
        </Grid>
        <Grid md={1.2}>{t("offer.activityName")}</Grid>
        <Grid sm={1.5}>{t("offer.branch")}</Grid>
        <Grid sm={1}>{t("offer.offerType")}</Grid>
        <Grid sm={1}>{t("offer.percentage")}</Grid>
        <Grid sm={1}>{t("offer.priceBefore")}</Grid>
        <Grid sm={1}>{t("offer.priceAfter")}</Grid>
        <Grid sm={1.2}>{t("offer.startDate")}</Grid>
        <Grid sm={1.5}>{t("offer.endDate")}</Grid>
        <Grid sm={1}>{t("offer.statues")}</Grid>
        <Grid sm={0.85}>{t("offer.action")}</Grid>
      </Grid>
      {offers?.length === 0 && (
        <Grid container display="block" textAlign="center">
          {t("offer.thereAreNoRecords")}
        </Grid>
      )}

      <Grid container className="table-items-container">
        {offerData?.map((offer, index) => {
          return (
            <Grid
              container
              className="table-item mb-20px"
              key={index}
              width="100%"
              onClick={() => setSelectedData({ offer: offer, index: index })}
            >
              <Grid
                md={0.65}
                sx={{ textAlign: "center", color: "#ccc", fontWeight: "bold" }}
              >
                {offer?.id}
              </Grid>
              <Grid md={1.2} sx={{ fontWeight: "bold" }}>
                {offer?.dive_activity?.name}
              </Grid>
              <Grid sm={1.5}>{offer?.dive_activity?.branch?.name}</Grid>
              <Grid sm={1}>{offer?.offer_type?.split("_")[0]}</Grid>
              <Grid sm={1}>{offer?.discount_percentage}%</Grid>
              <Grid sm={1}>
                {offer?.price_before}{" "}
                {offer?.dive_activity?.currency?.lookup_key}
              </Grid>
              <Grid sm={1}>
                {offer?.price_after}{" "}
                {offer?.dive_activity?.currency?.lookup_key}
              </Grid>
              <Grid sm={1.2}>
                {dayjs(new Date(offer?.start_date)).format("MMM DD, YYYY")}
              </Grid>
              <Grid sm={1.5}>
                {dayjs(new Date(offer?.end_date)).format("MMM DD, YYYY")}
              </Grid>
              <Grid
                sm={1}
                sx={{
                  color:
                    statusColors[
                      offer?.status === "expired" ? "ended" : offer?.status
                    ],
                }}
              >
                {offer?.status === "expired" ? "ended" : offer?.status}
              </Grid>
              {userType !== "descendant_user" &&
                offer?.status !== "expired" && (
                  <Grid sm={0.85}>
                    <Button
                      id={`demo-positioned-button${index}`}
                      aria-controls={
                        open ? `demo-positioned-menu${index}` : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={
                        open && selectedData.offer.id === offer.id
                          ? "true"
                          : undefined
                      }
                      onClick={handleClick}
                    >
                      <MoreHorizIcon style={{ color: "black" }} />
                    </Button>
                    {open && selectedData.offer.id === offer.id && (
                      <Menu
                        id={`demo-positioned-menu${index}`}
                        aria-labelledby={`demo-positioned-button${index}`}
                        className="offers-demo-positioned-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <MenuItem
                          key={index}
                          onClick={() => {
                            navigate(
                              `/offers/edit-offer/${offer?.id}?page=${pageNumber}`
                            );
                            handleClose();
                          }}
                        >
                          {t("offer.edit")}
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setSelectedData({ offer: offer, index: index });
                            setOpenDeleteModel(true);
                          }}
                        >
                          {t("offer.delete")}
                        </MenuItem>
                      </Menu>
                    )}
                  </Grid>
                )}
              {((userType === "descendant_user" &&
                offersPrivilages?.can_update &&
                offer?.status !== "expired") ||
                (userType === "descendant_user" &&
                  offersPrivilages?.can_delete &&
                  offer?.status !== "expired")) && (
                <Grid sm={0.85}>
                  <Button
                    id={`demo-positioned-button${index}`}
                    aria-controls={
                      open ? `demo-positioned-menu${index}` : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={
                      open && selectedData.offer.id === offer.id
                        ? "true"
                        : undefined
                    }
                    onClick={handleClick}
                  >
                    <MoreHorizIcon style={{ color: "black" }} />
                  </Button>
                  {open && selectedData.offer.id === offer.id && (
                    <Menu
                      id={`demo-positioned-menu${index}`}
                      aria-labelledby={`demo-positioned-button${index}`}
                      className="offers-demo-positioned-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      {offersPrivilages?.can_update && (
                        <MenuItem
                          key={index}
                          onClick={() => {
                            navigate(
                              `/offers/edit-offer/${offer?.id}?page=${pageNumber}`
                            );
                            handleClose();
                          }}
                        >
                          {t("offer.edit")}
                        </MenuItem>
                      )}
                      {offersPrivilages?.can_delete && (
                        <MenuItem
                          onClick={() => {
                            setSelectedData({ offer: offer, index: index });
                            setOpenDeleteModel(true);
                          }}
                        >
                          {t("offer.delete")}
                        </MenuItem>
                      )}
                    </Menu>
                  )}
                </Grid>
              )}
            </Grid>
          );
        })}
      </Grid>

      {openDeleteModel && (
        <DeleteOfferModal
          open={openDeleteModel}
          setOpen={setOpenDeleteModel}
          handelDeleteRequest={() => {
            handelDeleteOffer(selectedData?.offer?.id);
            setOpenDeleteModel(false);
          }}
        />
      )}

      {count > 10 && (
        <Grid container justifyContent="center" mb={7.5} mt={5}>
          <CustomPagination
            count={Math.ceil(count / 10)}
            size="large"
            shape="rounded"
            page={page}
            handleChange={handlePageChange}
            className="pagination"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default withTranslation()(ListOffers);
