import React from 'react'
import CustomerSupportTemplate from '../../../../Templates/Main/Help/CustomerSupport/CustomerSupport.template'

export const userDataCustomer = React.createContext();
function CustomerSupportPage({profileData}) {
  return (
    <userDataCustomer.Provider value={ profileData?.email }>
      <CustomerSupportTemplate />
    </userDataCustomer.Provider>
  )
}

export default CustomerSupportPage