import { makeFilterString } from "../Helper/Helper";
import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";

export async function getDiveCenterDetails(dive_centers_id, filter_obj) {
    return await axiosFn("get", URL.dive_centers + "/" + dive_centers_id + makeFilterString(filter_obj))
        .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}

export async function editDiveCenterActive(dive_centers_id, data) {
    return await axiosFn("put", URL.dive_centers + "/" + dive_centers_id, data)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}
