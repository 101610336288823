import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { changePassword } from "../../../Contexts/APIs/Auth/Changepassword";
import ResetPasswordTemplate from "../../../Templates/Main/Account/ResetPassword.Template";

const ResetPasswordPage = ({ t }) => {
  const navigate = useNavigate();
  const ChangePassword = async (type, data, user_id) => {
    const { res, err } = await changePassword(type, data, user_id);
    if (res) {
      navigate("/profile");
    }
  };
  return <ResetPasswordTemplate t={t} ChangePassword={ChangePassword} />;
};

export default ResetPasswordPage;
