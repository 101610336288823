import {
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import CustomPagination from "../../Atoms/Pagination/Pagination.js";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { editRequestedActivity } from "../../../Contexts/APIs/RequestedActivities/Activities.js";
import ConfirmRequstedReservationModel from "./Models/ConfirmReservation.jsx";
import RejectRequstedReservationsModel from "./Models/RejectReservations.jsx";

import { useEffect } from "react";
import { icons } from "../../../Assets/AssetHelper.js";
const ListRequestedActivity = (props) => {
  const {
    t,
    activitesList,
    setActivitiesList,
    updatePagination,
    count,
    page,
    setPage,
    getActivities,
    filterObj,
    setOpenLoader,
    activitiesPrivilages
  } = props;
  let [searchParams, setSearchParams] = useSearchParams();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openReject, setOpenReject] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [SelectedActivity, setSelectedActivity] = useState(false);

  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  const Navigate = useNavigate();
  const open = Boolean(anchorEl);
  const [activtiesStatus, setActivtiesStatus] = useState({});
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setPage(searchParams.get("page"));
    updatePagination(searchParams.get("page"));
  }, [searchParams.get("page")]);

  const handelUpdate = async (id, state, setOpenModel) => {
    setOpenLoader(true);
    const { res, err } = await editRequestedActivity(
      SelectedActivity?.activity?.id,
      {
        dive_expert_activity_request: {
          status: state, // accepted || rejected
        },
      }
    );
    handleClose();
    if (res) {
      state === 'accepted' 
      ? setActivitiesList( prevList => (prevList.filter( item => item.id !== id )) )
      : setActivtiesStatus(prevStatus => ({...prevStatus, [`${SelectedActivity?.activity?.id}`]:{
        id: SelectedActivity?.activity?.id, status: res?.data?.activity_request?.status
      } }));
      setOpenModel(false);
    }
    setOpenLoader(false);
  };

  const states = {
    pending: "#F6B119",
    rejected: "#E10007",
    dive_course: 1,
  };
  return (
    <Box width={'100%'}>
      <Stack flexDirection="row" width="100%" className="table-header mb-20px">
        <Grid sm={1} textAlign="center">
          {`${t("requestedActivites.ID")}.`}
        </Grid>
        <Grid sm={1.3}>{t("requestedActivites.name")}</Grid>
        <Grid sm={1.3}>{t("requestedActivites.diveExpertName")}</Grid>
        <Grid sm={2}>{t("requestedActivites.type")}</Grid>
        <Grid sm={2}>{t("requestedActivites.branch")}</Grid>
        <Grid sm={2}>{t("requestedActivites.activityPrice")}</Grid>
        <Grid sm={2}>{t("requestedActivites.privateDiveExpertFees")}</Grid>
        <Grid sm={2}>{t("requestedActivites.totalPrice")}</Grid>
        <Grid sm={2}>{t("requestedActivites.status")}</Grid>
        <Grid sm={2} style={{ padding: "0 20px" }}>
          {t("requestedActivites.action")}
        </Grid>
      </Stack>

      {activitesList?.length === 0 && (
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "200px",
          }}
        >
          <img
            src={icons.NoActivities}
            alt="no-branches"
            style={{ display: "block", width: "125.8px", height: "124.9px" }}
          />
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: "bold",
              color: "#333132",
              mt: "25px",
            }}
          >
            {t("activity.noActivites")}
          </Typography>
        </Grid>
      )}

      <Grid container className="table-items-container">
        {activitesList?.map((activityItem, index) => (
          activtiesStatus[`${activityItem?.id}`]?.status != 'accepted' ?
          <Stack
            flexDirection="row"
            width="100%"
            className="activity-item mb-20px"
            key={index}
            onClick={() =>
              setSelectedActivity({ activity: activityItem, index: index })
            }
          >
            <Grid md={1} textAlign="center">
              {activityItem?.id}
            </Grid>
            <Grid
              md={1.3}
              sx={{
                cursor: "pointer",
                fontWeight: "700",
              }}
              onClick={() =>
                Navigate(
                  `/requested-activites/details/${activityItem?.id}?page=${pageNumber} `
                )
              }
            >
              {activityItem?.dive_activity?.name || "-"}
            </Grid>
            <Grid
              sm={1.3}
              sx={{
                textDecoration: "underline",
                cursor: "pointer",
                fontWeight: "700",
              }}
              onClick={() =>
                Navigate(
                  `/requested-activites/freelancers/${activityItem?.dive_expert?.id}?page=${pageNumber}`
                )
              }
            >
              {`${activityItem?.dive_expert?.first_name} ${activityItem?.dive_expert?.last_name}` ||
                "-"}
            </Grid>
            <Grid sm={2}>
              {activityItem?.dive_activity?.activity_type?.replace("_", " ")}
            </Grid>

            <Grid sm={2}>{activityItem?.dive_activity?.branch?.name}</Grid>
            <Grid sm={2}>{activityItem?.dive_activity?.price}</Grid>

            <Grid sm={2}>{activityItem?.dive_expert?.fees}</Grid>

            <Grid sm={2}>
              {activityItem?.dive_activity?.price +
                activityItem?.dive_expert?.fees}
            </Grid>

            <Grid
              sm={2}
              sx={{
                color: states[
                  activtiesStatus[`${activityItem?.id}`]?.id == activityItem?.id
                  ? activtiesStatus[`${activityItem?.id}`]?.status
                  : activityItem?.status
                ],

                fontWeight: "700 !important",
              }}
            >
              {
                activtiesStatus[`${activityItem?.id}`]?.id == activityItem?.id
                  ? activtiesStatus[`${activityItem?.id}`]?.status
                  : activityItem?.status
              }
            </Grid>

            {activityItem?.status != "rejected" ? (
              <Grid sm={2}>
                <div>
                  <Button
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    sx={{
                      background: "#ECECEC",
                      color: "black",
                    }}
                  >
                    <MoreHorizIcon />
                  </Button>
                  {activitiesPrivilages?.can_update && (                  
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={
                        () => setOpenConfirm(true)
                        //   handelUpdate(activityItem?.id, "accepted")
                      }
                    >
                      Approve
                    </MenuItem>
                    <MenuItem
                      onClick={
                        () => setOpenReject(true)

                        //  handelUpdate(activityItem?.id, "rejected")
                      }
                    >
                      Reject
                    </MenuItem>
                  </Menu>
                  )}
                </div>
              </Grid>
            ) : (
              <Grid sm={2}></Grid>
            )}
          </Stack> 
          : null
        ))}
        {count > 10 && (
          <Grid container justifyContent="center" mb={7.5} mt={5}>
            <CustomPagination
              count={Math.ceil(count / 10)}
              size="large"
              shape="rounded"
              page={page}
              className="pagination"
            />
          </Grid>
        )}
        {openConfirm && (
          <ConfirmRequstedReservationModel
            open={openConfirm}
            setOpen={setOpenConfirm}
            handelConfirm={() =>
              handelUpdate(
                SelectedActivity?.activity?.id,
                "accepted",
                setOpenConfirm
              )
            }
          />
        )}
        {openReject && (
          <RejectRequstedReservationsModel
            open={openReject}
            setOpen={setOpenReject}
            handelReject={() =>
              handelUpdate(
                SelectedActivity?.activity?.id,
                "rejected",
                setOpenReject
              )
            }
          />
        )}
      </Grid>
    </Box>
  );
};

export default withTranslation()(ListRequestedActivity);
