
import { axiosFn, handleError } from "../index"
import { makeFilterString } from "../Helper/Helper"
import * as URL from "../URLs"

export async function PointsAction(filter) {
    return await axiosFn("get", URL.lookups + URL.POINTS + makeFilterString(filter) )
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}

export async function PointsConfig(filter) {
    return await axiosFn("get", URL.lookups + URL.PointsConfig)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}