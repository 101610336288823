import { Box, Button, Modal, Stack, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { reservationDetails } from '../../../../Contexts/APIs/Reservations/Reservations';
import Loader from '../../../Atoms/Loader/Loader';
import { withTranslation } from 'react-i18next';
import ReactPhoneInput2 from '../../../Atoms/Input/PhoneInput/ReactPhoneInput2';
import './ReservationDetails.css'
const ReservationDetailsModel = ({
    open, setOpen, orderId, customerData, participants, t, isSalesMenReservation
}) => {
    const [details, setDetails] = useState({})
    const [openLoader, setOpenLoader] = useState(false)


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        // width: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '25px',
        p: 4,
        width: isSalesMenReservation ? 557 : 1016,
        height: 750,
        maxWidth: '80vw',
        maxHeight: '70vh',
        overflow: 'auto'
    };

    const getReservationDetails = async () => {
        setOpenLoader(true)
        const { res, err } = await reservationDetails(orderId)
        if (res) {
            setDetails(res.data.order)
        }
        setOpenLoader(false)
    }


    const handleClose = () => setOpen(false);
    useEffect(() => {
        if (orderId) getReservationDetails()
    }, [orderId])
    return (
        <>
            <Loader open={openLoader} />

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"

                aria-describedby="modal-modal-description"
            >
                <Stack sx={style} justifyContent='center' gap={2} >
                    <Stack flexDirection='row' alignItems='center' gap={1}>
                        {
                          isSalesMenReservation !== true && <img src={customerData.image} style={{ width: '64px', height: '64px', borderRadius: '10px' }} />
                        }
                        <Typography fontSize='24px' fontWeight='bold'>{customerData?.name}</Typography>
                    </Stack>
                    {
                    isSalesMenReservation === true ? <Stack className="details-panel" gap={2} >
                        <Typography component="h1" width='fit-content' fontSize='20px'>{t("salesmen.mainCustomerInfo")}</Typography>
                        <Stack gap={{xs:2, md:0}} justifyContent={'space-between'} flexDirection='row' flexWrap='wrap'>
                            <Stack gap={2} width={"100%"}>
                                <Stack flexDirection='row' gap={1}>
                                    <Stack width='45%'>
                                        <Typography color='#aaaaaa' fontSize='13px'>{t("reservations.email")}</Typography>
                                        <Typography fontWeight='bold' fontSize='18px'>{customerData?.email}</Typography>
                                    </Stack>
                                    <Stack width='45%'>
                                        <Typography color='#aaaaaa' fontSize='13px'>{t("salesmen.hotelName")}</Typography>
                                        <Typography fontWeight='bold' fontSize='18px'>{customerData?.hotel_name}</Typography>
                                    </Stack>
                                </Stack>
                                <Stack flexDirection='row' gap={1}>
                                    <Stack width='45%'>
                                        <Typography color='#aaaaaa' fontSize='13px'>{t("reservations.phoneNumber")}</Typography>
                                        <Typography fontWeight='bold' fontSize='18px'>
                                            <ReactPhoneInput2
                                                phoneValue={customerData?.phone_number}
                                                codeValue={customerData?.country_code}
                                                disableDropdown={true}
                                                formatType={'read'}
                                                customClass={'customer-phone'}
                                            />
                                        </Typography>
                                    </Stack>
                                    <Stack width='45%'>
                                        <Typography color='#aaaaaa' fontSize='13px'>{t("salesmen.roomNumber")}</Typography>
                                        <Typography fontWeight='bold' fontSize='18px'>{customerData?.room_number}</Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                    : <Stack className="details-panel" gap={2} >
                        <Typography component="h1" width='fit-content' fontSize='20px'>{t("reservations.meetingPoint")}</Typography>
                            {
                            (details?.order_item?.pickup_location?.area && details?.order_item?.pickup_location?.city) ?
                            <Typography>{details?.order_item?.pickup_location?.area?.name},{details?.order_item?.pickup_location?.city?.name}</Typography>
                            :
                            <Typography>{details?.order_item?.pickup_location?.full_address}</Typography>

                            }
                        <Button
                            onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${details?.order_item?.pickup_location?.lat},${details?.order_item?.pickup_location?.long}`, '_blank')}
                            style={{ width: '150px', color: '#faa100', background: 'white', border: '1px solid #faa100', borderRadius: '18px', alignSelf: 'flex-end' }} >Get direction</Button>
                    </Stack>
                    }
                    <Box sx={{ height: '30vh', overflowY: 'scroll' }}>
                        {
                            (isSalesMenReservation === true && participants.length > 0) ?
                            <Stack className="details-panel" gap={2} >
                                <Typography component="h1" width='fit-content' fontSize='20px'>{t("reservations.participantsInfo")}</Typography>
                                <Stack gap={{xs:2, md:0}} justifyContent={'space-between'} flexDirection='row' flexWrap='wrap'>
                                    {participants?.map((val, index) => {
                                        return <Stack gap={2} width={{ xs:"100%", md:"48%" }}
                                        key={index} >
                                            <Typography color='#faa100' fontWeight='bold' fontSize='18px'>{t("reservations.participants")} {index + 1}</Typography>

                                            <Stack>
                                                <Typography color='#aaaaaa' fontSize='13px'>{t("reservations.name")}</Typography>
                                                <Typography fontWeight='bold' fontSize='18px'>{val?.name}</Typography>
                                            </Stack>
                                            <Stack>
                                                <Typography color='#aaaaaa' fontSize='13px'>{t("salesmen.hotelName")}</Typography>
                                                <Typography fontWeight='bold' fontSize='18px'>{val?.hotel_name}</Typography>
                                            </Stack>
                                            <Stack>
                                                <Typography color='#aaaaaa' fontSize='13px'>{t("salesmen.roomNumber")}</Typography>
                                                <Typography fontWeight='bold' fontSize='18px'>{val?.room_number}</Typography>
                                            </Stack>
                                        </Stack>
                                    })}
                                </Stack>
                            </Stack>
                            : (isSalesMenReservation !== true && details?.order_item?.reservation_participants?.length > 0) ?
                            <Stack className="details-panel" gap={2} >
                                <Typography component="h1" width='fit-content' fontSize='20px'>{t("reservations.participantsInfo")}</Typography>
                                <Stack gap={{xs:2, md:0}} justifyContent={'space-between'} flexDirection='row' flexWrap='wrap'>
                                    {details?.order_item?.reservation_participants?.map((val, index) => {
                                        return <Stack gap={2} width={{ xs:"100%", md:"48%" }}
                                        key={index} >
                                            <Typography color='#faa100' fontWeight='bold' fontSize='18px'>{t("reservations.participants")} {index + 1}</Typography>

                                            <Stack>
                                                <Typography color='#aaaaaa' fontSize='13px'>{t("reservations.name")}</Typography>
                                                <Typography fontWeight='bold' fontSize='18px'>{val?.name}</Typography>
                                            </Stack>
                                            <Stack>
                                                <Typography color='#aaaaaa' fontSize='13px'>{t("reservations.email")}</Typography>
                                                <Typography fontWeight='bold' fontSize='18px'>{val?.email}</Typography>
                                            </Stack>
                                            <Stack>
                                                <Typography color='#aaaaaa' fontSize='13px'>{t("reservations.phoneNumber")}</Typography>
                                                <Typography fontWeight='bold' fontSize='18px'>
                                                    <ReactPhoneInput2
                                                        phoneValue={val?.phone_number}
                                                        codeValue={val?.country_code}
                                                        disableDropdown={true}
                                                        formatType={'read'}
                                                        customClass={'participant-phone'}
                                                    />
                                                </Typography>
                                            </Stack>
                                            <Stack flexDirection='row' gap={1}>
                                                <Stack width='45%'>
                                                <Typography color='#aaaaaa' fontSize='13px'>{t("reservations.numberDives")}</Typography>
                                                <Typography fontWeight='bold' fontSize='18px'>{val?.no_of_dives ? val?.no_of_dives : 'N/A'}</Typography>                                     
                                                </Stack>
                                            {/* {val?.last_dive_date && <Stack width='45%'> */}
                                            <Stack width='45%'>
                                                <Typography color='#aaaaaa' fontSize='13px'>{t("reservations.lastDiveDate")}</Typography>
                                                <Typography fontWeight='bold' fontSize='18px'>{val?.last_dive_date ? moment(val?.last_dive_date).format('MMM DD,YYYY') : 'N/A'}</Typography>                                    
                                                </Stack>
                                            </Stack>
                                            
                                            {/* { val?.certificates_list &&    <Stack> */}
                                            <Stack>
                                                <Typography color='#aaaaaa' fontSize='13px'>{t("reservations.certificates")}</Typography>
                                                <Typography fontWeight='bold' fontSize='18px'>{val?.certificates_list ? val?.certificates_list : 'N/A'}</Typography>
                                            </Stack>                                                                                        
                                        </Stack>
                                    })}
                                </Stack>
                            </Stack>
                            : null
                        }
                    </Box>
                    <Stack flexDirection='row' alignItems='center' mt={4} alignSelf='center'>
                        <Button style={{ width: '150px', color: 'white', background: '#faa100', borderRadius: '18px' }} onClick={() => setOpen(false)}>{t("reservations.done")}</Button>
                    </Stack>
                </Stack>

            </Modal>
        </>

    )
}

export default withTranslation()(ReservationDetailsModel)