import ReservationsDetailsTemplate from "../../../Templates/Main/Reservations/ReservationsDetails.template.jsx";
import { getGroupReservationsList, getGroupReservationsListData, getSystemConfigDetails, updatePaymentStatus } from "../../../Contexts/APIs/Reservations/Reservations.js";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Loader from "../../../Components/Atoms/Loader/Loader.js";
import { toast } from "react-toastify";
import "../../../Components/Organisms/Reservations/Reservations.css";

const ReservationsDetailsPage = (props) => {
    const { t, ordersPrivilages } = props;
    const { id } = useParams();
    const [search, setSearch] = useSearchParams();

    const [count, setCount] = useState();
    const [reservationsData, setReservationsData] = useState({});
    const [openLoader, setOpenLoader] = useState(false);
    const [systemConfig, setSystemConfig] = useState({});

    const getGroupReservationsList = async (filter_obj) => {
        setOpenLoader(true);
        const { res, err } = await getGroupReservationsListData(id, filter_obj);
        if (res) {
            setCount(res?.extra?.total_count);
            setReservationsData(res?.data);
        }
        if (err) { }
        setOpenLoader(false);
    };
    const updatePayment = async (order_id, type) => {
        setOpenLoader(true);
        const data = {
            payment_transaction: {
                status: type
            }
        }
        const { res, err } = await updatePaymentStatus(data)
        if (res) {
            getGroupReservationsList({
                'page_size': 10,
                'page_number': 1,

            })
        }
        setOpenLoader(false);
    };

    const getSystemConfig = async () => {
        const { res, err } = await getSystemConfigDetails('auto_rejection_duration');
        if (res) {
          setSystemConfig(res?.data?.system_configuration);
        }
    }; 

    useEffect(()=>{
        getSystemConfig();
    },[])
    
    return (
        <>
            <Loader open={openLoader} />
            <ReservationsDetailsTemplate
                getGroupReservationsList={getGroupReservationsList}
                count={count}
                reservationsData={reservationsData}
                setReservationsData={setReservationsData}
                reservationsDuration={systemConfig?.value}
                setOpenLoader={setOpenLoader}
                updatePayment={updatePayment}
                ordersPrivilages={ordersPrivilages}
            />
        </>
    )
}

export default ReservationsDetailsPage