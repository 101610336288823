import Cookies from "js-cookie";
import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";

export async function login(user_type, data) {
  return await axiosFn("post", URL.auth + user_type + URL.login, data)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}


export async function checkToken(user_type, data) {
  return await axiosFn("patch", '/auth/verify_token_authenticity')
    .then((res) => {
      return {
        res: {message: "success"},
        err: null,
      };
    })
    .catch(() => {
      localStorage.clear()
      Cookies.remove("firebase_auth_token")
      window.location.reload()
    });
}
