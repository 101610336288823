import React from 'react'
import FaqTemplate from '../../../../Templates/Main/Help/Faq/Faq.template'
import { withTranslation } from 'react-i18next'

function FaqPage({t}) {
  return (
    <FaqTemplate head={t("help.faq")} />
  )
}

export default withTranslation()(FaqPage)