import { Avatar, Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import { icons } from "../../../Assets/AssetHelper";
import { useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import { LogoutAPI } from "../../../Contexts/APIs/Auth/Logout";
import { useState } from "react";
import DeleteModal from "../../../Components/Molecules/General/Models/DeleteModal";
import "./AccountData.css";
import { handleError } from "../../../Contexts/APIs";
import axios from "axios";
import ReactPhoneInput2 from "../../../Components/Atoms/Input/PhoneInput/ReactPhoneInput2";
import Cookies from "js-cookie";
import { Firebase } from "../../../Contexts/Firebase/Firebase";

const AccountDataTemplate = ({ t, diveCenterDetails, setOpen }) => {
  const lang = localStorage.getItem("lang");
  const userType = localStorage.getItem("userType");
  const navigate = useNavigate();

  const [openAttention, setOpenAttention] = useState(false);

  const Logout = async () => {
    const { res, err } = await LogoutAPI();
    if (res) {
      localStorage.clear();
      Cookies.remove("firebase_auth_token");
      Firebase.SignoutFirebase();
      window.location.reload();
    }
    localStorage.clear();
    Cookies.remove("firebase_auth_token");
    window.location.reload();
  };

  async function getPdf() {
    return await axios
      .get(diveCenterDetails?.dive_center_contract, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `contract_${diveCenterDetails?.id}.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(handleError);
  }
  return (
    <>
      <Grid className="account-data-container">
        <Typography fontSize="18px" color="#bebebe">
          {t("profile.profile")}
        </Typography>
        <Grid container className="activity-form" flexDirection="column" gap={2}>
          {/* main data */}
          <Stack width="100%" flexDirection="row" alignItems="center" justifyContent="space-between">
            <Stack flexDirection="row" gap={2}>
              <Avatar
                src={diveCenterDetails?.logo || diveCenterDetails?.avatar}
                style={{ width: "82px", height: "82px", borderRadius: "10px" }}
              />
              {userType !== "descendant_user" && (
                <Stack flexDirection="row" alignItems="center" gap={2}>
                  {lang && diveCenterDetails?.localized_data && (
                    <Typography fontSize="30px" fontWeight="bold">
                      {diveCenterDetails?.localized_data[lang]?.name}
                    </Typography>
                  )}
                  <Typography color="#f6b119" fontSize="16px">
                    {diveCenterDetails?.branch_locations?.length} Branches
                  </Typography>
                </Stack>
              )}
            </Stack>

            <Stack flexDirection="row" gap={2}>
              {userType !== "descendant_user" && (
                <>
                  <Box>
                    <Typography fontSize="13px" color="#aaaaaa">
                      {t("profile.primaryLanguage")}
                    </Typography>
                    <Typography>{diveCenterDetails?.primary_language?.name}</Typography>
                  </Box>
                  <PrimaryButton
                    click={() => {
                      setOpen(true);
                    }}
                    btnType="icon"
                    startIcon={<img width="35" src={icons.EditIcon} alt="edit" />}
                  />
                </>
              )}
              <Button
                // onClick={Logout}
                onClick={() => setOpenAttention(true)}
                style={{
                  textTransform: "capitalize",
                  height: "52px",
                  fontSize: "14px",
                  width: "123px",
                  color: "#e10007",
                  border: "1px solid #e10007",
                  borderRadius: "26px",
                }}
              >
                <img src={icons.LogoutIcon} /> <span style={{ marginInline: "3px" }}> {t("profile.logout")}</span>
              </Button>
            </Stack>
          </Stack>
          {userType !== "descendant_user" && (
            <>
              <Divider
                style={{
                  marginBlock: "20px",
                  width: "100%",
                  background: "#d9d9d9",
                }}
              />
              <Stack justifyContent="space-between" flexDirection="row">
                <Box bgcolor="#fbfbfb" borderRadius="20px" p={2} width="55%" height="100%">
                  {lang && diveCenterDetails?.localized_data && (
                    <Typography fontSize="20px" fontWeight="bold">
                      {" "}
                      {t("profile.about")} {diveCenterDetails?.localized_data[lang]?.name}
                    </Typography>
                  )}
                  <Typography fontSize="18px">{diveCenterDetails?.about_us}</Typography>
                </Box>
                <Stack bgcolor="#fbfbfb" borderRadius="20px" p={2} width="35%" gap={2} height="100%">
                  <Stack
                    flexDirection="row"
                    gap={2}
                    alignItems="center"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/reset-password")}
                  >
                    <img src={icons.PasswordIcon} width="40px" height="40px" />

                    <Typography fontSize="18px" fontWeight="bold">
                      {t("profile.resetpassword")}
                    </Typography>
                  </Stack>
                  <Stack flexDirection="row" gap={2} alignItems="center" onClick={getPdf} style={{ cursor: "pointer" }}>
                    <img src={icons.download} width="40px" height="40px" />
                    {/* <a
                      href={diveCenterDetails?.dive_center_contract}
                      style={{ textDecoration: "none", color: "black" }}
                      download
                    > */}
                    <Typography fontSize="18px" fontWeight="bold">
                      {t("profile.downloadAgreementContract")}
                    </Typography>
                    {/* </a> */}
                  </Stack>
                  {/* <Stack flexDirection='row' gap={2} alignItems='center'>
                            <img src={icons.CheckIcon} width='40px' height='40px' />

                            <Typography fontSize='18px' fontWeight='bold'>
                                {t('profile.ownercontract')}
                            </Typography>
                        </Stack> */}
                  <Stack flexDirection="row" gap={2} alignItems="center" style={{ cursor: "pointer" }}
                    onClick={() => navigate("/bank-details")}>
                    <img src={icons.EditIcon} width="40px" height="40px" />
                    <Typography fontSize="18px" fontWeight="bold">
                      {t("profile.bankaccountdetails")}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Stack flexDirection="row" gap={2} width="100%">
                <Stack gap={2} width="37%">
                  <Box bgcolor="#fbfbfb" borderRadius="20px" p={2}>
                    <Typography
                      component="h1"
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        borderBottom: "2px solid var(--primary-color)",
                      }}
                      className="mb-20px"
                    >
                      {t("profile.ownerinfo")}
                    </Typography>

                    <Box my={1}>
                      <Typography color="#aaaaaa" fontSize="13px">
                        {t("profile.ownername")}{" "}
                      </Typography>
                      <Typography fontSize="18px">{diveCenterDetails?.owner_name}</Typography>
                    </Box>
                    <Box my={1}>
                      <Typography color="#aaaaaa" fontSize="13px">
                        {t("profile.owneremailaddress")}
                      </Typography>
                      <Typography fontSize="18px">{diveCenterDetails?.owner_email}</Typography>
                    </Box>
                    <Box my={1}>
                      <Typography color="#aaaaaa" fontSize="13px">
                        {t("profile.ownermobile")}
                      </Typography>
                      {/* <Typography fontSize="18px">
                        {diveCenterDetails?.owner_country_code}
                        {diveCenterDetails?.owner_phone_number}
                      </Typography> */}
                      {/* <PhoneInput
                        inputStyle={{
                          border: "none",
                          width: "100%",
                          padding: "0px",
                          fontSize: "18px",
                          fontWeight: "400",
                          color: "#000",
                          backgroundColor: "#fbfbfb",
                          cursor: "auto",
                        }}
                        value={`${diveCenterDetails?.owner_country_code} ${diveCenterDetails?.owner_phone_number}`}
                        disabled={true}
                        disableDropdown={true}
                        specialLabel=""
                        placeholder=""
                      /> */}
                      <ReactPhoneInput2
                        customClass="account_phone"
                        phoneValue={diveCenterDetails?.owner_phone_number}
                        codeValue={diveCenterDetails?.owner_country_code}
                        disableDropdown={true}
                        formatType={"read"}
                      />
                    </Box>
                  </Box>
                  <Box bgcolor="#fbfbfb" borderRadius="20px" p={2}>
                    <Typography
                      component="h1"
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        borderBottom: "2px solid var(--primary-color)",
                      }}
                      className="mb-20px"
                    >
                      {t("profile.contactpersoninfo")}
                    </Typography>

                    <Box my={1}>
                      <Typography color="#aaaaaa" fontSize="13px">
                        {" "}
                        {t("profile.contactpersonname")}
                      </Typography>
                      <Typography fontSize="18px">{diveCenterDetails?.contact_person_name}</Typography>
                    </Box>
                    <Box my={1}>
                      <Typography color="#aaaaaa" fontSize="13px">
                        {t("profile.contactpersonemailaddress")}
                      </Typography>
                      <Typography fontSize="18px">{diveCenterDetails?.contact_person_email}</Typography>
                    </Box>
                    <Box my={1}>
                      <Typography color="#aaaaaa" fontSize="13px">
                        {t("profile.contactpersonmobile")}
                      </Typography>
                      {/* <Typography fontSize="18px">
                        {diveCenterDetails?.contact_person_country_code}

                        {diveCenterDetails?.contact_person_phone_number}
                      </Typography> */}
                      <ReactPhoneInput2
                        customClass="account_phone"
                        phoneValue={diveCenterDetails?.contact_person_phone_number}
                        codeValue={diveCenterDetails?.contact_person_country_code}
                        disableDropdown={true}
                        formatType={"read"}
                      />
                    </Box>
                  </Box>
                </Stack>

                <Box bgcolor="#fbfbfb" borderRadius="20px" p={2}>
                  <Typography
                    component="h1"
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      borderBottom: "2px solid var(--primary-color)",
                    }}
                    className="mb-20px"
                  >
                    {t("profile.operatorInformation")}
                  </Typography>

                  <Box my={1}>
                    <Typography color="#aaaaaa" fontSize="13px">
                      {t("profile.diveoperationname")}
                    </Typography>
                    <Typography fontSize="18px">{diveCenterDetails?.name}</Typography>
                  </Box>
                  <Box my={1}>
                    <Typography color="#aaaaaa" fontSize="13px">
                      {t("profile.operatoremailaddress")}
                    </Typography>
                    <Typography fontSize="18px">{diveCenterDetails?.email}</Typography>
                  </Box>
                  <Box my={1}>
                    <Typography color="#aaaaaa" fontSize="13px">
                      {t("profile.operatormobile")}
                    </Typography>
                    <ReactPhoneInput2
                      customClass="account_phone"
                      phoneValue={diveCenterDetails?.phone_number}
                      codeValue={diveCenterDetails?.country_code}
                      disableDropdown={true}
                      formatType={"read"}
                    />
                  </Box>
                  <Box my={1}>
                    <Typography color="#aaaaaa" fontSize="13px">
                      {t("profile.websitelink")}
                    </Typography>
                    <Typography fontSize="18px">{diveCenterDetails?.website}</Typography>
                  </Box>
                  <Box my={1}>
                    <Typography color="#aaaaaa" fontSize="13px">
                      {t("profile.socialmedialink")}
                    </Typography>
                    <Typography fontSize="18px">{diveCenterDetails?.facebook}</Typography>
                  </Box>
                  <Box my={1}>
                    <Typography color="#aaaaaa" fontSize="13px">
                      {t("profile.mainofficeaddress")}
                    </Typography>
                    <Typography fontSize="18px">{diveCenterDetails?.main_office_address}</Typography>
                  </Box>
                </Box>

                <Stack gap={1} p={2}>
                  <Typography
                    component="h1"
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      borderBottom: "2px solid var(--primary-color)",
                    }}
                    className="mb-20px"
                  >
                    {t("profile.freelancepolicy")}
                  </Typography>
                  <Grid container className="mb-10px" direction="row">
                    {diveCenterDetails?.allow_outsider_freelancers ? ( //free lancer from outside
                      <CheckOutlinedIcon className="check-icon" />
                    ) : (
                      <ClearIcon className="wrong-icon" />
                    )}
                    <Typography textTransform="capitalize" width={"calc(100% - 40px)"}>
                      {t("profile.acceptFreelancerOutside")}
                    </Typography>
                  </Grid>
                </Stack>
              </Stack>
            </>
          )}
        </Grid>
        {openAttention && (
          <DeleteModal
            customClass={"account-data-modal"}
            open={openAttention}
            setOpen={setOpenAttention}
            handelDeleteRequest={() => {
              Logout();
              setOpenAttention(false);
            }}
            text={[t("profile.logout"), `${t("profile.logoutFrom")} ${diveCenterDetails?.email}`]}
            buttonText={[t("branch.cancel"), t("profile.logout")]}
          />
        )}
      </Grid>
    </>
  );
};

export default withTranslation()(AccountDataTemplate);
