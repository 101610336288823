import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getBranchDetails } from "../../../Contexts/APIs/Branches/Branches";
import { getReviewsList } from "../../../Contexts/APIs/Reviews/Reviews";
import BranchReviewsTemplate from "../../../Templates/Main/Branches/BranchReviews.template";

const BranchReviewsPage = ({ profileData }) => {
  const [filterObj, setFilterObj] = useState({
    page_size: 10,
    page_number: 1,
  });
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [branchDetails, setBranchDetails] = useState({});
  const [reviewsList, setReviewsList] = useState([]);
  const [reviewsDetails, setReviewsDetails] = useState({});
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);

  const GetReviewsList = async () => {
    const data = {
      review: {
        reviewee_id: id,
        reviewee_type: "Branch", // DiveActivity - DiveCenter,
        reviewer_type:
          searchParams.get("type") == "customer" ? "Customer" : "DiveExpert", //DiveCenter, Customer, DiveExpert
      },
    };
    const { res, err } = await getReviewsList(filterObj, data);
    if (res) {
      setReviewsList(res?.data?.reviews);
      setReviewsDetails(res?.data?.reviews_details);
      setCount(res?.extra?.total_count);
    }
  };

  const GetbranchDetails = async () => {
    const { res, err } = await getBranchDetails(id);
    if (res) {
      setBranchDetails(res?.data.branch);
    }
  };

  useEffect(() => {
    GetbranchDetails();
  }, []);

  useEffect(() => {
    GetReviewsList();
  }, [filterObj]);

  useEffect(() => {
    GetReviewsList();
  }, [searchParams?.get("type")]);

  const handlePageChange = (event, value) => {
    setPage(value);
    setFilterObj({ page_number: value });
    setSearchParams({ type: searchParams?.get("type"), page: value });
  };
  return (
    <BranchReviewsTemplate
      count={count}
      reviewsList={reviewsList}
      branchDetails={branchDetails}
      reviewsDetails={reviewsDetails}
      handlePageChange={handlePageChange}
      page={page}
      setFilterObj={setFilterObj}
    />
  );
};

export default BranchReviewsPage;
