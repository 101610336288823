import { Typography, FormControl, Stack } from "@mui/material";
import "./Form.css";
import { PaymentMethodsAPI } from "../../../../Contexts/APIs/Lookups/PaymentMethods.js";
import { useEffect, useState } from "react";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { withTranslation } from "react-i18next";
const PaymentInfo = (props) => {
    const {
        t,
        type,
        formik
    } = props;
    const [paymentList, setPaymentList] = useState([])


    const getPaymentMethods = async () => {
        const { res, err } = await PaymentMethodsAPI({ activity_type: type })
        if (res) {
            setPaymentList(res.data.payment_methods)
        }
    }

    useEffect(() => {
        if (type) getPaymentMethods()
    }, [type])


    return (
        paymentList?.length > 0 ? <Stack width='100%' className="activity-form">

            <Typography
                component="h1"
                style={{
                    width: 'fit-content',
                    fontSize: "20px",
                    fontWeight: "bold",
                    borderBottom: "2px solid var(--primary-color)"
                }}

            >
                {t('activity.paymentMethod')}
            </Typography>
            <FormControl sx={{ m: 3, ml: 0 }} component="fieldset" variant="standard">
                <FormGroup sx={{flexDirection: 'row-reverse', justifyContent: 'start'}}>
                    {paymentList?.map((val , index) => (
                        val?.lookup_key == "credit_card" ?
                            <FormControlLabel
                                disabled 
                                key={index} 
                                control={<Checkbox defaultChecked />} label={val?.name} 
                                sx={{ textTransform: 'capitalize', mr: '40px' }} 
                                className="payment-label"
                            />
                        : val?.lookup_key == "cash" ?
                            <FormControlLabel
                                key={index} 
                                control={<Checkbox
                                    defaultChecked={formik.values.allow_cash ? formik.values.allow_cash : false}
                                    checked={formik.values.allow_cash ? formik.values.allow_cash : false}
                                    onChange={formik.handleChange}
                                    name="allow_cash"
                                    value={formik.values.allow_cash == true ? 'on' : ''} />}
                                    sx={{ textTransform: 'capitalize' }}
                                    label={"cash"} 
                                    className="payment-label"
                                />
                        : null                            
                    ))}
                </FormGroup >
            </FormControl >
        </Stack > : <></>
    )
}

export default withTranslation()(PaymentInfo)