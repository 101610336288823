import ReservationsTemplate from "../../../Templates/Main/Reservations/Reservations.template.jsx";
import { getActivitiesList } from "../../../Contexts/APIs/Activities/Activities.js";
import { useEffect, useState } from "react";
import Loader from "../../../Components/Atoms/Loader/Loader.js";
import { getBranchesList } from "../../../Contexts/APIs/Branches/Branches.js";
import {
  getGroupReservationsListData,
  getReservationsList,
  getSystemConfigDetails,
  updatePaymentStatus,
} from "../../../Contexts/APIs/Reservations/Reservations.js";
import { toast } from "react-toastify";
import "../../../Components/Organisms/Reservations/Reservations.css";
import { useParams } from "react-router-dom";

const ReservationsPage = (props) => {
  const { t, ordersPrivilages, profileData } = props;
  const [count, setCount] = useState();
  const [openLoader, setOpenLoader] = useState(false);
  const [reservationsActivities, setReservationsActivities] = useState([]);
  const [branchesList, setBranchesList] = useState([]);
  const [branchesCount, setBranchesCount] = useState([]);
  const userId = localStorage.getItem("userId");
  const userType = localStorage.getItem("userType");
  const ancestorId = localStorage.getItem("ancestorId");
  const [systemConfig, setSystemConfig] = useState({});
  const [reservationsData, setReservationsData] = useState({});
  const [filterObj, setFilterObj] = useState({
    page_size: 10,
    page_number: 1,
    sort_by: "id",
    activity_type: "",
    owner_type: "DiveCenter",
    branch_id: profileData?.branch?.name ? profileData?.branch?.id : null
  });

  const getReservationsActivities = async (filterObj) => {
    setOpenLoader(true);
    const { res, err } = await getActivitiesList(filterObj);

    if (res) {
      setCount(res?.extra?.total_count);
      setReservationsActivities(res?.data?.activities);
    }
    if (err) {
    }

    setOpenLoader(false);
  };
  const getBranches = async (filterObj) => {
    const filterData = {
      page_size: "10",
      dive_center_id: userType == "descendant_user" ? ancestorId : userId,
      ...filterObj,
    };
    const { res, err } = await getBranchesList({
      ...filterData,
    });
    if (res) {
      filterData?.page_number == 1
        ? setBranchesList(res?.data?.branches)
        : setBranchesList((s) => [...s, ...res?.data?.branches]);
      setBranchesCount(res?.extra?.total_count);
    }
  };

  const { id } = useParams();

  const getReservations = async (filterObj) => {
    setOpenLoader(true);
    // const { res, error } = await getReservationsList(filterObj);
    const { res, error } =
      id && id !== "undefined"
        ? await getGroupReservationsListData(id, filterObj)
        : await getReservationsList(filterObj);
    if (res) {
      setOpenLoader(false);
      setReservationsData(res?.data);
      setCount(res?.extra?.total_count);
    }
    if (error) {
      setOpenLoader(false);
    }
  };
  const updatePayment = async (order_id, type) => {
    setOpenLoader(true);
    const data = {
      payment_transaction: {
        status: type,
      },
    };
    const { res, err } = await updatePaymentStatus(data);
    if (res) {
      getReservations({ ...filterObj, page_number: 1 });
    }
    setOpenLoader(false);
  };

  const getSystemConfig = async () => {
    const { res, err } = await getSystemConfigDetails(
      "auto_rejection_duration"
    );
    if (res) {
      setSystemConfig(res?.data?.system_configuration);
    }
  };

  useEffect(() => {
    getSystemConfig();
  }, []);

  useEffect(() => {
    getReservations(filterObj);
  }, [filterObj, id]);

  return (
    <>
      <Loader open={openLoader} />
      <ReservationsTemplate
        getBranches={getBranches}
        getReservationsActivities={getReservationsActivities}
        count={count}
        reservationsActivities={reservationsActivities}
        branchesList={branchesList}
        getGroupReservationsList={getReservations}
        reservationsData={reservationsData}
        setReservationsData={setReservationsData}
        reservationsDuration={systemConfig?.value}
        setOpenLoader={setOpenLoader}
        updatePayment={updatePayment}
        ordersPrivilages={ordersPrivilages}
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        ReservationsPageListener={id && id !== "undefined" ? false : true}
        branchesCount={branchesCount}
        profileData={profileData}
      />
    </>
  );
};

export default ReservationsPage;
