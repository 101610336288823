import { Grid, Typography } from "@mui/material";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput.jsx";
import "../../Activities/Forms/Form.css";
import SearchIcon from '@mui/icons-material/Search';
import { useState } from "react";
import { icons } from "../../../../Assets/AssetHelper.js";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import AddParticipant from "./AddParticipant.jsx";
import { withTranslation } from "react-i18next";

const AddReservationForm = (props) => {
    const { searchUsers, usersList, numberOfSeats, groupId, createReservation, t } = props;
    const [user, setUser] = useState("");
    const [showResult, setShowResult] = useState(false);
    const [quantity, setQuantity] = useState([0]);
    const [showParticipationsForm, setShowParticipationsForm] = useState(false);
    const [userList, setUsersList] = useState([]);
    const [firstUser, setFirstUser] = useState({});
    const [userId, setUserId] = useState("");

    const searchForUsers = (e) => {
        if (e.target.value !== "") {
            setShowResult(true);
            setShowParticipationsForm(true);
        } else {
            setShowResult(false);
            setShowParticipationsForm(false);
        }
        setUser(e.target.value);
        searchUsers(e.target.value);
    };

    const selectUser = (user) => {
        setUser(user.firstname + " " + user.lastname);
        setShowResult(false);
        setUsersList([...userList, {
            "id": user?.id ? user.id : null,
            "name": user.firstname + " " + user.lastname,
            "country_code": user.country_code === null ? '' : user.country_code,
            "phone_number": user.phone_number === null ? '' : user.phone_number,
            "email": user.email
        }]);
        setFirstUser(user);
        setUserId(user.id);
    };

    const AddToQuantity = () => {
        if (quantity.length < numberOfSeats) {
            setQuantity([...quantity, quantity.length++]);
            setUsersList([...userList, {
                "id": null,
                "name": "",
                "country_code": "20",
                "phone_number": "",
                "email": ""
            }])
        }
    };

    const RemoveFromQuantity = () => {
        if (quantity.length > 1) {
            quantity.pop();
            setQuantity([...quantity]);
            userList.pop();
            setUsersList(userList);
        }
    };

    const getUserPhoneNumber = (value, i) => {
        let tempArray = [...userList];
        let tempObj = tempArray[i];
        tempObj.phone_number = value;
        const newList = userList.map((obj, index) => {
            if (index === i) {
                return tempObj
            }
            return obj;
        });
        setUsersList(newList);
    };

    const getUserName = (value, i) => {
        let tempArray = [...userList];
        let tempObj = tempArray[i];
        tempObj.name = value;
        const newList = userList.map((obj, index) => {
            if (index === i) {
                return tempObj
            }
            return obj;
        });
        setUsersList(newList);
    };


    const getUserEmail = (value, i) => {
        let tempArray = [...userList];
        let tempObj = tempArray[i];
        tempObj.email = value;
        const newList = userList.map((obj, index) => {
            if (index === i) {
                return tempObj
            }
            return obj;
        });
        setUsersList(newList);
    };

    const getCountryCode = (value, i) => {
        let tempArray = [...userList];
        let tempObj = tempArray[i];
        tempObj.country_code = value;
        const newList = userList.map((obj, index) => {
            if (index === i) {
                return tempObj
            }
            return obj;
        });
        setUsersList(newList);
    };

    return (
        <Grid container lg={12}>
            <Grid container lg={6} className="activity-form">
                <Grid container md={12}>
                    <Typography
                        component="h1"
                        style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            borderBottom: "2px solid var(--primary-color)"
                        }}
                        className="mb-20px"
                    >
                        {t("reservations.searchExistCustomer")}
                    </Typography>
                </Grid>
                <Grid container md={12} className="mb-30px">
                    <Grid sm={6} position="relative">
                        <TextFieldInput
                            type="search"
                            placeholder={t("reservations.searchNameEmail")}
                            StartAdornment={<SearchIcon />}
                            className="reservation-search"
                            style={{ width: "90%" }}
                            value={user}
                            onChange={(e) => searchForUsers(e)}
                            sx={{padding: '0px!important'}}
                        />
                        {showResult && (
                            <Typography component="ul" container className="render-options">
                                {usersList?.length === 0 && (
                                    <Grid
                                        container
                                        component="li"
                                        className="mb-10px"
                                        textAlign="center"
                                        display="block"
                                    >
                                        {t("reservations.noUsers")}
                                    </Grid>
                                )}
                                {usersList?.map((item , index) => (
                                    <Grid
                                    key={index} 

                                        container
                                        component="li"
                                        className="mb-10px render-item"
                                        onClick={() => selectUser(item)}
                                    >
                                        <Grid md={3}>
                                            <img src={icons.UserAvatar} alt="" />
                                        </Grid>
                                        <Grid md={9}>
                                            <Typography component="p" fontWeight="bold">
                                                {item.firstname} {" "} {item.lastname}
                                            </Typography>
                                            <Typography component="p" color="#a7a7a7">
                                                {item.email}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Typography>
                        )}
                    </Grid>
                    <Grid container sm={6}>
                        <Grid xs={6}>
                            <Typography component="p" lineHeight="3.5" fontWeight="bolder">
                                {numberOfSeats} {t("reservations.seatsAvailable")}
                            </Typography>
                        </Grid>
                        <Grid container xs={6}>
                            <Grid container xs={9} margin="auto" style={{ background: "#f9f9f9", borderRadius: "10px", padding: "8px 5px" }}>
                                <Grid xs={4}>
                                    <PrimaryButton
                                        btnType="icon"
                                        startIcon={<RemoveIcon />}
                                        click={() => RemoveFromQuantity()}
                                    />
                                </Grid>
                                <Grid xs={4}>
                                    <Typography
                                        component="p"
                                        textAlign="center"
                                        lineHeight="2.5"
                                        fontWeight="bold"
                                    >
                                        {quantity?.length}
                                    </Typography>
                                </Grid>
                                <Grid xs={4}>
                                    <PrimaryButton
                                        btnType="icon"
                                        startIcon={<AddIcon />}
                                        click={() => AddToQuantity()}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {showParticipationsForm && (
                    <Grid container>
                        <Grid container md={12} className="mb-10px">
                            <Typography
                                component="h1"
                                style={{
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    borderBottom: "2px solid var(--primary-color)"
                                }}
                                className="mb-20px"
                            >
                                {t("reservations.participants")}
                            </Typography>
                        </Grid>
                        {quantity?.map((i) => (
                            <AddParticipant
                            key={i} 
                                i={i}
                                getUserPhoneNumber={getUserPhoneNumber}
                                quantity={quantity}
                                getUserName={getUserName}
                                getUserEmail={getUserEmail}
                                getCountryCode={getCountryCode}
                                firstUser={firstUser}
                            />
                        ))}
                    </Grid>
                )}
            </Grid>
            <Grid container lg={12} gap={1.5}>
                <Grid lg={'auto'}>
                    <PrimaryButton
                        text={t("reservations.done")}
                        classBtn="primary"
                        style={{ padding: "5px 25px" }}
                        click={() => createReservation(userId, groupId, userList, quantity.length)}
                        disabled={!userId}
                    />
                </Grid>
                <Grid lg={'auto'}>
                    <PrimaryButton
                        text={t("reservations.cancel")}
                        classBtn="secoundry"
                        style={{ padding: "5px 20px" }}
                        click={() => {
                            setUsersList([]);
                            setUser("");
                            setShowResult(false);
                            setQuantity([0]);
                            setShowParticipationsForm(false);
                            setUserId("");
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default withTranslation()(AddReservationForm)