import { Grid, Typography, Divider } from "@mui/material";
import SelectBox from "../../Atoms/Select/Select.jsx";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import TextFieldInput from "../../Atoms/Input/TextField/TextFieldInput.jsx";
import SearchIcon from "@mui/icons-material/Search";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import ScanQrcodeModel from "../JobOffers/Models/qrCode-scan.jsx";
import { ScanQrCode } from "../../../Contexts/APIs/General/scan-code.js";
import CustomSeparator from "../../Atoms/BreadCrumb/index.jsx";
import moment from "moment";
import { t } from "i18next";
import { withTranslation } from "react-i18next";

const DetailsHeader = (props) => {
  const [search, setSearch] = useSearchParams();

  let pageNumber = +search.get("page") == 0 ? 1 : +search.get("page");

  const Navigate = useNavigate();
  const { activityGroup, updateSearch, activityId, t } = props;
  const searchInReservations = (value) => {
    updateSearch(value);
  };
  const [open, setOpen] = useState(false);
  const handelScanCode = (code) => {
    const action = () => {
      setOpen(false);
      window.location.reload();
    };
    ScanQrCode({ qr_code: code }, action);
  };

  return (
    <Grid container>
      <CustomSeparator
        breadcrumbs={[
          <Link
            style={{ color: "#bebebe", textDecoration: "none" }}
            underline="hover"
            key="2"
            color="inherit"
            to={`/reservations?page=${pageNumber}`}
          >
            {t("reservations.reservations")}
          </Link>,
          <Typography
            key="3"
            style={{ color: "#bebebe", textDecoration: "none" }}
          >
            {`"${activityGroup?.start_date}"`}
          </Typography>,
        ]}
      />
      <Grid container md={12} mt={2}>
        <Grid sm={3}>
          <Typography
            component="h1"
            style={{ fontWeight: "bold", fontSize: "30px" }}
          >
            {`${activityGroup?.start_date} / ${moment(
              activityGroup?.start_time
            ).format("h a")}`}
          </Typography>
        </Grid>
        {!search.get("reservation_number") && (
          <>
            <Grid sm={3}>
              <Grid xs={10} margin="auto">
                <TextFieldInput
                  type="search"
                  placeholder={t("reservations.searchReservation")}
                  StartAdornment={<SearchIcon />}
                  className="reservation-search"
                  onChange={(e) =>
                    searchInReservations({ keyword: e.target.value })
                  }
                  sx={{ padding: "0px!important" }}
                  // value={keywords}
                />
              </Grid>
            </Grid>
            <Grid container sm={2}>
              <SelectBox
                id="status"
                style={{ width: "150px" }}
                name="status"
                items={[
                  { name: t("reservations.all"), id: "all" },
                  { name: t("reservations.pending"), id: "pending" },
                  {
                    name: t("reservations.paymentPending"),
                    id: "payment_pending",
                  },
                  { name: t("reservations.completed"), id: "completed" },
                  { name: t("reservations.cancelled"), id: "cancelled" },
                  { name: t("reservations.rejected"), id: "rejected" },
                  {
                    name: t("reservations.checkinPending"),
                    id: "checkin_pending",
                  },
                  { name: t("reservations.checkedIn"), id: "checked_in" },
                  {
                    name: t("reservations.paymentExpired"),
                    id: "payment_expired",
                  },
                ]}
                onchange={(e) =>
                  searchInReservations({ status: e.target.value })
                }
                title={t("reservations.status")}
                className="select"
              />
            </Grid>
            <Grid
              sm={4}
              textAlign="end"
              display="flex"
              alignItems="center"
              maxWidth="280px!important"
            >
              {/* <PrimaryButton
                        startIcon={<AddIcon />}
                        text="New reservation"
                        classBtn="primary r-25px"
                        style={{ padding: "5px 20px", height: "48px" }}
                        click={() => Navigate('/reservations/activities/add-reservation/' + activityId + '?group_id=' + activityGroup?.id)}
                    /> */}
              <PrimaryButton
                fontSize="13px"
                endIcon={<QrCodeScannerIcon />}
                text={t("reservations.scanQRCustomer")}
                style={{ padding: "8px 10px", height: "48px", width: "100%" }}
                classBtn="primary r-25px"
                click={() => setOpen(true)}
              />
              {open && (
                <ScanQrcodeModel
                  open={open}
                  setOpen={setOpen}
                  handelScanCode={handelScanCode}
                />
              )}
            </Grid>
          </>
        )}
      </Grid>
      <Divider style={{ width: "100%", margin: "20px 0" }} />
    </Grid>
  );
};

export default withTranslation()(DetailsHeader);
