import React from "react";
import HomeTemplate from "../../Templates/Main/Home.template";

const HomePage = ({ profileData, setProfileData }) => {
  return (
    <HomeTemplate profileData={profileData} setProfileData={setProfileData} />
  );
};

export default HomePage;
