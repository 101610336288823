import { Grid, Stack, Typography } from "@mui/material";
import ActivityDetails from "../../../Components/Organisms/Activities/ActivityDetails.jsx";
import SelectBox from "../../../Components/Atoms/Select/Select.jsx";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import CustomSeparator from "../../../Components/Atoms/BreadCrumb/index.jsx";
import { getSupportedLanguages } from "../../../Contexts/APIs/Lookups/SupportedLanguages.js";
import { useState } from "react";
import { useEffect } from "react";
import { withTranslation } from "react-i18next";

const ActivityDetailsTemplate = (props) => {
  const { pathname } = useLocation();

  const lang = localStorage.getItem("lang");
  let [searchParams, setSearchParams] = useSearchParams();
  let page = +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  const { t, activityDetails, RemoveActivity, activitiesPrivilages, type } = props;
  const [languagesList, setLanguagesList] = useState([]);
  const [language, setLanguageInTemp] = useState(lang);
  const [disableListeners, setDisableListeners] = useState([]);

  const getLanguagesAPI = async () => {
    const { res, err } = await getSupportedLanguages({
      page_size: 10000,
      page_number: 1,
    });
    if (res) setLanguagesList(res?.data?.supported_locales);
  };

  const getPropsNotHaveValueLength = (obj, counter) => {
    for(let prop in obj){
      if(obj[prop] == undefined || obj[prop] == null || obj[prop] == ""){ counter++; }
    }
    return counter
  }

  useEffect(() => {
    getLanguagesAPI();
  }, []);
  
  useEffect(() => {
    setTimeout(() => {
      activityDetails?.localized_data && languagesList?.map( item => {
        let counterListener = 0;
        if(Object.keys(activityDetails?.localized_data?.[item.id]).length === getPropsNotHaveValueLength(
          activityDetails?.localized_data?.[item.id], counterListener
        )){ disableListeners.length == 0 && setDisableListeners(prevState => [...prevState, true]) }else{
          disableListeners.length == 0 && setDisableListeners(prevState => [...prevState, false])
        }
      })
    }, 1000);    
  }, [languagesList])
  
  return (
    <Stack gap={4}>
      <CustomSeparator
        breadcrumbs={[
          <Link
            style={{ color: "#bebebe", textDecoration: "none" }}
            underline="hover"
            key="1"
            color="inherit"
            to={
              pathname?.includes("requested-activites")
                ? `/requested-activites?page=${page}`
                : pathname.includes("/approved-activites")
                ? `/approved-activites?page=${page}`
                : `/activities?page=${page}`
            }
          >
            Activities
          </Link>,

          <Typography
            key="3"
            style={{ color: "#bebebe", textDecoration: "none" }}
          >
            {activityDetails?.localized_data
              ? activityDetails?.localized_data[language]?.name
              : activityDetails?.name}
          </Typography>,
        ]}
      />
      <Stack flexDirection="row" gap={1} width="30%" alignItems="center">
        <Typography width="200px" fontSize="16px" fontWeight="bold">
          {t("joboffer.dataLanguage")}
        </Typography>
        <SelectBox
          id="language"
          name="language"
          items={languagesList}
          disabledItemsState={disableListeners}
          title="Select"
          className="select"
          value={language}
          onclick={(id, name) => {
            setLanguageInTemp(id);
          }}
        />
      </Stack>
      <ActivityDetails
        activityDetails={activityDetails}
        RemoveActivity={RemoveActivity}
        activitiesPrivilages={activitiesPrivilages}
        language={language}
        type={type}
      />
    </Stack>
  );
};

export default withTranslation()(ActivityDetailsTemplate);
