import {
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { completeAccount } from "../../../../Contexts/APIs/Auth/CompleteAccount";
import { CityAPI } from "../../../../Contexts/APIs/Lookups/City";
import { CountryAPI } from "../../../../Contexts/APIs/Lookups/Country";
import { CurrencyAPI } from "../../../../Contexts/APIs/Lookups/Currency";
import BankAccountDetails from "../../Auth/Account/Forms/BankAccountDetails";
import { withTranslation } from "react-i18next";
import Loader from "../../../Atoms/Loader/Loader";

const UpdateBankDetailsModal = ({ diveCenterDetails, open, setOpen, t, newDiveCenterDetails, setNewDiveCenterDetails }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 900,
    p: 4,
    border: "0",
  };
  const fcm_token = localStorage.getItem("fcm_token");
  const [currencies, setCurrencies] = useState([]);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [countriesCount, setCountriesCount] = useState([]);
  const [citiesCount, setCitiesCount] = useState([]);
  const [currenciesCount, setCurrenciesCount] = useState([]);
  const [openLoader, setOpenLoader] = useState(false);

  const EmptyBankAccountAttributes = {
    id: null,
    beneficiary_name: "",
    beneficiary_address: "",
    iban: "",
    swift_code: "",
    country_id: null,
    city_id: null,
    currency_id: null,
    beneficiary_account_number: "",
    bank_name: "",
    bank_address: ""
  }

  const handleClose = () => setOpen(false);

  const getCurrencies = async () => {
    setOpenLoader(true);
    setCurrencies([]);
    const { res, err } = await CurrencyAPI({
      page_size: 10,
      page_number: 1,
      is_payout_currency: true,
    });
    if (res) {
      res?.data?.currencies.map((currency) => {
        setCurrencies((currencies) => [
          ...currencies,
          { name: currency?.lookup_key, id: currency?.id },
        ]);
      });
    }
    setCurrenciesCount(res?.extra?.total_count);
    setOpenLoader(false);
  };

  const getCities = async (filter) => {
    setOpenLoader(true);
    setCities([]);
    const { res, err } = await CityAPI({
      page_size: 10,
      page_number: 1,
      ...filter,
    });
    if (res) {
      if(filter?.page_number == 1){
        res?.data?.cities.map((currency) => {
          setCities((cities) => [
            ...cities,
            { name: currency?.name, id: currency?.id },
          ]);        
        })
      }else{
        setCities(cities);
        res?.data?.cities.map((currency, index) => {
          setCities((cities) => ([            
            ...cities,
            { name: currency?.name, id: currency?.id },
          ]));
        });
      }
      setCitiesCount(res?.extra?.total_count);
      setOpenLoader(false);
    }
    setOpenLoader(false);
  };

  const getCountries = async (filter) => {
    setOpenLoader(true);
    setCountries([]);
    const { res, err } = await CountryAPI({
      page_size: 10,
      page_number: 1,
      ...filter,
    });
    if (res) {
      if(filter?.page_number == 1){
        res?.data?.countries.map((currency) => {
          setCountries((countries) => [
            ...countries,
            { name: currency?.name, id: currency?.id },
          ]);        
        })
      }else{
        setCountries(countries);
        res?.data?.countries.map((currency, index) => {
          setCountries((countries) => ([            
            ...countries,
            { name: currency?.name, id: currency?.id },
          ]));
        });
      }
      setCountriesCount(res?.extra?.total_count);
      setOpenLoader(false);
    }
    setOpenLoader(false);
  };

  const CompleteAccount = async (type, data, user_id) => {
    const { res, err } = await completeAccount(type, data, user_id);
    if (res) {
      setNewDiveCenterDetails(res?.data?.user?.bank_account);
      handleClose();        
    }
  };

  const getPropsHasValueLength = (obj, counter) => {
    for (let prop in obj) {
      console.log('vals-prop', prop)
      if (obj[prop] != undefined && obj[prop] != null && obj[prop] != "") {
        counter++;
      }
    }
    return counter;
  };

  useEffect(() => {
    getCurrencies();
    // getCountries();
  }, []);

  const formik = useFormik({
    initialValues: {
      bank_account_attributes: {
        id: diveCenterDetails?.bank_account?.id, //supply id when updating
        beneficiary_name: diveCenterDetails?.bank_account?.beneficiary_name,
        beneficiary_address:
          diveCenterDetails?.bank_account?.beneficiary_address,
        iban: diveCenterDetails?.bank_account?.iban,
        swift_code: diveCenterDetails?.bank_account?.swift_code,
        country_id: diveCenterDetails?.bank_account?.country?.id,
        city_id: diveCenterDetails?.bank_account?.city?.id,
        currency_id: diveCenterDetails?.bank_account?.currency?.id,
        beneficiary_account_number:
          diveCenterDetails?.bank_account?.beneficiary_account_number,
        bank_name: diveCenterDetails?.bank_account?.bank_name,
        bank_address: diveCenterDetails?.bank_account?.bank_address,
      },
    },
    onSubmit: (values) => {
      const data = {
        user: { ...values },
        device: {
          uuid: "dummy",
          device_type: "web",
          fcm_token: fcm_token,
        },
      };
      const user_id = localStorage.getItem("userId");      
      let propsCounter = 0; 
      const bankPropsHasValueLength = getPropsHasValueLength(
        values.bank_account_attributes,
        propsCounter
      );

      // bankPropsHasValueLength - 1 : decreased 1 because form ui not have id input , 1 ref to id in values.bank_account_attributes
      
      if (
        (bankPropsHasValueLength === Object.keys(values.bank_account_attributes).length) ||
        bankPropsHasValueLength - 1 === 0
      ) {
        if (bankPropsHasValueLength - 1 == 0) {
          CompleteAccount("dive_centers", {...data, user: {
            bank_account_attributes: EmptyBankAccountAttributes
          }}, user_id);
          // CompleteAccount("dive_centers", {...data, user: {
          //   bank_account_attributes: {...EmptyBankAccountAttributes, id: data.user.bank_account_attributes.id}
          // }}, user_id);
        }else{
          CompleteAccount("dive_centers", data, user_id);
        }
      } else if (
        (bankPropsHasValueLength !== Object.keys(values.bank_account_attributes).length) &&
        bankPropsHasValueLength -1 !== 0
      ) {
        toast.error(t("profile.pleaseFillAllBank"));
      }
    },
  });

  useEffect(() => {  
    formik?.values?.bank_account_attributes?.country_id && setTimeout(() => {
      getCities({ country_id: formik?.values?.bank_account_attributes?.country_id });      
    }, 1000);  
  },[formik?.values?.bank_account_attributes?.country_id])
  
  useEffect(()=>{
    if(newDiveCenterDetails){
    setOpenLoader(true);    
    setTimeout(() => {
      formik.setValues({bank_account_attributes: {
        id: newDiveCenterDetails?.id,
        beneficiary_name: newDiveCenterDetails?.beneficiary_name,
        beneficiary_address:
          newDiveCenterDetails?.beneficiary_address,
        iban: newDiveCenterDetails?.iban,
        swift_code: newDiveCenterDetails?.swift_code,
        country_id: newDiveCenterDetails?.country?.id,
        country_name: newDiveCenterDetails?.country?.name,
        city_id: newDiveCenterDetails?.city?.id,
        city_name: newDiveCenterDetails?.city?.name,
        currency_id: newDiveCenterDetails?.currency?.id,
        currency_name: newDiveCenterDetails?.currency?.name,
        beneficiary_account_number:
          newDiveCenterDetails?.beneficiary_account_number,
        bank_name: newDiveCenterDetails?.bank_name,
        bank_address: newDiveCenterDetails?.bank_address,
      }})
    }, 500);
    setTimeout(() => {        
      setOpenLoader(false);      
    }, 1000);
    }
  },[newDiveCenterDetails])

  return (
    <>
    <Loader open={openLoader} />
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}
    >
      <Box sx={style}>
        <Grid
          className="activity-form"
          bgcolor={"white"}
          alignItems="center"
          justifyContent="center"
          width={"100%"}
        >
          <Grid>
            <form onSubmit={formik.handleSubmit}>
              <BankAccountDetails
                style={{ justifyContent: "center" }}
                isShadow={false}
                formik={formik}
                currencies={currencies}
                cities={cities}
                countries={countries}
                countryDefaultValue={newDiveCenterDetails?.country}
                cityDefaultValue={newDiveCenterDetails?.city}
                currencyDefaultValue={newDiveCenterDetails?.currency}
                getCountries={getCountries}
                getCities={getCities}
                getCurrencies={getCurrencies}
                countriesCount={countriesCount}
                citiesCount={citiesCount}
                currenciesCount={currenciesCount}
                setCities={setCities}
                isUpdateBankDetailsModal={true}
              />
              <Divider
                style={{
                  marginBlock: "30px",
                  width: "100%",
                  background: "#d9d9d9",
                }}
              />
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                gap={2}
              >
                <Button
                  onClick={() => setOpen(false)}
                  style={{
                    borderRadius: "26px",
                    border: "1px solid var(--primary-color)",
                    padding: "5px 20px",
                    height: "48px",
                    margin: "0 10px",
                  }}
                >
                  {t("profile.cancel")}
                </Button>
                <Button
                  type="submit"
                  style={{
                    borderRadius: "26px",
                    backgroundColor: "var(--primary-color)",
                    color: "white",
                    padding: "5px 20px",
                    height: "48px",
                    margin: "0 10px",
                  }}
                >
                  {t("profile.saveChanges")}
                </Button>
              </Stack>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Modal>
    </>
  );
};

export default withTranslation()(UpdateBankDetailsModal);
