import {
  Route,
  BrowserRouter,
  Routes as Switch,
  Navigate,
} from "react-router-dom";
import PendingReview from "./Components/Organisms/Auth/Account/Forms/pendingReview.jsx";
import ProfileRejected from "./Components/Organisms/Auth/Account/Forms/ProfileRejected.jsx";
import ChangePasswordPage from "./Pages/Auth/ChangePassword.page.jsx";
import CompleteAccountPage from "./Pages/Auth/CompleteAccount.page.jsx";
import CheckEmailPage from "./Pages/Auth/ForgotPassword/CheckEmail.page.jsx";
import ConfirmPasswordPage from "./Pages/Auth/ForgotPassword/ConfirmPassword.page.jsx";
import EmailSuccessPage from "./Pages/Auth/ForgotPassword/EmailSuccess.page.jsx";
import VerificationCodePage from "./Pages/Auth/ForgotPassword/VerificationCode.page.jsx";
import LoginPage from "./Pages/Auth/Login.page.jsx";
import SelectUserSuperPage from "./Pages/Auth/SelectUserSuper/SelectUserSuper.page.jsx";
import MainPage from "./Pages/Main/Main.Page.jsx";

function RoutesPage(props) {
  const { t } = props;
  const isAuth = localStorage.getItem("Token");
  const accessToken = localStorage.getItem("accessToken");
  
  return (
    <BrowserRouter>
   
      <Switch>
        <Route
          path="*"
          element={isAuth ? <MainPage /> : <Navigate to="/login" />}
        />
        {!isAuth &&
          (accessToken ? (
            <>              
              <Route path="/login" exact element={<LoginPage t={t} />} />

              <Route
                path="/change-password"
                exact
                element={<ChangePasswordPage t={t} />}
              />
              <Route
                path="/complete-account"
                exact
                element={<CompleteAccountPage t={t} />}
              />
              <Route
                path="/pending-review"
                exact
                element={<PendingReview t={t} />}
              />
              <Route
                path="/profile-rejected"
                exact
                element={<ProfileRejected t={t} />}
              />
              <Route
                path="/select-user"
                exact
                element={<SelectUserSuperPage />}
              />
            </>
          ) : (
            <>
              <Route path="/login" exact element={<LoginPage t={t} />} />
              <Route exact path="/forget-password" element={<CheckEmailPage />} />
              <Route exact path="/email-success" element={<EmailSuccessPage />} />
              <Route exact path="/email-verify" element={<VerificationCodePage />} />
              <Route exact path="/confirm-password" element={<ConfirmPasswordPage />} />              
            </>
          ))}
      </Switch>
    </BrowserRouter>
  );
}

export default RoutesPage;
