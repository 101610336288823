import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";
import { makeFilterString } from "../Helper/Helper.js";

export async function searchForUsers(user_type, filter_obj) {
    return await axiosFn("get", URL.users + user_type + makeFilterString(filter_obj))
      .then((res) => {
        return {
          res: res.data,
          err: null,
        };
      })
      .catch(handleError);
}