import * as yup from "yup";
import { getBranchTranslations } from "../../../Contexts/APIs/Lookups/Help";

export const JopOfferValidationSchema = (data) => {
  const joboffer = getBranchTranslations("joboffer");

  return yup.object({
    branch_id: yup.string().required(joboffer.branchRequired),
    organization_id: yup
      .string()
      .nullable()
      .required(joboffer.organizationRequired),
    organization_certificate_id: yup
      .string()
      .nullable()
      .required(joboffer.organizationCertificateRequired),

    rate: yup.number().required(joboffer.rateRequired),
    currency_id: yup.string().required(joboffer.currencyRequired),
    job_type: yup.string().required(joboffer.jobTypeRequired),
    activity_type: yup.string().required(joboffer.activityTypeRequired),
    dive_expert_professional_level: yup
      .string()
      .required(joboffer.diveExpertRequired),
  });
};
