import { Grid, Divider } from "@mui/material";
import ListPrivateGuideReservations from "./List.jsx";
import MainList from "./MainList.jsx";

const PrivateGuideReservationsPanal = (props) => {
  const {
    activitesList,
    count,
    page,
    setPage,
    updatePagination,
    ordersPrivilages,
    filterObj,
    setOpenLoader,
    joboffersPrivilages
  } = props;

  return (
    <Grid container width="100%">
      <Divider style={{ width: "100%", margin: "20px 0" }} />
      <Grid container width="100%">
        <MainList
          activitesList={activitesList}
          count={count}
          page={page}
          setPage={setPage}
          updatePagination={updatePagination}
          ordersPrivilages={ordersPrivilages}
          filterObj={filterObj}
          setOpenLoader={setOpenLoader}
          joboffersPrivilages={joboffersPrivilages}
        />
      </Grid>
    </Grid>
  );
};

export default PrivateGuideReservationsPanal;
