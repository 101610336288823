import WalletTemplate from "../../../Templates/Main/Wallet/Wallet.template.jsx";
import { useState, useEffect } from "react";
import Loader from "../../../Components/Atoms/Loader/Loader.js";
import { getDiveCenterDetails } from "../../../Contexts/APIs/DiveCenter/DiveCenter.js";

const WalletPage = (props) => {
    const { t ,profileData} = props;
    const [WalletData, setWalletData] = useState([]);
    const [WalletList, setWalletList] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [openLoader, setOpenLoader] = useState(false);
    const userType = localStorage.getItem("userType");
    const userId = userType !== 'descendant_user' ? localStorage.getItem('userId') : localStorage.getItem('ancestorId');
    const [filterObj, setFilterObj] = useState(null)
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const getWallet = async () => {
        setOpenLoader(true);
        const { res, err } = await getDiveCenterDetails(userId, {
            ...filterObj,
        });
        if (res) {
            setCount(res?.data?.user?.wallet?.wallet_transactions?.length);
            setWalletData(res?.data?.user?.wallet);
            setWalletList(
                res?.data?.user?.wallet?.wallet_transactions?.filter((item, index) => index >= 0 + ((page - 1) * 10) && index <= 9 + ((page - 1) * 10))
            );
        }
        setOpenLoader(false);
    };


    useEffect(() => {
        getWallet();
    }, [page, filterObj])

    return (
        <>
            <Loader open={openLoader} />
            <WalletTemplate
                WalletData={WalletData}
                WalletList={WalletList}
                count={count}
                page={page}
                handlePageChange={handlePageChange}
                filterObj={filterObj}
                setFilterObj={setFilterObj}
                profileData={profileData}
            // WalletPrivilages={WalletPrivilages}
            />
        </>
    )
}

export default WalletPage