import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";
import { makeFilterString } from "../Helper/Helper.js";

export async function getReviewsList(filter_obj, data) {
    return await axiosFn("post", URL.reviews + makeFilterString(filter_obj), data)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}

// 

export async function createReview(job_offer_id, data) {
    return await axiosFn("post", URL.reviews + `/job_offers/${job_offer_id}`, data)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}