import {
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import CustomPagination from "../../Atoms/Pagination/Pagination.js";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  deleteRequestedActivity,
  editRequestedActivity,
} from "../../../Contexts/APIs/RequestedActivities/Activities.js";
import { deleteActivity } from "../../../Contexts/APIs/Activities/Activities.js";
import { useEffect } from "react";
import { icons } from "../../../Assets/AssetHelper.js";
import { toast } from "react-toastify";
import DeleteModal from "../General/Models/DeleteModal.jsx";
const ListApprovedActivity = (props) => {
  const {
    t,
    activitesList,
    setActivitiesList,
    updatePagination,
    count,
    page,
    setPage,
    getActivities,
    filterObj,
    setOpenLoader,
    activitiesPrivilages,
  } = props;
  let [searchParams, setSearchParams] = useSearchParams();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");

  const Navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndex(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedIndex(null);
  };

  const [SelectedActivity, setSelectedActivity] = useState(false);

  useEffect(() => {
    setPage(searchParams.get("page"));
    updatePagination(searchParams.get("page"));
  }, [searchParams.get("page")]);

  const handelUpdate = async () => {
    setOpenLoader(true);
    const { res, err } = await deleteRequestedActivity(
      SelectedActivity?.activity?.id
    );
    handleClose();
    if (res) {
      setActivitiesList((prevList) =>
        prevList.filter((item) => item.id !== SelectedActivity?.activity?.id)
      );
      toast.success("Approved activity deleted successfully");
    }
    setOpenLoader(false);
  };

  const states = {
    pending: "#F6B119",
    rejected: "#E10007",
    dive_course: 1,
  };

  return (
    <Box width="100%">
      <Stack flexDirection="row" width="100%" className="table-header mb-20px">
        <Grid sm={1} textAlign="center">
          {`${t("requestedActivites.ID")}.`}
        </Grid>
        <Grid sm={1.5}>{t("requestedActivites.name")}</Grid>
        <Grid sm={1.5}>{t("requestedActivites.diveExpertName")}</Grid>
        <Grid sm={1.5}>{t("requestedActivites.type")}</Grid>
        <Grid sm={1.5}>{t("requestedActivites.branch")}</Grid>
        <Grid sm={1.5}>{t("requestedActivites.activityPrice")}</Grid>
        <Grid sm={1.5}>{t("requestedActivites.privateDiveExpertFees")}</Grid>
        <Grid sm={1}>{t("requestedActivites.totalPrice")}</Grid>
        <Grid sm={1}>{t("requestedActivites.action")}</Grid>
      </Stack>

      <Grid container className="table-items-container">
        {activitesList?.length == 0 && (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "200px",
            }}
          >
            <img
              src={icons.NoActivities}
              alt="no-branches"
              style={{ display: "block", width: "125.8px", height: "124.9px" }}
            />
            <Typography
              sx={{
                fontSize: "30px",
                fontWeight: "bold",
                color: "#333132",
                mt: "25px",
              }}
            >
              {t("activity.noActivites")}
            </Typography>
          </Grid>
        )}

        {activitesList?.map((activityItem, index) => (
          <Stack
            flexDirection="row"
            width="100%"
            className="activity-item mb-20px"
            key={index}
            onClick={() =>
              setSelectedActivity({ activity: activityItem, index: index })
            }
            sx={{
              cursor: "pointer",
            }}
          >
            <Grid md={1} textAlign="center">
              {activityItem?.id}
            </Grid>
            <Grid
              md={1.5}
              sx={{
                cursor: "pointer",
                fontWeight: "700",
              }}
              onClick={() =>
                Navigate(
                  "/approved-activites/details/" +
                    activityItem?.dive_activity?.id +
                    "?page=" +
                    pageNumber
                )
              }
            >
              {activityItem?.dive_activity?.name || "-"}
            </Grid>
            <Grid
              sm={1.5}
              sx={{
                textDecoration: "underline",
                cursor: "pointer",
                fontWeight: "700",
              }}
              onClick={() =>
                Navigate(
                  `/approved-activites/freelancers/${activityItem?.dive_expert?.id}?page=${pageNumber}`
                )
              }
            >
              {`${activityItem?.dive_expert?.first_name} ${activityItem?.dive_expert?.last_name}` ||
                "-"}
            </Grid>
            <Grid sm={1.5}>
              {activityItem?.dive_activity?.activity_type?.replace("_", " ")}
            </Grid>

            <Grid sm={1.5}>{activityItem?.dive_activity?.branch?.name}</Grid>
            <Grid sm={1.5}>{activityItem?.dive_activity?.price}</Grid>

            <Grid sm={1.5}>{activityItem?.dive_expert?.fees}</Grid>

            <Grid sm={1}>
              {activityItem?.dive_activity?.price +
                activityItem?.dive_expert?.fees}
            </Grid>

            <Grid sm={1}>
              <div>
                <Button
                  id={`basic-button-${index}`}
                  aria-controls={
                    open && selectedIndex === index ? "basic-menu" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={
                    open && selectedIndex === index ? "true" : undefined
                  }
                  onClick={(event) => handleClick(event, index)}
                  sx={{ background: "#ECECEC", color: "black" }}
                >
                  <MoreHorizIcon />
                </Button>
                {activitiesPrivilages.can_delete && (
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={selectedIndex === index && open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={() => setOpenDeleteModel(true)}>
                    {t('activity.deleteActivity')}
                    </MenuItem>
                  </Menu>
                )}
              </div>
            </Grid>
          </Stack>
        ))}
        {count > 10 && (
          <Grid container justifyContent="center" mb={7.5} mt={5}>
            <CustomPagination
              count={Math.ceil(count / 10)}
              size="large"
              shape="rounded"
              page={page}
              className="pagination"
            />
          </Grid>
        )}
      </Grid>
      {openDeleteModel && (
        <DeleteModal
          open={openDeleteModel}
          setOpen={setOpenDeleteModel}
          handelDeleteRequest={() => {
            handelUpdate();
            setOpenDeleteModel(false);
          }}
          text={[
            `${t("users.delete")} ${SelectedActivity?.activity?.dive_activity?.name}`,
            t("activity.deleteActivityMsg"),
          ]}
          buttonText={[t("offer.cancel"), t("offer.delete")]}
        />
      )}
    </Box>
  );
};

export default withTranslation()(ListApprovedActivity);
