import { Grid, Stack, Typography } from "@mui/material";

const DescriptionPanel = ({ title, description }) => {
  return (
    <Stack
      container
      className="details-panel"
      bgcolor="#fbfbfb"
      borderRadius="10px"
      p={2}
    >
      <Typography width="fit-content" className="mb-20px" component="h1">
        {title}
      </Typography>

      <Typography>{description}</Typography>
    </Stack>
  );
};

export default DescriptionPanel;
