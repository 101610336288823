import { axiosFn, handleError } from "../../index"
import { makeFilterString } from "../../Helper/Helper"
import * as URL from "../../URLs"

export async function OrganizationsAPI(filter = {}) {
    return await axiosFn("get", URL.organizations + makeFilterString(filter))
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}