import React, { useEffect, useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Grid,
  Typography,
  Divider,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Stack,
} from "@mui/material";
import moment from "moment";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { withTranslation } from "react-i18next";
import InfoModal from "../General/Models/InfoModal";
import { useNavigate, useSearchParams } from "react-router-dom";

const ListPrivateGuideReservations = ({
  t,
  setOpenConfirmPayment,
  setOpenCancelReservations,
  setOpenRejectReservations,
  activityItem,
  setOpenCompleteActivity,
  setOpenReservationDetails,
  setOpenConfirmReservations,
  ordersPrivilages,
  reservationsDuration,
  reservationsStatus,
  joboffersPrivilages,
}) => {
  const Navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const userType = localStorage.getItem("userType");
  let [searchParams, setSearchParams] = useSearchParams();

  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const pendingOptions = (activityItem) => {
    return [
      !activityItem?.is_confirmed_by_dive_center && {
        title: t("reservations.confirmReservation"),
        action: () => setOpenConfirmReservations(true),
      },
      {
        title: t("reservations.rejectReservation"),
        action: () => setOpenRejectReservations(true),
      },
    ];
  };

  const completedOptions = [
    {
      title: t("reservations.confirmPayment"),
      action: () => setOpenConfirmPayment(true),
    },
    {
      title: t("reservations.cancelReservation"),
      action: () => setOpenCancelReservations(true),
    },
  ];

  const checkinOptions = [
    {
      title: t("reservations.completeActivity"),
      action: () => setOpenCompleteActivity(true),
    },
  ];

  const menuOptions = (activityItem) => {
    return {
      "Waiting for confirm reservation": pendingOptions(activityItem),
      "Waiting for payment confirmation": completedOptions,
      "Customer checked in": checkinOptions,
    };
  };
  return (
    <Stack
      Stack
      flexDirection="row"
      width="100%"
      className="activity-item mb-20px"
      gap="5px"
    >
      {" "}
      <Grid
        md={1}
        textAlign="center"
        fontWeight="bold"
        style={{ textDecoration: "underline" }}
        onClick={(e) => {
          Navigate(
            `/private-guide-reservations/${activityItem?.id}?page=${pageNumber}`
          );
        }}
      >
        {activityItem?.id}
      </Grid>
      <Grid
        sm={2}
        fontWeight="bold"
        style={{ textDecoration: "underline" }}
        onClick={() => setOpenReservationDetails(true)}
      >
        {activityItem?.user_info?.name}
      </Grid>
      <Grid
        md={2}
        fontWeight="bold"
        style={{ textDecoration: joboffersPrivilages?.can_read && "underline" }}
        onClick={(e) => {
          joboffersPrivilages?.can_read &&
            Navigate(
              `/private-guide-reservations/freelancers/${activityItem?.order_item?.purchasable?.dive_activity?.owner?.id}?page=${pageNumber}`
            );
        }}
      >
        {activityItem?.order_item?.purchasable?.dive_activity?.owner?.firstname}{" "}
        {activityItem?.order_item?.purchasable?.dive_activity?.owner?.lastname}
      </Grid>
      <Grid md={2}>
        {activityItem?.order_item?.purchasable?.dive_activity?.name}
      </Grid>
      <Grid md={2}>
        {moment(
          activityItem?.order_item?.purchasable?.dive_activity?.created_at
        ).format("MMM DD,YYYY")}
      </Grid>
      <Grid md={2}>{activityItem?.branch?.name}</Grid>
      <Grid md={2}>
        {" "}
        {moment(activityItem?.created_at).format("MMM DD,YYYY")}
        {/* {moment(activityItem?.order_item?.purchasable?.start_date).format("MMM DD,YYYY")} */}
      </Grid>
      <Grid sm={2}>{activityItem?.reservation_number}</Grid>
      <Grid sm={1.5}>{activityItem?.quantity}</Grid>
      <Grid sm={2} fontWeight="bold">
        {/* {activityItem?.order_item?.amount}{" "} */}
        {activityItem?.payment_transaction?.amount_with_vat}{" "}
        {activityItem?.order_item?.purchasable?.currency?.lookup_key}
      </Grid>
      <Grid sm={2} color={activityItem?.is_paid ? "#3ed181" : "#e10007"}>
        {activityItem?.is_paid ? "Paid" : "Unpaid"}
      </Grid>
      <Grid sm={0.7}>{activityItem?.payment_method?.name}</Grid>
      <Grid sm={1.8}>
        {moment(activityItem?.updated_at)?.format("MMM DD,YYYY / h a")}
      </Grid>
      <Grid
        sm={2}
        display="flex"
        alignItems="end"
        color={
          reservationsStatus[`${activityItem?.id}`]?.id == activityItem?.id
            ? reservationsStatus[`${activityItem?.id}`]?.status_display
                ?.display_color
            : activityItem?.status_display?.display_color
        }
      >
        {reservationsStatus[`${activityItem?.id}`]?.id == activityItem?.id
          ? reservationsStatus[`${activityItem?.id}`]?.status_display
              ?.display_name
          : activityItem?.status_display?.display_name}
        {reservationsStatus[`${activityItem?.id}`]?.status ===
          "payment_expired" ||
        activityItem?.status === "payment_expired" ||
        reservationsStatus[`${activityItem?.id}`]?.status === "rejected" ||
        activityItem?.status === "rejected" ||
        reservationsStatus[`${activityItem?.id}`]?.status === "pending" ||
        activityItem?.status === "pending" ? (
          <IconButton
            className="reservation-modal-button"
            onClick={() => setOpenInfoModal(true)}
            sx={{ mb: "5px" }}
          >
            <ErrorOutlineOutlinedIcon
              style={{ color: "#e10007", fontSize: "1.4rem" }}
            />
          </IconButton>
        ) : null}
      </Grid>
      {userType === "descendant_user" && ordersPrivilages?.can_update && (
        <Grid sm={2}>
          {menuOptions(activityItem)[
            reservationsStatus[`${activityItem?.id}`]?.id == activityItem?.id
              ? reservationsStatus[`${activityItem?.id}`]?.status_display
                  ?.display_name
              : activityItem?.status_display?.display_name
          ] ? (
            <Grid>
              <Button
                id="demo-positioned-button"
                aria-controls={open ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={(e) => {
                  // e.stopPropagation();
                  handleClick(e);
                }}
              >
                <MoreHorizIcon style={{ color: "black" }} />
              </Button>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                {menuOptions(activityItem)[
                  reservationsStatus[`${activityItem?.id}`]?.id ==
                  activityItem?.id
                    ? reservationsStatus[`${activityItem?.id}`]?.status_display
                        ?.display_name
                    : activityItem?.status_display?.display_name
                ]?.map((val, index) => {
                  if (val)
                    return (
                      <MenuItem
                        key={index}
                        onClick={(e) => {
                          e.stopPropagation();
                          val?.action();
                          handleClose();
                        }}
                      >
                        {val?.title}
                      </MenuItem>
                    );
                })}
              </Menu>
            </Grid>
          ) : (
            <Typography>-</Typography>
          )}
        </Grid>
      )}
      {userType !== "descendant_user" && (
        <Grid sm={2}>
          {menuOptions(activityItem)[
            reservationsStatus[`${activityItem?.id}`]?.id == activityItem?.id
              ? reservationsStatus[`${activityItem?.id}`]?.status_display
                  ?.display_name
              : activityItem?.status_display?.display_name
          ] ? (
            <Grid>
              <Button
                id="demo-positioned-button"
                aria-controls={open ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={(e) => {
                  // e.stopPropagation();
                  handleClick(e);
                }}
              >
                <MoreHorizIcon style={{ color: "black" }} />
              </Button>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                {menuOptions(activityItem)[
                  reservationsStatus[`${activityItem?.id}`]?.id ==
                  activityItem?.id
                    ? reservationsStatus[`${activityItem?.id}`]?.status_display
                        ?.display_name
                    : activityItem?.status_display?.display_name
                ]?.map((val, index) => {
                  if (val)
                    return (
                      <MenuItem
                        key={index}
                        onClick={(e) => {
                          e.stopPropagation();
                          val?.action();
                          handleClose();
                        }}
                      >
                        {val?.title}
                      </MenuItem>
                    );
                })}
              </Menu>
            </Grid>
          ) : (
            <Typography>-</Typography>
          )}
        </Grid>
      )}
      {openInfoModal && (
        <InfoModal
          open={openInfoModal}
          setOpen={setOpenInfoModal}
          text={[
            t("reservations.attention"),
            activityItem?.status_display.display_name.toLowerCase() ===
            "payment allowance period has expired"
              ? t("reservations.paymentAllowanceExpired")
              : activityItem?.status_display.display_name.toLowerCase() ===
                "confirmation rejected"
              ? t("reservations.confirmationRejected")
              : activityItem?.status_display.display_name.toLowerCase() ===
                "waiting for confirm reservation"
              ? `${t("reservations.waitingConfirmReservation")} ${parseInt(
                  reservationsDuration / 24
                )} ${
                  parseInt(reservationsDuration / 24) !== 1
                    ? t("reservations.days")
                    : t("reservations.day")
                } ${t("reservations.and")} ${reservationsDuration % 24} ${
                  reservationsDuration % 24 !== 1
                    ? t("reservations.hours")
                    : t("reservations.hour")
                }`
              : null,
          ]}
        />
      )}
    </Stack>
  );
};

export default withTranslation()(ListPrivateGuideReservations);
