import { Grid, Typography } from "@mui/material";
import AddBranchForm from "../../../Components/Organisms/Branches/AddBranchForm.jsx";

const AddBranchTemplate = (props) => {
  const {
    t,
    uploadImages,
    organizationsList,
    organizationsCount,
    createBranch,
    editBranch,
    branchImages,
    RemoveBranchImage,
    uploadLocalLicense,
    uploadLicense,
    localeLicenseImg,
    licenseImg,
    getFeaturesOptions,
    featureOptions,
    supportedLanguages,
    type,
    getBranchType,
    formType = { formType },
    getOrganization,
    RemoveLocaleLicenseImg
  } = props;

  return (
    <Grid container>
      <Grid container>
        <AddBranchForm
          getOrganization={getOrganization}
          getBranchType={getBranchType}
          uploadImages={uploadImages}
          organizationsList={organizationsList}
          organizationsCount={organizationsCount}
          createBranch={createBranch}
          editBranch={editBranch}
          branchImages={branchImages}
          RemoveBranchImage={RemoveBranchImage}
          uploadLocalLicense={uploadLocalLicense}
          uploadLicense={uploadLicense}
          localeLicenseImg={localeLicenseImg}
          licenseImg={licenseImg}
          getFeaturesOptions={getFeaturesOptions}
          featureOptions={featureOptions}
          supportedLanguages={supportedLanguages}
          type={type}
          formType={formType}
          RemoveLocaleLicenseImg={RemoveLocaleLicenseImg}
        />
      </Grid>
    </Grid>
  );
};

export default AddBranchTemplate;
