import { axiosFn, handleError } from "../index.js";
import * as URL from "../URLs";
import { makeFilterString } from "../Helper/Helper.js";

export async function getPermissionList(filter_obj) {
    return await axiosFn("get", '/lookups/resources'+ makeFilterString(filter_obj))    
      .then((res) => {
        return {
          res: res?.data,
          err: null,
        };
      })
      .catch(handleError);
}


export async function getRoleList(filter_obj) {
  return await axiosFn("get", URL.Roles + makeFilterString(filter_obj))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}


export async function createRole(data) {
  return await axiosFn("post", URL.Roles, data)    
    .then((res) => {        
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}


export async function showRole(id) {
  return await axiosFn("get", `${URL.Roles}/${id}`)    
    .then((res) => {      
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}


export async function editRole(id, data) {
  return await axiosFn("put", `${URL.Roles}/${id}`,data)
      .then((res) => {
          return {
              res: res.data,
              err: null,
          };
      })
      .catch(handleError);
}

export async function deleteRole(id) {
  return await axiosFn("delete", `/${URL.Roles}/${id}`)
      .then((res) => {
          return {
              res: res.data,
              err: null,
          };
      })
      .catch(handleError);
}