import { useParams } from "react-router-dom";
import { useState } from "react";
import Loader from "../../../../Components/Atoms/Loader/Loader";
import InHouseActivityGroupsTemplate from "../../../../Templates/Main/Salesmen/InHouseActivities/InHouseActivityGroups.template";
import { getInHouseActivityGroupsList } from "../../../../Contexts/APIs/Salsemen/InHouseActivities";

const InHouseActivityGroupsPage = (props) => {
  const { t } = props;
  const { id } = useParams();
  const [count, setCount] = useState();
  const [openLoader, setOpenLoader] = useState(false);
  const [activityGroups, setActivityGroups] = useState([]);
  const [activityInfo, setActivityInfo] = useState({});

  const GetActivityGroups = async (filterObj) => {
    setOpenLoader(true);
    // const {res, err} = await getActivityGroups(id, filterObj);
    const { res, err } = await getInHouseActivityGroupsList(id, filterObj);
    if (res) {
      setCount(res?.extra?.total_count);
      setActivityGroups(res?.data?.inhouse_activity_groups);
      setActivityInfo(
        res?.data?.inhouse_activity_groups?.[0]?.inhouse_activity
      );
    }
    if (err) {
    }
    setOpenLoader(false);
  };
  return (
    <>
      <Loader open={openLoader} />
      <InHouseActivityGroupsTemplate
        getActivityGroups={GetActivityGroups}
        count={count}
        activityGroups={activityGroups}
        activityInfo={activityInfo}
      />
    </>
  );
};

export default InHouseActivityGroupsPage;
