import React from 'react'
import { Button, Modal, Stack, Typography } from '@mui/material';
import warning from '../../../../Assets/Icons/warning.png';
import './AttentionModal.css';

function AttentionModal({open, setOpen, handleRequest, text, buttons}) {
    const handleClose = () => { 
        setOpen(false);        
    }

  return (
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Stack alignItems='center' justifyContent='center' className='attention-modal'>
            <div className="attention-modal-content">
                <img src={warning} alt='attention-logo' />
                <Typography component="h1" >
                    <span>{ text[0] }</span> { text[1] }
                </Typography>
                <Typography> { text[2] } </Typography>
            </div>
            <Stack flexDirection='row' alignItems='center' mt={4} className='buttons-wrap'>
                <Button className='primary r-25px' onClick={handleRequest}>{buttons[0]}</Button>

                <Button onClick={() => setOpen(false)}>  {buttons[1]}</Button>
            </Stack>
        </Stack>

    </Modal>
  )
}

export default AttentionModal