import { Grid, Stack, Typography } from "@mui/material";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import moment from "moment";
const InformationPanel = ({
  title,
  features,
  jobOfferData,
  isDate = false,
}) => {
  return (
    <Grid container className="details-panel">
      <Typography className="mb-20px" component="h1">
        {title}
      </Typography>
      <Grid container>
        {isDate && (
          <>
            <Stack
              container
              flexDirection="row"
              className="mb-10px"
              width="100%"
            >
              <CalendarTodayIcon className="check-icon" />
              <Stack>
                <Typography textTransform="capitalize">
                  Start date{" "}
                  {moment(jobOfferData?.start_date).format("DD/MM/YYYY")}
                </Typography>
                <Typography textTransform="capitalize">
                  End date {moment(jobOfferData?.end_date).format("DD/MM/YYYY")}
                </Typography>
              </Stack>
            </Stack>

            <Stack flexDirection="row" className="mb-10px">
              <AccessTimeIcon className="check-icon" />

              <Typography textTransform="capitalize">
                Start time {moment(jobOfferData?.start_time).format("h A")}
              </Typography>
            </Stack>
          </>
        )}

        {features?.map((feature, index) => (
          <Grid container className="mb-10px" key={index}>
            {feature.value ? (
              <CheckOutlinedIcon className="check-icon" />
            ) : (
              <ClearIcon className="wrong-icon" />
            )}
            <Typography textTransform="capitalize">{feature?.item}</Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default InformationPanel;
