import { Grid, Typography, Divider, Stack } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useState, useEffect } from "react";
import Map from "../../Maps/GoogleMap.jsx";
import ImagesPanel from "../../General/Panels/ImagesPanel.jsx";
import DescriptionPanel from "../../General/Panels/DescriptionPanel.jsx";
import InformationPanel from "../../General/Panels/InformationPanel.jsx";
import "./ActivityDetailsPanel.css";
import { findInObject } from "../../../../Contexts/APIs/Helper/Helper.js";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { icons } from "../../../../Assets/AssetHelper.js";
const ActivityDetailsPanel = (props) => {
  const { t, activityDetails, language } = props;
  const lang = localStorage.getItem("lang");

  const featuresArray = [
    "mobile_voucher",
    "wheelchair_accessible",
    "guided_dives",
  ];
  const [features, setFeatures] = useState([]);
  const [activityInfos, setActivityInfos] = useState([]);
  const [priceInclude, setPriceInclude] = useState([]);
  const [priceNotInclude, setPriceNotInclude] = useState([]);
  const [certificationInfos, setCertificationInfos] = useState([]);
  const [dateTime, setDateTime] = useState([]);
  const [location, setLocation] = useState({
    lat: "",
    lng: "",
  });
  const getCurrentLocation = (lat, lng) => {
    setLocation({
      lat: lat,
      lng: lng,
    });
  };
  useEffect(() => {
    //set Activity Info
    const activityInfosTemp = [];
    activityInfosTemp.push(
      {
        item: `${t("activity.diveCenterName")} : `,
        value: activityDetails?.dive_center?.name
          ? activityDetails.dive_center.name
          : " - ",
        hasMark: false,
      },
      {
        item: `${t("activity.branchName")} : `,
        value: activityDetails?.branch?.name
          ? activityDetails.branch.name
          : " - ",
        hasMark: false,
      },
      {
        item: `${t("profile.ownername")} : `,
        value: activityDetails?.owner?.name
          ? activityDetails.owner.name
          : " - ",
        hasMark: false,
      }
    );
    setActivityInfos(activityInfosTemp);

    //set info
    const array = [];
    const languageStr = activityDetails?.activity_languages?.map(
      (language, ndx) => {
        return (
          language.language.name +
          `${ndx < activityDetails?.activity_languages?.length - 1 ? ", " : ""}`
        );
      }
    );
    array.push(
      {
        item: `${t("activity.needConfirmation")}`,
        value: activityDetails?.need_confirmation,
      },
      { item: `${t("activity.freeCancelation")}`, value: true },
      {
        item: `${t("activity.hotelPickup")}`,
        value: activityDetails?.pickup_option !== "hotel_pickup" ? false : true,
      },
      {
        item: `${t("activity.max")}. ${activityDetails?.max_no_of_students} ${t(
          "activity.studentsInstructors"
        )}`,
        value: true,
      },
      { item: languageStr, value: true }
    );
    featuresArray.forEach((item) => {
      const result = findInObject(activityDetails, item);
      array.push(result);
    });
    // array.push(
    //   {
    //     item: `${t("activity.invitationLink")} : `,
    //     value:
    //       activityDetails?.invitation_link != null
    //         ? activityDetails.invitation_link
    //         : " Empty",
    //     hasMark: false,
    //   },
    //   {
    //     item: `${t("activity.minAge")} : `,
    //     value:
    //       activityDetails?.min_age != null ? activityDetails.min_age : " N/A",
    //     hasMark: false,
    //   },
    //   {
    //     item: `${t("activity.minDives")} : `,
    //     value:
    //       activityDetails?.min_dives != null
    //         ? activityDetails.min_dives
    //         : " N/A",
    //     hasMark: false,
    //   }
    // );
    setFeatures(array);

    //set Price include list
    const priceIncludeTemp = [];
    activityDetails?.activity_price_includes?.map((price) => {
      priceIncludeTemp.push({ item: price.name, value: true });
    });
    setPriceInclude(priceIncludeTemp);

    //set Price not include list
    const priceNotIncludeTemp = [];
    activityDetails?.activity_price_excludes?.map((price) => {
      priceNotIncludeTemp.push({ item: price.name, value: false });
    });
    setPriceNotInclude(priceNotIncludeTemp);

    //set Date & Time
    // set Certificate
    const certificationInfosTemp = [];
    certificationInfosTemp.push(
      {
        item: `${t("branch.organization")} : `,
        value: activityDetails?.minimum_certificate
          ? activityDetails.minimum_certificate
          : " N/A ",
        hasMark: false,
      },
      {
        item: `${t("activity.certificate")} : `,
        value: activityDetails?.organization?.name
          ? activityDetails.organization.name
          : " N/A ",
        hasMark: false,
      }
    );
    setCertificationInfos(certificationInfosTemp);
  }, [activityDetails]);

  return (
    <Grid container>
      <Grid
        columnSpacing={2}
        container
        lg={11}
        margin="auto"
        paddingTop="20px"
        width="100%"
      >
        <Grid columnSpacing={2} item md={8} width="100%" px="16px">
          <ImagesPanel
            title={t("activity.activityImages")}
            DetailsImages={activityDetails}
            mediaType="default"
          />
          {activityDetails?.localized_data?.[language]?.description && (
            <DescriptionPanel
              title={t("activity.packageDescription")}
              description={
                activityDetails?.localized_data?.[language]?.description
              }
            />
          )}
          {activityDetails?.localized_data?.[language]?.client_expectation && (
            <DescriptionPanel
              title={t("activity.whatToExpect")}
              description={
                activityDetails?.localized_data?.[language]?.client_expectation
              }
            />
          )}
          {activityDetails?.localized_data?.[language]?.other_information && (
            <DescriptionPanel
              title={t("activity.activityInfo")}
              description={
                activityDetails?.localized_data?.[language]?.other_information
              }
            />
          )}
          {activityDetails?.localized_data?.[language]?.pickup_info && (
            <DescriptionPanel
              title={t("activity.pickupInfo")}
              description={
                activityDetails?.localized_data?.[language]?.pickup_info
              }
            />
          )}
          {activityDetails?.localized_data?.[language]?.cancellation_policy && (
            <DescriptionPanel
              title={t("activity.cancelationPolicy")}
              description={
                activityDetails?.localized_data?.[language]?.cancellation_policy
              }
            />
          )}
        </Grid>
        <Grid item md={4} width="100%" px="16px">
          {/* <InformationPanel
            title={t("activity.activityInfo")}
            features={activityInfos}
          /> */}
          <InformationPanel
            title={t("activity.information")}
            features={features}
          />
          {/* <InformationPanel
                        title={t('activity.dateAndTime')}
                        features={[
                            { item: activityDetails?.scheduling_option?.split('_').join(' '), value: true }
                        ]}
                    /> */}
          <Grid container className="details-panel">
            <Typography className="mb-10px" component="h1">
              {t("activity.dateAndTime")}
            </Typography>
            <Stack
              flexDirection="row"
              gap={1}
              my={1}
              className="details-info"
              alignItems="flex-start"
              width="100%"
            >
              <CheckIcon
                fontSize="1.1rem"
                style={{
                  color: "#f6b119",
                  marginTop: "4px",
                  fontSize: "15px",
                }}
              />
              <Grid>
                <Typography className="details-text">
                  {activityDetails?.scheduling_option === "everyday"
                    ? `${t("activity.Everyday")}`
                    : activityDetails?.scheduling_option === "some_weekdays"
                    ? `${t("activity.repeatWeekly")}`
                    : activityDetails?.scheduling_option === "some_dates"
                    ? `${t("activity.someDates")}`
                    : null}
                </Typography>
              </Grid>
            </Stack>
            {activityDetails.scheduling_option === "everyday" ? (
              <>
                <Stack flexDirection="row" gap={1} mb={1.5} width={"100%"}>
                  <Typography color="#333132" className="details-text">
                    {`${t("activity.startDate")} : `}
                  </Typography>
                  <Typography color="#333132" className="details-text">
                    {moment(new Date(activityDetails?.start_date)).format(
                      "DD/MM/YYYY"
                    )}
                  </Typography>
                </Stack>
                <Stack flexDirection="row" gap={1} mb={1.5} width={"100%"}>
                  <Typography color="#333132" className="details-text">
                    {`${t("activity.endDate")} : `}
                  </Typography>
                  <Typography color="#333132" className="details-text">
                    {moment(new Date(activityDetails?.end_date)).format(
                      "DD/MM/YYYY"
                    )}
                  </Typography>
                </Stack>
                <Stack flexDirection="row" gap={1} mb={1.5} width={"100%"}>
                  <Typography color="#333132" className="details-text">
                    {`${t("activity.startTime")} : `}
                  </Typography>
                  <Typography color="#333132" className="details-text">
                    {activityDetails?.scheduled_days[0]?.start_time
                      ? `${activityDetails?.scheduled_days[0]?.start_time}`
                      : "N/A"}
                  </Typography>
                </Stack>
              </>
            ) : activityDetails.scheduling_option === "some_weekdays" ? (
              <>
                {activityDetails.scheduled_days.map((day, index) => {
                  return (
                    <Grid container key={index} mb={1}>
                      <Grid item xs={12} md={6}>
                        <Typography
                          color="#333132"
                          className="details-text"
                          sx={{ textTransform: "capitalize" }}
                        >
                          {day.weekday}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography color="#333132" className="details-text">
                          {`${t("activity.startTime")} : ${day.start_time}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}
                <Grid container mb={1}>
                  <Grid item xs={12} md={6}>
                    <Typography color="#333132" className="details-text">
                      {`${t("activity.startDate")} : ${moment(
                        new Date(activityDetails?.start_date)
                      ).format("DD/MM/YYYY")}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography color="#333132" className="details-text">
                      {`${t("activity.endDate")} : ${moment(
                        new Date(activityDetails?.end_date)
                      ).format("DD/MM/YYYY")}`}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ) : activityDetails.scheduling_option === "some_dates" ? (
              activityDetails.activity_groups.map((day, index) => {
                return (
                  <Grid container key={index} mb={0.5}>
                    <Grid item xs={6}>
                      <Typography color="#333132" className="details-text">
                        {`${t("activity.date")} : ${day.start_date}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography color="#333132" className="details-text">
                        {`${t("activity.startTime")} : ${day.start_time}`}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })
            ) : null}

            <Stack flexDirection="row" gap={1} mb={1.5} width={"100%"}>
              <Typography color="#333132" className="details-text">
                {`${t("activity.reserveBefore")} : `}
              </Typography>
              <Typography color="#333132" className="details-text">
                {activityDetails?.reserve_before}{" "}
                {activityDetails?.reserve_before === 1
                  ? t("activity.hour")
                  : t("activity.hours")}
              </Typography>
            </Stack>

            <Stack flexDirection="row" gap={1} mb={1.5} width={"100%"}>
              <Typography color="#333132" className="details-text">
                {`${t("activity.cancelBefore")} : `}
              </Typography>
              <Typography color="#333132" className="details-text">
                {activityDetails?.cancellation_notice_period}{" "}
                {activityDetails?.cancellation_notice_period === 1
                  ? t("activity.hour")
                  : t("activity.hours")}
              </Typography>
            </Stack>
          </Grid>

          {/* <Map
                        getCurrentLocation={getCurrentLocation}
                        lat={activityDetails?.pickup_location?.lat}
                        lng={activityDetails?.pickup_location?.long}
                        type='view'
                    /> */}
          <Stack
            bgcolor="rgba(0, 0, 0, 0.01)"
            borderRadius="10px"
            p={0}
            className="map-wrap"
          >
            <Typography className="details-text">
              {t("activity.meetingPoint")}
            </Typography>
            <Map
              getCurrentLocation={getCurrentLocation}
              lat={activityDetails?.pickup_location?.lat}
              lng={activityDetails?.pickup_location?.long}
              type="view"
            />
            {activityDetails?.pickup_location?.full_address?.length !== 0 &&
              typeof activityDetails?.pickup_location?.full_address ===
                "string" && (
                <Typography className="map-wrap-address">
                  <img src={icons.Location} alt="location" />
                  {activityDetails?.pickup_location?.full_address}
                </Typography>
              )}
          </Stack>

          <InformationPanel
            title={t("activity.priceIncludes")}
            features={priceInclude}
          />
          <InformationPanel
            title={t("activity.priceNotIncludes")}
            features={priceNotInclude}
          />
          {/* <InformationPanel
            title={t("activity.certification")}
            features={certificationInfos}
          /> */}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(ActivityDetailsPanel);
