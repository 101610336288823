import { Button, Modal, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput";
import FormHead from "../../../Atoms/FormHead/FormHead";
import "./CancelModal.css";
import { useState } from "react";

const CancelModal = ({
  open,
  setOpen,
  cancelVal,
  handleRequest,
  text,
  buttons,
  defaultValue,
}) => {
  const handleClose = () => {
    setOpen(false);
  };
  const [val, setVal] = useState(0);
  useEffect(()=>{
    setTimeout(() => {
      defaultValue > 0 && setVal(defaultValue);
    }, 1000);
  },[defaultValue])
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        className="cancel-modal"
      >
        <FormHead text={text[0]}></FormHead>
        <div className="cancel-modal-content">
          <TextFieldInput
            name="duration"
            id="duration"
            placeholder={text[1]}
            className="login-input"
            type="number"
            value={val}
            style={{ width: "180px" }}
            onChange={(e) => setVal(e?.target?.value)}
          />
          <Typography> {text[2]} </Typography>
        </div>
        <Stack
          flexDirection="row"
          alignItems="center"
          mt={3}
          gap={2}
          className="buttons-wrap"
        >
          <Button
            className="primary r-25px"
            onClick={(e) => handleRequest(e, val)}
          >
            {buttons[0]}
          </Button>

          <Button className="r-25px" onClick={() => setOpen(false)}>
            {" "}
            {buttons[1]}
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default CancelModal;
