import { Grid, Typography, Divider } from "@mui/material";
import TabPanel from "../../Atoms/Tabs/TabPanel.js";
import ListBranches from "../../Molecules/Branches/Lists/ListBranches.jsx";

const BranchesPanel = (props) => {
  const {
    options,
    BranchValue,
    updatePagination,
    branchesList,
    count,
    branchesPrivilages,
    page,
  } = props;

  return (
    <Grid container>
      {options.map((option, index) => (
        <Grid container key={index}>
          <TabPanel value={BranchValue} index={index} className="tab-panel">
            {index === 0 && (
              <Grid container>
                <ListBranches
                  updatePagination={updatePagination}
                  branchesList={branchesList}
                  count={count}
                  branchesPrivilages={branchesPrivilages}
                  page={page}
                />
              </Grid>
            )}
            {index === 1 && (
              <Grid container>
                <ListBranches
                  updatePagination={updatePagination}
                  branchesList={branchesList}
                  count={count}
                  branchesPrivilages={branchesPrivilages}
                  page={page}
                />
              </Grid>
            )}
            {index === 2 && (
              <Grid container>
                <ListBranches
                  updatePagination={updatePagination}
                  branchesList={branchesList}
                  count={count}
                  branchesPrivilages={branchesPrivilages}
                  page={page}
                />
              </Grid>
            )}
          </TabPanel>
        </Grid>
      ))}
    </Grid>
  );
};

export default BranchesPanel;
