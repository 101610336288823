import { Grid, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import "./style.css";
import { withTranslation } from "react-i18next";
import PrivateGuideReservationsTabs from "../../Molecules/PrivateGuideReservations/Tabs";
import PrivateGuideReservationsPanal from "../../Molecules/PrivateGuideReservations/Panel";

const PrivateGuideReservationsList = (props) => {
  const {
    t,
    activitesList,
    count,
    ordersPrivilages,
    setOpenLoader,
    setFilterObj,
    filterObj,
    joboffersPrivilages,
  } = props;
  const [ActivityValue, setActivityValue] = useState(0);

  const [searchKeywords, setSearchKeywords] = useState("");
  const [page, setPage] = useState(1);

  const updatePagination = (page) => {
    setFilterObj((s) => {
      return { ...s, page_number: page, keyword: searchKeywords };
    });
  };
  const getBranchType = (value) => {
    if (value === 0) {
      setPage(1);
      setFilterObj((s) => {
        return { ...s, page_number: 1, activity_type: "" };
      });
    }
    if (value === 1) {
      setPage(1);
      setFilterObj((s) => {
        return {
          ...s,
          page_number: 1,
          activity_type: "0",
        };
      });
    }
    if (value === 2) {
      setPage(1);
      setFilterObj((s) => {
        return {
          ...s,
          page_number: 1,
          activity_type: "1",
        };
      });
    }
    if (value === 3) {
      setPage(1);
      setFilterObj((s) => {
        return {
          ...s,
          page_number: 1,
          activity_type: "2",
        };
      });
    }
  };
  const getKeywords = (keyword) => {
    setSearchKeywords(keyword);
    setFilterObj((s) => {
      return { ...s, reservation_number: keyword };
    });
  };

  return (
    <Grid
      container
      className="activities-list-container"
      width="1700px"
    >
      <PrivateGuideReservationsTabs
        getBranchType={getBranchType}
        getKeywords={getKeywords}
        ordersPrivilages={ordersPrivilages}
        setFilterObj={setFilterObj}
        filterObj={filterObj}
        setActivityValue={setActivityValue}
      />
      <PrivateGuideReservationsPanal
        activitesList={activitesList}
        count={count}
        page={page}
        setPage={setPage}
        updatePagination={updatePagination}
        ordersPrivilages={ordersPrivilages}
        filterObj={filterObj}
        setOpenLoader={setOpenLoader}
        setFilterObj={setFilterObj}
        joboffersPrivilages={joboffersPrivilages}
      />
    </Grid>
  );
};

export default withTranslation()(PrivateGuideReservationsList);
