import { Grid, Typography } from "@mui/material";
import ActivityTabs from "../../Molecules/Activities/ActivityTabs.jsx";
import AddActivityPanel from "../../Molecules/Activities/AddActivityPanel.jsx";
import { useState, useRef, useEffect } from "react";
import "./ActivitiesList.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { withTranslation } from "react-i18next";

const AddActivityForm = (props) => {
  const {
    t,
    uploadActivityImages,
    supportedLanguages,
    branchesList,
    currencies,
    organizationsList,
    setOrganizationsList,
    certificatesList,
    setCertificatesList,
    languagesList,
    priceIncludesList,
    activityImages,
    removeActivityImage,
    getListOfPrices,
    createNewActivity,
    type,
    activityDetails,
    getOrganization,
    getLanguagesAPI,
    languagesCount,
    getBranches,
    getCertificates,
    branchesCount,
    certificatesCount,
    organizationsCount,
    profileData
  } = props;
  const [ActivityValue, setActivityValue] = useState(0);
  const handleFormSubmit = useRef(null);
  const navigate = useNavigate();

  let [searchParams, setSearchParams] = useSearchParams();

  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  const ActivitiesTypes = [
    {
      id: 1,
      label: t("activity.diveTrip"),
    },
    {
      id: 2,
      label: t("activity.courses"),
    },
    {
      id: 3,
      label: t("activity.liveaboard"),
    },
  ];
  const handleActivityValue = (event, newValue) => {
    setActivityValue(newValue);
    navigate(`${window.location.pathname}?type=${newValue}&page=${pageNumber}`);
  };
  useEffect(() => {
    const indxs = {
      live_aboard: 2,
      dive_trip: 0,
      dive_course: 1,
    };
    if (type == "edit")
      handleActivityValue("", indxs[activityDetails?.activity_type]);
  }, [type, activityDetails]);
  return (
    <Grid container className="add-activity-container">
      <ActivityTabs
        options={ActivitiesTypes}
        handleActivityValue={handleActivityValue}
        ActivityValue={ActivityValue}
        type={type}
        handleSubmit={() => handleFormSubmit.current()}
        activityType={activityDetails?.activity_type}
      />
      <AddActivityPanel
        options={ActivitiesTypes}
        ActivityValue={ActivityValue}
        uploadActivityImages={uploadActivityImages}
        supportedLanguages={supportedLanguages}
        handleFormSubmit={handleFormSubmit}
        branchesList={branchesList}
        branchesCount={branchesCount}
        certificatesCount={certificatesCount}
        currencies={currencies}
        organizationsList={organizationsList}
        setOrganizationsList={setOrganizationsList}
        getOrganization={getOrganization}
        organizationsCount={organizationsCount}
        getBranches={getBranches}
        certificatesList={certificatesList}
        setCertificatesList={setCertificatesList}
        languagesList={languagesList}
        getLanguagesAPI={getLanguagesAPI}
        languagesCount={languagesCount}
        priceIncludesList={priceIncludesList}
        activityImages={activityImages}
        removeActivityImage={removeActivityImage}
        getListOfPrices={getListOfPrices}
        createNewActivity={createNewActivity}
        type={type}
        activityDetails={activityDetails}
        getCertificates={getCertificates}
        profileData={profileData}
      />
    </Grid>
  );
};

export default withTranslation()(AddActivityForm);
