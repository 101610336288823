import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';

import passwordCheck from './../../../Assets/Images/passwordcheck.png';
import { CheckExists } from '../../../Contexts/APIs/ForgotPassword/forgetPassword';
import PrimaryButton from '../../Atoms/Buttons/Primary/PrimaryButton';
import TextFieldInput from '../../Atoms/Input/TextField/TextFieldInput';
import * as yup from "yup";
import ForgetPasswordHead from './ForgetPasswordHead';
import { useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import './ForgetPassword.css';


function CheckEmail({t}) {
    const navigate = useNavigate();

    const validationSchemaObject = yup.object({
        email: yup.string().trim().required(t("users.emailRequired")).email()
    });
        
    const formik = useFormik({
        initialValues: {            
            email: ''            
        },
        validationSchema: validationSchemaObject,
        onSubmit: (values) => {                        
            CheckExists({ "user": values }, navigate);
        }        
    });

  return (
    <Grid container item xl={4} lg={5} md={5} sm={11} xs={12} mt={16} mx={'auto'} className='password-form-wrap'>
        <ForgetPasswordHead imgSrc={passwordCheck} imgAlt='passwordCheck' text={t("forgotPassword.checkEmailHead")} />

        <Grid item xs={12} mt={10}>
            <form style={{ margin: "auto", width: "100%" }} className='password-form'>
                <Grid item xs={9} mb={2} mx={'auto'} width={'75%'}>
                    <TextFieldInput
                        name="email"
                        id="email"                            
                        placeholder={t("forgotPassword.emailPlaceholder")}
                        type="email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                </Grid>
                
                <PrimaryButton
                    text={t("forgotPassword.submit")}
                    classBtn="primary"
                    style={{ padding: "5px 50px", margin: "auto" }}
                    click={formik.handleSubmit}
                />
            </form>
        </Grid>
    </Grid>
  )
}

export default withTranslation() (CheckEmail)