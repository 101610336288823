import { Avatar, Divider, Rating, Stack, styled, Typography } from '@mui/material'
import React from 'react'
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import moment from 'moment';
const ReviewCard = ({ reviewData, visibleDivider }) => {
    const StyledRating = styled(Rating)({
        '& .MuiRating-iconFilled': {
            color: '#3ed181            ',
        },
        '& .MuiRating-iconHover': {
            color: '#ff3d47',
        },
    });
    return (

        <Stack>
            <Stack flexDirection='row' alignItems='center' justifyContent='space-between'>
                <Stack flexDirection='row' alignItems='center' gap={2}>
                    <Avatar />
                    <Stack>
                        <Typography fontSize='18px' fontWeight='bold'>
                        {reviewData?.reviewer_name}

                        </Typography>
                        <StyledRating
                            readOnly
                            name="customized-color"
                            value={parseFloat(reviewData?.rating)}
                            icon={<CircleIcon fontSize="inherit" style={{fontSize:'14px'}} />}
                            emptyIcon={<CircleOutlinedIcon fontSize="inherit" style={{ color: '#3ed181' ,fontSize:'14px'}} />}
                        />
                    </Stack>

                </Stack>
                <Typography color='#bebebe'>
                {moment((reviewData?.created_at)).format('DD MMM YYYY')}

                </Typography>
            </Stack>
            <Typography fontSize='18px' fontWeight='500' lineHeight='1.7' mt={2}>
            {reviewData?.comment}

            </Typography>
            {
                visibleDivider && <Divider style={{ marginBlock: '20px', width: '100%', background: '#d5d5d5' }} />
            }
        </Stack>



    )
}

export default ReviewCard