import { Grid, Typography, Divider } from "@mui/material";
import "./Form.css";

import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput.jsx";
import { withTranslation } from "react-i18next";


const LiveAboradOptions = (props) => {
    const { formik, t} = props;

    return (
        <Grid container lg={12} className="activity-form">
            <Grid container md={12}>
                <Typography
                    component="h1"
                    style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        borderBottom: "2px solid var(--primary-color)"
                    }}
                    className="mb-20px"
                >
                   {t('activity.liveaboardStartEndPoints')}
                </Typography>
            </Grid>

            <Grid container md={12} className="mb-20px">
                <Grid sm={5.8}>
                    <TextFieldInput
                        name="start_point"
                        id="start_point"
                        placeholder=  {t('activity.startPointLocation')}
                        className='login-input'
                        type="text"
                        value={formik.values.start_point}
                        error={formik.touched.start_point && Boolean(formik.errors.start_point)}
                        helperText={formik.touched.start_point && formik.errors.start_point}
                        onChange={formik.handleChange}
                    />
                </Grid>
                <Grid sm={0.4}></Grid>
                <Grid sm={5.8}>
                    <TextFieldInput
                        name="end_point"
                        id="end_point"
                        placeholder=  {t('activity.endPointLocation')}
                        className='login-input'
                        type="text"
                        value={formik.values.end_point}
                        error={formik.touched.end_point && Boolean(formik.errors.end_point)}
                        helperText={formik.touched.end_point && formik.errors.end_point}
                        onChange={formik.handleChange}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default withTranslation() (LiveAboradOptions)