import { axiosFn, handleError } from "../index"
import * as URL from "../URLs"

export async function Badges() {
    return await axiosFn("get", URL.lookups + '/badges?keyword=&page_number=1&page_size=1000')
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
