import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { icons } from "../../../Assets/AssetHelper";
import CustomSeparator from "../../Atoms/BreadCrumb";
import FreelancerDetailsHeader from "../../Molecules/Freelancers/FreelancerDetailsHeader";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckIcon from "@mui/icons-material/Check";
import Carousel from "../../Atoms/Carousel/Carousel";
import "./FreelancerDetails.css";
import moment from "moment";
import { withTranslation } from "react-i18next";
import FreelancerBreadcrumb from "./FreelancerBreadcrumb";
const FreelancerDetails = ({ jobOfferData, applicantData, t }) => {
  const lang = localStorage.getItem("lang");
  const info = [
    {
      title: t("branch.localGovernmentalLicense"),
      status: applicantData?.local_gov_license_number ? true : false,
    },
    {
      title: t("joboffer.divingInsurance"),
      status: applicantData?.diving_insurance_number ? true : false,
    },
    {
      title: t("joboffer.technicalCertificate"),
      status: applicantData?.certificates?.length > 0 ? true : false,
    },
    {
      title: t("joboffer.volunteerWork"),
      status: applicantData?.volunteer_work_participant,
    },
  ];
  const locationsResponsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const certificatesResponsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <Stack gap={3}>
      <Grid sm={12} container>
        <FreelancerBreadcrumb
          applicantData={applicantData}
          jobOfferData={jobOfferData}
        />
      </Grid>
      <FreelancerDetailsHeader
        data={applicantData}
        jobOfferData={jobOfferData}
      />
      <Stack
        mt="-25px"
        bgcoloe="white"
        padding="53.8px"
        className="details-panel"
        flexDirection="row"
        justifyContent="space-between"
        boxShadow="0 2px 15px 0 rgba(0, 0, 0, 0.1)"
      >
        <Stack gap={2} width="65%">
          {/* <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
            <Typography width="fit-content" className="mb-20px" component="h1">
              "Freelancer" info
            </Typography>

            <Stack
              flexDirection="row"
              flexWrap="wrap"
              alignItems="flex-start"
              justifyContent="space-between"
              gap={2}
            >
              <Stack width={{ xs: "100%", md: "40%" }}>
                <Typography fontSize="13px" color="#aaa" fontWeight="bold">
                  Id number
                </Typography>
                <Typography fontSize="18px" fontWeight="bold">
                  {applicantData?.id}
                </Typography>
              </Stack>
              <Stack width={{ xs: "100%", md: "40%" }}>
                <Typography fontSize="13px" color="#aaa" fontWeight="bold">
                  Freelancer Email
                </Typography>
                <Typography fontSize="18px" fontWeight="bold">
                  {applicantData?.email}
                </Typography>
              </Stack>

              <Stack width={{ xs: "100%", md: "40%" }}>
                <Typography fontSize="13px" color="#aaa" fontWeight="bold">
                  Freelancer Mobile
                </Typography>
                <Typography fontSize="18px" fontWeight="bold">
                  {applicantData?.country_code} {applicantData?.phone_number}
                </Typography>
              </Stack>
            </Stack>
          </Stack> */}
          <Stack
            bgcolor="rgba(0, 0, 0, 0.01)"
            borderRadius="10px"
            p={2}
            className="applicant-carousel"
          >
            <Typography width="fit-content" className="mb-20px" component="h1">
              {t("joboffer.WorkLocations")}
            </Typography>

            {applicantData?.work_locations?.length > 1 ? (
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={false}
                responsive={locationsResponsive}
                autoPlaySpeed={1000}
                containerClass={"carousel-location"}
                itemClass={"carousel-location-item"}
                partialVisible={true}
                arrows={false}
              >
                {applicantData?.work_locations?.map((data, index) => {
                  return (
                    <Stack
                      key={index}
                      flexDirection="row"
                      alignItems="center"
                      gap={2}
                      width="208px"
                    >
                      <Box position="relative" width="100%" height="100%">
                        <img
                          src={data?.area?.logo}
                          width="100%"
                          height="100%"
                          style={{ borderRadius: "15px" }}
                        />
                        <Stack
                          alignItems="center"
                          justifyContent="center"
                          width="100%"
                          position="absolute"
                          bottom="20px"
                        >
                          <Typography
                            fontSize="20px"
                            letterSpacing="2px"
                            color="white"
                            fontWeight="bold"
                            sx={{ textShadow: "0 2px 5px #00000033" }}
                          >
                            {data?.area?.city?.name}
                          </Typography>
                        </Stack>
                        {data?.is_current_location && (
                          <Stack
                            alignItems="center"
                            justifyContent="center"
                            position="absolute"
                            top="20px"
                            left="43px"
                            bgcolor="#faa100"
                            borderRadius="10px"
                            color="white"
                            p={0.5}
                            fontWeight="bold"
                          >
                            {t("joboffer.currentLocation")}
                          </Stack>
                        )}
                      </Box>
                    </Stack>
                  );
                })}
              </Carousel>
            ) : applicantData?.work_locations?.length == 1 ? (
              <Stack flexDirection="row" alignItems="center" gap={2}>
                <Box position="relative">
                  <img
                    src={applicantData?.work_locations[0].area?.logo}
                    width="208px"
                    height="149px"
                    style={{ borderRadius: "15px" }}
                  />
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    width="208px"
                    position="absolute"
                    bottom="20px"
                  >
                    <Typography
                      fontSize="20px"
                      letterSpacing="2px"
                      color="white"
                      fontWeight="bold"
                      sx={{ textShadow: "0 2px 5px #00000033" }}
                    >
                      {applicantData?.work_locations[0].area?.city?.name}
                    </Typography>
                  </Stack>
                  {applicantData?.work_locations[0].is_current_location && (
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      position="absolute"
                      top="20px"
                      left="43px"
                      bgcolor="#faa100"
                      borderRadius="10px"
                      color="white"
                      p={0.5}
                      fontWeight="bold"
                    >
                      {t("joboffer.currentLocation")}
                    </Stack>
                  )}
                </Box>
              </Stack>
            ) : null}
          </Stack>

          <Stack
            bgcolor="rgba(0, 0, 0, 0.01)"
            borderRadius="10px"
            p={2}
            className="applicant-carousel"
          >
            <Typography width="fit-content" className="mb-20px" component="h1">
              {t("joboffer.workCertificates")}
            </Typography>
            {applicantData?.certificates?.length > 1 ? (
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={false}
                responsive={certificatesResponsive}
                autoPlaySpeed={1000}
                containerClass={"carousel-certificate"}
                itemClass={"carousel-certificate-item"}
                partialVisible={true}
                arrows={true}
              >
                {applicantData?.certificates.map((data, index) => {
                  return (
                    <Stack
                      key={index}
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="10px"
                      textAlign="center"
                    >
                      <img
                        src={data?.image}
                        width="100%"
                        height="124px"
                        style={{ borderRadius: "15px" }}
                      />
                      <Typography
                        color="#333132"
                        fontSize="14px"
                        fontWeight="bold"
                        px={2}
                        mt="5px"
                      >
                        {data?.organization_certificate?.name}
                      </Typography>
                    </Stack>
                  );
                })}
              </Carousel>
            ) : applicantData?.certificates?.length === 1 ? (
              <Stack flexDirection="row" gap={1}>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="10px"
                >
                  <img
                    src={applicantData?.certificates[0]?.image}
                    width="178px"
                    height="124px"
                    style={{ borderRadius: "15px" }}
                  />
                  <Typography
                    color="#333132"
                    fontSize="14px"
                    fontWeight="bold"
                    mt="5px"
                  >
                    {
                      applicantData?.certificates[0]?.organization_certificate
                        ?.name
                    }
                  </Typography>
                </Stack>
              </Stack>
            ) : null}
          </Stack>

          {applicantData?.live_aboard_work_experience && (
            <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
              <Typography
                width="fit-content"
                className="mb-20px"
                component="h1"
              >
                {" "}
                <CheckOutlinedIcon style={{ color: "#f6b119" }} />{" "}
                {t("joboffer.liveaboardsWorkingExpreince")}
              </Typography>
              <Typography>
                {applicantData?.live_aboard_work_description}
              </Typography>
            </Stack>
          )}

          <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
            {applicantData?.about ? (
              <Stack>
                <Typography
                  width="fit-content"
                  className="mb-20px"
                  component="h1"
                  display="flex"
                  alignItems="center"
                >
                  {" "}
                  <CheckOutlinedIcon style={{ color: "#f6b119" }} />
                  {t("profile.about")}
                </Typography>
                <Typography>{applicantData?.about}</Typography>
              </Stack>
            ) : (
              <Stack>
                <Typography
                  width="fit-content"
                  className="mb-20px"
                  component="h1"
                >
                  <CloseOutlinedIcon style={{ color: "#e10007" }} />
                  {t("profile.about")}
                </Typography>
                <Typography style={{ color: "#e10007" }}>
                  {t("joboffer.noData")}
                </Typography>
              </Stack>
            )}
          </Stack>

          <Stack
            flexDirection="row"
            flexWrap="wrap"
            alignItems="flex-start"
            gap={8}
            p={2}
          >
            <Typography fontSize="18px" fontWeight="bold">
              {`${t("profile.createdAt")} : `}
              {moment(applicantData?.created_at).format("DD-MM-YYYY HH:MM")}
            </Typography>
            <Typography fontSize="18px" fontWeight="bold">
              {`${t("profile.updatedAt")} : `}
              {moment(applicantData?.updated_at).format("DD-MM-YYYY HH:MM")}
            </Typography>
          </Stack>
        </Stack>

        <Stack gap={2} width="30%">
          <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
            <Typography
              width="fit-content"
              className="mb-20px"
              component="h1"
              textTransform="capitalize"
            >
              {t("profile.language")}
            </Typography>

            <Grid container gap={1} flexWrap="wrap">
              <Grid item xs={12} lg={5.5}>
                {applicantData?.languages?.length > 4 ? (
                  <>
                    {applicantData?.languages?.map((lang, index) => {
                      return (
                        index <= 3 && (
                          <Typography
                            textTransform="capitalize"
                            display="flex"
                            alignItems="center"
                            flexWrap="wrap"
                            mb={1}
                            color="#333132"
                            fontWeight="bold"
                            className="details-text-bold"
                            key={index}
                          >
                            {lang?.language?.name}
                            <Typography
                              color="#bebebe"
                              width="fit-content"
                              maxWidth="50%"
                              ml="5px"
                              className="details-text"
                            >{`: ${lang?.language_fluency_level?.name}`}</Typography>
                          </Typography>
                        )
                      );
                    })}
                  </>
                ) : (
                  <>
                    {applicantData?.languages?.map((lang, index) => {
                      return (
                        <Typography
                          textTransform="capitalize"
                          display="flex"
                          alignItems="center"
                          flexWrap="wrap"
                          mb={1}
                          color="#333132"
                          fontWeight="bold"
                          className="details-text-bold"
                          key={index}
                        >
                          {lang?.language?.name}
                          <Typography
                            color="#bebebe"
                            width="fit-content"
                            maxWidth="50%"
                            ml="5px"
                            className="details-text"
                          >{`: ${lang?.language_fluency_level?.name}`}</Typography>
                        </Typography>
                      );
                    })}
                  </>
                )}
              </Grid>
              {applicantData?.languages?.length > 4 && (
                <Grid item xs={12} lg={5.5}>
                  {applicantData?.languages?.map((lang, index) => {
                    return (
                      index > 3 && (
                        <Typography
                          textTransform="capitalize"
                          display="flex"
                          alignItems="center"
                          flexWrap="wrap"
                          mb={1}
                          color="#333132"
                          fontWeight="bold"
                          className="details-text-bold"
                          key={index}
                        >
                          {lang?.language?.name}
                          <Typography
                            color="#bebebe"
                            width="fit-content"
                            maxWidth="50%"
                            ml="5px"
                            className="details-text"
                          >{`: ${lang?.language_fluency_level?.name}`}</Typography>
                        </Typography>
                      )
                    );
                  })}
                </Grid>
              )}
            </Grid>
          </Stack>
          <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
            <Typography width="fit-content" className="mb-20px" component="h1">
              {t("activity.information")}
            </Typography>

            {info?.map((data, index) => {
              return (
                <Stack flexDirection="row" gap={1} my={1} key={index}>
                  {data?.status ? (
                    <CheckIcon style={{ color: "#f6b119" }} />
                  ) : (
                    <CloseOutlinedIcon style={{ color: "#e10007" }} />
                  )}
                  <Typography fontWeight="bold">{data?.title}</Typography>
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default withTranslation()(FreelancerDetails);
