import { Grid, Typography, Divider } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomPagination from "../../../Atoms/Pagination/Pagination.js";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import { useEffect } from "react";

const ListActivitiesForReservations = (props) => {
  const { updatePagination, count, reservationsActivities, t } = props;
  let [searchParams, setSearchParams] = useSearchParams();

  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  const Navigate = useNavigate();
  const [page, setPage] = useState(1);
  const selectedLang = localStorage.getItem("lang");

  useEffect(() => {
    setPage(searchParams.get("page"));
    updatePagination(searchParams.get("page"));
  }, [searchParams.get("page")]);

  return (
    <Grid container>
      <Grid container className="table-header mb-20px">
        <Grid md={1} textAlign="center">
          {t("reservations.id")}d
        </Grid>
        <Grid md={2}>{t("reservations.activityName")}</Grid>
        <Grid sm={2}>{t("reservations.branch")}</Grid>
        <Grid sm={2}>{t("reservations.activityGroups")}</Grid>
        <Grid sm={2}>{t("reservations.type")}</Grid>
      </Grid>
      {reservationsActivities?.length === 0 && (
        <Grid container display="block" textAlign="center">
          {t("offer.thereAreNoRecords")}
        </Grid>
      )}
      {reservationsActivities?.map((activityItem, index) => (
        <Grid
          key={index}
          onClick={() =>
            Navigate(
              `/reservations/activities/activity-groups/${activityItem?.id}?page=${pageNumber}`
            )
          }
          container
          lg={12}
          className="activity-item mb-20px"
        >
          <Grid md={1} textAlign="center">
            {activityItem?.id}
          </Grid>
          <Grid md={2}>
            {" "}
            {activityItem?.localized_data?.[selectedLang]?.name || "-"}
          </Grid>
          <Grid sm={2}>{activityItem?.branch?.name}</Grid>
          <Grid sm={2}>{activityItem?.number_of_groups}</Grid>
          <Grid sm={2}>{activityItem?.activity_type.replace("_", " ")}</Grid>
        </Grid>
      ))}
      {count > 10 && (
        <Grid container justifyContent="center" mb={7.5} mt={5}>
          <CustomPagination
            count={Math.ceil(count / 10)}
            size="large"
            shape="rounded"
            page={page}
            className="pagination"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default withTranslation()(ListActivitiesForReservations);
