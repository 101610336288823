import {
  Grid,
  Typography,
  Divider,
  Menu,
  MenuItem,
  Button,
  Stack,
} from "@mui/material";
import TextFieldInput from "../../Atoms/Input/TextField/TextFieldInput.jsx";
import SearchIcon from "@mui/icons-material/Search";
import { withTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SelectBox from "../../Atoms/Select/Select.jsx";
import BasicDatePicker from "../../Atoms/Input/DatePicker/DatePicker.js";
import dayjs from "dayjs";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import { ScanQrCode } from "../../../Contexts/APIs/General/scan-code.js";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import ScanQrcodeModel from "../../Molecules/JobOffers/Models/qrCode-scan.jsx";
import { getDiveExpertList } from "../../../Contexts/APIs/RequestedActivities/Activities.js";

const PrivateGuideReservationsTabs = (props) => {
  const {
    t,
    getKeywords,

    setFilterObj,
    filterObj,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isOpen, setOpen] = useState(false);

  const [payment, setPayment] = useState(null);
  const [diveExpertList, setDiveExpertList] = useState([]);
  const [name, setName] = useState({
    label: "All",
    value: "all",
  });
  const [activityType, setActivityType] = useState(null);

  const open = Boolean(anchorEl);
  const getDiveExpert = async () => {
    const { res, err } = await getDiveExpertList({ page_size: 100 });
    if (res) {
      setDiveExpertList(res?.data?.dive_experts);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    getDiveExpert();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const ActivitiesTypes = [
    {
      id: 0,
      name: t("activity.all"),
    },
    {
      id: "dive_trip",
      name: t("activity.diveTrip"),
    },
    {
      id: "dive_course",
      name: t("activity.courses"),
    },
    {
      id: "live_aboard",
      name: t("activity.liveaboard"),
    },
  ];
  const PaymentTypes = [
    {
      id: 0,
      name: t("activity.all"),
    },
    {
      id: true,
      name: t("reservations.paid"),
    },
    {
      id: false,
      name: t("reservations.notPaid"),
    },
  ];
  const updateStartDate = (date) => {
    setFilterObj({
      ...filterObj,
      page_number: 1,
      ...date,
    });
  };
  const updateEndDate = (date) => {
    setFilterObj({
      ...filterObj,
      page_number: 1,
      ...date,
    });
  };

  const handleEndDate = (end_date) => {
    if (!end_date?.includes("Invalid")) {
      setEndDate(end_date);
      updateEndDate({
        reservation_date_to:
          end_date != null ? dayjs(end_date).format("YYYY-MM-DD") : null,
      });
    }
  };

  const handleStartDate = (start_date) => {
    if (!start_date?.includes("Invalid")) {
      setStartDate(start_date);
      updateStartDate({
        reservation_date_from:
          start_date != null ? dayjs(start_date).format("YYYY-MM-DD") : null,
      });
    }
  };
  const handelScanCode = (code) => {
    const action = () => {
      setOpen(false);
      window.location.reload();
    };
    ScanQrCode({ qr_code: code }, action);
  };

  const handelReset = () => {
    setName({
      label: "all",
      value: "all",
    });
    setActivityType("0");
    setPayment("0");
    handleClose();
    setFilterObj((s) => {
      return {
        owner_type: "DiveExpert",
        page_number: 1,
        sort_by: "id",
      };
    });
  };
  const handelApply = () => {
    setFilterObj((s) => {
      return {
        ...s,
        dive_expert_id: name?.value == "all" ? null : name?.value,
        is_paid: payment,
        activity_type: activityType,
      };
    });
  };
  const diveExpertListOptions = diveExpertList?.map((data) => {
    return {
      name: `${data?.firstname} ${data?.lastname}`,
      id: data?.id,
    };
  });
  return (
    <Stack flexDirection="row" gap={3} width="100%">
      <Stack flexDirection="row" gap={3} width="50%" alignItems="center">
        <Grid item flexDirection="row">
          <Typography
            component="h1"
            style={{ fontWeight: "bold", fontSize: "30px" }}
          >
            {t("reservations.PrivateGuideResrvations")}
          </Typography>
        </Grid>
        <Grid item>
          <TextFieldInput
            onChange={(e) => getKeywords(e?.target?.value)}
            type="search"
            placeholder={t("reservations.searchReservation")}
            StartAdornment={<SearchIcon />}
            className="searchReservation"
            sx={{ padding: "0px!important" }}
          />
        </Grid>
      </Stack>
      <Stack
        container
        width="50%"
        gap={1}
        flexDirection="row"
        justifyContent="end"
        alignItems="center"
      >
        <Grid item xs={4} pt={1}>
          <BasicDatePicker
            value={startDate}
            setValue={handleStartDate}
            placeholder={t("reservations.from")}
            className="border-r-10px basic-date-picker"
            createLis={false}
          />
        </Grid>
        <Grid item xs={4} pt={1}>
          <BasicDatePicker
            value={endDate}
            setValue={handleEndDate}
            placeholder={t("reservations.to")}
            className="border-r-10px basic-date-picker"
            createLis={false}
          />
        </Grid>
        <Grid item>
          <Button
            id="basic-button"
            variant="outlined"
            sx={{ color: "black", borderColor: "#BEBEBE", height: "55px" }}
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            startIcon={<FilterAltIcon color="#BEBEBE" />}
          >
            {t("diveExpertActivity.filter")}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{
              "& .MuiList-root": {
                width: "270px",
              },
            }}
          >
            <Typography color="#333132" fontSize="16px" fontWeight="500" p={1}>
              {t("diveExpertActivity.filterBy")}
            </Typography>
            <Divider w="100%" />
            <MenuItem
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              {" "}
              <Typography color="#333132" fontSize="16px">
                {t("diveExpertActivity.diveExpertName")}
              </Typography>
              <SelectBox
                style={{ width: "100%" }}
                id="Status"
                name="Status"
                items={[{ id: "all", name: "All" }, ...diveExpertListOptions]}
                className="select"
                value={name?.label}
                placeholder="select"
                onchange={(e) =>
                  setName({
                    label: e.target.value,
                    value: e.target.value == "all" ? 0 : e.target.value,
                  })
                }
              />
            </MenuItem>
            <MenuItem
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              {" "}
              <Typography color="#333132" fontSize="16px">
                {t("diveExpertActivity.ActivityType")}
              </Typography>
              <SelectBox
                style={{ width: "100%" }}
                id="ActivityType"
                name="ActivityType"
                items={ActivitiesTypes}
                className="select"
                placeholder="select"
                value={activityType}
                onchange={(e) => {
                  setActivityType(e.target.value);
                  // setActivityValue(e.target.value);
                }}
              />
            </MenuItem>

            <MenuItem
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              {" "}
              <Typography color="#333132" fontSize="16px">
                {t("reservations.payment")}
              </Typography>
              <SelectBox
                style={{ width: "100%" }}
                id="ActivityType"
                name="ActivityType"
                items={PaymentTypes}
                placeholder="select"
                value={payment}
                className="select"
                onchange={(e) => {
                  setPayment(e.target.value);
                }}
              />
            </MenuItem>
            <MenuItem
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                gap: 2,
              }}
            >
              <Button
                onClick={handelReset}
                style={{
                  borderRadius: "26px",
                  color: "black",
                  border: "1px solid #bebebe",
                  width: "50%",
                  padding: "10px",
                }}
              >
                {t('general.reset')}
              </Button>
              <PrimaryButton
                classBtn="primary r-25px"
                text={t('general.apply')}
                click={handelApply}
                style={{
                  width: "50%",
                  padding: "10px",
                }}
              />
            </MenuItem>
          </Menu>
        </Grid>
        <PrimaryButton
          fontSize="13px"
          endIcon={<QrCodeScannerIcon />}
          text={t("reservations.scanQRCustomer")}
          style={{ padding: "8px 10px", height: "48px" }}
          classBtn="primary r-25px"
          click={() => setOpen(true)}
        />
        {isOpen && (
          <ScanQrcodeModel
            open={isOpen}
            setOpen={setOpen}
            handelScanCode={handelScanCode}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default withTranslation()(PrivateGuideReservationsTabs);
