import { Grid, Stack, Typography } from "@mui/material";
import { icons, images } from "../../../Assets/AssetHelper.js";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import { useNavigate, NavLink } from "react-router-dom";
import "./Layout.css";
import { useState } from "react";
import TermsAndConditionsModel from "../../Molecules/General/Models/TermsAndCon.jsx";
import PrivacyPolicyModel from "../../Molecules/General/Models/PrivacyPolicy.jsx";
import CovidModel from "../../Molecules/General/Models/Covid19.jsx";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { withTranslation } from "react-i18next";

const SideBar = (props) => {
  const {
    t,
    canReadActivities,
    canReadBranches,
    canReadOffers,
    canReadOrders,
    canReadFreelancers,
    canReadJobOffers,
    canReadUsers,
    canReadSalesmen,
    canReadSalesmenReservations,
    canReadSalesmenInhouseActivities,
    canReadSalesmenStatistics,
    salesmanFeature,
    reguestsPrivallage,
    diveExpertActivityRequests,
  } = props;
  const Navigate = useNavigate();
  const [reservationsSubLinks, setReservationsSubLinks] = useState(null);
  const [openTerms, setOpenTerms] = useState(false);
  const [openPrivacy, setOpenPrivacy] = useState(false);
  const [openCovid, setOpenCovid] = useState(false);
  const userType = localStorage.getItem("userType");
  const openReservationsSubLinks = (i) => setReservationsSubLinks(i);
  const ButtonsGroup = [
    {
      text: t("profile.dashboard"),
      icon: <img src={icons.HomeIcon} alt="Dashboard" />,
      action: "/",
      isAllowed: true,
    },
    {
      text: t("branch.branches"),
      icon: <img src={icons.Branches} alt="Branches" />,
      action: "/branches?page=1",
      isAllowed: canReadBranches ?? true,
    },
    {
      text: t("activity.activities"),
      icon: <img src={icons.Activity} alt="Activities" />,
      action: "/activities?page=1",
      isAllowed: canReadActivities ?? true,
    },

    {
      text: t("sidebar.privateGuideActivites"),
      icon: <img src={icons.ApprovedActivity} alt="ApprovedActivity" />,
      action: "/requested-activites?page=1",
      subLinks: [
        {
          link: t("diveExpertActivity.requestedActivites"),
          action: "/requested-activites?page=1",
          isAllowed: diveExpertActivityRequests ?? true,
        },
        {
          link: t("diveExpertActivity.approvedActivites"),
          action: "/approved-activites?page=1",
          isAllowed: diveExpertActivityRequests ?? true,
        },
      ],
      isAllowed: diveExpertActivityRequests ?? true,
    },
    {
      text: t("activity.reservations"),
      icon: <img src={icons.Reservations} alt="" />,
      action: "/reservations?page=1",
      subLinks: [
        {
          link: t("activity.reservations"),
          action: "/reservations?page=1",
          isAllowed: canReadOrders ?? true,
        },
        {
          link: t("sidebar.privateGuideReservations"),
          action: "/private-guide-reservations?page=1",
          isAllowed: canReadOrders ?? true,
        },
      ],
      isAllowed: canReadOrders ?? true,
    },

    {
      text: t("joboffer.freelancers"),
      icon: <img src={icons.Freelancers} alt="Freelancers" />,
      action: "/freelancers",
      subLinks: [
        {
          link: t("profile.jobOffers"),
          action: "/freelancers/job-offers?page=1",
          isAllowed: canReadJobOffers ?? true,
        },
        {
          link: t("sidebar.hiredFreelancer"),
          action: "/freelancers/list?page=1",
          isAllowed: canReadFreelancers ?? true,
        },
      ],
      isAllowed: (canReadJobOffers || canReadFreelancers) ?? true,
    },

    {
      text: t("offer.offers"),
      icon: <img src={icons.Offers} alt="Users" />,
      action: "/offers?page=1",
      isAllowed: canReadOffers ?? true,
    },
    {
      text: t("salesmen.salesmen"),
      icon: <img src={icons.Freelancers} alt="Freelancers" />,
      action: canReadSalesmenStatistics
        ? "/salesmen/dashboard?page=1"
        : "/salesmen/salesmen-list?page=1",
      subLinks: [
        {
          link: t("sidebar.salesmenDashboard"),
          action: "/salesmen/dashboard?page=1",
          isAllowed: canReadSalesmenStatistics ?? true,
        },
        {
          link: t("activity.reservations"),
          action: "/salesmen/reservations?page=1",
          isAllowed: canReadSalesmenReservations ?? true,
        },
        {
          link: t("salesmen.salesmenList"),
          action: "/salesmen/salesmen-list?page=1",
          isAllowed: canReadSalesmen ?? true,
        },
        {
          link: t("sidebar.inHouseActivities"),
          action: "/salesmen/in-house-activities?page=1",
          isAllowed: canReadSalesmenInhouseActivities ?? true,
        },
      ],
      isAllowed:
        !canReadSalesmen &&
        !canReadSalesmenInhouseActivities &&
        !canReadSalesmenReservations &&
        !canReadSalesmenStatistics
          ? false
          : (salesmanFeature &&
              (canReadSalesmen ||
                canReadSalesmenInhouseActivities ||
                canReadSalesmenReservations ||
                canReadSalesmenStatistics)) ??
            true,
    },

    {
      text: "Users",
      icon: <img src={icons.Users} alt="Users" />,
      action: "/users?page=1",
      isAllowed: canReadUsers ?? true,
    },

    {
      text: t("profile.wallet"),
      icon: <img src={icons.Wallet} alt="Wallet" />,
      action: "/wallet?page=1",
      isAllowed: true,
    },

    {
      text: t("help.help"),
      icon: <img src={icons.HelpQuestion} alt="" />,
      action: "/help",
      subLinks: [
        {
          link: t("sidebar.customerSupport"),
          action: "/help/customer-support",
          isAllowed: true,
        },
        {
          link: t("sidebar.reportAppIssue"),
          action: "/help/report-app-issue",
          isAllowed: true,
        },
        {
          link: t("help.faq"),
          action: "/help/faq",
          isAllowed: true,
        },
      ],
      isAllowed: true,
    },
  ];

  const TermsButtons = [
    {
      text: t("sidebar.termsAndConditions"),
      icon: <img src={icons.Terms} alt="Terms" />,
      action: () => setOpenTerms(true),
    },
    {
      text: t("sidebar.privacyPolicy"),
      icon: <img src={icons.PrivacyPolicy} alt="Privacy" />,
      action: () => setOpenPrivacy(true),
    },
    // {
    //     text: "Help",
    //     icon: <img src={icons.Help} alt="Help" />,
    //     action: "/"
    // },
    {
      text: t("sidebar.covid-19Regulations"),
      icon: <img src={icons.Covid} alt="Covid" />,
      action: () => setOpenCovid(true),
    },
  ];
  return props.open ? (
    <Grid
      container
      item
      xs={3}
      md={2.5}
      xl={1.7}
      className="side-bar-container"
      style={{
        height: "100%",
        position: "fixed",
        top: "0px",
        overflow: "auto",
        overflowX: "hidden",
      }}
    >
      <Grid style={{ margin: " 0 auto", padding: "5px" }}>
        <Grid container className="mb-20px">
          <Grid item sm={2}>
            <PrimaryButton
              btnType="icon"
              startIcon={<img src={icons.SideIcon} alt="side menu" />}
              click={() => props.setOpen(false)}
            />
          </Grid>
          <Grid item sm={10} style={{ textAlign: "center" }}>
            <img src={images.HeaderLogo} alt="Logo" width="85%" />
          </Grid>
        </Grid>
        <Grid container className="mb-30px" p={1}>
          {ButtonsGroup?.map((btn, index) =>
            btn.isAllowed ? (
              <NavLink
                className="side-bar-btn mb-10px"
                to={btn.action}
                key={index}
              >
                <PrimaryButton
                  classBtn={btn?.subLinks?.length > 0 ? "button-menu" : ""}
                  startIcon={btn.icon}
                  endIcon={
                    btn?.subLinks?.length > 0 &&
                    reservationsSubLinks === index ? (
                      <ExpandLessIcon />
                    ) : btn?.subLinks?.length > 0 &&
                      reservationsSubLinks !== index ? (
                      <ExpandMoreIcon />
                    ) : null
                  }
                  text={btn.text}
                  style={{
                    color: "#fff",
                    fontSize: "16px",
                    lineHeight: "2.5",
                    width: "100%",
                    justifyContent: "start",
                    textTransform: "capitalize",
                  }}
                  click={() => openReservationsSubLinks(index)}
                />
                {btn?.subLinks?.length > 0 &&
                  reservationsSubLinks === index &&
                  btn?.subLinks?.map((item) =>
                    item?.isAllowed ? (
                      <NavLink className="sub-link-active" to={item.action}>
                        <PrimaryButton
                          style={{ textTransform: "capitalize" }}
                          text={item.link}
                          classBtn="sub-link"
                        />
                      </NavLink>
                    ) : (
                      <></>
                    )
                  )}
                {/* {btn.subLinks && reservationsSubLinks === index && (
                                <Stack  >
                                    <NavLink className="sub-link-active" to={btn.action1}>
                                        <PrimaryButton
                                            style={{ textAlign: 'center' }}
                                            text={btn.link1}
                                            classBtn="sub-link"
                                        />
                                    </NavLink>
                                    <NavLink className="sub-link-active" to={btn.action2}>
                                        <PrimaryButton
                                            text={btn.link2}
                                            classBtn="sub-link"
                                        />
                                    </NavLink>
                                    {btn?.action3 && <NavLink className="sub-link-active" to={btn?.action3}>
                                        <PrimaryButton
                                            text={btn?.link3}
                                            classBtn="sub-link"
                                        />
                                    </NavLink>}
                                </Stack>
                            )} */}
              </NavLink>
            ) : (
              <></>
            )
          )}
        </Grid>
        <Grid container className="mb-30px">
          {TermsButtons?.map((btn, i) => (
            <PrimaryButton
              fontSize={"14px"}
              startIcon={btn.icon}
              text={btn.text}
              classBtn="terms-btns mb-10px"
              click={btn.action}
              key={i}
            />
          ))}
        </Grid>
        {openTerms && (
          <TermsAndConditionsModel open={openTerms} setOpen={setOpenTerms} />
        )}
        {openPrivacy && (
          <PrivacyPolicyModel open={openPrivacy} setOpen={setOpenPrivacy} />
        )}
        {openCovid && <CovidModel setOpen={setOpenCovid} open={openCovid} />}
      </Grid>
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
          width: "100%",
          mt: "auto",
          mb: "0px",
          fontSize: "12px",
          color: "#aeaeae",
        }}
      >
        Developed by
        <img src={images.InovaLogo} alt="side menu" />
      </Typography>
    </Grid>
  ) : (
    <Grid container className="side-bar-container" style={{ height: "100%" }}>
      <Grid item xs={12}>
        <PrimaryButton
          btnType="icon"
          startIcon={<img src={icons.SideIcon} alt="side menu" />}
          click={() => props.setOpen(true)}
          style={{ position: "fixed" }}
        />
      </Grid>
    </Grid>
  );
};

export default withTranslation()(SideBar);
