import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Box, Button, Grid, Menu, MenuItem, Typography } from "@mui/material";
import { withTranslation } from "react-i18next";

function JobOfferlist({
  t,
  offer,
  loadingStatus,
  selectedData,
  joboffersPrivilages,
  setOpenRateModel,
  setOpenCompleteModel,
  jobRequestsPrivilages,
  setOpenDeleteModel,
}) {
  let [searchParams, setSearchParams] = useSearchParams();
  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");

  const lang = localStorage.getItem("lang");
  const userType = localStorage.getItem("userType");

  const statueColor = {
    pending: "#faa100",
    hired: "#3ed181",
    qr_scanned: "#00b906",
    completed: "#00b906",
    deleted: "#e10007",
  };
  const activityType = {
    dive_trip: "Dive trip",
    dive_course: "Course",
    live_aboard: "Live Aboard",
  };
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // console.log(offer);

  const pendingOptions =
    userType !== "descendant_user" ||
    (userType === "descendant_user" && joboffersPrivilages?.can_delete)
      ? [
          {
            title: t("joboffer.deleteJobOffer"),
            action: () => setOpenDeleteModel(true),
          },
        ]
      : [];

  const completedOptions =
    (userType !== "descendant_user" &&
      offer &&
      offer?.accepted_job_request?.dive_center_review === null) ||
    (userType === "descendant_user" &&
      joboffersPrivilages?.can_update &&
      offer &&
      offer?.accepted_job_request?.dive_center_review === null)
      ? [
          {
            title: "Rate freelancer",
            action: (review) => setOpenRateModel(true),
          },
        ]
      : [];

  const scannedOptions =
    userType !== "descendant_user" ||
    (userType === "descendant_user" && joboffersPrivilages?.can_update)
      ? [
          {
            title: "Mark as completed",
            action: () => setOpenCompleteModel(true),
          },
        ]
      : [];

  const menuOptions = {
    pending: pendingOptions,
    completed: completedOptions,
    qr_scanned: scannedOptions,
  };

  return (
    <Grid
      container
      lg={12}
      className="activity-item mb-20px"
      key={offer?.id}
      alignItems="center"
    >
      <Grid md={1} px={2}>
        {offer?.id}
      </Grid>
      <Grid
        md={1.2}
        onClick={() =>
          navigate(
            "/freelancers/job-offers/" + offer?.id + "?page=" + pageNumber
          )
        }
      >
        <Typography
          sx={{
            overflowWrap: "break-word",
            fontSize: "20px",
            fontWeight: "bold",
            width: "113px",
          }}
        >
          {offer?.localized_data[lang]?.title}
        </Typography>
      </Grid>
      <Grid sm={1.5} textTransform="capitalize">
        {offer?.dive_expert_professional_level?.replace("_", " ")}
      </Grid>
      <Grid sm={1.2}>{activityType[offer?.activity_type]}</Grid>
      <Grid sm={1.2}>
        {offer?.rate} {offer?.currency?.lookup_key}
      </Grid>
      <Grid sm={1.2} width="70px">
        {(offer?.reach_x_applicants === true ||
          offer?.reach_favorite_first === true) &&
          "Yes"}
        {offer?.reach_x_applicants === false &&
          offer?.reach_favorite_first === false &&
          "No"}
      </Grid>
      <Grid sm={1.2}>{offer?.is_high_priority ? "Yes" : "No"}</Grid>
      {!loadingStatus && (
        <Grid
          sm={1}
          textTransform="capitalize"
          color={statueColor[offer?.status]}
        >
          {offer?.status == "qr_scanned" ? "Scanned QR" : offer?.status}
        </Grid>
      )}{" "}
      <Grid sm={1.5}>
        <Typography
          style={{ textDecoration: "underline" }}
          onClick={() =>
            userType !== "descendant_user" ||
            (userType === "descendant_user" && jobRequestsPrivilages?.can_read)
              ? navigate(
                  `/freelancers/jop-offers-applicants/${offer?.id}?page=${pageNumber}`
                )
              : null
          }
        >
          {offer?.number_of_applicants} applicant
        </Typography>
        {offer?.accepted_job_request?.dive_expert?.firstname && (
          <Typography
            onClick={() =>
              userType !== "descendant_user" ||
              (userType === "descendant_user" &&
                jobRequestsPrivilages?.can_read)
                ? navigate(
                    `/freelancers/job-offers/${offer?.id}/applicant/${offer?.accepted_job_request?.dive_expert?.id}?page=${pageNumber}`
                  )
                : null
            }
            fontSize="16px"
            style={{ textDecoration: "underline" }}
            color="#272726"
            textTransform="capitalize"
          >
            {offer?.accepted_job_request?.dive_expert?.firstname} (
            {offer?.status == "qr_scanned" ? "Scanned QR" : offer?.status})
          </Typography>
        )}
      </Grid>
      {menuOptions[offer?.status]?.length > 0 && (
        <Grid sm={1}>
          <Button
            id="demo-positioned-button"
            aria-controls={open ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <MoreHorizIcon style={{ color: "black" }} />
          </Button>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {menuOptions[selectedData?.offer?.status]?.map((val, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={() => {
                    val?.action();
                    handleClose();
                  }}
                >
                  {val?.title}
                </MenuItem>
              );
            })}
          </Menu>
        </Grid>
      )}
    </Grid>
  );
}

export default withTranslation()(JobOfferlist);
