import { Grid, Stack, Typography } from "@mui/material";
import React, { Fragment } from "react";
import TextFieldInput from "../../../../Atoms/Input/TextField/TextFieldInput";
import SelectBox from "../../../../Atoms/Select/Select";
import { withTranslation } from "react-i18next";
import SearchSelect from "../../../../Atoms/Select/SearchSelect";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import SearchableSelect from "../../../../Atoms/Select/SearchableSelect";

const BankAccountDetails = ({
  formik,
  currencies,
  cities,
  countries,
  isShadow = true,
  style,
  t,
  getCountries,
  getCities,
  countriesCount,
  citiesCount,
  currenciesCount,
  countryDefaultValue,
  cityDefaultValue,
  currencyDefaultValue,
  setCities,
  getCurrencies,
  isUpdateBankDetailsModal
}) => {
  return (
    <Grid
      container
      className={isShadow ? "activity-form" : ""}
      gap={2}
      style={style}
    >
      <Typography
        component="h1"
        style={{
          textAlign: "center",
          fontSize: "20px",
          fontWeight: "bold",
          borderBottom: "2px solid var(--primary-color)",
        }}
        className="mb-20px"
      >
        {`${t("profile.bankaccountdetails")} (${t("activity.optional")})`}
      </Typography>

      <Stack flexDirection="row" width="100%" gap={2}>
        <Grid item xs={12}>
          <SearchableSelect
            name="bank_account_attributes.country_id"
            options={countries}
            label={t("profile.payoutCountry")}
            count={countriesCount}
            className="searchable-select"
            getList={getCountries}
            handelMenuOpen={countries?.length == 0 && getCountries}
            defaultValues={countryDefaultValue}
            error={
              formik?.touched?.bank_account_attributes?.country_id &&
              Boolean(formik?.errors?.bank_account_attributes?.country_id)
            }
            helperText={
              formik?.touched?.bank_account_attributes?.country_id && formik?.errors?.bank_account_attributes?.country_id
            }
            onChange={(val) => {
              formik.setValues({
                ...formik?.values,
                bank_account_attributes: {
                  ...formik?.values?.bank_account_attributes,
                  country_id: val?.value,
                  country_name: val?.label,
                  city_id: "",
                  city_name: ""
                },
              });
              setCities([]);
            }}
            onClear={() => {
              formik.setValues({
                ...formik?.values,
                bank_account_attributes: {
                  ...formik?.values?.bank_account_attributes,
                  country_id: "",
                  country_name: "",
                  city_id: "",
                  city_name: ""
                },
              });
              getCountries({page_size: 10, page_number: 1});
              setCities([]);
            }}
            value={{
              value: formik?.values?.bank_account_attributes?.country_id,
              label: formik?.values?.bank_account_attributes?.country_name,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <SearchableSelect
            name="bank_account_attributes.city_id"
            options={cities}
            label={t("profile.payoutProvince")}            
            count={citiesCount}
            className="searchable-select"
            getList={getCities}
            moreFilterData={{
              country_id: formik?.values?.bank_account_attributes?.country_id,
            }}
            handelMenuOpen={cities?.length == 0 && getCities}
            defaultValues={cityDefaultValue}
            error={
              formik?.touched?.bank_account_attributes?.city_id &&
              Boolean(formik?.errors?.bank_account_attributes?.city_id)
            }
            helperText={
              formik?.touched?.bank_account_attributes?.city_id && formik?.errors?.bank_account_attributes?.city_id
            }
            onChange={(val) => {
              formik.setValues({
                ...formik?.values,
                bank_account_attributes: {
                  ...formik?.values?.bank_account_attributes,
                  city_id: val?.value,
                  city_name: val?.label
                },
              })    
            }}
            onClear={() => {
              formik.setValues({
                ...formik?.values,
                bank_account_attributes: {
                  ...formik?.values?.bank_account_attributes,
                  city_id: "",
                  city_name: ""
                },
              });
              getCities({page_size: 10, page_number: 1, country_id: formik?.values?.bank_account_attributes?.country_id});
            }}
            value={{
              value: formik?.values?.bank_account_attributes?.city_id,
              label: formik?.values?.bank_account_attributes?.city_name,
            }}
          />
        </Grid>
      </Stack>
      <Stack flexDirection="row" width="100%" gap={2}>
        <TextFieldInput
          name="bank_account_attributes.bank_name"
          id="bank_name"
          placeholder={t("profile.bankName")}
          className="login-input"
          type="text"
          onChange={formik?.handleChange}
          value={formik?.values?.bank_account_attributes?.bank_name}
          error={
            formik?.touched?.bank_account_attributes?.bank_name &&
            Boolean(formik?.errors?.bank_account_attributes?.bank_name)
          }
          helperText={
            formik?.touched?.bank_account_attributes?.bank_name &&
            formik?.errors?.bank_account_attributes?.bank_name
          }
          clickEnter={formik.handleSubmit}
        />
        <TextFieldInput
          name="bank_account_attributes.bank_address"
          id="bank_address"
          placeholder={t("profile.bankAddress")}
          className="login-input"
          type="text"
          onChange={formik?.handleChange}
          value={formik?.values?.bank_account_attributes?.bank_address}
          error={
            formik?.touched?.bank_account_attributes?.bank_address &&
            Boolean(formik?.errors?.bank_account_attributes?.bank_address)
          }
          helperText={
            formik?.touched?.bank_account_attributes?.bank_address &&
            formik?.errors?.bank_account_attributes?.bank_address
          }
          clickEnter={formik?.handleSubmit}
        />
      </Stack>
      <Stack flexDirection="row" width="100%" gap={2}>
        <TextFieldInput
          name="bank_account_attributes.swift_code"
          id="swift_code"
          placeholder={t("profile.swiftCode")}
          className="login-input "
          type="text"
          onChange={formik?.handleChange}
          value={formik?.values?.bank_account_attributes?.swift_code}
          error={
            formik?.touched?.bank_account_attributes?.swift_code &&
            Boolean(formik?.errors?.bank_account_attributes?.swift_code)
          }
          helperText={
            formik?.touched?.bank_account_attributes?.swift_code &&
            formik?.errors?.bank_account_attributes?.swift_code
          }
          clickEnter={formik?.handleSubmit}
        />
        <TextFieldInput
          name="bank_account_attributes.iban"
          id="iban"
          placeholder={t("profile.IBAN")}
          className="login-input "
          type="text"
          onChange={formik?.handleChange}
          value={formik?.values?.bank_account_attributes?.iban}
          error={
            formik?.touched?.bank_account_attributes?.iban &&
            Boolean(formik?.errors?.bank_account_attributes?.iban)
          }
          helperText={
            formik?.touched?.bank_account_attributes?.iban &&
            formik?.errors?.bank_account_attributes?.iban
          }
          clickEnter={formik?.handleSubmit}
        />
      </Stack>
      <Stack flexDirection="row" width="100%" gap={2}>
        <TextFieldInput
          name="bank_account_attributes.beneficiary_account_number"
          id="beneficiary_account_number"
          placeholder={t("profile.beneficiaryAccountNumber")}
          className="login-input "
          type="text"
          onChange={formik?.handleChange}
          value={
            formik?.values?.bank_account_attributes?.beneficiary_account_number
          }
          error={
            formik?.touched?.bank_account_attributes
              ?.beneficiary_account_number &&
            Boolean(
              formik?.errors?.bank_account_attributes
                ?.beneficiary_account_number
            )
          }
          helperText={
            formik?.touched?.bank_account_attributes
              ?.beneficiary_account_number &&
            formik?.errors?.bank_account_attributes?.beneficiary_account_number
          }
          clickEnter={formik?.handleSubmit}
        />
        <TextFieldInput
          name="bank_account_attributes.beneficiary_name"
          id="beneficiary_name"
          placeholder={t("profile.beneficiaryName")}
          className="login-input "
          type="text"
          onChange={formik?.handleChange}
          value={formik?.values?.bank_account_attributes?.beneficiary_name}
          error={
            formik?.touched?.bank_account_attributes?.beneficiary_name &&
            Boolean(formik?.errors?.bank_account_attributes?.beneficiary_name)
          }
          helperText={
            formik?.touched?.bank_account_attributes?.beneficiary_name &&
            formik?.errors?.bank_account_attributes?.beneficiary_name
          }
          clickEnter={formik?.handleSubmit}
        />
      </Stack>

      <Stack flexDirection="row" width="100%" gap={2} alignItems={"center"}>
      {
        isUpdateBankDetailsModal == true 
        ? <Fragment>
            <Grid item xs={12}>
              <SearchableSelect
                name="bank_account_attributes.currency_id"
                options={currencies}
                label={t("profile.payoutCurrency")}            
                count={currenciesCount}
                className="searchable-select"
                getList={getCurrencies}
                handelMenuOpen={currencies?.length == 0 && getCurrencies}
                defaultValues={currencyDefaultValue}
                error={
                  formik?.touched?.bank_account_attributes?.currency_id &&
                  Boolean(formik?.errors?.bank_account_attributes?.currency_id)
                }
                helperText={
                  formik?.touched?.bank_account_attributes?.currency_id && formik?.errors?.bank_account_attributes?.currency_id
                }
                onChange={(val) => {
                  formik.setValues({
                    ...formik?.values,
                    bank_account_attributes: {
                      ...formik?.values?.bank_account_attributes,
                      currency_id: val?.value,
                      currency_name: val?.label
                    },
                  })    
                }}
                onClear={() => {
                  formik.setValues({
                    ...formik?.values,
                    bank_account_attributes: {
                      ...formik?.values?.bank_account_attributes,
                      currency_id: "",
                      currency_name: ""
                    },
                  });
                  getCurrencies({page_size: 10, page_number: 1});
                }}
                value={{
                  value: formik?.values?.bank_account_attributes?.currency_id,
                  label: formik?.values?.bank_account_attributes?.currency_name,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldInput
                name="bank_account_attributes.beneficiary_address"
                id="beneficiary_address"
                placeholder={t("profile.beneficiaryAddress")}
                className="login-input "
                type="text"
                onChange={formik?.handleChange}
                value={formik?.values?.bank_account_attributes?.beneficiary_address}
                error={
                  formik?.touched?.bank_account_attributes?.beneficiary_address &&
                  Boolean(
                    formik?.errors?.bank_account_attributes?.beneficiary_address
                  )
                }
                helperText={
                  formik?.touched?.bank_account_attributes?.beneficiary_address &&
                  formik?.errors?.bank_account_attributes?.beneficiary_address
                }
                clickEnter={formik?.handleSubmit}
              />
            </Grid>
        </Fragment>
        : <Fragment>
          <SelectBox
            id="currency_id"
            name="bank_account_attributes.currency_id"
            items={currencies}
            title={t("profile.payoutCurrency")}
            className="select"
            value={
              formik?.values?.bank_account_attributes?.currency_id
                ? formik?.values?.bank_account_attributes?.currency_id
                : ""
            }
            error={
              formik?.touched?.bank_account_attributes?.currency_id &&
              Boolean(formik?.errors?.bank_account_attributes?.currency_id)
            }
            helperText={
              formik?.touched?.bank_account_attributes?.currency_id &&
              formik?.errors?.bank_account_attributes?.currency_id
            }
            onClear={()=>{
              formik.setValues({
                ...formik?.values,
                bank_account_attributes: "",
              });
            }}
            onchange={formik?.handleChange}
            onclick={(val) =>
              formik.setValues({
                ...formik?.values,
                bank_account_attributes: {
                  ...formik?.values?.bank_account_attributes,
                  currency_id: val?.id,
                },
              })
            }
          />
          <TextFieldInput
            name="bank_account_attributes.beneficiary_address"
            id="beneficiary_address"
            placeholder={t("profile.beneficiaryAddress")}
            className="login-input "
            type="text"
            onChange={formik?.handleChange}
            value={formik?.values?.bank_account_attributes?.beneficiary_address}
            error={
              formik?.touched?.bank_account_attributes?.beneficiary_address &&
              Boolean(
                formik?.errors?.bank_account_attributes?.beneficiary_address
              )
            }
            helperText={
              formik?.touched?.bank_account_attributes?.beneficiary_address &&
              formik?.errors?.bank_account_attributes?.beneficiary_address
            }
            clickEnter={formik?.handleSubmit}
          />
        </Fragment>
      }
      </Stack>
    </Grid>
  );
};

export default withTranslation()(BankAccountDetails);
