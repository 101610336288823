import { Box, Button, Modal, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { getDocuments, getTermsAndConditions } from '../../../../Contexts/APIs/Lookups/Documents';

const TermsAndConditionsModel = ({
    open, setOpen
}) => {
    const [terms, setTerms] = useState([])
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '25px',
        p: 4,
    };


    const handleClose = () => setOpen(false);
    const getData = async () => {
        const { res, err } = await getDocuments('dive_center')
        if (res) {
            setTerms(res.data.documents[0])
        }
    }
    useEffect(() => {
        getData()
    }, [])

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Stack sx={style} gap={2} >
                <Typography component="h1" style={{ width: 'fit-content', color: 'black', fontWeight: "bold", fontSize: "20px", borderBottom: '2px solid #f6b119' }} >
                    Terms and conditions
                </Typography>

                <Box bgcolor='#fbfbfb' height='400px' p={2} sx={{ overflowY: 'scroll' }}>
                    <div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: terms?.content }} >

                    </div>
                </Box>
                <Button onClick={handleClose} sx={{ '&:hover': { bgcolor: '#f6b119' }, fontSize: "20px", bgcolor: '#f6b119', color: 'white', width: '200px', alignSelf: 'center' }}>
                    Ok
                </Button>
            </Stack>
        </Modal>
    )
}

export default TermsAndConditionsModel