import * as yup from "yup";
import { getBranchTranslations } from "../../../../Contexts/APIs/Lookups/Help";

export const InHouseActivityValidationSchema = () => {
  const activity = getBranchTranslations("activity");
  return yup.object({
    branch_name: yup.string().required(activity.branchNameReq),
    currency: yup.string().required(activity.currencyReq),
    price: yup
      .number()
      .positive(activity.priceNotNegative)
      .required(activity.priceReq),
    number_of_tickets: yup.number().required(activity.noTicketsReq),
    days: yup.number().required(activity.daysReq),
    hours: yup.number().required(activity.couhoursntryReq),
    time_unit: yup.string().required(activity.required).default("hour"),
  });
};
