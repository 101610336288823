import React from "react";
import { useState } from "react";
import Loader from "../../../../Components/Atoms/Loader/Loader";
import { useEffect } from "react";
import AssignedActivitiesTemplate from "../../../../Templates/Main/Salesmen/SalesmenList/AssignedActivities.template";
import { getBranchesList } from "../../../../Contexts/APIs/Branches/Branches";
import {
  getInHouseActivitiesList,
  getInHouseActivityDetails,
} from "../../../../Contexts/APIs/Salsemen/InHouseActivities";
import * as yup from "yup";
import { withTranslation } from "react-i18next";
import { useFormik } from "formik";

import {
  EditSalesmen,
  deleteAssignedActivity,
  getSalesmenAssignActivities,
  getSalesmenDetails,
} from "../../../../Contexts/APIs/Salsemen/Salesmen";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

const AssignedActivitiesPage = (props) => {
  const { t, salesmenPrivilages ,profileData} = props;
  const [assignedActivitiesList, setAssignedActivitiesList] = useState([]);
  const [count, setCount] = useState();
  const [openLoader, setOpenLoader] = useState(true);
  const [filterObj, setFilterObj] = useState({
    page_size: 10,
    page_number: 1,
    branch_type: "",
    keyword: "",
    sort_by: "id",
  });
  const [searchKeywords, setSearchKeywords] = useState("");
  const [branchesList, setBranchesList] = useState([]);
  const [branchesCount, setBranchesCount] = useState(10);

  const [inHouseActivitiesList, setInHouseActivitiesList] = useState([]);
  const [inHouseActivitiesCount, setInHouseActivitiesCount] = useState(10);

  const [selectedAssignedActivityId, setSelectedAssignedActivityId] =
    useState(null);
  const userId = localStorage.getItem("userId");
  const { id: salesmanId } = useParams();
  const [salesmanName, setSalesmenName] = useState("");
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);

  const [modalType, setModalType] = useState(null);
  const [openAssignActivityModal, setOpenAssignActivityModal] = useState(false);
  const [assignedActivity, setAssignedActivity] = useState([]);

  const initialValuesObject = {
    branch_id: "",
    branch_name: "",
    inhouse_activity_id: "",
    inhouse_activity_name: "",
    max_discount_percentage_allowed: "",
  };

  const validationSchemaObject = yup.object({
    branch_id: yup.string().required(t("salesmen.branchRequired")),
    inhouse_activity_id: yup.string().required(t("salesmen.inhouseActivityRequired")),
    max_discount_percentage_allowed: yup
      .number()
      .positive(t("users.numbersOnlyAllowed"))
      .required(t("salesmen.maxDiscountAllowedRequired")),
  });

  const updatePagination = (page) => {
    setFilterObj({ ...filterObj, page_number: page, keyword: searchKeywords });
  };

  const handlePageChange = (event, value) => {
    updatePagination(value);
    setPage(value);
  };

  const getKeywords = (keyword) => {
    setSearchKeywords(keyword);
    setFilterObj({ ...filterObj, keyword: keyword });
  };

  const removeProperties = (obj, ...properties) => {
    const result = { ...obj };
    properties.forEach(function (property) {
      delete result[property];
    });
    return result;
  };

  const getAssignedActivitiesList = async (id, filterObj) => {
    setOpenLoader(true);
    const { res, err } = await getSalesmenAssignActivities(id, filterObj);
    if (res) {
      setAssignedActivitiesList(res?.data?.salesman_inhouse_activities);
      setCount(res?.extra?.total_count);
    }
    if (err) {
    }
    setOpenLoader(false);
  };

  const getBranches = async (filter) => {
    const { res, err } = await getBranchesList({
      page_size: "10",
      page_number: 1,
      dive_center_id: userId,
      ...filter,
    });
    if (res) {
      filter?.page_number == 1
        ? setBranchesList(res?.data?.branches)
        : setBranchesList((s) => [...s, ...res?.data?.branches]);

      setBranchesCount(res?.extra?.total_count);
    }
  };

  const handleGetInHouseActivity = async (id, setState) => {
    const { res, err } = await getInHouseActivityDetails(id);
    if (res) {
      setState(res.data.inhouse_activity);
    }
  };

  const handleGetAssignedActivity = async (id) => {
    const { res, err } = await getSalesmenAssignActivities(
      salesmanId,
      filterObj
    );
    if (res) {
      setAssignedActivity(
        res?.data?.salesman_inhouse_activities?.find((item) => item.id === id)
      );
    }
  };

  const handelGetSalesmanDetails = async (id) => {
    // const { res, err } = await getSalesmenDetails(salesmanId, filterObj);
    const { res, err } = await getSalesmenDetails(salesmanId);
    if (res) {
      setSalesmenName(res?.data?.salesman?.name);
    }
  };
  const handleAssignActivity = async (values) => {
    setOpenLoader(true);
    const { res, err } = await EditSalesmen(salesmanId, {
      salesman: {
        salesman_inhouse_activities_attributes: [
          {
            ...removeProperties(
              values,
              "branch_id",
              "branch_name",
              "inhouse_activity_name"
            ),
          },
        ],
      },
    });
    if (res) {
      formik.setValues(initialValuesObject);
    }
    setOpenLoader(false);
  };

  const handleEditAssignedActivity = async (values) => {
    setOpenLoader(true);
    const { res, err } = await EditSalesmen(salesmanId, {
      salesman: {
        salesman_inhouse_activities_attributes: [
          {
            id: selectedAssignedActivityId,
            ...removeProperties(
              values,
              "branch_id",
              "branch_name",
              "inhouse_activity_name"
            ),
          },
        ],
      },
    });
    if (res) {
      formik.setValues(initialValuesObject);
    }
    setOpenLoader(false);
  };

  const handelUnassignActivity = async (id, inhouseId) => {
    setOpenLoader(true);
    const { res, err } = await deleteAssignedActivity(id, inhouseId);
    if (res) {
      getAssignedActivitiesList(salesmanId, filterObj);
    }
    setOpenLoader(false);
  };
  const formik = useFormik({
    initialValues: initialValuesObject,
    validationSchema: validationSchemaObject,
    onSubmit: (values) => {
      if (modalType === "add") {
        handleAssignActivity(values);
        setTimeout(() => {
          getAssignedActivitiesList(salesmanId, filterObj);
        }, 500);
      } else if (modalType === "edit") {
        handleEditAssignedActivity(values);
        setTimeout(() => {
          getAssignedActivitiesList(salesmanId, filterObj);
        }, 500);
      }
      setOpenAssignActivityModal(false);
      setModalType(null);
    },
  });

  const getInHouseActivities = async (filter) => {
    const filterData = {
      page_size: 10,
      page_number: 1,
      ...filter,
    };
    const { res, err } = await getInHouseActivitiesList({
      branch_id: formik.values.branch_id,
      ...filterData,
    });
    if (res) {
      filterData?.page_number == 1
        ? setInHouseActivitiesList(res?.data?.inhouse_activities)
        : setInHouseActivitiesList((s) => [
            ...s,
            ...res?.data?.inhouse_activities,
          ]);
      setInHouseActivitiesCount(res?.extra?.total_count);

      res?.data?.inhouse_activities?.length === 0 &&
        formik.setValues({
          ...formik?.values,
          inhouse_activity_id: null,
          inhouse_activity_name: null,
          price: null,
          currency_key: null,
        });
    }
  };

  useEffect(() => {
    if (salesmanId) handelGetSalesmanDetails();
  }, [salesmanId]);

  useEffect(() => {
    const timeId = setTimeout(() => {
      getAssignedActivitiesList(salesmanId, filterObj);
    }, 500);
    return () => clearTimeout(timeId);
  }, [filterObj, salesmanId]);
  useEffect(() => {
    formik.values.branch_id = assignedActivity?.activity?.branch?.id;
    formik.values.branch_name = assignedActivity?.activity?.branch?.name;
    formik.values.inhouse_activity_id = assignedActivity?.activity?.id;
    formik.values.inhouse_activity_name = assignedActivity?.activity?.name;
    formik.values.max_discount_percentage_allowed =
      assignedActivity?.max_discount_percentage_allowed;
    formik.values.price = assignedActivity?.activity?.price;
    formik.values.currency_key =
      assignedActivity?.activity?.currency?.lookup_key;
  }, [assignedActivity]);

  return (
    <>
      <Loader open={openLoader} />
      <AssignedActivitiesTemplate
        branchesCount={branchesCount}
        getBranches={getBranches}
        getInHouseActivities={getInHouseActivities}
        assignedActivitiesList={assignedActivitiesList}
        activitiesNo={assignedActivitiesList?.length}
        count={count}
        inHouseActivitiesCount={inHouseActivitiesCount}
        setOpenLoader={setOpenLoader}
        searchKeywords={searchKeywords}
        page={page}
        handlePageChange={handlePageChange}
        getKeywords={getKeywords}
        handelUnassignActivity={handelUnassignActivity}
        setOpen={setOpen}
        branchesList={branchesList}
        inHouseActivitiesList={inHouseActivitiesList}
        formik={formik}
        initialValuesObject={initialValuesObject}
        modalType={modalType}
        setModalType={setModalType}
        salesmanId={salesmanId}
        salesmanName={salesmanName}
        setSelectedAssignedActivityId={setSelectedAssignedActivityId}
        openAssignActivityModal={openAssignActivityModal}
        setOpenAssignActivityModal={setOpenAssignActivityModal}
        handleGetAssignedActivity={handleGetAssignedActivity}
        handleGetInHouseActivity={handleGetInHouseActivity}
        salesmenPrivilages={salesmenPrivilages}
        profileData={profileData}
      />
    </>
  );
};
export default withTranslation()(AssignedActivitiesPage);
