import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput";
import SearchableSelect from "../../../Atoms/Select/SearchableSelect";
import SelectBox from "../../../Atoms/Select/Select";
import PercentIcon from "@mui/icons-material/Percent";
import { withTranslation } from "react-i18next";
const OfferInfo = ({
  t,
  branchesList,
  setBranchData,
  branchData,
  formik,
  activitiesList,
  userId,
  getBranches,
  getActivities,
  offerDetails,
  branchesCount,
  activitiesCount,
  profileData,
  formType,
}) => {
  const isAssignedToOneBranch = profileData?.branch?.name;
  const activitiesTypes = [
    { id: "mobile_offer", name: "mobile offer" },
    { id: "general_offer", name: "general offer" },
  ];
  useEffect(() => {
    if (isAssignedToOneBranch && formType === "add") {
      setBranchData({
        label: profileData?.branch?.name,
        value: profileData?.branch?.id,
      });
      formik.setValues({
        ...formik.values,
        branch_id: profileData?.branch?.id,
        branch_name: profileData?.branch?.name,
        dive_activity_id: "",
        dive_activity_name: "",
      });
    }
  }, [isAssignedToOneBranch, formType, profileData]);
  return (
    <Grid container item lg={12}>
      <Grid container item md={12}>
        <Typography
          component="h1"
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            borderBottom: "2px solid var(--primary-color)",
          }}
          className="mb-30px"
        >
          {t("offer.offerInformation")}
        </Typography>
      </Grid>
      <Grid container item md={12} position="relative" zIndex={10}>
        <Grid item sm={5.8}>
          <SearchableSelect
            name="branche_id"
            options={branchesList}
            getList={getBranches}
            count={branchesCount}
            label={t("offer.branch")}
            className="searchable-select searchable-select-branches"
            disabled={isAssignedToOneBranch}
            onChange={(val) => {
              setBranchData(val);
              formik.setValues({
                ...formik?.values,
                branch_name: val.label,
                dive_activity_id: "",
                dive_activity_name: "",
              });
            }}
            handelMenuOpen={getBranches}
            // value={{ label: branchData?.label, value: branchData?.value }}
            value={{
              value:
                isAssignedToOneBranch && formType == "add"
                  ? profileData?.branch?.id
                  : branchData?.value,
              label:
                isAssignedToOneBranch && formType == "add"
                  ? profileData?.branch?.name
                  : branchData?.label,
            }}
            error={
              formik?.touched?.branch_name &&
              Boolean(formik?.errors?.branch_name)
            }
            onClear={() => {
              setBranchData({});
              formik.setValues({
                ...formik?.values,
                branch_name: "",
                dive_activity_id: "",
                dive_activity_name: "",
              });
            }}
          />
        </Grid>
        <Grid item sm={0.4}></Grid>
        <Grid item sm={5.8}>
          <SearchableSelect
            name="activitie_id"
            options={activitiesList}
            getList={getActivities}
            count={activitiesCount}
            moreFilterData={{
              branch_id: isAssignedToOneBranch
                ? profileData?.branch?.id
                : branchData?.value,
            }}
            handelMenuOpen={(_) =>
              getActivities({
                branch_id: isAssignedToOneBranch
                  ? profileData?.branch?.id
                  : branchData?.value,
              })
            }
            label={t("offer.activityName")}
            className="searchable-select"
            onChange={(val) => {
              formik.setValues({
                ...formik?.values,
                dive_activity_id: val?.value,
                dive_activity_name: val?.label,
              });
            }}
            value={{
              label: formik?.values?.dive_activity_name,
              value: formik?.values?.dive_activity_id,
            }}
            error={
              formik?.touched?.dive_activity_id &&
              Boolean(formik?.errors?.dive_activity_id)
            }
            onClear={() => {
              formik.setValues({
                ...formik?.values,
                dive_activity_id: "",
                dive_activity_name: "",
              });
            }}
          />
        </Grid>
      </Grid>

      <Grid container item md={12} mt={2}>
        <Grid item sm={5.8} mt={1}>
          <SelectBox
            id="offer_type"
            name="offer_type"
            items={activitiesTypes}
            title={t("offer.offerType")}
            className="select"
            value={formik.values.offer_type ? formik.values.offer_type : ""}
            error={
              formik.touched.offer_type && Boolean(formik.errors.offer_type)
            }
            helperText={formik.touched.offer_type && formik.errors.offer_type}
            onchange={formik.handleChange}
            onclick={(val) =>
              formik.setValues({ ...formik?.values, offer_type: val?.id })
            }
          />
        </Grid>
        <Grid item sm={0.4}></Grid>

        <Grid item sm={5.8}>
          <TextFieldInput
            placeholder={t("offer.percentage")}
            name={"discount_percentage"}
            id={"discount_percentage"}
            className="login-input"
            type="number"
            value={formik?.values?.discount_percentage}
            error={
              formik?.touched?.discount_percentage &&
              Boolean(formik?.errors?.discount_percentage)
            }
            helperText={
              formik?.touched?.discount_percentage &&
              formik?.errors?.discount_percentage
            }
            onChange={formik.handleChange}
            EndAdornment={<PercentIcon />}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(OfferInfo);
