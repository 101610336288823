import {
  Grid,
  Avatar,
  MenuItem,
  Menu,
  IconButton,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import CustomPagination from "../../Atoms/Pagination/Pagination";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { withTranslation } from "react-i18next";
import {
  EditSalesmen,
  deleteSalesman,
} from "../../../Contexts/APIs/Salsemen/Salesmen";
import { useNavigate, useSearchParams } from "react-router-dom";
import DeleteModal from "../../Molecules/General/Models/DeleteModal";
import "./SalesmenList.css";
import ReactPhoneInput2 from "../../Atoms/Input/PhoneInput/ReactPhoneInput2";
import { icons } from "../../../Assets/AssetHelper";

const SalesmenList = ({
  t,
  count,
  salesmenList,
  privilages,
  setOpenLoader,
  getSalesmenListing,
  filterObj,
  setFilterObj,
  setSalesmenList,
}) => {
  let [searchParams, setSearchParams] = useSearchParams();

  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  const [page, setPage] = useState(1);
  const [salesmanData, setData] = useState(salesmenList);
  const [loading, setLoading] = useState(false);
  const userType = localStorage.getItem("userType");

  const navigate = useNavigate();
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [selectedSelesman, setSelectedSelesman] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event, freelancer, index) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (name, value) =>
    setFilterObj({ ...filterObj, [name]: value });

  const handlePageChange = (event, value) => {
    setPage(value);
    handleChange("page_number", value);
  };

  const handelDeleteSalesman = async (salesman) => {
    setOpenLoader(true);
    const { res, err } = await deleteSalesman(salesman?.id);
    if (res) {
      getSalesmenListing({});
    }
    setOpenLoader(false);
  };

  const handelSalesmanStatus = async (id, newStatus) => {
    setOpenLoader(true);
    const { res, err } = await EditSalesmen(id, {
      salesman: {
        status: newStatus === "deactivated" ? "deactivated" : "active",
      },
    });
    if (res) {
      setSalesmenList(prev =>
        prev?.map(salesman =>
          salesman.id === id
            ? { ...salesman, status: res?.data?.salesman?.status }
            : salesman
        )
      );
    }
    setOpenLoader(false);
  };

  const activeOptions = [
    {
      title: t("salesmen.edit"),
      action: () =>
        navigate(
          `/salesmen/salesmen-list/edit/${selectedSelesman.salesman.id}?page=${pageNumber}`
        ),
    },
    { title: t("salesmen.delete"), action: () => setOpenDeleteModel(true) },
    {
      title: t("salesmen.deactivate"),
      action: () =>
        handelSalesmanStatus(selectedSelesman.salesman.id, "deactivated"),
    },
    {
      title: t("salesmen.changePassword"),
      action: () =>
        navigate(
          `/salesmen/salesmen-list/edit-password/${selectedSelesman.salesman.id}?page=${pageNumber}`
        ),
    },
  ];

  const deactivatedOptions = [
    { title: t("salesmen.delete"), action: () => setOpenDeleteModel(true) },
    {
      title: t("salesmen.activate"),
      action: () =>
        handelSalesmanStatus(selectedSelesman.salesman.id, "active"),
    },
    {
      title: t("salesmen.changePassword"),
      action: () =>
        navigate(
          `/salesmen/salesmen-list/edit-password/${selectedSelesman.salesman.id}?page=${pageNumber}`
        ),
    },
  ];

  const menuOptions = {
    active: activeOptions,
    deactivated: deactivatedOptions,
  };

  useEffect(() => {
    setData(salesmenList);
  }, [salesmenList]);

  return (
    <>
      <Grid container>
        <Grid container className="table-header mb-20px">
          <Grid item md={0.5} textAlign="center">
            {t("salesmen.No.")}
          </Grid>
          <Grid item md={1}>
            {t("salesmen.image")}
          </Grid>
          <Grid item md={1}>
            {t("salesmen.name")}
          </Grid>
          <Grid item md={2}>
            {t("salesmen.emailAddress")}
          </Grid>
          <Grid item sm={2} px={"15px"}>
            {t("salesmen.mobilePhone")}
          </Grid>

          <Grid item sm={1.5}>
            {t("reservations.branch")}
          </Grid>
          <Grid item sm={1.25}>
            {t("salesmen.status")}
          </Grid>
          <Grid item sm={1.75}></Grid>
          <Grid item sm={1}>
            {t("salesmen.action")}
          </Grid>
        </Grid>

        {salesmenList?.length === 0 && (
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <img
              src={icons.InHouseActivity}
              style={{
                margin: "80px 0px 20px",
                width: "70px",
                height: "72px",
              }}
              alt="no in house activities img"
            />
            <Typography
              sx={{ fontWeight: 700, fontSize: "22px", color: "#F6B119" }}
            >
              {t("salesmen.nosalesmenList")}
            </Typography>
          </Grid>
        )}
        {!loading &&
          salesmanData?.map((freelancer, index) => (
            <Grid
              onClick={() =>
                setSelectedSelesman({ salesman: freelancer, index: index })
              }
              container
              item
              lg={12}
              alignItems={"center"}
              className="selesman-item mb-20px"
            >
              <Grid item md={0.5} textAlign="center">
                {freelancer?.id}
              </Grid>
              <Grid item md={1}>
                <Avatar
                  src={freelancer?.avatar}
                  alt="avatar"
                  style={{
                    width: "53px",
                    height: "53px",
                    borderRadius: "10px",
                  }}
                />
              </Grid>
              <Grid item md={1} fontWeight={"700"}>
                {freelancer?.name}
              </Grid>
              <Grid item md={2}>
                {freelancer?.email}
              </Grid>
              <Grid item md={2}>
                <ReactPhoneInput2
                  phoneValue={freelancer?.phone_number}
                  codeValue={freelancer?.country_code}
                  customClass="salesman-phone"
                  formatType={"read"}
                  disableDropdown={true}
                />
              </Grid>
              <Grid item sm={1.5} textTransform={"capitalize"}>
                {freelancer?.branch?.name}
              </Grid>
              <Grid
                item
                sm={1.25}
                color={freelancer?.status == "active" ? "#00b906" : "#e10007"}
                textTransform={"capitalize"}
              >
                {freelancer?.status}
              </Grid>

              <Grid
                item
                sm={1.75}
                sx={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  fontWeight: "700",
                }}
                onClick={() =>
                  navigate(
                    `/salesmen/salesmen-list/${freelancer?.id}/assigned-activities?page=${pageNumber}`,
                    {
                      state: {
                        salesmanId: freelancer?.id,
                        salesmanName: freelancer?.name,
                      },
                    }
                  )
                }
              >
                {t("salesmen.assignedActivities")}
              </Grid>

              {((userType === "descendant_user" && privilages?.can_update) ||
                userType !== "descendant_user") && (
                <Grid sm={1}>
                  <IconButton
                    id={`demo-positioned-button-${index}`}
                    aria-controls={
                      open ? `demo-positioned-menu-${index}` : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={(e) => handleClick(e, freelancer, index)}
                  >
                    <MoreHorizIcon style={{ color: "black" }} />
                  </IconButton>

                  <Menu
                    id={`demo-positioned-menu-${index}`}
                    MenuListProps={{
                      "aria-labelledby": `demo-positioned-button-${index}`,
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    className={
                      anchorEl && anchorEl.id.indexOf(`${index}`) != -1
                        ? "demo-menu-current"
                        : "demo-menu-not-current"
                    }
                  >
                    {menuOptions[selectedSelesman?.salesman?.status]?.map(
                      (val, ndx) => {
                        return (
                          <MenuItem
                            key={ndx}
                            onClick={() => {
                              val?.action();
                              handleClose();
                            }}
                          >
                            {val?.title}
                          </MenuItem>
                        );
                      }
                    )}
                  </Menu>
                </Grid>
              )}
            </Grid>
          ))}
        {count > 10 && (
          <Grid container justifyContent="center" mb={7.5} mt={5}>
            <CustomPagination
              count={Math.ceil(count / 10)}
              size="large"
              shape="rounded"
              page={page}
              handleChange={handlePageChange}
              className="pagination"
            />
          </Grid>
        )}
      </Grid>

      {openDeleteModel && (
        <DeleteModal
          open={openDeleteModel}
          setOpen={setOpenDeleteModel}
          handelDeleteRequest={() => {
            handelDeleteSalesman(selectedSelesman?.salesman);
            setOpenDeleteModel(false);
          }}
          text={[t("salesmen.deleteSalesman"), t("salesmen.deleteSalesmanMsg")]}
          buttonText={[t("offer.cancel"), t("offer.delete")]}
        />
      )}
    </>
  );
};

export default withTranslation()(SalesmenList);
