import { Grid, Typography, Divider, Stack, Box } from "@mui/material";
import "./Form.css";
import { useEffect, useState } from "react";
import SelectBox from "../../../Atoms/Select/Select";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { icons } from "../../../../Assets/AssetHelper";
import SearchableSelect from "../../../Atoms/Select/SearchableSelect";
import { withTranslation } from "react-i18next";

const MultiOrganizationsSelect = ({
  t,
  formTitle,
  addBtnTitle,
  getOrganization,
  list,
  intialActiveList,
  getActiveList,
  organizationsCount,
  value, setValue
}) => {
  const [selectedList, setSelectedList] = useState([]); // add value in selected list
  const [templist, setTempList] = useState([]); //save value when select it from selectbox
  const [selectedOrganization, setSelectedOrganization] = useState({});

  const setLanguageInTemp = (id, name) => {
    setTempList((templist) => [...templist, { name: name, id: id }]);
    setValue({ value: id, label: name });
    setSelectedOrganization({ name: name, id: id });
  };

  const addNewLanguage = () => {
    // var uniqueArray = [
    //   ...new Map(templist.map((item) => [item["name"], item])).values(),
    // ];
    // setSelectedList([...uniqueArray]);
    (Object.keys(selectedOrganization).length !== 0 && selectedList.indexOf(selectedOrganization) == -1) && setSelectedList((templanguages) => [...templanguages, selectedOrganization]);
  };

  useEffect(() => {
    if (intialActiveList?.length > 0) {
      const data = intialActiveList?.map((val) => {
        return {
          name: val?.name,
          id: val?.id,
        };
      });
      setSelectedList(data);
      setTempList(data);
    }
  }, [intialActiveList]);

  useEffect(() => {
    var activeList = selectedList.map((data) => {
      return data?.id;
    });
    getActiveList([...activeList]);
  }, [selectedList]);

  const RemoveActivityLanguage = (i) => {
    if (i > -1) {
      const filterLang = selectedList?.filter((val, ind) => ind != i);
      setSelectedList(filterLang);
      setTempList(filterLang);
    }
  };
  return (
    <Box width={'100% !important'}>
      {selectedList?.map((lang, index) => (
        <Grid container md={12}>
          <Grid sm={6}>
            <Typography
              component="h1"
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                lineHeight: "3",
              }}
            >
              {lang?.name}
            </Typography>
          </Grid>
          <Grid sm={6} textAlign="end">
            <PrimaryButton
              btnType="icon"
              startIcon={<img src={icons.DeleteIcon} alt="delete" />}
              click={() => RemoveActivityLanguage(index)}
            />
          </Grid>
          <Divider style={{ width: "100%", margin: "10px 0" }} />
        </Grid>
      ))}
      <Stack
        container
        width="100%"
        flexDirection={{ xs: "column", md: "row" }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box width={{ xs: "100%" }}>
          <SearchableSelect
            options={list}
            getList={getOrganization}
            handelMenuOpen={list?.length == 0 && getOrganization}
            label={formTitle}
            count={organizationsCount}
            type="cer"
            className="searchable-select"
            onChange={(val) => {
              setLanguageInTemp(val?.value, val?.label);
              setValue({ label: val?.label, value: val?.value });
            }}
            value={value}
            onClear={() => {
              setLanguageInTemp([]);
              setValue({});
              setSelectedOrganization({});
            }}
          />
        </Box>

        {/* <PrimaryButton
          text={addBtnTitle}
          classBtn="grey-btn"
          style={{ padding: "5px" }}
          fontSize={"14px"}
          click={addNewLanguage}
        /> */}
      </Stack>
    </Box>
  );
};

export default withTranslation()(MultiOrganizationsSelect);
