import React from "react";
import SalesmenReservations from "../../../../Components/Organisms/Salesmen/SalesmenReservations/SalesmenReservations";

const SalesmenReservationsTemplate = ({
  count,
  reservationsList,
  branchesList,
  salesmenList,
  statusList,
  setOpenLoader,
  page,
  handlePageChange,
  updateSalesmenReservationsList,
  getReservations,
  salesmenReservationsPrivilages,
  getBranches,
  getSalesmenListing,
  branchesCount,
  salesmenCount,
  filterObj,
  setFilterObj,
  profileData
}) => {
  return (
    <SalesmenReservations
      count={count}
      reservationsList={reservationsList}
      branchesList={branchesList}
      salesmenList={salesmenList}
      salesmenCount={salesmenCount}
      statusList={statusList}
      setOpenLoader={setOpenLoader}
      page={page}
      handlePageChange={handlePageChange}
      updateSalesmenReservationsList={updateSalesmenReservationsList}
      getReservations={getReservations}
      salesmenReservationsPrivilages={salesmenReservationsPrivilages}
      getBranches={getBranches}
      getSalesmenListing={getSalesmenListing}
      branchesCount={branchesCount}
      filterObj={filterObj}
      setFilterObj={setFilterObj}
      profileData={profileData}
    />
  );
};

export default SalesmenReservationsTemplate;
