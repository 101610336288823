import { Grid, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import BranchesTabs from "../../Molecules/Branches/BranchesTabs.jsx";
import BranchesPanel from "../../Molecules/Branches/BranchesPanel.jsx";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { icons } from "../../../Assets/AssetHelper.js";
import { useNavigate } from "react-router-dom";

const BranchesList = (props) => {
  const {
    branchesList,
    count,
    t,
    branchesPrivilages,
    setFilterObj,
    filterObj,
    updatePagination,
    page,
  } = props;
  const [BranchValue, setBranchValue] = useState(0);

  const [searchKeywords, setSearchKeywords] = useState("");

  const BranchesTypes = [
    {
      id: 1,
      label: t("branch.all"),
    },
    {
      id: 2,
      label: t("branch.safariBoat"),
    },
    {
      id: 3,
      label: t("branch.diveCenter"),
    },
  ];
  const handleBranchValue = (newValue) => {
    getBranchType(newValue);
    setBranchValue(newValue);
  };

  const getBranchType = (value) => {
    if (value === 0) setFilterObj({ ...filterObj, branch_type: "" });
    if (value === 1) setFilterObj({ ...filterObj, branch_type: "safari_boat" });
    if (value === 2) setFilterObj({ ...filterObj, branch_type: "office" });
  };
  const getKeywords = (keyword) => {
    setSearchKeywords(keyword);
    setFilterObj({ ...filterObj, keyword: keyword });
  };

  return (
    <Grid container className="branches-list-container">
      <BranchesTabs
        options={BranchesTypes}
        handleBranchValue={handleBranchValue}
        BranchValue={BranchValue}
        getBranchType={getBranchType}
        searchKeywords={searchKeywords}
        getKeywords={getKeywords}
        count={count}
        type="list"
        branchesPrivilages={branchesPrivilages}
        branchesList={branchesList}
      />
      {branchesList.length === 0 ? (
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "200px",
          }}
        >
          <img
            src={icons.NoBranches}
            alt="no-branches"
            style={{ display: "block", width: "125.8px", height: "124.9px" }}
          />
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: "bold",
              color: "#333132",
              mt: "25px",
            }}
          >
            {t("branch.noBranches")}
          </Typography>
        </Grid>
      ) : (
        <BranchesPanel
          options={BranchesTypes}
          BranchValue={BranchValue}
          updatePagination={updatePagination}
          branchesList={branchesList}
          count={count}
          branchesPrivilages={branchesPrivilages}
          page={page}
        />
      )}
    </Grid>
  );
};

export default withTranslation()(BranchesList);
