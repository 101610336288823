import { useState, useEffect } from "react";
import FreelancersHeaders from "../../Molecules/Freelancers/FreelancersHeaders";
import ListFreelancers from "../../Molecules/Freelancers/Lists/ListFreelancers";
import "./Freelancers.css";
import { Stack, Typography } from "@mui/material";
import { withTranslation } from "react-i18next";
import { icons } from "../../../Assets/AssetHelper";

const FreelancersList = ({
  t,
  count,
  freelancers,
  getFreelancers,
  freelancersPrivilages,
  filterObj,
  setFilterObj,
  profileData
}) => {
  const handleChange = (name, value) =>
    setFilterObj({ ...filterObj, [name]: value });

  return (
    <>
      <FreelancersHeaders handleChange={handleChange} filterObj={filterObj} setFilterObj={setFilterObj} profileData={profileData} />

      {freelancers?.length == 0 ? (
        <Stack
          width="100%"
          alignItems="center"
          justifyContent="center"
          height="500px"
        >
          <img src={icons?.Freelancer} width="113px" />
          <Typography fontSize="30px" fontWeight="700">
            {t("joboffer.emptyMsg")}
          </Typography>
        </Stack>
      ) : (
        <ListFreelancers
          count={count}
          freelancers={freelancers}
          handleChange={handleChange}
          freelancersPrivilages={freelancersPrivilages}
        />
      )}
    </>
  );
};

export default withTranslation()(FreelancersList);
