import { axiosFn, handleError } from "../index"
import { makeFilterString } from "../Helper/Helper"
import * as URL from "../URLs"

export async function FreeLancerStatus() {
    return await axiosFn("get", URL.lookups + `/mutual_freelancer_statuses`)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
