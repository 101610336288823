import { Grid, Typography, Divider, Stack } from "@mui/material";
import "./Form.css";
import SelectBox from "../../Atoms/Select/Select.jsx";
import Map from "../Maps/GoogleMap.jsx";
import { CountryAPI } from "../../../Contexts/APIs/Lookups/Country.js";
import { useEffect, useState } from "react";
import { CityAPI } from "../../../Contexts/APIs/Lookups/City.js";
import { AreaAPI } from "../../../Contexts/APIs/Lookups/Area";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import SearchableSelect from "../../Atoms/Select/SearchableSelect";
const LocationForm = (props) => {
  const {
    activityDetails,
    formTitle,
    formik,
    getCurrentLocation,
    type,
    mapType,
    setMapType,
    selectedBranchData,
    location,
  } = props;
  const [countries, setCounries] = useState([]);
  const [countriesCount, setCounriesCount] = useState(10);

  const [cities, setCities] = useState([]);
  const [citiesCount, setCitiesCount] = useState(10);

  const [areas, setAreas] = useState([]);
  const [areasCount, setAreasCount] = useState(10);

  const [isActive, setActive] = useState(0);
  const [mapTitle, setMapTitle] = useState("Meeting Location");
  const [showMap, setShowMap] = useState(true);

  const MapTypes = [
    {
      label: "Add meeting point",
      value: 0,
    },
    {
      label: "Hotel pickup",
      value: 1,
    },
    {
      label: "Operator pickup",
      value: 2,
    },
  ];
  const handleChange = (i, val) => {
    setActive(i);
    setMapType(val);
    if (val === 0) {
      setMapTitle("Meeting Location");
      setShowMap(true);
    }
    if (val === 2) {
      setMapTitle("Operator Location");
      setShowMap(false);
    }
    if (val === 1) setShowMap(false);
  };
  const getCountries = async (filter) => {
    setCities([]);
    const { res, err } = await CountryAPI({
      page_size: 10,
      page_number: 1,
      ...filter,
    });
    if (res) {
      filter?.page_number == 1
        ? setCounries(res?.data?.countries)
        : setCounries((s) => [...s, ...res?.data?.countries]);

      setCounriesCount(res?.extra?.total_count);
    }
  };
  const getCities = async (filter) => {
    setAreas([]);
    const { res, err } = await CityAPI({
      page_size: 10,
      page_number: 1,
      country_id: formik?.values.country_id,
      ...filter,
    });
    if (res) {
      filter?.page_number == 1
        ? setCities(res?.data?.cities)
        : setCities((s) => [...s, ...res?.data?.cities]);

      setCitiesCount(res?.extra?.total_count);
    }
  };
  const getAreas = async (filter) => {
    const { res, err } = await AreaAPI({
      page_size: 10,
      page_number: 1,
      city_id: formik?.values.city_id,
      ...filter,
    });
    if (res) {
      filter?.page_number == 1
      ? setAreas(res?.data?.areas)
      : setAreas((s) => [...s, ...res?.data?.areas]);

      setAreasCount(res?.extra?.total_count);
    }
  };

  useEffect(() => {
    if (formik?.values.country_id?.toString().length > 0) {
      setTimeout(() => {
        getCities({ country_id: formik?.values.country_id, page_number: 1 });
      }, 1000);
    } else {
      setAreas([]);
      setCities([]);
    }
  }, [formik?.values.country_id]);

  useEffect(() => {
    if (formik?.values.city_id?.toString().length > 0) {
      setTimeout(() => {
        getAreas(formik?.values.city_id);
      }, 1000);
    } else {
      setAreas([]);
    }
  }, [formik?.values.city_id]);

  useEffect(() => {
    handleChange(mapType, mapType);
  }, [mapType]);

  return (
    <Grid container className="activity-form">
      <Grid container>
        <Typography
          component="h1"
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            borderBottom: "2px solid var(--primary-color)",
          }}
          className="mb-20px"
        >
          {type === "switch" ? mapTitle : formTitle}
        </Typography>
      </Grid>
      {type === "switch" && (
        <Grid container className="mb-20px">
          {MapTypes.map((type, index) => (
            <Grid container item sm={4} key={index}>
              <Grid item xs={2}>
                <Typography
                  onClick={() => handleChange(index, type.value)}
                  component="span"
                  className={
                    index === isActive
                      ? "custom-radio-btn active-select"
                      : "custom-radio-btn"
                  }
                ></Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography component="label">{type.label}</Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
      {showMap && (
        <>
          <Grid
            container
            className="mb-20px"
            style={{ position: "relative", zIndex: "10" }}
          >
            <Grid item sm={5.8}>
              <SearchableSelect
                name="country_id"
                options={countries}
                count={countriesCount}
                label="Country"
                getList={getCountries}
                handelMenuOpen={getCountries}
                defaultValues={activityDetails?.country}
                className="searchable-select"
                error={Boolean(formik?.errors?.country_id)}
                helperText={formik?.errors?.country_id}
                onChange={(val) => {
                  formik.setValues({
                    ...formik?.values,
                    area_id: "",
                    area_name: "",
                    city_id: "",
                    city_name: "",
                    country_id: val?.value,
                    country_name: val?.label,
                  });
                }}
                value={{
                  label: formik?.values?.country_name,
                  value: formik?.values?.country_id,
                }}
                onClear={() => {
                  formik.setValues({
                    ...formik?.values,
                    country_id: "",
                    country_name: "",
                    city_id: "",
                    city_name: "",
                    area_id: "",
                    area_name: "",
                  });
                }}
              />
            </Grid>
            <Grid item sm={0.4}></Grid>
            <Grid item sm={5.8}>
              <SearchableSelect
                name="city_id"
                options={cities}
                count={citiesCount}
                getList={getCities}
                moreFilterData={{ country_id: formik?.values?.country_id }}
                defaultValues={activityDetails?.city}
                label="Province"
                className="searchable-select"
                error={Boolean(formik?.errors?.city_id)}
                helperText={formik?.errors?.city_id}
                onChange={(val) => {
                  formik.setValues({
                    ...formik?.values,
                    area_id: "",
                    area_name: "",
                    city_id: val?.value,
                    city_name: val?.label,
                  });
                }}
                value={{
                  label: formik?.values?.city_name,
                  value: formik?.values?.city_id,
                }}
                onClear={() => {
                  formik.setValues({
                    ...formik?.values,
                    city_id: "",
                    city_name: "",
                    area_id: "",
                    area_name: "",
                  });
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            className="mb-20px"
            style={{ position: "relative", zIndex: "9" }}
          >
            <Grid item sm={12}>
              <SearchableSelect
                name="area_id"
                options={areas}
                count={areasCount}
                getList={getAreas}
                moreFilterData={{ city_id: formik?.values?.city_id }}
                defaultValues={activityDetails?.area}
                label="City"
                className="searchable-select"
                error={Boolean(formik?.errors?.area_id)}
                helperText={formik?.errors?.area_id}
                onChange={(val) => {
                  formik.setValues({
                    ...formik?.values,
                    area_id: val?.value,
                    area_name: val?.label,
                  });
                }}
                value={{
                  label: formik?.values?.area_name,
                  value: formik?.values?.area_id,
                }}
                onClear={() => {
                  formik.setValues({
                    ...formik?.values,
                    area_id: "",
                    area_name: "",
                  });
                }}
              />
            </Grid>
          </Grid>
          <Grid container className="mb-20px">
            <Map
              lat={location?.lat}
              lng={location?.lng}
              getCurrentLocation={getCurrentLocation}
              type="add"
            />
            <Typography>
              <span style={{ color: "red", fontWeight: "bold" }}>Tip:</span> if
              you didn't choose a location your current location is chosen
            </Typography>
          </Grid>
        </>
      )}
      {isActive == 2 && (
        <Grid container className="mb-20px">
          {selectedBranchData ? (
            <Stack width="100%">
              <Map
                lat={selectedBranchData?.lat}
                lng={selectedBranchData?.long}
                getCurrentLocation={getCurrentLocation}
                type="show"
              />
              <Stack width="100%" flexDirection="row" gap={1}>
                <FmdGoodIcon style={{ color: "#f6b119" }} />
                <Typography>
                  {selectedBranchData?.area?.name} ,
                  {selectedBranchData?.city?.name} ,
                  {selectedBranchData?.country?.name}{" "}
                </Typography>
              </Stack>
            </Stack>
          ) : (
            <Stack alignItems="center" justifyContent="center" width="100%">
              <Typography>Please choose Branch First</Typography>
            </Stack>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default LocationForm;
