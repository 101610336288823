import React from "react";
import {
  deleteActivity,
  getActivityDetails,
} from "../../../../Contexts/APIs/Activities/Activities";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import InHouseActivityDetailsTemplate from "../../../../Templates/Main/Salesmen/InHouseActivities/InHouseActivityDetails.template";
import {
  deleteInHouseActivity,
  getInHouseActivityDetails,
} from "../../../../Contexts/APIs/Salsemen/InHouseActivities";

const InHouseActivityDetailsPage = (props) => {
  const { t, inHouseActivitiesPrivilages } = props;
  const { id } = useParams();
  const [activityDetails, setActivityDetails] = useState({});
  let [searchParams, setSearchParams] = useSearchParams();

  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  const ActivityDetails = async () => {
    const { res, err } = await getInHouseActivityDetails(id);
    if (res) {
      setActivityDetails(res?.data?.inhouse_activity);
    }
  };

  const Navigate = useNavigate();

  const RemoveActivity = async (activity_id) => {
    const { res, err } = await deleteInHouseActivity(activity_id);
    if (res) Navigate(`/salesmen/in-house-activities?page=${pageNumber}`);
  };

  useEffect(() => {
    ActivityDetails();
  }, []);

  return (
    <InHouseActivityDetailsTemplate
      activityDetails={activityDetails}
      RemoveActivity={RemoveActivity}
      inHouseActivitiesPrivilages={inHouseActivitiesPrivilages}
    />
  );
};

export default InHouseActivityDetailsPage;
