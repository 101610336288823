import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { getUsersList } from '../../../Contexts/APIs/Users/UsersList';
import UsersTemplate from '../../../Templates/Main/Users/Users.template'

const UsersPage = ({ usersPrivilages,profileData }) => {
    const [openLoader, setOpenLoader] = useState(false);
    const [usersList, setUsersList] = useState([]);

    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);

    const [filterObj, setFilterObj] = useState({
        'page_size': 10,
        'page_number': 1,
        'keyword': ''
    });

    const updatePagination = (page) => {
        setFilterObj({ ...filterObj, 'page_number': page });
    };

    const handlePageChange = (value) => {
        setPage(value);
        updatePagination(value);
    };

    const getUsers = async (filterObj) => {
        setOpenLoader(true)
        const { res, error } = await getUsersList(filterObj)
        if (res) {
            setOpenLoader(false);
            setUsersList(res?.data?.descendant_users);
            setCount(res?.extra?.total_count);
        }else{        
            setOpenLoader(false)
        }
    }

    useEffect(() => {
        getUsers(filterObj);
    }, [filterObj])

    return (
        <UsersTemplate
            filterObj={filterObj} setFilterObj={setFilterObj}
            usersList={usersList} setUsersList={setUsersList}
            openLoader={openLoader} setOpenLoader={setOpenLoader}
            count={count} page={page}
            handlePageChange={handlePageChange}
            usersPrivilages={usersPrivilages}
            profileData={profileData}
        />
    )
}

export default UsersPage