import { Button, Modal, Stack, Typography } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";

const DeleteApplicantModal = ({ t, open, setOpen, handelDeleteRequest }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "25px",
    p: 4,
  };

  const handleClose = () =>
    setOpen((s) => {
      return { state: false, data: null };
    });

  return (
    <Modal
      open={open?.state}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack sx={style} alignItems="center" justifyContent="center">
        <Typography
          component="h1"
          style={{ color: "#f6b119", fontWeight: "bold", fontSize: "30px" }}
        >
          {t("offer.deleteFreelancer")}
        </Typography>
        <Typography textAlign="center" style={{ fontWeight: "bold" }}>
          {t("offer.deleteMsg-3")}
        </Typography>
        <Stack flexDirection="row" alignItems="center" mt={4}>
          <Button
            style={{ width: "150px", color: "black" }}
            onClick={() =>
              setOpen((s) => {
                return { state: false, data: null };
              })
            }
          >
            {" "}
            {t("joboffer.cancel")}
          </Button>

          <Button
            style={{ width: "150px" }}
            className="primary r-25px"
            onClick={handelDeleteRequest}
          >
            {t("joboffer.delete")}
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default withTranslation()(DeleteApplicantModal);
