import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import { icons } from "../../../Assets/AssetHelper.js";
import { Avatar, Box, Grid, Stack, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

function JobOfferDetailsHeader({ jobOfferData, language, t }) {
  const lang = localStorage.getItem("lang");
  const [selectedLang, setSelectedLang] = useState(lang);

  useEffect(() => {
    setSelectedLang(lang);
  }, [lang]);

  useEffect(() => {
    language && setSelectedLang(language);
  }, [language]);
  let [searchParams, setSearchParams] = useSearchParams();

  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  const navigate = useNavigate();
  return (
    <Grid
      container
      sx={{
        padding: "30px 60px",
      }}
    >
      <Grid container item xs={8} justifyContent="space-between">
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: "bold",
              textTransform: "capitalize",
            }}
          >
            {jobOfferData?.localized_data &&
              jobOfferData?.localized_data[selectedLang]?.title}{" "}
            - {jobOfferData?.dive_expert_professional_level?.replace("_", " ")}
          </Typography>
        </Grid>

        <Stack item xs={12} direction="row" gap={2}>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            {jobOfferData?.location?.city?.name}
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            {jobOfferData?.branch?.name}
          </Typography>

          <Box>
            <Typography
              display="inline"
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                color: "#faa100",
              }}
            >
              {jobOfferData?.rate}{" "}
            </Typography>
            <Typography
              display="inline"
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                color: "#faa100",
                textTransform: "uppercase",
              }}
            >
              {jobOfferData?.currency?.lookup_key}
            </Typography>
            <Typography
              display="inline"
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                color: "#faa100",
              }}
            >
              {`/${t("reservations.day")}`}
            </Typography>
          </Box>
        </Stack>
      </Grid>
      <Grid container item xs={2} alignContent="center">
        <PrimaryButton
          text={`${jobOfferData?.number_of_applicants} applicants`}
          style={{
            padding: "5px 20px",
            height: "48px",
            width: "100%",
          }}
          classBtn="primary r-25px"
          click={() =>
            navigate(
              `/freelancers/jop-offers-applicants/${jobOfferData?.id}?page=${pageNumber}`
            )
          }
        />
      </Grid>
    </Grid>
  );
}

export default withTranslation()(JobOfferDetailsHeader);
