import { Grid } from "@mui/material";
import ActivityGroups from "../../../../Components/Organisms/Reservations/ActivityGroups";
const AssignedActivityGroupsTemplate = (props) => {
    const { t, getActivityGroups, count, activityGroups, activityInfo, assignedActivityName, 
        salesmanId, salesmanName, setStartDate, setEndDate, page, setPage } = props;
    return (
        <Grid container> 
            <ActivityGroups
                getActivityGroups={getActivityGroups}
                count={count}
                activityGroups={activityGroups}
                activityInfo={activityInfo}
                assignedActivityName={assignedActivityName}
                salesmanId={salesmanId}
                salesmanName={salesmanName}
                isAssignedActivity={true}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                page={page}
                setPage={setPage}
            />
        </Grid>
    )
}
export default AssignedActivityGroupsTemplate