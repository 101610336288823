import { Grid, Typography, Stack, Box } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import CustomSeparator from "../../Atoms/BreadCrumb/index.jsx";
import OfferInfo from "../../Molecules/Offers/Forms/offerInfo.jsx";
import OfferTime from "../../Molecules/Offers/Forms/offerTime.jsx";
import { withTranslation } from "react-i18next";
import {
  createOffer,
  editOffer,
} from "../../../Contexts/APIs/Offers/Offers.js";

const OfferForm = (props) => {
  const {
    t,
    branchesList,
    userId,
    getBranches,
    getActivities,
    activitiesList,
    formType,
    offerDetails,
    branchesCount,
    activitiesCount,
    profileData,
  } = props;
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");

  const handleFormSubmit = useRef(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [branchData, setBranchData] = useState();
  const [loading, setLoading] = useState(false);

  const [selectedActivity, setSelectedActivity] = useState(null);
  let requestStatus = "";
  const [requestCounter, setRequestCounter] = useState(0);

  useEffect(() => {
    handleFormSubmit.current = validationMethod;
  }, [startDate, endDate]);

  const DateValidation = () => {
    if (startDate == null) {
      toast.error(t("joboffer.pleaseEnterStartDate"));
    } else if (endDate == null) {
      toast.error(t("joboffer.pleaseEnterEndDate"));
    } else if (!moment(endDate).isSameOrAfter(startDate)) {
      toast.error(t("joboffer.pleaseEnterValidDate"));
    }
  };

  const validationMethod = () => {
    DateValidation(startDate, endDate);
    formik.handleSubmit();
  };

  const OffrIntialValues = {
    branch_name: offerDetails?.dive_activity?.branch?.name,
    offer_type: offerDetails?.offer_type,
    dive_activity_id: offerDetails?.dive_activity?.id,
    start_date: offerDetails?.start_date,
    end_date: offerDetails?.end_date,
    discount_percentage: offerDetails?.discount_percentage
      ? offerDetails?.discount_percentage
      : "",
    dive_activity_name: offerDetails?.dive_activity?.name,
  };

  const OfferValidationSchema = yup.object({
    branch_name: yup.string().required(t("offer.branchNameRequired")),
    offer_type: yup.string().required(t("offer.offerTypeRequired")),
    dive_activity_id: yup.number().required(t("offer.activityNameRequired")),
    discount_percentage: yup
      .number()
      .positive(t("offer.discountPercentageNegative"))
      .required(t("offer.discountPercentageRequired")),
  });

  const [createStartDate, setCreateStartDate] = useState(false);
  const [createEndDate, setCreateEndDate] = useState(false);

  const handleDateMsg = () => {
    if (startDate == null) {
      setCreateStartDate(true);
    } else {
      setCreateStartDate(false);
    }
    if (endDate == null) {
      setCreateEndDate(true);
    } else {
      setCreateEndDate(false);
    }
  };

  const formik = useFormik({
    initialValues: OffrIntialValues,
    validationSchema: OfferValidationSchema,
    enableReinitialize: true,
    onSubmit: async(values) => {
      if (startDate && endDate) {
        let job_offer = {
          ...values,
          start_date: moment(startDate).format("DD-MM-YYYY") + " 10:00 am",
          end_date: moment(endDate).format("DD-MM-YYYY"),
          offer_type: values?.offer_type,
          discount_percentage: values?.discount_percentage,
          dive_activity_id: values?.dive_activity_id,
        };

          
        if(formType == 'edit'){
          requestStatus = "pending";
          setRequestCounter(prev => prev+1);
          
          if( requestStatus === "pending" && requestCounter === 0 ){
            const { res, err } = await editOffer(offerDetails?.id, { activity_offer: job_offer });
              if (res) {
                requestStatus = "success";
                navigate(`/offers?page=${pageNumber}`);
                requestStatus = "";
                setRequestCounter(null);
              }
              else{              
                if (err) {
                  requestStatus = "fail";
                  setRequestCounter(0);  
                }
              }
          }
        }else{
          requestStatus = "pending";
          setRequestCounter(prev => prev+1);
          
          if( requestStatus === "pending" && requestCounter === 0 ){
            const { res, err } = await createOffer({ activity_offer: job_offer });
              if (res) {
                requestStatus = "success";
                navigate(`/offers?page=${pageNumber}`);
                requestStatus = "";
                setRequestCounter(null);
              }
              else{              
                if (err) {
                  requestStatus = "fail";
                  setRequestCounter(0);  
                }
              }
          }
        }

      } else if (
        startDate == null ||
        endDate == null ||
        !moment(endDate).isSameOrAfter(startDate)
      ) {
        DateValidation();
      }
      handleDateMsg();
    },
  });

  // useEffect(() => {
  //   if (branchData?.value) getActivities({ branch_id: branchData?.value });
  // }, [branchData?.value]);

  useEffect(() => {
    if (offerDetails?.dive_activity?.branch)
      setBranchData({
        label: offerDetails?.dive_activity?.branch?.name,
        value: offerDetails?.dive_activity?.branch?.id,
      });
    if (offerDetails) {
      setLoading(true);
      setTimeout(() => {
        setStartDate(offerDetails?.start_date);
        setEndDate(offerDetails?.end_date);
      }, 1000);
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [offerDetails]);

  useEffect(() => {
    const selected = activitiesList?.find(
      (data) => data?.id == formik.values?.dive_activity_id
    );
    setSelectedActivity(selected);
  }, [formik.values?.dive_activity_id]);
  return (
    <Stack>
      <Grid container item sm={12}>
        <CustomSeparator
          breadcrumbs={[
            <Link
              style={{ color: "#bebebe", textDecoration: "none" }}
              underline="hover"
              key="1"
              color="inherit"
              to={`/offers?page=${pageNumber}`}
            >
              {t("offer.offers")}
            </Link>,

            <Typography
              key="3"
              style={{ color: "#bebebe", textDecoration: "none" }}
            >
              {formType == "edit" ? t("offer.editOffer") : t("offer.createNew")}
            </Typography>,
          ]}
        />
      </Grid>
      <Grid container item sm={12}>
        <Grid item sm={5} md={6}>
          <Typography
            component="h1"
            style={{
              fontSize: "30px",
              fontWeight: "bold",
            }}
            className="mb-20px"
          >
            {formType == "edit"
              ? t("offer.editOffer")
              : t("offer.createNewOffer")}
          </Typography>
        </Grid>
        <Grid item sm={5} textAlign="end" md={6}>
          <PrimaryButton
            text={t("offer.cancel")}
            classBtn="secoundry r-25px"
            style={{ padding: "5px 20px", height: "48px", margin: "0 10px" }}
            click={() => navigate(`/offers?page=${pageNumber}`)}
          />
          <PrimaryButton
            text={
              formType == "edit" ? t("offer.apply") : t("offer.createOffer")
            }
            classBtn="primary r-25px"
            style={{ padding: "5px 20px", height: "48px", margin: "0 10px" }}
            click={() => formik.handleSubmit()}
          />
        </Grid>
      </Grid>
      <Grid item rowSpacing={1} columnSpacing={{ xs: 2 }} container lg={12}>
        <Grid item md={6} className="activity-form" p={'20px !important'} mt={1}>
          <OfferInfo
            branchesList={branchesList}
            activitiesList={activitiesList}
            userId={userId}
            getBranches={getBranches}
            getActivities={getActivities}
            branchData={branchData}
            setBranchData={setBranchData}
            formik={formik}
            offerDetails={offerDetails}
            branchesCount={branchesCount}
            activitiesCount={activitiesCount}
            profileData={profileData}
            formType={formType}
          />
          {selectedActivity && (
            <Grid item container lg={12} mt={0.5}>
              <Box
                as="span"
                sx={{ fontSize: "16px", color: "#333132", fontWeight: "bold" }}
              >
                Activity price :
                <Box
                  as="span"
                  sx={{
                    fontSize: "16px",
                    color: "#333132",
                    fontWeight: "bold",
                  }}
                  class="text-style-1"
                >
                  {selectedActivity?.price_after_discount}{" "}
                  {selectedActivity?.currency?.lookup_key}
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>

        <Grid item md={6}>
          {!loading && (
            <OfferTime
              endDate={endDate}
              setEndDate={setEndDate}
              startDate={startDate}
              setStartDate={setStartDate}
              createStartDate={createStartDate}
              createEndDate={createEndDate}
            />
          )}
        </Grid>
      </Grid>
    </Stack>
  );
};

export default withTranslation()(OfferForm);
