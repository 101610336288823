import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import TextFieldInput from "../../Components/Atoms/Input/TextField/TextFieldInput";
import UploadImagesForm from "../../Components/Molecules/Forms/UploadImagesForm";
import DiveOperationInformation from "../../Components/Organisms/Auth/Account/Forms/diveOperationInformation";
import AboutOperator from "../../Components/Organisms/Auth/Account/Forms/aboutOperator";
import FreelancePolicy from "../../Components/Organisms/Auth/Account/Forms/freelancePolicy";
import ContactPersonInfo from "../../Components/Organisms/Auth/Account/Forms/ContactPersonInfo";
import BankAccountDetails from "../../Components/Organisms/Auth/Account/Forms/BankAccountDetails";
import AuthLayout from "../../Components/Organisms/Auth/Layout";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { icons } from "../../Assets/AssetHelper";
import FileInput from "../../Components/Atoms/Input/FileInput/FileInput";
import PrimaryButton from "../../Components/Atoms/Buttons/Primary/PrimaryButton";
import "./CompleteAccountTemplate.css";
import { withTranslation } from "react-i18next";
import ReactPhoneInput2 from "../../Components/Atoms/Input/PhoneInput/ReactPhoneInput2";

const CompleteAccountTemplate = ({
  t,
  languagesList,
  currencies,
  formik,
  cities,
  uploadActivityImages,
  removeActivityImage,
  activityImages,
  setContract,
  setUploadedContract,
  contractName,
  setContractName,
  countries,
  getLanguagesAPI,
  getCountries,
  getCities,
  setCities
}) => {
  // const [contractName, setContractName] = useState(null)

  useEffect(() => {
    if (formik?.values.primary_language_id?.toString().length === 0) {
      getLanguagesAPI(null);      
    }
  }, [formik?.values.primary_language_id]);
  return (
    <AuthLayout
      title=" Welcome to our diving world"
      subTitle="Complete your profile"
    >
      <Container maxWidth="XL" className="complete-profile">
        <form onSubmit={formik.handleSubmit}>
          <Stack flexDirection={{ sm: "column", md: "row" }} width="100%">
            <Grid container width={"210px"}>
              <UploadImagesForm
                type="single"
                uploadImages={uploadActivityImages}
                formTitle={t("profile.logoUpload")}
                uploadText={t("profile.addLogo")}
                Images={activityImages}
                RemoveImage={removeActivityImage}
                uploadIcon={icons.AddLogo}
                hideSizeTip={true}
              />
            </Grid>
            <Grid
              container
              className="activity-form"
              gap={2}
              width={{ sm: "100%", md: "52%" }}
            >
              <Typography
                component="h1"
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  borderBottom: "2px solid var(--primary-color)",
                }}
                className="mb-20px"
              >
                {t("profile.ownerInformation")}
              </Typography>
              <Stack flexDirection="row" width="100%" gap={2}>
                <TextFieldInput
                  name="owner_name"
                  id="owner_name"
                  placeholder={t("profile.ownerName")}
                  className="login-input"
                  type="text"
                  value={formik.values.owner_name}
                  error={
                    formik.touched.owner_name &&
                    Boolean(formik.errors.owner_name)
                  }
                  helperText={
                    formik.touched.owner_name && formik.errors.owner_name
                  }
                  clickEnter={formik.handleSubmit}
                  onChange={formik.handleChange}
                />
                  <ReactPhoneInput2
                    formik={formik} 
                    name="owner_phone_number"
                    customContainerClass="owner-info-phone-input"
                    placeholder={t("profile.ownermobile")}
                    specialLabel={t("profile.ownermobile")}
                    phoneProperty="owner_phone_number"
                    codeProperty="owner_country_code"
                    enableSearch={true}
                    showBorder={true}
                    // defaultCountryCode prop to set default selected country from code ddl
                    // defaultCountryCode={"eg"}
                  />
              </Stack>
              <TextFieldInput
                name="owner_email"
                id="owner_email"
                placeholder={t("profile.ownerEmailAddress")}
                className="login-input "
                type="email"
                style={{ width: "50%" }}
                onChange={formik.handleChange}
                value={formik.values.owner_email}
                error={
                  formik.touched.owner_email &&
                  Boolean(formik.errors.owner_email)
                }
                helperText={
                  formik.touched.owner_email && formik.errors.owner_email
                }
                clickEnter={formik.handleSubmit}
              />
            </Grid>
            <Stack
              width={{ sm: "100%", md: "30%" }}
              flexDirection="row"
              gap={1}
              mt={1.5}
            >
              <Stack
                className="activity-form"
                border="1px solid #eee"
                borderRadius="8px"
                direction="column"
                width="50%"
                padding="5px"
                alignItems="center"
                gap={2}
              >
                <Typography
                  component="h1"
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    borderBottom: "2px solid var(--primary-color)",
                  }}
                >
                  {t("profile.downloadContract")}
                </Typography>

                <Stack
                  border="1px solid #bfbfbf"
                  borderRadius="8px"
                  justifyContent="center"
                  alignItems="center"
                  padding="10px"
                  height="140px"
                  maxWidth="85%"
                  width="140px"
                >
                  <a
                    href={localStorage.getItem("contract")}
                    style={{
                      textDecoration: "none",
                      color: "#f6b119",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "0.5rem",
                    }}
                    download
                  >
                    <img src={icons.downloadImg} width="40px" />
                    {t("profile.downloadAgreementContract")}
                  </a>
                </Stack>
              </Stack>
              <Box
                className="activity-form"
                width="50%"
                padding="5px"
                display="flex"
                alignItems="center"
                flexDirection="column"
              >
                <Typography
                  component="h1"
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    borderBottom: "2px solid var(--primary-color)",
                  }}
                  className="mb-20px"
                >
                  {t("profile.uploadContract")}
                </Typography>

                <Stack
                  border="1px solid #bfbfbf"
                  borderRadius="8px"
                  justifyContent="center"
                  alignItems="center"
                  padding="10px"
                  maxWidth="85%"
                  width="140px"
                  height="140px"
                >
                  {(!contractName || contractName == null) && (
                    <>
                      <FileInput
                        icon={icons.uploadImg}
                        change={(event) => {
                          setContract(event);
                          // setContractName(event?.target?.files[0]?.name)
                        }}
                        fileName={contractName}
                        accept="image/*,application/pdf"
                        btnStyle="upload-contract-box"
                        uploadText="Upload signed agreement contract"
                        textStyle={"upload-contract-input"}
                      />
                    </>
                  )}
                  {contractName && (
                    <Grid position={"relative"} width={"inherit"}>
                      <Grid className="contract-card">
                        <img src={icons?.Contract} alt="" />
                        <Typography> {contractName} </Typography>
                      </Grid>
                      <PrimaryButton
                        btnType="icon"
                        startIcon={<img src={icons.DeleteIcon} alt="delete" />}
                        style={{
                          position: "absolute",
                          bottom: "-23px",
                          right: "calc(50% - 29px)",
                        }}
                        click={() => {
                          setUploadedContract(null);
                          setContractName(null);
                        }}
                      />
                    </Grid>
                  )}
                </Stack>
              </Box>
            </Stack>
          </Stack>
          <Stack flexDirection="row" gap={2} width="100%">
            <Box width="48%">
              <DiveOperationInformation
                formik={formik}
                languagesList={languagesList}
                getLanguagesAPI={getLanguagesAPI}
              />
              <AboutOperator formik={formik} />
            </Box>

            <Box width="48%">
              <FreelancePolicy formik={formik} />
              <ContactPersonInfo formik={formik} />
              <BankAccountDetails
                formik={formik}
                currencies={currencies}
                cities={cities} 
                countries={countries} 
                getCountries={getCountries}
                getCities={getCities} 
                setCities={setCities}
              />
            </Box>
          </Stack>
          <Button
            style={{
              borderRadius: "26px",
              float: "left",
              width: "25%",
              backgroundColor: "var(--primary-color)",
              color: "white",
              padding: "5px 20px",
              height: "48px",
              margin: "0 10px",
            }}
            type="submit"
          >
            {t("profile.done")}
          </Button>
        </form>
      </Container>
    </AuthLayout>
  );
};

export default withTranslation()(CompleteAccountTemplate);
