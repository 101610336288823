import LoginLogo from "./Icons/login-logo.svg";
import HeaderLogo from "./Images/headerlogo.png";
import InovaLogo from "./Images/inova.png";
import SideIcon from "./Icons/icon-menu.svg";
import HomeIcon from "./Icons/dashboard.svg";
import Branches from "./Icons/branches.svg";
import Activity from "./Icons/activity.svg";
import Financials from "./Icons/financials.svg";
import Freelancers from "./Icons/freelancers.svg";
import Freelancer from "./Icons/freelancer.svg";
import EmptyUsers from "./Icons/empty-users.svg";
import Roles from "./Icons/roles.svg";

import Offers from "./Icons/offers.svg";
import Reservations from "./Icons/reservations.svg";
import Sales from "./Icons/sales.svg";
import Users from "./Icons/users.svg";
import Terms from "./Icons/terms.svg";
import Help from "./Icons/help.svg";
import Covid from "./Icons/covid.svg";
import PrivacyPolicy from "./Icons/privacypolicy.svg";
import Notifications from "./Icons/notifications.svg";
import Language from "./Icons/language.svg";
import Avatar from "./dummy/avatar.png";
import EditIcon from "./Icons/edit-icon.svg";
import DeleteIcon from "./Icons/delete-icon.svg";
import NewTab from "./Icons/new-tab.svg";
import AddImage from "./Icons/add-image.svg";
import UserAvatar from "./Icons/userAvatar.svg";
import Favorite from "./Icons/favorite.svg";
import UploadIcon from "./Icons/uploadIcon.svg";
import LogoutIcon from "./Icons/logout.svg";
import PasswordIcon from "./Icons/password.svg";
import CheckIcon from "./Icons/check.svg";
import UploadOutline from "./Icons/uploadOutline.svg";
import DownLoadOutline from "./Icons/downloadOutline.svg";
import OffersEmpty from "./Icons/offersempty.svg";

import CustomerServices from "./Icons/customer-services.svg";
import Efficient from "./Icons/efficient.svg";
import LocalExpert from "./Icons/local-expert.svg";
import Prompt from "./Icons/prompt.svg";
import SalesConsultant from "./Icons/sales-consultant.svg";
import Warning from "./Icons/warning.svg";
import NoReservations from "./Icons/no-reservations.svg";

import downloadImg from "./Icons/download.png";
import uploadImg from "./Icons/upload.png";

import HelpQuestion from "./Icons/helpquestion.svg";
import download from "./Icons/download.svg";

import Contract from "./Icons/contract.svg";
import Location from "./Icons/location.svg";
import Wallet from "./Icons/wallet.svg";
import ReviewLogo from "./Images/reviewlogo.png";
import CompleteLogo from "./Images/completelogo.png";
import AddLogo from "./Icons/add-logo.svg";
import NoBranches from "./Icons/no-branches.svg";
import NoActivities from "./Icons/no-activity.svg";
import InHouseActivity from "./Icons/in-house-activity.svg";
import ApprovedActivity from "./Icons/approved-activity.svg";

import Activity2 from "./Icons/activity2.svg";
import BranchRejected from "./Icons/branch-rejected.svg";
import BranchAccepted from "./Icons/branch-accepted.svg";
import JobOffer from "./Icons/job-offer.svg";
import Points from "./Icons/points.svg";
import Reservation from "./Icons/reservation.svg";
import Review from "./Icons/review.svg";
import ComplainNotification from "./Icons/complaint-notification.svg";
import SystemNotificaion from "./Icons/System-notification.svg";
import pdfPlaceholder from "./Icons/pdfPlaceholder.png";

export const images = {
  HeaderLogo,
  InovaLogo,
  ReviewLogo,
  CompleteLogo
};

export const icons = {
  pdfPlaceholder,
  SystemNotificaion,
  ComplainNotification,
  Activity2,
  BranchRejected,
  BranchAccepted,
  Review,
  Points,
  Reservation,
  JobOffer,
  NoReservations,
  Warning,
  Roles,
  EmptyUsers,
  OffersEmpty,
  Freelancer,
  ApprovedActivity,
  download,
  CustomerServices,
  Efficient,
  LocalExpert,
  Prompt,
  SalesConsultant,
  downloadImg,
  uploadImg,
  UploadOutline,
  DownLoadOutline,
  PasswordIcon,
  CheckIcon,
  LoginLogo,
  SideIcon,
  HomeIcon,
  Branches,
  Activity,
  Financials,
  Freelancers,
  Offers,
  Reservations,
  Sales,
  Users,
  Wallet,
  Terms,
  Help,
  Covid,
  PrivacyPolicy,
  Notifications,
  Language,
  EditIcon,
  DeleteIcon,
  NewTab,
  AddImage,
  UserAvatar,
  Favorite,
  UploadIcon,
  LogoutIcon,
  HelpQuestion,
  Contract,
  Location,
  AddLogo,
  NoBranches,
  NoActivities,
  InHouseActivity,
};

export const dummy = {
  Avatar,
};
