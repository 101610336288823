import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import styled from "@emotion/styled";
import { Grid, Typography, Divider, Stack, Rating } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
const BranchRating = ({ t, branchDetails }) => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");

  const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
      color: "#3ed181            ",
    },
    "& .MuiRating-iconHover": {
      color: "#ff3d47",
    },
  });
  return (
    <Grid container className="details-panel">
      <Grid item md={12}>
        <Typography component="h1" width="fit-content">
          {t("branch.ratingReview")}
        </Typography>
      </Grid>
      <Grid
        item
        md={5}
        style={{ cursor: "pointer", height: "fit-content", marginTop: "10px" }}
        flexDirection="row"
        alignItems="center"
        gap={1}
        onClick={() =>
          navigate(
            `/branch-reviews/${branchDetails?.id}?type=customer&page=${pageNumber}`
          )
        }
      >
        <Typography color="#bfbfbf" fontSize="16px">
          {t("branch.customerRatingReview")}
        </Typography>
        <Stack width="100%" flexDirection="row" alignItems="center">
          <StyledRating
            readOnly
            name="customized-color"
            value={branchDetails?.customer_avg_rate}
            icon={
              <CircleIcon fontSize="inherit" style={{ fontSize: "14px" }} />
            }
            emptyIcon={
              <CircleOutlinedIcon
                fontSize="inherit"
                style={{ color: "#3ed181", fontSize: "14px" }}
              />
            }
          />
          <Typography style={{ color: "#3ed181", margin: "3px" }}>
            ( {branchDetails?.customer_reviews_count} {t("profile.review")} )
          </Typography>
        </Stack>
      </Grid>

      <Grid
        item
        md={6}
        style={{ cursor: "pointer", height: "fit-content", marginTop: "10px" }}
        flexDirection="row"
        alignItems="center"
        gap={1}
        onClick={() =>
          navigate(
            `/branch-reviews/${branchDetails?.id}?type=freelancer&page=${pageNumber}`
          )
        }
      >
        <Typography color="#bfbfbf" fontSize="16px">
          {t("branch.freelancerRatingReview")}
        </Typography>
        <Stack width="100%" flexDirection="row" alignItems="center">
          <StyledRating
            readOnly
            name="customized-color"
            value={branchDetails?.dive_expert_avg_rate}
            icon={
              <CircleIcon fontSize="inherit" style={{ fontSize: "14px" }} />
            }
            emptyIcon={
              <CircleOutlinedIcon
                fontSize="inherit"
                style={{ color: "#3ed181", fontSize: "14px" }}
              />
            }
          />
          <Typography style={{ color: "#3ed181", margin: "3px" }}>
            ( {branchDetails?.dive_expert_reviews_count} {t("profile.review")} )
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(BranchRating);
