import { useState, useEffect } from "react";
import {
  getPresignedLinkAPI,
  uploadFileAPI,
} from "../../../Contexts/APIs/General/upload.js";
import { OrganizationsAPI } from "../../../Contexts/APIs/Lookups/Certificates/Organizations.js";
import {
  createNewBranch,
  EditBranch,
  getBranchDetails,
} from "../../../Contexts/APIs/Branches/Branches.js";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Loader from "../../../Components/Atoms/Loader/Loader.js";
import { getFeatureOptions } from "../../../Contexts/APIs/Lookups/FeaturesOptions.js";
import { getSupportedLanguages } from "../../../Contexts/APIs/Lookups/SupportedLanguages.js";
import EditBranchTemplate from "../../../Templates/Main/Branches/EditBranch.template.jsx";
import { toast } from "react-toastify";

const EditBranchPage = (props) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [organizationsCount, setOrganizationsCount] = useState(10);

  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  const { t, formType } = props;
  const { id } = useParams();
  const [branchDetails, setBranchDetails] = useState({});

  const GetbranchDetails = async () => {
    const { res, err } = await getBranchDetails(id);
    if (res) {
      setBranchDetails(res?.data.branch);
    }
  };

  useEffect(() => {
    GetbranchDetails();
  }, []);
  const [organizationsList, setOrganizationsList] = useState([]);
  const Navigate = useNavigate();
  const [openLoader, setOpenLoader] = useState(false);
  const [branchImages, setBranchImages] = useState([]);
  const [localeLicenseImg, setLocaleLicenseImg] = useState({});
  const [licenseImg, setLicenseImg] = useState({});
  const [featureOptions, setFeatureOptions] = useState([]);
  const [supportedLanguages, setSupportedLanguages] = useState([]);
  let requestCounter = 0;
  let requestStatus = "";


  const uploadImage = async (event, str = "") => {
    const numberOfImages = event.target.files?.length + branchImages?.length;
    const file = event.target.files[0];
    const fileSize = file.size / window.maxFileSize; // in MiB
    if (fileSize > 1) {
      toast.warning(window.fileSize);
    } else {
      if (event.target.files?.length == 1) {
        setOpenLoader(true);
        if (str === "local_license") {
          setLocaleLicenseImg({
            media_type: "photo",
            url: URL.createObjectURL(event.target.files[0]),
            file_type: event.target.files[0]?.type,
            file: event?.target?.files[0],
          });
        } else if (str === "license") {
          setLicenseImg({
            media_type: "photo",
            url: URL.createObjectURL(event.target.files[0]),
            file_type: event.target.files[0]?.type,
            file: event?.target?.files[0],
          });
        } else {
          setBranchImages([
            ...branchImages,
            {
              media_type: "photo",
              url: URL.createObjectURL(event.target.files[0]),
              file_type: event.target.files[0]?.type,
              file: event?.target?.files[0],
            },
          ]);
        }
        setOpenLoader(false);
      } else if (numberOfImages <= 10) {
        setOpenLoader(true);
        Array.from(event.target.files).forEach(async (file, index) => {
          const fileSize = file.size / window.maxFileSize; // in MiB
          if (fileSize > 1) {
            toast.warning(window.fileSize);
          } else {
            setBranchImages((s) => {
              return [
                ...s,
                {
                  media_type: "photo",
                  url: URL.createObjectURL(file),
                  file_type: event.target.files[0]?.type,
                  file: event?.target?.files[0],
                },
              ];
            });
          }
        });
        setTimeout(() => {
          setOpenLoader(false);
        }, 500);
      } else {
        toast.error("you exceeded the maximum number of images");
      }
    }
  };

  const RemoveBranchImage = (i) => {
    if (i > -1) {
      branchImages.splice(i, 1);
      setBranchImages([...branchImages]);
    }
  };

  const RemoveLocaleLicenseImg = () => {
    setLocaleLicenseImg({});
  };

  const getOrganization = async (filter) => {
    const { res, err } = await OrganizationsAPI({ page_size: 10, ...filter });
    if (res) {
      filter?.page_number == 1
        ? setOrganizationsList(res?.data?.organizations)
        : setOrganizationsList((s) => [...s, ...res?.data?.organizations]);
      setOrganizationsCount(res?.extra?.total_count);
    }
    if (err) {
    }
  };

  const createBranch = async (branchObj) => {
    setOpenLoader(true);
    const { res, err } = await createNewBranch(branchObj);
    if (res) {
      Navigate(`/branches?page=${pageNumber}`);
    }
    setOpenLoader(false);
  };

  const editBranch = async (branch_id, branchObj) => {
    ++requestCounter;
    requestStatus = "pending";
    
    if( requestStatus === "pending" && requestCounter === 1 ){
      setOpenLoader(true);
      const { res, err } = await EditBranch(branch_id, branchObj);
      if (res) {
        requestStatus = "success";
        Navigate(`/branches?page=${pageNumber}`);
        requestStatus = "";
        requestCounter = 2;
      }
      else{
        requestStatus = "fail";
        requestCounter = 0;
      }
      setOpenLoader(false);
    }
  };

  const getFeaturesOptions = async (filter_obj) => {
    const { res, err } = await getFeatureOptions(filter_obj);
    if (res) {
      setFeatureOptions(res?.data?.feature_options);
    }
  };
  const getLanguages = async () => {
    const { res, err } = await getSupportedLanguages();
    if (res) {
      setSupportedLanguages(res?.data?.supported_locales);
    }
  };

  useEffect(() => {
    getOrganization();
    getLanguages();
  }, []);
  useEffect(() => {
    const imagesData = branchDetails?.media
      ?.map((data) => {
        if (data?.media_option == "default")
          return {
            media_type: data?.media_type,
            url: data?.url,
            id: data?.id,
          };
      })
      .filter((val) => val);
    setBranchImages(imagesData);

    if (branchDetails?.local_gov_license?.url)
      setLocaleLicenseImg({
        media_type: "photo",
        url: branchDetails?.local_gov_license?.url,
      });
    if (branchDetails?.branch_organizations_attributes?.license_url) {
      setLicenseImg({
        media_type: "photo",
        url: branchDetails?.branch_organizations_attributes?.license_url,
      });
    }
  }, [branchDetails]);

  return (
    <>
      <Loader open={openLoader} />
      <EditBranchTemplate
        getOrganization={getOrganization}
        organizationsCount={organizationsCount}
        uploadImages={uploadImage}
        editBranch={editBranch}
        organizationsList={organizationsList}
        createBranch={createBranch}
        branchImages={branchImages}
        RemoveBranchImage={RemoveBranchImage}
        uploadLocalLicense={(e) => uploadImage(e, "local_license")}
        uploadLicense={(e) => uploadImage(e, "license")}
        localeLicenseImg={localeLicenseImg}
        licenseImg={licenseImg}
        getFeaturesOptions={getFeaturesOptions}
        featureOptions={featureOptions}
        supportedLanguages={supportedLanguages}
        branchDetails={branchDetails}
        formType={formType}
        RemoveLocaleLicenseImg={RemoveLocaleLicenseImg}
        licensesData={branchDetails?.branch_organizations}
        type="edit"
        getbranchDetails={GetbranchDetails}
        id={id}
      />
    </>
  );
};

export default EditBranchPage;
