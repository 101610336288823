import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import {
  getUsersListSuper,
  superLogin,
} from "../../../Contexts/APIs/Auth/SelectUserSuper";
import SelectUserSuperTemplate from "../../../Templates/Auth/SelectUserSuper/SelectUserSuper.template";

function SelectUserSuperPage() {
  const [usersListSuper, setUsersListSuper] = useState([]);
  const [userListCount, setUsersListCount] = useState(0);

  const filter_obj = {
    user_type: "dive_center",
    page_size: 10,
    page_number: 1,
  };
  const getUsersData = async (filter) => {
    const { res, err } = await getUsersListSuper({ ...filter_obj, ...filter });
    if (res) {
      if (filter?.page_number == 1) {
        console.log(filter, "filter_obj");
        setUsersListSuper(res?.data);
      } else {
        setUsersListSuper((prev) => [...prev, ...res?.data]);
      }
      setUsersListCount(res?.extra?.total_count);
    }
  };

  // useEffect(() => {
  //   getUsersData();
  // }, []);

  const formik = useFormik({
    initialValues: {
      user_id: null,
      user_name: "",
    },
    onSubmit: async (values) => {
      const { res, err } = await superLogin("dive_center", values.user_id);
      if (res) {
        localStorage.setItem("accessToken", res?.data?.extra?.access_token);
        localStorage.setItem("userId", res?.data?.user?.id);
        localStorage.setItem("contract", res.data?.user?.dive_center_contract);
        localStorage.setItem("email", res.data?.user?.email);
        localStorage.setItem("name", res.data?.user?.name);
        if (!localStorage.getItem("fcm_token"))
          localStorage.setItem("fcm_token", window.navigator.userAgent);

        if (!res?.data?.user?.is_password_changed) {
          window.location.href = "/change-password";
        } else if (res?.data?.user?.profile_status == "incomplete") {
          window.location.href = "/complete-account";
        } else if (res?.data?.user?.profile_status == "pending_approval") {
          window.location.href = "/pending-review";
        } else if (res?.data?.user?.profile_status == "rejected") {
          localStorage.setItem("Msg", res.data?.user?.rejection_reason);
          window.location.href = "/profile-rejected";
        } else if (res?.data?.user?.profile_status == "approved") {
          localStorage.setItem("Token", res?.data?.extra?.access_token);
          window.location.href = "/profile";
        }
      }
    },
  });

  return (
    <SelectUserSuperTemplate
      formik={formik}
      usersListSuper={usersListSuper}
      getUsersData={getUsersData}
      userListCount={userListCount}
    />
  );
}

export default SelectUserSuperPage;
