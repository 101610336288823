import React from 'react'
import { toast } from 'react-toastify';
import { changePassword } from '../../Contexts/APIs/Auth/Changepassword';
import ChangePasswordTemplate from '../../Templates/Auth/ChangePassword.Template'

const ChangePasswordPage = ({ t }) => {

    const ChangePassword = async (type, data, user_id) => {
        const { res, err } = await changePassword(type, data, user_id);
        if (res) {

            if (res?.data?.user?.profile_status == "incomplete") {
                window.location.href = "/complete-account"
            } else if (res?.data?.user?.profile_status == "pending_approval") {
                window.location.href = "/pending-review"
            } else if (res?.data?.user?.profile_status == 'approved') {
                localStorage.setItem("Token", res?.data?.extra?.access_token);
                window.location.href = "/"
            }
        }
    };
    return (
        <ChangePasswordTemplate t={t} ChangePassword={ChangePassword} />
    )
}

export default ChangePasswordPage