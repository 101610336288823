import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../Components/Atoms/Loader/Loader";
import { getFreelancerDetails } from "../../../Contexts/APIs/Freelancers/Freelancers";
import { showJobOffer } from "../../../Contexts/APIs/Job-Offers/JobOffers";
import FreelancerDetailsTemplate from "../../../Templates/Main/Freelancers/FreelancerDetails.Template";

const FreelancerDetailsPage = () => {
  const { jop_offer_id, applicant_id } = useParams();
  const [jobOfferData, setJobOfferData] = useState({});
  const [applicantData, setApplicantData] = useState({});

  const [openLoader, setOpenLoader] = useState(false);

  const getJobOfferData = async () => {
    setOpenLoader(true);
    const { res, err } = await showJobOffer(jop_offer_id);
    if (res) setJobOfferData(res?.data?.job_offer);
    setOpenLoader(false);
  };

  const getApplicantDetails = async () => {
    setOpenLoader(true);
    const { res, err } = await getFreelancerDetails(applicant_id);
    if (res) setApplicantData(res?.data?.user);
    setOpenLoader(false);
  };

  useEffect(() => {
    getApplicantDetails();
  }, []);

  useEffect(() => {
    if (jop_offer_id) getJobOfferData();
  }, [jop_offer_id]);
  return (
    <>
      <Loader open={openLoader} />
      <FreelancerDetailsTemplate
        jobOfferData={jobOfferData}
        applicantData={applicantData}
      />
    </>
  );
};

export default FreelancerDetailsPage;
