import React, { useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Grid, Button, Menu, MenuItem } from "@mui/material";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { updateSalesmanReservationsList } from "../../../../Contexts/APIs/Salsemen/SalesmenReservations";

const SalesmenReservationListItem = ({
  t,
  listItem,
  setOpenReservationDetails,
  getReservations,
  setOpenLoader,
  salesmenReservationsPrivilages,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const userType = localStorage.getItem("userType");
  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  const handleClick = (event, listItem, index) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReservationAction = async (id, val) => {
    setOpenLoader(true);
    const { res, err } = await updateSalesmanReservationsList(id, {
      salesman_reservation: { status: val },
    });
    if (res) {
      getReservations({ page_number: "1", page_size: "1000" });
    }
    setOpenLoader(false);
  };

  const pendingOptions = [
    {
      title: t("salesmen.confirm"),
      action: () => handleReservationAction(listItem?.id, "confirmed"),
    },
    {
      title: t("salesmen.cancel"),
      action: () => handleReservationAction(listItem?.id, "cancelled"),
    },
  ];

  const menuOptions = {
    pending: pendingOptions,
  };

  return (
    <>
      <Grid item md={1} textAlign="center" minWidth="61px">
        {listItem?.id}
      </Grid>
      <Grid
        item
        sm={2}
        fontWeight="700"
        style={{
          textDecoration: "underline",
          height: "35px",
          minWidth: "140px",
          cursor: "pointer",
        }}
        onClick={() => setOpenReservationDetails(true)}
      >
        {listItem?.participants?.name}
      </Grid>
      <Grid item sm={2} minWidth="180px" fontWeight="700">
        {listItem?.inhouse_activity_group?.inhouse_activity?.name}
      </Grid>
      <Grid item sm={2} minWidth="122px">
        {moment(listItem?.inhouse_activity_group?.start_date)?.format(
          "MMM DD, YYYY"
        )}
      </Grid>
      <Grid item sm={2} minWidth="122px">
        {listItem?.inhouse_activity_group?.inhouse_activity?.branch?.name}
      </Grid>
      <Grid item sm={2} minWidth="183px">
        {moment(listItem?.created_at)?.format("MMM DD, YYYY,hh A")}
      </Grid>
      <Grid item sm={2} minWidth="170px">
        {listItem?.reservation_number}
      </Grid>

      <Grid item sm={2} minWidth="81px">
        {listItem?.reserved_number_of_spots}
      </Grid>
      <Grid item sm={2} minWidth="122px">
        {listItem?.discount_percentage}%
      </Grid>
      <Grid item sm={2} minWidth="250px" fontWeight="700">
        {listItem?.salesman?.name}
      </Grid>
      <Grid item sm={2} minWidth="122px" fontWeight="700">
        {listItem?.total_amount} {listItem?.currency?.lookup_key}
      </Grid>
      <Grid
        item
        sm={2}
        display="flex"
        alignItems="baseline"
        minWidth="122px"
        textTransform="capitalize"
        color={
          listItem?.status === "pending"
            ? "#F6B119"
            : listItem?.status === "confirmed"
            ? "#00B906"
            : listItem?.status === "cancelled"
            ? "#F62F19"
            : "#333132"
        }
      >
        {listItem?.status}
      </Grid>

      {((userType === "descendant_user" && salesmenReservationsPrivilages?.can_update) ||
        userType !== "descendant_user") ? (
        <Grid item sm={2}>
          {menuOptions[listItem?.status] ? (
            <Grid item sm={1}>
              <Button
                id="demo-positioned-button"
                aria-controls={open ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={(e) => handleClick(e)}
              >
                <MoreHorizIcon style={{ color: "black" }} />
              </Button>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                {menuOptions[listItem?.status]?.map((val, index) => {
                  return (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        val?.action();
                        handleClose();
                      }}
                    >
                      {val?.title}
                    </MenuItem>
                  );
                })}
              </Menu>
            </Grid>
          ) : (
            <Grid item sm={2} ml={'1.9rem'}>-</Grid>
          )}
        </Grid>
      ):<Grid item sm={2} ml={'1.9rem'}>-</Grid>}
    </>
  );
};

export default withTranslation()(SalesmenReservationListItem);
