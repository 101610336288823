import { Grid, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import "./style.css";
import { withTranslation } from "react-i18next";
import ApprovedActivityTabs from "../../Molecules/ApprovedActivity/Tabs";
import ApprovedActivityPanal from "../../Molecules/ApprovedActivity/Panel";

const ApprovedActivitiesList = (props) => {
  const {
    t,
    activitesList,
    setActivitiesList,
    count,
    getActivities,
    activitiesPrivilages,
    setOpenLoader,
    filterObj,
    setFilterObj,
    profileData
  } = props;
  const [ActivityValue, setActivityValue] = useState(0);

  const [searchKeywords, setSearchKeywords] = useState("");
  const [page, setPage] = useState(1);

  const updatePagination = (page) => {
    setFilterObj({ ...filterObj, page_number: page, keyword: searchKeywords });
  };
  const getBranchType = (value) => {
    if (value === 0) {
      setPage(1);
      setFilterObj({ ...filterObj, page_number: 1, activity_type: "" });
    }
    if (value === 1) {
      setPage(1);
      setFilterObj({
        ...filterObj,
        page_number: 1,
        activity_type: "dive_trip",
      });
    }
    if (value === 2) {
      setPage(1);
      setFilterObj({
        ...filterObj,
        page_number: 1,
        activity_type: "dive_course",
      });
    }
    if (value === 3) {
      setPage(1);
      setFilterObj({
        ...filterObj,
        page_number: 1,
        activity_type: "live_aboard",
      });
    }
  };
  const getKeywords = (keyword) => {
    setSearchKeywords(keyword);
    setFilterObj({ ...filterObj, activity_name: keyword });
  };

  const ActivitiesTypes = [
    {
      id: 0,
      label: t("activity.all"),
    },
    {
      id: 1,
      label: t("activity.diveTrip"),
    },
    {
      id: 2,
      label: t("activity.courses"),
    },
    {
      id: 3,
      label: t("activity.liveaboard"),
    },
  ];
  const handleActivityValue = (event, newValue) => {
    setActivityValue(newValue);
  };
  return (
    <Grid container className="activities-list-container">
      <ApprovedActivityTabs
        getBranchType={getBranchType}
        getKeywords={getKeywords}
        options={ActivitiesTypes}
        handleActivityValue={handleActivityValue}
        ActivityValue={ActivityValue}
        activitiesNo={activitesList?.length}
        activitiesPrivilages={activitiesPrivilages}
        setFilterObj={setFilterObj}
        filterObj={filterObj}
        setActivityValue={setActivityValue}
        profileData={profileData}
      />
      <ApprovedActivityPanal
        options={ActivitiesTypes}
        ActivityValue={ActivityValue}
        activitesList={activitesList}
        setActivitiesList={setActivitiesList}
        count={count}
        page={page}
        setPage={setPage}
        updatePagination={updatePagination}
        activitiesPrivilages={activitiesPrivilages}
        getActivities={getActivities}
        filterObj={filterObj}
        setOpenLoader={setOpenLoader}
        setFilterObj={setFilterObj}
      />
    </Grid>
  );
};

export default withTranslation()(ApprovedActivitiesList);
