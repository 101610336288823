import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Loader from "../../../../Components/Atoms/Loader/Loader.js";
import JobOfferDetailsTemplate from "../../../../Templates/Main/Freelancers/JopOffers/JobOfferDetails.Template";
import { showJobOffer } from "../../../../Contexts/APIs/Job-Offers/JobOffers";
import { toast } from "react-toastify";

const JobOfferDetailsPage = () => {
  const { id } = useParams();
  const [jobOfferData, setJobOfferData] = useState({});
  const [openLoader, setOpenLoader] = useState(false);

  const getJobOfferData = async () => {
    setOpenLoader(true);
    const { res, err } = await showJobOffer(id);
    if (res) setJobOfferData(res?.data?.job_offer);
    setOpenLoader(false);
  };

  useEffect(() => {
    id && setTimeout(() => {
      getJobOfferData();      
    }, 1000); 
  }, [id]);

  return (
    <>
      <Loader open={openLoader} />
      <JobOfferDetailsTemplate jobOfferData={jobOfferData} />
    </>
  );
};

export default JobOfferDetailsPage;
