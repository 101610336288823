import { Grid, Typography } from "@mui/material";
import { withTranslation } from "react-i18next";
import "./Panels.css";
import moment from "moment";
const BranchInfoPanel = (props) => {
  const { title, type, branchDetails, t } = props;

  const BranchInfo = [
    { label: t("branch.insuranceName"), input: branchDetails?.insurance_name },
    {
      label: t("branch.insurancePolicyNo"),
      input: branchDetails?.policy_number,
    },
    { label: t("branch.branchManager"), input: branchDetails?.manager_name },
  ];

  const BoatInfo = [
    {
      label: t("branch.noOfEngines"),
      input: branchDetails?.boat?.no_of_engines,
    },
    { label: t("branch.horsePower"), input: branchDetails?.boat?.horse_power },
    { label: t("branch.fuelTanks"), input: branchDetails?.boat?.fuel_tanks },

    {
      // label: t("branch.builtYear"),
      label: t("branch.builtYearRenovated"),      
      input: branchDetails?.boat?.year_built?.split("-")?.[0],
    },
    { label: t("branch.noOfCabins"), input: branchDetails?.boat?.total_cabins },
    { label: t("branch.length"), input: branchDetails?.boat?.length },
    { label: t("branch.width"), input: branchDetails?.boat?.width },
    {
      label: t("branch.additionalInformation"),
      input: branchDetails?.boat?.boat_features,
    },
  ];

  const Generator = [
    {
      label: t("branch.generatorType"),
      input: branchDetails?.boat?.generator_type,
    },
    {
      label: t("branch.kiloWatt"),
      input: branchDetails?.boat?.generator_kwatt,
    },
  ];

  const Info =
    type === "branch_info"
      ? BranchInfo
      : type === "boat_info"
      ? BoatInfo
      : Generator;

  return (
    <Grid container className="details-panel">
      <Typography className="mb-20px" component="h1">
        {title}
      </Typography>
      <Grid container>
        {Info?.map((info, index) => {
          if (info?.input)
            return (
              <Grid container className="mb-10px" gap={1} key={index}>
                <Grid item lg={4}>
                  <Typography style={{ fontWeight: "bold" }} component="h2">
                    {info?.label}
                  </Typography>
                </Grid>
                <Grid item lg={5}>
                  <Typography color="#bebebe" component="p">
                    {info?.input}
                  </Typography>
                </Grid>
              </Grid>
            );
        })}
      </Grid>
    </Grid>
  );
};

export default withTranslation()(BranchInfoPanel);
