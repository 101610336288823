import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "./URLs";
import { LogoutAPI } from "./Auth/Logout";
import Cookies from "js-cookie";
import { getBranchTranslations } from "./Lookups/Help";

const profile = getBranchTranslations("profile");

export function axiosFn(
  method,
  url,
  recivedData,
  auth = true,
  source,
  contentType = "application/json"
) {
  // const source = axios.CancelToken.source();

  let token =
    localStorage.getItem("Token") || localStorage.getItem("accessToken");

  let data = { ...recivedData };
  let verifyToken = localStorage.getItem("VERIFY");
  return axios({
    method,
    url: BASE_URL + url, //url === "/auth/token/create" ? BASE + url : BASE_URL + url,
    data,

    headers: {
      Authorization: verifyToken ? "" : `Bearer ${token}`,
      "Content-Type": contentType,
      "verification-token": verifyToken,
      "Accept-Language": localStorage.getItem("lang"),
      // Timezone: new Date().getTimezoneOffset(),
      Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      // "Access-Control-Allow-Origin":"*"
    },
  });
}

const Logout = async () => {
  const { res, err } = await LogoutAPI();
  if (res) {
    localStorage.clear();
    Cookies.remove("firebase_auth_token");
    window.location.reload();
  }
  localStorage.clear();
  Cookies.remove("firebase_auth_token");
  window.location.reload();
};

export function handleError(err) {
  let errMsg;
  if (err?.response){
    if(err?.response?.data?.error === 'invalid_token' && err.response.status === 401){
      Logout();      
    }else{
      toast.error(err?.response?.data?.message);
    }
    // following errMsg assign to making easy catch request status pending or not to prevent another calls during call api
    errMsg = err?.response?.data?.error;
  } 
  else toast.error(profile.somethingWentWrong);
  return {
    res: null,
    err: errMsg,
  };
}
