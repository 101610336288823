import { Grid, Stack } from "@mui/material";
import AuthLayout from "../../Components/Organisms/Auth/Layout";
import LoginForm from "../../Components/Organisms/Auth/Login/LoginForm";

const LoginTemplate = (props) => {
  const { t, Login } = props;

  return (
    <AuthLayout title=" Welcome to our diving world">
      <Stack alignItems="center" justifyContent="center" width="100%">
        <Grid
          container
          borderRadius="10px"
          boxShadow="0 2px 15px 0 rgba(0, 0, 0, 0.1)"
          backgroundColor="#fff"
          xs={6}
        >
          <Grid container xs={12} style={{ margin: "auto" }}>
            <LoginForm Login={Login} />
          </Grid>
        </Grid>
      </Stack>
    </AuthLayout>
  );
};

export default LoginTemplate;
