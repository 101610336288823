import React from "react";
import InHouseActivityDetailsHeader from "../../../Molecules/Salesmen/InHouseActivities/InHouseActivityDetailsHeader";
import InHouseActivityDetailsPanel from "../../../Molecules/Salesmen/InHouseActivities/InHouseActivityDetailsPanel";
import { Grid, Typography } from "@mui/material";
import CustomSeparator from "../../../Atoms/BreadCrumb";
import { Link, useSearchParams } from "react-router-dom";
import { withTranslation } from "react-i18next";

const InHouseActivityDetails = (props) => {
  let [searchParams, setSearchParams] = useSearchParams();
  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");

  const { activityDetails, RemoveActivity, inHouseActivitiesPrivilages, t } =
    props;
  return (
    <>
      <Grid container m={"0px 0px 24px"}>
        <CustomSeparator
          breadcrumbs={[
            <Link
              style={{ color: "#bebebe", textDecoration: "none" }}
              underline="hover"
              key="2"
              color="inherit"
              to={`/salesmen?page=${pageNumber}`}
            >
              {t("salesmen.salesmen")}
            </Link>,
            <Link
              style={{ color: "#bebebe", textDecoration: "none" }}
              underline="hover"
              key="2"
              color="inherit"
              to={`/salesmen/in-house-activities?page=${pageNumber}`}
            >
              {t("salesmen.inHouseActivity")}
            </Link>,
            <Typography
              key="3"
              style={{ color: "#bebebe", textDecoration: "none" }}
            >
              {activityDetails?.name}
            </Typography>,
          ]}
        />
      </Grid>
      <Grid container className="branch-details-container">
        <InHouseActivityDetailsHeader
          activityDetails={activityDetails}
          RemoveActivity={RemoveActivity}
          inHouseActivitiesPrivilages={inHouseActivitiesPrivilages}
        />
        <InHouseActivityDetailsPanel activityDetails={activityDetails} />
      </Grid>
    </>
  );
};

export default withTranslation()(InHouseActivityDetails);
