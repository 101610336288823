import { Grid } from "@mui/material";
import React from "react";
import AuthLayout from "../../../Components/Organisms/Auth/Layout";
import SelectUserSuper from "../../../Components/Organisms/Auth/Login/SelectUserSuper/SelectUserSuper";
import { withTranslation } from "react-i18next";

function SelectUserSuperTemplate(props) {
  const { formik, usersListSuper, t, getUsersData ,userListCount} = props;
  return (
    <AuthLayout title={t("profile.welcomeDivingWorld")}>
      <Grid container>
        <Grid
          container
          item
          xl={4}
          lg={5}
          md={5}
          sm={11}
          xs={12}
          style={{ margin: "auto" }}
        >
          <SelectUserSuper
            formik={formik}
            usersListSuper={usersListSuper}
            getUsersData={getUsersData}
            userListCount={userListCount}
          />
        </Grid>
      </Grid>
    </AuthLayout>
  );
}

export default withTranslation()(SelectUserSuperTemplate);
