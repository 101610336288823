import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import moment from "moment";
import { withTranslation } from "react-i18next";
import "./WalletListing.css";

import CustomPagination from "../../Atoms/Pagination/Pagination";
import BranchesFilter from "../Statistics/BranchesFilter";

const WalletListing = (props) => {
  const {
    t,
    WalletData,
    WalletList,
    count,
    page,
    handlePageChange,
    filterObj,
    setFilterObj,
    profileData,
  } = props;

  return (
    <Stack>
      <Grid container justifyContent={"space-between"}>
        <Typography
          component="h1"
          style={{ fontWeight: "bold", fontSize: "30px", color: "#333132" }}
        >
          {t("profile.wallet")}
        </Typography>
        <Stack direction={"row"} alignItems={"center"} gap={2}>
          <Box className="border-r-10px">
            <BranchesFilter
              profileData={profileData}
              setFilterObj={setFilterObj}
              filterObj={filterObj}
            />
          </Box>
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              gap: "23px",
              fontWeight: "bold",
              fontSize: "24px",
              color: "#333132",
            }}
          >
            {t("profile.totalBalance")} :
            <Typography style={{ fontSize: "36px", fontWeight: "bold" }}>
              {WalletData?.balance?.toFixed(2)} {WalletData?.currency}
            </Typography>
          </Grid>
        </Stack>
        <Divider
          style={{
            width: "100%",
            margin: "10px 0px 20px",
            backgroundColor: "#d9d9d9",
          }}
        />
      </Grid>
      <Grid container className="table-header wallet-item">
        <Grid md={0.5}>{t("branch.ID")}</Grid>
        <Grid md={3}>{t("profile.name")}</Grid>
        <Grid md={2}>{t("profile.branch")}</Grid>
        <Grid md={1.5}>{t("profile.date")}</Grid>
        <Grid md={1.5}>{t("profile.commission_percentage")}</Grid>
        <Grid md={1.5}>{t("profile.reservation_price")}</Grid>
        <Grid md={1}>{t("profile.balance")}</Grid>
        <Grid md={1}>{t("profile.totalBalance")}</Grid>
      </Grid>
      {WalletList?.length === 0 && (
        <Grid container display="block" textAlign="center">
          {t("offer.thereAreNoRecords")}
        </Grid>
      )}

      <Grid container className="table-items-container">
        {WalletList?.map((wlt, index) => {
          return (
            <Grid
              container
              className="wallet-item mb-20px"
              key={index}
              width="100%"
            >
              <Grid md={0.5}>
                {wlt?.id}
              </Grid>
              <Grid md={3} className="wallet-item-name">
                {wlt?.extra_data} #{wlt?.reservation_number}
              </Grid>
              <Grid md={2}>{wlt?.branch?.name}</Grid>
              <Grid md={1.5}>{moment(wlt?.created_at).format("DD/MM/YYYY")}</Grid>
              <Grid md={1.5}>{wlt?.commission_percentage || 0} %</Grid>
              <Grid md={1.5}>{wlt?.reservation_price?.toFixed(2) || 0}</Grid>

              <Grid md={1}>{`${
                wlt?.transaction_type === "subtract" ? "-" : "+"
              } ${wlt?.amount?.toFixed(2)} ${wlt?.currency}`}</Grid>
              <Grid md={1}>{wlt?.total_amount?.toFixed(2)}</Grid>
            </Grid>
          );
        })}
      </Grid>

      {count > 10 && (
        <Grid container justifyContent="center" mb={7.5} mt={5}>
          <CustomPagination
            count={Math.ceil(count / 10)}
            size="large"
            shape="rounded"
            page={page}
            handleChange={handlePageChange}
            className="pagination"
          />
        </Grid>
      )}
    </Stack>
  );
};

export default withTranslation()(WalletListing);
