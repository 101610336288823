import React from "react";
import JopOfferForm from "../../../../Components/Organisms/JobOffers/JopOfferForm";

const AddJopOfferTemplate = ({
  branchesList,
  branchesCount,
  currencies,
  organizationsList,
  certificatesList,
  activitesList,
  languagesList,
  type,
  supportedLanguages,
  getCertificatesList,
  orgId,
  setOrgId,
  activityType,
  setActivityType,
  getLanguagesAPI,
  getBranches,
  userId,
  getOrganization,
  languagesCount,
  organizationsCount,
  certificatesCount,
  profileData
}) => {
  return (
    <JopOfferForm
      getOrganization={getOrganization}
      getCertificatesList={getCertificatesList}
      branchesList={branchesList}
      branchesCount={branchesCount}
      currencies={currencies}
      organizationsList={organizationsList}
      certificatesList={certificatesList}
      certificatesCount={certificatesCount}
      activitesList={activitesList}
      languagesList={languagesList}
      getLanguagesAPI={getLanguagesAPI}
      languagesCount={languagesCount}
      type={type}
      supportedLanguages={supportedLanguages}
      orgId={orgId}
      setOrgId={setOrgId}
      activityType={activityType}
      setActivityType={setActivityType}
      getBranches={getBranches}
      userId={userId}
      organizationsCount={organizationsCount}
      profileData={profileData}
    />
  );
};

export default AddJopOfferTemplate;
