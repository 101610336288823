import { Grid, Typography } from "@mui/material";
import BranchDetailsHeader from "../../Molecules/Branches/Details/BranchDetailsHeader.jsx";
import BranchDetailsPanel from "../../Molecules/Branches/Details/BranchDetailsPanel.jsx";

const BranchDetails = (props) => {
    const { branchDetails, branchesPrivilages } = props;

    return (
        <Grid container className="branch-details-container">
            <BranchDetailsHeader
                branchDetails={branchDetails}
                branchesPrivilages={branchesPrivilages}
            />
            <BranchDetailsPanel
                branchDetails={branchDetails}
            />
        </Grid>
    )
}

export default BranchDetails;