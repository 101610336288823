import { Grid, Typography, Divider } from "@mui/material";
import TabPanel from "../../Atoms/Tabs/TabPanel.js";
import ActivityForm from "./ActivityForm.jsx";

const AddActivityPanel = (props) => {
  const {
    options,
    ActivityValue,
    uploadActivityImages,
    supportedLanguages,
    handleFormSubmit,
    branchesList,
    currencies,
    organizationsList,
    setOrganizationsList,
    getCertificates,
    certificatesList,
    setCertificatesList,
    languagesList,
    priceIncludesList,
    activityImages,
    removeActivityImage,
    getListOfPrices,
    createNewActivity,
    type,
    activityDetails,
    getOrganization,
    organizationsCount,
    getLanguagesAPI,
    getBranches,
    languagesCount,
    branchesCount,
    certificatesCount,
    profileData
  } = props;
  const checkType = (index) => {
    if (index == 0) return "dive_trip";
    else if (index == 1) return "dive_course";
    else if (index == 2) return "live_aboard";
  };
  return (
    <Grid container>
      {options.map((option, index) => {
        return (
          <Grid container key={index}>
            <TabPanel value={ActivityValue} index={index} className="tab-panel">
              <ActivityForm
                uploadActivityImages={uploadActivityImages}
                type={checkType(index)}
                supportedLanguages={supportedLanguages}
                handleFormSubmit={handleFormSubmit}                
                branchesList={branchesList}
                currencies={currencies}
                branchesCount={branchesCount}
                certificatesCount={certificatesCount}
                organizationsList={organizationsList}
                setOrganizationsList={setOrganizationsList}
                getOrganization={getOrganization}
                organizationsCount={organizationsCount}
                getBranches={getBranches}
                certificatesList={certificatesList}
                setCertificatesList={setCertificatesList}
                languagesList={languagesList}
                getLanguagesAPI={getLanguagesAPI}
                languagesCount={languagesCount}
                priceIncludesList={priceIncludesList}
                activityImages={activityImages}
                removeActivityImage={removeActivityImage}
                getListOfPrices={getListOfPrices}
                createNewActivity={createNewActivity}
                formType={type}
                activityDetails={activityDetails}
                getCertificates={getCertificates}
                profileData={profileData}
              />
            </TabPanel>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default AddActivityPanel;
