import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput.jsx";
import { withTranslation } from "react-i18next";
import ReactPhoneInput2 from "../../../Atoms/Input/PhoneInput/ReactPhoneInput2.jsx";
import "./Information.css";
import PasswordCriteria from "../../../Atoms/SharedComponents/PasswordCriteria.jsx";
import SearchableSelect from "../../../Atoms/Select/SearchableSelect.jsx";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const AddForm = ({
  t,
  formik,
  getBranches,
  branchesList,
  branchesCount,
  profileData,
  formType,
  salesmanData,
}) => {
  const isAssignedToOneBranch = profileData?.branch?.name;
  useEffect(() => {
    if (isAssignedToOneBranch && formType === "add") {
      formik.setValues({
        ...formik.values,
        branch_id: profileData?.branch?.id,
        branch_name: profileData?.branch?.name,
      });
    }
  }, [isAssignedToOneBranch, formType, profileData]);
  const [search, setSearch] = useState("");

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const customFilterCountries = (search) => {
    return PhoneInput?.countries?.filter((country) =>
      country.name.toLowerCase().startsWith(search.toLowerCase())
    );
  };
  return (
    <>
      {
        <Grid container className="activity-form">
          <Grid container>
            <Typography
              component="h1"
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                borderBottom: "2px solid var(--primary-color)",
              }}
              className="mb-20px"
            >
              {t("salesmen.salesmanInformation")}
            </Typography>
          </Grid>

          <Grid container className="mb-10px" alignItems={"center"}>
            <Grid item sm={5.8}>
              <TextFieldInput
                name="name"
                id="fullName"
                placeholder={t("salesmen.fullName")}
                className="login-input"
                type="text"
                // value={formik.values.name}
                value={formik.values.name ? formik.values.name : ""}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item sm={0.4}></Grid>
            <Grid sm={5.8}>
              <SearchableSelect
                name="branch_name"
                options={branchesList}
                getList={getBranches}
                handelMenuOpen={getBranches}
                count={branchesCount}
                type="branch"
                // defaultValues={activityDetails?.branch}
                label={t("branch.branchName")}
                disabled={isAssignedToOneBranch}
                className="searchable-select searchable-select-branches"
                value={{
                  value:
                    isAssignedToOneBranch && formType == "add"
                      ? profileData?.branch?.id
                      : formik?.values?.branch_id,
                  label:
                    isAssignedToOneBranch && formType == "add"
                      ? profileData?.branch?.name
                      : formik?.values?.branch_name,
                }}
                error={
                  formik.touched.branch_name &&
                  Boolean(formik.errors.branch_name)
                }
                helperText={
                  formik.touched.branch_name && formik.errors.branch_name
                }
                onChange={(val) => {
                  formik.setValues({
                    ...formik?.values,
                    branch_id: val?.value,
                    branch_name: val?.label,
                  });
                }}
                onClear={() => {
                  formik.setValues({
                    ...formik?.values,
                    branch_id: "",
                    branch_name: "",
                  });
                }}
              />
            </Grid>
          </Grid>

          <Grid container className="mb-10px" alignItems="baseline">
            <Grid container item sm={5.8} alignItems="center">
              <TextFieldInput
                name="email"
                id="email"
                placeholder={`${t("salesmen.emailAddress")}`}
                className="login-input"
                type="email"
                // value={formik.values.email}
                value={formik.values.email ? formik.values.email : ""}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item sm={0.4}></Grid>
            <Grid container item sm={5.8} alignItems="center">
              {/* <ReactPhoneInput2
                formik={formik}
                id="phone"
                name="phone_number"
                customClass="normal-textField login-input salesman-phoneinput"
                customContainerClass="salesman-phoneinput-container"
                placeholder={`${t("users.phoneNumber")}`}
                specialLabel={`${t("users.phoneNumber")}`}
                phoneProperty="phone_number"
                codeProperty="country_code"
                disableDropdown={true}
                showBorder={true}
              /> */}
              <PhoneInput
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                  style: {
                    width: "100%",
                    fontSize: "14px",
                    height: "100% !important",
                  },
                }}
                value={
                  (formik.values.country_code || "") +
                  (formik.values.phone_number || "")
                }
                onChange={(value, data) => {
                  formik.setValues({
                    ...formik?.values,
                    phone_number: value.slice(data?.dialCode?.length),
                    country_code: `${data?.dialCode}`,
                  });
                }}
                enableSearch={true}
                containerStyle={{ height: "56px" }}
                searchStyle={{ width: "90%" }}
              />
            </Grid>
          </Grid>

          <Grid container item md={12} mb={"4px"}>
            <Grid item sm={5.8}>
              <TextFieldInput
                name="password"
                id="password"
                placeholder={t("salesmen.password")}
                type="password"
                className="login-input"
                onChange={formik.handleChange}
                // value={formik.values.password}
                value={formik.values.password ? formik.values.password : ""}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
              <PasswordCriteria />
            </Grid>
            <Grid item sm={0.4}></Grid>
            <Grid item sm={5.8}>
              <TextFieldInput
                name="password_confirmation"
                id="password_confirmation"
                placeholder={t("salesmen.confirmPassword")}
                type="password"
                className="login-input"
                onChange={formik.handleChange}
                // value={formik.values.password_confirmation}
                value={
                  formik.values.password_confirmation
                    ? formik.values.password_confirmation
                    : ""
                }
                error={
                  formik.touched.password_confirmation &&
                  Boolean(formik.errors.password_confirmation)
                }
                helperText={
                  formik.touched.password_confirmation &&
                  formik.errors.password_confirmation
                }
              />
            </Grid>
          </Grid>
        </Grid>
      }
    </>
  );
};

export default withTranslation()(AddForm);
