import {
  Container,
  FormControl,
  MenuItem,
  Select,
  ListSubheader,
  TextField,
  InputAdornment,
} from "@mui/material";
import React, { useState, useMemo } from "react";
import { getCountryCallingCode } from "react-phone-number-input";
import { getCountries } from "react-phone-number-input";
import TextFieldInput from "../TextField/TextFieldInput";
import "./PhoneInput.css";
import SearchIcon from "@mui/icons-material/Search";

function PhoneInput({
  setPhone,
  clickEnter,
  placeholder,
  value,
  labels,
  onChange,
  id,
  name,
  inputValue,
  error,
  helperText,
  className,
  selectId,
  codePlus,
  inputType,
  containerClassName,
}) {
  const containsText = (text, searchText) =>
    text?.toLowerCase()?.indexOf(searchText?.toLowerCase()) > -1;
  const allOptions = getCountries();
  const [searchText, setSearchText] = useState("");
  const displayedOptions = useMemo(
    () =>
      allOptions?.filter((option) => containsText(labels[option], searchText)),
    [searchText]
  );

  return (
    <Container
      style={{ display: "flex", alignItems: "baseline" }}
      fullWidth
      disableGutters
      className={`${containerClassName ? containerClassName : ""}`}
    >
      <FormControl
        className={
          localStorage.getItem("lang") == "ar"
            ? "code_selectAR"
            : "code_selectEN"
        }
      >
        <Select
          className={`select phone-select ${
            value === "Code" ? "phone-select-placeholder" : ""
          }`}
          value={value}
          // value={`+${value}`}
          MenuProps={{ autoFocus: false }}
          labelId="search-select-label"
          id={selectId}
          onChange={(e) => onChange(e.target.value)}
          onClose={() => setSearchText("")}
          renderValue={() =>
            value === "Code" ? value : codePlus ? `+${value}` : value
          }
        >
          <ListSubheader>
            <TextField
              size="small"
              // Autofocus on textfield
              autoFocus
              placeholder="Type to search..."
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          {displayedOptions?.map((country) => (
            <MenuItem key={country} value={getCountryCallingCode(country)}>
              {labels[country]} +{getCountryCallingCode(country)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextFieldInput
        error={error}
        helperText={helperText}
        name={name}
        id={id}
        value={inputValue}
        fullWidth
        placeholder={placeholder}
        onChange={setPhone}
        className={"mb-20px " + className}
        type={inputType ? inputType : "text"}
        onKeyDown={(e) => {
          if (e.key === "Enter") clickEnter && clickEnter();
        }}
      />
    </Container>
  );
}

export default PhoneInput;
