import React from "react";
import { getActivitiesList } from "../../../../Contexts/APIs/Activities/Activities";
import { useState } from "react";
import InHouseActivitiesTemplate from "../../../../Templates/Main/Salesmen/InHouseActivities/InHouseActivities.template";
import Loader from "../../../../Components/Atoms/Loader/Loader";
import { useEffect } from "react";
import CancelModal from "../../../../Components/Molecules/General/Models/CancelModal";
import { withTranslation } from "react-i18next";
import { getInHouseActivitiesList } from "../../../../Contexts/APIs/Salsemen/InHouseActivities";
import { editDiveCenterActive } from "../../../../Contexts/APIs/DiveCenter/DiveCenter";

const InHouseActivitiesPage = (props) => {
  const { t, profileData, inHouseActivitiesPrivilages } = props;
  const [inHouseActivitiesList, setInHouseActivitiesList] = useState([]);
  const [count, setCount] = useState();
  const [openLoader, setOpenLoader] = useState(true);
  const [filterObj, setFilterObj] = useState({
    page_size: 10,
    page_number: 1,
    branch_type: "",
    keyword: "",
    sort_by: "id",
    branch_id: profileData?.branch?.id
  });
  const [searchKeywords, setSearchKeywords] = useState("");
  const [cancelDur, setCancelDur] = useState(null);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const handleApplyCancel = async (e, val) => {
    const { res, err } = await editDiveCenterActive(profileData?.id, {
      user: { cancellation_duration: val },
    });
    if (res) {
      setOpen(false);
      setCancelDur(val);
    }
  };

  const updatePagination = (page) => {
    setFilterObj({ ...filterObj, page_number: page, keyword: searchKeywords });
  };

  const getKeywords = (keyword) => {
    setSearchKeywords(keyword);
    setFilterObj({ ...filterObj, keyword: keyword });
  };

  const InHouseActivitiesList = async (filterObj) => {
    setOpenLoader(true);
    // replace api with new
    const { res, err } = await getInHouseActivitiesList(filterObj);

    if (res) {
      setInHouseActivitiesList(res?.data?.inhouse_activities);
      setCount(res?.extra?.total_count);
    }
    if (err) {
    }
    setOpenLoader(false);
  };

  const handelDeleteInHouseActivity = async (id) => {
    // setOpenLoader(true);
    // const { res, err } = await deleteActivity(id);
    // if (res) {
    //   InHouseActivitiesList(filterObj);
    // }
    // setOpenLoader(false);
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      InHouseActivitiesList(filterObj);
    }, 500);
    return () => clearTimeout(timeId);
  }, [filterObj]);

  useEffect(() => {
    setTimeout(() => {
      setCancelDur(profileData?.cancellation_duration);      
    }, 1000);
  }, [profileData]);
  return (
    <>
      <Loader open={openLoader} />
      <InHouseActivitiesTemplate
        inHouseActivitiesList={inHouseActivitiesList}
        count={count}
        getInHouseActivities={InHouseActivitiesList}
        setInHouseActivitiesList={setInHouseActivitiesList}
        inHouseActivitiesPrivilages={inHouseActivitiesPrivilages}
        setOpenLoader={setOpenLoader}
        searchKeywords={searchKeywords}
        setSearchKeywords={setSearchKeywords}
        page={page}
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        setPage={setPage}
        updatePagination={updatePagination}
        getKeywords={getKeywords}
        handelDeleteInHouseActivity={handelDeleteInHouseActivity}
        setOpen={setOpen}
        cancelDur={cancelDur}
        profileData={profileData}
      />
      <CancelModal
        open={open}
        setOpen={setOpen}
        defaultValue={cancelDur}
        handleRequest={handleApplyCancel}
        text={[
          `${cancelDur ? t("offer.edit") : t("users.add")} ${t(
            "salesmen.cancelationDuration"
          )}`,
          t("salesmen.cancelationDuration"),
          t("activity.hour"),
        ]}
        buttons={[t("offer.apply"), t("offer.cancel")]}
      />
    </>
  );
};

export default withTranslation()(InHouseActivitiesPage);
