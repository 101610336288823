import WalletListing from "../../../Components/Organisms/Wallet/WalletListing";

const WalletTemplate = (props) => {
    const { WalletData, WalletList, count, page, handlePageChange , filterObj, setFilterObj,profileData } = props;

    return (
        <WalletListing
            WalletData={WalletData}
            WalletList={WalletList}
            count={count}
            page={page}
            handlePageChange={handlePageChange}
            filterObj={filterObj}
            setFilterObj={setFilterObj}
            profileData={profileData}
        />
    )
}

export default WalletTemplate