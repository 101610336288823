import moment from "moment";
import { useEffect, useRef } from "react";

var En = false;
function getEnglishLanguage() {
  if (localStorage.getItem("lang") === "en") {
    En = true;
  }
}
getEnglishLanguage();

export function makeFilterString(filter_obj) {
  var filterString = "?";
  Object.keys(filter_obj)?.map(function (key) {
    if (filter_obj[key]) {
      filterString += key + "=" + filter_obj[key] + "&";
    }
  });
  if (filterString.substr(filterString.length - 1) === "&") {
    filterString = filterString.slice(0, -1);
  }
  return filterString;
}

export const DaysList = [
  {
    name: "Saturday",
    id: "saturday",
  },
  {
    name: "Sunday",
    id: "sunday",
  },
  {
    name: "Monday",
    id: "monday",
  },
  {
    name: "Tuesday",
    id: "tuesday",
  },
  {
    name: "Wednesday",
    id: "wednesday",
  },
  {
    name: "Thursday",
    id: "thursday",
  },
  {
    name: "Friday",
    id: "friday",
  },
];

export const ActivityTypes = [
  {
    name: "Dive Trip",
    id: "dive_trip",
  },
  {
    name: "Dive Course",
    id: "dive_course",
  },
  {
    name: "Live Aboard",
    id: "live_aboard",
  },
];

export const GroupsStatus = [
  {
    name: "Completed",
    id: "completed",
  },
  {
    name: "Started",
    id: "started",
  },
  {
    name: "Upcoming",
    id: "upcoming",
  },
];

export function getObjectWithSupportedLanguages(
  obj,
  supportedLang,
  objOfKeys,
  key
) {
  for (let i = 0; i < supportedLang.length; i++) {
    obj[key][supportedLang[i].id] = objOfKeys[i];
  }
  return obj;
}

export const findInObject = (object, attr) => {
  const res = Object.keys(object)
    .filter((key) => key.includes(attr))
    .reduce((obj, key) => {
      return Object.assign(obj, {
        item: key.split("_").join(" "),
        value: object[key],
      });
    }, {});
  return res;
};

export const updateLocalizationsValues = (event, i, initArray) => {
  let tempArray = [...initArray];
  let tempIndex = tempArray[i];
  tempIndex = event.target.value;
  const newList = initArray.map((obj, index) => {
    if (index === i) {
      return tempIndex;
    }
    return obj;
  });
  return newList;
};

export const removeObjectWithId = (arr, id, key) => {
  const objWithIdIndex = arr.findIndex((obj) => obj[key] === id);
  arr.splice(objWithIdIndex, 1);
  return arr;
};

export const mergeDateTime = (date = new Date(), time) => {
  const parsedTime = moment(time, ["h:mm A"]);
  const merged = moment(date).set({
    hours: parsedTime.get("hours"),
    minutes: parsedTime.get("minutes"),
  });

  return merged.toDate();
};

export function useOnMountUnsafe(effect, list) {
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      !list && effect({}, list);
    }
  }, []);
}


export const removeDuplicateObjects = (array, identifier) => {
  const uniqueObjects = {};
  array.forEach((obj) => {
    uniqueObjects[obj[identifier]] = obj;
  });

  return Object.values(uniqueObjects);
};