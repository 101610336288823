import {
  Grid,
  Typography,
  Divider,
  Menu,
  MenuItem,
  Button,
  Stack,
} from "@mui/material";
import NormalTabs from "../../Atoms/Tabs/NormalTabs.js";
import TextFieldInput from "../../Atoms/Input/TextField/TextFieldInput.jsx";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SelectBox from "../../Atoms/Select/Select.jsx";
import { getDiveExpertList } from "../../../Contexts/APIs/RequestedActivities/Activities.js";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import BranchesFilter from "../../Organisms/Statistics/BranchesFilter.jsx";
const RequestedActivityTabs = (props) => {
  const {
    t,
    options,
    handleActivityValue,
    ActivityValue,
    getKeywords,
    getBranchType,
    activitiesPrivilages,
    setActivityValue,
    setFilterObj,
    filterObj,
    profileData,
    getActivities
  } = props;
  const Navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [diveExpertList, setDiveExpertList] = useState([]);

  const [status, setStatus] = useState("pending,rejected");
  const [name, setName] = useState({
    label: "all",
    value: "all",
  });
  const [activityType, setActivityType] = useState(0);
  const open = Boolean(anchorEl);
  const getDiveExpert = async () => {
    const { res, err } = await getDiveExpertList({ page_size: 100 });
    if (res) {
      setDiveExpertList(res?.data?.dive_experts);
    }
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    getDiveExpert();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handelReset = () => {
    setName({
      label: "all",
      value: "all",
    });
    setActivityType("0");
    setStatus("pending,rejected");
    setActivityValue(0);
    getBranchType(0);
    handleClose();
    setFilterObj((s) => {
      return {
        ...s,
        status: "rejected,pending",
        page_number: 1,
        sort_by: "id",
      };
    });
  };
  const handelApply = () => {
    setActivityValue(activityType);
    getBranchType(activityType);
    setFilterObj((s) => {
      return {
        ...s,
        dive_expert_id: name?.value == "all" ? null : name?.value,
        status: status,
      };
    });
  };
  const ActivitiesTypes = [
    {
      id: 0,
      name: t("activity.all"),
    },
    {
      id: 1,
      name: t("activity.diveTrip"),
    },
    {
      id: 2,
      name: t("activity.courses"),
    },
    {
      id: 3,
      name: t("activity.liveaboard"),
    },
  ];
  const diveExpertListOptions = diveExpertList?.map((data) => {
    return {
      name: `${data?.firstname} ${data?.lastname}`,
      id: data?.id,
    };
  });
  return (
    <Stack flexDirection="row" gap={3} mt={'20px'}>
      <Grid item flexDirection="row">
        <Typography
          component="h1"
          style={{ fontWeight: "bold", fontSize: "30px" }}
        >
          {t("diveExpertActivity.requestedActivites")}
        </Typography>
      </Grid>

      <Grid item>
        <NormalTabs
          TabsOptions={options}
          value={ActivityValue}
          change={handleActivityValue}
          ContainerStyle="switch-container"
          TabStyle="switch-btn mb-20px"
          onclick={(value) => {
            getBranchType && getBranchType(value);
          }}
        />
      </Grid>
      <Grid sx={{ mb: "20px" }} className="border-r-10px">
        <BranchesFilter
          setFilterObj={setFilterObj}
          filterObj={filterObj}
          profileData={profileData}
        />
      </Grid>
      <Grid item>
        <TextFieldInput
          onChange={(e) => getKeywords(e?.target?.value)}
          type="search"
          placeholder={t("joboffer.searchActivities")}
          StartAdornment={<SearchIcon />}
          className="activity-search"
          sx={{ padding: "0px!important" }}
        />
      </Grid>
      <Grid item>
        <Button
          id="basic-button"
          variant="outlined"
          sx={{ color: "black", borderColor: "#BEBEBE", height: "95%" }}
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          startIcon={<FilterAltIcon color="#BEBEBE" />}
        >
          {t("diveExpertActivity.filter")}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            "& .MuiList-root": {
              width: "270px",
            },
          }}
        >
          <Typography color="#333132" fontSize="16px" fontWeight="500" p={1}>
            {t("diveExpertActivity.filterBy")}
          </Typography>
          <Divider w="100%" />
          <MenuItem
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              width: "100%",
            }}
          >
            {" "}
            <Typography color="#333132" fontSize="16px">
              {t("diveExpertActivity.diveExpertName")}
            </Typography>
            <SelectBox
              style={{ width: "100%" }}
              id="diveExpert"
              name="diveExpert"
              items={[{ id: "all", name: "All" }, ...diveExpertListOptions]}
              className="select"
              value={name?.label}
              onchange={(e) =>
                setName({
                  label: e.target.value,
                  value: e.target.value == "all" ? 0 : e.target.value,
                })
              }
            />
          </MenuItem>
          <MenuItem
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            {" "}
            <Typography color="#333132" fontSize="16px">
              {t("diveExpertActivity.ActivityType")}
            </Typography>
            <SelectBox
              style={{ width: "100%" }}
              id="Status"
              name="Status"
              items={ActivitiesTypes}
              value={activityType}
              className="select"
              onchange={(e) => {
                setActivityType(e.target.value);
              }}
            />
          </MenuItem>
          <MenuItem
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            {" "}
            <Typography color="#333132" fontSize="16px">
              {t("diveExpertActivity.status")}
            </Typography>
            <SelectBox
              style={{ width: "100%" }}
              id="Status"
              name="Status"
              value={status}
              items={[
                { name: "All", id: "pending,rejected" },
                { name: "Pending", id: "pending" },
                { name: "Rejected", id: "rejected" },
              ]}
              className="select"
              onchange={(e) => setStatus(e.target.value)}
            />
          </MenuItem>
          <MenuItem
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "start",
              gap: 2,
              width: "100%",
            }}
          >
            <Button
              onClick={handelReset}
              style={{
                borderRadius: "26px",
                color: "black",
                border: "1px solid #bebebe",
                width: "50%",
                padding: "10px",
              }}
            >
              {t("general.reset")}
            </Button>
            <PrimaryButton
              classBtn="primary r-25px"
              text={t("general.apply")}
              click={handelApply}
              style={{
                width: "50%",
                padding: "10px",
              }}
            />
          </MenuItem>
        </Menu>
      </Grid>
    </Stack>
  );
};

export default withTranslation()(RequestedActivityTabs);
