import { Grid, Avatar } from "@mui/material";
import { useState } from "react";
import CustomPagination from "../../../Atoms/Pagination/Pagination";
import { withTranslation } from "react-i18next";


const ListHistory = ({ count, offers, handleChange, t }) => {
    const [page, setPage] = useState(1);
    const lang = localStorage.getItem('lang')
    const handlePageChange = (event, value) => {
        setPage(value);
        handleChange('page_number', value);
    };


    return (
        <Grid container>
            <Grid container className="table-header mb-20px">
                <Grid item md={1} textAlign="center">{t("joboffer.id")}</Grid>
                <Grid item md={2}>{t("joboffer.jobTitle")}</Grid>
                <Grid item md={2}>{t("joboffer.freelancerTitle")}</Grid>
                <Grid item sm={2}>{t("joboffer.activityType")}</Grid>
                <Grid item sm={2}>{t("joboffer.fare")}</Grid>

            </Grid>
            {offers?.length === 0 && (
                <Grid container display="block" textAlign="center">
                    {t("offer.thereAreNoRecords")}
                </Grid>
            )}
            {offers?.map((offer) => (
                <Grid
                    key={offer?.id}
                    container
                    item
                    lg={12}
                    className="activity-item mb-20px"
                >
                    <Grid item md={1} textAlign="center" >{offer?.id}</Grid>
                    <Grid item md={2}>{offer?.localized_data[lang]?.title}</Grid>


                    <Grid sm={2} textTransform='capitalize'>{offer?.dive_expert_professional_level?.replace("_", " ")}</Grid>
                    <Grid item md={2} textTransform='capitalize'>{offer?.activity_type?.replace("_", " ")}</Grid>
                    <Grid item sm={2}>{offer?.rate}</Grid>

                </Grid>
            ))}
            {count > 10 &&
                <Grid container justifyContent='center' mb={7.5} mt={5}>
                    <CustomPagination
                        count={Math.ceil(count / 10)}
                        size='large'
                        shape="rounded"
                        page={page}
                        handleChange={handlePageChange}
                        className='pagination'
                    />
                </Grid>
            }
        </Grid>
    );
}

export default withTranslation()(ListHistory);