import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";
import Autocomplete from "react-google-autocomplete";
import { Box, Button } from "@mui/material";
import { toast } from "react-toastify";
import { compose, withProps, withStateHandlers } from "recompose";

const mapKey = process.env.REACT_APP_GOOGLE_API_MAP_KEY;
function Map(props) {
  const { type, getCurrentLocation, lat, lng } = props;
  const [open, setOpen] = React.useState(true);

  const [userLat, setUserLat] = useState(48.89880531796407);
  const [userLng, setUserLng] = useState(2.3507744060959697);
  const [center, setCenter] = useState({
    lat: "",
    lng: "",
  });
  const onDragEnd = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setUserLat(lat);
    setUserLng(lng);
    setCenter({ lat, lng });
  };

  const defaultProps = {
    center: {
      lat: 2.3507744060959697,
      lng: 2.3507744060959697,
    },
    greatPlaceCoords: { lat: 59.724465, lng: 30.080121 },
    zoom: 10,
  };
  function getLocationhi() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPositionby);
    } else {
    }
  }

  function showPositionby(position) {
    getCurrentLocation(position.coords.latitude, position.coords.longitude);
  }
  useEffect(() => {
    if (!lat && !lng) {
      getLocationhi();
    }
  }, [lat, lng]);

  const getSearchLocation = (place) => {
    setUserLat(place.geometry.location.lat());
    setUserLng(place.geometry.location.lng());
  };

  useEffect(() => {
    setCenter({
      lat: userLat,
      lng: userLng,
    });
    if (type === "add") getCurrentLocation(userLat, userLng);
  }, [userLat, userLng]);

  useEffect(() => {
    if (lat !== null) {
      setUserLat(lat);
      setUserLng(lng);
      getCurrentLocation(lat, lng);
    }
  }, [lat, lng]);

  return (
    <div
      style={{
        height: "300px",
        width: "100%",
        borderRadius: "10px",
        position: "relative",
      }}
    >
      <Box sx={{ position: "absolute", top: "-255px", width: "100%" }}>
        {type === "add" && (
          <Autocomplete
            className="places-search"
            apiKey={mapKey}
            onPlaceSelected={(place) => {
              getSearchLocation(place);
            }}
            placeholder="Search Location"
          />
        )}
      </Box>

      <Box
        sx={{
          position: "absolute",
          top: " -10px",
          right: "35px",
          width: "50%",
        }}
      >
        {type == "add" && (
          <>
            <Button
              onClick={() => {
                if (type == "add") {
                  setOpen(false);
                  toast.info("Location saved successfully", {
                    position: "bottom-center",
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                  });
                }
              }}
              sx={{
                "&:hover": { background: "var(--primary-color)" },
                visibility: open ? "visible" : "hidden",
                background: "var(--primary-color)",
                color: "white",
                position: "absolute",
                bottom: "20px",
                right: "20px",
                zIndex: 10,
                borderRadius: "24px",
              }}
            >
              Add location
            </Button>
          </>
        )}
      </Box>
      <GoogleMap
        bootstrapURLKeys={{ key: mapKey, libraries: ["places"] }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        center={center}
        defaultClickableIcons={true}
        defaultDraggable={type === "add" && true}
        onClick={type === "add" ? onDragEnd : null}
      >
        <Marker
          lat={userLat}
          lng={userLng}
          position={center}
          defaultDraggable={type === "add" && true}
          onDragEnd={onDragEnd}
        />
      </GoogleMap>
    </div>
  );
}

export default compose(
  withProps({
    googleMapURL:
      `https://maps.googleapis.com/maps/api/js?key=${mapKey}&callback=initMap&libraries=places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: (
      <div style={{ height: `300px`, width: "100%", marginBottom: "20px" }} />
    ),
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(Map);
