import { Grid, Stack, Typography } from "@mui/material";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput.jsx";
import "./Form.css";

import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { icons } from "../../../../Assets/AssetHelper.js";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import FileInput from "../../../Atoms/Input/FileInput/FileInput.jsx";
import DisplayLicense from "../DisplayLicense.jsx";
import MultiOrganizationsSelect from "./MultiOrganizationsSelect.jsx";
import { toast } from "react-toastify";
import { EditBranch } from "../../../../Contexts/APIs/Branches/Branches.js";

const MoreInfo = (props) => {
  const {
    t,
    licenseErr,
    formik,
    getLicense,
    licenseName,
    setLicenseName,
    organizationsList,
    addBtnTitle,
    getOrganization,
    intialActiveList,
    getActiveList,
    organizationsCount,
    setSelectedList,
    selectedList,
    licenseImg,
    licensesData = [],
    type,
    getbranchDetails,
    id,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState({}); // searchableselect displayed value
  const [allLicensesData, setAllLicensesData] = useState([
    ...selectedList,
    ...licensesData,
  ]);
  const [licenseNumber, setLicenseNumber] = useState(""); // state for license number
  const [licenseImage, setLicenseImage] = useState(null); // state for license image

  const addNewLicense = () => {
      // const errors = [];
      // if (!value?.value) {
      //   errors.push(t("validation.organizationIsRequired"));
      // }
      // if (!licenseImage?.url) {
      //   errors.push(t("validation.licenseImageIsRequired"));
      // }
      // if (!licenseNumber) {
      //   errors.push(t("validation.licenseNumberIsRequired"));
      // }

      // // Check if the license id already exists
      // const licenseExists = allLicensesData?.some(
      //   (license) => license?.organization_id === value?.value
      // );
      // if (licenseExists) {
      //   errors.push(t("validation.thisOrganizationAlreadyExists"));
      // }

      // if (errors.length > 0) {
      //   toast.error(errors.join(", "));
      //   return;
      // }

    const newLicenseInfo = {
      organization_id: value?.value,
      license_number: licenseNumber,
      license_url: licenseImage?.url,
      file: licenseImage?.url,
      file_type: licenseImage?.file_type,
      organization: { name: value?.label, id: value?.value },
    };
    const updatedList = [...selectedList, newLicenseInfo];
    setSelectedList(updatedList);
    setAllLicensesData([...updatedList, ...licensesData]);

    // Reset the fields
    setValue({});
    setLicenseNumber("");
    setLicenseImage(null);
    setLicenseName("");
    formik.setFieldValue("license_number", ""); // reset Formik license number field
  };
  useEffect(() => {
    if (type == "edit") {
      setAllLicensesData([...selectedList, ...licensesData]);
    } else {
      setAllLicensesData(selectedList);
    }
  }, [selectedList, licensesData, type]);
  const handleDelete = async (index) => {
    const action = () => {
      getbranchDetails();
      setIsLoading(false);
    };
    if (allLicensesData?.[index]?.id) {
      setIsLoading(true);
      const { res, err } = await EditBranch(
        id,
        {
          branch: formik?.values,
          deleted_branch_organizations_ids: [allLicensesData?.[index]?.id],
        },
        action
      );
      setIsLoading(false);
    } else {
      const licenseToDelete = allLicensesData[index];
      // Check if the license exists in selectedList
      const updatedSelectedList = selectedList?.filter(
        (license) =>
          license?.organization_id !== licenseToDelete?.organization_id
      );
      // Update the selectedList
      setSelectedList(updatedSelectedList);
      // Update allLicensesData
      setAllLicensesData([...updatedSelectedList, ...licensesData]);
    }
  };

  return (
    <Grid container lg={12} className="activity-form">
      <Grid container md={12}>
        <Typography
          component="h1"
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            borderBottom: "2px solid var(--primary-color)",
          }}
          className="mb-20px"
        >
          {t("branch.licenses")} ({t("activity.optional")})
        </Typography>
      </Grid>
      <Grid container md={5.8} className="mb-20px">
        <Grid sm={12}>
          <MultiOrganizationsSelect
            formTitle={t("branch.organization")}
            addBtnTitle={t("branch.addOrganization")}
            list={organizationsList}
            organizationsCount={organizationsCount}
            getOrganization={getOrganization}
            intialActiveList={intialActiveList}
            getActiveList={getActiveList}
            value={value}
            setValue={setValue}
          />
        </Grid>
      </Grid>
      <Grid container md={0.4} className="mb-20px"></Grid>
      <Grid container md={5.8} className="mb-20px">
        <Stack width={"100%"}>
          <Grid sm={12}>
            <FileInput
              icon={icons.UploadIcon}
              uploadText={t("branch.license")}
              btnStyle="upload-input"
              errorTxt={licenseErr}
              fileName={licenseName}
              change={(event) => {
                getLicense(event);
                setLicenseImage({
                  url: event.target.files[0],
                  file_type: event.target.files[0].type,
                }); // update license image state
              }}
              accept="image/*,application/pdf"
            />
          </Grid>
        </Stack>
      </Grid>
      <Grid container md={5.8} className="mb-20px">
        <Grid sm={12}>
          <TextFieldInput
            name="license_number"
            id="license_number"
            placeholder={t("branch.licenseNumber")}
            className="login-input"
            type="text"
            value={licenseNumber}
            error={
              formik.touched.license_number &&
              Boolean(formik.errors.license_number)
            }
            helperText={
              formik.touched.license_number && formik.errors.license_number
            }
            onChange={(event) => {
              formik.handleChange(event);
              setLicenseNumber(event.target.value); // update license number state
            }}
          />
        </Grid>
        <Grid sm={0.4}></Grid>
      </Grid>
      <Grid sm={3}></Grid>
      <Grid
        alignItems="center"
        justifyContent="center"
        container
        md={3.2}
        className="mb-20px"
      >
        <Grid sm={12}>
          <PrimaryButton
            text={addBtnTitle}
            classBtn="primary addLicenseBtn"
            style={{ width: "100%" }}            
            fontSize={"14px"}
            click={addNewLicense}
          />
        </Grid>
      </Grid>
      {allLicensesData?.length > 0 && (
        <Grid
          container
          sm={12}
          direction={"row"}
          justifyContent={"space-between"}
          rowGap={"20px"}
          sx={{ borderTop: "1px solid #D9D9D9", pt: "20px" }}
        >
          {allLicensesData?.map((license, i) => (
            <DisplayLicense
              data={license}
              // image={
              //   license?.license_url?.includes("blob")
              //     ? URL?.createObjectURL(license?.license_url)
              //     : license?.license_url
              // }
              image={license?.license_url}
              handleRemove={(_) => handleDelete(i)}
              title={license?.organization?.name}
              licenseNumber={license?.license_number}
              isLoading={isLoading}
            />
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export default withTranslation()(MoreInfo);
