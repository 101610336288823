import { Grid } from "@mui/material";
import React from "react";
import SalesmenReservationsHeader from "../../../Molecules/Salesmen/SalesmenReservations/SalesmenReservationsHeader";
import SalesmenReservationList from "../../../Molecules/Salesmen/SalesmenReservations/SalesmenReservationList";
import CustomPagination from "../../../Atoms/Pagination/Pagination";

const SalesmenReservations = ({
  count,
  reservationsList,
  branchesList,
  salesmenList,
  statusList,
  setOpenLoader,
  page,
  handlePageChange,
  updateSalesmenReservationsList,
  getReservations,
  salesmenReservationsPrivilages,
  getBranches,
  getSalesmenListing,
  branchesCount,
  salesmenCount,
  filterObj,
  setFilterObj,
  profileData
}) => {
  return (
    <>
      <Grid
        container
        className="reservations-container"
        width="1800px"
        // sx={{ overflowX: "scroll" }}
        overflow={"hidden"}
      >
        <SalesmenReservationsHeader
          updateSalesmenReservationsList={updateSalesmenReservationsList}
          branchesList={branchesList}
          branchesCount={branchesCount}
          salesmenList={salesmenList}
          statusList={statusList}
          getBranches={getBranches}
          getSalesmenListing={getSalesmenListing}
          salesmenCount={salesmenCount}
          filterObj={filterObj}
          setFilterObj={setFilterObj}
          profileData={profileData}
        />
        <SalesmenReservationList
          reservationsList={reservationsList}
          setOpenLoader={setOpenLoader}
          count={count}
          page={page}
          handlePageChange={handlePageChange}
          getReservations={getReservations}
          salesmenReservationsPrivilages={salesmenReservationsPrivilages}
        />
      </Grid>
      {count > 10 && (
        <Grid container justifyContent="center" mb={7.5} mt={5}>
          <CustomPagination
            count={Math.ceil(count / 10)}
            size="large"
            shape="rounded"
            page={page}
            handleChange={handlePageChange}
            className="pagination"
          />
        </Grid>
      )}
    </>
  );
};

export default SalesmenReservations;
