import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { withTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import TextFieldInput from "../../Atoms/Input/TextField/TextFieldInput";
import UploadImagesForm from "./UploadImagesForm";
import en from "react-phone-number-input/locale/en.json";
import SelectBox from "../../Atoms/Select/Select";
import Loader from "../../Atoms/Loader/Loader";
import "./UserForm.css";
import "./Form.css";
import ReactPhoneInput2 from "../../Atoms/Input/PhoneInput/ReactPhoneInput2";
import SearchableSelect from "../../Atoms/Select/SearchableSelect";

const UserForm = (props) => {
  const {
    t,
    formik,
    openLoader,
    rolesData,
    handleFormSubmit,
    validationMethod,
    userImage,
    uploadUserImages,
    removeUserImage,
    formType,
    branchesList,
    getBranches,
    branchesCount,
    profileData,
  } = props;
  const isAssignedToOneBranch = profileData?.branch?.name;
  useEffect(() => {
    handleFormSubmit.current = validationMethod;
  }, [
    formik.values.name,
    formik.values.role_id,
    formik.values.email,
    formik.values.phone_number,
    formik.values.password,
    formik.values.password_confirmation,
    userImage,
  ]);

  return (
    <>
      <Loader open={openLoader} />

      {!openLoader && (
        <Grid container item lg={12} flexDirection="column">
          <Grid item md={7} className="user-form">
            <Grid container item md={12} pl={3}>
              <Typography
                component="h1"
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  borderBottom: "2px solid var(--primary-color)",
                }}
                className="mb-20px"
              >
                {t("users.userInfo")}
              </Typography>
            </Grid>
            <Grid
              container
              item
              md={12}
              className="mb-20px"
              alignItems="center"
            >
              <Grid item sm={5.8}>
                <TextFieldInput
                  name="name"
                  id="name"
                  placeholder={`${t("users.fullName")}`}
                  className="user-name"
                  type="text"
                  value={formik.values.name}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item sm={0.4}></Grid>
              <Grid item sm={5.8}>
                <SearchableSelect
                  name="branch_name"
                  options={branchesList}
                  getList={getBranches}
                  handelMenuOpen={getBranches}
                  count={branchesCount}
                  type="branch"
                  // defaultValues={activityDetails?.branch}
                  label={t("branch.branchName")}
                  className="searchable-select searchable-select-branches"
                  disabled={isAssignedToOneBranch}
                  value={{
                    value:
                      isAssignedToOneBranch && formType == "Add"
                        ? profileData?.branch?.id
                        : formik?.values?.branch_id,
                    label:
                      isAssignedToOneBranch && formType == "Add"
                        ? profileData?.branch?.name
                        : formik?.values?.branch_name,
                  }}
                  error={
                    formik.touched.branch_name &&
                    Boolean(formik.errors.branch_name)
                  }
                  helperText={
                    formik.touched.branch_name && formik.errors.branch_name
                  }
                  onChange={(val) => {
                    formik.setValues({
                      ...formik?.values,
                      branch_id: val?.label == "All" ? null : val?.value,
                      branch_name: val?.label,
                    });
                  }}
                  onClear={() => {
                    formik.setValues({
                      ...formik?.values,
                      branch_id: "",
                      branch_name: "",
                    });
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              md={12}
              className="mb-20px"
              alignItems="center"
            >
              <Grid item sm={5.8}>
                <TextFieldInput
                  name="email"
                  id="email"
                  placeholder={`${t("users.emailAddress")}`}
                  className="user-email"
                  type="email"
                  value={formik.values.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item sm={0.4}></Grid>
              <Grid item sm={5.8}>
                <SelectBox
                  id="role_id"
                  name="role_id"
                  items={rolesData}
                  title={`${t("users.userRole")}`}
                  className="select"
                  value={
                    formik.values.role_id ? `${formik.values.role_id}` : ""
                  }
                  error={
                    formik.touched.role_id && Boolean(formik.errors.role_id)
                  }
                  helperText={formik.touched.role_id && formik.errors.role_id}
                  onchange={formik.handleChange}
                  onclick={(val) =>
                    formik.setValues({ ...formik?.values, role_id: val })
                  }
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              md={12}
              className="mb-20px"
              alignItems="center"
            >
              <Grid item sm={5.8} className="password">
                <TextFieldInput
                  name="password"
                  id="password"
                  placeholder={`${t("users.password")}`}
                  type="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  clickEnter={formik.handleSubmit}
                />
              </Grid>
              <Grid item sm={0.4}></Grid>
              <Grid item sm={5.8}>
                {/* <ReactPhoneInput2
                  formik={formik}
                  id="phone_number"
                  name="phone_number"
                  customClass="normal-textField user-phone"
                  placeholder={`${t("users.phoneNumber")}`}
                  specialLabel={`${t("users.phoneNumber")}`}
                  phoneProperty="phone_number"
                  codeProperty="country_code"
                  disableDropdown={true}
                /> */}
                <PhoneInput
                  inputProps={{
                    name: "phone",
                    required: true,
                    autoFocus: true,
                    style: {
                      width: "100%",
                      fontSize: "14px",
                      height: "100% !important",
                    },
                  }}
                  value={
                    (formik.values.country_code || "") +
                    (formik.values.phone_number || "")
                  }
                  onChange={(value, data) => {
                    formik.setValues({
                      ...formik?.values,
                      phone_number: value.slice(data?.dialCode?.length),
                      country_code: `${data?.dialCode}`,
                    });
                  }}
                  enableSearch={true}
                  containerStyle={{ height: "56px" }}
                  searchStyle={{ width: "90%" }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              sm={12}
              className="mb-20px"
              alignItems="center"
            >
              <Grid item sm={5.8}>
                <TextFieldInput
                  name="password_confirmation"
                  id="password_confirmation"
                  placeholder={`${t("users.confirmPassword")}`}
                  type="password"
                  onChange={formik.handleChange}
                  value={formik.values.password_confirmation}
                  error={
                    formik.touched.password_confirmation &&
                    Boolean(formik.errors.password_confirmation)
                  }
                  helperText={
                    formik.touched.password_confirmation &&
                    formik.errors.password_confirmation
                  }
                  clickEnter={formik.handleSubmit}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={5} className="user-form-image">
            <UploadImagesForm
              formTitle={`${t("users.image")}`}
              uploadImages={uploadUserImages}
              Images={userImage}
              RemoveImage={removeUserImage}
              type="single"
              uploadText="Add image"
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default withTranslation()(UserForm);
