import { Grid, Typography, Stack } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import LanguagesForm from "../../Molecules/Forms/LanguagesForm.jsx";
import JopOfferInfo from "../../Molecules/JobOffers/Forms/JopOfferInfo.jsx";
import MoreInfo from "../../Molecules/JobOffers/Forms/MoreInfo.jsx";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { createJobOffer } from "../../../Contexts/APIs/Job-Offers/JobOffers.js";
import moment from "moment";
import CustomSeparator from "../../Atoms/BreadCrumb/index.jsx";
import { getObjectWithSupportedLanguages } from "../../../Contexts/APIs/Helper/Helper.js";
import { JopOfferValidationSchema } from "./validation.js";
import { withTranslation } from "react-i18next";

const JopOfferForm = (props) => {
  const {
    currencies,
    organizationsList,
    branchesList,
    certificatesList,
    supportedLanguages,
    languagesList,
    getCertificatesList,
    orgId,
    setOrgId,
    activityType,
    setActivityType,
    getLanguagesAPI,
    getBranches,
    userId,
    getOrganization,
    languagesCount,
    branchesCount,
    t,
    organizationsCount,
    certificatesCount,
    profileData
  } = props;

  let [searchParams, setSearchParams] = useSearchParams();
  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");

  const navigate = useNavigate();
  const handleFormSubmit = useRef(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [startBorder, setStartBorder] = useState("");
  const [concatTime, setConcatTime] = useState("");
  const [includesList, setIncludesList] = useState([]);
  const [notIncludesList, setNotIncludesList] = useState([]);
  const [activityLanguages, setActivityLanguages] = useState([]);
  const [branchName, setBranchName] = useState([""]);
  const [supportLang, setSupportLang] = useState("en");
  let requestStatus = "";
  const [requestCounter, setRequestCounter] = useState(0);

  useEffect(() => {
    handleFormSubmit.current = validationMethod;
  }, [includesList, notIncludesList, activityLanguages, startDate, endDate]);

  const DateValidation = () => {
    if (startDate == null) {
      toast.error(t("joboffer.pleaseEnterStartDate"));
    } else if (endDate == null) {
      toast.error(t("joboffer.pleaseEnterEndDate"));
    } else if (!moment(endDate).isSameOrAfter(startDate)) {
      toast.error(t("joboffer.pleaseEnterValidDate"));
    } else if (startTime == null) {
      toast.error(t("joboffer.pleaseEnterValidTime"));
    }
  };

  const validationMethod = () => {
    DateValidation(startDate, endDate);
    if (includesList.length == 0)
      toast.error(t("joboffer.pleaseAddPriceIncludes"));
    if (notIncludesList.length == 0)
      toast.error(t("joboffer.pleaseAddPriceExcludes"));
    if (activityLanguages.length == 0)
      toast.error(t("joboffer.pleaseAddOneMoreLang"));
    formik.handleSubmit();
  };

  const JopOffrIntialValues = {
    branch_id: "",
    branch_name: "",
    organization_id: "",
    organization_certificate_id: "",
    rate: "",
    currency_id: "",
    job_type: "",
    activity_type: "",
    dive_expert_professional_level: "",
    description: "",
    language_ids: [],
    start_date: "",
    end_date: "",
    start_time: "",
    reach_x_applicants: false,
    reach_favorite_first: false,
    transportation_included: false,
    meals_included: false,
  };

  

  const formik = useFormik({
    initialValues: JopOffrIntialValues,
    validationSchema: JopOfferValidationSchema(),
    onSubmit: async (values) => {
      const isValid =
        values?.dive_expert_professional_level == "dive_guide" &&
        values?.activity_type == "dive_course"
          ? false
          : true;

      if (
        values?.title_en &&
        startDate &&
        endDate &&
        startTime &&
        activityLanguages?.length > 0 &&
        isValid
      ) {
        const startTimeCon = moment(new Date(concatTime)).format(`HH:mm ZZ`);
        let job_offer = {
          ...values,
          start_date: `${moment(startDate).format(
            "DD-MM-YYYY"
          )} ${startTimeCon}`,
          start_time: startTimeCon,
          end_date: moment(endDate).format("DD-MM-YYYY"),
          language_ids: activityLanguages?.map((val) => val?.language_id),
          organization_id:
            values?.organization_id == "all" ? null : values?.organization_id,
          organization_certificate_id:
            values?.organization_certificate_id == "all"
              ? null
              : values?.organization_certificate_id,
        };
        const data = getObjectWithSupportedLanguages(
          {
            job_offer,
          },
          supportedLanguages,
          supportedLanguages.map((lang, index) => ({
            title: values[`title_${lang.id}`],
            description: values[`description_${lang.id}`],
          })),
          "job_offer"
        );

        requestStatus = "pending";
        setRequestCounter(prev => prev+1);
        
        if( requestStatus === "pending" && requestCounter === 0 ){
          const { res, err } = await createJobOffer(data);
            if (res) {
              requestStatus = "success";
              navigate(`/freelancers/job-offers?page=${pageNumber}`);
              requestStatus = "";
              setRequestCounter(null);
            }
            else{              
              if (err) {
                requestStatus = "fail";
                setRequestCounter(0);  
              }
            }
        }

      } else if (
        startDate == null ||
        endDate == null ||
        !moment(endDate).isSameOrAfter(startDate) ||
        startTime == null
      ) {
        DateValidation();
      } else if (!values?.title_en) {
        toast.error(t("joboffer.pleaseEnterJopTitle"));
      } else if (activityLanguages?.length == 0) {
        toast.error(t("joboffer.pleaseEnterLanguages"));
      } else if (!isValid) {
        toast.error(t("joboffer.pleaseEnterActivtyType"));
      }
    },
  });

  useEffect(() => {
    formik.setValues({
      ...formik?.values,
      organization_certificate_id: null,
      organization_id: null,
      organization_name: null,
      organization_certificate_name: null,
    });
  }, [
    formik?.values?.activity_type,
    formik?.values?.dive_expert_professional_level,
  ]);
  return (
    <Stack>
      <Grid sm={12} container>
        <CustomSeparator
          breadcrumbs={[
            <Link
              style={{ color: "#bebebe", textDecoration: "none" }}
              underline="hover"
              key="1"
              color="inherit"
              to={`/freelancers/list?page=${pageNumber}`}
            >
              {t("joboffer.freelancer")}
            </Link>,
            <Link
              style={{ color: "#bebebe", textDecoration: "none" }}
              underline="hover"
              key="2"
              color="inherit"
              to={`/freelancers/job-offers?page=${pageNumber}`}
            >
              {t("joboffer.jopOffers")}
            </Link>,
            <Typography
              key="3"
              style={{ color: "#bebebe", textDecoration: "none" }}
            >
              {t("joboffer.createNew")}
            </Typography>,
          ]}
        />
      </Grid>
      <Grid sm={12} container>
        <Grid sm={5} md={6}>
          <Typography
            component="h1"
            style={{
              fontSize: "30px",
              fontWeight: "bold",
            }}
            className="mb-20px"
          >
            {t("joboffer.createNewJobOffer")}
          </Typography>
        </Grid>
        <Grid sm={5} textAlign="end" md={6}>
          <PrimaryButton
            text={t("offer.cancel")}
            classBtn="secoundry r-25px"
            style={{ padding: "5px 20px", height: "48px", margin: "0 10px" }}
            click={() => navigate(`/freelancers/job-offers?page=${pageNumber}`)}
          />
          <PrimaryButton
            text={t("offer.createOffer")}
            classBtn="primary r-25px"
            style={{ padding: "5px 20px", height: "48px", margin: "0 10px" }}
            click={() => formik.handleSubmit()}
          />
        </Grid>
      </Grid>
      <Grid rowSpacing={1} columnSpacing={{ xs: 2 }} container lg={12}>
        <Grid item md={6}>
          <JopOfferInfo
            organizationsCount={organizationsCount}
            getOrganization={getOrganization}
            getCertificatesList={getCertificatesList}
            getBranches={getBranches}
            userId={userId}
            endDate={endDate}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            startDate={startDate}
            branchesList={branchesList}
            branchesCount={branchesCount}
            formik={formik}
            currencies={currencies}
            organizationsList={organizationsList}
            certificatesList={certificatesList}
            certificatesCount={certificatesCount}
            orgId={orgId}
            setOrgId={setOrgId}
            startTime={startTime}
            setStartTime={setStartTime}
            setConcatTime={setConcatTime}
            supportedLanguages={supportedLanguages}
            getBranchLocalized={(names) => setBranchName(names)}
            supportLang={supportLang}
            setBranchName={setBranchName}
            setSupportLang={setSupportLang}
            branchName={branchName}
            startBorder={startBorder}
            endBorder={startBorder}
            activityType={activityType}
            setActivityType={setActivityType}
            profileData={profileData}
          />
          <LanguagesForm
            formTitle={t("joboffer.courseLanguages")}
            languagesList={languagesList}
            getLanguagesAPI={getLanguagesAPI}
            languagesCount={languagesCount}
            getActivityLanguages={(languages) =>
              setActivityLanguages(languages)
            }
          />
        </Grid>
        <Grid item md={6}>
          <MoreInfo
            formik={formik}
            supportedLanguages={supportedLanguages}
            supportLang={supportLang}
            switchedOptions={[
              {
                title: t("joboffer.transportationIncluded"),
                value: false,
                name: "transportation_included",
              },
              {
                title: t("joboffer.mealsIncluded"),
                value: false,
                name: "meals_included",
              },
              {
                title: t("joboffer.reachXApplicants"),
                value: false,
                name: "reach_x_applicants",
              },
              {
                title: t("joboffer.reachFavoriteFirst"),
                value: false,
                name: "reach_favorite_first",
              },
            ]}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default withTranslation()(JopOfferForm);
