import React, { useEffect, useState } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import CustomSeparator from "../../../../Components/Atoms/BreadCrumb";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import BranchesFilter from "../../../../Components/Organisms/Statistics/BranchesFilter";
import DiveCenterStatisticsFilters from "../../../../Components/Organisms/Statistics/DiveCenterStatisticsFilters";
import Charts from "../../../../Components/Atoms/SharedComponents/Charts";
import { getSalesmanStatistics } from "../../../../Contexts/APIs/Statistics/statistics";
import dayjs from "dayjs";
import Salesmanfilter from "../../../../Components/Organisms/Statistics/Salesmanfilter";

const SalesmenDashboardPage = ({ t, profileData }) => {
  const [filterObj, setFilterObj] = useState({
    interval_type: "month",
    keyword: "",
  });

  const [bookingsData, setBookingsData] = useState([]);
  const [earningsData, setEarningsData] = useState([]);
  const [bookingsTicks, setBookingsTicks] = useState([]);
  const [earningsTicks, setEarningsTicks] = useState([]);

  const getStatistics = async (filterObj) => {
    const { res, err } = await getSalesmanStatistics({ ...filterObj });
    if (res) {
      const { x_axis, graph_points, earnings_y_axis, reservations_y_axis } =
        res?.data?.salesman_statistics;

      const formattedBookingsData = x_axis?.map((date, index) => ({
        name:
          filterObj?.interval_type == "month"
            ? dayjs(date).format("MMM")
            : dayjs(date).format("DD"),
        "No of booking": graph_points?.[date]?.reservations_count || 0,
        y_axis: reservations_y_axis?.[index] || 0,
      }));

      const formattedEarningsData = x_axis?.map((date, index) => ({
        name:
          filterObj?.interval_type == "month"
            ? dayjs(date).format("MMM")
            : dayjs(date).format("DD"),
        // name: date,
        "No of earnings": graph_points?.[date]?.earnings_amount || 0,
        y_axis: earnings_y_axis?.[index] || 0,
      }));

      setBookingsData(formattedBookingsData);
      setEarningsData(formattedEarningsData);
      setBookingsTicks(reservations_y_axis || []);
      setEarningsTicks(earnings_y_axis || []);
    }
  };

  useEffect(() => {
    getStatistics(filterObj);
  }, [filterObj]);

  const intervalTypeOptions = [
    {
      id: 0,
      label: t("statistics.monthly"),
    },
    {
      id: 1,
      label: t("statistics.daily"),
    },
  ];

  const getIntervalType = (value) => {
    if (value === 0) {
      setFilterObj({
        ...filterObj,
        interval_type: "month",
      });
    }
    if (value === 1) {
      setFilterObj({
        ...filterObj,
        interval_type: "day",
      });
    }
  };

  return (
    <>
      <Grid container mb={4}>
        <CustomSeparator
          breadcrumbs={[
            <Link
              style={{ color: "#bebebe", textDecoration: "none" }}
              underline="hover"
              key="1"
              color="inherit"
              to={`/salesmen`}
            >
              {t("salesmen.salesmen")}
            </Link>,
            <Typography
              key="2"
              style={{ color: "#bebebe", textDecoration: "none" }}
            >
              {t("salesmen.salesmen")} {t("profile.dashboard")}
            </Typography>,
          ]}
        />
        <Stack
          width={"100%"}
          direction={"row"}
          justifyContent="space-between"
          py={1}
        >
          <Typography
            component="h1"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              marginBottom: "10px",
            }}
          >
            {t("salesmen.salesmen")} {t("profile.dashboard")}
          </Typography>
          <Stack direction={"row"} gap={2}>
            <Grid sx={{ mb: "20px" }} className="border-r-10px">
              <BranchesFilter
                setFilterObj={setFilterObj}
                filterObj={filterObj}
                profileData={profileData}
              />
            </Grid>
            <Grid sx={{ mb: "20px" }} className="border-r-10px">
              <Salesmanfilter
                setFilterObj={setFilterObj}
                filterObj={filterObj}
              />
            </Grid>
          </Stack>
          <Grid sx={{ mb: "20px" }} className="border-r-10px">
            <DiveCenterStatisticsFilters
              getType={getIntervalType}
              options={intervalTypeOptions}
            />
          </Grid>
        </Stack>
        <Stack
          width={"100%"}
          justifyContent={"space-between"}
          direction={"row"}
          alignItems={"center"}
        >
          <Stack width="49%">
            <Charts
              data={bookingsData}
              ticks={bookingsTicks}
              title={t("statistics.bookings")}
              height={400}
              chartBarDataKey="No of booking"
            />
          </Stack>
          <Stack width="49%">
            <Charts
              data={earningsData}
              ticks={earningsTicks}
              title={t("statistics.earnings")}
              height={400}
              chartBarDataKey="No of earnings"
            />
          </Stack>
        </Stack>
      </Grid>
    </>
  );
};

export default withTranslation()(SalesmenDashboardPage);
