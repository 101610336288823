import { Button, Icon, Rating, Stack, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { acceptJopRequest } from "../../../Contexts/APIs/Job-Offers/JobOffers";
import { useNavigate, useSearchParams } from "react-router-dom";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { withTranslation } from "react-i18next";
import ReactPhoneInput2 from "../../Atoms/Input/PhoneInput/ReactPhoneInput2";
const FreelancerDetailsHeader = ({ data, jobOfferData, t }) => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
      color: "#3ed181",
    },
    "& .MuiRating-iconHover": {
      color: "#3ed181",
    },
  });

  const isActive = jobOfferData?.state != "pending" ? false : true;
  const handelAcceptRequest = async () => {
    const { res, error } = await acceptJopRequest(data?.id);
    if (res) {
      navigate(`/freelancers/job-offers?page=${pageNumber}`);
    }
  };

  return (
    <Stack
      alignItems="center"
      bgcolor="#333132"
      borderRadius="10px"
      padding={2}
      flexWrap="wrap"
      flexDirection="row"
      justifyContent="space-between"
    >
      <Stack flexDirection="row" gap={2}>
        <img
          src={data?.avatar}
          width="90px"
          height="90px"
          style={{ borderRadius: "10px" }}
        />
        <Stack>
          <Stack flexDirection="row" gap={2} alignItems="center">
            <Typography
              fontSize="30px"
              fontWeight="bold"
              color="white"
              textTransform="capitalize"
            >
              {data?.name}
            </Typography>
            <Stack
              flexDirection="row"
              gap={0.5}
              alignItems="center"
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate(
                  `/freelancer-reviews/${jobOfferData?.id}/${data?.id}?page=${pageNumber}`
                )
              }
            >
              <StyledRating
                name="customized-color"
                value={data?.avg_rate}
                readOnly={true}
                icon={
                  <CircleIcon fontSize="inherit" style={{ fontSize: "12px" }} />
                }
                emptyIcon={
                  <CircleOutlinedIcon
                    fontSize="inherit"
                    style={{ fontSize: "12px", color: "#3ed181" }}
                  />
                }
              />
              <Typography color="#3ed181">
                ({data?.number_of_reviews} {t("joboffer.reviews")})
              </Typography>
            </Stack>
          </Stack>
          <Stack flexDirection="row" gap={2} alignItems="center">
            <Typography
              fontSize="20px"
              fontWeight="bold"
              color="white"
              textTransform="capitalize"
            >
              {t("joboffer.applicant")}
            </Typography>
            <Typography fontSize="20px" fontWeight="bold" color="#f6b119">
              {data?.rate} {data?.currency?.lookup_key}/day
            </Typography>
            {jobOfferData?.location?.area?.name != data?.area?.name && (
              <Stack
                flexDirection="row"
                gap={0.5}
                color="white"
                fontSize="16px"
                alignItems="center"
              >
                <InfoOutlinedIcon fontSize="16px" />
                <Typography>{t("joboffer.locationNotJob")}</Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
      {!isActive ? (
        jobOfferData?.accepted_job_request?.dive_expert?.id == data?.id ? (
          <Stack
            flexDirection="row"
            flexWrap="wrap"
            gap={2}
            alignItems="center"
          >
            <Stack>
              <Typography
                style={{ cursor: "pointer" }}
                alignSelf="flex-end"
                fontSize="18px"
                width="fit-content"
                color="white"
                onClick={() =>
                  navigate(
                    `/freelancer/jop-offers-history/${jobOfferData?.id}/${data?.id}?page=${pageNumber}`
                  )
                }
              >
                {t("joboffer.viewHistory")}
              </Typography>
              <Stack flexDirection="row" gap={2} alignItems="center">
                <Stack
                  borderRadius="10px"
                  color="white"
                  flexDirection="row"
                  gap={1}
                  alignItems="center"
                  border="solid 1px #d9d9d9"
                  p={1}
                >
                  <PhoneOutlinedIcon />
                  <Typography>
                    <ReactPhoneInput2
                      phoneValue={data.phone_number}
                      codeValue={data.country_code}
                      customClass="header-phone"
                      formatType={'read'}
                      disableDropdown={true}
                    />
                  </Typography>
                </Stack>
                <Stack
                  borderRadius="10px"
                  color="white"
                  flexDirection="row"
                  gap={1}
                  alignItems="center"
                  border="solid 1px #d9d9d9"
                  p={1}
                >
                  <EmailOutlinedIcon />
                  <Typography>{data?.email}</Typography>
                </Stack>
              </Stack>
            </Stack>
            <Button
              disabled
              className=" r-25px"
              sx={{
                width: "130px",
                height: "40px",
                bgcolor: "#d9d9d9",
                color: "white",
              }}
            >
              {t("joboffer.accept")}
            </Button>
          </Stack>
        ) : null
      ) : jobOfferData?.accepted_job_request?.dive_expert?.id == data?.id ? (
        <Stack flexDirection="row" flexWrap="wrap" gap={2} alignItems="center">
          <Typography
            onClick={() =>
              navigate(
                `/freelancer/jop-offers-history/${jobOfferData?.id}/${data?.id}?page=${pageNumber}`
              )
            }
            fontSize="18px"
            width="fit-content"
            color="white"
            style={{ cursor: "pointer" }}
          >
            {t("joboffer.viewHistory")}
          </Typography>
          <Button
            onClick={handelAcceptRequest}
            className="primary r-25px"
            sx={{ width: "130px", height: "40px" }}
          >
            {t("joboffer.accept")}
          </Button>
        </Stack>
      ) : null}
    </Stack>
  );
};

export default withTranslation()(FreelancerDetailsHeader);
