import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import CustomSeparator from "../../../../Components/Atoms/BreadCrumb";
import SelectBox from "../../../../Components/Atoms/Select/Select";
import JobOfferDetails from "../../../../Components/Organisms/JobOffers/JobOfferDetails";
import { getSupportedLanguages } from "../../../../Contexts/APIs/Lookups/SupportedLanguages";
import { withTranslation } from "react-i18next";

const JobOfferDetailsTemplate = ({ jobOfferData, t }) => {
  let [searchParams, setSearchParams] = useSearchParams();
  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");

  const lang = localStorage.getItem("lang");
  const [languagesList, setLanguagesList] = useState([]);
  const [language, setLanguageInTemp] = useState(lang);
  const [disableListeners, setDisableListeners] = useState([]);

  const getLanguagesAPI = async () => {
    const { res, err } = await getSupportedLanguages({
      page_size: 10000,
      page_number: 1,
    });
    if (res) setLanguagesList(res?.data?.supported_locales);
  };

  const getPropsNotHaveValueLength = (obj, counter) => {
    for(let prop in obj){
      if(obj[prop] == undefined || obj[prop] == null || obj[prop] == ""){ counter++; }
    }
    return counter
  }
  useEffect(() => {
    languagesList.length == 0 && getLanguagesAPI();
    setTimeout(() => {
      if(jobOfferData?.localized_data && languagesList.length >= 1){
        languagesList?.map( item => {
        let counterListener = 0;
        if(Object.keys(jobOfferData?.localized_data?.[item.id]).length === getPropsNotHaveValueLength(
          jobOfferData?.localized_data?.[item.id], counterListener
        )){ disableListeners.length == 0 && setDisableListeners(prevState => [...prevState, true]) }else{
          disableListeners.length == 0 && setDisableListeners(prevState => [...prevState, false])
        }
      })
      }       
    }, 1000);
  }, [jobOfferData, languagesList]);
  return (
    <Stack gap={4}>
      <CustomSeparator
        breadcrumbs={[
          <Link
            style={{ color: "#bebebe", textDecoration: "none" }}
            underline="hover"
            key="1"
            color="inherit"
            to={`/freelancers/listpage=${pageNumber}`}
          >
            {t("joboffer.freelancer")}
          </Link>,
          <Link
            style={{ color: "#bebebe", textDecoration: "none" }}
            underline="hover"
            key="2"
            color="inherit"
            to={`/freelancers/job-offers?page=${pageNumber}`}
          >
            {t("joboffer.jopOffers")}
          </Link>,

          <Typography
            key="3"
            style={{ color: "#bebebe", textDecoration: "none" }}
          >
            {jobOfferData?.localized_data &&
              jobOfferData?.localized_data[language]?.title}
          </Typography>,
        ]}
      />
      <Stack flexDirection="row" gap={1} width="30%" alignItems="center">
        <Typography width="200px" fontSize="16px" fontWeight="bold">
          {t("joboffer.dataLanguage")}
        </Typography>
        <SelectBox
          id="language"
          name="language"
          items={languagesList}
          disabledItemsState={disableListeners}
          title="Select"
          className="select"
          value={language}
          onclick={(id, name) => setLanguageInTemp(id)}
        />
      </Stack>

      <JobOfferDetails jobOfferData={jobOfferData} language={language} />
    </Stack>
  );
};

export default withTranslation()(JobOfferDetailsTemplate);
