import { Grid, Typography } from "@mui/material";
import ReservationsList from "../../../Components/Organisms/Reservations/ReservationsList.jsx";

const ReservationsTemplate = (props) => {
  const {
    getBranches,
    getReservationsActivities,
    count,
    reservationsActivities,
    branchesList,
    getGroupReservationsList,
    reservationsData,
    setReservationsData,
    reservationsDuration,
    setOpenLoader,
    updatePayment,
    ordersPrivilages,
    filterObj,
    setFilterObj,
    ReservationsPageListener,
    branchesCount,
    profileData
  } = props;

  return (
    <Grid container className="reservation_temp">
      <ReservationsList
        getBranches={getBranches}
        getReservationsActivities={getReservationsActivities}
        count={count}
        reservationsActivities={reservationsActivities}
        branchesList={branchesList}
        getGroupReservationsList={getGroupReservationsList}
        reservationsData={reservationsData}
        setReservationsData={setReservationsData}
        reservationsDuration={reservationsDuration}
        setOpenLoader={setOpenLoader}
        updatePayment={updatePayment}
        ordersPrivilages={ordersPrivilages}
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        ReservationsPageListener={ReservationsPageListener}
        branchesCount={branchesCount}
        profileData={profileData}
      />
    </Grid>
  );
};

export default ReservationsTemplate;
