import { Grid } from "@mui/material";
import React, { useRef } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import UserForm from "../../Molecules/Forms/UserForm";
import PagesHead from "../../Molecules/Users/PagesHead";

function UserFormWrap(props) {
  const {
    t,
    formik,
    openLoader,
    rolesData,
    validationMethod,
    userImage,
    uploadUserImages,
    removeUserImage,
    formType,
    branchesList,
    getBranches,
    branchesCount,
    profileData
  } = props;
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  const navigateToUser = () => {
    navigate("/users");
  };
  const handleFormSubmit = useRef(null);
  return (
    <Grid container className={formType === "Add" ? "add-user" : "edit-user"}>
      <PagesHead
        title={
          formType === "Add" ? t("users.addNewUser") : `${t("users.editUser")}`
        }
        breadCrumb={{
          link: `${t("users.users")}`,
          path: `/users?page=${pageNumber}`,
          text: `${
            formType === "Add" ? t("users.addNewUser") : t("users.editUser")
          }`,
        }}
        primaryButtons={[
          {
            fontSize: "14px",
            text: `${t("users.cancel")}`,
            classBtn: "primary r-25px primary-text",
            style: { padding: "5px 20px", height: "48px", marginRight: "1rem" },
            click: navigateToUser,
          },
          {
            fontSize: "14px",
            text: `${
              formType === "Add" ? t("users.addUser") : t("users.editUser")
            }`,
            classBtn: "primary r-25px",
            style: { padding: "5px 20px", height: "48px" },
            btnType: "submit",
          },
        ]}
        handelSubmit={() => handleFormSubmit.current()}
      />
      <UserForm
        handleFormSubmit={handleFormSubmit}
        formik={formik}
        openLoader={openLoader}
        rolesData={rolesData}
        validationMethod={validationMethod}
        userImage={userImage}
        uploadUserImages={uploadUserImages}
        removeUserImage={removeUserImage}
        formType={formType}
        branchesList={branchesList}
        getBranches={getBranches}
        branchesCount={branchesCount}
        profileData={profileData}
      />
    </Grid>
  );
}

export default withTranslation()(UserFormWrap);
