import { Grid } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomPagination from "../../../Atoms/Pagination/Pagination.js";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { useEffect } from "react";

const ListGroupsForActivities = (props) => {
  const {
    updatePagination,
    count,
    page,
    setPage,
    activityGroups,
    t,
    isInHouseActivity,
    isAssignedActivity,
  } = props;
  const Navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  useEffect(() => {
    setPage(searchParams.get("page"));
    updatePagination(searchParams.get("page"));
  }, [searchParams.get("page")]);
  return (
    <Grid container>
      <Grid container className="table-header mb-20px">
        <Grid item md={1} textAlign="center">
          {isInHouseActivity === true
            ? t("users.no")
            : `${t("reservations.id")}`}
        </Grid>
        <Grid item md={3}>
          {t("reservations.activityDateTime")}
        </Grid>
        {isInHouseActivity === true ? (
          <Grid item sm={2}>
            {t("salesmen.bookedAvailable")}
          </Grid>
        ) : (
          <Grid item sm={2}>
            {t("reservations.bookedCapacity")}
          </Grid>
        )}
        <Grid item sm={2}>
          {t("reservations.status")}
        </Grid>
      </Grid>
      {activityGroups?.length === 0 && (
        <Grid container display="block" textAlign="center">
          {t("offer.thereAreNoRecords")}
        </Grid>
      )}

      {activityGroups &&
        activityGroups[0] &&
        activityGroups?.map((activityItem, index) => (
          <Grid
            key={index}
            onClick={() =>
              isInHouseActivity !== true && isAssignedActivity !== true
                ? Navigate(
                    `/reservations/${activityItem?.id}?page=${pageNumber}`
                  )
                : null
            }
            container
            lg={12}
            className="activity-item mb-20px"
            sx={{
              cursor:
                isInHouseActivity !== true && isAssignedActivity !== true
                  ? "pointer !important"
                  : "default !important",
            }}
          >
            <Grid item md={1} textAlign="center">
              {activityItem?.id}
            </Grid>
            <Grid item md={3} fontWeight="bold">
              {activityItem?.start_date} / {activityItem?.start_time}
            </Grid>
            <Grid item sm={2}>
              {activityItem?.reservations_count}/ {activityItem?.capacity}
            </Grid>
            <Grid item sm={2}>
              {activityItem?.status}
            </Grid>
            {isAssignedActivity === true ? (
              <>
                <Grid
                  sm={"auto"}
                  sx={{
                    cursor: "pointer",
                    fontSize: "16px",
                    fontWeight: "500",
                    textDecoration: "underline",
                    marginRight: "30px",
                    marginLeft: "auto",
                  }}
                  onClick={() =>
                    Navigate(`/salesmen/reservations?page=${pageNumber}`, {
                      state: { assignedActivityGroupId: activityItem?.id },
                    })
                  }
                >
                  {t("salesmen.viewReservationsDetails")}
                </Grid>
              </>
            ) : null}
          </Grid>
        ))}
      {count > 10 && (
        <Grid container justifyContent="center" mb={7.5} mt={5}>
          <CustomPagination
            count={Math.ceil(count / 10)}
            size="large"
            shape="rounded"
            page={page}
            className="pagination"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default withTranslation()(ListGroupsForActivities);
