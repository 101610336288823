import BranchDetailsTemplate from "../../../Templates/Main/Branches/BranchDetails.template.jsx";
import { getBranchDetails } from "../../../Contexts/APIs/Branches/Branches.js";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";

const BranchDetailsPage = (props) => {
    const { t, branchesPrivilages } = props;
    const { id } = useParams();
    const [branchDetails, setBranchDetails] = useState({});

    const GetbranchDetails = async () => {
        const {res, err} = await getBranchDetails(id);
        if(res) {
            setBranchDetails(res?.data.branch);
        }
    };

    useEffect(() => {
        GetbranchDetails();
    }, []);

    return (
        <BranchDetailsTemplate
            branchDetails={branchDetails}
            branchesPrivilages={branchesPrivilages}
        />
    )
}

export default BranchDetailsPage