import { Grid } from "@mui/material";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PagesHead from "../../../Molecules/Users/PagesHead";
import SearchIcon from "@mui/icons-material/Search";
import RolesList from "../../../Molecules/Users/Roles/RolesList/RolesList";
import Loader from "../../../Atoms/Loader/Loader";
import { withTranslation } from "react-i18next";

function Roles(props) {
  const {
    t,
    filterObj,
    setFilterObj,
    rolesData,
    setRolesData,
    openLoader,
    setOpenLoader,
    count,
    page,
    handlePageChange,
    usersPrivilages,
    updatePagination,
  } = props;
  const navigate = useNavigate();
  const userType = localStorage.getItem("userType");
  let [searchParams, setSearchParams] = useSearchParams();

  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  return (
    <>
      <Loader open={openLoader} />
      <Grid container>
        <PagesHead
          title={`${t("users.roles")}`}
          searchInput={{
            type: "search",
            placeholder: `${t("users.rolesSearchPlaceholder")}`,
            StartAdornment: { SearchIcon },
            className: "Search-roles",
          }}
          primaryButtons={
            userType !== "descendant_user" ||
            (userType === "descendant_user" && usersPrivilages?.can_create)
              ? [
                  {
                    fontSize: "14px",
                    startIcon: true,
                    text: `${t("users.addNewRole")}`,
                    classBtn: "primary r-25px",
                    style: { padding: "5px 20px", height: "48px" },
                    click: () =>
                      navigate(`/users/roles/add?page=${pageNumber}`),
                  },
                ]
              : []
          }
          filterObj={filterObj}
          setFilterObj={setFilterObj}
        />
        <RolesList
          rolesData={rolesData}
          setRolesData={setRolesData}
          setOpenLoader={setOpenLoader}
          count={count}
          page={page}
          handlePageChange={handlePageChange}
          usersPrivilages={usersPrivilages}
          updatePagination={updatePagination}
        />
      </Grid>
    </>
  );
}

export default withTranslation()(Roles);
