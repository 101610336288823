import { axiosFn, handleError } from "../index";
import * as URL from "../URLs.js";
import { makeFilterString } from "../Helper/Helper.js";


export async function getDiveCenterStatistics(filter_obj) {
    return await axiosFn("get", URL.DiveCenterStatistics + makeFilterString(filter_obj))
        .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}
export async function getSalesmanStatistics(filter_obj) {
    return await axiosFn("get", URL.SalesmanStatistics + makeFilterString(filter_obj))
        .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}
export async function getjobOfferStatistics(filter_obj) {
    return await axiosFn("get", URL.JobOfferStatistics + makeFilterString(filter_obj))
        .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}
