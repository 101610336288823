import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import CustomSeparator from "../../../../Components/Atoms/BreadCrumb";
import TextFieldInput from "../../../../Components/Atoms/Input/TextField/TextFieldInput";
import SearchIcon from "@mui/icons-material/Search";
import ListJobApplicatnts from "../../../../Components/Molecules/JobOffers/Lists/ListJopApplicant";
import { t } from "i18next";
import { withTranslation } from "react-i18next";

const OfferApplicantTamplate = ({
  jobOfferData,
  count,
  applicants,
  getJobOffers,
  type,
  title,
  is_navigate,
  setFilter,
  jobRequestsPrivilages,
  t,
}) => {
  const lang = localStorage.getItem("lang");
  let [searchParams, setSearchParams] = useSearchParams();
  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  return (
    <Stack>
      <CustomSeparator
        breadcrumbs={[
          <Link
            style={{ color: "#bebebe", textDecoration: "none" }}
            underline="hover"
            key="1"
            color="inherit"
            to={`/freelancers/list?page=${pageNumber}`}
          >
            {t("joboffer.freelancer")}
          </Link>,
          <Link
            style={{ color: "#bebebe", textDecoration: "none" }}
            underline="hover"
            key="2"
            color="inherit"
            to={`/freelancers/job-offers?page=${pageNumber}`}
          >
            {t("joboffer.jopOffers")}
          </Link>,
          <Link
            style={{ color: "#bebebe", textDecoration: "none" }}
            underline="hover"
            key="2"
            color="inherit"
            to={`/freelancers/job-offers/${jobOfferData?.id}?page=${pageNumber}`}
          >
            {jobOfferData?.title}
          </Link>,
          <Typography
            key="3"
            style={{
              color: "#bebebe",
              textDecoration: "none",
              textTransform: "capitalize",
            }}
          >
            {t("joboffer.applicants")}
          </Typography>,
        ]}
      />
      <Stack flexDirection="row" justifyContent="space-between">
        <Grid container item xs={8}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: "30px",
                fontWeight: "bold",
              }}
            >
              {`${t("joboffer.JobOfferTitle")} - `}
              {jobOfferData?.localized_data &&
                jobOfferData?.localized_data[lang]?.title}
            </Typography>
          </Grid>

          <Stack item xs={12} direction="row" gap={2}>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              {jobOfferData?.location?.city?.name}
            </Typography>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              {jobOfferData?.branch?.name}
            </Typography>

            <Box>
              <Typography
                display="inline"
                sx={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#faa100",
                }}
              >
                {jobOfferData?.rate}{" "}
              </Typography>
              <Typography
                display="inline"
                sx={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#faa100",
                  textTransform: "uppercase",
                }}
              >
                {jobOfferData?.currency?.lookup_key}
              </Typography>
              <Typography
                display="inline"
                sx={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#faa100",
                }}
              >
                {`/${t("reservations.day")}`}
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <TextFieldInput
          style={{ width: "30%" }}
          type="search"
          placeholder={t("joboffer.searchActivities")}
          StartAdornment={<SearchIcon />}
          className="reservation-search"
          onChange={(e) => setFilter(`dive_expert_keyword=${e.target.value}`)}
          sx={{ padding: "0px!important" }}
        />
      </Stack>

      <Divider sx={{ width: "100%", my: "20px" }} />
      <ListJobApplicatnts
        jobOfferData={jobOfferData}
        count={count}
        applicants={applicants}
        getJobOffers={getJobOffers}
        jobRequestsPrivilages={jobRequestsPrivilages}
        type={type}
        title={title}
        is_navigate={is_navigate}
      />
    </Stack>
  );
};

export default withTranslation()(OfferApplicantTamplate);
