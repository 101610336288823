import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import UpdateBankDetailsModal from "../../../Components/Organisms/DiveCenterAccount/Models/UpdateBankDetails";
import AccountBankDetailsTemplate from "../../../Templates/Main/Account/AccountBankDetails.template";
import UpdatePassword from "../../../Components/Organisms/DiveCenterAccount/Models/UpdatePassword";
import { confirmPassword } from "../../../Contexts/APIs/Auth/ConfirmPassword";
import { toast } from "react-toastify";

const AccountBankDetailsPage = ({ profileData }) => {
  const [diveCenterDetails, setDiveCenterDetails] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const dive_center_id = localStorage.getItem("userId");
  const [open, setOpen] = useState(false);

  const ConfirmPassword = async (dive_center_id, password) => {
    const { res, err } = await confirmPassword(dive_center_id, password);
    if (res) {
      setOpen(false);
      setOpenModal(true);
    } else {
      toast.error(err);
    }
  };

  const handelClick = (password) => {
    ConfirmPassword(dive_center_id, password);
  };

  const [newDiveCenterDetails, setNewDiveCenterDetails] = useState(
    profileData?.bank_account
  );
  return (
    <>
      <AccountBankDetailsTemplate
        setOpenModal={setOpenModal}
        openModal={openModal}
        newDiveCenterDetails={newDiveCenterDetails}
        setOpen={setOpen}
      />
      {open && (
        <UpdatePassword
          open={open}
          setOpen={setOpen}
          handleConfirm={handelClick}
        />
      )}
      {openModal && (
        <UpdateBankDetailsModal
          newDiveCenterDetails={newDiveCenterDetails}
          setNewDiveCenterDetails={setNewDiveCenterDetails}
          open={openModal}
          setOpen={setOpenModal}
        />
      )}
    </>
  );
};

export default AccountBankDetailsPage;
