import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Divider, Grid, Stack } from "@mui/material";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput";
import { withTranslation } from "react-i18next";
import { confirmPassword } from "../../../../Contexts/APIs/Auth/ConfirmPassword";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const UpdatePassword = ({ open, setOpen, t, handleConfirm }) => {
  const [password, setPassword] = useState();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 900,
    p: 4,
  };
  const handleClose = () => setOpen(false);
  const dive_center_id = localStorage.getItem("userId");
  const navigate = useNavigate();

  const handelClick = async (password) => {
    const { res, err } = await confirmPassword(dive_center_id, password);
    if (res) {
      navigate("/update-account");
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid
          className="activity-form"
          bgcolor={"white"}
          gap={2}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
          width={"100%"}
        >
          <Typography
            color="var(--primary-color)"
            fontSize="27px"
            fontWeight="bold"
            textAlign="center"
          >
            {t("profile.forSecurity")}
          </Typography>
          <Typography textAlign="center" fontSize="20px" fontWeight="bold">
            {t("profile.enterDataWithoutShowing")}
          </Typography>

          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            my={4}
          >
            <TextFieldInput
              name="current_password"
              id="current_password"
              placeholder="Password"
              className="login-input"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              onClick={() => {
                handleConfirm ? handleConfirm(password) : handelClick(password);
              }}
              style={{
                borderRadius: "26px",
                backgroundColor: "var(--primary-color)",
                color: "white",
                padding: "5px 20px",
                height: "48px",
                margin: "0 10px",
              }}
            >
              {t("profile.confirm")}
            </Button>
          </Stack>
        </Grid>
      </Box>
    </Modal>
  );
};

export default withTranslation()(UpdatePassword);
