import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../Components/Atoms/Loader/Loader";
import { getFreelancerDetails } from "../../../Contexts/APIs/Freelancers/Freelancers";
import { showJobOffer } from "../../../Contexts/APIs/Job-Offers/JobOffers";
import FreelancerDetailsTemplate from "../../../Templates/Main/Freelancers/FreelancerDetails.Template";
import PrivateGuideReservationsDetailsTemplate from "../../../Templates/Main/PrivateGuideReservations/PrivateGuideReservationsDetails.Template";
import { reservationDetails } from "../../../Contexts/APIs/Reservations/Reservations";

const PrivateGuideReservationsDetailsPage = () => {
  const { id } = useParams();
  const [reservationData, setReservationData] = useState({});
  const [applicantData, setApplicantData] = useState({});

  const [openLoader, setOpenLoader] = useState(false);

  const getReservationData = async () => {
    setOpenLoader(true);
    const { res, err } = await reservationDetails(id);
    if (res) setReservationData(res?.data?.order);
    setOpenLoader(false);
  };

  useEffect(() => {
    if (id) getReservationData();
  }, [id]);
  return (
    <>
      <Loader open={openLoader} />
      <PrivateGuideReservationsDetailsTemplate
        reservationData={reservationData}
      />
    </>
  );
};

export default PrivateGuideReservationsDetailsPage;
