import { Box, Button, Modal, Stack, Typography } from '@mui/material';
import React from 'react'

const CompleteJopOfferModel = ({
    open, setOpen, handelCompleteOffer
}) => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '25px',

    };


    const handleClose = () => setOpen(false);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Stack sx={style} alignItems='center' justifyContent='center'>
                <Typography component="h1" style={{ color: '#f6b119', fontWeight: "bold", fontSize: "30px" }} >
                    Mark job offer completed
                </Typography>
                <Typography textAlign='center' style={{ fontWeight: "bold" }}>
                    are you sure that make activity completed ?

                </Typography>
                <Stack flexDirection='row' alignItems='center' mt={4}>
                    <Button style={{ width: '150px', color: 'black' }} onClick={() => setOpen(false)}>Cancel</Button>

                    <Button style={{ width: '150px' }} className='primary r-25px' onClick={handelCompleteOffer}>Accept</Button>
                </Stack>
            </Stack>

        </Modal>
    )
}

export default CompleteJopOfferModel