import { Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import CustomSeparator from "../../../Components/Atoms/BreadCrumb";
import ListHistory from "../../../Components/Molecules/Freelancers/Lists/ListHistory";
import { withTranslation } from "react-i18next";

const FreelancerJopOffersHistoryTemplate = ({
  applicantData,
  jobOfferData,
  offers,
  count,
  handleChange,
  t,
}) => {
  const lang = localStorage.getItem("lang");
  let [searchParams, setSearchParams] = useSearchParams();
  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  return (
    <Stack sm={12} container gap={3}>
      <CustomSeparator
        breadcrumbs={[
          <Link
            style={{ color: "#bebebe", textDecoration: "none" }}
            underline="hover"
            key="1"
            color="inherit"
            to={`/freelancers/list?page=${pageNumber}`}
          >
            {t("joboffer.freelancer")}
          </Link>,
          <Link
            style={{ color: "#bebebe", textDecoration: "none" }}
            underline="hover"
            key="2"
            color="inherit"
            to={`/freelancers/job-offers?page=${pageNumber}`}
          >
            {t("joboffer.jopOffers")}
          </Link>,
          <Link
            style={{ color: "#bebebe", textDecoration: "none" }}
            underline="hover"
            key="2"
            color="inherit"
            to={`/freelancers/job-offers/${jobOfferData?.id}?page=${pageNumber}`}
          >
            {jobOfferData?.localized_data &&
              jobOfferData?.localized_data[lang]?.title}
          </Link>,
          <Link
            style={{ color: "#bebebe", textDecoration: "none" }}
            underline="hover"
            key="2"
            color="inherit"
            to={`/freelancers/jop-offers-applicants/${jobOfferData?.id}?page=${pageNumber}`}
          >
            {t("joboffer.applicants")}
          </Link>,

          <Link
            style={{ color: "#bebebe", textDecoration: "none" }}
            underline="hover"
            key="2"
            color="inherit"
            to={`/freelancers/job-offers/${jobOfferData?.id}/applicant/${applicantData?.id}?page=${pageNumber}`}
          >
            {applicantData?.name}
          </Link>,
          <Typography
            key="3"
            style={{ color: "#bebebe", textDecoration: "none" }}
          >
            {t("joboffer.jobHistory")}
          </Typography>,
        ]}
      />

      <Grid item sm={5}>
        <Typography
          component="h1"
          style={{ fontWeight: "bold", fontSize: "30px" }}
        >
          {applicantData?.firstname} {applicantData?.lastname} Job history
        </Typography>
      </Grid>
      <Divider style={{ width: "100%" }} />
      <ListHistory offers={offers} count={count} handleChange={handleChange} />
    </Stack>
  );
};

export default withTranslation()(FreelancerJopOffersHistoryTemplate);
