import { Divider, Grid, Typography,Checkbox } from "@mui/material";

import React, { useEffect } from "react";
import FormHead from "../../Atoms/FormHead/FormHead";
import TextFieldInput from "../../Atoms/Input/TextField/TextFieldInput";
import "./RolesForm.css";

import RoleSwitch from "../../Atoms/Switch/RoleSwitch";
import { withTranslation } from "react-i18next";

const RolesForm = (props) => {
  const {
    t,
    formik,
    roleDataRes,
    optionsStateObj,
    handleFormSubmit,
    validationMethod,
    handlePermissionsChange,
    handleBatchPermissionsChange,
    formType,
    rows,handleSelectedRowsChange
  } = props;
  useEffect(() => {
    handleFormSubmit.current = validationMethod;
  }, [formik.values.name]);
  return (
    <>
      <Grid container item lg={12} flexDirection="row" gap={2}>
        <Grid container item xs={12}>
          <Grid container item xs={12} lg={8} className="roles-form-container">
            <Grid item xs={12}>
              <FormHead
                text={
                  formType === "Add"
                    ? t("users.addRoleTitle")
                    : t("users.editRoleTitle")
                }
              ></FormHead>
            </Grid>
            <Grid item xs={12} lg={7}>
              <TextFieldInput
                name="name"
                id="name"
                placeholder={
                  formType === "Add"
                    ? t("users.addRoleTitle")
                    : t("users.editRoleTitle")
                }
                className="role-title"
                type="text"
                value={formik.values.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
          flexDirection="row"
          gap={3}
          className="roles-form-container"
        >
          <Grid container item xs={12} >
            <Grid item xs={12}>
              <FormHead
              text={`${
                formType === "Add" ? t("users.add") : t("users.edit")
              } ${t("users.rolePermissions")}`}
              ></FormHead>
            </Grid>
            <Grid item xs={12}>
              <Typography component="p">
              {t("users.rolePermissionsTitle")}
              </Typography>
            </Grid>
          </Grid>

          <Divider style={{ background: "#d9d9d9", width: "100%" }} />

          <Grid item xs={12}>
            <Grid container>
              <Grid container className="table-header mb-20px">
                <Grid item md={1} display={'flex'} alignItems={'center'}>
                  <Checkbox  checked={optionsStateObj.select_all} 
                  onChange={(e)=>{
                    handleSelectedRowsChange(
                      e.target.checked,
                      "all",// select all
                    )
                  }}

                  inputProps={{ 'aria-label': 'controlled' }}

                  
                  />
                  <Typography  sx={{fontSize:"18px",textDecoration: 'underline',fontWeight:700,color:'#000000 !important'}}>{t("users.selectAll")}</Typography>
                </Grid>
                <Grid item md={1} textAlign="center" >
                {t("users.no")}
                </Grid>
                <Grid item md={6}>
                {t("users.permission")}
                </Grid>
                <Grid item sm={1} display={'flex'} alignItems={'center'}>
                  <RoleSwitch
                        val={{
                          name: `can_create_selectAll`,
                          val: optionsStateObj.can_create,
                        }}
                        change={(e) =>
                          handleBatchPermissionsChange(
                            'can_create',
                            e.target.checked)
                        }
                        disabled={optionsStateObj.disabled}
                        label={t("users.create")}

                      />
                </Grid>
                <Grid item sm={1} display={'flex'} alignItems={'center'}>
                  <RoleSwitch
                        val={{
                          name: `can_read_selectAll`,
                          val: optionsStateObj.can_read,
                        }}
                        change={(e) =>
                          handleBatchPermissionsChange(
                            'can_read',
                            e.target.checked)
                        }
                        disabled={optionsStateObj.disabled}
                        label={t("users.read")}
                      />
                </Grid>

                <Grid item sm={1} display={'flex'} alignItems={'center'}>
                  <RoleSwitch
                        val={{
                          name: `can_update_selectAll`,
                          val: optionsStateObj.can_update,
                        }}
                        change={(e) =>
                          handleBatchPermissionsChange(
                            'can_update',
                            e.target.checked)
                        }
                        disabled={optionsStateObj.disabled}
                        label={t("users.edit")}

                      />
                    
                </Grid>
                <Grid item sm={1} display={'flex'} alignItems={'center'}>
                  <RoleSwitch
                        val={{
                          name: `can_delete_selectAll`,
                          val: optionsStateObj.can_delete,
                        }}
                        change={(e) =>
                          handleBatchPermissionsChange(
                            'can_delete',
                            e.target.checked)
                        }
                        disabled={optionsStateObj.disabled}
                        label={t("users.delete")}
                      />
                    
                </Grid>
              </Grid>

              {roleDataRes?.length === 0 && (
                <Grid container display="block" textAlign="center">
                                    {t("users.noPermissions")}
                </Grid>
              )}

              {roleDataRes?.map((item, index) => {
                return (
                  <Grid
                    key={index}
                    container
                    item
                    lg={12}
                    className="role-item mb-20px"
                  >
                    <Grid item md={1} color="#ccc" textAlign="center">
                      <Checkbox  checked={rows[index]} onChange={(e)=>{
                        handleSelectedRowsChange(
                          e.target.checked,
                          index
                        )
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}

                      />
                    </Grid>


                    <Grid item md={1} color="#ccc" textAlign="center">
                      {index + 1}
                    </Grid>
                    <Grid item md={6} textTransform="capitalize">
                      {item?.title}
                    </Grid>
                    <Grid item sm={1}>
                      <RoleSwitch
                        crudIndex={index}
                        val={{
                          name: `can_create_${index + 1}`,
                          val: item.can_create,
                        }}
                        change={(e) =>
                          handlePermissionsChange(
                            "can_create",
                            e.target.checked,
                            item.resource_id
                          )
                        }
                        disabled={(item.title === 'Activity Offers' || item.title === 'Customer Levels' || item.title === 'Freelancer Levels' 
                          || item.title === 'Documents' || item.title === 'Salesman Reservations' || item.title === 'Salesman Dashboard'
                        ) ? true : false}
                      />
                    </Grid>
                    <Grid item sm={1}>
                      <RoleSwitch
                        crudIndex={index}
                        val={{
                          name: `can_read_${index + 1}`,
                          val: item.can_read,
                        }}
                        change={(e) =>
                          handlePermissionsChange(
                            "can_read",
                            e.target.checked,
                            item.resource_id
                          )
                        }
                      />
                    </Grid>
                    <Grid item sm={1}>
                      <RoleSwitch
                        crudIndex={index}
                        val={{
                          name: `can_update_${index + 1}`,
                          val: item.can_update,
                        }}
                        change={(e) =>
                          handlePermissionsChange(
                            "can_update",
                            e.target.checked,
                            item.resource_id
                          )
                        }
                        disabled={(item.title === 'Activity Offers' || item.title === 'Salesman Dashboard') ? true : false}
                      />
                    </Grid>

                    <Grid item sm={1}>

                      <RoleSwitch
                        crudIndex={index}
                        val={{
                          name: `can_delete_${index + 1}`,
                          val: item.can_delete,
                        }}
                        change={(e) =>
                          handlePermissionsChange(
                            "can_delete",
                            e.target.checked,
                            item.resource_id
                          )
                        }
                        disabled={(item.title === 'Activity Offers' || item.title === 'Customer Levels' || item.title === 'Freelancer Levels' 
                          || item.title === 'Documents' || item.title === 'Salesman Reservations' || item.title === 'Salesman Dashboard'
                        ) ? true : false}
                      />
                    </Grid>

                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withTranslation()(RolesForm);
