import { Grid, Typography, Divider } from "@mui/material";
import TabPanel from "../../Atoms/Tabs/TabPanel.js";
import ListRequestedActivity from "./List.jsx";
import { useState } from "react";

const RequestedActivityPanal = (props) => {
  const {
    options,
    ActivityValue,
    activitesList,
    setActivitiesList,
    count,
    page,
    setPage,
    updatePagination,
    activitiesPrivilages,
    getActivities,
    filterObj,
    setOpenLoader,
    isInHouseActivity,
  } = props;

  return (
    <Grid container>
      <Divider style={{ width: "100%", margin: "20px 0" }} />

      {options &&
        options.map((option, index) => (
          <Grid container key={index}>
            <TabPanel value={ActivityValue} index={index} className="tab-panel">
              {index === 0 && (
                <Grid container>
                  <ListRequestedActivity
                    activitesList={activitesList}
                    setActivitiesList={setActivitiesList}
                    count={count}
                    page={page}
                    setPage={setPage}
                    updatePagination={updatePagination}
                    activitiesPrivilages={activitiesPrivilages}
                    getActivities={getActivities}
                    filterObj={filterObj}
                    setOpenLoader={setOpenLoader}
                  />
                </Grid>
              )}
              {index === 1 && (
                <Grid container>
                  <ListRequestedActivity
                    activitesList={activitesList}
                    setActivitiesList={setActivitiesList}
                    count={count}
                    page={page}
                    setPage={setPage}
                    updatePagination={updatePagination}
                    activitiesPrivilages={activitiesPrivilages}
                    getActivities={getActivities}
                    filterObj={filterObj}
                    setOpenLoader={setOpenLoader}
                  />
                </Grid>
              )}
              {index === 2 && (
                <Grid container>
                  <ListRequestedActivity
                    activitesList={activitesList}
                    setActivitiesList={setActivitiesList}
                    count={count}
                    page={page}
                    setPage={setPage}
                    updatePagination={updatePagination}
                    activitiesPrivilages={activitiesPrivilages}
                    getActivities={getActivities}
                    filterObj={filterObj}
                    setOpenLoader={setOpenLoader}
                  />
                </Grid>
              )}
              {index === 3 && (
                <Grid container>
                  <ListRequestedActivity
                    activitesList={activitesList}
                    setActivitiesList={setActivitiesList}
                    count={count}
                    page={page}
                    setPage={setPage}
                    updatePagination={updatePagination}
                    activitiesPrivilages={activitiesPrivilages}
                    getActivities={getActivities}
                    filterObj={filterObj}
                    setOpenLoader={setOpenLoader}
                  />
                </Grid>
              )}
            </TabPanel>
          </Grid>
        ))}
      {!options && (
        <Grid container>
          <ListRequestedActivity
            activitesList={activitesList}
            setActivitiesList={setActivitiesList}
            count={count}
            page={page}
            setPage={setPage}
            updatePagination={updatePagination}
            activitiesPrivilages={activitiesPrivilages}
            getActivities={getActivities}
            filterObj={filterObj}
            setOpenLoader={setOpenLoader}
            isInHouseActivity={isInHouseActivity}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default RequestedActivityPanal;
