import { Grid, Typography } from "@mui/material";
import BranchesList from "../../../Components/Organisms/Branches/BranchesList.jsx";

const BranchesTemplate = (props) => {
  const {
    branchesList,
    count,
    branchesPrivilages,
    setFilterObj,
    filterObj,
    updatePagination,
    page,
  } = props;

  return (
    <Grid container>
      <BranchesList
        branchesList={branchesList}
        count={count}
        branchesPrivilages={branchesPrivilages}
        setFilterObj={setFilterObj}
        filterObj={filterObj}
        updatePagination={updatePagination}
        page={page}
      />
    </Grid>
  );
};

export default BranchesTemplate;
