import LoginTemplate from "../../Templates/Auth/Login.Template.jsx";
import { login } from "../../Contexts/APIs/Auth/Login.js";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { Firebase } from "../../Contexts/Firebase/Firebase.js";

const LoginPage = (props) => {
  const { t } = props;

  const Login = async (data) => {
    const { res, err } = await login("dive_center", data);
    if (res) {
      localStorage.setItem("accessToken", res?.data?.extra?.access_token);
      localStorage.setItem("userId", res?.data?.user?.id);
      localStorage.setItem("contract", res.data?.user?.dive_center_contract);
      localStorage.setItem("email", res.data?.user?.email);
      localStorage.setItem("name", res.data?.user?.name);
      localStorage.setItem("userType", res.data?.user?.user_type);
      localStorage.setItem("firebase_auth_token", res?.data?.user?.firebase_auth_token);
      localStorage.setItem("firebase_node_path", res?.data?.user?.firebase_node_path);

      if (!localStorage.getItem("fcm_token")) {
        localStorage.setItem("fcm_token", window.navigator.userAgent);
      }
      if (res.data?.user?.user_type == "descendant_user") {
        localStorage.setItem("Token", res?.data?.extra?.access_token);
        localStorage.setItem("ancestorId", res?.data?.user?.ancestor_id);
        window.location.href = "/profile";
      } else {
        if (res?.data?.user?.user_type === "super_admin") {
          window.location.href = "/select-user";
        } else if (!res?.data?.user?.is_password_changed) {
          window.location.href = "/change-password";
        } else if (res?.data?.user?.profile_status == "incomplete") {
          window.location.href = "/complete-account";
        } else if (res?.data?.user?.profile_status == "pending_approval") {
          //   window.location.href = "/pending-review";
          localStorage.setItem("Token", res?.data?.extra?.access_token);
          window.location.href = "/";
        } else if (res?.data?.user?.profile_status == "rejected") {
          localStorage.setItem("Msg", res.data?.user?.rejection_reason);
          // window.location.href = "/profile-rejected";
          localStorage.setItem("Token", res?.data?.extra?.access_token);
          window.location.href = "/";
        } else if (res?.data?.user?.profile_status == "approved") {
          localStorage.setItem("Token", res?.data?.extra?.access_token);
          window.location.href = "/profile";
        }
      }
    }
  };

  // useEffect(() => {
  //   if (!localStorage.getItem("fcm_token")) Firebase.getFCMToken();
  // }, [localStorage.getItem("fcm_token")]);

  return <LoginTemplate t={t} Login={Login} />;
};

export default LoginPage;
