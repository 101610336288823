import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import './FaqAccordion.css';

function FaqAccordion({index, head, text}) {

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Accordion className={`custom-accordion custom-accordion-${expanded}`} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}
      sx={{ backgroundColor: '#f4f4f4' }}
    >
        <AccordionSummary expandIcon={<ExpandMoreIcon sx={{color: '#000 !important', opacity: '1 !important'}} />} aria-controls={`panel${index}d-content`} id={`panel${index}d-header`}>
            <Typography> {head} </Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography> {text} </Typography>
        </AccordionDetails>
    </Accordion>
  )
}

FaqAccordion.propTypes = {
  index: PropTypes.number,
  head: PropTypes.string,
  text: PropTypes.string
}

export default FaqAccordion