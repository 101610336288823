import React, { useEffect, useState } from "react";
import Loader from "../../../../Components/Atoms/Loader/Loader";
import { getActivitiesList } from "../../../../Contexts/APIs/Activities/Activities";
import { getBranchesList } from "../../../../Contexts/APIs/Branches/Branches";
import { OrganizationsAPI } from "../../../../Contexts/APIs/Lookups/Certificates/Organizations";
import { CurrencyAPI } from "../../../../Contexts/APIs/Lookups/Currency";
import { LanguageAPI } from "../../../../Contexts/APIs/Lookups/Language";
import { getCertificates } from "../../../../Contexts/APIs/Lookups/OrganizationCertificates";
import { getSupportedLanguages } from "../../../../Contexts/APIs/Lookups/SupportedLanguages";
import AddJopOfferTemplate from "../../../../Templates/Main/Freelancers/JopOffers/AddJopOffer.template";

const AddJopForPage = ({ type,profileData }) => {
  const [openLoader, setOpenLoader] = useState(false);
  const [branchesList, setbranchesList] = useState([]);
  const [branchesCount, setbranchesCount] = useState(10);

  const [currencies, setCurrencies] = useState([]);
  const [organizationsList, setOrganizationsList] = useState([]);
  const [organizationsCount, setOrganizationsCount] = useState(10);

  const [certificatesList, setCertificatesList] = useState([]);
  const [certificatesCount, setCertificatesCount] = useState(10);

  const [languagesList, setLanguagesList] = useState([]);
  const [languagesCount, setLanguagesCount] = useState(10);

  const [supportedLanguages, setSupportedLanguages] = useState([]);
  const [activityType, setActivityType] = useState("");
  const [orgId, setOrgId] = useState(null);
  const userId =
    localStorage.getItem("userType") !== "descendant_user"
      ? localStorage.getItem("userId")
      : localStorage.getItem("ancestorId");

  const getBranches = async (filter) => {
    const filterData = {
      page_size: 10,
      page_number: 1,
      status: "approved",
      dive_center_id: userId,
      ...filter,
    };
    const { res, err } = await getBranchesList({
      ...filterData,
    });
    if (res) {
      filterData?.page_number == 1
        ? setbranchesList(res?.data?.branches)
        : setbranchesList((s) => [...s, ...res?.data?.branches]);
      setbranchesCount(res?.extra?.total_count);
    }
  };

  const getLanguagesAPI = async (filter) => {
    const { res, err } = await LanguageAPI({
      page_size: 10,
      page_number: 1,
      ...filter,
    });
    if (res) {
      filter?.page_number == 1
        ? setLanguagesList(res?.data?.languages)
        : setLanguagesList((s) => [...s, ...res?.data?.languages]);

      setLanguagesCount(res?.extra?.total_count);
    }
  };
  const getCurrencies = async () => {
    const { res, err } = await CurrencyAPI({
      page_size: 10000,
      page_number: 1,
    });
    if (res) {
      res?.data?.currencies.map((currency) => {
        setCurrencies((currencies) => [
          ...currencies,
          { name: currency?.lookup_key?.toUpperCase(), id: currency?.id },
        ]);
      });
    }
  };

  const getOrganization = async (filter) => {
    const filterData = {
      page_size: 10,
      page_number: 1,

      ...filter,
    };
    const { res, err } = await OrganizationsAPI({
      ...filterData,
    });
    if (res) {
      filterData?.page_number == 1
        ? setOrganizationsList(res?.data?.organizations)
        : setOrganizationsList((s) => [...s, ...res?.data?.organizations]);
      setOrganizationsCount(res?.extra?.total_count);
    }
    if (err) {
    }
  };

  const getCertificatesList = async (filter) => {
    const filterData = {
      page_size: 10,
      page_number: 1,
      organization_id: orgId,
      is_professional: true,

      ...filter,
    };
    const { res, err } = await getCertificates({
      ...filterData,
    });
    if (res) {
      filterData?.page_number == 1
        ? setCertificatesList(res?.data?.organization_certificates)
        : setCertificatesList((s) => [
            ...s,
            ...res?.data?.organization_certificates,
          ]);

      setCertificatesCount(res?.extra?.total_count);
    }
  };

  const getLanguages = async () => {
    const { res, err } = await getSupportedLanguages();
    if (res) {
      setSupportedLanguages(res?.data?.supported_locales);
    }
  };
  useEffect(() => {
    getCurrencies();
    // getOrganization();
    // getLanguagesAPI()
    getLanguages();
  }, []);

  useEffect(() => {
    if (userId && activityType)
      getBranches({
        dive_center_id: userId,
        branch_type: activityType == "live_aboard" ? "safari_boat" : "",
      });
  }, [activityType]);

  return (
    <>
      <Loader open={openLoader} />
      <AddJopOfferTemplate
        getOrganization={getOrganization}
        getCertificatesList={getCertificatesList}
        getBranches={getBranches}
        branchesCount={branchesCount}
        userId={userId}
        branchesList={branchesList}
        currencies={currencies}
        organizationsList={organizationsList}
        certificatesList={certificatesList}
        languagesList={languagesList}
        type={type}
        supportedLanguages={supportedLanguages}
        orgId={orgId}
        setOrgId={setOrgId}
        activityType={activityType}
        setActivityType={setActivityType}
        getLanguagesAPI={getLanguagesAPI}
        languagesCount={languagesCount}
        organizationsCount={organizationsCount}
        certificatesCount={certificatesCount}
        profileData={profileData}
      />
    </>
  );
};

export default AddJopForPage;
