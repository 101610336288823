import { useState, useEffect, useMemo } from "react";
import {
  getPresignedLinkAPI,
  uploadFileAPI,
} from "../../../../Contexts/APIs/General/upload.js";
import Loader from "../../../../Components/Atoms/Loader/Loader.js";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import SalesmenFormTemplate from "../../../../Templates/Main/Salesmen/SalesmenList/SalesmenForm.template.jsx";
import { useFormik } from "formik";
import {
  EditSalesmen,
  createNewSalesmen,
  getSalesmenDetails,
} from "../../../../Contexts/APIs/Salsemen/Salesmen.js";
import * as yup from "yup";
import { withTranslation } from "react-i18next";
import { getBranchesList } from "../../../../Contexts/APIs/Branches/Branches.js";
const SalesmenFormPage = (props) => {
  const { t, type, privilages,profileData } = props;
  let [searchParams, setSearchParams] = useSearchParams();

  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  const [image, setImage] = useState([]);
  const [openLoader, setOpenLoader] = useState(false);
  const [branchesList, setbranchesList] = useState([]);
  const [branchesCount, setbranchesCount] = useState(10);
  const Navigate = useNavigate();
  const { id } = useParams();
  const [salesmanData, setSalesmanData] = useState({});
  const userType = localStorage.getItem("userType");
  const userId =
    userType !== "descendant_user"
      ? localStorage.getItem("userId")
      : localStorage.getItem("ancestorId");

  const removeProperties = (obj, ...properties) => {
    const result = { ...obj };
    properties.forEach(function (property) {
      delete result[property];
    });
    return result;
  };

  const uploadImages = async (event) => {
    const file = event.target.files[0];
    const fileSize = file.size / window.maxFileSize; // in MiB
    if (fileSize > 1) {
      toast.warning(window.fileSize);
    } else {
      setOpenLoader(true);
      const files = [{ extension: "png" }];
      const res = await getPresignedLinkAPI(files);
      const presignedUrl = res?.data[0].presigned_url;
      const upload = await uploadFileAPI(presignedUrl, file);
      if (upload) {
        setImage({
          media_type: "photo",
          url: res?.data[0]?.file_url,
        });
      }
      setOpenLoader(false);
    }
  };

  const removeImage = (i) => {
    setImage({});
  };

  useEffect(() => {
    if (type === "edit") {
      getSalesman(id);
    }
  }, []);

  const getSalesman = async (id) => {
    setOpenLoader(true);
    const { res, error } = await getSalesmenDetails(id);
    if (res) {
      setSalesmanData(res?.data);

      formik.setValues({
        owner_type: res?.data["salesman"]["owner_type"],
        owner_id: res?.data["salesman"]["owner_id"],
        name: res?.data["salesman"]["name"],
        email: res?.data["salesman"]["email"],
        country_code:
          res?.data["salesman"]["country_code"]?.indexOf("+") !== -1
            ? res?.data["salesman"]["country_code"]
            : `+${res?.data["salesman"]["country_code"]}`,
        phone_number: res?.data["salesman"]["phone_number"],
        password: "",
        password_confirmation: "",
        avatar: res?.data["salesman"]["avatar"],
        branch_id: res?.data["salesman"]?.branch?.id,
        branch_name: res?.data["salesman"]?.branch?.name,
      });
    }
    setOpenLoader(false);
  };

  const handleCreateSalesman = async (values) => {
    const { res, err } = await createNewSalesmen({
      salesman: {
        ...values,
        country_code: `+${formik.values.country_code}`,
        avatar: image.url,
      },
    });
    if (res) {
      setImage({});
      Navigate(`/salesmen/salesmen-list?page=${pageNumber}`);
    }
  };

  const handleEditSalesman = async (values) => {
    const { res, err } = await EditSalesmen(id, {
      salesman: {
        ...values,
        avatar: image.url,
      },
    });
    if (res) {
      Navigate(`/salesmen/salesmen-list?page=${pageNumber}`);
    }
  };
  const getBranches = async (filter) => {
    const { res, err } = await getBranchesList({
      page_size: 10,
      page_number: 1,
      dive_center_id: userId,
      // branch_type: search.get("type") == 2 ? "safari_boat" : "",
      ...filter,
    });
    if (res) {
      filter?.page_number == 1
        ? setbranchesList(res?.data?.branches)
        : setbranchesList((s) => [...s, ...res?.data?.branches]);
      setbranchesCount(res?.extra?.total_count);
    }
  };
  const validationInfo = yup.object({
    name: yup.string().trim().required(t("users.userNameRequired")).min(8),
    email: yup.string().trim().required(t("users.emailRequired")).email(),
    country_code: yup.string().required(t("users.countryCodeRequired")),
    phone_number: yup
      .number()
      .positive(t("users.numbersOnlyAllowed"))
      .required(t("users.phoneNumberRequired")),
  });
  const validationPass = yup.object({
    password: yup
      .string()
      .trim()
      .required(t("validation.password"))
      .min(8, t("validation.password_digits")) // Minimum 8 characters
      .matches(/[a-zA-Z]/, t("validation.password_letter")) // At least one letter
      .matches(/[0-9]/, t("validation.password_numbers")),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], t("users.passwordsMustMatch"))
      .required(t("users.confirmRequired")),
  });
  const validationAdd = yup.object({
    name: yup.string().trim().required(t("users.userNameRequired")).min(8),
    email: yup.string().trim().required(t("users.emailRequired")).email(),
    country_code: yup.string().required(t("users.countryCodeRequired")),
    phone_number: yup
      .number()
      .positive(t("users.numbersOnlyAllowed"))
      .required(t("users.phoneNumberRequired")),
    password: yup
      .string()
      .trim()
      .required(t("validation.password"))
      .min(8, t("validation.password_digits")) // Minimum 8 characters
      .matches(/[a-zA-Z]/, t("validation.password_letter")) // At least one letter
      .matches(/[0-9]/, t("validation.password_numbers")),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], t("users.passwordsMustMatch"))
      .required(t("users.confirmRequired")),
  });

  const formik = useFormik({
    initialValues: {
      owner_type: userType,
      owner_id: userId,
      name: "",
      email: "",
      country_code: "33",
      phone_number: "",
      password: "",
      password_confirmation: "",
      avatar: "",
    },
    validationSchema:
      type === "add"
        ? validationAdd
        : type === "edit"
          ? validationInfo
          : type === "edit-password"
            ? validationPass
            : null,

    onSubmit: (values) => {
      type === "add" && handleCreateSalesman(values);

      type === "edit" &&
        handleEditSalesman(
          removeProperties(values, "password", "password_confirmation")
        );

      type === "edit-password" &&
        handleEditSalesman(
          removeProperties(
            values,
            "owner_type",
            "owner_id",
            "name",
            "email",
            "country_code",
            "phone_number",
            "avatar"
          )
        );
    },
    onReset: ()=> {
      formik.setValues({
        owner_type: "",
        owner_id: "",
        name: "",
        email: '',
        phone_number: "",
        password: "",
        password_confirmation: "",
        avatar: '',
      });
    }
  });

  const validationMethod = () => {
    if (Object.keys(image).length === 0 && type !== "edit-password") {
      toast.error(t("salesmen.pleaseUploadSalesmanImage"));
    } else {
      formik.handleSubmit();
    }
  };

  const resetValues = () => {
    formik.setValues({
      owner_type: "",
      owner_id: "",
      name: "",
      email: '',
      phone_number: "",
      password: "",
      password_confirmation: "",
      avatar: '',
    });

    const inps = document.querySelectorAll("input");
    inps.forEach(elem => {
      // console.log('inps', elem.value);
      elem.value = "";
    });
  }

  useEffect(() => {
    salesmanData?.salesman?.avatar &&
      setImage({
        media_type: "photo",
        url: salesmanData.salesman.avatar,
      });
  }, [salesmanData]);

  return (
    <>
      <Loader open={openLoader} />
      {!openLoader && <SalesmenFormTemplate
        formik={formik}
        handleSubmit={validationMethod}          
        uploadImages={uploadImages}          
        image={image}
        removeImage={removeImage}
        type={type}
        privilages={privilages}
        getBranches={getBranches}
        branchesList={branchesList}
        branchesCount={branchesCount}
        profileData={profileData}
        salesmanData={salesmanData}
      />}
    </>
  );
};

export default withTranslation()(SalesmenFormPage);
