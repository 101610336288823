import ActivitiesList from "../../../Components/Organisms/Activities/ActivitiesList.jsx";

const ActivitiesTemplate = (props) => {
  const {
    t,
    activitesList,
    setActivitiesList,
    count,
    getActivities,
    activitiesPrivilages,
    setOpenLoader,
    filterObj,
    setFilterObj,
    updatePagination,
    page,
    setPage,
    profileData
  } = props;

  return (
    <ActivitiesList
      activitesList={activitesList}
      setActivitiesList={setActivitiesList}
      count={count}
      getActivities={getActivities}
      activitiesPrivilages={activitiesPrivilages}
      setOpenLoader={setOpenLoader}
      filterObj={filterObj}
      setFilterObj={setFilterObj}
      updatePagination={updatePagination}
      page={page}
      setPage={setPage}
      profileData={profileData}
    />
  );
};

export default ActivitiesTemplate;
