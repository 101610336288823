import React, { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { Grid, Typography } from '@mui/material'
import { ResetPassOtp, VerifyOtp } from '../../../../Contexts/APIs/ForgotPassword/forgetPassword';
import PrimaryButton from '../../../Atoms/Buttons/Primary/PrimaryButton';
import ForgetPasswordHead from '../ForgetPasswordHead'
import Countdown from 'react-countdown';
import ReactCodeInput from 'react-verification-code-input';
import verifyCodeImg from '../../../../Assets/Images/verificationcode.png';
import { withTranslation } from 'react-i18next';
import './VerificationCode.css';
import '../ForgetPassword.css';

function VerificationCode({t}) {
    const navigate = useNavigate();

    const vrcodeRef = useRef();
    const [vrcode, setVrcode] = useState(null);
    const [countdown, setCountdown] = useState(true);
    const [vrcodeFocus, setVrcodeFocus] = useState(true);
    const startDate = React.useRef(Date.now());
    
    const handleSubmit = async () => {
      await  VerifyOtp({
            "verification_code": vrcode,
            "user": {
                "email": `${localStorage.getItem('email')}`
            }
        }, 'dive_center', navigate);
        // }, 'customer', navigate);
    }

    const handleResend = () => {        
        setVrcode(null);
        ResetPassOtp(
            {"user": {
                "email": `${localStorage.getItem('email')}`
            }}, 'dive_center');    
            // }}, 'customer');
        setCountdown(true);
        setVrcodeFocus(true);
        vrcodeRef.current.__clearvalues__();
    }


  return (
    <Grid container lg={4} md={4} sm={5} xs={8} className='password-form-wrap'>
        <ForgetPasswordHead imgSrc={verifyCodeImg} imgAlt='verificationcode' text={t("forgotPassword.vertifyCodeHead")} />

        <Grid lg={12} md={12} sm={12} xs={12}>
            <div style={{ margin: "auto", width: "100%" }} className='password-form'>
                <Grid container item xs={12} justifyContent='center' mt={3} mb={2}>
                    <ReactCodeInput
                        fields={6}
                        autoFocus={vrcodeFocus}
                        className="verification-code"
                        ref={vrcodeRef}
                        onChange={ (value) => setVrcode(value) }
                    />
                </Grid>

                <Grid container item xs={12} justifyContent='center' mb={2}> 
                    {   countdown === true
                        ?<Countdown date={startDate.current + 22000}
                        
                            renderer={({ seconds }) => {
                                seconds === 0 && setCountdown(false);
                                return (
                                    <p className='countdown-wrap'>
                                        { t("forgotPassword.vertifyCodeCount") }
                                        <span style={{ color: "#f6b119" }}>
                                            {seconds}                                            
                                        </span>
                                    </p>
                                )
                            }} 
                        />
                        : <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                            <p className='countdown-wrap'>
                                { t("forgotPassword.resendCodeAgain") }
                            </p>
                            <Typography
                                component={'button'}
                                className='vrcode-resend'
                                style={{ cursor: 'pointer', color: "#f6b119" }}
                                onClick={handleResend}>
                                {t("forgotPassword.resend")}
                            </Typography>
                        </div>
                    }
                </Grid>
                
                <PrimaryButton
                    text={t("forgotPassword.submit")}
                    classBtn="primary"
                    style={{ padding: "5px 50px", margin: "auto" }}
                    click={handleSubmit}
                />
            </div>
        </Grid>
    </Grid>
  )
}

export default withTranslation() (VerificationCode)