import { Grid, Typography } from "@mui/material";
import ActivityTabs from "../../Molecules/Activities/ActivityTabs.jsx";
import ActivityPanal from "../../Molecules/Activities/ActivityPanel.jsx";
import { useState, useEffect } from "react";
import "./ActivitiesList.css";
import { withTranslation } from "react-i18next";
import { icons } from "../../../Assets/AssetHelper.js";
import BranchesFilter from "../Statistics/BranchesFilter.jsx";

const ActivitiesList = (props) => {
  const {
    t,
    activitesList,
    setActivitiesList,
    count,
    getActivities,
    activitiesPrivilages,
    setOpenLoader,
    filterObj,
    setFilterObj,
    updatePagination,
    page,
    setPage,
    profileData
  } = props;
  const [ActivityValue, setActivityValue] = useState(0);

  const getBranchType = (value) => {
    if (value === 0) {
      setPage(1);
      setFilterObj({ ...filterObj, page_number: 1, activity_type: "" });
    }
    if (value === 1) {
      setPage(1);
      setFilterObj({
        ...filterObj,
        page_number: 1,
        activity_type: "dive_trip",
      });
    }
    if (value === 2) {
      setPage(1);
      setFilterObj({
        ...filterObj,
        page_number: 1,
        activity_type: "dive_course",
      });
    }
    if (value === 3) {
      setPage(1);
      setFilterObj({
        ...filterObj,
        page_number: 1,
        activity_type: "live_aboard",
      });
    }
  };
  const getKeywords = (keyword) => {
    setFilterObj({ ...filterObj, keyword: keyword });
  };

  const ActivitiesTypes = [
    {
      id: 1,
      label: t("activity.all"),
    },
    {
      id: 2,
      label: t("activity.diveTrip"),
    },
    {
      id: 3,
      label: t("activity.courses"),
    },
    {
      id: 4,
      label: t("activity.liveaboard"),
    },
  ];
  const handleActivityValue = (event, newValue) => {
    setActivityValue(newValue);
  };
  return (
    <Grid container className="activities-list-container">
      <ActivityTabs
        getBranchType={getBranchType}
        getKeywords={getKeywords}
        options={ActivitiesTypes}
        handleActivityValue={handleActivityValue}
        ActivityValue={ActivityValue}
        activitiesNo={activitesList?.length}
        activitiesPrivilages={activitiesPrivilages}
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        profileData={profileData}
      />
      {activitesList?.length === 0 ? (
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "200px",
          }}
        >
          <img
            src={icons.NoActivities}
            alt="no-branches"
            style={{ display: "block", width: "101px", height: "105px" }}
          />
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: "bold",
              color: "#333132",
              mt: "25px",
            }}
          >
            {t("activity.noActivitesList")}
          </Typography>
        </Grid>
      ) : (
        <ActivityPanal
          options={ActivitiesTypes}
          ActivityValue={ActivityValue}
          activitesList={activitesList}
          setActivitiesList={setActivitiesList}
          count={count}
          page={page}
          setPage={setPage}
          updatePagination={updatePagination}
          activitiesPrivilages={activitiesPrivilages}
          getActivities={getActivities}
          filterObj={filterObj}
          setOpenLoader={setOpenLoader}
        />
      )}
    </Grid>
  );
};

export default withTranslation()(ActivitiesList);
