import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../../Components/Atoms/Loader/Loader';
import { getFreelancerDetails } from '../../../Contexts/APIs/Freelancers/Freelancers';
import { getJobOffersList, showJobOffer } from '../../../Contexts/APIs/Job-Offers/JobOffers';
import FreelancerJopOffersHistoryTemplate from '../../../Templates/Main/Freelancers/FreelancerJopOffersHistory.Template'

const FreelancerJopOfferHistoryPage = () => {
    const { jop_offer_id, applicant_id } = useParams();
    const [jobOfferData, setJobOfferData] = useState({});
    const [applicantData, setApplicantData] = useState({});
    const [offers, setOffers] = useState([])
    const [openLoader, setOpenLoader] = useState(false);
    const [count, setCount] = useState(0)
    const [filterObj, setFilterObj] = useState(null)

    const getJobOfferData = async () => {
        setOpenLoader(true);
        const { res, err } = await showJobOffer(jop_offer_id);
        if (res) setJobOfferData(res?.data?.job_offer);
        if (err) toast.error(err)
        setOpenLoader(false);
    };
    const handleChange = (name, value) => setFilterObj({ ...filterObj, [name]: value });


    const getApplicantDetails = async () => {
        setOpenLoader(true);
        const { res, err } = await getFreelancerDetails(applicant_id);
        if (res) setApplicantData(res?.data?.user);
        if (err) toast.error(err)
        setOpenLoader(false);
    };


    const getJobOffers = async (filter) => {
        setOpenLoader(true)
        const { res, err } = await getJobOffersList({ ...filterObj, dive_expert_id: applicantData?.id, status: "completed" })
        if (res) {
            setCount(res?.extra?.total_count);

            setOffers(res?.data?.job_offers);
        }
        if (err) toast.error(err)
        setOpenLoader(false)
    }

    useEffect(() => {
        getJobOfferData()
        getApplicantDetails()
    }, []);

    useEffect(() => {
        if (applicantData?.id) getJobOffers()
    }, [applicantData?.id, filterObj])
    return (
        <>
            <Loader open={openLoader} />

            <FreelancerJopOffersHistoryTemplate
                jobOfferData={jobOfferData}
                applicantData={applicantData}
                offers={offers}
                count={count}
                handleChange={handleChange} />
        </>
    )
}

export default FreelancerJopOfferHistoryPage