import * as Yup from 'yup';
import { getBranchTranslations } from '../../../../../Contexts/APIs/Lookups/Help';

const profile = getBranchTranslations("profile");
const branch = getBranchTranslations("branch");
const users = getBranchTranslations("users");
const reservations = getBranchTranslations("reservations");

export const AccountSchema = Yup.object().shape({
    /*phone_number: Yup.string(profileEn.requiredData).required(profileEn.requiredData),
    country_code: Yup.string(profileEn.requiredData).required(profileEn.requiredData),
    primary_language_id: Yup.object().shape({
        label: Yup.string().required(profileEn.requiredData),
        value: Yup.string().required(profileEn.requiredData),
    }),
    main_office_address: Yup.string(profileEn.requiredData).required(profileEn.requiredData),
    owner_email: Yup.string(profileEn.requiredData).required(profileEn.requiredData),
    owner_name: Yup.string(profileEn.requiredData).required(profileEn.requiredData),
    owner_country_code: Yup.string(profileEn.requiredData).required(profileEn.requiredData),
    owner_phone_number: Yup.string(profileEn.requiredData).required(profileEn.requiredData),
    facebook: Yup.string('').nullable(),
    website: Yup.string('').nullable(),
    contact_person_name: Yup.string(profileEn.requiredData).required(profileEn.requiredData),
    contact_person_email: Yup.string(profileEn.requiredData).required(profileEn.requiredData),
    contact_person_phone_number: Yup.string(profileEn.requiredData).required(profileEn.requiredData),
    en: Yup.object().shape({

        about_us: Yup.string(profileEn.requiredData).required(profileEn.requiredData)
    }),*/
    
    phone_number: Yup.number().required(`${reservations.phoneNumber} ${branch.isARequiredField}`),
    country_code: Yup.string().required(users.countryCodeRequired),
    primary_language_id: Yup.object().shape({
        label: Yup.string().required(`${profile.primaryLanguage} ${branch.isARequiredField}`),
        value: Yup.string().required(`${profile.primaryLanguage} ${branch.isARequiredField}`),
    }),
    main_office_address: Yup.string().required(`${profile.mainofficeaddress} ${branch.isARequiredField}`),
    owner_email: Yup.string().required(`${profile.ownerEmail} ${branch.isARequiredField}`),
    owner_name: Yup.string().required(`${profile.ownerName} ${branch.isARequiredField}`),
    owner_country_code: Yup.string().required(`${profile.ownerCountryCode} ${branch.isARequiredField}`),
    owner_phone_number: Yup.string().required(`${profile.ownerPhoneNumber} ${branch.isARequiredField}`),
    facebook: Yup.string('').nullable(),
    website: Yup.string('').nullable(),
    contact_person_name: Yup.string().required(`${profile.contactpersonname} ${branch.isARequiredField}`),
    contact_person_email: Yup.string().required(`${profile.contactpersonemail} ${branch.isARequiredField}`),
    contact_person_phone_number: Yup.string().required(`${profile.contactPersonPhoneNumber} ${branch.isARequiredField}`),
    en: Yup.object().shape({
        about_us: Yup.string().required(`${profile.aboutOperator} ${branch.isARequiredField}`)
    }),

    
    // bank_account_attributes: Yup.object().shape({
    //     beneficiary_name: Yup.string('Required Data'),
    //     beneficiary_address: Yup.string('Required Data'),
    //     iban: Yup.string('Required Data'),
    //     swift_code: Yup.string('Required Data'),
    //     country_id: Yup.number('Required Data'),
    //     city_id: Yup.number('Required Data'),
    //     currency_id: Yup.number('Required Data'),
    //     beneficiary_account_number: Yup.string('Required Data'),
    //     bank_name: Yup.string('Required Data'),
    //     bank_address: Yup.string('Required Data')
    // })
})


