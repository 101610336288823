import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";
import { makeFilterString } from "../Helper/Helper.js";

export async function getOffersList(filter_obj) {
    return await axiosFn("get", URL.OFFERS + makeFilterString(filter_obj))
        .then((res) => {            
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError); 
}


export async function createOffer(data, action) {
    return await axiosFn("post", URL.OFFERS, data)
        .then((res) => {
            action && action()

            return {
                res: res.data,
                err: null,
            };

        })
        .catch(handleError);
}

export async function editOffer(id , data , action) {
    return await axiosFn("put", `${URL.OFFERS}/${id}`,data)
        .then((res) => {
            action && action()
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}


export async function deleteOffer(id) {
    return await axiosFn("delete", `${URL.OFFERS}/${id}`)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError); 
}

export async function showOffer(id) {
    return await axiosFn("get", `${URL.OFFERS}/${id}`)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}