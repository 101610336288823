import React from 'react'
import UserFormWrap from '../../../Components/Organisms/Users/UserFormWrap'

function UserFormTemplate(props) {
  const { formik, openLoader, rolesData, validationMethod, userImage, uploadUserImages, removeUserImage, formType, branchesList, getBranches ,branchesCount,profileData} = props;
  return (
    <UserFormWrap
      formik={formik}
      openLoader={openLoader}
      rolesData={rolesData}
      validationMethod={validationMethod}
      userImage={userImage}
      uploadUserImages={uploadUserImages}
      removeUserImage={removeUserImage}
      formType={formType}
      branchesList={branchesList}
      getBranches={getBranches}
      branchesCount={branchesCount}
      profileData={profileData}
    />
  )
}

export default UserFormTemplate