import React from "react";
import Information from "../../../Molecules/Salesmen/Form/Information";
import { Box, Grid, Typography } from "@mui/material";
import FormHeadr from "../../../Molecules/Salesmen/Form/FormHeadr";
import UploadImagesForm from "../../../Molecules/Forms/UploadImagesForm";

import { withTranslation } from "react-i18next";
import AddForm from "../../../Molecules/Salesmen/Form/AddForm";
import ChangePassword from "../../../Molecules/Salesmen/Form/ChangePassword";
import CustomSeparator from "../../../Atoms/BreadCrumb";
import { Link, useSearchParams } from "react-router-dom";

const SalesmanForm = ({
  formik,
  handleSubmit,
  type,
  privilages,
  image,
  uploadImages,
  removeImage,
  getBranches,
  branchesList,
  branchesCount,
  profileData,
  salesmanData,
  t,
}) => {
  let [searchParams, setSearchParams] = useSearchParams();
  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 2 }}>
      <CustomSeparator
        breadcrumbs={[
          <Link
            style={{ color: "#bebebe", textDecoration: "none" }}
            underline="hover"
            key="1"
            color="inherit"
            to={`/salesmen?page=${pageNumber}`}
          >
            {t("salesmen.salesmen")}
          </Link>,
          <Link
            style={{ color: "#bebebe", textDecoration: "none" }}
            underline="hover"
            key="2"
            color="inherit"
            to={`/salesmen/salesmen-list?page=${pageNumber}`}
          >
            {t("salesmen.salesmenList")}
          </Link>,
          <Typography
            key="3"
            style={{ color: "#bebebe", textDecoration: "none" }}
          >
            {type === "add"
              ? t("salesmen.addNew")
              : type === "edit"
                ? t("salesmen.editSalesman")
                : type === "edit-password"
                  ? t("salesmen.changePassword")
                  : null}
          </Typography>,
        ]}
      />
      <FormHeadr
        type={type}
        handleSubmit={handleSubmit}
        privilages={privilages}
        handleReset={formik.handleReset}
      />
      {type === "edit-password" ? (
        <Grid item sm={12} lg={6}>
          <ChangePassword formik={formik} />
        </Grid>
      ) : type === "edit" || type === "add" ? (
        <Grid
          container
          item
          sm={12}
          pl={"0px !important"}
          justifyContent={"space-between"}
        >
          <Grid item md={6.8}>
            {type === "add" ? (
              <AddForm
                formik={formik}
                getBranches={getBranches}
                branchesList={branchesList}
                branchesCount={branchesCount}
                profileData={profileData}
                formType={type}
                salesmanData={salesmanData}
              />
            ) : type === "edit" ? (
              <Information
                formik={formik}
                getBranches={getBranches}
                branchesList={branchesList}
                branchesCount={branchesCount}
                profileData={profileData}
                formType={type}
              />
            ) : null}
          </Grid>
          <Grid item md={4.8}>
            <Box className="update-profile-img-box">
              <UploadImagesForm
                type="single"
                uploadImages={uploadImages}
                formTitle={t("salesmen.image")}
                Images={image}
                RemoveImage={removeImage}
              />
            </Box>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default withTranslation()(SalesmanForm);
