export const BASE_URL = process.env.REACT_APP_API_URL;
export const auth = "auth/";
export const login = "/login";
export const scanQr = "scan_qr";
export const dive_centers = "dive_centers";
export const activities = "activities";
export const generate_presigned_url = "/generate_presigned_url";
export const activity_groups = "activity_groups/";
export const reservations = "/reservations";

export const group_reservations = "/group_reservations";
export const update_payment = "/update_payment";
export const orders = "orders/";
export const users = "users/";
export const create_order = "/create_order/";
export const FREELANCERS = "dive_experts/mutuals";
export const FREELANCER = "dive_experts";

export const JOB_OFFERS = "job_offers";
export const OFFERS = "activity_offers";
export const POINTS = "/point_actions";

export const PointsConfig = "/point_configs";

export const Salesmen = "salesmen";

export const branches = "branches";
export const organizations = "/organizations";
export const lookups = "lookups";
export const faqs = "/faqs";
export const countries = "/countries";
export const cities = "/cities";
export const areas = "/areas";
export const feature_options = "/feature_options";
export const get_supported_languages = "/get_supported_languages";
export const currencies = "/currencies";
export const organization_certificates = "/organization_certificates";
export const languages = "/languages";
export const price_includes = "/price_includes";
export const payment_methods = "lookups/payment_methods/";
export const reviews = "/reviews";
export const getGroupReservations =
  "activities/dashboard/search_dive_center_reservation";
export const notifications = "/notifications";
export const FCM = "/auth/set_device_fcm";
export const Decendent = "descendant_users/";
export const firebase_auth_token = "/auth/tokens/firebase";


export const Roles = "lookups/roles";
export const Descendant_users = "/descendant_users";
export const SelectUserSuper = "lookups/users";
export const Inhouse_activities = "inhouse_activities";
export const Salesman_reservation = "/salesman_reservation/";
export const dive_expert_requests = "dive_expert_activity_requests";
export const dive_expert_list = "dive_experts/search_contracted";

export const SalesmanStatistics = "salesman_statistics";
export const DiveCenterStatistics = "order_statistics";
export const JobOfferStatistics = "job_offer_statistics";
