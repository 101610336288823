import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { icons } from "../../../Assets/AssetHelper";

const AccountBankDetailsTemplate = ({ t, newDiveCenterDetails, setOpen,openModal,setOpenModal }) => {
  return (
    <Container maxWidth="XL">
      <Stack flexDirection="row" alignItems="center" gap={1} mb={2}>
        <NavLink to="/profile" style={{ textDecoration: "none" }}>
          <Typography fontSize="18px" color="#bebebe">
            {t("profile.profile")} {">"}
          </Typography>
        </NavLink>
        <Typography fontSize="18px" color="#bebebe">
          {t("profile.bankAccountDetails")}
        </Typography>
      </Stack>
      <Grid container className="activity-form" flexDirection="column" gap={2}>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            fontSize="30px"
            fontWeight="bold"
            textTransform="capitalize"
          >
            {newDiveCenterDetails?.bank_name}
          </Typography>
          <img
            src={icons.EditIcon}
            width="42px"
            height="42px"
            onClick={() => setOpen(true)}
            style={{ cursor: "pointer" }}
          />
        </Stack>

        <Stack bgcolor="#fbfbfb" borderRadius="20px" p={2} gap={2}>
          <Typography
            component="h1"
            style={{
              width: "fit-content",
              fontSize: "20px",
              fontWeight: "bold",
              borderBottom: "2px solid var(--primary-color)",
            }}
            className="mb-20px"
          >
            {t("profile.accountInformation")}
          </Typography>
          <Stack flexDirection="row" alignItems="center">
            <Box my={1} width="25%">
              <Typography color="#aaaaaa" fontSize="13px">
                {t("profile.payoutCurrency")}
              </Typography>
              <Typography fontSize="18px" fontWeight="bold">
                {newDiveCenterDetails?.currency?.name}
              </Typography>
            </Box>
            <Box my={1} width="25%">
              <Typography color="#aaaaaa" fontSize="13px">
                {t("profile.payoutCountry")}
              </Typography>
              <Typography fontSize="18px" fontWeight="bold">
                {newDiveCenterDetails?.country?.name}
              </Typography>
            </Box>
            <Box my={1} width="25%">
              <Typography color="#aaaaaa" fontSize="13px">
                {t("profile.payoutCity")}
              </Typography>
              <Typography fontSize="18px" fontWeight="bold">
                {newDiveCenterDetails?.city?.name}
              </Typography>
            </Box>
          </Stack>
          <Stack flexDirection="row" alignItems="center">
            <Box my={1} width="25%">
              <Typography color="#aaaaaa" fontSize="13px">
                {t("profile.bankName")}
              </Typography>
              <Typography fontSize="18px" fontWeight="bold">
                {newDiveCenterDetails?.bank_name}
              </Typography>
            </Box>

            <Box my={1} width="25%">
              <Typography color="#aaaaaa" fontSize="13px">
                {t("profile.bankAddress")}
              </Typography>
              <Typography fontSize="18px" fontWeight="bold">
                {newDiveCenterDetails?.bank_address}
              </Typography>
            </Box>
            <Box my={1} width="25%">
              <Typography color="#aaaaaa" fontSize="13px">
                {t("profile.swiftCode")}
              </Typography>
              <Typography fontSize="18px" fontWeight="bold">
                {newDiveCenterDetails?.swift_code}
              </Typography>
            </Box>
            <Box my={1} width="25%">
              <Typography color="#aaaaaa" fontSize="13px">
                {t("profile.IBAN")}
              </Typography>
              <Typography fontSize="18px" fontWeight="bold">
                {newDiveCenterDetails?.iban}
              </Typography>
            </Box>
          </Stack>
          <Stack flexDirection="row" alignItems="center">
            <Box my={1} width="25%">
              <Typography color="#aaaaaa" fontSize="13px">
                {t("profile.beneficiaryAccountNumber")}
              </Typography>
              <Typography fontSize="18px" fontWeight="bold">
                {newDiveCenterDetails?.beneficiary_account_number}
              </Typography>
            </Box>
            <Box my={1} width="25%">
              <Typography color="#aaaaaa" fontSize="13px">
                {t("profile.beneficiaryName")}
              </Typography>
              <Typography fontSize="18px" fontWeight="bold">
                {newDiveCenterDetails?.beneficiary_name}
              </Typography>
            </Box>
            <Box my={1} width="25%">
              <Typography color="#aaaaaa" fontSize="13px">
                {t("profile.beneficiaryAddress")}
              </Typography>
              <Typography fontSize="18px" fontWeight="bold">
                {newDiveCenterDetails?.beneficiary_address}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Grid>
    </Container>
  );
};

export default withTranslation()(AccountBankDetailsTemplate);
