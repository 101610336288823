import { Grid, Typography } from "@mui/material";
import BranchesTabs from "../../Molecules/Branches/BranchesTabs.jsx";
import AddBranchPanel from "../../Molecules/Branches/AddBranchPanel.jsx";
import { useState, useRef, useEffect } from "react";
import "./Branches.css";
import { useSearchParams } from "react-router-dom";
import { withTranslation } from "react-i18next";

const AddBranchForm = (props) => {
  const {
    t,
    type,
    uploadImages,
    organizationsList,
    createBranch,
    editBranch,
    branchImages,
    RemoveBranchImage,
    uploadLocalLicense,
    uploadLicense,
    localeLicenseImg,
    licenseImg,
    getFeaturesOptions,
    featureOptions,
    supportedLanguages,
    branchDetails,
    formType,
    getOrganization,
    organizationsCount,
    RemoveLocaleLicenseImg,
    licensesData,
    getbranchDetails,
    id
  } = props;
  const [BranchValue, setBranchValue] = useState(0);
  const handleFormSubmit = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const Types = [
    {
      id: 1,
      label: t("branch.diveCenter"),
      disabled:
        formType == "edit" && searchParams.get("branchType") == "safari_boat"
          ? true
          : false,
    },
    {
      id: 2,
      label: t("branch.safariBoat"),
      disabled:
        formType == "edit" && searchParams.get("branchType") != "safari_boat"
          ? true
          : false,
    },
  ];
  const handleBranchValue = (newValue) => {
    setBranchValue(newValue);
  };
  useEffect(() => {
    formType == "edit"
      ? searchParams.get("branchType") == "safari_boat"
        ? setBranchValue(1)
        : setBranchValue(0)
      : setBranchValue(0);
  }, [type]);
  return (
    <Grid container className="add-activity-container">
      <BranchesTabs
        options={Types}
        handleBranchValue={handleBranchValue}
        BranchValue={BranchValue}
        type={type}
        formType={formType}
        handleSubmit={() => handleFormSubmit.current()}
      />
      <AddBranchPanel
        getOrganization={getOrganization}
        options={Types}
        formType={formType}
        BranchValue={BranchValue}
        uploadImages={uploadImages}
        handleFormSubmit={handleFormSubmit}
        organizationsList={organizationsList}
        organizationsCount={organizationsCount}
        createBranch={createBranch}
        editBranch={editBranch}
        branchImages={branchImages}
        RemoveBranchImage={RemoveBranchImage}
        uploadLocalLicense={uploadLocalLicense}
        uploadLicense={uploadLicense}
        localeLicenseImg={localeLicenseImg}
        licenseImg={licenseImg}
        getFeaturesOptions={getFeaturesOptions}
        featureOptions={featureOptions}
        supportedLanguages={supportedLanguages}
        branchDetails={branchDetails}
        RemoveLocaleLicenseImg={RemoveLocaleLicenseImg}
        licensesData={licensesData}
        type={type}
        getbranchDetails={getbranchDetails}
        id={id}
      />
    </Grid>
  );
};

export default withTranslation()(AddBranchForm);
