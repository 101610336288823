import { Grid, Typography, Divider, Stack } from "@mui/material";
import SelectBox from "../../Atoms/Select/Select.jsx";
import { ActivityTypes } from "../../../Contexts/APIs/Helper/Helper.js";
import { useEffect, useState } from "react";
import TextFieldInput from "../../Atoms/Input/TextField/TextFieldInput.jsx";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getGroupReservations } from "../../../Contexts/APIs/Reservations/Reservations.js";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import ScanQrcodeModel from "../JobOffers/Models/qrCode-scan.jsx";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import { ScanQrCode } from "../../../Contexts/APIs/General/scan-code.js";
import NoOrdersModel from "./Models/NoOrdersFound.jsx";
import { withTranslation } from "react-i18next";
import SearchSelect from "../../Atoms/Select/SearchSelect.jsx";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import BranchesFilter from "../../Organisms/Statistics/BranchesFilter.jsx";

const ReservationHeader = (props) => {
  const {
    updateActivityList,
    branchesList,
    t,
    getBranches,
    branchesCount,
    setReservationsData,
    getGroupReservationsList,
    filterObj,
    setFilterObj,
    profileData
  } = props;
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");
  const [open, setOpen] = useState(false);
  const [openNoOrders, setOpenNoOrders] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);
  let [searchParams, setSearchParams] = useSearchParams();
  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  const updateActivities = (name, value) => {
    updateActivityList(name, value);
  };
  const handelSearchResult = async () => {
    getGroupReservationsList({ ...filterObj, reservation_number: keyword });
    //   const { res, err } = await getGroupReservations({
    //     reservation_number: keyword,
    //   });
    //   if (res) {
    //     if (res?.data?.activity?.id != null) {
    //       // navigate(
    //       //   `/activities/activity-groups/reservations-list/${res?.data?.activity_group?.id}?reservation_number=${keyword}&page=${pageNumber}`
    //       // );
    //     } else {
    //       setOpenNoOrders(true);
    //     }
    //   }
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      handelSearchResult();
    }, 1000);

    // Cleanup the timeout if the component unmounts or the keyword changes
    return () => clearTimeout(timer);
  }, [keyword]);

  const handelScanCode = (code) => {
    const action = () => {
      setOpen(false);
      window.location.reload();
    };
    ScanQrCode({ qr_code: code }, action);
  };

  return (
    <Grid container>
      <Stack width="100%" justifyContent="space-between" flexDirection="row">
        <Stack width="160px">
          <Typography
            component="h1"
            style={{ fontWeight: "bold", fontSize: "30px" }}
          >
            {t("reservations.activitiesReservations")}
          </Typography>
        </Stack>
        <Stack
          flexDirection="row"
          gap={1}
          maxWidth={"calc(100% - 170px)"}
          alignItems={"center"}
          flexWrap={"wrap"}
        >
          <form onSubmit={handelSearchResult} style={{ width: "300px" }}>
            <TextFieldInput
              type="search"
              placeholder={t("reservations.searchReservation")}
              StartAdornment={<SearchIcon />}
              className="joboffers-search"
              style={{ width: "300px" }}
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
          </form>

          <SelectBox
            style={{ width: "180px" }}
            id="activities"
            name="activities"
            items={[
              { name: t("reservations.all"), id: "All" },
              ...ActivityTypes,
            ]}
            title={t("joboffer.allActivites")}
            className="select"
            onchange={(e) => updateActivities("activity_type", e.target.value)}
          />

          <Grid item style={{ width: "230px" }}>
            <BranchesFilter
              setFilterObj={setFilterObj}
              filterObj={filterObj}
              profileData={profileData}
            />
          </Grid>
          <PrimaryButton
            fontSize="13px"
            endIcon={<QrCodeScannerIcon />}
            text={t("reservations.scanQRCustomer")}
            style={{ padding: "8px 10px", height: "48px" }}
            classBtn="primary r-25px"
            click={() => setOpen(true)}
          />
          {open && (
            <ScanQrcodeModel
              open={open}
              setOpen={setOpen}
              handelScanCode={handelScanCode}
            />
          )}

          {openNoOrders && (
            <NoOrdersModel open={openNoOrders} setOpen={setOpenNoOrders} />
          )}
        </Stack>
      </Stack>
      <Divider style={{ width: "100%", margin: "20px 0" }} />
    </Grid>
  );
};

export default withTranslation()(ReservationHeader);
