import { Grid, Typography } from "@mui/material";
import AddReservationsHeader from "../../Molecules/Reservations/AddReservationsHeader.jsx";
import AddReservationForm from "../../Molecules/Reservations/Forms/AddReservationForm.jsx";
import { useState } from "react";

const ReservationForm = (props) => {
    const { searchUsers, usersList, activityGroups, createReservation } = props;
    const [numberOfSeats, setNumbersOfSeats] = useState("");
    const [groupId, setGroupId] = useState("");

    const updateNumbersOfSeats = (seats) => {
        setNumbersOfSeats(seats);
    };
    const updateGroupId = (activity_group_id) => {
        setGroupId(activity_group_id);
    };

    return (
        <Grid container className="reservations-container">
            <AddReservationsHeader
                activityGroups={activityGroups}
                updateNumbersOfSeats={updateNumbersOfSeats}
                updateGroupId={updateGroupId}
            />
            <AddReservationForm
                searchUsers={searchUsers}
                usersList={usersList}
                numberOfSeats={numberOfSeats}
                groupId={groupId}
                createReservation={createReservation}
            />
        </Grid>
    )
}

export default ReservationForm