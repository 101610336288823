import { Grid } from "@mui/material";
import React from "react";
import PagesHead from "../../Molecules/Users/PagesHead";
import SearchIcon from "@mui/icons-material/Search";
import UsersList from "../../Molecules/Users/UsersList/UsersList";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../../Atoms/Loader/Loader";
import { withTranslation } from "react-i18next";
import BranchesFilter from "../Statistics/BranchesFilter";

const Users = (props) => {
  const {
    t,
    filterObj,
    setFilterObj,
    usersList,
    setUsersList,
    openLoader,
    setOpenLoader,
    count,
    page,
    handlePageChange,
    usersPrivilages,
    profileData
  } = props;
  const navigate = useNavigate();
  const userType = localStorage.getItem("userType");
  let [searchParams, setSearchParams] = useSearchParams();

  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  return (
    <>
      <Loader open={openLoader} />
      <Grid container>
        <PagesHead
          title="Users"
          searchInput={{
            type: "search",
            placeholder: `${t("users.searchPlaceholder")}`,
            StartAdornment: { SearchIcon },
            className: "Search-users",
          }}
          primaryButtons={
            userType !== "descendant_user" ||
              (userType === "descendant_user" && usersPrivilages?.can_create)
              ? [
                {
                  fontSize: "14px",
                  text: `${t("users.rolesDetails")}`,
                  classBtn: "primary r-25px primary-outlined",
                  style: {
                    padding: "5px 20px",
                    height: "48px",
                    marginRight: "1rem",
                  },
                  click: () => navigate(`/users/roles?page=${pageNumber}`),
                },
                {
                  fontSize: "14px",
                  startIcon: true,
                  text: `${t("users.addNewUser")}`,
                  classBtn: "primary r-25px",
                  style: { padding: "5px 20px", height: "48px" },
                  click: () => navigate(`/users/add?page=${pageNumber}`),
                },
              ]
              : [
                {
                  fontSize: "14px",
                  text: `${t("users.rolesDetails")}`,
                  classBtn: "primary r-25px primary-outlined",
                  style: {
                    padding: "5px 20px",
                    height: "48px",
                    marginRight: "1rem",
                  },
                  click: () => navigate(`/users/roles?page=${pageNumber}`),
                },
              ]
          }
          filterObj={filterObj}
          setFilterObj={setFilterObj}
          filter={<BranchesFilter setFilterObj={setFilterObj} filterObj={filterObj} profileData={profileData} />}
        />
        <UsersList
          usersList={usersList}
          setUsersList={setUsersList}
          setOpenLoader={setOpenLoader}
          count={count}
          page={page}
          handlePageChange={handlePageChange}
          usersPrivilages={usersPrivilages}
        />
      </Grid>
    </>
  );
};

export default withTranslation()(Users);
