import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";
import { makeFilterString } from "../Helper/Helper.js";

export async function getFreelancersList(filter_obj) {
  return await axiosFn("get", URL.FREELANCERS + makeFilterString(filter_obj))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function getFreelancerDetails(dive_expert_id) {
  return await axiosFn("get", `/profile/dive_expert/${dive_expert_id}/show`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function deleteFreelancer(data, action, setLoading) {
  setLoading(true);
  return await axiosFn("post", `/black_lists`, data)
    .then((res) => {
      action && action();
      setLoading(false);
      return {
        res: res.data,
        err: null,
      };
    })
    .catch((err) => {
      setLoading(false);
      handleError(err);
    });
}

export async function addToFavoriate(data, action, setLoading) {
  setLoading(true);

  return await axiosFn("post", `/favourites`, data)
    .then((res) => {
      action && action();
      setLoading(false);
      return {
        res: res.data,
        err: null,
      };
    })
    .catch((err) => {
      setLoading(false);
      handleError(err);
    });
}

export async function deleteFavoriate(data, action, setLoading) {
  setLoading(true);

  return await axiosFn("delete", `/favourites`, data)
    .then((res) => {
      action && action();
      setLoading(false);
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
