import { Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import {
  getDiveCenterStatistics,
  getjobOfferStatistics,
} from "../../../Contexts/APIs/Statistics/statistics";
import Charts from "../../Atoms/SharedComponents/Charts";
import NormalTabs from "../../Atoms/Tabs/NormalTabs";
import BranchesFilter from "./BranchesFilter";
import "./DiveCenterStatistics.css";
import DiveCenterStatisticsFilters from "./DiveCenterStatisticsFilters";
import dayjs from "dayjs";

const DiveCenterStatistics = ({ t,profileData}) => {
  const [allData, setAllData] = useState(null);
  const [data, setData] = useState([]);
  const [ticks, setTicks] = useState([]);
  const [selectedTabId, setSelectedTabId] = useState(0);
  const [filterObj, setFilterObj] = useState({
    interval_type: "month",
    keyword: "",
  });
  const [totals, setTotals] = useState({
    totalEarnings: 0,
    totalReservations: 0,
    total_job_offers_count: 0,
  });
  const getYAixData = (value) => {
    let yAxisKey = "";
    let graphPointKey = "";
    switch (value) {
      case 0:
        yAxisKey = "reservations_y_axis";
        graphPointKey = "reservations_count";
        break;
      case 1:
        yAxisKey = "earnings_y_axis";
        graphPointKey = "earnings_amount";
        break;
      case 2:
        yAxisKey = "job_offers_y_axis";
        graphPointKey = "job_offers_count";
        break;
      default:
        yAxisKey = "reservations_y_axis";
        graphPointKey = "reservations_count";
    }
    return { yAxisKey, graphPointKey };
  };
  // get statistics data
  const getStatistics = async (filterObj) => {
    const { res, err } = await getDiveCenterStatistics({ ...filterObj });
    if (res) {
      setAllData(res.data.order_statistics);
      setTotals({
        totalEarnings: res.data.order_statistics.total_earnings_amount,
        totalReservations: res.data.order_statistics.total_reservations,
      });
    }
  };
  // change the data when tab change
  const updateDataForSelectedTab = (selectedTabId) => {
    if (!allData) return;
    const { yAxisKey, graphPointKey } = getYAixData(selectedTabId);
    const x_axis = allData.x_axis;
    const y_axis = allData[yAxisKey];
    const graph_points = allData.graph_points;
    const formattedData = x_axis?.map((date, index) => ({
      name: dayjs(date).format("DD-MM"),
      "No of booking": graph_points?.[date]?.[graphPointKey] || 0,
      y_axis: y_axis?.[index] || 0,
    }));
    setData(formattedData);
    setTicks(y_axis || []);
  };
  const getJobOffer = async (filterObj) => {
    const { res, err } = await getjobOfferStatistics(filterObj);
    if (res) {
      setAllData(res.data.salesman_statistics);
      setTotals({
        total_job_offers_count:
          res.data.salesman_statistics.total_job_offers_count,
      });
    }
  };
  useEffect(() => {
    selectedTabId !== 2 && getStatistics(filterObj);
    selectedTabId == 2 && getJobOffer(filterObj);
  }, [filterObj, selectedTabId]);

  useEffect(() => {
    updateDataForSelectedTab(selectedTabId);
  }, [allData, selectedTabId]);

  const options = [
    {
      id: 0,
      label: t("statistics.bookings"),
    },
    {
      id: 1,
      label: t("statistics.earnings"),
    },
    {
      id: 2,
      label: t("statistics.requestedFreelancer"),
    },
  ];
  const handleChange = async (event, newValue) => {
    setSelectedTabId(newValue);
    if (newValue === 2) {
      setFilterObj({ interval_type: "month" });
      getJobOffer(filterObj)
    }
  };
  const getSelectedTabLabel = () => {
    const selectedTab = options.find((option) => option.id === selectedTabId);
    return selectedTab ? selectedTab.label : "";
  };
  const getTotalForSelectedTab = () => {
    switch (selectedTabId) {
      case 0:
        return `${totals.totalReservations} ${t("statistics.bookings")}`;
      case 1:
        return `${totals.totalEarnings}`;
      case 2:
        return `${totals.total_job_offers_count} ${t("statistics.requests")}`;
      default:
        return "";
    }
  };
  const getTitleForSelectedTab = () => {
    switch (selectedTabId) {
      case 0:
        return t('statistics.totalNoOfBookings');
      case 1:
        return t('statistics.totalEarnings');
      case 2:
        return t('statistics.totalNoOfRequestedFreelancer');
      default:
        return "";
    }
  };
  const activityTypeOptions = [
    {
      id: 1,
      label: t("activity.all"),
    },
    {
      id: 2,
      label: t("activity.diveTrip"),
    },
    {
      id: 3,
      label: t("activity.courses"),
    },
    {
      id: 4,
      label: t("activity.liveaboard"),
    },
  ];
  const getActivityType = (value) => {
    if (value === 0) {
      setFilterObj({ ...filterObj, activity_type: "" });
    }
    if (value === 1) {
      setFilterObj({
        ...filterObj,
        activity_type: "dive_trip",
      });
    }
    if (value === 2) {
      setFilterObj({
        ...filterObj,
        activity_type: "dive_course",
      });
    }
    if (value === 3) {
      setFilterObj({
        ...filterObj,
        activity_type: "live_aboard",
      });
    }
  };
  const activityOwnerOptions = [
    {
      id: 1,
      label: t("activity.all"),
    },
    {
      id: 2,
      label: t("activity.activity"),
    },
    {
      id: 3,
      label: t("reservations.privateGuide"),
    },
  ];
  const getActivityOwnerType = (value) => {
    if (value === 0) {
      setFilterObj({ ...filterObj, activity_owner_type: "" });
    }
    if (value === 1) {
      setFilterObj({
        ...filterObj,
        activity_owner_type: "DiveCenter",
      });
    }
    if (value === 2) {
      setFilterObj({
        ...filterObj,
        activity_owner_type: "DiveExpert",
      });
    }
  };
  const intervalTypeOptions = [
    {
      id: 0,
      label: t("statistics.monthly"),
    },
    {
      id: 1,
      label: t("statistics.daily"),
    },
  ];
  const getIntervalType = (value) => {
    if (value === 0) {
      setFilterObj({
        ...filterObj,
        interval_type: "month",
      });
    }
    if (value === 1) {
      setFilterObj({
        ...filterObj,
        interval_type: "day",
      });
    }
  };
  return (
    <Stack>
      <Typography
        component="h1"
        style={{ fontWeight: "bold", fontSize: "30px", marginBottom: "10px" }}
      >
        {t("statistics.statistics")}
      </Typography>
      {/* Bookings, Earnings, Requested freelancer Tabs */}
      <Stack sx={{ mb: "40px" }}>
        <NormalTabs
          TabsOptions={options}
          value={selectedTabId}
          change={handleChange}
          ContainerStyle="dive-center-tabs-container"
          TabStyle="dive-center-tabs"
          onclick={(_) => console.log()}
        />
      </Stack>
      <Grid container justifyContent={"space-between"}>
        {/* Branches Filter */}
        <Grid sx={{ mb: "20px" }} className="border-r-10px">
          <BranchesFilter setFilterObj={setFilterObj} filterObj={filterObj} profileData={profileData} hideOptionAll={true} />
        </Grid>
        {/* Activity Owner Tabs */}
        {getSelectedTabLabel() !== t("statistics.requestedFreelancer") && (
          <Grid sx={{ mb: "20px" }} className="border-r-10px">
            <DiveCenterStatisticsFilters
              getType={getActivityOwnerType}
              options={activityOwnerOptions}
            />
          </Grid>
        )}
        {/* Activity Type Tabs */}
        <Grid sx={{ mb: "20px" }} className="border-r-10px">
          <DiveCenterStatisticsFilters
            getType={getActivityType}
            options={activityTypeOptions}
          />
        </Grid>
        {/* Interval Tabs */}
        <Grid sx={{ mb: "20px" }} className="border-r-10px">
          <DiveCenterStatisticsFilters
            getType={getIntervalType}
            options={intervalTypeOptions}
          />
        </Grid>
      </Grid>
      {/* Charts */}
      <Charts
        data={data}
        ticks={ticks}
        total={`${getTotalForSelectedTab()} ${allData?.currency} `}
        title={getTitleForSelectedTab()}
        chartBarDataKey="No of booking"
      />
    </Stack>
  );
};

export default withTranslation()(DiveCenterStatistics);
