
import * as yup from "yup";
import { getBranchTranslations } from "../../../../Contexts/APIs/Lookups/Help";

const branch = getBranchTranslations("branch");

export const SafariBoatValidationSchema = yup.object({
    manager_name: yup.string().required(branch.managerNameReq),
    email: yup.string().email().required(branch.emailReq),
    branch_number: yup.number().required(branch.numberReq),
    insurance_name: yup.string(),
    policy_number: yup.string(),
    // license_number: yup.string(),
    boat_length: yup.number().required(branch.boatLengthReq),
    boat_width: yup.number().required(branch.boatWidthReq),
    // fuel_tanks: yup.number().required(),
    // year_renovated: yup.string().required(),
    total_cabins: yup.number().required(branch.totalCabinsReq),
    // horse_power: yup.number().required(),
    // diving_certification: yup.string(),
    country_id: yup.string().required(branch.countryReq),
    city_id: yup.string().required(branch.provinceReq),
    area_id: yup.string().required(branch.cityReq),
    // area_name: yup.string().required('City required'),
    // generator_type: yup.string().required(),
    // kilo_watt: yup.number().required(),
});

export const BranchValidationSchema = yup.object({
    // name: yup.string().required(),
    manager_name: yup.string().required(branch.managerNameReq),
    name_en: yup.string().required(branch.pleaseEnterBranchName),
    email: yup.string().email().required(branch.emailReq),
    branch_number: yup.number().required(branch.numberReq),
    insurance_name: yup.string(),
    policy_number: yup.string(),
    // license_number: yup.string(),
    // diving_certification: yup.string().required(),
    country_id: yup.string().required(branch.countryReq),
    city_id: yup.string().required(branch.provinceReq),
    area_id: yup.string().required(branch.areaReq),
    // area_name: yup.string().required('Area required'),
});
