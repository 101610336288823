import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";

const ActivityCompletedModel = ({
  open,
  setOpen,
  handelActivityCompleted,
  t,
  isLoading = false,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "25px",
    p: 4,
  };
  console.log(isLoading, "isLoading");
  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack sx={style} alignItems="center" justifyContent="center">
        <Typography
          component="h1"
          style={{ color: "#f6b119", fontWeight: "bold", fontSize: "30px" }}
        >
          {t("reservations.activityCompleted")}
        </Typography>
        <Typography textAlign="center" style={{ fontWeight: "bold" }}>
          {t("reservations.activityCompletedMsg")}
        </Typography>
        <Stack flexDirection="row" alignItems="center" mt={4}>
          <Button
            style={{ width: "150px", color: "black" }}
            onClick={() => setOpen(false)}
          >
            {t("reservations.cancel")}
          </Button>

          {!isLoading ? (
            <Button
              style={{ width: "150px" }}
              className="primary r-25px"
              onClick={handelActivityCompleted}
            >
              {t("reservations.yes")}
            </Button>
          ) : (
            <Button style={{ width: "150px" }} className="primary r-25px">
              <CircularProgress size={"30px"} sx={{ color: "white" }} />
            </Button>
          )}
        </Stack>
      </Stack>
    </Modal>
  );
};

export default withTranslation()(ActivityCompletedModel);
