import { Grid, Typography } from "@mui/material";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput.jsx";
import "../../Activities/Forms/Form.css";
import {useState, useEffect} from "react";
import PhoneInput from "../../../Atoms/Input/PhoneInput/PhoneInput.jsx";
import en from "react-phone-number-input/locale/en.json";

const AddParticipant = (props) => {
    const { i, getUserPhoneNumber, cartStatus, quantity, getUserName, getUserEmail, getCountryCode, firstUser } = props;
    const [countryCode, setCountryCode] = useState("20");
    const [phoneValue, setPhoneValue] = useState("");
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");

    const handleChange = (e, i) => {
        setPhoneValue(e.target.value);
        getUserPhoneNumber(e.target.value, i);
    };
    const updateUserName = (e, i) => {
        setUserName(e.target.value);
        getUserName(e.target.value, i);
    };
    const updateEmail = (e, i) => {
        setUserEmail(e.target.value);
        getUserEmail(e.target.value, i);
    };

    const updateCountryCode = (value, i) => {
        setCountryCode(value);
        getCountryCode(value, i);
    };

    useEffect(() => {
        if(i === 0) {
            setUserEmail(firstUser.email);
            setUserName(firstUser.firstname + " " + firstUser.lastname);
            firstUser.phone_number === null ? setPhoneValue('') : setPhoneValue(firstUser.phone_number);
            firstUser.country_code === null ? setCountryCode('') : setCountryCode(firstUser.country_code);
        }
    }, [firstUser]);
    return (
        <Grid container lg={12} className="mb-20px">
            <Typography component="h2" className="mb-20px" fontWeight="bold">
                Participation {" "} {i+1}
            </Typography>
            <Grid container md={12}>
                <Grid md={6}>
                    <TextFieldInput
                        id={"user name " + i}
                        name={"user name " + i}
                        type="text"
                        placeholder="Client Name"
                        style={{width: "90%"}}
                        value={userName}
                        className="normal-textField"
                        onChange={(e) => updateUserName(e, i)}
                    />
                </Grid>
                <Grid container md={6}> 
                    <Grid md={12}>
                        <PhoneInput
                            id={"phone " + i}
                            selectId={"search-select-" + i}
                            name={"phone " + i}
                            inputValue={phoneValue}
                            setPhone={(e) => handleChange(e, i)}
                            placeholder={"Phone Number"}
                            labels={en}
                            value={countryCode}
                            onChange={(input) => updateCountryCode(input, i)}
                            className="normal-textField"
                        />
                    </Grid>
                    
                </Grid>
            </Grid>
            <Grid container md={12}>
                <Grid md={6}>
                    <TextFieldInput
                        id={"email " + i}
                        name={"email " + i}
                        type="email"
                        placeholder="Email address"
                        style={{width: "90%"}}
                        value={userEmail}
                        className="normal-textField"
                        onChange={(e) => updateEmail(e, i)}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default AddParticipant