import { Button, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import SwitcheLabel from "../../Components/Atoms/Buttons/Switch/SwitchButton";
import { editDiveCenterActive } from "../../Contexts/APIs/DiveCenter/DiveCenter";
import AttentionModal from "../../Components/Molecules/General/Models/AttentionModal";
import { withTranslation } from "react-i18next";
import { getDiveCenterStatistics } from "../../Contexts/APIs/Statistics/statistics";
import Charts from "../../Components/Atoms/SharedComponents/Charts";
import DiveCenterStatistics from "../../Components/Organisms/Statistics/DiveCenterStatistics";

const HomeTemplate = ({ profileData, t, setProfileData }) => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  const [open, setOpen] = useState(false);
  const [activeState, setActiveState] = useState(
    profileData?.status === "active" ? true : false
  );

  const handleDiveCenterActive = async (id, data) => {
    const { res, err } = await editDiveCenterActive(id, data);
    if (res) {
      setActiveState((prevState) => (prevState === true ? false : true));
      setProfileData(res?.data?.user);
    }
  };

  useEffect(() => {
    setActiveState(profileData?.status === "active" ? true : false);
  }, [profileData]);
  // console.log(profileData);f
  return (
    <>
      <Grid rowSpacing={1} columnSpacing={{ xs: 2 }} container item lg={12}>
        <Grid item md={profileData?.user_type != "descendant_user" ? 7 : 12}>
          <Stack
            sx={{
              alignItems: "center",
              bgcolor: "#333132",
              borderRadius: "10px",
              padding: "32px 30px",
              margin: "10px 10px 30px",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Stack
              gap={2}
              sx={{
                flexDirection: "row",
                padding: "0px",
                width: "100%",
                alignItems: "center",
              }}
            >
              <img
                src={profileData?.user_type != "descendant_user" ? profileData?.logo : profileData?.ancestor?.logo}
                width="90px"
                height="90px"
                style={{ borderRadius: "10px", border: "solid 1px #f6b119" }}
                alt="profile logo"
              />
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
                className="details-header-content"
              >
                <Stack flexDirection="column" justifyContent="space-evenly">
                  <Stack flexDirection="row" gap={2} alignItems="center">
                    <Typography
                      fontSize="30px"
                      fontWeight="bold"
                      color="white"
                      textTransform="capitalize"
                    >
                      {profileData?.user_type != "descendant_user" ? profileData?.name : profileData?.ancestor?.name} {t("profile.dashboard")}
                    </Typography>
                  </Stack>
                  <Stack flexDirection="row" gap={2} alignItems="center">
                    <Typography fontSize="20px" fontWeight="bold" color="white">
                      {profileData?.owner_name}
                    </Typography>
                  </Stack>
                </Stack>
                {profileData?.user_type != "descendant_user" && (
                  <Stack flexDirection="row" gap={1} alignItems="center">
                    <Stack
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center" 
                    >
                      <SwitcheLabel
                        checked={activeState}
                        style={{ margin: "auto", padding: "12px" }}
                        change={(data) => {
                          data === false
                            ? setOpen(true)
                            : handleDiveCenterActive(profileData?.id, {
                                user: { status: "active" },
                              });
                        }}
                        className={"dive-center-active-switch"}
                      />
                    </Stack>
                    <Stack
                      flexDirection="column"
                      py="4px"
                      justifyContent="space-between"
                    >
                      <Typography
                        fontSize="16px"
                        fontWeight="bold"
                        color="#f6b119"
                        display="block"
                      >
                        {profileData?.status === "active"
                          ? t("profile.active")
                          : t("profile.deactive")}
                      </Typography>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        {profileData?.user_type != "descendant_user" && (
          <Grid item md={5}>
            <Grid
              container
              item
              lg={12}
              className="activity-form"
              sx={{ alignItems: "center" }}
            >
              <Grid container item md={6}>
                <Grid container item md={12}>
                  <Typography
                    component="h1"
                    style={{
                      color: "#aaaaaa",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                    className="mb-20px"
                  >
                    {t("points.availablePoints")}
                  </Typography>
                </Grid>
                <Grid container item md={12}>
                  <Typography
                    component="h1"
                    style={{
                      color: "#f6b119",
                      fontSize: "32px",
                      fontWeight: "bold",
                    }}
                    className="mb-20px"
                  >
                    {parseFloat(profileData?.available_points)}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container item md={6} sx={{ justifyContent: "end" }}>
                <Button
                  style={{
                    height: "fit-content",
                    borderRadius: "26px",
                    color: "#f6b119",
                    border: "1px solid #f6b119",
                  }}
                  onClick={() => navigate(`/points?page=${pageNumber}`)}
                >
                  {t("profile.viewDetails")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <DiveCenterStatistics profileData={profileData} />
      {open === true && (
        <AttentionModal
          open={open}
          setOpen={setOpen}
          handleRequest={() => {
            handleDiveCenterActive(profileData?.id, {
              user: {
                status: `${
                  profileData?.status === true ? "active" : "deactivated"
                }`,
              },
            });
            setOpen(false);
          }}
          text={[
            profileData?.name,
            t("profile.operatorDeactivated"),
            t("profile.operatorDeactivatedMsg"),
          ]}
          buttons={[t("profile.deactivate"), t("profile.cancel")]}
        />
      )}
    </>
  );
};

export default withTranslation()(HomeTemplate);
