import {
  Button,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useSearchParams } from "react-router-dom";
import TextFieldInput from "../../Atoms/Input/TextField/TextFieldInput";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import SelectBox from "../../Atoms/Select/Select";
import { useEffect, useState } from "react";
import QrScan from "react-qr-reader";
import ScanQrcodeModel from "./Models/qrCode-scan";
import { ScanQrCode } from "../../../Contexts/APIs/General/scan-code";
import { getActivitiesList } from "../../../Contexts/APIs/Activities/Activities";
import { getBranchesList } from "../../../Contexts/APIs/Branches/Branches";
import "./JobOffersHeader.css";
import { withTranslation } from "react-i18next";
import SearchableSelect from "../../Atoms/Select/SearchableSelect";
import SearchSelect from "../../Atoms/Select/SearchSelect";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import BranchesFilter from "../../Organisms/Statistics/BranchesFilter.jsx";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const JobOffersHeader = ({
  t,
  handleChange,
  title = "Job offers",
  url = "/freelancers/add-job-offers",
  is_navigate = true,
  joboffersPrivilages,
  profileData,
  filterObj,
  setFilterObj,
}) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const isFilterOpen = Boolean(anchorEl);
  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [branchList, setbranchesList] = useState([]);
  const userType = localStorage.getItem("userType");
  const [activityType, setActivityType] = useState("");
  const [branch, setBranch] = useState();
  const [status, setStatus] = useState();
  const activitesList = [
    {
      name: t("joboffer.all"),
      id: "all",
    },
    {
      name: t("joboffer.diveTrip"),
      id: "dive_trip",
    },
    {
      name: t("joboffer.diveCourse"),
      id: "dive_course",
    },
    {
      name: t("joboffer.liveaboard"),
      id: "live_aboard",
    },
  ];
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handelScanCode = (code) => {
    const action = () => {
      setOpen(false);
      window.location.reload();
    };
    ScanQrCode({ qr_code: code }, action);
  };
  const handelApply = () => {
    setFilterObj((s) => {
      return {
        ...s,
        branch_id: branch?.value && branch?.value,
        activity_type: activityType,
        status,
      };
    });
    handleClose();
  };
  const handelReset = () => {
    setActivityType("");
    setStatus("");
    setBranch("");
    handleClose();
    setFilterObj((s) => {
      return {
        status: "accepted",
        page_number: 1,
        sort_by: "id",
      };
    });
  };
  const getBranches = async (filterObj) => {
    const { res, err } = await getBranchesList({
      ...filterObj,
      page_number: 1,
      dive_center_id: userId,
    });
    if (res) {
      setbranchesList([{ name: "All", id: "All" }, ...res?.data?.branches]);
    }
  };
  const getBranchesFiltered = async (dive_center_id, filter_id) => {
    const { res, err } = await getBranchesList({
      page_size: 10000,
      page_number: 1,
      dive_center_id: dive_center_id,
      branch_type: filter_id == "live_aboard" ? "safari_boat" : "",
    });
    if (res) {
      setbranchesList(res?.data?.branches);
    }
  };

  useEffect(() => {
    if (userId && activityType) getBranchesFiltered(userId, activityType);
  }, [activityType]);
  return (
    <Grid container>
      <Stack
        flexDirection="row"
        container
        width="100%"
        gap={1}
        justifyContent="space-between"
        className="job-offer-header"
      >
        <Typography
          component="h1"
          style={{
            fontWeight: "bold",
            fontSize: "25px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {title}
        </Typography>

        <TextFieldInput
          type="search"
          placeholder={t("joboffer.searchJobOffers")}
          StartAdornment={<SearchIcon />}
          className="joboffers-search"
          style={{ width: "200px", minWidth: "200px" }}
          onChange={(e) => handleChange("keyword", e.target.value)}
          sx={{ padding: "0px!important" }}
        />
        {/* <SelectBox
          style={{ width: "185px", height: "58px" }}
          items={activitesList}
          title={t("joboffer.allActivites")}
          className="select"
          onchange={(e) => {
            handleChange(
              "activity_type",
              e.target.value == "all" ? "" : e.target.value
            );
            setActivityType(e.target.value);
          }}
          defaultValue=""
        />

        <Grid
          item
          style={{ width: "190px", height: "60px" }}
          className="branches-drop-down"
        >
          <BranchesFilter
            setFilterObj={setFilterObj}
            filterObj={filterObj}
            profileData={profileData}
          />
        </Grid> */}
        <Grid item>
          <Button
            id="basic-button"
            variant="outlined"
            sx={{ color: "black", borderColor: "#BEBEBE", height: "48px" }}
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            startIcon={<FilterAltIcon color="#BEBEBE" />}
          >
            {t("diveExpertActivity.filter")}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={isFilterOpen}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{
              "& .MuiList-root": {
                width: "370px !important",
              },
            }}
          >
            <Typography color="#333132" fontSize="16px" fontWeight="500" p={1}>
              {t("diveExpertActivity.filterBy")}
            </Typography>
            <Divider w="100%" />
            <MenuItem
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                width: "100%",
              }}
            >
              {" "}
              <Typography color="#333132" fontSize="16px">
                {t("diveExpertActivity.diveExpertName")}
              </Typography>
              <SelectBox
                style={{ width: "100%", height: "58px" }}
                items={activitesList}
                title={t("joboffer.allActivites")}
                className="select"
                onchange={(e) => {
                  setActivityType(e.target.value);
                }}
                defaultValue={activityType}
                value={activityType}
                disabled={false}
                onClear={() => {
                  setActivityType("");
                }}
              />
            </MenuItem>
            <MenuItem
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              {" "}
              <Typography color="#333132" fontSize="16px">
                {t("offer.statues")}
              </Typography>
              <SelectBox
                style={{ width: "100%", height: "58px" }}
                items={[
                  { id: "pending", name: t("reservations.pending") },
                  { id: "hired", name: t("joboffer.hired") },
                  { id: "qr_scanned", name: t("joboffer.qr_scanned") },
                  { id: "completed", name: t("offer.completed") },
                  { id: "canceled", name: t("joboffer.canceled") },
                  { id: "deleted", name: t("reservations.deleted") },
                ]}
                title={t("offer.statues")}
                className="select"
                onchange={(e) => {
                  setStatus(e.target.value);
                }}
                defaultValue={status} 
                disabled={false}
                onClear={() => {
                  setStatus("");
                }}
              />
            </MenuItem>
            <MenuItem
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              {" "}
              <Typography color="#333132" fontSize="16px">
                {t("branch.branchName")}
              </Typography>
              <BranchesFilter
                setFilterObj={setFilterObj}
                filterObj={filterObj}
                profileData={profileData}
                handleChange={(val) => setBranch(val)}
                defaultValue={branch}
                handleClear={(_) => setBranch("")}
              />
            </MenuItem>
            <MenuItem
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                gap: 2,
                cursor: "auto",
              }}
            >
              <Button
                onClick={handelReset}
                style={{
                  borderRadius: "26px",
                  color: "black",
                  border: "1px solid #bebebe",
                  width: "50%",
                  padding: "10px",
                }}
              >
                Reset
              </Button>
              <PrimaryButton
                classBtn="primary r-25px"
                text="Apply"
                click={handelApply}
                style={{
                  width: "50%",
                  padding: "10px",
                }}
              />
            </MenuItem>
          </Menu>
        </Grid>
        {userType === "descendant_user" && joboffersPrivilages?.can_create && (
          <>
            {is_navigate && (
              <PrimaryButton
                fontSize="13px"
                startIcon={<AddIcon />}
                text={t("joboffer.createJobOffer")}
                style={{ padding: "8px", height: "48px" }}
                classBtn="primary r-25px"
                click={() => navigate(`${url}?page=${pageNumber}`)}
              />
            )}
          </>
        )}
        {userType !== "descendant_user" && (
          <>
            {is_navigate && (
              <PrimaryButton
                fontSize="13px"
                startIcon={<AddIcon />}
                text={t("joboffer.createJobOffer")}
                style={{ padding: "8px", height: "48px" }}
                classBtn="primary r-25px"
                click={() => navigate(`${url}?page=${pageNumber}`)}
              />
            )}
          </>
        )}
        <PrimaryButton
          fontSize="13px"
          endIcon={<QrCodeScannerIcon />}
          text={t("joboffer.scanQRFreelancer")}
          style={{ padding: "8px", height: "48px" }}
          classBtn="primary r-25px"
          click={() => setOpen(true)}
        />
      </Stack>
      {open && (
        <ScanQrcodeModel
          open={open}
          setOpen={setOpen}
          handelScanCode={handelScanCode}
        />
      )}{" "}
      <Divider style={{ width: "100%", margin: "20px 0" }} />
    </Grid>
  );
};

export default withTranslation()(JobOffersHeader);
