import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getReviewsList } from "../../../Contexts/APIs/Reviews/Reviews";
import { getActivityDetails } from "../../../Contexts/APIs/Activities/Activities";
import ActivityReviewsTemplate from "../../../Templates/Main/Acticities/ActivityReviews.template";

const ActivityReviewsPage = ({ profileData }) => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const [filterObj, setFilterObj] = useState({
    page_size: 10,
    page_number: 1,
    activity_id: id
  });

  const [activityDetails, setActivityDetails] = useState({});
  const [reviewsList, setReviewsList] = useState([]);
  const [reviewsDetails, setReviewsDetails] = useState({});
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);

  const handlePageChange = (event, value) => {
    setPage(value);
    setFilterObj({ page_number: value });
  };

  const ActivityDetails = async () => {
    const {res, err} = await getActivityDetails(id);
    if(res){
        setActivityDetails(res?.data?.activity);
    }
  };

  const GetReviewsList = async () => {
    const data = {
      review: {
        reviewee_id: (searchParams.get("type") == null || searchParams.get("type") == "diveCenter") 
          ? activityDetails?.branch_id
          : searchParams.get("type") == "diveActivity" ? id
          : undefined,
        reviewee_type: (searchParams.get("type") == null || searchParams.get("type") == "diveCenter") 
          ? "Branch" 
          : searchParams.get("type") == "diveActivity" ? "DiveActivity"
          : searchParams.get("type") == "diveExpert" ? "DiveExpert" : null,
        reviewer_type: "Customer"
      },
    };

    const { res, err } = await getReviewsList(filterObj, data);
    if (res) {
      setReviewsList(res?.data?.reviews);
      setReviewsDetails(res?.data?.reviews_details);
      setCount(res?.extra?.total_count);
    }
  };



  useEffect(() => {
    ActivityDetails();
  }, []);

  useEffect(() => {
    GetReviewsList();
  }, [filterObj]);

  useEffect(() => {
    GetReviewsList();
  }, [searchParams?.get("type")]);

  return (
    <ActivityReviewsTemplate
      count={count}
      reviewsList={reviewsList}
      activityDetails={activityDetails}
      reviewsDetails={reviewsDetails}
      handlePageChange={handlePageChange}
      page={page}
    />
  );
};

export default ActivityReviewsPage; 
