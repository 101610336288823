import { axiosFn, handleError } from "../index";

export async function CheckExists(data, Navigate) {
    return await axiosFn("post", '/auth/passwords/check_exists', data)
    .then((res) => {
        // if( res?.data?.data?.user_types?.indexOf('dive_center') !== -1){
        //     ResetPassOtp(data, 'customer', Navigate);            
        // }
        ResetPassOtp(data, 'dive_center', Navigate);
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
} 

export async function ResetPassOtp(data, user_type, Navigate) {
    return await axiosFn("post", `/auth/passwords/${user_type}/send_reset_password_info`, data)
    .then((res) => {        
        if(res?.data){
            localStorage.setItem('email', data.user.email);
        }
        Navigate('/email-success');
        return {
            res: res.data,
            err: null,
        };
    })
    .catch(handleError);
}

export async function VerifyOtp(data, user_type, Navigate) {
    return await axiosFn("post", `/auth/passwords/${user_type}/verify_otp`, data)
    .then( async (res) => {
        if(res){
            window.localStorage.setItem('accessToken', res?.data?.data?.token);
            window.localStorage.setItem('VERIFY', res?.data?.data?.token);
        }         
        Navigate('/confirm-password');
        return {
            res: res.data,
            err: null,
        };
    })
    .catch(handleError);
}

export async function ConfirmPass(data, user_type, Navigate) {
    return await axiosFn("post", `/auth/passwords/${user_type}/reset_password`, data)
    .then((res) => {
            if(res?.data){
                Navigate('/login');
                window.localStorage.removeItem('VERIFY');
            }
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}