import InHouseActivityForm from "./InHouseActivityForm.jsx";

const AddInHouseActivityPanel = (props) => {
  const {
    uploadActivityImages,
    supportedLanguages,
    handleFormSubmit,
    branchesList,
    currencies,
    organizationsList,
    getCertificates,
    certificatesList,
    languagesList,
    activityImages,
    removeActivityImage,
    getLanguagesAPI,
    type,
    activityDetails,
    getBranchOrganizations,
    getOrganization,
    getCurrencies,
    getBranches,
    languagesCount,
    setCertificatesList,
    certificatesCount,
    organizationsCount,
    profileData
  } = props;

  return (
    <InHouseActivityForm
      getBranchOrganizations={getBranchOrganizations}
      getOrganization={getOrganization}
      uploadActivityImages={uploadActivityImages}
      supportedLanguages={supportedLanguages}
      handleFormSubmit={handleFormSubmit}
      branchesList={branchesList}
      currencies={currencies}
      getCurrencies={getCurrencies}
      organizationsList={organizationsList}
      getCertificates={getCertificates}
      certificatesList={certificatesList}
      languagesList={languagesList}
      getLanguagesAPI={getLanguagesAPI}
      languagesCount={languagesCount}
      activityImages={activityImages}
      removeActivityImage={removeActivityImage}
      formType={type}
      activityDetails={activityDetails}
      getBranches={getBranches}
      setCertificatesList={setCertificatesList}
      certificatesCount={certificatesCount}
      organizationsCount={organizationsCount}
      profileData={profileData}
    />
  );
};

export default AddInHouseActivityPanel;
