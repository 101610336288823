import { Grid, Typography } from "@mui/material";
import React from "react";
import PoinstActionsList from "../../../Components/Organisms/Points/actionsList";
import PointsConfig from "../../../Components/Organisms/Points/config";
import CustomPagination from "../../../Components/Atoms/Pagination/Pagination";
import { withTranslation } from "react-i18next";

const PointsTemplate = ({
  pointsData,
  pointsConfig,
  profileData,
  count,
  page,
  handlePageChange,
  pointsRate,
  t,
}) => {
  return (
    <Grid rowSpacing={1} columnSpacing={{ xs: 2 }} container item lg={12}>
      <Grid item md={6}>
        <Grid container item lg={12} className="activity-form">
          <Grid container item md={12}>
            <Typography
              component="h1"
              style={{
                color: "#aaaaaa",
                fontSize: "20px",
                fontWeight: "bold",
              }}
              className="mb-20px"
            >
              {t("points.availablePoints")}
            </Typography>
            <Grid container item md={12}>
              <Typography
                component="h1"
                style={{
                  color: "#f6b119",
                  fontSize: "32px",
                  fontWeight: "bold",
                }}
                className="mb-20px"
              >
                {parseFloat(profileData?.available_points)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* points actions */}
        <Grid container item lg={12} className="activity-form">
          <Typography
            component="h1"
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              borderBottom: "2px solid var(--primary-color)",
            }}
            className="mb-20px"
          >
            {t("points.pointHistory")}
          </Typography>
          {pointsData?.map((val, index) => {
            return <PoinstActionsList pointData={val} key={index} t={t} />;
          })}
        </Grid>
      </Grid>

      {/* points config */}
      <Grid item md={6}>
        <Grid container item lg={12} className="activity-form">
          <Grid container item md={12}>
            <Typography
              component="h1"
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                borderBottom: "2px solid var(--primary-color)",
              }}
              className="mb-20px"
            >
              {`${t("points.howGetPoints")} ( ${pointsRate} points = 1 ${
                profileData?.bank_account?.currency?.lookup_key
              })`}
            </Typography>
          </Grid>
          <Grid container item md={12}>
            {pointsConfig?.map((val, index) => {
              return <PointsConfig pointConfig={val} key={index} />;
            })}
          </Grid>
        </Grid>
      </Grid>

      {count > 10 && (
        <Grid container item justifyContent="center" mb={7.5} mt={5}>
          <CustomPagination
            count={Math.ceil(count / 10)}
            size="large"
            shape="rounded"
            page={page}
            handleChange={handlePageChange}
            className="pagination"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default withTranslation()(PointsTemplate);
