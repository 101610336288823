import { Grid, Typography, Divider } from "@mui/material";
import NormalTabs from "../../Atoms/Tabs/NormalTabs.js";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import AddIcon from "@mui/icons-material/Add";
import TextFieldInput from "../../Atoms/Input/TextField/TextFieldInput.jsx";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate, useSearchParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import BranchesFilter from "../../Organisms/Statistics/BranchesFilter.jsx";

const ActivityTabs = (props) => {
  const {
    t,
    options,
    handleActivityValue,
    ActivityValue,
    type,
    handleSubmit,
    activitiesNo,
    activityType,
    getKeywords,
    getBranchType,
    activitiesPrivilages,
    isInHouseActivity,
    filterObj,
    setFilterObj,
    profileData,
  } = props;
  const Navigate = useNavigate();
  const userType = localStorage.getItem("userType");
  let [searchParams, setSearchParams] = useSearchParams();

  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  return (
    <Grid container display={"flex"} flexWrap={"wrap"}>
      <Grid
        container
        display={"flex"}
        justifyContent={{ lg: "space-between" }}
        flexWrap={"wrap"}
      >
        <Grid
          container
          item
          xs={isInHouseActivity === true ? 12 : "auto"}
          sm={isInHouseActivity === true ? 12 : type === "edit" ? 4 : 1.5}
          xl={isInHouseActivity === true ? 4 : type === "edit" ? 3 : 1.4}
          lg={isInHouseActivity === true ? 4 : type === "edit" ? 3 : 1.4}
          md={isInHouseActivity === true ? 4 : type === "edit" ? 3 : 2}
        >
          <Grid
            xs={
              isInHouseActivity === true
                ? 12
                : type === "add" || type === "edit"
                ? 12
                : 12
            }
            sx={
              isInHouseActivity === true
                ? { display: "flex", alignItems: "center", gap: "20px" }
                : null
            }
            flexDirection="row"
          >
            <Typography
              component="h1"
              style={{ fontWeight: "bold", fontSize: "30px" }}
            >
              {isInHouseActivity === true
                ? t("salesmen.inHouseActivity")
                : type === "add"
                ? t("activity.addNewActivity")
                : type === "edit" && activityType
                ? `${t("activity.editActivity")} (${activityType
                    ?.split("_")
                    .join(" ")})`
                : t("activity.activities")}
            </Typography>
            {type !== "add" && type !== "edit" && (
              <Typography
                component="p"
                style={{
                  color: "#bebebe",
                  fontSize: "16px",
                  lineHeight: "2.5",
                }}
              >
                {activitiesNo} {t("activity.activities")}
              </Typography>
            )}
          </Grid>
        </Grid>
        {type !== "edit" && options && (
          <Grid item lg={5.5} xl={4.3} md={7.3} sm={6}>
            <NormalTabs
              TabsOptions={options}
              value={ActivityValue}
              change={handleActivityValue}
              ContainerStyle="switch-container"
              TabStyle="switch-btn mb-20px"
              onclick={(value) => {
                getBranchType && getBranchType(value);
              }}
            />
          </Grid>
        )}
        {!type && (
          <Grid
            xs={2}
            lg={isInHouseActivity === true ? 3.2 : 2.2}
            xl={isInHouseActivity === true ? 3.2 : 2.2}
            md={isInHouseActivity === true ? 3.2 : 3.2}
            sx={{ mb: "20px", display: "flex" }}
            justifyContent={{
              lg: isInHouseActivity === true ? "end" : "start",
              xs: "start",
            }}
            className="border-r-10px"
          >
            <BranchesFilter
              setFilterObj={setFilterObj}
              filterObj={filterObj}
              profileData={profileData}
            />
          </Grid>
        )}
        {isInHouseActivity === true ? (
          <Grid
            container
            item
            xs={12}
            lg={4.8}
            gap={"20px"}
            justifyContent={"end"}
          >
            <Grid item sm={"auto"}>
              <TextFieldInput
                onChange={(e) => getKeywords(e?.target?.value)}
                type="search"
                placeholder={t("joboffer.searchActivities")}
                StartAdornment={<SearchIcon />}
                className="activity-search"
                sx={{ padding: "0px!important" }}
              />
            </Grid>
            <Grid item sm={"auto"} textAlign="end">
              {userType === "descendant_user" &&
                activitiesPrivilages?.can_create && (
                  <PrimaryButton
                    fontSize="14px"
                    startIcon={<AddIcon />}
                    text={t("activity.addNewActivity")}
                    classBtn="primary r-25px"
                    style={{ padding: "5px 20px", height: "48px" }}
                    click={() =>
                      Navigate(
                        `/salesmen/in-house-activities/add-new-activity?page=${pageNumber}`
                      )
                    }
                  />
                )}
              {userType !== "descendant_user" && (
                <PrimaryButton
                  fontSize="14px"
                  startIcon={<AddIcon />}
                  text={t("activity.addNewActivity")}
                  classBtn="primary r-25px"
                  style={{ padding: "5px 20px", height: "48px" }}
                  click={() =>
                    Navigate(
                      `/salesmen/in-house-activities/add-new-activity?page=${pageNumber}`
                    )
                  }
                />
              )}
            </Grid>
          </Grid>
        ) : null}
        {type !== "add" && type !== "edit" && isInHouseActivity !== true ? (
          <>
            <Grid item sm={1.75} md={2.5} lg={1.75}>
              <TextFieldInput
                onChange={(e) => getKeywords(e?.target?.value)}
                type="search"
                placeholder={t("joboffer.searchActivities")}
                StartAdornment={<SearchIcon />}
                className="activity-search"
                sx={{ padding: "0px!important" }}
              />
            </Grid>
            <Grid item sm={2} textAlign="end">
              {userType === "descendant_user" &&
                activitiesPrivilages?.can_create && (
                  <PrimaryButton
                    fontSize="14px"
                    startIcon={<AddIcon />}
                    text={t("activity.addNewActivity")}
                    classBtn="primary r-25px"
                    style={{ padding: "5px 20px", height: "48px" }}
                    click={() =>
                      Navigate(
                        `/activities/add-new-activity?page=${pageNumber}`
                      )
                    }
                  />
                )}
              {userType !== "descendant_user" && (
                <PrimaryButton
                  fontSize="14px"
                  startIcon={<AddIcon />}
                  text={t("activity.addNewActivity")}
                  classBtn="primary r-25px"
                  style={{ padding: "5px 20px", height: "48px" }}
                  click={() =>
                    Navigate(`/activities/add-new-activity?page=${pageNumber}`)
                  }
                />
              )}
            </Grid>
          </>
        ) : type === "edit" ? (
          <>
            <Grid item sm={8} textAlign="end">
              <PrimaryButton
                text={t("activity.cancel")}
                classBtn="secoundry r-25px"
                style={{
                  padding: "5px 20px",
                  height: "48px",
                  margin: "0 10px",
                }}
                click={() => Navigate(`/activities?page=${pageNumber}`)}
              />
              <PrimaryButton
                text={t("activity.applyChange")}
                classBtn="primary r-25px"
                style={{
                  padding: "5px 20px",
                  height: "48px",
                  margin: "0 10px",
                }}
                type="submit"
                click={() => handleSubmit()}
              />
            </Grid>
          </>
        ) : isInHouseActivity !== true ? (
          <>
            <Grid item sm={4} textAlign="end">
              <PrimaryButton
                text={t("activity.cancel")}
                classBtn="secoundry r-25px"
                style={{
                  padding: "5px 20px",
                  height: "48px",
                  margin: "0 10px",
                }}
                click={() => Navigate(`/activities?page=${pageNumber}`)}
              />
              <PrimaryButton
                fontSize="14px"
                text={t("activity.addActivity")}
                classBtn="primary r-25px"
                style={{
                  padding: "5px 20px",
                  height: "48px",
                  margin: "0 10px",
                }}
                type="submit"
                click={() => handleSubmit()}
              />
            </Grid>
          </>
        ) : null}
      </Grid>
      <Divider style={{ width: "100%", margin: "20px 0" }} />
    </Grid>
  );
};

export default withTranslation()(ActivityTabs);
