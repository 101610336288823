import { Grid, Typography } from "@mui/material";
import DetailsHeader from "../../Molecules/Reservations/DetailsHeader.jsx";
import DetailsList from "../../Molecules/Reservations/List/DetailsList.jsx";
import { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";

const ReservationsDetails = (props) => {
    const { getGroupReservationsList, count, reservationsData, setReservationsData, reservationsDuration, setOpenLoader, updatePayment, ordersPrivilages } = props;
    const { id } = useParams();
    const [search, setSearch] = useSearchParams();

    const [filterObj, setFilterObj] = useState({
        'page_size': 10,
        'page_number': 1,
        'keyword': search.get('reservation_number'),


    });
    const updatePagination = (page) => {
        setFilterObj({ ...filterObj, 'page_number': page });
    };
    const updateSearch = (data) => {
        setFilterObj({ ...filterObj, ...data });
    };
    // const updateActivityGroup = (activity_group_id) => {
    //     setFilterObj({ ...filterObj, 'activity_group_id': activity_group_id });
    // };





    useEffect(() => {
        getGroupReservationsList({ ...filterObj });
    }, [filterObj]);

    // useEffect(() => {
    //     if (id) updateActivityGroup(id)
    // }, [id])

    return (
        <Grid container className="reservations-container">
            <DetailsHeader
                activityGroup={reservationsData?.activity_group}
                updateSearch={updateSearch}
                activityId={reservationsData?.activity?.id}
            />
            <DetailsList
                count={count}
                updatePagination={updatePagination}
                list={reservationsData?.orders}
                updatePayment={updatePayment}
                ordersPrivilages={ordersPrivilages}
                setReservationsData={setReservationsData}
                reservationsDuration={reservationsDuration}
                setOpenLoader={setOpenLoader}
                filterObj={filterObj}
            />
        </Grid> 
    )
}

export default ReservationsDetails