import { axiosFn, handleError } from "../index";

export async function addGeneralComplaint(reservation_data) {
    return await axiosFn("post", 'complaints/general_complaint/' , reservation_data)
      .then((res) => {
        return { 
          res: res.data,
          err: null,
        };
      })
      .catch(handleError);
}

export async function addAppComplaint(reservation_data) {
  return await axiosFn("post", 'complaints/app_complaint/' , reservation_data)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}