import { Grid, Typography } from "@mui/material";
import FreelancersList from "../../../Components/Organisms/Reservations/FreelancersList.jsx";

const FreelancersTemplate = (props) => {

    return (
        <Grid container>
            <FreelancersList/>
        </Grid>
    )
}

export default FreelancersTemplate