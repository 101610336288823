import { Grid, Typography } from '@mui/material'
import React from 'react'
import { icons } from '../../../Assets/AssetHelper'
import './ForgetPassword.css';

function ForgetPasswordHead({
    imgSrc, imgAlt, text
}) {
  return (
    <Grid item xs={12} className='password-form-head'>
        <img src={icons.LoginLogo} alt="headerLogo"  className='logo'/>
        <img src={imgSrc} alt={imgAlt} className='check' />
        <Typography
            component="h1"
            style={{
                fontSize: "28px",
                fontWeight: "bold",
                color: "#272726"
            }}
        >
            {text}
        </Typography>
    </Grid>
  )
}

export default ForgetPasswordHead