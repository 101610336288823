import { Grid, Typography, Divider } from "@mui/material";
import TabPanel from "../../Atoms/Tabs/TabPanel.js";
import BranchForm from "./BranchForm.jsx";

const AddBranchPanel = (props) => {
  const {
    options,
    BranchValue,
    uploadImages,
    handleFormSubmit,
    organizationsList,
    createBranch,
    editBranch,
    branchImages,
    RemoveBranchImage,
    uploadLocalLicense,
    uploadLicense,
    localeLicenseImg,
    licenseImg,
    getFeaturesOptions,
    featureOptions,
    supportedLanguages,
    branchDetails,
    formType,
    getOrganization,
    organizationsCount,
    RemoveLocaleLicenseImg,
    licensesData,
    type,
    getbranchDetails,
    id
  } = props;
  return (
    <Grid container>
      {options.map((option, index) => (
        <Grid container key={index}>
          <TabPanel value={BranchValue} index={index} className="tab-panel">
            {index === 1 && (
              <BranchForm
                getOrganization={getOrganization}
                uploadImages={uploadImages}
                type="safari_boat"
                formType={formType}
                handleFormSubmit={handleFormSubmit}
                organizationsList={organizationsList}
                organizationsCount={organizationsCount}
                createBranch={createBranch}
                editBranch={editBranch}
                branchImages={branchImages}
                RemoveBranchImage={RemoveBranchImage}
                uploadLocalLicense={uploadLocalLicense}
                uploadLicense={uploadLicense}
                localeLicenseImg={localeLicenseImg}
                licenseImg={licenseImg}
                getFeaturesOptions={getFeaturesOptions}
                featureOptions={featureOptions}
                supportedLanguages={supportedLanguages}
                branchDetails={branchDetails}
                RemoveLocaleLicenseImg={RemoveLocaleLicenseImg}
                getbranchDetails={getbranchDetails}
                id={id}
              />
            )}
            {index === 0 && (
              <BranchForm
                getOrganization={getOrganization}
                organizationsCount={organizationsCount}
                uploadImages={uploadImages}
                formType={formType}
                handleFormSubmit={handleFormSubmit}
                organizationsList={organizationsList}
                createBranch={createBranch}
                editBranch={editBranch}
                branchImages={branchImages}
                RemoveBranchImage={RemoveBranchImage}
                uploadLocalLicense={uploadLocalLicense}
                uploadLicense={uploadLicense}
                localeLicenseImg={localeLicenseImg}
                licenseImg={licenseImg}
                getFeaturesOptions={getFeaturesOptions}
                featureOptions={featureOptions}
                supportedLanguages={supportedLanguages}
                branchDetails={branchDetails}
                RemoveLocaleLicenseImg={RemoveLocaleLicenseImg}
                licensesData={licensesData}
                type={type}
                getbranchDetails={getbranchDetails}
                id={id}
              />
            )}
          </TabPanel>
        </Grid>
      ))}
    </Grid>
  );
};

export default AddBranchPanel;
