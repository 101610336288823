import JobOffersList from "../../../../Components/Organisms/JobOffers/JobOffersList";

const JobOffersTemplate = ({
  count,
  offers,
  getJobOffers,
  type,
  joboffersPrivilages,
  jobRequestsPrivilages,
  filterObj,
  setFilterObj,
  profileData,
  setOffers
}) => {
  return (
    <JobOffersList
      count={count}
      offers={offers}
      getJobOffers={getJobOffers}
      type={type}
      joboffersPrivilages={joboffersPrivilages}
      jobRequestsPrivilages={jobRequestsPrivilages}
      filterObj={filterObj}
      setFilterObj={setFilterObj}
      profileData={profileData}
      setOffers={setOffers}
    />
  );
};

export default JobOffersTemplate;
