import { Grid, Typography } from "@mui/material";
import ReservationHeader from "../../Molecules/Reservations/ReservationHeader.jsx";
import ListActivitiesForReservations from "../../Molecules/Reservations/List/ListActivitiesForReservations.jsx";
import { useState, useEffect } from "react";
import DetailsList from "../../Molecules/Reservations/List/DetailsList.jsx";
const ReservationsList = (props) => {
  const {
    getReservationsActivities,
    count,
    reservationsActivities,
    branchesList,
    getBranches,
    reservationsData,
    setReservationsData,
    reservationsDuration,
    setOpenLoader,
    updatePayment,
    ordersPrivilages,
    filterObj,
    setFilterObj,
    ReservationsPageListener,
    branchesCount,
    getGroupReservationsList,
    profileData
  } = props;

  const [page, setPage] = useState(1);

  const updatePagination = (page) => {
    setFilterObj({ ...filterObj, page_number: page });
  };
  const updateActivityList = (name, value) => {
    setFilterObj({ ...filterObj, page_number: 1, [name]: value });
    setPage(1);
  };

  // useEffect(() => {
  //     getReservationsActivities(filterObj);
  // }, [filterObj]);

  return (
    <Grid container className="reservations-container">
      <ReservationHeader
        updateActivityList={updateActivityList}
        branchesList={branchesList}
        getBranches={getBranches}
        branchesCount={branchesCount}
        setReservationsData={setReservationsData}
        getGroupReservationsList={getGroupReservationsList}
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        profileData={profileData}
      />
      {/* <ListActivitiesForReservations
                updatePagination={updatePagination}
                count={count}
                reservationsActivities={reservationsActivities}
            /> */}
      <DetailsList
        count={count}
        updatePagination={updatePagination}
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        list={reservationsData?.orders}
        updatePayment={updatePayment}
        ordersPrivilages={ordersPrivilages}
        setReservationsData={setReservationsData}
        reservationsDuration={reservationsDuration}
        setOpenLoader={setOpenLoader}
        page={page}
        setPage={setPage}
        ReservationsPageListener={ReservationsPageListener}
      />
    </Grid>
  );
};

export default ReservationsList;
