import React from "react";
import CustomSeparator from "../../Atoms/BreadCrumb";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { Typography } from "@mui/material";
import { withTranslation } from "react-i18next";
// /requested-activites
const FreelancerBreadcrumb = ({ t, jobOfferData, applicantData }) => {
  const { pathname } = useLocation();
  const lang = localStorage.getItem("lang");

  let [searchParams, setSearchParams] = useSearchParams();

  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  return pathname?.includes("requested-activites") ||
    pathname?.includes("approved-activites") ? (
    <CustomSeparator
      breadcrumbs={[
        <Link
          style={{ color: "#bebebe", textDecoration: "none" }}
          underline="hover"
          key="1"
          color="inherit"
          to={
            pathname?.includes("requested-activites")
              ? `/requested-activites?page=${pageNumber}`
              : pathname.includes("private-guide-reservations")
              ? `/private-guide-reservations?page=${pageNumber}`
              : `/approved-activites?page=${pageNumber}`
          }
        >
          {pathname?.includes("requested-activites")
            ? t("diveExpertActivity.requestedActivites")
            : pathname.includes("private-guide-reservations")
            ? t("reservations.PrivateGuideResrvations")
            : t("diveExpertActivity.approvedActivites")}
        </Link>,
        <Typography
          key="3"
          style={{ color: "#bebebe", textDecoration: "none" }}
        >
          {applicantData?.name}
        </Typography>,
      ]}
    />
  ) : pathname?.includes("freelancers") ? (
    <CustomSeparator
      breadcrumbs={[
        <Link
          style={{ color: "#bebebe", textDecoration: "none" }}
          underline="hover"
          key="1"
          color="inherit"
          to={`/freelancers/list?page=${pageNumber}`}
        >
          {t("joboffer.freelancer")}
        </Link>,

        <Typography
          key="3"
          style={{ color: "#bebebe", textDecoration: "none" }}
        >
          {applicantData?.name}
        </Typography>,
      ]}
    />
  ) : (
    <CustomSeparator
      breadcrumbs={[
        <Link
          style={{ color: "#bebebe", textDecoration: "none" }}
          underline="hover"
          key="1"
          color="inherit"
          to={`/freelancers/list?page=${pageNumber}`}
        >
          {t("joboffer.freelancer")}
        </Link>,
        <Link
          style={{ color: "#bebebe", textDecoration: "none" }}
          underline="hover"
          key="2"
          color="inherit"
          to={`/freelancers/job-offers?page=${pageNumber}`}
        >
          {t("joboffer.jopOffers")}
        </Link>,
        <Link
          style={{ color: "#bebebe", textDecoration: "none" }}
          underline="hover"
          key="2"
          color="inherit"
          to={`/freelancers/job-offers/${jobOfferData?.id}?page=${pageNumber}`}
        >
          {jobOfferData?.localized_data &&
            jobOfferData?.localized_data[lang]?.title}
        </Link>,
        <Link
          style={{ color: "#bebebe", textDecoration: "none" }}
          underline="hover"
          key="2"
          color="inherit"
          to={`/freelancers/jop-offers-applicants/${jobOfferData?.id}?page=${pageNumber}`}
        >
          {t("joboffer.applicants")}
        </Link>,

        <Typography
          key="3"
          style={{ color: "#bebebe", textDecoration: "none" }}
        >
          {applicantData?.name}
        </Typography>,
      ]}
    />
  );
};

export default withTranslation()(FreelancerBreadcrumb);
