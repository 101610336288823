import { Grid, Typography } from "@mui/material";
import ActivityDetailsHeader from "../../Molecules/Activities/Details/ActivityDetailsHeader.jsx";
import ActivityDetailsPanel from "../../Molecules/Activities/Details/ActivityDetailsPanel.jsx";

const ActivityDetails = (props) => {
  const {
    activityDetails,
    RemoveActivity,
    activitiesPrivilages,
    language,
    type,
  } = props;
  return (
    <Grid container className="branch-details-container">
      <ActivityDetailsHeader
        activityDetails={activityDetails}
        RemoveActivity={RemoveActivity}
        activitiesPrivilages={activitiesPrivilages}
        language={language}
        type={type}
      />
      <ActivityDetailsPanel
        activityDetails={activityDetails}
        language={language}
      />
    </Grid>
  );
};

export default ActivityDetails;
