import React from "react";
import FreelancerDetails from "../../../Components/Organisms/Freelancers/FreelancerDetails";

const FreelancerDetailsTemplate = ({ jobOfferData, applicantData }) => {
  return (
    <FreelancerDetails
      jobOfferData={jobOfferData}
      applicantData={applicantData}
    />
  );
};

export default FreelancerDetailsTemplate;
