import { Grid, Typography } from "@mui/material";
import SwitcheLabel from "../../../Atoms/Buttons/Switch/SwitchButton.js";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import { icons } from "../../../../Assets/AssetHelper.js";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomPagination from "../../../Atoms/Pagination/Pagination.js";
import { useEffect, useState } from "react";
import {
  deleteActivity,
  editActivityAvilabilty,
} from "../../../../Contexts/APIs/Activities/Activities.js";
import { withTranslation } from "react-i18next";
import DeleteModal from "../../General/Models/DeleteModal.jsx";

const ListActivities = (props) => {
  const {
    t,
    activitesList,
    setActivitiesList,
    updatePagination,
    count,
    page,

    activitiesPrivilages,
    getActivities,
    filterObj,
    setOpenLoader,
  } = props;
  const Navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const userType = localStorage.getItem("userType");
  const selectedLang = localStorage.getItem("lang");
  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  const [SelectedActivity, setSelectedActivity] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [isAvilable, setAvailable] = useState({});

  const handelChangeAvilablty = async (data, id) => {
    setAvailable((prevAvailable) => ({
      ...prevAvailable,
      [`${id}`]: { id: id, status: data },
    }));
    const { res, err } = await editActivityAvilabilty(id, {
      dive_activity: {
        is_available: data,
      },
    });
  };

  const handelDeleteActivity = async (id) => {
    setOpenLoader(true);
    const { res, err } = await deleteActivity(id);
    if (res) {
      setActivitiesList((prevList) =>
        prevList.filter((item) => item.id !== id)
      );
    }
    setOpenLoader(false);
  };

  const handelNav = (id) => {
    Navigate(`/activities/activity-groups/${id}?page=${pageNumber}`);
  };

  const indxs = {
    live_aboard: 2,
    dive_trip: 0,
    dive_course: 1,
  };

  return (
    <>
      <Grid container>
        <Grid container className="table-header mb-20px">
          <Grid md={1} textAlign="center">
            {`${t("activity.ID")}.`}
          </Grid>
          <Grid md={1.3}>{t("activity.name")}</Grid>
          <Grid sm={1.5}>{t("activity.type")}</Grid>
          <Grid sm={2}>{t("activity.branch")}</Grid>
          <Grid sm={1.5}>{t("activity.price")}</Grid>
          <Grid sm={1.5}>{t("reservations.activityGroups")}</Grid>
          <Grid sm={2.5} style={{ padding: "0 20px" }}>
            {t("activity.action")}
          </Grid>
        </Grid>

        {activitesList?.length === 0 && (
          <Grid container display="block" textAlign="center">
            {t("activity.noActivites")}
          </Grid>
        )}

        <Grid container className="table-items-container">
          {activitesList?.map((activityItem, index) => (
            <Grid
              container
              lg={12}
              className="activity-item mb-20px"
              key={index}
              onClick={() =>
                setSelectedActivity({ activity: activityItem, index: index })
              }
              sx={{
                cursor: "default",
              }}
            >
              <Grid md={1} textAlign="center">
                {activityItem?.id}
              </Grid>
              <Grid
                md={1.3}
                style={{
                  fontWeight: "500",
                }}
                sx={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() =>
                  Navigate(
                    "/activities/details/" +
                      activityItem?.id +
                      "?page=" +
                      pageNumber
                  )
                }
              >
                {activityItem?.localized_data?.[selectedLang]?.name || "-"}
              </Grid>

              <Grid sm={1.5}>
                {activityItem?.activity_type?.replace("_", " ")}
              </Grid>

              <Grid sm={2}>"{activityItem?.branch?.name}"</Grid>

              <Grid sm={1.5}>
                {activityItem?.price}
                {activityItem?.currency?.lookup_key}
              </Grid>

              <Grid
                sm={1.5}
                sx={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  fontWeight: "700 !important",
                }}
                onClick={() => handelNav(activityItem?.id)}
              >
                {activityItem?.number_of_groups}
              </Grid>

              <Grid container sm={2.5} alignItems={'flex-start'}>
                <Grid xs={3}>
                  {userType === "descendant_user" &&
                    activitiesPrivilages?.can_update && (
                      <SwitcheLabel
                        checked={
                          isAvilable[`${activityItem?.id}`]?.id ==
                          activityItem?.id
                            ? isAvilable[`${activityItem?.id}`]?.status
                            : activityItem?.is_available
                        }
                        style={{ margin: "auto", padding: "12px" }}
                        change={(data) =>
                          handelChangeAvilablty(data, activityItem?.id)
                        }
                      />
                    )}
                  {userType !== "descendant_user" && (
                    <SwitcheLabel
                      checked={
                        isAvilable[`${activityItem?.id}`]?.id ==
                        activityItem?.id
                          ? isAvilable[`${activityItem?.id}`]?.status
                          : activityItem?.is_available
                      }
                      style={{ margin: "auto", padding: "12px" }}
                      change={(data) =>
                        handelChangeAvilablty(data, activityItem?.id)
                      }
                    />
                  )}
                </Grid>
                <Grid xs={3} textAlign="center">
                  {userType === "descendant_user" &&
                    activitiesPrivilages?.can_update && (
                      <PrimaryButton
                        btnType="icon"
                        startIcon={
                          <img width="35" src={icons.EditIcon} alt="edit" />
                        }
                        click={() =>
                          Navigate(
                            `/activities/edit-activity/${
                              activityItem?.id
                            }?type=${
                              indxs[activityItem?.activity_type]
                            }&page=${pageNumber}`
                          )
                        }
                      />
                    )}
                  {userType !== "descendant_user" && (
                    <PrimaryButton
                      btnType="icon"
                      startIcon={
                        <img width="35" src={icons.EditIcon} alt="edit" />
                      }
                      click={() =>
                        Navigate(
                          `/activities/edit-activity/${activityItem?.id}?type=${
                            indxs[activityItem?.activity_type]
                          }&page=${pageNumber}`
                        )
                      }
                    />
                  )}
                </Grid>

                {userType === "descendant_user" &&
                  activitiesPrivilages?.can_delete && (
                    <PrimaryButton
                      btnType="icon"
                      startIcon={
                        <img width="35" src={icons.DeleteIcon} alt="delete" />
                      }
                      click={() => setOpenDeleteModel(true)}
                    />
                  )}
                {userType !== "descendant_user" && (
                  <PrimaryButton
                    btnType="icon"
                    startIcon={
                      <img width="35" src={icons.DeleteIcon} alt="delete" />
                    }
                    click={() => setOpenDeleteModel(true)}
                  />
                )}
              </Grid>
            </Grid>
          ))}
          {count > 10 && (
            <Grid container justifyContent="center" mb={7.5} mt={5}>
              <CustomPagination
                count={Math.ceil(count / 10)}
                size="large"
                shape="rounded"
                page={page}
                className="pagination"
                updatePagination={updatePagination}
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      {openDeleteModel && (
        <DeleteModal
          open={openDeleteModel}
          setOpen={setOpenDeleteModel}
          handelDeleteRequest={() => {
            handelDeleteActivity(SelectedActivity?.activity?.id);
            setOpenDeleteModel(false);
          }}
          // text={[t("activity.deleteActivity"), t("activity.deleteActivityMsg")]}
          text={[
            `${t("users.delete")} ${SelectedActivity?.activity?.name}`,
            t("activity.deleteActivityMsg"),
          ]}
          buttonText={[t("offer.cancel"), t("offer.delete")]}
        />
      )}
    </>
  );
};

export default withTranslation()(ListActivities);
