import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";
import { makeFilterString } from "../Helper/Helper.js";

export async function getActivitiesList(filter_obj) {
  return await axiosFn("get", URL.activities + makeFilterString(filter_obj))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function createNewActivity(activity_data) {
  return await axiosFn("post", URL.activities, activity_data)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function editActivity(activityId, activity_data, navigate) {
  return await axiosFn("put", URL.activities + "/" + activityId, activity_data)
    .then((res) => {
      navigate("/activities?page=1");
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function editActivityAvilabilty(activityId, activity_data) {
  return await axiosFn(
    "put",
    URL.activities + "/" + activityId + "/availability",
    activity_data
  )
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function getActivityDetails(activityId) {
  return await axiosFn("get", URL.activities + "/" + activityId)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function deleteActivity(activityId) {
  return await axiosFn("delete", `${URL.activities}/${activityId}`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
