import { Grid, Typography, Divider, Stack } from "@mui/material";
import { icons } from "../../../../Assets/AssetHelper.js";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { deleteActivity } from "../../../../Contexts/APIs/Activities/Activities.js";
import { useState } from "react";

const ActivityDetailsHeader = (props) => {
  const { activityDetails, t, activitiesPrivilages, language, type } = props;
  const Navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  let status = searchParams.get("status");
  const userType = localStorage.getItem("userType");
  const lang = localStorage.getItem("lang");
  const { pathname } = useLocation();
  const [isLoading, setOpenLoader] = useState();
  const handelDeleteActivity = async (id) => {
    setOpenLoader(true);
    const { res, err } = await deleteActivity(id);
    if (res) {
      Navigate(`/activities?page=${pageNumber}`);
    }
    setOpenLoader(false);
  };
  const states = {
    pending: "#F6B119",
    rejected: "#E10007",
  };
  return (
    <Grid container>
      <Grid container lg={11} margin="auto" padding="20px 0">
        <Grid container md={9}>
          <Grid container md={10}>
            <Typography
              fontWeight="bold"
              component="h1"
              fontSize="20px"
              mr="25px"
            >
              {activityDetails?.name == null
                ? `'"${t("activity.activityName")}"'`
                : activityDetails?.localized_data
                ? activityDetails?.localized_data[language]?.name
                : activityDetails?.name}
            </Typography>
            <Stack
              flexDirection="row"
              gap={"5px"}
              alignItems="center"
              style={{ cursor: "pointer" }}
              onClick={() =>
                Navigate(
                  `/activity-reviews/${activityDetails?.id}?page=${pageNumber}`
                )
              }
            >
              <Typography
                fontSize="20px"
                fontWeight="normal"
                color="#3ed181"
                style={{ textDecoration: "underline" }}
              >
                {t("profile.ratingAndReview")}
              </Typography>
              <Typography fontSize="20px" fontWeight="600" color="#3ed181">
                {" "}
                {activityDetails?.reviews_count}{" "}
              </Typography>
              <Typography fontSize="20px" fontWeight="600" color="#3ed181">
                {activityDetails?.reviews_count === 1 ? " review" : " reviews"}
              </Typography>
            </Stack>
          </Grid>
          {type == "requested-activites" && (
            <Grid container md={2} fontWeight="600">
              Stauts :{" "}
              <span
                style={{
                  color: states[activityDetails?.status],
                  textTransform: "capitalize",
                  marginInline: "5px",
                }}
              >
                {" "}
                {activityDetails?.status}
              </span>
            </Grid>
          )}
          <Grid container md={12}>
            <Typography>
              <Typography
                component="span"
                style={{
                  fontWeight: "bold",
                  color: "var(--primary-color)",
                  fontSize: "22px",
                }}
              >
                {`${
                  activityDetails?.price
                } ${activityDetails?.currency?.lookup_key?.toUpperCase()}`}
              </Typography>
              <Typography
                component="span"
                style={{ color: "#848383", fontSize: "15px", margin: "0 20px" }}
              >
                {`${activityDetails?.duration_days} ${t("activity.days")} . ${
                  activityDetails?.no_of_dives
                } ${t("activity.dives")}  . ${activityDetails?.capacity} ${t(
                  "activity.reservations"
                )} `}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
        {pathname.includes("activities") && (
          <Grid container md={3} paddingTop="10px">
            <Grid md={8}></Grid>
            <Grid md={2}>
              {userType === "descendant_user" && (
                <Stack flexDirection="row">
                  {activitiesPrivilages?.can_update && (
                    <PrimaryButton
                      btnType="icon"
                      startIcon={
                        <img width="35" src={icons.EditIcon} alt="edit" />
                      }
                      click={() =>
                        Navigate(
                          `/activities/edit-activity/${activityDetails?.id}?page=${pageNumber}`
                        )
                      }
                    />
                  )}
                  {activitiesPrivilages?.can_delete && (
                    <Grid md={2}>
                      <PrimaryButton
                        btnType="icon"
                        startIcon={
                          <img width="35" src={icons.DeleteIcon} alt="delete" />
                        }
                        click={() => handelDeleteActivity(activityDetails?.id)}
                      />
                    </Grid>
                  )}
                </Stack>
              )}
              {userType !== "descendant_user" && (
                <Stack flexDirection="row">
                  <PrimaryButton
                    btnType="icon"
                    startIcon={
                      <img width="35" src={icons.EditIcon} alt="edit" />
                    }
                    click={() =>
                      Navigate(
                        `/activities/edit-activity/${activityDetails?.id}?page=${pageNumber}`
                      )
                    }
                  />

                  <Grid md={2}>
                    <PrimaryButton
                      btnType="icon"
                      startIcon={
                        <img width="35" src={icons.DeleteIcon} alt="delete" />
                      }
                      click={() => handelDeleteActivity(activityDetails?.id)}
                    />
                  </Grid>
                </Stack>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
      <Divider style={{ width: "100%" }} />
    </Grid>
  );
};

export default withTranslation()(ActivityDetailsHeader);
