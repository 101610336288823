import { Grid, Typography } from '@mui/material'
import React from 'react'
import { withTranslation } from 'react-i18next'

const PointsConfig = ({t , pointConfig}) => {
    const compData = {
        "dive_center_freelancer_work" : {
            title : t("points.pointsFromFreelance") , 
            desc : t("points.forEveryFreelancer")},
        "dive_center_client_reservation":{
            title :   t("points.pointsFromCustomers") ,  
            desc : t("points.fromEveryCustomer")}
    }

  return (
    <Grid container item md={12}>
            <Typography
            fontSize= '16px'
            fontWeight= 'bold'>
            {compData[pointConfig?.point_type]?.title}
           </Typography>
            <Grid container className="table-item mb-20px mt-10px" sx={{px:2}}>
            - {t('points.gain')} <span style={{color:'#f6b119' , marginInline:'4px'}}> {pointConfig?.num_of_points} {t('points.points')}</span>      {compData[pointConfig?.point_type]?.desc}
            </Grid>
    </Grid>
   
  )
}
 
export default withTranslation()(PointsConfig)