import { Grid, Typography, Divider } from "@mui/material";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput.jsx";
import { withTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import PhoneInput from "../../../Atoms/Input/PhoneInput/PhoneInput.jsx";
import en from "react-phone-number-input/locale/en.json";
import { useState } from "react";
// import PhoneInput from "react-phone-input-2";
import "./Information.css";
import ReactPhoneInput2 from "../../../Atoms/Input/PhoneInput/ReactPhoneInput2.jsx";
import SearchableSelect from "../../../Atoms/Select/SearchableSelect.jsx";

const Information = (props) => {
  const {
    getOrganization,
    branchesList,
    formik,
    currencies,
    supportedLanguages,
    supportLang,
    getBranchInformation,
    getCurrentLocation,
    getBranches,
    branchesCount,
    profileData,
    formType,
    t,
  } = props;
  const isAssignedToOneBranch = profileData?.branch?.name;
  const [searchParams] = useSearchParams();

  return (
    <Grid container className="activity-form">
      <Grid container>
        <Typography
          component="h1"
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            borderBottom: "2px solid var(--primary-color)",
          }}
          className="mb-20px"
        >
          {t("salesmen.salesmanInformation")}
        </Typography>
      </Grid>

      <Grid container className="mb-10px" gap={1} alignItems={"center"}>
        <Grid item sm={5.8}>
          <TextFieldInput
            name="name"
            id="fullName"
            placeholder={t("salesmen.fullName")}
            className="login-input"
            type="text"
            value={formik.values.name}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid sm={5.8}>
          <SearchableSelect
            name="branch_name"
            options={branchesList}
            getList={getBranches}
            handelMenuOpen={getBranches}
            count={branchesCount}
            type="branch"
            // defaultValues={activityDetails?.branch}
            label={t("branch.branchName")}
            className="searchable-select searchable-select-branches"
            disabled={isAssignedToOneBranch}
            value={{
              value: formik?.values?.branch_id,
              label: formik.values.branch_name,
            }}
            error={
              formik.touched.branch_name && Boolean(formik.errors.branch_name)
            }
            helperText={formik.touched.branch_name && formik.errors.branch_name}
            onChange={(val) => {
              formik.setValues({
                ...formik?.values,
                branch_id: val?.value,
                branch_name: val?.label,
              });
            }}
            onClear={() => {
              formik.setValues({
                ...formik?.values,
                branch_id: "",
                branch_name: "",
              });
            }}
          />
        </Grid>
      </Grid>

      <Grid container mb={"4px"} gap={1} alignItems="baseline">
        <Grid container item sm={5.8} alignItems="center">
          <TextFieldInput
            name="email"
            id="email"
            placeholder={`${t("salesmen.emailAddress")}`}
            className="login-input"
            type="email"
            value={formik.values.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid container item sm={5.8} alignItems="center">
          <ReactPhoneInput2
            formik={formik}
            id="phone"
            name="phone_number"
            customClass="normal-textField login-input salesman-phoneinput"
            customContainerClass="salesman-phoneinput-container"
            placeholder={`${t("users.phoneNumber")}`}
            specialLabel={`${t("users.phoneNumber")}`}
            phoneProperty="phone_number"
            codeProperty="country_code"
            disableDropdown={true}
            showBorder={true}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(Information);
