import React from "react";
import SalesmenReservationsTemplate from "../../../../Templates/Main/Salesmen/SalesmenReservations/SalesmenReservations.Template";
import { useState } from "react";
import Loader from "../../../../Components/Atoms/Loader/Loader";
import { useEffect } from "react";
import { getBranchesList } from "../../../../Contexts/APIs/Branches/Branches";
import { getSalesmanReservationsList } from "../../../../Contexts/APIs/Salsemen/SalesmenReservations";
import { getSalesmenList } from "../../../../Contexts/APIs/Salsemen/Salesmen";
import { useLocation } from "react-router-dom";

const SalesmenReservationsPage = ({ salesmenReservationsPrivilages,profileData }) => {
  const [count, setCount] = useState();
  const [openLoader, setOpenLoader] = useState(false);
  const [reservationsList, setReservationsList] = useState([]);
  const [branchesList, setBranchesList] = useState([]);
  const [branchesCount, setBranchesCount] = useState(10);

  const [salesmenList, setSalesmenList] = useState([]);
  const [salesmenCount, setSalesmenCount] = useState(10);

  const userId = localStorage.getItem("userId");
  const userType = localStorage.getItem("userType");
  const ancestorId = localStorage.getItem("ancestorId");
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState({
    page_size: 10,
    page_number: 1,
    sort_by: "id",
    activity_type: "",
    keyword: "",
  });

  const statusList = [
    { name: "Pending", id: "pending" },
    { name: "Confirmed", id: "confirmed" },
    { name: "Cancelled", id: "cancelled" },
  ];

  const { state } = useLocation();

  const updatePagination = (page) => {
    setFilterObj({ ...filterObj, page_number: page });
  };

  const handlePageChange = (event, value) => {
    updatePagination(value);
    setPage(value);
  };

  const getReservations = async (filterObj) => {
    setOpenLoader(true);
    const { res, error } = state
      ? await getSalesmanReservationsList({
          ...filterObj,
          inhouse_activity_group_id: state.assignedActivityGroupId,
        })
      : await getSalesmanReservationsList(filterObj);
    if (res) {
      setOpenLoader(false);
      setReservationsList(res?.data?.salesman_reservations);
      setCount(res?.extra?.total_count);
    }
    if (error) {
      setOpenLoader(false);
    }
  };

  const getBranches = async (filter) => {
    const filterData = {
      page_size: "10",
      page_number: 1,
      dive_center_id: userType == "descendant_user" ? ancestorId : userId,
      ...filter,
    };
    const { res, err } = await getBranchesList({
      ...filterData,
    });
    if (res) {
      filterData?.page_number == 1
        ? setBranchesList(res?.data?.branches)
        : setBranchesList((s) => [...s, ...res?.data?.branches]);
      setBranchesCount(res?.extra?.total_count);
    }
  };

  const getSalesmenListing = async (filter) => {
    setOpenLoader(true);
    const filterData = {
      page_number: "1",
      page_size: "10",
      ...filter,
    };
    const { res, err } = await getSalesmenList({
      ...filterData,
    });
    if (res) {
      filterData?.page_number == 1
        ? setSalesmenList(res?.data?.salesmen)
        : setSalesmenList((s) => [...s, ...res?.data?.salesmen]);

      setSalesmenCount(res?.extra?.total_count);
    }
    if (err) {
    }
    setOpenLoader(false);
  };

  const updateSalesmenReservationsList = (name, value) => {
    filterObj[name] != value &&
      setFilterObj({ ...filterObj, page_number: 1, [name]: value });
    setPage(1);
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      getReservations(filterObj);
    }, 700);
    return () => clearTimeout(timeId);
  }, [filterObj, state]);

  return (
    <div style={{ overflowX: "scroll" }}>
      <Loader open={openLoader} />
      <SalesmenReservationsTemplate
        count={count}
        reservationsList={reservationsList}
        branchesList={branchesList}
        salesmenList={salesmenList}
        salesmenCount={salesmenCount}
        statusList={statusList}
        setOpenLoader={setOpenLoader}
        branchesCount={branchesCount}
        page={page}
        handlePageChange={handlePageChange}
        updateSalesmenReservationsList={updateSalesmenReservationsList}
        getReservations={getReservations}
        salesmenReservationsPrivilages={salesmenReservationsPrivilages}
        getBranches={getBranches}
        getSalesmenListing={getSalesmenListing}
        profileData={profileData}
        filterObj={filterObj}
        setFilterObj={setFilterObj}
      />
    </div>
  );
};

export default SalesmenReservationsPage;
