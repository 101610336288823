import { Grid, Typography, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import CloseIcon from '@mui/icons-material/Close';
import CustomPagination from "../../../Atoms/Pagination/Pagination.js";
import { useState } from "react";
import CheckIcon from '@mui/icons-material/Check';
import { withTranslation } from "react-i18next";

const FreelancersRequests = ({t}) => {
    // const Navigate = useNavigate();
    // const { count, updatePagination, list, updatePayment } = props;

    const [page, setPage] = useState(1);

    // const handlePageChange = (event, value) => {
    //     setPage(value);
    //     updatePagination(value);
    // };

    return (
        <Grid container>
            <Grid container className="table-header mb-20px">
                <Grid md={0.5} textAlign="center">{t("reservations.id")}</Grid>
                <Grid md={1.5}>{t("reservations.name")}</Grid>
                <Grid sm={1.5}>{t("joboffer.freelancerName")}</Grid>
                <Grid sm={1.5}>{t("reservations.type")}</Grid>
                <Grid sm={2}>{t("reservations.dateTime")}</Grid>
                <Grid sm={1.5}>{t("profile.branch")}</Grid>
                <Grid sm={1.5}>{t("reservations.seats")}</Grid>
                <Grid sm={2}>{t("reservations.action")}</Grid>
            </Grid>

            {[0, 1, 2, 3, 4, 5].map((activityItem, index) => (
                <Grid container lg={12}
                    className="activity-item mb-20px"
                    key={index}
                >
                    <Grid md={0.5} textAlign="center">1</Grid>
                    <Grid md={1.5}>"Course name"</Grid>
                    <Grid sm={1.5}>"Freelancer name"</Grid>
                    <Grid sm={1.5}>Course</Grid>
                    <Grid sm={2}>Sep 20, 2021 / 2 pm</Grid>
                    <Grid sm={1.5}>"Branch name"</Grid>
                    <Grid sm={1.5}>23</Grid>
                    <Grid container sm={2}>
                        <Grid xs={8}>
                            <PrimaryButton
                                text="Confirm"
                                classBtn="primary"
                                style={{ padding: "5px 25px" }}
                            />
                        </Grid>
                        <Grid xs={4}>
                            <PrimaryButton
                                btnType="icon"
                                startIcon={<CloseIcon color="var(--error-color)" />}
                                classBtn="cancel-btn"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            ))}
            {/* {count > 10 && */}
                <Grid container justifyContent='center' mb={7.5} mt={5}>
                    <CustomPagination
                        count={Math.ceil(32 / 10)}
                        size='large'
                        shape="rounded"
                        page={page}
                        // handleChange={handlePageChange}
                        className='pagination'
                    />
                </Grid>
            {/* } */}
        </Grid>
    )
}

export default withTranslation()(FreelancersRequests);