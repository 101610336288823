import { Grid, Typography, Divider } from "@mui/material";
import "./Form.css";
import SwitcheLabel from "../../Atoms/Buttons/Switch/SwitchButton.js";
import { useState, useEffect } from "react";
import { removeObjectWithId } from "../../../Contexts/APIs/Helper/Helper.js";

const SwitchesForm = (props) => {
    const { formTitle, options, type, getFeaturesOptions, updateFeatureOptions, defaultData } = props;

    const [switchValue, setSwitchValue] = useState([]);
    const [filterObj, setFilterObj] = useState({
        facility_type: "boat"
    });
    const [featuresOptions, setFeaturesOptions] = useState([]);

    const selectFeature = (value, i, id) => {
        let newData = [...switchValue]
        if (value?.target?.checked) {

            const checkArr = newData?.filter((val) => val?.feature_option_id == id).filter((val) => val)
            if (checkArr?.length == 0) {
                newData.push(
                    {
                        "feature_option_id": id
                    }

                )
                setSwitchValue(newData.filter((val) => val));
                setFeaturesOptions(newData.filter((val) => val));
            } else {

                const filtered = newData?.filter((val) => val?.feature_option_id != id)
                setSwitchValue(filtered);
                setFeaturesOptions(filtered.filter((val) => val));
            }

        } else {

            const filtered = newData?.filter((val) => val?.feature_option_id != id)
            setSwitchValue(filtered);
            setFeaturesOptions(filtered.filter((val) => val));
        }


    };


    useEffect(() => {
        if (type === 'safari_boat') {
            setFilterObj({ facility_type: "boat" });
        } else {
            setFilterObj({ facility_type: "office" });
        }
    }, []);

    useEffect(() => {
        getFeaturesOptions(filterObj);
    }, [filterObj])



    useEffect(() => {
        updateFeatureOptions(featuresOptions);
    }, [featuresOptions, removeObjectWithId])
    useEffect(() => {

        setSwitchValue(defaultData)
    }, [defaultData])
    return (
        <Grid container lg={12} className="activity-form">
            <Grid container md={12}>
                <Typography
                    component="h1"
                    style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        borderBottom: "2px solid var(--primary-color)"
                    }}
                    className="mb-20px"
                >
                    {formTitle}
                </Typography>
            </Grid>

            {options?.map((option, index) => {
                const data = switchValue?.filter((val) =>
                    val?.feature_option_id == option?.id
                )
                return <Grid container md={12} className="mb-30px" key={index} >
                    <Grid md={11}>
                        <Typography fontWeight="bolder">{option?.name}</Typography>
                    </Grid>
                    <Grid md={1}>
                        <SwitcheLabel
                            checked={data?.length > 0 ? true : false}
                            onChange={(val) => selectFeature(val, index, option?.id)}
                        />
                    </Grid>
                </Grid>
            })}
        </Grid>
    )
}

export default SwitchesForm