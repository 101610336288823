import { Grid, Typography } from "@mui/material";
import { withTranslation } from "react-i18next";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput.jsx";
import "./Form.css";

const Generators = (props) => {
    const {t, formik } = props;

    return (
        <Grid container lg={12} className="activity-form">
            <Grid container md={12}>
                <Typography 
                    component="h1" 
                    style={{
                        fontSize: "20px", 
                        fontWeight: "bold", 
                        borderBottom: "2px solid var(--primary-color)"
                    }}
                    className="mb-20px"
                >
                    {t('branch.generators')}
                </Typography>
            </Grid>
            <Grid container md={12} className="mb-20px">
                <Grid sm={5.8}>
                    <TextFieldInput
                        name="generator_type"
                        id="generator_type"
                        placeholder={t('branch.generatorType')}
                        className='login-input'
                        type="text"
                        value={formik.values.generator_type}
                        error={formik.touched.generator_type && Boolean(formik.errors.generator_type)}
                        helperText={formik.touched.generator_type && formik.errors.generator_type}
                        onChange={formik.handleChange}
                    />
                </Grid>
                <Grid sm={0.4}></Grid>
                <Grid sm={5.8}>
                    <TextFieldInput
                        name="kilo_watt"
                        id="kilo_watt"
                        placeholder={t('branch.kiloWatt')}
                        className='login-input'
                        type="text"
                        value={formik.values.kilo_watt}
                        error={formik.touched.kilo_watt && Boolean(formik.errors.kilo_watt)}
                        helperText={formik.touched.kilo_watt && formik.errors.kilo_watt}
                        onChange={formik.handleChange}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default withTranslation()(Generators)