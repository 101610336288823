import FreelancersTemplate from "../../../Templates/Main/Reservations/Freelancers.template.jsx";
import Loader from "../../../Components/Atoms/Loader/Loader.js";

const FreelancersPage = (props) => {
    const { t } = props;
    
    return (
        <>
            <FreelancersTemplate/>
        </>
    )
}

export default FreelancersPage