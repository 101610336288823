import { axiosFn, handleError } from "../index";

export async function completeAccount(user_type, data, dive_center_id) {
    return await axiosFn("put", user_type + `/${dive_center_id}`, data)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}