import React from "react";
import {
  addToFavoriate,
  deleteFavoriate,
} from "../../../../Contexts/APIs/Freelancers/Freelancers";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
const Favourate = ({
  freelancer,
  freelancerData,
  setData,
  setLoading,
  index,
  canCreate,
  canDalete,
}) => {
  return !freelancer?.is_favourite_by_current_user ? (
    <PrimaryButton
      btnType="icon"
      style={{
        border: "1px solid #80808014",
        borderRadius: "10px",
        background: "#80808014",
        display: canCreate ? "block" : "none",
      }}
      startIcon={
        <FavoriteIcon style={{ fontSize: "20px", color: "gray.200" }} />
      }
      click={async () => {
        const action = async () => {
          let newData = await freelancerData;
          newData[index].is_favourite_by_current_user = true;
          setData(newData);
        };
        const data = {
          favourite: {
            favourite_id: freelancer?.id,
            favourite_type: "DiveExpert", // DiveActivity, DiveCenter, DiveExpert
          },
        };
        addToFavoriate(data, action, setLoading);
      }}
    />
  ) : (
    <PrimaryButton
      btnType="icon"
      style={{
        border: "1px solid #80808014",
        borderRadius: "10px",
        background: "#80808014",
        display: canDalete ? "block" : "none",
      }}
      startIcon={<FavoriteIcon style={{ fontSize: "20px", color: "red" }} />}
      click={async () => {
        const action = async () => {
          let newData = await freelancerData;
          newData[index].is_favourite_by_current_user = false;
          setData(newData);
        };
        const data = {
          favourite: {
            favourite_id: freelancer?.id,
            favourite_type: "DiveExpert", // DiveActivity, DiveCenter, DiveExpert
          },
        };
        deleteFavoriate(data, action, setLoading);
      }}
    />
  );
};

export default Favourate;
