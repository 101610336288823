import { useEffect, useState } from "react";
import Loader from "../../../Components/Atoms/Loader/Loader.js";
import RequestedActivitiesTemplate from "../../../Templates/Main/RequestedActivity/RequestedActivities.template.jsx";
import { getRequestedActivitiesList } from "../../../Contexts/APIs/RequestedActivities/Activities.js";
import { Box } from "@mui/material";

const RequestedActivitiesPage = (props) => {
  const { t, profileData, requestedActivitiesPrivilages } = props;
  const [activitesList, setActivitiesList] = useState([]);
  const [count, setCount] = useState();
  const [openLoader, setOpenLoader] = useState(true);

  const ActivitiesList = async (filterObj) => {
    setOpenLoader(true);
    const { res, err } = await getRequestedActivitiesList(filterObj);
    if (res) {
      setActivitiesList(res?.data?.activity_requests);
      setCount(res?.extra?.total_count);
    }
    if (err) {
    }
    setTimeout(() => {
      setOpenLoader(false);
    }, 400);
  };
  return (
    <Box
      width="100%"
      sx={{
        overflowX: "scroll",
        minHeight: "100vh",
      }}
    >
      <Loader open={openLoader} />
      <RequestedActivitiesTemplate
        activitesList={activitesList}
        setActivitiesList={setActivitiesList}
        count={count}
        getActivities={ActivitiesList}
        requestedActivitiesPrivilages={requestedActivitiesPrivilages}
        setOpenLoader={setOpenLoader}
        profileData={profileData}
      />
    </Box>
  );
};

export default RequestedActivitiesPage;
