import { Divider, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { icons } from '../../../../../Assets/AssetHelper'
import PrimaryButton from '../../../../Atoms/Buttons/Primary/PrimaryButton'

const ProfileRejected = ({ t }) => {
    const navigate = useNavigate()
    return (
        <Stack alignItems='center' justifyContent='center' height='100vh'>
            <Grid container className='activity-form' bgcolor={'white'} alignItems='center' justifyContent='center' width={'50%'}>
                <Grid className="mb-20px" >
                    <img src={icons.LoginLogo} alt="Logo" />
                </Grid>
                <Grid className="mb-30px" >
                    <Typography component="h1" style={{ fontSize: "27px", color: "#333132", fontWeight: 'bold', textAlign: 'center' }}>
                        Your account got rejected please update your information
                    </Typography>
                    <Typography style={{ fontWeight: 'bold', fontSize: "18px", color: "#e10007", marginTop: '10px', textAlign: 'center', textDecoration: 'underline' }}>
                        Rejection reasons
                    </Typography>
                    <Typography style={{ fontSize: "18px", color: "#333132", marginTop: '10px', textAlign: 'center' }}>
                        {localStorage.getItem('Msg')}
                    </Typography>
                </Grid>
                <Divider style={{ background: '#d9d9d9', width: '100%' }} />

                <Stack width='100%' direction='row' alignItems='center' justifyContent='center' mt={2} gap={2} >
                    <PrimaryButton
                        text="Cancel"
                        classBtn="white"
                        style={{ padding: "5px 50px", border: '1px solid #d9d9d9', color: 'black', borderRadius: '18px' }}
                        click={() => navigate('/login')}
                    />
                    <PrimaryButton
                        text="Complete you profile"
                        classBtn="primary"
                        style={{ padding: "5px 50px" }}
                        click={() => navigate('/complete-account?status=recomplete')}
                    />
                </Stack>
            </Grid >
        </Stack >


    )
}

export default ProfileRejected