import AddReservationTemplate from "../../../Templates/Main/Reservations/AddReservation.template.jsx";
import { searchForUsers } from "../../../Contexts/APIs/Users/Users.js";
import { useState, useEffect } from "react";
import {
  getActivityGroups,
  createNewReservation,
} from "../../../Contexts/APIs/Reservations/Reservations.js";
import Loader from "../../../Components/Atoms/Loader/Loader.js";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

const AddReservationPage = (props) => {
  const { t } = props;
  const [usersList, setUsersList] = useState([]);
  const { id } = useParams();
  const [activityGroups, setActivityGroups] = useState([]);
  const [activityInfo, setActivityInfo] = useState({});
  const [openLoader, setOpenLoader] = useState(false);
  const Navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  const searchUsers = async (keyword) => {
    const filterObj = {
      keyword: keyword,
      page_number: 1,
      page_size: 100,
    };
    const { res, err } = await searchForUsers("customer", filterObj);
    if (res) {
      setUsersList(res?.data?.users);
    }
  };

  const createReservation = async (
    user_id,
    activity_group_id,
    users_list,
    quantity
  ) => {
    setOpenLoader(true);
    const reservationData = {
      cart_item: {
        id: null,
        quantity: quantity,
      },
      reservation_participants: users_list,
    };
    const { res, err } = await createNewReservation(
      activity_group_id,
      user_id,
      reservationData
    );
    if (res) {
      Navigate(
        `/activities/activity-groups/reservations-list/${activity_group_id}?page=${pageNumber}`
      );
    }
    setOpenLoader(false);
  };
  const GetActivityGroups = async () => {
    const { res, err } = await getActivityGroups(id, {});
    if (res) {
      setActivityGroups(res?.data?.activity_groups);
      setActivityInfo(res?.data?.activity);
    }
    if (err) {
    }
  };

  useEffect(() => {
    GetActivityGroups();
  }, []);
  return (
    <>
      <Loader open={openLoader} />
      <AddReservationTemplate
        searchUsers={searchUsers}
        usersList={usersList}
        activityGroups={activityGroups}
        createReservation={createReservation}
      />
    </>
  );
};

export default AddReservationPage;
