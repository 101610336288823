import { Grid, Typography } from "@mui/material";
import "./Form.css";
import SelectBox from "../../Atoms/Select/Select.jsx";
import { withTranslation } from "react-i18next";

const LanguagesSelect = (props) => {
    const { t, supportedLanguages, value, onChange } = props;
    return (
        <Grid container md={12} item flexWrap='wrap' >
            <Grid container xs={12} md={4} item flexDirection='row' minWidth={{md: '29rem'}}>
                <Grid item md={5} maxWidth={'fit-content !important'}>
                    <Typography lineHeight="3.5" fontWeight="bold" component="p" width="fit-content">
                        {t("profile.DataSLang")}
                    </Typography>
                </Grid>
                <Grid container item xs={4} md={7} width='auto' pl={2}>
                    <Grid item  xs={12} md={10} margin="auto">
                        <SelectBox
                            id="language"
                            name="language"
                            items={supportedLanguages}
                            title={t('general.selectLanguage')}
                            className="select"
                            value={value}
                            onchange={onChange}
                        />
                        {/* convert normal select to searchable */}
                        {/* <SearchableSelect
                            name="language"
                            options={supportedLanguages}
                            label={"Select Language"}
                            className="select searchable-select-lang"
                            onChange={onChange}
                            value={value}
                        /> */}
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} md={8} maxWidth={'fit-content !important'}>
                <Typography lineHeight="3.5" fontWeight="bold" component="p">
                    <Typography style={{ color: "red" }} component="span">{`${t("profile.tip")} : `}</Typography>
                    {t("profile.langTipMsg")}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default withTranslation()(LanguagesSelect)