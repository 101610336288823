import { Grid, Typography } from "@mui/material";
import AddActivityForm from "../../../Components/Organisms/Activities/AddActivityForm.jsx";

const AddActivityTemplate = (props) => {
  const {
    t,
    uploadActivityImages,
    supportedLanguages,
    branchesList,
    currencies,
    getOrganization,
    organizationsCount,
    organizationsList,
    setOrganizationsList,
    languagesCount,
    certificatesList,
    setCertificatesList,
    languagesList,
    getLanguagesAPI,
    priceIncludesList,
    activityImages,
    removeActivityImage,
    getListOfPrices,
    createNewActivity,
    type,
    activityDetails,
    ActivitiesTypes,
    getCertificates,
    getBranches,
    branchesCount,
    certificatesCount,
    profileData
  } = props;

  return (
    
        <AddActivityForm
          ActivitiesTypes={ActivitiesTypes}
          uploadActivityImages={uploadActivityImages}
          supportedLanguages={supportedLanguages}
          branchesList={branchesList}
          getOrganization={getOrganization}
          organizationsCount={organizationsCount}
          branchesCount={branchesCount}
          certificatesCount={certificatesCount}
          currencies={currencies}
          organizationsList={organizationsList}
          setOrganizationsList={setOrganizationsList}
          getBranches={getBranches}
          certificatesList={certificatesList}
          setCertificatesList={setCertificatesList}
          languagesList={languagesList}
          getLanguagesAPI={getLanguagesAPI}
          languagesCount={languagesCount}
          priceIncludesList={priceIncludesList}
          activityImages={activityImages}
          removeActivityImage={removeActivityImage}
          getListOfPrices={getListOfPrices}
          createNewActivity={createNewActivity}
          type={type}
          activityDetails={activityDetails}
          getCertificates={getCertificates}
          profileData={profileData}
        />
      
  );
};

export default AddActivityTemplate;
