import React from 'react'
import ReactDOM from 'react-dom/client'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { I18nextProvider } from "react-i18next"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import './index.css'
import './Assets/fonts/Catamaran/Catamaran-Regular.ttf'
import App from './App'
import reportWebVitals from './reportWebVitals'
import i18n from "./Localization/i18n"
import { ToastContainer } from 'react-toastify'

const theme = createTheme({
  palette: {
    primary: {
      main: '#f6b119',
    },
    secondary: {
      main: '#3ed181',
    },
  },
  typography: {
    fontFamily: [
      'Catamaran'
    ]
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <I18nextProvider i18n={i18n}>
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ToastContainer />
        <App i18n={i18n} />
      </LocalizationProvider>
    </ThemeProvider>
  </I18nextProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
