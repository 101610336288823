import { Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { withTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-input-2'
import TextFieldInput from '../../../../Atoms/Input/TextField/TextFieldInput'
import ReactPhoneInput2 from '../../../../Atoms/Input/PhoneInput/ReactPhoneInput2'

const ContactPersonInfo = ({ t, formik }) => {
    return (
        <Grid container className='activity-form' gap={2} width='100%'>
            <Typography
                component="h1"
                style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    borderBottom: "2px solid var(--primary-color)"
                }}
                className="mb-20px"
            >
                {t('profile.contactPersonInfo')}
            </Typography>
            <Stack flexDirection='row' width='100%' gap={2}>
                <TextFieldInput
                    name="contact_person_name"
                    id="contact_person_name"
                    placeholder={t('profile.contactPersonName')}
                    className='login-input'
                    type="text"
                    onChange={formik?.handleChange}
                    value={formik?.values?.contact_person_name}
                    error={formik?.touched?.contact_person_name && Boolean(formik?.errors?.contact_person_name)}
                    helperText={formik?.touched?.contact_person_name && formik?.errors?.contact_person_name}
                    clickEnter={formik?.handleSubmit}
                />
                <ReactPhoneInput2
                    formik={formik} 
                    name="contact_person_phone_number"
                    customContainerClass="contact-person-phone-input"
                    placeholder={`${t("profile.contactpersonmobile")}`}
                    specialLabel={`${t("profile.contactpersonmobile")}`}
                    phoneProperty="contact_person_phone_number"
                    codeProperty="contact_person_country_code"
                    enableSearch={true}
                    showBorder={true}
                />
            </Stack>
            <TextFieldInput
                name="contact_person_email"
                id="contact_person_email"
                placeholder={t('profile.contactPersonEmailAddress')}
                className='login-input '
                type="email"
                style={{ width: '50%' }}
                onChange={formik?.handleChange}
                value={formik?.values?.contact_person_email}
                error={formik?.touched?.contact_person_email && Boolean(formik?.errors?.contact_person_email)}
                helperText={formik?.touched?.contact_person_email && formik?.errors?.contact_person_email}
                clickEnter={formik?.handleSubmit}
            />
        </Grid>
    )
}

export default withTranslation()(ContactPersonInfo)