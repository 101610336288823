import { useState, useEffect } from 'react';
import { Grid, Avatar, Stack, Typography, Rating, Divider } from "@mui/material";
import CustomPagination from '../../../Atoms/Pagination/Pagination';
import { styled } from '@mui/material/styles';
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
const FreelancerReviewsList = ({ t, count, reviewsList, handleChange, visibleDivider }) => {
    const StyledRating = styled(Rating)({
        '& .MuiRating-iconFilled': {
            color: '#3ed181',
        },
        '& .MuiRating-iconHover': {
            color: '#3ed181',
        },
    });
    const [page, setPage] = useState(1);
    const handlePageChange = (event, value) => {
        setPage(value);
        handleChange('page_number', value);
    };
    return (
        <Grid container>

            {reviewsList?.length === 0 && (
                <Grid container display="block" textAlign="center">
                    {t("offer.thereAreNoRecords")}
                </Grid>
            )}
            <Stack gap={2}
                width='100%'>

                {reviewsList?.map((data, index) => (
                    <Stack
                        key={data?.id}
                        container
                        width='100%'
                        p={1}
                        gap={1.5}
                    >

                        <Stack width='100%' flexDirection='row' justifyContent='space-between'>

                            <Stack width='100%' flexDirection='row' gap={1}>
                                <Avatar src={data?.reviewer_avatar} alt='avatar' style={{ width: '53px', height: '53px', borderRadius: '50%' }} />

                                <Stack>
                                    <Typography fontSize='18px'>{data?.reviewer_name}</Typography>
                                    <StyledRating
                                        name="customized-color"
                                        value={data?.rating}
                                        readOnly={true}
                                        icon={<CircleIcon fontSize="inherit" style={{ fontSize: '12px' }} />}
                                        emptyIcon={<CircleOutlinedIcon fontSize="inherit" style={{ fontSize: '12px', color: '#3ed181' }} />}
                                    />

                                </Stack>
                            </Stack>
                            <Typography color='#bebebe' width='150px'>{moment(data?.created_at).format('DD MMM YYYY')}</Typography>

                        </Stack>
                        <Typography p={1}>{data?.comment}</Typography>
                        { index !== reviewsList?.length -1 && <Divider style={{ width: '100%', marginTop: '7px' }} /> }

                    </Stack>
                ))}
            </Stack>

            {count > 10 &&
                <Grid container justifyContent='center' mb={7.5} mt={5}>
                    <CustomPagination
                        count={Math.ceil(count / 10)}
                        size='large'
                        shape="rounded"
                        page={page}
                        handleChange={handlePageChange}
                        className='pagination'
                    />
                </Grid>
            }
        </Grid>
    );
}

export default withTranslation()(FreelancerReviewsList);