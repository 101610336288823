import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useSearchParams } from "react-router-dom";
import TextFieldInput from "../../Atoms/Input/TextField/TextFieldInput";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import NormalTabs from "../../Atoms/Tabs/NormalTabs";
import { withTranslation } from "react-i18next";
import BranchesFilter from "../../Organisms/Statistics/BranchesFilter";

const OfferHeader = ({
  t,
  filterValue,
  setFilterValue,
  handleChange,
  title = "Offers",
  url = "/offers/add-new-offer",
  is_navigate = true,
  type,
  offersPrivilages,
  filterObj,
  setFilterObj,
  profileData
}) => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  const userType = localStorage.getItem("userType");
  const options = [
    {
      id: 1,
      label: t("offer.all"),
    },
    {
      id: "active",
      label: t("offer.active"),
    },
    {
      id: "expired",
      label: t("offer.ended"),
    },
  ];
  const handelValue = (name, value) => {
    handleChange(name, value);
  };

  return (
    <Grid container>
      <Stack
        flexDirection="row"
        container
        width="100%"
        gap={1}
        justifyContent="space-between"
      >
        <Typography
          component="h1"
          style={{ fontWeight: "bold", fontSize: "25px" }}
        >
          {title}
        </Typography>

        {type != "edit" && (
          <NormalTabs
            TabsOptions={options}
            value={filterValue}
            change={(event, index) => setFilterValue(index)}
            ContainerStyle="switch-container"
            TabStyle="switch-btn mb-20px"
            onclick={(value, index) => {
              if (value == 0) handelValue("status", "");
              else if (value == 1) handelValue("status", "active");
              else if (value == 2) handelValue("status", "expired");
            }}
          />
        )}
         <Box sx={{ mb: "20px" }} className="border-r-10px">
          <BranchesFilter setFilterObj={setFilterObj} filterObj={filterObj} profileData={profileData} />
        </Box>
        <TextFieldInput
          type="search"
          placeholder="Search  offers"
          StartAdornment={<SearchIcon />}
          className="joboffers-search"
          style={{ width: "180px" }}
          onChange={(e) => handelValue("keyword", e.target.value)}
        />

        {userType === "descendant_user" && offersPrivilages?.can_create && (
          <>
            {is_navigate && (
              <PrimaryButton
                fontSize="13px"
                startIcon={<AddIcon />}
                text={t("offer.addNewOffer")}
                style={{ padding: "8px", height: "48px" }}
                classBtn="primary r-25px"
                click={() => navigate(`${url}?page=${pageNumber}`)}
              />
            )}
          </>
        )}
        {userType !== "descendant_user" && (
          <>
            {is_navigate && (
              <PrimaryButton
                fontSize="13px"
                startIcon={<AddIcon />}
                text={t("offer.addNewOffer")}
                style={{ padding: "8px", height: "48px" }}
                classBtn="primary r-25px"
                click={() => navigate(`${url}?page=${pageNumber}`)}
              />
            )}
          </>
        )}
      </Stack>
      <Divider style={{ width: "100%", margin: "20px 0" }} />
    </Grid>
  );
};

export default withTranslation()(OfferHeader);
