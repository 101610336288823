import ActivityGroupsTemplate from "../../../Templates/Main/Reservations/ActivityGroups.template.jsx";
import { getActivityGroups } from "../../../Contexts/APIs/Reservations/Reservations.js";
import Loader from "../../../Components/Atoms/Loader/Loader.js";
import { useParams } from "react-router-dom";
import { useState } from "react";

const ActivityGroupsPage = (props) => {
    const { t } = props;
    const { id } = useParams();
    const [count, setCount] = useState();
    const [openLoader, setOpenLoader] = useState(false);
    const [activityGroups, setActivityGroups] = useState([]);
    const [activityInfo, setActivityInfo] = useState({});

    const GetActivityGroups = async (filterObj) => {
        setOpenLoader(true);
        const {res, err} = await getActivityGroups(id, filterObj);
        if (res) {
            setCount(res?.extra?.total_count);
            setActivityGroups(res?.data?.activity_groups);
            setActivityInfo(res?.data?.activity);
        }
        if (err) {

        }
        setOpenLoader(false);
    };
    return (
        <>
            <Loader open={openLoader}/>
            <ActivityGroupsTemplate
                getActivityGroups={GetActivityGroups}
                count={count}
                activityGroups={activityGroups}
                activityInfo={activityInfo}
            />
        </>
    )
}

export default ActivityGroupsPage