import { Grid, Typography } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import "./LoginForm.css";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput.jsx";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import { useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";

const LoginForm = (props) => {
  const { Login, t } = props;
  const fcm_token = localStorage.getItem("fcm_token");
  const navigate = useNavigate();
  const LoginIntialValues = {
    email: "",
    password: "",
  };

  const LoginValidationSchema = yup.object({
    email: yup.string().trim().required(t("profile.emailRequired")).email(),
    password: yup.string().trim().required(t("profile.passwordRequired")),
  });

  const formik = useFormik({
    initialValues: LoginIntialValues,
    validationSchema: LoginValidationSchema,
    onSubmit: (values) => {
      const data = {
        user: {
          email: values.email,
          password: values.password,
        },
        device: {
          uuid: "dummy",
          device_type: "web",
          fcm_token: fcm_token ? fcm_token : window.navigator.userAgent,
        },
      };
      Login(data);
    },
  });

  return (
    <Grid container lg={12} md={12} sm={12} xs={12} mt={10}>
      <Grid
        className="mb-20px"
        lg={9}
        md={9}
        sm={9}
        xs={9}
        style={{ margin: "auto" }}
      >
        <TextFieldInput
          name="email"
          id="email"
          placeholder={t("profile.emailAddress")}
          className="login-input"
          type="email"
          onChange={formik.handleChange}
          value={formik.values.email}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          clickEnter={formik.handleSubmit}
        />
      </Grid>
      <Grid
        className="mb-30px"
        lg={9}
        md={9}
        sm={9}
        xs={9}
        style={{ margin: "auto" }}
      >
        <TextFieldInput
          name="password"
          id="password"
          placeholder={t("forgotPassword.passwordPlaceholder")}
          className="login-input"
          type="password"
          onChange={formik.handleChange}
          value={formik.values.password}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          clickEnter={formik.handleSubmit}
        />
      </Grid>
      <Grid className="mb-30px" container>
        <PrimaryButton
          text="Login"
          classBtn="primary"
          style={{ padding: "5px 50px", margin: "auto" }}
          click={formik.handleSubmit}
        />
      </Grid>

      <Grid container className="mb-30px">
        <Typography
          style={{ margin: "auto", cursor: "pointer" }}
          onClick={() => navigate("/forget-password")}
        >
          {t("profile.forgotPassword")}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(LoginForm);
