import { Grid, Typography, Divider } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton.jsx";

const FormHeadr = (props) => {
  const { t, type, handleSubmit, handleReset, privilages } = props;
  const Navigate = useNavigate();
  const userType = localStorage.getItem("userType");
  let [searchParams, setSearchParams] = useSearchParams();

  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  return (
    <>
      <Grid container>
        <Grid container item width={"230px"}>
          <Grid
            item
            xs={
              type === "add" || type === "edit" || type === "edit-password"
                ? 12
                : 6
            }
            flexDirection="row"
            display="flex"
            alignItems="center"
          >
            <Typography
              component="h1"
              style={{ fontWeight: "bold", fontSize: "30px" }}
            >
              {type === "add" ? "Add salesman" : "Edit salesman"}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          item
          width={"calc(100% - 230px)"}
          justifyContent={"end"}
        >
          <PrimaryButton
            text={t("activity.cancel")}
            classBtn="secoundry r-25px"
            style={{
              padding: "5px 20px",
              height: "60px",
              margin: "0 10px",
              textTransform: "none",
            }}
            click={() => {
              handleReset();
              setTimeout(() => {
                Navigate(`/salesmen/salesmen-list?page=${pageNumber}`);                
              }, 500);
            }}
          />
          {type == "add" ? (
            <>
              {((userType === "descendant_user" && privilages?.can_create) ||
                userType !== "descendant_user") && (
                <PrimaryButton
                  fontSize="14px"
                  text={t("users.add")}
                  classBtn="primary r-25px"
                  style={{
                    padding: "5px 20px",
                    height: "60px",
                    width: "115px",
                    textTransform: "none",
                  }}
                  click={() => handleSubmit()}
                />
              )}
            </>
          ) : (
            <>
              {((userType === "descendant_user" && privilages?.can_update) ||
                userType !== "descendant_user") && (
                <PrimaryButton
                  text={
                    type === "edit-password"
                      ? t("salesmen.savePassword")
                      : t("activity.applyChange")
                  }
                  classBtn="primary r-25px"
                  style={{
                    padding: "5px 20px",
                    height: "60px",
                    margin: "0 10px",
                    textTransform: "none",
                  }}
                  click={() => handleSubmit()}
                />
              )}
            </>
          )}
        </Grid>
      </Grid>
      <Divider style={{ width: "100%", margin: "20px 0" }} />
    </>
  );
};

export default withTranslation()(FormHeadr);
