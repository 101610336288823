import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";
import { makeFilterString } from "../Helper/Helper.js";

export async function getSalesmanReservationsList(filter_obj) {
  return await axiosFn(
    "get",
    URL.Salesman_reservation + makeFilterString(filter_obj)
  )
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function updateSalesmanReservationsList(id, data) {
  return await axiosFn("put", URL.Salesman_reservation + id, data)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
