import { Grid, Typography, Divider, Stack } from "@mui/material";
import { icons } from "../../../../Assets/AssetHelper.js";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import { useNavigate, useSearchParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { useState } from "react";
import { deleteActivity } from "../../../../Contexts/APIs/Activities/Activities.js";
import DeleteModal from "../../General/Models/DeleteModal.jsx";
import {
  deleteInHouseActivity,
  getInHouseActivitiesList,
} from "../../../../Contexts/APIs/Salsemen/InHouseActivities.js";

const InHouseActivityDetailsHeader = (props) => {
  const { activityDetails, t, inHouseActivitiesPrivilages } = props;
  let [searchParams, setSearchParams] = useSearchParams();
  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  const Navigate = useNavigate();
  const userType = localStorage.getItem("userType");
  const lang = localStorage.getItem("lang");
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const handelDeleteActivity = async (id) => {
    setOpenLoader(true);
    const { res, err } = await deleteInHouseActivity(id);
    if (res) {
      Navigate(`/salesmen/in-house-activities?page=${pageNumber}`);
      getInHouseActivitiesList({});
    }
    setOpenLoader(false);
  };

  return (
    <>
      <Grid container>
        <Grid container lg={11} margin="auto" padding="20px 0">
          <Grid container md={9}>
            <Grid container md={12}>
              <Typography
                fontWeight="bold"
                component="h1"
                fontSize="20px"
                mr="25px"
              >
                {activityDetails?.name == null
                  ? `'"${t("activity.activityName")}"'`
                  : activityDetails?.name}
              </Typography>
            </Grid>
            <Grid container md={12}>
              <Typography>
                <Typography
                  component="span"
                  style={{
                    fontWeight: "bold",
                    color: "var(--primary-color)",
                    fontSize: "22px",
                  }}
                >
                  {`${
                    activityDetails?.price
                  } ${activityDetails?.currency?.lookup_key?.toUpperCase()}`}
                </Typography>
                <Typography
                  component="span"
                  style={{
                    color: "#848383",
                    fontSize: "15px",
                    margin: "0 20px",
                  }}
                >
                  {`${activityDetails?.duration_days} ${t("activity.days")} . ${
                    activityDetails?.no_of_dives
                  } ${t("activity.dives")}  . ${activityDetails?.capacity} ${t(
                    "activity.reservations"
                  )} `}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={3} paddingTop="10px">
            <Grid md={8}></Grid>
            <Grid md={2}>
              {userType === "descendant_user" &&
                inHouseActivitiesPrivilages?.can_update && (
                  <PrimaryButton
                    btnType="icon"
                    startIcon={
                      <img width="35" src={icons.EditIcon} alt="edit" />
                    }
                    click={() =>
                      Navigate(
                        `/salesmen/in-house-activities/edit-activity/${activityDetails?.id}?page=${pageNumber}`
                      )
                    }
                  />
                )}
              {userType !== "descendant_user" && (
                <PrimaryButton
                  btnType="icon"
                  startIcon={<img width="35" src={icons.EditIcon} alt="edit" />}
                  click={() =>
                    Navigate(
                      `/salesmen/in-house-activities/edit-activity/${activityDetails?.id}?page=${pageNumber}`
                    )
                  }
                />
              )}
            </Grid>
            <Grid md={2}>
              <PrimaryButton
                btnType="icon"
                startIcon={
                  <img width="35" src={icons.DeleteIcon} alt="delete" />
                }
                // click={() => RemoveActivity(activityDetails?.id)}
                click={() => setOpenDeleteModel(true)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{ width: "100%" }} />
      </Grid>
      {openDeleteModel && (
        <DeleteModal
          open={openDeleteModel}
          setOpen={setOpenDeleteModel}
          handelDeleteRequest={() => {
            handelDeleteActivity(activityDetails?.id);
            setOpenDeleteModel(false);
          }}
          // text={[t("activity.deleteActivity"), t("activity.deleteActivityMsg")]}
          text={[
            `${t("users.delete")} ${activityDetails?.name}`,
            t("activity.deleteActivityMsg"),
          ]}
          buttonText={[t("offer.cancel"), t("offer.delete")]}
        />
      )}
    </>
  );
};

export default withTranslation()(InHouseActivityDetailsHeader);
