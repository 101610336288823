import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";
import { makeFilterString } from "../Helper/Helper.js";

export async function getActivityGroups(id, filter_obj) {
  return await axiosFn("get", URL.activities + '/' + id + URL.group_reservations + makeFilterString(filter_obj))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function getGroupReservations(filter_obj) {
  return await axiosFn("get", URL.getGroupReservations + makeFilterString(filter_obj))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function getGroupReservationsListData(activity_group_id, filter_obj) {
  return await axiosFn("get", URL.activity_groups + activity_group_id + '/reservations' + makeFilterString(filter_obj))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function updatePaymentStatus(order_id, payment_obj) {
  return await axiosFn("put", URL.orders + order_id + URL.update_payment, payment_obj)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function getReservationsList(filter_obj) {
  return await axiosFn("get", URL.orders + makeFilterString(filter_obj))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function createNewReservation(group_id, customer_id, reservationObj) {
  return await axiosFn("post", URL.activity_groups + group_id + URL.create_order + customer_id, reservationObj)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function deleteReservation(order_id) {
  return await axiosFn("delete", URL.orders + order_id)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function reservationDetails(order_id) {
  return await axiosFn("get", URL.orders + order_id)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function getSystemConfigDetails(systemConfig_id) {
  return await axiosFn("get", `/lookups/system_configurations/${systemConfig_id}`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}