import { Grid, Typography } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { icons } from "../../../../Assets/AssetHelper";
import FileInput from "../../../Atoms/Input/FileInput/FileInput";
import DisplayLicense from "../DisplayLicense";
import "./Form.css";

const LocalLicense = ({
  t,
  localLicenseErr,
  getLocalLicense,
  localLicenseName,
  localeLicenseImg,
  RemoveLocaleLicenseImg,
  setLocalLicenseName,
}) => {
  return (
    <>
      <Grid container lg={12} className="activity-form">
        <Grid container md={12}>
          <Typography
            component="h1"
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              borderBottom: "2px solid var(--primary-color)",
            }}
            className="mb-20px"
          >
            {t("branch.local")} {t("branch.license")}
          </Typography>
        </Grid>
        <Grid container md={12} className="mb-20px">
          <Grid sm={5.8}>
            <FileInput
              icon={icons.UploadIcon}
              uploadText={t("branch.localGovernmentalLicense")}
              btnStyle="upload-input"
              errorTxt={localLicenseErr}
              change={(event) => getLocalLicense(event)}
              fileName={localLicenseName}
              accept="image/*,application/pdf"
            />
          </Grid>
          <Grid sm={0.4}></Grid>
          {localeLicenseImg?.url && (
            <DisplayLicense
              data={localeLicenseImg}
              image={localeLicenseImg?.url}
              handleRemove={(_) => {
                RemoveLocaleLicenseImg();
                setLocalLicenseName("");
              }}
              title={t("branch.governementalLicense")}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default withTranslation()(LocalLicense);
