import { Breadcrumbs, ButtonGroup, Grid, Link, Typography } from '@mui/material'
import React, { useState } from 'react';
import PrimaryButton from '../../Atoms/Buttons/Primary/PrimaryButton';
import TextFieldInput from '../../Atoms/Input/TextField/TextFieldInput';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';

import './PagesHead.css';

const PagesHead = ({ title, searchInput, primaryButtons, breadCrumb, handelSubmit, filterObj, setFilterObj,filter }) => {
    const [searchKeywords, setSearchKeywords] = useState('');

    const getKeywords = (keyword) => {
        setSearchKeywords(keyword);
        setFilterObj({ ...filterObj, 'page_number': 1, 'keyword': keyword });
    };

    return (
        <Grid container item lg={12} style={{ borderBottom: "1px solid #d9d9d9" }} sx={{ pb: 1, mb: 2 }} className='user-pages-head'>
            {
                breadCrumb && <Grid item sm={12}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" href={breadCrumb.path}>
                            {breadCrumb.link}
                        </Link>
                        <Typography color="text.primary">{breadCrumb.text}</Typography>
                    </Breadcrumbs>
                </Grid>
            }
            <Grid item lg={4}>
                <Typography component="h1" style={{ fontSize: "30px", fontWeight: "bold", textAlign: "left", color: "#333132" }}>
                    {title}
                </Typography>
            </Grid>

            <Grid container item lg={8} flexDirection='row' justifyContent='space-between' >
                <Grid item lg={filter ? 3 : 4}>
                    {
                        searchInput && <TextFieldInput
                            type={searchInput.type}
                            onChange={(e) => getKeywords(e.target.value)}
                            placeholder={searchInput.placeholder}
                            StartAdornment={<SearchIcon />}
                            className={searchInput.className}
                            value={searchKeywords}
                        />
                    }
                </Grid>
                {filter && <Grid sm={1} sx={{ mb: "20px" }} className="border-r-10px">
                 {filter}
                </Grid>}
                <Grid item lg={filter ? 7 : 8} textAlign="end">
                    {
                        primaryButtons.map((btn, index) => {
                            return (
                                <PrimaryButton
                                    key={index}
                                    fontSize={btn.fontSize}
                                    startIcon={btn.startIcon && <AddIcon />}
                                    text={btn.text}
                                    classBtn={btn.classBtn}
                                    style={btn.style}
                                    click={() => {
                                        btn.click && btn.click();
                                        btn.btnType === 'submit' && handelSubmit();
                                    }}
                                />
                            )
                        })
                    }
                </Grid>
            </Grid>
        </Grid>

    )
}

export default PagesHead