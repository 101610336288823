import React, { useEffect, useState } from 'react'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Grid, Typography, Divider, Button, Menu, MenuItem, IconButton } from "@mui/material";
import moment from "moment";
import InfoModal from '../../General/Models/InfoModal';
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { withTranslation } from 'react-i18next';

const OrdersList = ({ t, setOpenConfirmPayment, setOpenCancelReservations, setOpenRejectReservations, activityItem, setOpenCompleteActivity, setOpenReservationDetails, setOpenConfirmReservations, ordersPrivilages, reservationsDuration }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openInfoModal, setOpenInfoModal] = useState(false);
    const userType = localStorage.getItem("userType")

    const open = Boolean(anchorEl);
    const handleClick = (event, activityItem, index) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const pendingOptions = [
        { title: t("reservations.confirmReservation"), action: () => setOpenConfirmReservations(true) },
        { title: t("reservations.rejectReservation"), action: () => setOpenRejectReservations(true) }
    ]

    const completedOptions = [
        { title: t("reservations.confirmPayment"), action: () => setOpenConfirmPayment(true) },
        { title: t("reservations.cancelReservation"), action: () => setOpenCancelReservations(true) }
    ]

    const checkinOptions = [
        { title: t("reservations.completeActivity"), action: () => setOpenCompleteActivity(true) }
    ] 

    const menuOptions = {
        'Waiting for confirm reservation': pendingOptions,
        'Waiting for payment confirmation': completedOptions,
        "Customer checked in": checkinOptions
    }

    const paymentStatus = {
        'cancelled': 'Unpaid',
        "pending": "Unpaid",
        "payment_pending": "Unpaid",
        "checked_in ": "Paid",
        "completed": "Paid",
        "rejected": "Unpaid",
        "checkin_pending": "Paid"
    }

    return (
        <Grid container lg={12} className="activity-item mb-20px"
        >
            <Grid md={0.5} textAlign="center">{activityItem?.id}</Grid>
            <Grid md={1.5}> {moment(activityItem?.created_at).format('MMM DD,YYYY')}</Grid>
            <Grid sm={1.3}>{activityItem?.reservation_number}</Grid>
            <Grid sm={1.5} fontWeight='bold' style={{ textDecoration: 'underline', height: '35px', width: '20px' }}
                onClick={() => setOpenReservationDetails(true)}>{activityItem?.user_info?.name}</Grid>
            <Grid sm={0.7}>{activityItem?.quantity}</Grid>
            <Grid sm={1.5} fontWeight='bold'>{activityItem?.payment_info?.amount} {activityItem?.payment_info?.currency?.lookup_key}</Grid>
            <Grid sm={1} color={paymentStatus[activityItem?.payment_info?.status] == 'Unpaid' ? '#e10007' : '#3ed181'}>{paymentStatus[activityItem?.payment_info?.status]}</Grid>
            <Grid sm={2}>{moment(activityItem?.payment_info?.updated_at).format('MMM DD,YYYY')} / {moment(activityItem?.payment_info?.updated_at).format('h a')} </Grid>
            <Grid sm={1} display='flex' alignItems='end' color={activityItem?.payment_info?.status_display?.display_color}>
                {activityItem?.payment_info?.status_display?.display_name}
                {
                    (activityItem?.payment_info?.status === "payment_expired" ||
                        activityItem?.payment_info?.status === "rejected" ||
                        activityItem?.payment_info?.status === "pending") ?
                    <IconButton
                        className="reservation-modal-button"
                        onClick={() => setOpenInfoModal(true)}
                        sx={{mb: '5px'}}
                    >
                        <ErrorOutlineOutlinedIcon style={{ color: "#e10007", fontSize: '1.4rem' }} />
                    </IconButton>
                    : null
                }
            </Grid>
            {userType === 'descendant_user' && ordersPrivilages?.can_update &&
                <Grid sm={1}>
                    {
                        menuOptions[activityItem?.payment_info?.status_display?.display_name] ?
                            <Grid sm={1}
                            >
                                <Button

                                    id="demo-positioned-button"
                                    aria-controls={open ? 'demo-positioned-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={(e) => handleClick(e)}
                                >
                                    <MoreHorizIcon style={{ color: "black" }} />
                                </Button>
                                <Menu
                                    id="demo-positioned-menu"
                                    aria-labelledby="demo-positioned-button"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >
                                    {
                                        menuOptions[activityItem?.payment_info?.status_display?.display_name]?.map((val, index) => {
                                            return <MenuItem
                                                key={index}
                                                onClick={() => {

                                                    val?.action()
                                                    handleClose()

                                                }}>{val?.title}</MenuItem>
                                        })
                                    }

                                </Menu>
                            </Grid>
                            : <Typography>-</Typography>
                    }
                </Grid>
            }
            {userType !== 'descendant_user' &&
                <Grid sm={1}>
                    {
                        menuOptions[activityItem?.payment_info?.status_display?.display_name] ?
                            <Grid sm={1}
                            >
                                <Button

                                    id="demo-positioned-button"
                                    aria-controls={open ? 'demo-positioned-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={(e) => handleClick(e)}
                                >
                                    <MoreHorizIcon style={{ color: "black" }} />
                                </Button>
                                <Menu
                                    id="demo-positioned-menu"
                                    aria-labelledby="demo-positioned-button"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >
                                    {
                                        menuOptions[activityItem?.payment_info?.status_display?.display_name]?.map((val, index) => {
                                            return <MenuItem
                                                key={index}
                                                onClick={() => {

                                                    val?.action()
                                                    handleClose()

                                                }}>{val?.title}</MenuItem>
                                        })
                                    }

                                </Menu>
                            </Grid>
                            : <Typography>-</Typography>
                    }
                </Grid>
            }

        {openInfoModal && (
          <InfoModal
            open={openInfoModal}
            setOpen={setOpenInfoModal}
            text={[
                t("reservations.attention"),
                activityItem?.payment_info?.status_display.display_name.toLowerCase() === "payment allowance period has expired"
                  ? t("reservations.paymentAllowanceExpired")
                : activityItem?.payment_info?.status_display.display_name.toLowerCase() === "confirmation rejected"
                  ? t("reservations.confirmationRejected")
                : activityItem?.payment_info?.status_display.display_name.toLowerCase() === "waiting for confirm reservation"        
                  ? `${t("reservations.waitingConfirmReservation")} ${parseInt(reservationsDuration / 24)} ${parseInt(reservationsDuration / 24) !== 1 ? t("reservations.days") : t("reservations.day")} ${t("reservations.and")} ${reservationsDuration % 24} ${(reservationsDuration % 24) !== 1 ? t("reservations.hours") : t("reservations.hour")}`
                : null
              ]}
          />
        )}
        </Grid>)
}

export default withTranslation()(OrdersList)