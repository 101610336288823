import { Grid, Typography, Divider } from "@mui/material";
import UploadImagesForm from "../Forms/UploadImagesForm.jsx";
import LocationForm from "../Forms/BranchLocationForm";
import BranchInfo from "./Forms/BranchInfo.jsx";
import BoatInfo from "./Forms/BoatInfo.jsx";
import MoreInfo from "./Forms/MoreInfo.jsx";
import Generators from "./Forms/Generators.jsx";
import SwitchesForm from "../Forms/SwitchesForm.jsx";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import LanguagesSelect from "../Forms/LanguagesSelect.jsx";
import { getObjectWithSupportedLanguages } from "../../../Contexts/APIs/Helper/Helper.js";
import { toast } from "react-toastify";
import moment from "moment";
import { withTranslation } from "react-i18next";
import {
  BranchValidationSchema,
  SafariBoatValidationSchema,
} from "./Forms/validation.js";
import MultiOrganizationsSelect from "./Forms/MultiOrganizationsSelect.jsx";
import {
  getPresignedLinkAPI,
  uploadFileAPI,
} from "../../../Contexts/APIs/General/upload.js";
import LocalLicense from "./Forms/LocalLicense.jsx";

const BranchForm = (props) => {
  const {
    t,
    branchDetails,
    uploadImages,
    type,
    formType,
    handleFormSubmit,
    organizationsList,
    organizationsCount,
    createBranch,
    editBranch,
    branchImages,
    RemoveBranchImage,
    uploadLocalLicense,
    uploadLicense,
    localeLicenseImg,
    licenseImg,
    getFeaturesOptions,
    featureOptions,
    supportedLanguages,
    getOrganization,
    RemoveLocaleLicenseImg,
    licensesData = [],
    getbranchDetails,
    id,
  } = props;
  const [localLicenseErr, setLocalLicenseErr] = useState("");
  const [licenseErr, setLicenseErr] = useState("");
  const [localLicenseName, setLocalLicenseName] = useState("");
  const [licenseName, setLicenseName] = useState("");
  const [location, setLocation] = useState({
    lat: "30.8251200666126",
    lng: "30.535008531552304",
  });
  const [yearBuilt, setYearBuilt] = useState(null);
  const [renovated, setRenovated] = useState(null);
  const [startBorder, setStartBorder] = useState("");
  const [endBorder, setEndBorder] = useState("");
  const userId =
    localStorage.getItem("userType") !== "descendant_user"
      ? localStorage.getItem("userId")
      : localStorage.getItem("ancestorId");
  const [branchFeatures, setBranchFeatures] = useState([]);
  const [branchFeaturesDefault, setBranchFeaturesDefault] = useState([]);
  const [supportLang, setSupportLang] = useState("en");
  const [branchNames, setBranchNames] = useState([]);
  const [selectedOrganizationList, setSelectedOrganizationList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  // const [allLicensesData, setAllLicensesData] = useState([
  //   ...selectedList,
  //   ...licensesData,
  // ]);
  useEffect(() => {
    handleFormSubmit.current = validationMethod;
  }, [localLicenseName, licenseName]);

  const validationMethod = () => {
    formik.handleSubmit();
  };

  const getCurrentLocation = (lat, lng) => {
    setLocation({
      lat: lat,
      lng: lng,
    });
  };
  const BranchIntialValues = {
    name_en: branchDetails?.localized_data?.en?.name || "",
    name_de: branchDetails?.localized_data?.de?.name || "",
    name_fr: branchDetails?.localized_data?.fr?.name || "",
    name_it: branchDetails?.localized_data?.it?.name || "",
    name_nl: branchDetails?.localized_data?.nl?.name || "",
    manager_name: branchDetails?.manager_name || "",
    email: branchDetails?.email || "",
    branch_number: branchDetails?.branch_number || "",
    branch_country_code: branchDetails?.branch_country_code || "+33",
    insurance_name: branchDetails?.insurance_name || "",
    policy_number: branchDetails?.policy_number || "",
    boat_length: branchDetails?.boat?.length || "",
    boat_width: branchDetails?.boat?.width || "",
    engines_no: branchDetails?.no_of_engines || "",
    fuel_tanks: branchDetails?.fuel_tanks || "",
    year_renovated: branchDetails?.boat?.year_renovated || "",
    total_cabins: branchDetails?.boat?.total_cabins || "",
    boat_features: branchDetails?.boat?.boat_features || "",
    rental_equipment_available:
      branchDetails?.boat?.rental_equipment_available || "",
    nitrox_available: branchDetails?.boat?.nitrox_available || "",
    horse_power: branchDetails?.horse_power || "",
    license_number:
      branchDetails?.branch_organizations_attributes?.license_number || "",
    diving_certification:
      branchDetails?.organizations?.length > 0
        ? branchDetails?.organizations[0]?.id
        : "",
    diving_certification_name:
      branchDetails?.organizations?.length > 0
        ? branchDetails?.organizations[0]?.name
        : "",
    country_id: branchDetails?.country?.id || "",
    city_id: branchDetails?.city?.id || "",
    area_id: branchDetails?.area?.id || "",
    country_name: branchDetails?.country?.name || "",
    city_name: branchDetails?.city?.name || "",
    area_name: branchDetails?.area?.name || "",
    generator_type: "",
    kilo_watt: "",
  };

  const handleUpload = async (images) => {
    const files = [];
    const fileData = [];

    images.forEach((image) => {
      let extension;

      // Check if file_type is available
      if (image?.file_type) {
        // Extract the extension from file_type
        extension = image.file_type.split("/")[1];
      } else if (image?.url) {
        // Extract the extension from the URL if file_type is not available
        extension = image.url.split(".").pop();
      }

      // Push the extension into the files array
      files.push({ extension });

      // Push the file and file_type or url into the fileData array
      fileData.push({ file: image.file, type: image?.file_type || image?.url });
    });

    const res = await getPresignedLinkAPI(files);
    const uploadedFiles = await Promise.all(
      fileData.map(async (data, index) => {
        const uploadRes = await uploadFileAPI(
          res?.data[index].presigned_url,
          data.file
        );
        return {
          url: res?.data[index]?.file_url,
          type: data.type,
        };
      })
    );

    return uploadedFiles;
  };

  const handleSubmit = async (values, type) => {
    let branchImagesUrls = [];
    let localeLicenseImageUrl = [];
    let licenseImageUrls = [];

    // Separate the branch images into new and old images
    const newBranchImages = branchImages?.filter(
      (item) => item.id === undefined
    );
    const oldBranchImages = branchImages?.filter(
      (item) => item.id !== undefined
    );
    if (newBranchImages?.length) {
      branchImagesUrls = await handleUpload(newBranchImages);
    }
    if (localeLicenseImg?.file) {
      localeLicenseImageUrl = await handleUpload([localeLicenseImg]);
    }
    if (selectedList?.length) {
      licenseImageUrls = await handleUpload(selectedList);
    }

    // Combine old and new images
    const combinedBranchImages = [
      ...oldBranchImages,
      ...branchImagesUrls.map((file) => ({
        file_type: file?.type,
        url: file?.url,
        media_type: "photo",
      })),
    ];
    const commonBranchData = {
      license_number: values?.license_number,
      dive_center_id: userId,
      city_id: values.city_id,
      country_id: values.country_id,
      area_id: values.area_id,
      name: values.name,
      branch_type: type === "safari_boat" ? "safari_boat" : "office",
      manager_name: values.manager_name,
      insurance_name: values.insurance_name,
      policy_number: values.policy_number,
      email: values.email,
      media_attributes: combinedBranchImages,
      local_gov_license_attributes: {
        file_type: localeLicenseImageUrl[0]?.type,
        url: localeLicenseImageUrl[0]?.url,
        media_type: "photo",
      },
      branch_country_code: values?.branch_country_code,
      branch_number: values?.branch_number,
      branch_organizations_attributes: selectedList.map(
        ({ organization, file, file_type, ...rest }, index) => ({
          ...rest,
          license_url: licenseImageUrls[index]?.url,
        })
      ),
      lat: location.lat,
      long: location.lng,
      branch_features: branchFeatures,
    };

    const branchObj = getObjectWithSupportedLanguages(
      {
        branch: commonBranchData,
      },
      supportedLanguages,
      supportedLanguages.map((lang, index) => ({
        name: values[`name_${lang.id}`],
      })),
      "branch"
    );

    const boatObj = getObjectWithSupportedLanguages(
      {
        branch: {
          ...commonBranchData,
        },
        boat: {
          id: null,
          dive_center_id: userId,
          length: values.boat_length,
          width: values.boat_width,
          year_built: moment(yearBuilt).format("DD/MM/YYYY"),
          year_renovated: moment(renovated).format("DD/MM/YYYY"),
          total_cabins: values.total_cabins,
          boat_features: values.boat_features,
        },
      },
      supportedLanguages,
      supportedLanguages.map((lang, index) => ({
        name: values[`name_${lang.id}`],
      })),
      "branch"
    );
    if (renovated && yearBuilt && values?.name_en) {
      formType === "add"
        ? createBranch(type === "safari_boat" ? boatObj : branchObj)
        : editBranch(
            branchDetails?.id,
            type === "safari_boat" ? boatObj : branchObj
          );
    } else if (renovated == null || yearBuilt == null) {
      DateValidation();
    }  else {
      let eSubString =
        type === "safari_boat" ? "Safari Boat" : t("branch.diveCenter");
      toast.error(
        `${t("branch.pleaseUpload")} ${eSubString} ${t("branch.image")}`
      );
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: BranchIntialValues,
    validationSchema:
      type === "safari_boat"
        ? SafariBoatValidationSchema
        : BranchValidationSchema,
    onSubmit: (values) => handleSubmit(values, type),
  });

  const getLocalLicense = (event) => {
    const file = event.target.files[0];
    const fileSize = file.size / window.maxFileSize; // in MiB
    if (fileSize > 1) {
      toast.warning(window.fileSize);
    } else {
      setLocalLicenseName(file?.name);
      setLocalLicenseErr("");
      uploadLocalLicense(event);
    }
  };

  const getLicense = (event) => {
    const file = event.target.files[0];
    const fileSize = file.size / window.maxFileSize; // in MiB
    if (fileSize > 1) {
      toast.warning(window.fileSize);
    } else {
      setLicenseName(file?.name);
      setLicenseErr("");
      uploadLicense(event);
    }
  };

  const updateSupportedLang = (e, languages) => {
    setSupportLang(e.target.value);
  };

  useEffect(() => {
    setLocalLicenseName(branchDetails?.local_gov_license?.url);
    setLicenseName(branchDetails?.license?.url);
    const newData = branchDetails?.branch_features
      ? branchDetails?.branch_features
          ?.map((data) => {
            if (data?.enabled) return { feature_option_id: data?.id };
          })
          .filter((val) => val)
      : [];
    setBranchFeatures(newData);
    setBranchFeaturesDefault(newData);
    setRenovated(moment(branchDetails?.boat?.year_renovated));
    setYearBuilt(moment(branchDetails?.boat?.year_built));
    setLocation({
      lat: branchDetails?.lat,
      lng: branchDetails?.long,
    });
    const intialOrganizationData = branchDetails?.organizations
      ? branchDetails?.organizations?.map((data) => {
          return data?.id;
        })
      : [];
    setSelectedOrganizationList(intialOrganizationData);
  }, [branchDetails]);
  useEffect(() => {
    if (formik?.errors && formik?.isSubmitting) {
      toast.dismiss();
      Object.values(formik.errors).forEach((error) => {
        toast.error(error);  // Display each error as a toast notification
      });
      if(!formik?.values[`name_${supportLang}`]){
        toast.error(t("branch.pleaseEnterBranchName"));
      }
    }
  }, [formik?.errors,formik?.isSubmitting]);
  const DateValidation = (startDate, endDate) => {
    if (renovated === null) {
      setStartBorder("red-border");
    } else {
      setStartBorder("");
    }
    if (yearBuilt === null) {
      setEndBorder("red-border");
    } else {
      setEndBorder("");
    }
  };
  return (
    <Grid rowSpacing={1} columnSpacing={{ xs: 2 }} container lg={12}>
      <LanguagesSelect
        value={supportLang}
        supportedLanguages={supportedLanguages}
        onChange={(e) => updateSupportedLang(e, supportedLanguages)}
      />
      <Grid item md={6}>
        <BranchInfo
          type={type}
          formType={formType}
          formik={formik}
          supportedLanguages={supportedLanguages}
          getBranchLocalized={(names) => setBranchNames(names)}
          supportLang={supportLang}
        />
        {type === "safari_boat" && (
          <BoatInfo
            formik={formik}
            yearBuilt={yearBuilt}
            setYearBuilt={setYearBuilt}
            renovaled={renovated}
            setRenovated={setRenovated}
            startBorder={startBorder}
            endBorder={endBorder}
          />
        )}
        <LocalLicense
          localLicenseErr={localLicenseErr}
          licenseErr={licenseErr}
          formik={formik}
          getLocalLicense={getLocalLicense}
          localLicenseName={localLicenseName}
          licenseName={licenseName}
          getLicense={getLicense}
          organizationsList={organizationsList}
          localeLicenseImg={localeLicenseImg}
          RemoveLocaleLicenseImg={RemoveLocaleLicenseImg}
          setLocalLicenseName={setLocalLicenseName}
        />
        <MoreInfo
          localLicenseErr={localLicenseErr}
          licenseErr={licenseErr}
          formik={formik}
          getLocalLicense={getLocalLicense}
          localLicenseName={localLicenseName}
          licenseName={licenseName}
          getLicense={getLicense}
          organizationsList={organizationsList}
          formTitle={t("branch.organization")}
          addBtnTitle={t("branch.addLicense")}
          list={organizationsList}
          organizationsCount={organizationsCount}
          getOrganization={getOrganization}
          intialActiveList={branchDetails?.organizations}
          getActiveList={(data) => setSelectedOrganizationList(data)}
          selectedList={selectedList}
          setSelectedList={setSelectedList}
          licenseImg={licenseImg}
          licensesData={licensesData}
          type={type}
          getbranchDetails={getbranchDetails}
          id={id}
          setLicenseName={setLicenseName}
        />
        {/* <MultiOrganizationsSelect
          formTitle={t("branch.organization")}
          addBtnTitle={t("branch.addOrganization")}
          list={organizationsList}
          organizationsCount={organizationsCount}
          getOrganization={getOrganization}
          intialActiveList={branchDetails?.organizations}
          getActiveList={(data) => setSelectedOrganizationList(data)}
        /> */}
      </Grid>
      <Grid item md={12} lg={6}>
        <UploadImagesForm
          uploadImages={uploadImages}
          formTitle={
            type === "safari_boat"
              ? t("branch.safariBoatImages")
              : t("branch.diveCenterImage")
          }
          Images={branchImages}
          RemoveImage={RemoveBranchImage}
          type="multi"
        />
        <LocationForm
          formTitle={
            type === "safari_boat"
              ? t("branch.safariBoatLocation")
              : t("branch.diveCenterLocation")
          }
          defaultData={branchDetails}
          formik={formik}
          getCurrentLocation={getCurrentLocation}
          setMapType={() => {}}
          location={location}
        />
        <SwitchesForm
          formTitle={
            type === "safari_boat"
              ? t("branch.safariBoatFeatures")
              : t("branch.facilityInformation")
          }
          options={featureOptions}
          defaultData={branchFeaturesDefault}
          type={type}
          getFeaturesOptions={getFeaturesOptions}
          updateFeatureOptions={(arr) => setBranchFeatures(arr)}
        />
      </Grid>
    </Grid>
  );
};

export default withTranslation()(BranchForm);
