import { Grid } from '@mui/material';
import React, { useRef } from 'react';
import { useNavigate,useSearchParams } from 'react-router-dom';
import Loader from '../../../Atoms/Loader/Loader';
import RolesForm from '../../../Molecules/Forms/RolesForm';
import PagesHead from '../../../Molecules/Users/PagesHead';
import { withTranslation } from "react-i18next";

function RoleFormWrap(props) {
    const {t,formik, roleDataRes, validationMethod, handlePermissionsChange,handleBatchPermissionsChange, openLoader, formType,rows,handleSelectedRowsChange,optionsStateObj} = props;
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    let pageNumber =
      +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  
    const handleFormSubmit = useRef(null);

  return (
    <Grid container>
        <Loader open={openLoader} /> 
        <PagesHead 
            title={formType === 'Add' ?t("users.addNewRole"):t("users.editUserRole")}
            breadCrumb={{
                link: `${t("users.roles")}`,
                path: `/users/roles?page=${pageNumber}`,
                text: `${
                    formType === "Add" ? t("users.addNewRole") : t("users.editUserRole")
                  }`,

                }}
            primaryButtons={[
                {
                    fontSize:'14px',
                    text:`${t("users.cancel")}`,
                    classBtn:"primary r-25px primary-text-outline",
                    style:{ padding: "5px 20px", height: "48px", marginRight: "1rem" },
                    click: () => navigate(`/users/roles?page=${pageNumber}`),
                },
                {
                    fontSize:'14px',                    
                    text: `${
                        formType === "Add" ? t("users.add") : t("users.saveChange")
                      }`,
                    classBtn:"primary r-25px",
                    style:{ padding: "5px 20px", height: "48px" },
                    btnType: 'submit'
                }
            ]}
            handelSubmit={() => handleFormSubmit.current()}
        />
        {
            !openLoader && <RolesForm
                formik={formik}
                roleDataRes={roleDataRes}
                optionsStateObj={optionsStateObj}
                rows={rows}
                handleSelectedRowsChange={handleSelectedRowsChange}        
                handleFormSubmit={handleFormSubmit}
                validationMethod={validationMethod}
                handlePermissionsChange={handlePermissionsChange}
                handleBatchPermissionsChange={handleBatchPermissionsChange}
                formType={formType}
            />            
        }
    </Grid>
  )
}

export default withTranslation()(RoleFormWrap);
