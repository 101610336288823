import { Grid } from "@mui/material";
import ActivityInfo from "../Activities/Forms/ActivityInfo.jsx";
import ActivityDatesTimes from "../Activities/Forms/ActivityDatesTimes.jsx";
import ActivityRequirement from "../Activities/Forms/ActivityRequirement.jsx";
import ActivityMoreInfo from "../Activities/Forms/ActivityMoreInfo.jsx";
import PaymentInfo from "../Activities/Forms/PaymentInfo.jsx";
import UploadImagesForm from "../Forms/UploadImagesForm.jsx";
import LocationForm from "../Forms/ActivityLocationForm";
import LanguagesForm from "../Forms/LanguagesForm.jsx";
import { useState, useEffect, useRef } from "react";
import PriceIncludeOrNot from "../Activities/Forms/PriceIncludeOrNot.jsx";
import LanguagesSelect from "../Forms/LanguagesSelect.jsx";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {
  getObjectWithSupportedLanguages,
  updateLocalizationsValues,
} from "../../../Contexts/APIs/Helper/Helper.js";
import { editActivity } from "../../../Contexts/APIs/Activities/Activities.js";
import moment from "moment";
import LiveAboradOptions from "./Forms/LiveAboradOptions.jsx";
import { useNavigate } from "react-router-dom";
import {
  ActivityIntialValues,
  ActivityValidationSchema,
} from "./ActivityFormValidation.js";
import { getBranchDetails } from "../../../Contexts/APIs/Branches/Branches.js";
import { withTranslation } from "react-i18next";
import dayjs from "dayjs";

const ActivityForm = (props) => {
  const navigate = useNavigate();
  const {
    t,
    uploadActivityImages,
    type,
    supportedLanguages,
    handleFormSubmit,
    branchesList,
    currencies,
    organizationsList,
    setOrganizationsList,
    getCertificates,
    certificatesList,
    setCertificatesList,
    languagesList,
    priceIncludesList,
    activityImages,
    removeActivityImage,
    getListOfPrices,
    createNewActivity,
    formType,
    activityDetails,
    getOrganization,
    branchesCount,
    certificatesCount,
    getLanguagesAPI,
    getBranches,
    languagesCount,
    organizationsCount,
    profileData,
  } = props;
  const [location, setLocation] = useState({
    lat: "",
    lng: "",
  });

  const [supportLang, setSupportLang] = useState(
    localStorage.getItem("lang") || "en"
  );
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startBorder, setStartBorder] = useState("");
  const [endBorder, setEndBorder] = useState("");
  const [dateType, setDateType] = useState(0);
  const [mapType, setMapType] = useState(0);
  const [updatedList, setUpdatedList] = useState([]);
  const [includesList, setIncludesList] = useState([]);
  const [notIncludesList, setNotIncludesList] = useState([]);
  const [activityLanguages, setActivityLanguages] = useState([]);
  const [everyDayList, setEveryDayList] = useState([]);
  const [editableIncludeList, setEditableIncludeList] = useState([]);
  const [editableExcludeList, setEditableExcludeList] = useState([]);
  const [editableDateType, setEditableDateType] = useState();
  const [editableStartTime, setEditableStartTime] = useState();
  const [someWeekDays, setSomeWeekDays] = useState([]);
  const [everydaysStartTime, setEverydaysStartTime] = useState("");
  const [someDates, setSomeDates] = useState([]);
  const [someDatesData, setSomeDatesData] = useState([]);
  // Activity More Informations values
  const [concatTime, setConcatTime] = useState("");
  const [tripName, setTripName] = useState([]);
  const [allList, setAllList] = useState([]);
  const [selectedBranchData, setSelectedBranchData] = useState(null);
  const isAssignedToOneBranch = profileData?.branch?.name;
  let requestStatus = "";
  const [requestCounter, setRequestCounter] = useState(0);

  const getCurrentLocation = (lat, lng) => {
    setLocation({
      lat: lat,
      lng: lng,
    });
  };

  const updateSupportedLang = (e, languages) => {
    setSupportLang(e.target.value);
    localStorage.setItem("lang", e.target.value);
    localStorage.setItem("i18nextLng", e.target.value);
    window.location.reload();
  };

  useEffect(() => {
    handleFormSubmit.current = validationMethod;
  }, []);

  const DateValidation = (startDate, endDate) => {
    if (editableDateType != 2 && startDate === null) {
      setStartBorder("red-border");
    } else {
      setStartBorder("");
    }
    if (editableDateType != 2 && endDate === null) {
      setEndBorder("red-border");
    } else {
      setEndBorder("");
    }
  };

  const validationMethod = () => {
    formik.handleSubmit();
  };

  const getBrachData = async () => {
    const { res, err } = await getBranchDetails(formik.values.branch_id);
    if (res) {
      setSelectedBranchData(res.data.branch);
    }
  };

  const handelErrorMsgs = () => {
    if (!formik?.values?.branch_name) toast.error(t("activity.nameReq"));
    if (!formik?.values?.description_en)
      toast.error(t("activity.descriptionReq"));
    // if (!formik?.values?.cancellation_policy_en)
    //   toast.error(t("activity.cancellationPolicyReq"));
    if (!formik?.values?.pickup_info_en)
      toast.error(t("activity.pickupInfoReq"));
    if (!formik?.values?.client_expectation_en)
      toast.error(t("activity.clientExpectationReq"));
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      if (
        startDate != "Invalid Date" &&
        startDate != null &&
        endDate != "Invalid Date" &&
        endDate != null
      ) {
        if (dateType != 2 && !moment(endDate).isSameOrAfter(startDate)) {
          toast.error(t("activity.PleaseAddCorrectDates"));
        }
      }
    }, 1500);
    return () => clearTimeout(timeId);
  }, [startDate, endDate]);

  const formik = useFormik({
    initialValues: formType == "add" ? {} : ActivityIntialValues,
    validationSchema: ActivityValidationSchema(mapType),
    onSubmit: async (values) => {
      var activityObj = {};
      const filteredDates = someWeekDays?.filter(
        (val) => val?.start_time != ""
      );
      if (activityImages?.length == 0) {
        toast.error(t("activity.pleaseUploadActivityImage"));
      } else if (
        !values?.branch_name ||
        !values?.description_en ||
        // !values?.cancellation_policy_en ||
        !values?.pickup_info_en ||
        !values?.client_expectation_en
      ) {
        handelErrorMsgs();
      } else if (filteredDates?.length == 0 && dateType == 1) {
        toast.error(t("activity.pleaseChooseDays"));
      } else if (someDatesData?.length == 0 && dateType == 2) {
        toast.error(t("activity.pleaseChooseDates"));
      } else if (dateType != 2 && !moment(endDate).isSameOrAfter(startDate)) {
        toast.error(t("activity.PleaseAddCorrectDates"));
      } else if (
        (everydaysStartTime === "" || !everydaysStartTime) &&
        dateType == 0
      ) {
        toast.error(t("activity.PleaseAddStartTime"));
      } else if (
        includesList.length != 0 &&
        notIncludesList?.length != 0 &&
        activityImages?.length > 0
      ) {
        activityObj = getObjectWithSupportedLanguages(
          {
            dive_activity: {
              // branch_id: values.branch_name,
              branch_id: isAssignedToOneBranch
                ? profileData?.branch?.id
                : values.branch_id,
              need_confirmation: values.confirmation_state === 1 ? true : false,
              minimum_certificate_id: values.certificates,
              min_dives: values.min_dives,
              capacity: values.number_of_tickets,
              min_age: values.min_age,
              scheduling_option: dateType, //scheduling_option: { everyday: 0, some_weekdays: 1, some_dates: 2 }
              pickup_option: mapType, // pickup_option: { add_meeting_point: 0, hotel_pickup: 1, operator_pickup: 2 }
              reserve_before:
                values.deadline_time_unit == "day"
                  ? values.last_time_booking * 24
                  : values.last_time_booking,
              start_date:
                dateType != 2
                  ? moment(new Date(startDate), "DD/MM/YYYY").format(
                      "DD-MM-YYYY"
                    )
                  : "",
              end_date:
                dateType != 2
                  ? moment(new Date(endDate), "DD/MM/YYYY").format("DD-MM-YYYY")
                  : "",
              currency_id: values.currency,
              price: values.price,
              cancellation_notice_period:
                values?.time_unit == "day"
                  ? values.cancelation_lead_time * 24
                  : values.cancelation_lead_time,
              duration_days: values.days,
              duration_hours: values.hours,
              no_of_dives: values.no_dives,
              max_no_of_students: values.max_divers,
              activity_type: type,
              is_available: true,
              // allow_cash: true, //values?.allow_cash
              allow_cash: values?.allow_cash,
              mobile_voucher: values?.mobile_voucher,
              wheelchair_accessible:
                values?.wheelchair_accessible?.length > 0 ||
                values?.wheelchair_accessible
                  ? true
                  : false,

              guided_dives:
                values?.guided_dives?.length > 0 || values?.guided_dives
                  ? true
                  : false,
            },
            pickup_location:
              mapType === 0
                ? {
                    country_id: values.country_id,
                    city_id: values.city_id,
                    area_id: values.area_id,
                    lat: location.lat,
                    long: location.lng,

                    lookup_key: values.area,
                    is_available: true,
                    location_type: "general",
                  }
                : "",
            media: activityImages,
            activity_language: activityLanguages,
            activity_price_includes: includesList,
            activity_price_excludes: notIncludesList,
            scheduled_days:
              dateType == 0
                ? everyDayList
                : dateType == 1
                ? someWeekDays?.filter((val) => val?.start_time != "")
                : someDatesData,
            activity_groups: dateType === 2 ? someDatesData : "",
          },
          supportedLanguages,
          supportedLanguages.map((lang, index) => ({
            name: values[`name_${lang.id}`],
            description: values[`description_${lang.id}`],
            cancellation_policy: values[`cancellation_policy_${lang.id}`],
            confirmation_text: values[`confirmation_text_${lang.id}`],
            pickup_info: values[`pickup_info_${lang.id}`],
            client_expectation: values[`client_expectation_${lang.id}`],
            other_information: values[`other_information_${lang.id}`],
            start_point: values?.start_point, //only liveaboard
            end_point: values?.end_point,
          })),
          "dive_activity"
        );

        if (formType == "add") {
          requestStatus = "pending";
          setRequestCounter((prev) => prev + 1);

          if (requestStatus === "pending" && requestCounter === 0) {
            const { res, err } = await createNewActivity(activityObj);
            if (res) {
              requestStatus = "success";
              requestStatus = "";
              setRequestCounter(null);
            } else {
              if (err) {
                requestStatus = "fail";
                setRequestCounter(0);
              }
            }
          }
        } else {
          requestStatus = "pending";
          setRequestCounter((prev) => prev + 1);

          if (requestStatus === "pending" && requestCounter === 0) {
            const { res, err } = await editActivity(
              activityDetails?.id,
              activityObj,
              navigate
            );
            if (res) {
              requestStatus = "success";
              requestStatus = "";
              setRequestCounter(null);
            } else {
              if (err) {
                requestStatus = "fail";
                setRequestCounter(0);
              }
            }
          }
        }
      }
    },
  });
  const prevSubmitting = useRef(false);
  // set focus to first invalid input field.
  useEffect(() => {
    if (!formik.isSubmitting) return;
    if (Object.keys(formik.errors).length > 0) {
      document.getElementsByName(Object.keys(formik.errors)[0])[0].focus();
    }
  }, [formik]);

  useEffect(() => {
    // Check if isSubmitting changed from false to true
    if (
      prevSubmitting.current === false &&
      formik?.isSubmitting &&
      Object.keys(formik?.errors).length !== 0
    ) {
      // Display all Formik errors using toast notifications
      // Object.keys(formik?.errors).forEach((errorKey) => {
      //   toast.error(formik?.errors[errorKey]);
      // });
      DateValidation(startDate, endDate);
      if (activityImages?.length == 0)
        toast.error(t("activity.pleaseUploadActivityImage"));
      if (includesList.length === 0)
        toast.error(t("activity.PleaseAddPriceIncludes"));
      if (notIncludesList.length === 0)
        toast.error(t("activity.PleaseAddPriceExcludes"));
      if (activityLanguages.length === 0)
        toast.error(t("activity.PleaseAddOneOrMoreLanguages"));

      prevSubmitting.current = formik?.isSubmitting; // Update ref
    }

    // Update prevSubmitting on every render
    prevSubmitting.current = formik?.isSubmitting;
  }, [
    formik.errors,
    formik.isSubmitting,
    startDate,
    endDate,
    includesList,
    notIncludesList,
    activityLanguages,
  ]);

  const updatePriceTable = (priceList, allList, type) => {
    if (type === "include") setIncludesList(priceList);
    if (type === "not_include") setNotIncludesList(priceList);
    setAllList(allList);
  };
  useEffect(() => {
    if (formik.values.branch_name) getBrachData();
  }, [formik.values.branch_name]);

  useEffect(() => {
    DateValidation(startDate, endDate);
  }, [startDate, endDate]);

  const getBranchInformation = (value) => {
    const branch_info = branchesList.filter((branch) => {
      return branch.id === value;
    });
  };
  const setOldData = () => {
    getCertificates(activityDetails?.minimum_certificate?.organization?.id);
    formik.values.branch_id = activityDetails?.branch?.id;
    formik.values.branch_name = activityDetails?.branch?.name;
    formik.values.price = activityDetails?.price;
    formik.values.no_dives = activityDetails?.no_of_dives;
    formik.values.number_of_tickets = activityDetails?.capacity;
    formik.values.currency = activityDetails?.currency_id;
    formik.values.days = activityDetails?.duration_days;
    formik.values.hours = activityDetails?.duration_hours;
    formik.values.confirmation_state =
      activityDetails?.need_confirmation === false ? 2 : 1;
    formik.values.max_divers = activityDetails?.max_no_of_students;
    formik.values.cancelation_lead_time =
      activityDetails?.cancellation_notice_period;
    formik.values.last_time_booking = activityDetails?.reserve_before;
    formik.values.country_id = activityDetails?.pickup_location?.country_id;
    formik.values.country_name =
      activityDetails?.pickup_location?.country?.name;
    formik.values.city_id = activityDetails?.pickup_location?.city_id;
    formik.values.city_name = activityDetails?.pickup_location?.city?.name;
    formik.values.area_id = activityDetails?.pickup_location?.area_id;
    formik.values.area_name = activityDetails?.pickup_location?.area?.name;
    formik.values.organization =
      activityDetails?.minimum_certificate?.organization?.id;
    formik.values.certificates = activityDetails?.minimum_certificate?.id;
    formik.values.organization_name =
      activityDetails?.minimum_certificate?.organization?.name;
    formik.values.certificates_name =
      activityDetails?.minimum_certificate?.name;
    formik.values.min_dives = activityDetails?.min_dives;
    formik.values.min_age = activityDetails?.min_age;
    formik.values.mobile_voucher = activityDetails?.mobile_voucher;
    formik.values.start_point =
      activityDetails?.localized_data?.en?.start_point;
    formik.values.allow_cash = activityDetails?.allow_cash;
    formik.values.end_point = activityDetails?.localized_data?.en?.end_point;
    formik.values.guided_dives = activityDetails?.guided_dives;
    formik.values.wheelchair_accessible =
      activityDetails?.wheelchair_accessible;
    formik.values.time_unit = "hour";
    formik.values.deadline_time_unit = "hour";
    setTimeout(() => {
      if (activityDetails?.scheduled_days?.length > 0) {
        setStartDate(activityDetails?.scheduled_days[0]?.start_date);
        setEverydaysStartTime(
          dayjs(activityDetails?.scheduled_days[0]?.start_time, "hh:mm a")
        );
      }
      setStartDate(activityDetails?.start_date);
      setEndDate(activityDetails?.end_date);
    }, 1000);
    setLocation({
      lat: activityDetails?.pickup_location?.lat,
      lng: activityDetails?.pickup_location?.long,
    });
    if (activityDetails?.scheduling_option === "everyday") {
      setEditableDateType(0);
      setEditableStartTime(activityDetails?.scheduled_days[0]?.start_time);
    }
    if (activityDetails?.scheduling_option === "some_dates") {
      setEditableDateType(2);
      if (activityDetails?.scheduled_days) {
        const obj = activityDetails?.activity_groups?.map((val) => {
          return {
            start_date: val?.start_date,
            start_time: val?.start_time,
            reservations_count: val?.reservations_count,
          };
        });
        const obj_2 = activityDetails?.activity_groups?.map((val) => {
          return val?.start_date;
        });
        setSomeDates(obj_2);
        setSomeDatesData(obj);
      }
    }
    if (activityDetails?.localized_data) {
      const obj = activityDetails?.localized_data[supportLang];
      for (let key in obj) {
        formik.values[`${key}_${supportLang}`] = obj[key];
      }
    }
    if (activityDetails?.scheduling_option === "some_weekdays") {
      setEditableDateType(1);
      if (activityDetails?.scheduled_days) {
        const obj = activityDetails?.scheduled_days?.map((val) => {
          return {
            weekday: val?.weekday,
            start_time: val?.start_time,
          };
        });
        setSomeWeekDays(obj);
      }
    }
    if (activityDetails?.pickup_option == "hotel_pickup") {
      setMapType(1);
    } else if (activityDetails?.pickup_option == "operator_pickup") {
      setMapType(2);
    } else {
      setMapType(0);
    }
  };
  const setOldPriceList = () => {
    const oldIncludeList = [];
    activityDetails?.activity_price_includes?.map((price) => {
      oldIncludeList.push({
        id: price?.price_include_id,
        position: price?.position,
        name: price?.name,
      });
    });
    setEditableIncludeList(oldIncludeList);
    setIncludesList(oldIncludeList);

    // set exclude price list
    const oldExcludeList = [];
    activityDetails?.activity_price_excludes?.map((price) => {
      oldExcludeList.push({
        id: price?.price_include_id,
        position: price?.position,
        name: price?.name,
      });
    });
    setEditableExcludeList(oldExcludeList);
    setNotIncludesList(oldExcludeList);
  };
  useEffect(() => {
    if (formType === "edit") {
      setOldData();
      setOldPriceList();
    }
  }, [formType, supportLang, activityDetails]);

  useEffect(() => {
    if (
      formik?.values.branch_id?.toString().length > 0 ||
      isAssignedToOneBranch
    ) {
      getOrganization({
        branch_id: formik?.values?.branch_id || profileData?.branch?.id,
      });
    }
  }, [formik?.values?.branch_id, profileData, isAssignedToOneBranch]);

  useEffect(() => {
    if (formik?.values.organization?.toString().length > 0) {
      setTimeout(() => {
        getCertificates({
          organization_id: formik?.values?.organization,
          page_number: 1,
        });
      }, 1000);
    } else {
      getCertificates(null);
    }
  }, [formik?.values?.organization]);

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 2 }}>
      <LanguagesSelect
        value={supportLang}
        supportedLanguages={supportedLanguages}
        onChange={(e) => updateSupportedLang(e, supportedLanguages)}
      />
      <Grid item sm={12} lg={6}>
        <ActivityInfo
          branchesList={branchesList}
          branchesCount={branchesCount}
          setOrganizationsList={setOrganizationsList}
          setCertificatesList={setCertificatesList}
          getBranches={getBranches}
          formik={formik}
          supportedLanguages={supportedLanguages}
          supportLang={supportLang}
          currencies={currencies}
          tripName={tripName}
          setTripName={(e, i) =>
            setTripName(updateLocalizationsValues(e, i, tripName))
          }
          getBranchInformation={getBranchInformation}
          getCurrentLocation={getCurrentLocation}
          profileData={profileData}
          formType={formType}
          isAssignedToOneBranch={isAssignedToOneBranch}
        />

        <Grid container lg={12}>
          <PaymentInfo type={type} formik={formik} />
        </Grid>

        <ActivityDatesTimes
          activityDetails={activityDetails}
          formik={formik}
          startDate={startDate}
          endDate={endDate}
          setStartDate={(date) => setStartDate(date)}
          setEndDate={(date) => setEndDate(date)}
          concatTime={concatTime}
          setConcatTime={setConcatTime}
          startBorder={startBorder}
          endBorder={endBorder}
          dateType={dateType}
          setDateType={(value) => setDateType(value)}
          setEveryDayList={(list) => setEveryDayList(list)}
          editableDateType={editableDateType}
          formType={formType}
          editableStartTime={editableStartTime}
          setSomeWeekDays={setSomeWeekDays}
          someWeekDays={someWeekDays}
          everydaysStartTime={everydaysStartTime}
          setEverydaysStartTime={setEverydaysStartTime}
          someDates={someDates}
          setSomeDates={setSomeDates}
          setSomeDatesData={setSomeDatesData}
          someDatesData={someDatesData}
        />

        <PriceIncludeOrNot
          title={t("activity.priceInclude")}
          updatePriceTable={(priceList, allList, type) =>
            updatePriceTable(priceList, allList, type)
          }
          updatedList={updatedList}
          type="include"
          list={priceIncludesList}
          getListOfPrices={getListOfPrices}
          editableList={editableIncludeList}
          formType={formType}
          setAllList={setAllList}
          allList={allList}
        />
        <PriceIncludeOrNot
          title={t("activity.priceNotInclude")}
          updatePriceTable={(priceList, allList, type) =>
            updatePriceTable(priceList, allList, type)
          }
          updatedList={updatedList}
          type="not_include"
          list={priceIncludesList}
          getListOfPrices={getListOfPrices}
          editableList={editableExcludeList}
          formType={formType}
          setAllList={setAllList}
          allList={allList}
        />

        <ActivityMoreInfo
          formik={formik}
          supportedLanguages={supportedLanguages}
          supportLang={supportLang}
        />
      </Grid>
      <Grid item lg={6} sm={12}>
        <UploadImagesForm
          uploadImages={uploadActivityImages}
          formTitle={t("activity.activityImages")}
          Images={activityImages}
          RemoveImage={removeActivityImage}
        />
        <LocationForm
          formTitle={t("activity.tripLocation")}
          getCurrentLocation={getCurrentLocation}
          location={location}
          formik={formik}
          type="switch"
          mapType={mapType}
          setMapType={(value) => setMapType(value)}
          selectedBranchData={selectedBranchData}
          activityDetails={activityDetails}
        />
        {type == "live_aboard" && <LiveAboradOptions formik={formik} />}

        <ActivityRequirement
          formik={formik}
          organizationsList={organizationsList}
          setOrganizationsList={setOrganizationsList}
          getOrganization={getOrganization}
          organizationsCount={organizationsCount}
          getCertificates={getCertificates}
          certificatesCount={certificatesCount}
          certificatesList={certificatesList}
          setCertificatesList={setCertificatesList}
          IsDiveActivity={true}
        />
        <LanguagesForm
          formTitle={t("activity.activityLanguages")}
          languagesList={languagesList}
          getLanguagesAPI={getLanguagesAPI}
          languagesCount={languagesCount}
          activityLanguages={activityDetails?.activity_languages}
          getActivityLanguages={(languages) => setActivityLanguages(languages)}
        />
      </Grid>
    </Grid>
  );
};

export default withTranslation()(ActivityForm);
