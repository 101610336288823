import React from "react";
import OfferForm from "../../../Components/Organisms/Offers/OfferForm";

const AddOfferTemplate = ({
  branchesList,
  getBranches,
  userId,
  getActivities,
  activitiesList,
  formType,
  offerDetails,
  branchesCount,
  activitiesCount,
  profileData
}) => {
  return (
    <OfferForm
      branchesList={branchesList}
      userId={userId}
      getBranches={getBranches}
      getActivities={getActivities}
      activitiesList={activitiesList}
      formType={formType}
      offerDetails={offerDetails}
      branchesCount={branchesCount}
      activitiesCount={activitiesCount}
      profileData={profileData}
    />
  );
};

export default AddOfferTemplate;
