import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import SearchableSelect from "../../../Atoms/Select/SearchableSelect";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput";
import { Button, Dialog, Grid, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PercentIcon from "@mui/icons-material/Percent";

const AssignActivityModal = ({
  t,
  open,
  setOpen,
  formik,
  initialValuesObject,
  branchesList,
  inHouseActivitiesList,
  modalType,
  handleGetInHouseActivity,
  getBranches,
  getInHouseActivities,
  branchesCount,
  inHouseActivitiesCount,
  profileData,
}) => {
  const isAssignedToOneBranch = profileData?.branch?.name;
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 410,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "25px",
    p: 4,
  };

  const handleClose = () => {
    setOpen(false);
    formik.setValues(initialValuesObject);
  };
  const [selectedInHouseActivity, setSelectedInHouseActivity] = useState(null);
  useEffect(() => {
    if (isAssignedToOneBranch) {
      formik.setValues({
        ...formik.values,
        branch_id: profileData?.branch?.id,
        branch_name: profileData?.branch?.name,
      });
    }
  }, [isAssignedToOneBranch, profileData]);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "8px",
          height: "auto",
          width: "400px",
          padding: "18px",
        },
      }}
    >
      <Grid container mb={5}>
        <Grid item xs={11}>
          <Typography
            sx={{
              fontSize: "var(--medium-font)",
              fontWeight: "bold",
              color: "var(--font-color)",
              borderBottom: "2px solid #F6B119",
              width: "fit-content",
              margin: "auto",
            }}
          >
            {modalType === "add"
              ? t("salesmen.assignNewActivity")
              : t("salesmen.editAssignedActivitiy")}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
        </Grid>
      </Grid>

      <Grid container>
        <Grid
          item
          xs={12}
          maxWidth={"310px !important"}
          p={0}
          m={"0px auto 20px"}
          position="relative"
          // zIndex={20}
        >
          <SearchableSelect
            name="branch_id"
            options={branchesList}
            count={branchesCount}
            label="branch"
            getList={getBranches}
            className="searchable-select assigned-activity-select"
            disabled={isAssignedToOneBranch}
            value={{
              label: formik?.values?.branch_name,
              value: formik?.values?.branch_id,
            }}
            onChange={(val) => {
              formik.setValues({
                ...formik?.values,
                inhouse_activity_id: "",
                inhouse_activity_name: "",
                branch_id: val?.value,
                branch_name: val?.label,
              });
            }}
            index={10}
            error={
              !formik?.values?.branch_id &&
              formik?.touched?.branch_id &&
              Boolean(formik?.errors?.branch_id)
            }
            handelMenuOpen={branchesList?.length == 0 && getBranches}
            helperText={formik?.touched?.branch_id && formik?.errors?.branch_id}
            onClear={() => {
              formik.setValues({
                ...formik?.values,
                branch_id: "",
                branch_name: "",
                inhouse_activity_id: "",
                inhouse_activity_name: "",
              });
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          maxWidth={"310px !important"}
          p={0}
          m={"0px auto 20px"}
          zIndex={"10 !important"}
          position={"relative"}
        >
          <SearchableSelect
            name="inhouse_activity_id"
            options={inHouseActivitiesList}
            getList={getInHouseActivities}
            handelMenuOpen={getInHouseActivities}
            count={inHouseActivitiesCount}
            moreFilterData={{ branch_id: formik?.values?.branch_id }}
            label={t("salesmen.inHouseActivity")}
            className="searchable-select assigned-activity-select"
            value={{
              label: formik?.values?.inhouse_activity_name,
              value: formik?.values?.inhouse_activity_id,
            }}
            index={1}
            onChange={(val) => {
              formik.setValues({
                ...formik?.values,
                inhouse_activity_id: val?.value,
                inhouse_activity_name: val?.label,
              });

              handleGetInHouseActivity(val?.value, setSelectedInHouseActivity);
            }}
            error={
              !formik?.values?.inhouse_activity_id &&
              formik?.touched?.inhouse_activity_id &&
              Boolean(formik?.errors?.inhouse_activity_id)
            }
            helperText={
              formik?.touched?.inhouse_activity_id &&
              formik?.errors?.inhouse_activity_id
            }
            onClear={() => {
              formik.setValues({
                ...formik?.values,
                inhouse_activity_id: "",
                inhouse_activity_name: "",
              });
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          maxWidth={"310px !important"}
          p={0}
          m={"0px auto 20px"}
        >
          <TextFieldInput
            placeholder={t("salesmen.maxDiscountAllowed")}
            name={"max_discount_percentage_allowed"}
            id={"max_discount_percentage_allowed"}
            className="login-input assigned-activity-input"
            type="number"
            value={formik?.values?.max_discount_percentage_allowed}
            onChange={formik.handleChange}
            error={
              !formik?.values?.max_discount_percentage_allowed &&
              formik?.touched?.max_discount_percentage_allowed &&
              Boolean(formik?.errors?.max_discount_percentage_allowed)
            }
            helperText={
              formik?.touched?.max_discount_percentage_allowed &&
              formik?.errors?.max_discount_percentage_allowed
            }
            EndAdornment={<PercentIcon />}
          />
        </Grid>

        <Grid item xs={12} maxWidth={"310px !important"} p={0} m={"0px auto"}>
          <Typography
            sx={{ color: "#333132", fontSize: "16px", fontWeight: "700" }}
          >
            Price :{" "}
            {formik?.values?.inhouse_activity_id && formik?.values?.price
              ? `${formik?.values?.price} ${formik?.values?.currency_key}`
              : selectedInHouseActivity
              ? `${selectedInHouseActivity?.price} ${selectedInHouseActivity?.currency?.lookup_key}`
              : ""}
          </Typography>
        </Grid>
      </Grid>

      <Grid container my={3.5} justifyContent={"center"} gap={"30px"}>
        <Button
          style={{
            width: "141px",
            height: "60px",
            textTransform: "capitalize",
          }}
          sx={{ fontSize: "18px!important" }}
          className="primary r-25px"
          onClick={() => formik.handleSubmit()}
        >
          {t("profile.confirm")}
        </Button>
        <Button
          style={{
            width: "141px",
            height: "60px",
            color: "#333132",
            textTransform: "capitalize",
            border: "1px solid #bebebe",
          }}
          sx={{ fontSize: "18px!important" }}
          className="r-25px"
          onClick={handleClose}
        >
          {t("profile.cancel")}
        </Button>
      </Grid>
    </Dialog>
  );
};

export default withTranslation()(AssignActivityModal);
