import React, { useEffect, useState } from "react";
import {
  PointsAction,
  PointsConfig,
} from "../../../Contexts/APIs/Lookups/Points";
import PointsTemplate from "../../../Templates/Main/Points/Points.template";
import Loader from "../../../Components/Atoms/Loader/Loader";

const PointsPage = ({ profileData }) => {
  const [pointsData, setPointsData] = useState([]);
  const [pointsConfig, setPointsConfig] = useState([]);
  const [pointsRate, setPointsRate] = useState(null);

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState({ page_number: 1 });
  const [openLoader, setOpenLoader] = useState(false);

  const handlePageChange = (event, value) => {
    setPage(value);
    setFilterObj({ ...filterObj, page_number: value });
  };

  const getPoints = async (filter) => {
    setOpenLoader(true);
    const { res, error } = await PointsAction(filter);
    if (res) {
      setCount(res?.extra?.total_count);
      setPointsData(res?.data?.point_actions);
    }
    setOpenLoader(false);
  };

  const getPointsConfig = async () => {
    const { res, error } = await PointsConfig();
    if (res) {
      setPointsConfig(res?.data?.point_configurations);
      setPointsRate(res?.extra?.points_conversion_rate);
    }
  };
  useEffect(() => {
    getPoints(filterObj);
    getPointsConfig();
  }, []);

  useEffect(() => {
    getPoints(filterObj);
  }, [filterObj]);

  return (
    <>
      <Loader open={openLoader} />
      <PointsTemplate
        pointsData={pointsData}
        pointsConfig={pointsConfig}
        profileData={profileData}
        count={count}
        page={page}
        handlePageChange={handlePageChange}
        pointsRate={pointsRate}
      />
    </>
  );
};

export default PointsPage;
