import { useState } from "react";
import Loader from "../../../Components/Atoms/Loader/Loader.js";
import { getRequestedActivitiesList } from "../../../Contexts/APIs/RequestedActivities/Activities.js";
import ApprovedActivitiesTemplate from "../../../Templates/Main/ApprovedActivity/ApprovedActivity.template.jsx";
import PrivateGuideReservationsTemplate from "../../../Templates/Main/PrivateGuideReservations/PrivateGuideReservations.template.jsx";
import { getActivitiesList } from "../../../Contexts/APIs/Activities/Activities.js";
import { getReservationsList } from "../../../Contexts/APIs/Reservations/Reservations.js";
import { Box } from "@mui/material";
import { useEffect } from "react";

const PrivateGuideReservationsPage = (props) => {
  const { t, profileData, ordersPrivilages,joboffersPrivilages } = props;
  const [activitesList, setActivitiesList] = useState([]);
  const [count, setCount] = useState();
  const [openLoader, setOpenLoader] = useState(true);

  const [filterObj, setFilterObj] = useState({
    page_size: 10,
    page_number: 1,
    sort_by: "id",
    activity_type: "",
    owner_type: "DiveExpert",
  });

  const getReservationsActivities = async () => {
    setOpenLoader(true);
    const { res, err } = await getReservationsList(filterObj);

    if (res) {
      setCount(res?.extra?.total_count);
      setActivitiesList(res?.data?.orders);
    }
    if (err) {
    }

    setOpenLoader(false);
  };
  useEffect(() => {
    const timeId = setTimeout(() => {
      getReservationsActivities(filterObj);
    }, 500);
    return () => clearTimeout(timeId);
  }, [filterObj]);

  return (
    <Box
      width="100%"
      sx={{
        overflowX: "scroll",
        minHeight: "100vh",
      }}
    >
      <Loader open={openLoader} />
      <PrivateGuideReservationsTemplate
        activitesList={activitesList}
        count={count}
        ordersPrivilages={ordersPrivilages}
        setOpenLoader={setOpenLoader}
        setFilterObj={setFilterObj}
        filterObj={filterObj}
        joboffersPrivilages={joboffersPrivilages}
      />
    </Box>
  );
};

export default PrivateGuideReservationsPage;
