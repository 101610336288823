import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../../Components/Atoms/Loader/Loader';
import { getFreelancerDetails } from '../../../Contexts/APIs/Freelancers/Freelancers';
import { showJobOffer } from '../../../Contexts/APIs/Job-Offers/JobOffers';
import { getReviewsList } from '../../../Contexts/APIs/Reviews/Reviews';
import FreelancerReviewsTemplate from '../../../Templates/Main/Freelancers/FreelancerReviews.Template';

const FreelancerReviewsPage = () => {
    const { jop_offer_id, applicant_id } = useParams();
    const [reviewsList, setReviewsList] = useState([]);
    const [count, setCount] = useState(0);

    const [reviewsDetails, setReviewsDetails] = useState([]);

    const [applicantData, setApplicantData] = useState({});

    const [openLoader, setOpenLoader] = useState(false);
    const [filterObj, setFilterObj] = useState({
        'page_number': 1,

    });

    const handleChange = (name, value) => setFilterObj({ ...filterObj, [name]: value });

    const getReviews = async () => {
        setOpenLoader(true);
        const body = {
            "review": {
                "reviewee_id": applicantData?.id,
                "reviewee_type": "DiveExpert" // DiveActivity - DiveCenter,
            }
        }
        const { res, err } = await getReviewsList({}, body);
        if (res) {
            setReviewsList(res?.data?.reviews);
            setReviewsDetails(res?.data?.reviews_details)
            setCount(res?.extra?.total_count);

        }
        if (err) toast.error(err)
        setOpenLoader(false);
    };


    const getApplicantDetails = async () => {
        setOpenLoader(true);
        const { res, err } = await getFreelancerDetails(applicant_id);
        if (res) setApplicantData(res?.data?.user);
        if (err) toast.error(err)
        setOpenLoader(false);
    };

    useEffect(() => {
        getApplicantDetails()
    }, []);

    useEffect(() => {
        if (applicantData?.id) getReviews()
    }, [applicantData])
    return (
        <>
            <Loader open={openLoader} />
            <FreelancerReviewsTemplate
                reviewsList={reviewsList}
                reviewsDetails={reviewsDetails}
                applicantData={applicantData}
                count={count}
                handleChange={handleChange} />

        </>
    )
}

export default FreelancerReviewsPage