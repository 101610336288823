import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";
import { makeFilterString } from "../Helper/Helper.js";
export async function getInHouseActivitiesList(filter_obj) {
  return await axiosFn(
    "get",
    URL.Inhouse_activities + makeFilterString(filter_obj)
  )
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function getInHouseActivityGroupsList(id, filter_obj) {
  return await axiosFn(
    "get",
    URL.Inhouse_activities +
      "/" +
      id +
      "/inhouse_groups" +
      makeFilterString(filter_obj)
  )
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function getInHouseActivityDetails(branch_id) {
  return await axiosFn("get", URL.Inhouse_activities + "/" + branch_id)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function updateInHouseActivity(id, data) {
  return await axiosFn("put", URL.Inhouse_activities + "/" + id, data)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function updateInHouseActivityAvilabilty(id, data) {
  return await axiosFn(
    "patch",
    URL.Inhouse_activities + "/" + id + "/availability",
    data
  )
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function createInHouseActivity(data) {
  return await axiosFn("post", URL.Inhouse_activities, data)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function deleteInHouseActivity(id) {
  return await axiosFn("delete", URL.Inhouse_activities + "/" + id)
    .then((res) => {
      return {
        res: res,
        err: null,
      };
    })
    .catch(handleError);
}
