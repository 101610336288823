import ActivitiesTemplate from "../../../Templates/Main/Acticities/Activities.template.jsx";
import { getActivitiesList } from "../../../Contexts/APIs/Activities/Activities.js";
import { useEffect, useState } from "react";
import Loader from "../../../Components/Atoms/Loader/Loader.js";
import { useSearchParams } from "react-router-dom";

const ActivitiesPage = (props) => {
  const { t, profileData, activitiesPrivilages } = props;
  const [activitesList, setActivitiesList] = useState([]);
  const [count, setCount] = useState();
  const [openLoader, setOpenLoader] = useState(true);
  const [filterObj, setFilterObj] = useState(null);
  const [page, setPage] = useState(1);
  let [searchParams, setSearchParams] = useSearchParams();
  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  const ActivitiesList = async (filterObj) => {
    setOpenLoader(true);
    const defaultFilterObject = {
      page_size: 10,
      page_number: pageNumber,
      branch_type: "",
      keyword: "",
      sort_by: "id",
    };
    const { res, err } = await getActivitiesList({
      ...defaultFilterObject,
      ...filterObj,
    });
    if (res) {
      setActivitiesList(res?.data?.activities);
      setCount(res?.extra?.total_count);
    }
    if (err) {
    }
    setOpenLoader(false);
  };
  const updatePagination = (page) => {
    setPage(page);
    setFilterObj((data) => {
      return { ...data, page_number: page };
    });
  };
  useEffect(() => {
    const timeId = setTimeout(() => {
      ActivitiesList(filterObj);
    }, 500);
    return () => clearTimeout(timeId);
  }, [filterObj]);
  return (
    <>
      <Loader open={openLoader} />
      <ActivitiesTemplate
        activitesList={activitesList}
        setActivitiesList={setActivitiesList}
        count={count}
        getActivities={ActivitiesList}
        activitiesPrivilages={activitiesPrivilages}
        setOpenLoader={setOpenLoader}
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        updatePagination={updatePagination}
        page={page}
        setPage={setPage}
        profileData={profileData} 
      />
    </>
  );
};

export default ActivitiesPage;
