import AddBranchPageTemplate from "../../../Templates/Main/Branches/AddBranch.template.jsx";
import { useState, useEffect } from "react";
import {
  getPresignedLinkAPI,
  uploadFileAPI,
} from "../../../Contexts/APIs/General/upload.js";
import { OrganizationsAPI } from "../../../Contexts/APIs/Lookups/Certificates/Organizations.js";
import {
  createNewBranch,
  EditBranch,
} from "../../../Contexts/APIs/Branches/Branches.js";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../../../Components/Atoms/Loader/Loader.js";
import { getFeatureOptions } from "../../../Contexts/APIs/Lookups/FeaturesOptions.js";
import { getSupportedLanguages } from "../../../Contexts/APIs/Lookups/SupportedLanguages.js";
import { toast } from "react-toastify";

const AddBranchPage = (props) => {
  const { t, formType } = props;
  const [organizationsList, setOrganizationsList] = useState([]);
  const [organizationsCount, setOrganizationsCount] = useState(10);

  const Navigate = useNavigate();
  const [openLoader, setOpenLoader] = useState(false);
  const [branchImages, setBranchImages] = useState([]);
  const [localeLicenseImg, setLocaleLicenseImg] = useState({});
  const [licenseImg, setLicenseImg] = useState({});
  const [featureOptions, setFeatureOptions] = useState([]);
  const [supportedLanguages, setSupportedLanguages] = useState([]);
  let [searchParams, setSearchParams] = useSearchParams();

  let pageNumber = +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");

  let requestCounter = 0;
  let requestStatus = "";
  
  const uploadImage = async (event, str = "") => {
    const numberOfImages = event.target.files?.length + branchImages?.length;
    const file = event.target.files[0];
    const fileSize = file.size / window.maxFileSize; // in MiB
    if (fileSize > 1) {
      toast.warning(window.fileSize);
    } else {
      if (event.target.files?.length == 1) {
        setOpenLoader(true);
          if (str === "local_license") {
            setLocaleLicenseImg({
              media_type: "photo",
              url: URL.createObjectURL(event.target.files[0]),
              file_type: event.target.files[0]?.type,
              file: event?.target?.files[0],
            });
          } else if (str === "license") {
            setLicenseImg({
              media_type: "photo",
              url: URL.createObjectURL(event.target.files[0]),
              file_type: event.target.files[0]?.type,
              file: event?.target?.files[0],
            });
          } else {
            setBranchImages([
              ...branchImages,
              {
                media_type: "photo",
                url: URL.createObjectURL(event.target.files[0]),
                file_type: event.target.files[0]?.type,
                file: event?.target?.files[0],
              },
            ]);
          }
        setOpenLoader(false);
      } else if (numberOfImages <= 10) {
        setOpenLoader(true);
        Array.from(event.target.files).forEach(async (file, index) => {
          const fileSize = file.size / window.maxFileSize; // in MiB
          if (fileSize > 1) {
            toast.warning(window.fileSize);
          } else {
            setBranchImages((s) => {
              return [
                ...s,
                {
                  media_type: "photo",
                  url: URL.createObjectURL(file),
                  file_type: event.target.files[0]?.type,
                  file: event?.target?.files[0],
                },
              ];
            });
          }
        });
        setTimeout(() => {
          setOpenLoader(false);
        }, 500);
      } else {
        toast.error("you exceeded the maximum number of images");
      }
    }
  };

  const RemoveBranchImage = (i) => {
    if (i > -1) {
      branchImages.splice(i, 1);
      setBranchImages([...branchImages]);
    }
  };
  const RemoveLocaleLicenseImg = () => {
    setLocaleLicenseImg({});
  };
  const getOrganization = async (filter) => {
    const { res, err } = await OrganizationsAPI({ page_size: 10, ...filter });
    if (res) {
      filter?.page_number == 1
        ? setOrganizationsList(res?.data?.organizations)
        : setOrganizationsList((s) => [...s, ...res?.data?.organizations]);
      setOrganizationsCount(res?.extra?.total_count);
    }
    if (err) {
    }
  };


  const createBranch = async (branchObj) => {
    ++requestCounter;
    requestStatus = "pending";
    
    if( requestStatus === "pending" && requestCounter === 1 ){
      setOpenLoader(true);
      const { res, err } = await createNewBranch(branchObj);
      if (res) {
        requestStatus = "success";
        Navigate(`/branches?page=${pageNumber}`);
        requestStatus = "";
        requestCounter = null;
      }
      else{
        requestStatus = "fail";
        requestCounter = 0;
      }
      setOpenLoader(false);
    }
  };

  const editBranch = async (branch_id, branchObj) => {
    setOpenLoader(true);
    const { res, err } = await EditBranch(branch_id, branchObj);
    if (res) {
      Navigate(`/branches?page=${pageNumber}`);
    }
    setOpenLoader(false);
  };

  const getFeaturesOptions = async (filter_obj) => {
    const { res, err } = await getFeatureOptions(filter_obj);
    if (res) {
      setFeatureOptions(res?.data?.feature_options.filter(item => item.is_hidden != true));
    }
  };

  const getLanguages = async () => {
    const { res, err } = await getSupportedLanguages();
    if (res) {
      setSupportedLanguages(res?.data?.supported_locales);
    }
  };

  useEffect(() => {
    // getOrganization();
    getLanguages();
  }, []);

  return (
    <>
      <Loader open={openLoader} />
      <AddBranchPageTemplate 
        uploadImages={uploadImage}
        organizationsList={organizationsList}
        organizationsCount={organizationsCount}
        createBranch={createBranch}
        editBranch={editBranch}
        branchImages={branchImages}
        RemoveBranchImage={RemoveBranchImage}
        uploadLocalLicense={(e) => uploadImage(e, "local_license")}
        uploadLicense={(e) => uploadImage(e, "license")}
        localeLicenseImg={localeLicenseImg}
        licenseImg={licenseImg}
        getFeaturesOptions={getFeaturesOptions}
        featureOptions={featureOptions}
        supportedLanguages={supportedLanguages}
        type="add"
        formType={formType}
        getOrganization={getOrganization}
        RemoveLocaleLicenseImg={RemoveLocaleLicenseImg}
      />
    </>
  );
};

export default AddBranchPage;
