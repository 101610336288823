import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  createDescendantUser,
  editDescendantUser,
  showDescendantUser,
} from "../../../Contexts/APIs/Users/UsersList";
import UserFormTemplate from "../../../Templates/Main/Users/UserForm.template";
import * as yup from "yup";
import { toast } from "react-toastify";
import { getRoleList } from "../../../Contexts/APIs/Users/Roles";
import {
  getPresignedLinkAPI,
  uploadFileAPI,
} from "../../../Contexts/APIs/General/upload";
import { withTranslation } from "react-i18next";
import { getBranchesList } from "../../../Contexts/APIs/Branches/Branches.js";

function UserFormPage(props) {
  const { formType, t ,profileData} = props;
  const navigate = useNavigate();
  const [userImage, setUserImage] = useState({});
  const [openLoader, setOpenLoader] = useState(false);
  const [userData, setUserData] = useState([]);
  const [rolesData, setRolesData] = useState([]);
  let [searchParams, setSearchParams] = useSearchParams();
  const [branchesList, setbranchesList] = useState([]);
  const [branchesCount, setbranchesCount] = useState(10);
  const isAssignedToOneBranch = profileData?.branch?.name;
  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");
  const userId =
    localStorage.getItem("userType") !== "descendant_user"
      ? localStorage.getItem("userId")
      : localStorage.getItem("ancestorId");
  const validationSchemaObject = yup.object({
    name: yup.string().trim().required(t("users.userNameRequired")).min(8),
    role_id: yup.string().required(t("users.userRoleRequired")),
    email: yup.string().trim().required(t("users.emailRequired")).email(),
    country_code: yup.string().required(t("users.countryCodeRequired")),
    phone_number: yup
      .number()
      .positive(t("users.numbersOnlyAllowed"))
      .required(t("users.phoneNumberRequired")),
    password: yup.string().trim().required(t("users.passwordRequired")).min(8),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], t("users.passwordsMustMatch"))
      .required(t("users.confirmRequired")),
  });

  const handleCreateUser = async (values) => {
    const { res, err } = await createDescendantUser({
      descendant_user: {
        ...values,
        country_code: `+${formik.values.country_code}`,
        avatar: userImage.url,
      },
    });
    if (res) {
      setUserImage({});
      navigate(`/users?page=${pageNumber}`);
    }
  };

  const handleEditUser = async (values) => {
    const href = window.location.href;
    const { res, err } = await editDescendantUser(
      `${href.slice(href.indexOf("edit/") + 5, href.length)}`,
      {
        descendant_user: {
          ...values,
          country_code: `+${formik.values.country_code}`,
          avatar: userImage.url,
        },
      }
    );
    if (res) {
      navigate(`/users?page=${pageNumber}`);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      role_id: "",
      email: "",
      country_code: "33",
      phone_number: "",
      password: "",
      password_confirmation: "",
    },

    validationSchema: validationSchemaObject,
    onSubmit: (values) => {
      console.log(values, 'values');
      formType === "Add" && handleCreateUser(values);
      formType === "Edit" && handleEditUser(values);
    },
  });

  const validationMethod = () => {
    if (Object.keys(userImage).length === 0) {
      toast.error(t("users.pleaseUploadUserImage"));
      Object.values(formik.values).some((val) => val === "") &&
        formik.handleSubmit();
    } else {
      formik.handleSubmit();
    }
  };

  const getUser = async (id) => {
    setOpenLoader(true);
    const { res, error } = await showDescendantUser(id);
    if (res) {
      setUserData(res?.data);

      formik.setValues({
        name: res?.data["descendant_user"]["name"],
        role_id: res?.data["descendant_user"]["role"]["id"],
        email: res?.data["descendant_user"]["email"],
        country_code:
          res?.data["descendant_user"]["country_code"].indexOf("+") === 0
            ? res?.data["descendant_user"]["country_code"].slice(
              1,
              res?.data["descendant_user"]["country_code"].length
            )
            : res?.data["descendant_user"]["country_code"],
        phone_number: res?.data["descendant_user"]["phone_number"],
        password: "",
        password_confirmation: "",
        branch_name: res?.data['descendant_user']?.branch?.name,
        branch_id: res?.data['descendant_user']?.branch?.id
      });
    }
    setOpenLoader(false);
  };
  const getBranches = async (filter) => {
    const { res, err } = await getBranchesList({
      page_size: 10,
      page_number: 1,
      dive_center_id: userId,
      // branch_type: search.get("type") == 2 ? "safari_boat" : "",
      ...filter,
    });
    if (res) {
      if(isAssignedToOneBranch){
        (filter?.page_number == 1 || filter?.page_number == undefined)
          ? setbranchesList(res?.data?.branches)
          : setbranchesList((s) => [...s, ...res?.data?.branches]); 
      }else{
        (filter?.page_number == 1 || filter?.page_number == undefined)
          ? setbranchesList([{id: -1, name: "All"}, ...res?.data?.branches])
          : setbranchesList((s) => [{id: -1, name: "All"}, ...s, ...res?.data?.branches]); 
      }
      setbranchesCount(res?.extra?.total_count);
    }
  };
  const getRolesData = async () => {
    const { res, err } = await getRoleList({ page_size: 100, page_number: 1 });
    if (res) {
      setRolesData(res?.data?.roles);
    }
  };

  useEffect(() => {
    getRolesData();
    const href = window.location.href;
    formType === "Edit" &&
      getUser(href.slice(href.indexOf("edit/") + 5, href.length));
  }, []);

  useEffect(() => {
    userData?.descendant_user?.avatar &&
      setUserImage({
        media_type: "photo",
        url: userData.descendant_user.avatar,
      });
  }, [userData]);

  const uploadUserImages = async (event) => {
    const file = event.target.files[0];
    const fileSize = file.size / window.maxFileSize; // in MiB
    if (fileSize > 1) {
      toast.warning(window.fileSize);
    } else {
      setOpenLoader(true);
      const files = [{ extension: "png" }];
      const res = await getPresignedLinkAPI(files);
      const presignedUrl = res?.data[0].presigned_url;
      const upload = await uploadFileAPI(presignedUrl, file);
      if (upload) {
        setUserImage({
          media_type: "photo",
          url: res?.data[0]?.file_url,
        });
      }
      setOpenLoader(false);
    }
  };

  const removeUserImage = () => {
    Object.keys(userImage).forEach((key) => delete userImage[key]);
    setUserImage({});
  };

  return (
    <UserFormTemplate
      formik={formik}
      openLoader={openLoader}
      rolesData={rolesData}
      validationMethod={validationMethod}
      userImage={userImage}
      uploadUserImages={uploadUserImages}
      removeUserImage={removeUserImage}
      formType={formType}
      branchesList={branchesList}
      getBranches={getBranches}
      branchesCount={branchesCount}
      profileData={profileData}
    />
  );
}

export default withTranslation()(UserFormPage);
