import { axiosFn, handleError } from "../index"
import * as URL from "../URLs"
import { makeFilterString } from "../Helper/Helper"
import dataEN from '../../../Localization/en/translate.json';
import dataFR from '../../../Localization/fr/translate.json';
import dataDE from '../../../Localization/de/translate.json';


export async function getFaqAccordionsData(filter_obj) {
    return await axiosFn("get", URL.lookups + URL.faqs + makeFilterString(filter_obj))
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}

// Function to get translations based on selected language
export const getBranchTranslations = (data) => {
    const lang = localStorage.getItem('lang') || 'en'; // Set default to 'en' if no lang is set
    switch (lang) {
        case 'fr':
            return dataFR.translations[data];
        case 'de':
            return dataDE.translations[data];
        default: // Default to English
            return dataEN.translations[data];
    }
};