import { Grid, Avatar } from "@mui/material";
import { useState, useEffect } from "react";
import CustomPagination from "../../../Atoms/Pagination/Pagination";
import { icons } from "../../../../Assets/AssetHelper.js";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import moment from "moment";
import {
  addToFavoriate,
  deleteFavoriate,
  deleteFreelancer,
} from "../../../../Contexts/APIs/Freelancers/Freelancers";
import DeleteApplicantModal from "../Models/DeleteApplicant.jsx";
import { withTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import Favourate from "./favourate.jsx";
import Loader from "../../../Atoms/Loader/Loader.js";
const ListFreelancers = ({
  count,
  freelancers,
  handleChange,
  freelancersPrivilages,
  t,
}) => {
  let [searchParams, setSearchParams] = useSearchParams();

  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");

  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [freelancerData, setData] = useState(freelancers);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const userType = localStorage.getItem("userType");
  useEffect(() => {
    setData(freelancers);
  }, [freelancers]);

  const handlePageChange = (event, value) => {
    setPage(value);
    handleChange("page_number", value);
  };

  const handleDeleteFreelancer = async () => {
    const data = {
      black_list: {
        black_listed_id: open?.data?.freelancer?.id,
        black_listed_type: "DiveExpert", // DiveActivity, DiveCenter, DiveExpert
      },
    };

    const action = () => {
      setData((prevData) =>
        prevData.filter((data, idx) => idx != open?.data?.index)
      );
      setOpen((s) => {
        return { state: false, data: null };
      });
    };

    deleteFreelancer(data, action, setLoading);
  };

  return (
    <Grid container>
      <Grid container className="table-header mb-20px">
        <Grid item md={0.5} textAlign="center">
          {t("joboffer.id")}
        </Grid>
        <Grid item md={1}>
          {t("joboffer.image")}
        </Grid>
        <Grid item md={2}>
          {t("joboffer.freelancerName")}
        </Grid>
        <Grid item md={1.5}>
          {t("reservations.branchName")}
        </Grid>
        <Grid item sm={1}>
          {t("joboffer.workingDate")}
        </Grid>
        <Grid item sm={1.5}>
          {t("joboffer.workingEndDate")}
        </Grid>
        <Grid item sm={1.5}>
          {t("joboffer.status")}
        </Grid>
        <Grid item sm={1}>
          {t("joboffer.rate")}
        </Grid>
        <Grid item sm={2}>
          {t("joboffer.action")}
        </Grid>
      </Grid>
      {freelancers?.length === 0 && (
        <Grid container display="block" textAlign="center">
          {t("joboffer.noFreelancers")}
        </Grid>
      )}
      {!loading ? (
        freelancerData?.map((freelancer, index) => (
          <Grid
            key={freelancer?.id}
            // onClick={() => Navigate('/reservations/activities/activity-groups/' + freelancer?.id)}
            container
            item
            lg={12}
            className="activity-item mb-20px"
          >
            <Grid item md={0.5} textAlign="center">
              {freelancer?.id}
            </Grid>
            <Grid item md={1}>
              <Avatar
                src={freelancer?.avatar}
                alt="avatar"
                style={{ width: "53px", height: "53px", borderRadius: "10px" }}
              />
            </Grid>
            <Grid
              item
              md={2}
              onClick={() =>
                navigate(`/freelancers/${freelancer?.id}?page=${pageNumber}`)
              }
              sx={{
                textDecoration: "underline",
                fontWeight: "bold",
                textTransform: "capitalize",
              }}
            >
              {freelancer?.firstname} {freelancer?.lastname}
            </Grid>
            <Grid
              item
              md={1.5}
            >
              {freelancer?.branch_name}
            </Grid>

            <Grid item sm={1}>
              {moment(freelancer?.working_start_date).format("MMM DD,YYYY")}
            </Grid>
            <Grid item sm={1.5}>
              {moment(freelancer?.working_end_date).format("MMM DD,YYYY")}
            </Grid>
            <Grid
              item
              sm={1.5}
              color={
                freelancer?.status == "X Freelancer" ? "#e10007" : "#00b906"
              }
            >
              {freelancer?.status}
            </Grid>
            <Grid item sm={1}>
              {freelancer?.rate} {freelancer?.currency?.lookup_key}
            </Grid>
            <Grid item container sm={2}>
              <Grid item sm={5}>
                {userType !== "descendant_user" && (
                  <Favourate
                    freelancer={freelancer}
                    freelancerData={freelancerData}
                    setData={setData}
                    setLoading={setLoading}
                    index={index}
                    canCreate={true}
                    canDalete={true}
                  />
                )}
                {userType === "descendant_user" && (
                  <Favourate
                    freelancer={freelancer}
                    freelancerData={freelancerData}
                    setData={setData}
                    setLoading={setLoading}
                    index={index}
                    canCreate={freelancersPrivilages?.can_create}
                    canDalete={freelancersPrivilages?.can_delete}
                  />
                )}
              </Grid>
              <Grid item sm={5}>
                {userType === "descendant_user" &&
                  freelancersPrivilages?.can_delete && (
                    <PrimaryButton
                      btnType="icon"
                      startIcon={
                        <img width="35" src={icons.DeleteIcon} alt="delete" />
                      }
                      click={() =>
                        setOpen({
                          state: true,
                          data: {
                            freelancer,
                            index,
                          },
                        })
                      }
                    />
                  )}
                {userType !== "descendant_user" && (
                  <PrimaryButton
                    btnType="icon"
                    startIcon={
                      <img width="35" src={icons.DeleteIcon} alt="delete" />
                    }
                    click={() =>
                      setOpen({
                        state: true,
                        data: {
                          freelancer,
                          index,
                        },
                      })
                    }
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        ))
      ) : (
        <Loader open={loading} />
      )}
      {count > 10 && (
        <Grid container justifyContent="center" mb={7.5} mt={5}>
          <CustomPagination
            count={Math.ceil(count / 10)}
            size="large"
            shape="rounded"
            page={page}
            handleChange={handlePageChange}
            className="pagination"
          />
        </Grid>
      )}

      {open?.state && (
        <DeleteApplicantModal
          open={open}
          setOpen={setOpen}
          handelDeleteRequest={handleDeleteFreelancer}
        />
      )}
    </Grid>
  );
};

export default withTranslation()(ListFreelancers);
