import { Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import { NavLink, useSearchParams } from "react-router-dom";
import NormalTabs from "../../../Components/Atoms/Tabs/NormalTabs";
import ReviewCard from "../../../Components/Organisms/Reviews/card";
import ProgressRating from "../../../Pages/Main/Branches/ProgressRating";
import CustomPagination from "../../../Components/Atoms/Pagination/Pagination";

const BranchReviewsTemplate = ({
  t,
  reviewsList,
  branchDetails,
  reviewsDetails,
  count,
  handlePageChange,
  page,
  setFilterObj,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  let pageNumber =
    +searchParams.get("page") == 0 ? 1 : +searchParams.get("page");

  const [option, setOption] = useState(0);
  useEffect(() => {
    searchParams.get("type") == "customer" ? setOption(0) : setOption(1);
  }, [searchParams]);

  const options = [
    {
      id: 1,
      label: "Customer",
      value: "Customer",
    },
    {
      id: 2,
      label: "Freelancer",
      value: "DiveExpert",
    },
  ];

  const handelTabClick = (value) => {
    setOption(value);
    setFilterObj({ page_number: 1 });
    value == 0
      ? setSearchParams({ type: "customer", page: 1 })
      : setSearchParams({ type: "freelancer", page: 1 });
  };

  useEffect(() => {
    setSearchParams({ type: "customer", page: 1 });
  }, []);

  return (
    <Container maxWidth="XL">
      <Stack flexDirection="row" alignItems="center" gap={1} mb={2}>
        <NavLink
          to={`/branches?page=${pageNumber}`}
          style={{ textDecoration: "none" }}
        >
          <Typography fontSize="18px" color="#bebebe">
            {t("branch.branches")} {">"}
          </Typography>
        </NavLink>
        <NavLink
          to={`/branches/details/${branchDetails?.id}?branchType=${branchDetails?.branch_type}?page=${pageNumber}`}
          style={{ textDecoration: "none" }}
        >
          <Typography fontSize="18px" color="#bebebe">
            {branchDetails?.name} {">"}
          </Typography>
        </NavLink>
        <Typography fontSize="18px" color="#bebebe">
          {t("profile.ratingAndReview")}
        </Typography>
      </Stack>
      <Stack flexDirection="row" alignItems="center" gap={1} mb={2}>
        <Typography
          fontSize="30px"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {t("profile.ratingAndReview")}
        </Typography>

        <Typography color="#3ed181" mx={3}>
          {" "}
          ( {reviewsDetails?.total_reviews} {t("profile.review")} )
        </Typography>

        <NormalTabs
          type={"add"}
          // formType={formType}
          TabsOptions={options}
          value={option}
          change={(data) => setOption(data)}
          ContainerStyle="switch-container"
          TabStyle="switch-btn mb-20px"
          onclick={handelTabClick}
        />
      </Stack>
      <Grid container xs={12} gap={2}>
        <Grid
          item
          className="activity-form"
          flexDirection="column"
          md={7}
          m="0"
          my="10px"
        >
          {reviewsList?.length > 0 ? (
            reviewsList?.map((reviewData, index) => {
              return (
                <ReviewCard
                  reviewData={reviewData}
                  key={index}
                  visibleDivider={
                    index !== reviewsList?.length - 1 ? true : false
                  }
                />
              );
            })
          ) : (
            <Stack alignItems="center" justifyContent="center" h="100%">
              <Typography>{t("branch.noReviewsMsg")}</Typography>
            </Stack>
          )}
          {count > 10 && (
            <Grid container justifyContent="center" mb={7.5} mt={5}>
              <CustomPagination
                count={Math.ceil(count / 10)}
                size="large"
                shape="rounded"
                page={page}
                handleChange={handlePageChange}
                className="pagination"
              />
            </Grid>
          )}
        </Grid>

        <Grid
          item
          className="activity-form"
          flexDirection="column"
          md={4}
          m="0"
          my="10px"
        >
          <ProgressRating reviews={reviewsDetails} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default withTranslation()(BranchReviewsTemplate);
