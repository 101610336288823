import { Grid, Typography } from "@mui/material";
import "./Panels.css";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { useSearchParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { withTranslation } from "react-i18next";
const BoatFeatures = (props) => {
  const { t, boatFeatures } = props;
  const [search] = useSearchParams();

  return (
    <Grid container className="details-panel">
      {boatFeatures?.length > 0 && (
        <Typography className="mb-20px" component="h1">
          {search?.get("branchType") == "office"
            ? t("branch.diveCenterFeatures")
            : t("branch.safariBoatFeatures")}
        </Typography>
      )}
      <Grid container>
        {boatFeatures?.map((info, index) => {
          return (
            <Grid container className="mb-10px" key={index}>
              {info?.enabled ? (
                <CheckOutlinedIcon className="check-icon" />
              ) : (
                <CloseIcon
                  color="error"
                  sx={{
                    fontSize: "20px !important",
                    margin: "0 10px !important",
                  }}
                />
              )}
              <Typography>{info?.name}</Typography>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default withTranslation()(BoatFeatures);