import React from "react";
import Roles from "../../../../Components/Organisms/Users/Roles/Roles";

function RolesTemplate(props) {
  const {
    updatePagination,
    filterObj,
    setFilterObj,
    rolesData,
    setRolesData,
    openLoader,
    setOpenLoader,
    count,
    page,
    handlePageChange,
    usersPrivilages,
  } = props;
  return (
    <Roles
      filterObj={filterObj}
      setFilterObj={setFilterObj}
      rolesData={rolesData}
      setRolesData={setRolesData}
      openLoader={openLoader}
      setOpenLoader={setOpenLoader}
      count={count}
      page={page}
      handlePageChange={handlePageChange}
      usersPrivilages={usersPrivilages}
      updatePagination={updatePagination}
    />
  );
}

export default RolesTemplate;
