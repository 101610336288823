import Pagination from "@mui/material/Pagination";

import "./Pagination.css";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

export default function CustomPagination({
  count,
  size,
  shape,
  className,
  updatePagination,
  handlePageChange,
  handleChange
}) {
  let [searchParams, setSearchParams] = useSearchParams();
  // const [page, setPage] = useState(null);
  const [page, setPage] = useState(0);

  useEffect(() => {
    +searchParams.get("page") == 0
      ? setPage(1)
      : setPage(+searchParams.get("page"));
  }, [searchParams.get("page")]);
  return (
    <Pagination
      count={count}
      size={size}
      className={className}
      sx={{
        ".MuiPaginationItem-previousNext": {
          color: "#f6b119",
        },
        ".MuiPaginationItem-page": {
          bgcolor: "#f2f5f7",
        },
        ".Mui-selected": {
          color: "white !important",
          bgcolor: "#f6b119 !important",
        },
      }}
      shape={shape}
      page={page}
      onChange={(e, page) => {
        setSearchParams({ page: page });
        updatePagination && updatePagination(page);
        handlePageChange && handlePageChange(page);
        handleChange && handleChange(e, page);
        //setPage(page);
      }}
    />
  );
}
