import { Grid, Box, Stack, Typography } from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import CustomPagination from "../../Atoms/Pagination/Pagination.js";
import { useEffect, useState } from "react";
import ConfirmPaymentModel from "./Models/ConfirmPayment.jsx";
import {
  getGroupReservationsListData,
  getReservationsList,
  getSystemConfigDetails,
  updatePaymentStatus,
} from "../../../Contexts/APIs/Reservations/Reservations.js";
import RejectReservationsModel from "./Models/RejectReservations.jsx";
import ListPrivateGuideReservations from "./List.jsx";
import ActivityCompletedModel from "./Models/ActivityCompleted.jsx";
import ReservationDetailsModel from "./Models/ReservationDetails.jsx";
import ConfirmReservationModel from "./Models/ConfirmReservation.jsx";
import CancelReservationsModel from "./Models/CancelReservations.jsx";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { icons } from "../../../Assets/AssetHelper.js";

const MainList = (props) => {
  const {
    count,
    updatePagination,
    activitesList,
    updatePayment,
    ordersPrivilages,
    setOpenLoader,
    setReservationsData,
    reservationsDuration,
    filterObj,
    page,
    setPage,
    ReservationsPageListener,
    joboffersPrivilages,
    t,
  } = props;
  const { id } = useParams();
  const [selectedData, setSelectedData] = useState(false);
  const [listData, setListData] = useState([]);
  const [openConfirmPayment, setOpenConfirmPayment] = useState(false);
  const [openConfirmReservations, setOpenConfirmReservations] = useState(false);
  const [openRejectReservations, setOpenRejectReservations] = useState(false);
  const [openCancelReservations, setOpenCancelReservations] = useState(false);
  const [openCompleteActivity, setOpenCompleteActivity] = useState(false);
  const [openReservationDetails, setOpenReservationDetails] = useState(false);
  const [systemConfig, setSystemConfig] = useState({});

  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setPage(searchParams.get("page"));
    updatePagination(searchParams.get("page"));
  }, [searchParams.get("page")]);

  const [reservationsStatus, setReservationsStatus] = useState({});

  const handelConfirmReservation = async () => {
    const { res, err } = await updatePaymentStatus(
      selectedData?.activityItem?.id,
      {
        payment_transaction: {
          status: "confirmed",
        },
      }
    );
    if (res) {
      setOpenConfirmReservations(false);
      setReservationsStatus((prevStatus) => ({
        ...prevStatus,
        [`${selectedData?.activityItem?.id}`]: {
          id: selectedData?.activityItem?.id,
          status: res?.data?.order?.status,
          status_display: {
            display_color: res?.data?.order?.status_display?.display_color,
            display_name: res?.data?.order?.status_display?.display_name,
          },
        },
      }));
    }
  };
  const handelConfirmPayment = async () => {
    const { res, err } = await updatePaymentStatus(
      selectedData?.activityItem?.id,
      {
        payment_transaction: {
          status: "payment_success",
        },
      }
    );
    if (res) {
      setOpenConfirmPayment(false);
      setReservationsStatus((prevStatus) => ({
        ...prevStatus,
        [`${selectedData?.activityItem?.id}`]: {
          id: selectedData?.activityItem?.id,
          status: res?.data?.order?.status,
          status_display: {
            display_color: res?.data?.order?.status_display?.display_color,
            display_name: res?.data?.order?.status_display?.display_name,
          },
        },
      }));
    }
  };
  const handelRejectReservations = async () => {
    const { res, err } = await updatePaymentStatus(
      selectedData?.activityItem?.id,
      {
        payment_transaction: {
          status: "rejected",
        },
      }
    );
    if (res) {
      setOpenRejectReservations(false);
      setReservationsStatus((prevStatus) => ({
        ...prevStatus,
        [`${selectedData?.activityItem?.id}`]: {
          id: selectedData?.activityItem?.id,
          status: res?.data?.order?.status,
          status_display: {
            display_color: res?.data?.order?.status_display?.display_color,
            display_name: res?.data?.order?.status_display?.display_name,
          },
        },
      }));
    }
  };
  const handelActivityCompleted = async () => {
    const { res, err } = await updatePaymentStatus(
      selectedData?.activityItem?.id,
      {
        payment_transaction: {
          status: "completed",
        },
      }
    );
    if (res) {
      setOpenCompleteActivity(false);
      setReservationsStatus((prevStatus) => ({
        ...prevStatus,
        [`${selectedData?.activityItem?.id}`]: {
          id: selectedData?.activityItem?.id,
          status: res?.data?.order?.status,
          status_display: {
            display_color: res?.data?.order?.status_display?.display_color,
            display_name: res?.data?.order?.status_display?.display_name,
          },
        },
      }));
    }
  };

  const getSystemConfig = async () => {
    const { res, err } = await getSystemConfigDetails(
      "auto_rejection_duration"
    );
    if (res) {
      setSystemConfig(res?.data?.system_configuration);
    }
  };
  const handelCancelReservation = async () => {
    const { res, err } = await updatePaymentStatus(
      selectedData?.activityItem?.id,
      {
        payment_transaction: {
          status: "cancelled",
        },
      }
    );
    if (res) {
      setOpenCancelReservations(false);
      setReservationsStatus((prevStatus) => ({
        ...prevStatus,
        [`${selectedData?.activityItem?.id}`]: {
          id: selectedData?.activityItem?.id,
          status: res?.data?.order?.status,
          status_display: {
            display_color: res?.data?.order?.status_display?.display_color,
            display_name: res?.data?.order?.status_display?.display_name,
          },
        },
      }));
    }
  };

  useEffect(() => {
    if (activitesList) setListData(activitesList);
    // getSystemConfig();
  }, [activitesList]);

  useEffect(() => {
    getSystemConfig();
  }, []);

  return (
    <Grid container width="100%">
      <Stack
        flexDirection="row"
        width="100%"
        className="table-header mb-20px"
        gap="5px"
      >
        <Grid md={1} textAlign="center">
          {t("reservations.id")}
        </Grid>
        <Grid md={2}>{t("reservations.customerName")}</Grid>
        <Grid md={2}>{t("reservations.diveExpertName")}</Grid>
        <Grid md={2}>{t("reservations.activityName")}</Grid>
        <Grid md={2}>{t("reservations.activityDate")}</Grid>
        <Grid md={2}>{t("reservations.branchName")}</Grid>
        <Grid md={2}>{t("reservations.reservationDate")}</Grid>
        <Grid sm={2}>{t("reservations.refNumber")}</Grid>
        <Grid sm={1.5}>{t("reservations.seats")}</Grid>
        <Grid sm={2}>{t("reservations.totalPrice")}</Grid>
        <Grid sm={2}>{t("reservations.payment")}</Grid>
        <Grid sm={0.7}>{t("activity.paymentMethod")}</Grid>
        <Grid sm={1.8}>{t("reservations.paymentDateTime")}</Grid>
        <Grid sm={2}>{t("reservations.status")}</Grid>
        <Grid sm={2}>{t("reservations.action")}</Grid>
      </Stack>
      {listData?.length === 0 && (
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "200px",
          }}
        >
          <img
            src={icons.NoReservations}
            alt="no-branches"
            style={{ display: "block", width: "125.8px", height: "124.9px" }}
          />
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: "bold",
              color: "#333132",
              mt: "25px",
            }}
          >
            {t("offer.thereAreNoRecords")}
          </Typography>
        </Grid>
      )}
      {listData?.map((activityItem, index) => {
        return (
          <Box
            key={index}
            width="100%"
            onClick={() => {
              setSelectedData({ activityItem: activityItem, index: index });
            }}
          >
            <ListPrivateGuideReservations
              setSelectedData={setSelectedData}
              activityItem={activityItem}
              setOpenConfirmPayment={setOpenConfirmPayment}
              setOpenRejectReservations={setOpenRejectReservations}
              setOpenCancelReservations={setOpenCancelReservations}
              setOpenCompleteActivity={setOpenCompleteActivity}
              setOpenConfirmReservations={setOpenConfirmReservations}
              setOpenReservationDetails={setOpenReservationDetails}
              ordersPrivilages={ordersPrivilages}
              reservationsDuration={systemConfig?.value}
              reservationsStatus={reservationsStatus}
              joboffersPrivilages={joboffersPrivilages}
            />
          </Box>
        );
      })}
      {count > 10 && (
        <Grid container justifyContent="center" mb={7.5} mt={5} width="100%">
          <CustomPagination
            count={Math.ceil(count / 10)}
            size="large"
            shape="rounded"
            page={page}
            className="pagination"
          />
        </Grid>
      )}
      {openConfirmReservations && (
        <ConfirmReservationModel
          open={openConfirmReservations}
          setOpen={setOpenConfirmReservations}
          handelConfirmReservation={handelConfirmReservation}
        />
      )}
      {openConfirmPayment && (
        <ConfirmPaymentModel
          open={openConfirmPayment}
          setOpen={setOpenConfirmPayment}
          handelConfirmPayment={handelConfirmPayment}
        />
      )}

      {openCancelReservations && (
        <CancelReservationsModel
          open={openCancelReservations}
          setOpen={setOpenCancelReservations}
          handelCancelReservations={handelCancelReservation}
        />
      )}
      {openRejectReservations && (
        <RejectReservationsModel
          open={openRejectReservations}
          setOpen={setOpenRejectReservations}
          handelRejectReservations={handelRejectReservations}
        />
      )}
      {openCompleteActivity && (
        <ActivityCompletedModel
          open={openCompleteActivity}
          setOpen={setOpenCompleteActivity}
          handelActivityCompleted={handelActivityCompleted}
        />
      )}

      {openReservationDetails && (
        <ReservationDetailsModel
          open={openReservationDetails}
          setOpen={setOpenReservationDetails}
          orderId={selectedData?.activityItem?.id}
          customerData={selectedData?.activityItem?.user_info}
        />
      )}
    </Grid>
  );
};

export default withTranslation()(MainList);
