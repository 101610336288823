import { Grid, Typography, Divider } from "@mui/material";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import SearchableSelect from "../../Atoms/Select/SearchableSelect.jsx";
import "./AddReservationsHeader.css";

const AddReservationsHeader = (props) => {
  const { activityGroups, updateNumbersOfSeats, updateGroupId, t } = props;
  const [groups, setGroupes] = useState([]);
  const [groupValue, setGroupValue] = useState({});
  const [seats, setSeats] = useState("");
  const [selectedGroup, setSelectedGroup] = useState({});
  const [activityGroupId, setActivityGroupId] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const getActivitiyGroups = () => {
    activityGroups?.map((group) =>
      setGroupes((groups) => [
        ...groups,
        { name: group.start_date, id: group.id },
      ])
    );
  };

  // const getGroupValue = (e) => {
  //     setGroupValue(e.target.value);
  // };
  const getGroupValue = (val) => {
    setGroupValue({ value: val?.value, label: val?.label });
  };

  const getSelectValue = async (val) => {
    const groupObj = await activityGroups?.filter((item) => {
      return val == item.id;
    });
    setSelectedGroup(groupObj[0]);
    setSeats(groupObj[0]?.remaining_capacity);
    setActivityGroupId(groupObj[0]?.id);
  };

  useEffect(() => {
    updateNumbersOfSeats(seats);
  }, [seats]);

  useEffect(() => {
    updateGroupId(activityGroupId);
  }, [activityGroupId]);

  useEffect(() => {
    getActivitiyGroups();
    setGroupValue(searchParams.get("group_id"));
    getSelectValue(searchParams.get("group_id"));
  }, [activityGroups]);

  return (
    <Grid container>
      <Grid container md={12} justifyContent={"flex-start"} gap={2}>
        <Grid container sm={2} minWidth={"202px"} alignItems="center">
          <Grid>
            <Typography
              component="h1"
              style={{ fontWeight: "bold", fontSize: "30px" }}
            >
              {t("reservations.newReservation")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container sm={2} minWidth={"200px"} alignItems="center">
          <SearchableSelect
            label={t("reservations.selectActivityGroup")}
            name="groups"
            className="searchable-select groups-drop-down"
            options={groups}
            value={groupValue}
            onChange={(val) => {
              getGroupValue(val);
              getSelectValue(val);
            }}
          />
        </Grid>
        <Grid
          container
          sm={2}
          minWidth={"271px"}
          textAlign="center"
          alignItems="center"
        >
          <Typography
            component="p"
            color="#00b906"
            fontWeight="bolder"
            fontSize="18px"
            width="100%"
            lineHeight="2.5"
          >
            {`${t("reservations.availableNoSeats")} ${seats}`}
          </Typography>
        </Grid>
      </Grid>
      <Divider style={{ width: "100%", margin: "20px 0" }} />
    </Grid>
  );
};

export default withTranslation()(AddReservationsHeader);
