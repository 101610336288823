import { Button, Modal, Stack, Typography } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import "./DeleteModal.css"
import FormHead from "../../../Atoms/FormHead/FormHead";

const DeleteModal = ({
  t,
  customClass,
  open,
  setOpen,
  handelDeleteRequest,
  isUnassignActivity,
  text,
  buttonText,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "25px",
    p: 4,
  };

  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        sx={style}
        alignItems="center"
        justifyContent="center"
        className={`${customClass ? customClass : ""} delete-modal`}
      >
        {/* <Typography
          component="h1"
          style={{ color: "#333132", fontWeight: "bold", fontSize: "30px", borderBottom: "2px solid #f6b119" }}
          mb={1}
        >
          {text[0]}
        </Typography> */}
        <FormHead text={ text[0] }></FormHead>
        <Typography textAlign="center" style={{ fontWeight: "bold" }}>
          {text[1]}
        </Typography>

        <Stack flexDirection="row" alignItems="center" mt={4} gap={1} className="buttons-wrap">
          <Button
            style={{ width: "150px", height: "60px", textTransform: "none" }}
            sx={{ fontSize: "18px !important", textTransform: 'capitalize !important', backgroundColor: isUnassignActivity ? '#FA0D00 !important' : '#F6B119 !important' }}
            className="primary r-25px"
            onClick={handelDeleteRequest}
          >
            {buttonText ? buttonText[1] : t("offer.delete")}
          </Button>

          <Button
            style={{ width: "150px", height: "60px", color: "black", textTransform: "none" }}
            sx={{ fontSize: "18px !important", textTransform: 'capitalize !important', border: '1px solid #bebebe' }}
            className="r-25px"
            onClick={() => setOpen(false)}
          >
            {" "}
            {buttonText ? buttonText[0] : t("offer.cancel")}
          </Button>

          {/* <Button
            style={{ width: "150px" }}
            className="primary r-25px"
            onClick={handelDeleteRequest}
          >
            {buttonText ? buttonText[1] : t("offer.delete")}
          </Button> */}
        </Stack>
      </Stack>
    </Modal>
  );
};

export default withTranslation()(DeleteModal);
