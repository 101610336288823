import { Grid, Typography, Divider, Stack } from "@mui/material";
import "./Form.css";
import SelectBox from "../../Atoms/Select/Select.jsx";
import Map from "../Maps/GoogleMap.jsx";
import { CountryAPI } from "../../../Contexts/APIs/Lookups/Country.js";
import { useEffect, useState } from "react";
import { CityAPI } from "../../../Contexts/APIs/Lookups/City.js";
import { AreaAPI } from "../../../Contexts/APIs/Lookups/Area";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import TextFieldInput from "../../Atoms/Input/TextField/TextFieldInput";
import SearchableSelect from "../../Atoms/Select/SearchableSelect";
import SearchSelect from "../../Atoms/Select/SearchableSelect";
import { t } from "i18next";
const LocationForm = (props) => {
  const {
    formTitle,
    formik,
    getCurrentLocation,
    type,
    mapType,
    setMapType,
    selectedBranchData,
    location,
    defaultData,
  } = props;
  const [countries, setCounries] = useState([]);
  const [countriesCount, setCounriesCount] = useState(10);

  const [cities, setCities] = useState([]);
  const [citiesCount, setCitiesCount] = useState(10);

  const [areas, setAreas] = useState([]);
  const [areasCount,setAreasCount] = useState(0)
  const [countriesFilter, setCounriesFilter] = useState({
    page_size: 10,
    page_number: 1,
  });
  const [citiesFilter, setCitiesFilter] = useState({
    page_size: 10,
    page_number: 1,
  });
  const [areasFilter, setAreasFilter] = useState({
    page_size: 10,
    page_number: 1,
  });
  const [isActive, setActive] = useState(0);
  const [mapTitle, setMapTitle] = useState("Meeting Location");
  const [showMap, setShowMap] = useState(true);

  const MapTypes = [
    {
      label: "Add meeting point",
      value: 0,
    },
    {
      label: "Hotel pickup",
      value: 1,
    },
    {
      label: "Operator pickup",
      value: 2,
    },
  ];
  const handleChange = (i, val) => {
    setActive(i);
    setMapType(val);
    if (val === 0) {
      setMapTitle("Meeting Location");
      setShowMap(true);
    }
    if (val === 2) {
      setMapTitle("Operator Location");
      setShowMap(false);
    }
    if (val === 1) setShowMap(false);
  };
  const getCountries = async (values) => {
    setCities([]);
    const { res, err } = await CountryAPI({ ...countriesFilter, ...values });
    if (res) {
      values?.page_number == 1
        ? setCounries(res?.data?.countries)
        : setCounries((s) => [...s, ...res?.data?.countries]);

      setCounriesCount(res?.extra?.total_count);
    }
  };
  const getCities = async (filter) => {
    setAreas([]);
    setCitiesFilter({ ...citiesFilter, country_id: formik?.values?.country_id });
    const { res, err } = await CityAPI({
      ...citiesFilter,
      ...filter,
    });
    if (res) {
      filter?.page_number == 1
        ? setCities(res?.data?.cities)
        : setCities((s) => [...s, ...res?.data?.cities]);

      setCitiesCount(res?.extra?.total_count);
    }
  };
  const getAreas = async (filter) => {
    setAreasFilter({ ...areasFilter, city_id: formik?.values?.city_id });
    const { res, err } = await AreaAPI({
      ...areasFilter,
      ...filter,
    });
    if (res) {
      filter?.page_number == 1
        ? setAreas(res?.data?.areas) :
        setAreas((s) => [...s, ...res?.data?.areas]);
      setAreasCount(res?.extra?.total_count)
    }
  };

  useEffect(() => {
    // getCountries();
  }, []);

  useEffect(() => {
    if (formik?.values?.country_id?.toString()?.length > 0) {
      setTimeout(() => {
        getCities({ country_id: formik?.values?.country_id, page_number: 1 });
      }, 1000);
    } else {
      setAreas([]);
      setCities([]);
    }
  }, [formik?.values?.country_id]);
  useEffect(() => {
    if (formik?.values?.city_id?.toString()?.length > 0) {
      setTimeout(() => {
        getAreas({ city_id: formik?.values?.city_id, page_number: 1 });
      }, 1000);
    } else {
      setAreas([]);
    }
  }, [formik?.values?.city_id]);

  useEffect(() => {
    handleChange(mapType, mapType);
  }, [mapType]);
  return (
    <Grid container lg={12} className="activity-form">
      {type === "switch" && (
        <Grid container md={12} className="mb-20px">
          {MapTypes.map((type, index) => (
            <Grid container sm={4}>
              <Grid xs={2}>
                <Typography
                  onClick={() => handleChange(index, type.value)}
                  component="span"
                  className={
                    index === isActive
                      ? "custom-radio-btn active-select"
                      : "custom-radio-btn"
                  }
                ></Typography>
              </Grid>
              <Grid xs={10}>
                <Typography component="label">{type.label}</Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
      {showMap && (
        <>
          <Grid container md={12}>
            <Typography
              component="h1"
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                borderBottom: "2px solid var(--primary-color)",
              }}
              className="mb-20px"
            >
              {type === "switch" ? mapTitle : formTitle}
            </Typography>
          </Grid>
          <Grid
            container
            md={12}
            className="mb-20px"
            style={{ position: "relative", zIndex: "10" }}
          >
            <Grid sm={5.8}>
              <SearchableSelect
                name="country_id"
                options={countries}
                label={t("reservations.country")}
                count={countriesCount}
                className="searchable-select"
                getList={getCountries}
                handelMenuOpen={countries?.length == 0 && getCountries}
                defaultValues={defaultData?.country}
                error={
                  formik?.touched?.country_id &&
                  Boolean(formik?.errors?.country_id)
                }
                helperText={
                  formik?.touched?.country_id && formik?.errors?.country_id
                }
                onChange={(val) => {
                  formik.setValues({
                    ...formik?.values,
                    area_id: "",
                    area_name: "",
                    city_id: "",
                    city_name: "",
                    country_id: val?.value,
                    country_name: val?.label,
                  });
                }}
                onClear={() => {
                  formik.setValues({
                    ...formik?.values,
                    country_id: "",
                    country_name: "",
                    city_id: "",
                    city_name: "",
                  });
                }}
                value={{
                  label: formik?.values?.country_name,
                  value: formik?.values?.country_id,
                }}
              />
            </Grid>
            <Grid sm={0.4}></Grid>
            <Grid sm={5.8}>
              <SearchableSelect
                name="city_id"
                options={cities}
                label={t("reservations.province")}
                getList={getCities}
                count={citiesCount}
                defaultValues={defaultData?.city}
                moreFilterData={{ country_id: formik?.values.country_id }}
                className="searchable-select"
                error={
                  formik?.touched?.country_id &&
                  Boolean(formik?.errors?.city_id)
                }
                helperText={
                  formik?.touched?.country_id && formik?.errors?.city_id
                }
                onChange={(val) => {
                  formik.setValues({
                    ...formik?.values,
                    area_id: "",
                    area_name: "",
                    city_id: val?.value,
                    city_name: val?.label,
                  });
                }}
                onClear={() => {
                  formik.setValues({
                    ...formik?.values,
                    city_id: "",
                    city_name: "",
                    area_id: "",
                    area_name: "",
                  });
                }}
                value={{
                  label: formik?.values?.city_name,
                  value: formik?.values?.city_id,
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            className="mb-20px"
            style={{ position: "relative", zIndex: "9" }}
          >
            <Grid item sm={12}>
              <SearchableSelect
                name="area_id"
                options={areas}
                count={areasCount}
                getList={getAreas}
                moreFilterData={{ city_id: formik?.values?.city_id }}
                defaultValues={defaultData?.area}
                label="City"
                className="searchable-select"
                onChange={(val) => {
                  formik.setValues({
                    ...formik?.values,
                    area_id: val?.value,
                    area_name: val?.label,
                  });
                }}
                value={{
                  label: formik?.values?.area_name,
                  value: formik?.values?.area_id,
                }}
                onClear={() => {
                  formik.setValues({
                    ...formik?.values,
                    area_id: "",
                    area_name: "",
                  });
                }}
              />
            </Grid>
          </Grid>
          {/* <Grid container md={12} className="mb-20px">
            <SelectBox
              id="area_id"
              name="area_id"
              items={areas}
              title={t("reservations.city")}
              className="select"
              value={formik?.values.area_id ? formik?.values.area_id : ""}
              error={formik?.touched.area_id && Boolean(formik?.errors.area_id)}
              helperText={formik?.touched.area_id && formik?.errors.area_id}
              onchange={formik?.handleChange}
              onclick={(val) =>
                formik.setValues({ ...formik?.values, area_id: val?.id })
              }
            />
          </Grid> */}
          <Grid container md={12} className="mb-20px">
            <Map
              lat={location?.lat}
              lng={location?.lng}
              getCurrentLocation={getCurrentLocation}
              type="add"
            />
            <Typography>
              <span style={{ color: "red", fontWeight: "bold" }}>Tip:</span> if
              you didn't choose a location your current location is chosen
            </Typography>
          </Grid>
        </>
      )}
      {isActive == 2 && (
        <Grid container md={12} className="mb-20px">
          {selectedBranchData ? (
            <Stack width="100%">
              <Map
                lat={selectedBranchData?.lat}
                lng={selectedBranchData?.long}
                getCurrentLocation={getCurrentLocation}
                type="show"
              />
              <Stack width="100%" flexDirection="row" gap={1}>
                <FmdGoodIcon style={{ color: "#f6b119" }} />
                <Typography>
                  {selectedBranchData?.area?.name} ,
                  {selectedBranchData?.city?.name} ,
                  {selectedBranchData?.country?.name}{" "}
                </Typography>
              </Stack>
            </Stack>
          ) : (
            <Stack alignItems="center" justifyContent="center" width="100%">
              <Typography>Please choose Branch First</Typography>
            </Stack>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default LocationForm;
