import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState, useMemo, useEffect, useRef } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { withTranslation } from "react-i18next";
import ClearIcon from "@mui/icons-material/Clear";
import "./SearchSelect.css";
export const isScrolledToBottom = (e, extra = 0) => {
  const bottom =
    e.target.scrollHeight - e.target.clientHeight <= e.target.scrollTop + extra;
  return bottom;
};
const SearchSelect = ({
  className,
  value,
  onchange,
  onclear,
  title,
  items,
  onclick = () => {},
  name,
  label,
  id,
  style,
  IconComponent,
  InputLabelStyle,
  t,
  handelOpen,
  getList,
  moreFilterData,
  defaultValues,
  count = 10,
}) => {
  const [searchText, setSearchText] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  // const listInnerRef = useRef();

  useEffect(() => {
    const timeId = setTimeout(() => {
      if (getList && searchText) {
        getList({ keyword: searchText, ...moreFilterData });
      }
    }, 700);
    return () => clearTimeout(timeId);
  }, [searchText]);

  let filteredItems = items?.filter((data) => data?.id != defaultValues?.id);
  let concatedArr = filteredItems?.concat(defaultValues);

  const ClearIndicator = () => {
    return (
      <IconButton
        onClick={() => {
          setSearchLoading(true);
          setSearchText((prev) => (prev = null));
          onclear && onclear();
          setTimeout(() => {
            setSearchLoading(false);
          }, 1);
        }}
        style={{
          position: "absolute",
          top: "calc(50% - 10px)",
          right: "35px",
          padding: "0",
          zIndex: "99",
          width: "20px",
        }}
      >
        <ClearIcon
          sx={{
            color: "hsl(0, 0%, 80%) !important",
            fontSize: "20px !important",
            "&:hover": { color: "hsl(0, 0%, 60%) !important" },
            "&:focus": { color: "hsl(0, 0%, 20%) !important" },
          }}
        />
      </IconButton>
    );
  };
  // const handleMenuScrollToBottom = () => {
  //   console.log(items.length, count,'pagination');
  //   if (items?.length < count) {
  //     getList({
  //       page_number: Math?.ceil(items?.length / 10 + 1),
  //     });
  //   }
  //   // handle pagination scroll
  //   /*const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
  //   console.log('hello scroll', scrollTop, scrollHeight, clientHeight);
  //   if (items?.length < count && (scrollTop + clientHeight === scrollHeight)) {
  //     getList({
  //       page_number: Math?.ceil(items?.length / 10 + 1),
  //     });
  //   }*/
  // };
  const handleMenuScrollToBottom = (e) => {
    const isBottom = isScrolledToBottom(e);
    if (isBottom && getList) {
      if (items?.length < count) {
        /* for edit form, initial values has to be a part of the items so they can be displayed,
         so we add the initial values manually to the items[] 
         This might cause the items.length to -sometimes- equal 11/21/31 not 10/20/30 which will mess the pagination
         that's why we have different logic to handle the page_number */
        let page_number;
        if (Number.isInteger(items?.length / 10)) {
          page_number = Math?.ceil(items?.length / 10 + 1);
        } else {
          page_number = Math?.ceil(items?.length / 10);
        }
        getList({
          page_number,
        });
        return;
      }
    }
  };
  return (
    <FormControl style={style} fullWidth>
      <InputLabel
        id={id}
        style={
          InputLabelStyle?.normal
            ? InputLabelStyle?.normal
            : { fontWeight: "400", fontSize: "16px", color: "rgb(153 153 153)" }
        }
        sx={
          InputLabelStyle?.focus
            ? InputLabelStyle?.focus
            : {
                "&.MuiInputLabel-shrink, &.Mui-focused": {
                  color: "black",
                  fontSize: "13px",
                  backgroundColor: "#fff",
                },
              }
        }
      >
        {label}
      </InputLabel>
      <Select
        // ref={listInnerRef}
        className={`react-select-containe  ${className}`}
        name={name}
        value={value}
        onScroll={handleMenuScrollToBottom}
        MenuProps={{
          PaperProps: {
            onScroll: (event) => {
              // onMenuScrollToBottom && onMenuScrollToBottom(event);
              handleMenuScrollToBottom(event);
            },
            sx: {
              height:
                items?.length >= 10
                  ? "250px"
                  : items?.length === 0
                  ? "70px"
                  : "auto", // Set your desired height here
            },
          },
        }}
        // handle pagination scroll
        /*MenuProps={{ 
          autoFocus: false,
          PaperProps: {
          onScroll: (event) => {
            console.log(event);
            console.log("we scroll");
            handleMenuScrollToBottom();
          }
          },
        }}*/
        labelId="search-select-label"
        id="search-select"
        onChange={onchange}
        onOpen={handelOpen && handelOpen}
        // onClose={() => setSearchText("")}
        IconComponent={IconComponent}
      >
        <ListSubheader>
          {!searchLoading && (
            <TextField
              size="small"
              // Autofocus on textfield
              autoFocus
              placeholder={t("profile.typeSearch")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: searchText ? (
                  <InputAdornment position="end">
                    <ClearIndicator />
                  </InputAdornment>
                ) : null,
              }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
            />
          )}
        </ListSubheader>
        <MenuItem value="" disabled style={{ direction: "ltr" }}>
          {title}
        </MenuItem>
        {concatedArr?.map((item) => (
          <MenuItem
            value={item?.id}
            key={item?.id}
            onClick={() => onclick(item?.id)}
            style={{ direction: "ltr" }}
          >
            {item?.icon ? (
              <img
                style={{ margin: "0 10px" }}
                src={item?.icon}
                alt="item icon"
              />
            ) : (
              ""
            )}{" "}
            {item?.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default withTranslation()(SearchSelect);
