import { Grid } from "@mui/material";
import ActivityGroupInfo from "../../Molecules/Reservations/ActivityGroupInfo.jsx";
import "./Reservations.css";
import ActivityGroupHeader from "../../Molecules/Reservations/ActivityGroupHeader.jsx";
import ListGroupsForActivities from "../../Molecules/Reservations/List/ListGroupsForActivities.jsx";
import { useState, useEffect } from "react";

const ActivityGroups = (props) => {
  const {
    getActivityGroups,
    count,
    activityGroups,
    activityInfo,
    isInHouseActivity,
    isAssignedActivity,
    assignedActivityName,
    salesmanId,
    salesmanName,
    setStartDate,
    setEndDate,
    page,
    setPage,
  } = props;

  const [filterObj, setFilterObj] = useState({
    page_size: 10,
    page_number: 1,
    status: "",
    "date[from]": "",
    "date[to]": "",
    sort_by: "id",
  });

  const [activityGropPage, setActivityGropPage] = useState(1);

  const updatePagination = (page) => {
    setFilterObj({ ...filterObj, page_number: page });
  };
  const updateStartDate = (date) => {
    if (!date?.includes("Invalid")) {
      setStartDate && setStartDate(date);
      setPage ? setPage(1) : setActivityGropPage(1);
      setFilterObj({ ...filterObj, page_number: 1, "date[from]": date });
    }
  };
  const updateEndDate = (date) => {
    if (!date?.includes("Invalid")) {
      setEndDate && setEndDate(date);
      setPage ? setPage(1) : setActivityGropPage(1);
      setFilterObj({ ...filterObj, page_number: 1, "date[to]": date });
    }
  };
  const updateGroupStatus = (value) => {
    setPage ? setPage(1) : setActivityGropPage(1);
    setFilterObj({ ...filterObj, page_number: 1, status: value });
  };

  useEffect(() => {
    getActivityGroups(filterObj);
  }, [filterObj, page, activityGropPage]);

  return (
    <Grid container className="reservations-container">
      <ActivityGroupInfo
        activityInfo={activityInfo}
        isInHouseActivity={isInHouseActivity}
        isAssignedActivity={isAssignedActivity}
        assignedActivityName={assignedActivityName}
        salesmanId={salesmanId}
        salesmanName={salesmanName}
      />
      <ActivityGroupHeader
        updateStartDate={updateStartDate}
        updateEndDate={updateEndDate}
        updateGroupStatus={updateGroupStatus}
        isInHouseActivity={isInHouseActivity}
        isAssignedActivity={isAssignedActivity}
      />
      <ListGroupsForActivities
        updatePagination={updatePagination}
        count={count}
        page={page ? page : activityGropPage}
        setPage={setPage ? setPage : setActivityGropPage}
        activityGroups={activityGroups}
        isInHouseActivity={isInHouseActivity}
        isAssignedActivity={isAssignedActivity}
      />
    </Grid>
  );
};

export default ActivityGroups;
