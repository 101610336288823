import { Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { withTranslation } from 'react-i18next'
import TextFieldInput from '../../../../Atoms/Input/TextField/TextFieldInput'

const AboutOperator = ({ t,formik }) => {
    return (
        <Grid container className='activity-form' gap={2} width='100%'>
            <Typography
                component="h1"
                style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    borderBottom: "2px solid var(--primary-color)",
                    width: 'fit-content'

                }}
                className="mb-20px"
            >
                {t('profile.aboutOperator')}
            </Typography>

            <TextFieldInput
                name="en.about_us"
                id="about_us"
                placeholder={t('profile.describeYourOperator')}
                className='login-input '
                type="text"
                style={{ width: '100%' }}
                rows={5}
                multiline
                onChange={formik?.handleChange}
                value={formik?.values?.en?.about_us}
                error={formik?.touched?.en?.about_us && Boolean(formik?.errors?.en?.about_us)}
                helperText={formik?.touched?.en?.about_us && formik?.errors?.en?.about_us}
                clickEnter={formik?.handleSubmit}
            />
        </Grid>
    )
}

export default withTranslation() (AboutOperator)