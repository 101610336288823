import { useState } from "react";
import Loader from "../../../Components/Atoms/Loader/Loader.js";
import { getRequestedActivitiesList } from "../../../Contexts/APIs/RequestedActivities/Activities.js";
import ApprovedActivitiesTemplate from "../../../Templates/Main/ApprovedActivity/ApprovedActivity.template.jsx";
import { useEffect } from "react";

const ApprovedActivitiesPage = (props) => {
  const { t, profileData, requestedActivitiesPrivilages } = props;
  const [activitesList, setActivitiesList] = useState([]);
  const [count, setCount] = useState();
  const [openLoader, setOpenLoader] = useState(true);

  const [filterObj, setFilterObj] = useState({
    status: ["accepted"],
    page_size: 10,
    page_number: 1,
    branch_type: "",
    keyword: "",
    sort_by: "id",
  });
  const ActivitiesList = async (filterObj) => {
    setOpenLoader(true);
    const { res, err } = await getRequestedActivitiesList(filterObj);
    if (res) {
      setActivitiesList(res?.data?.activity_requests);
      setCount(res?.extra?.total_count);
    }
    if (err) {
    }
    setOpenLoader(false);
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      ActivitiesList(filterObj);
    }, 500);
    return () => clearTimeout(timeId);
  }, [filterObj]);
  return (
    <>
      <Loader open={openLoader} />
      <ApprovedActivitiesTemplate
        activitesList={activitesList}
        count={count}
        getActivities={ActivitiesList}
        setActivitiesList={setActivitiesList}
        requestedActivitiesPrivilages={requestedActivitiesPrivilages}
        setOpenLoader={setOpenLoader}
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        profileData={profileData}
      />
    </>
  );
};

export default ApprovedActivitiesPage;
