import { Grid } from '@mui/material'
import React from 'react'
import ChangePasswordForm from '../../Components/Organisms/Auth/ChangePassword/ChangePasswordForm'
import AuthLayout from '../../Components/Organisms/Auth/Layout'
import { withTranslation } from 'react-i18next'

const ChangePasswordTemplate = ({ t, ChangePassword }) => {
    return (
        <AuthLayout title={t("profile.welcomeDivingWorld")} subTitle={t("profile.addNewPassword")}>

            <Grid container>
                <Grid
                    container
                    xl={4}
                    lg={5}
                    md={5}
                    sm={11}
                    xs={12}
                    style={{ margin: "auto" }}
                >
                    <ChangePasswordForm ChangePassword={ChangePassword} />
                </Grid>

            </Grid>
        </AuthLayout>
    )
}

export default ChangePasswordTemplate