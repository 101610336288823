import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";
import { makeFilterString } from "../Helper/Helper.js";

export async function getRequestedActivitiesList(filter_obj) {
  return await axiosFn(
    "get",
    URL.dive_expert_requests + makeFilterString(filter_obj)
  )
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function getDiveExpertList(filter_obj) {
  return await axiosFn(
    "get",
    URL.dive_expert_list + makeFilterString(filter_obj)
  )
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function editRequestedActivity(activityId, activity_data) {
  return await axiosFn(
    "put",
    URL.dive_expert_requests + "/" + activityId,
    activity_data
  )
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function getRequestedActivityDetails(activityId) {
  return await axiosFn("get", URL.dive_expert_requests + "/" + activityId)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function deleteRequestedActivity(activityId) {
  return await axiosFn("delete", URL.dive_expert_requests + "/" + activityId)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
