import React, { useState, useEffect } from "react";
import { getRoleList } from "../../../../Contexts/APIs/Users/Roles";
import RolesTemplate from "../../../../Templates/Main/Users/Roles/Roles.template";
import { useSearchParams } from "react-router-dom";

function RolesPage({ usersPrivilages }) {
  const [rolesData, setRolesData] = useState([]);
  const [openLoader, setOpenLoader] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  let [searchParams, setSearchParams] = useSearchParams();

  const [filterObj, setFilterObj] = useState({
    page_size: 10,
    page_number: searchParams.get("page"),
    keyword: "",
  });

  const updatePagination = (page) => {
    setFilterObj({ ...filterObj, page_number: page });
  };

  useEffect(() => {
    setPage(searchParams.get("page"));
    // updatePagination(searchParams.get("page"));
  }, [searchParams.get("page")]);

  const getRolesData = async (filterObj) => {
    setOpenLoader(true);
    const { res, err } = await getRoleList(filterObj);
    if (res) {
      setOpenLoader(false);
      setRolesData(res?.data?.roles);
      setCount(res?.extra?.total_count);
    } else {
      setOpenLoader(false);
    }
  };

  useEffect(() => {
    getRolesData(filterObj);
  }, [filterObj]);

  return (
    <RolesTemplate
      filterObj={filterObj}
      setFilterObj={setFilterObj}
      rolesData={rolesData}
      setRolesData={setRolesData}
      openLoader={openLoader}
      setOpenLoader={setOpenLoader}
      count={count}
      page={page}
      usersPrivilages={usersPrivilages}
      updatePagination={updatePagination}
    />
  );
}

export default RolesPage;
