import React from "react";
import { withTranslation } from "react-i18next";
import OfferHeader from "../../../Components/Molecules/Offers/Header";
import OffersList from "../../../Components/Organisms/Offers/OffersList";
import { Stack, Typography } from "@mui/material";
import { icons } from "../../../Assets/AssetHelper";

const OffersTemplate = ({
  t,
  count,
  offers,
  type,
  handleChange,
  filterValue,
  setFilterValue,
  offersPrivilages,
  filterObj,
  setFilterObj,
  profileData
}) => {
  return (
    <>
      <OfferHeader
        type={type}
        title={t("offer.offers")}
        is_navigate
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        handleChange={handleChange}
        offersPrivilages={offersPrivilages}
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        profileData={profileData}
      />
      {offers?.length == 0 ? (
        <Stack
          width="100%"
          alignItems="center"
          justifyContent="center"
          height="500px"
          gap={2}
        >
          <img src={icons?.OffersEmpty} width="113px" />
          <Typography fontSize="30px" fontWeight="700">
            {t("offer.emptMsg")}
          </Typography>
        </Stack>
      ) : (
        <OffersList
          count={count}
          offers={offers}
          handleChange={handleChange}
          type={type}
          offersPrivilages={offersPrivilages}
        />
      )}
    </>
  );
};

export default withTranslation()(OffersTemplate);
