import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Loader from "../../../Components/Atoms/Loader/Loader";
import { getBranchesList } from "../../../Contexts/APIs/Branches/Branches";
import AddOfferTemplate from "../../../Templates/Main/Offers/AddOffer.template";
import { getActivitiesList } from "../../../Contexts/APIs/Activities/Activities";
import { useParams } from "react-router-dom";
import { showOffer } from "../../../Contexts/APIs/Offers/Offers";

const AddOfferPage = ({ formType ,profileData }) => {
  const [openLoader, setOpenLoader] = useState(false);
  const [offerDetails, setOfferDetails] = useState({});
  const [branchesList, setbranchesList] = useState([]);
  const [branchesCount, setbranchesCount] = useState(10);

  const [activitiesList, setActiviesList] = useState([]);
  const [activitiesCount, setActiviesCount] = useState(10);

  const { offer_id } = useParams();
  const userId =
    localStorage.getItem("userType") !== "descendant_user"
      ? localStorage.getItem("userId")
      : localStorage.getItem("ancestorId");

  const getBranches = async (filter) => {
    const filterData = {
      page_size: 10,
      page_number: 1,
      status: "approved",
      dive_center_id: userId,
      ...filter,
    };
    const { res, err } = await getBranchesList({
      ...filterData,
    });
    if (res) {
      filterData?.page_number == 1
        ? setbranchesList(res?.data?.branches)
        : setbranchesList((s) => [...s, ...res?.data?.branches]);
      setbranchesCount(res?.extra?.total_count);
    }
  };

  const getActivities = async (filter) => {
    const filterData = {
      page_size: 10,
      page_number: 1,
      ...filter,
    };
    const { res, err } = await getActivitiesList({
      ...filterData,
    });
    if (res) {
      filterData?.page_number == 1
        ? setActiviesList(res?.data?.activities)
        : setActiviesList((s) => [...s, ...res?.data?.activities]);
      setActiviesCount(res?.extra?.total_count);
    }
  };
  // useEffect(() => {
  //   if (userId) getBranches(userId);
  // }, [userId]);

  const getOfferData = async () => {
    const { res, err } = await showOffer(offer_id);
    if (res) {
      setOfferDetails(res?.data?.activity_offer);
    }
  };
  useEffect(() => {
    if (formType == "edit") getOfferData();
  }, [formType]);
  return (
    <>
      <Loader open={openLoader} />
      <AddOfferTemplate
        branchesCount={branchesCount}
        activitiesCount={activitiesCount}
        branchesList={branchesList}
        userId={userId}
        getBranches={getBranches}
        getActivities={getActivities}
        activitiesList={activitiesList}
        formType={formType}
        offerDetails={offerDetails}
        profileData={profileData}
      />
    </>
  );
};

export default AddOfferPage;
