import { Grid, Box, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import CustomPagination from "../../../Atoms/Pagination/Pagination.js";
import { useEffect, useState } from "react";
import ConfirmPaymentModel from "../Models/ConfirmPayment.jsx";
import {
  getGroupReservationsListData,
  getReservationsList,
  updatePaymentStatus,
} from "../../../../Contexts/APIs/Reservations/Reservations.js";
import RejectReservationsModel from "../Models/RejectReservations.jsx";
import OrdersList from "./list.jsx";
import ActivityCompletedModel from "../Models/ActivityCompleted.jsx";
import ReservationDetailsModel from "../Models/ReservationDetails.jsx";
import ConfirmReservationModel from "../Models/ConfirmReservation.jsx";
import CancelReservationsModel from "../Models/CancelReservations.jsx";
import { withTranslation } from "react-i18next";
import ReservationsPageList from "./ReservationsPageList.jsx";
import { icons } from "../../../../Assets/AssetHelper.js";

const DetailsList = (props) => {
  const {
    count,
    updatePagination,
    list,
    updatePayment,
    ordersPrivilages,
    setOpenLoader,
    setReservationsData,
    reservationsDuration,
    filterObj,
    page,
    setPage,
    ReservationsPageListener,
    t,
  } = props;
  const { id } = useParams();
  const [selectedData, setSelectedData] = useState(false);
  const [listData, setListData] = useState([]);
  const [openConfirmPayment, setOpenConfirmPayment] = useState(false);
  const [openConfirmReservations, setOpenConfirmReservations] = useState(false);
  const [openRejectReservations, setOpenRejectReservations] = useState(false);
  const [openCancelReservations, setOpenCancelReservations] = useState(false);
  const [openCompleteActivity, setOpenCompleteActivity] = useState(false);
  const [openReservationDetails, setOpenReservationDetails] = useState(false);
  const [completeActivityLoading, setCompleteActivityLoading] = useState(false)
  const handlePageChange = (event, value) => {
    setPage(value);
    updatePagination(value);
  };

  const getGroupReservationsList = async (filter_obj) => {
    setOpenLoader(true);
    const { res, err } = ReservationsPageListener
      ? await getReservationsList(filter_obj)
      : await getGroupReservationsListData(id, filter_obj);
    if (res) {
      setReservationsData(res?.data);
    }
    if (err) {
    }
    setOpenLoader(false);
  };

  const handelConfirmReservation = async () => {
    const { res, err } = await updatePaymentStatus(
      selectedData?.activityItem?.id,
      {
        payment_transaction: {
          status: "confirmed",
        },
      }
    );
    if (res) {
      setOpenConfirmReservations(false);
      getGroupReservationsList(filterObj);
    }
  };
  const handelConfirmPayment = async () => {
    const { res, err } = await updatePaymentStatus(
      selectedData?.activityItem?.id,
      {
        payment_transaction: {
          status: "payment_success",
        },
      }
    );
    if (res) {
      setOpenConfirmPayment(false);
      getGroupReservationsList(filterObj);
    }
  };
  const handelRejectReservations = async () => {
    const { res, err } = await updatePaymentStatus(
      selectedData?.activityItem?.id,
      {
        payment_transaction: {
          status: "rejected",
        },
      }
    );
    if (res) {
      setOpenRejectReservations(false);
      getGroupReservationsList(filterObj);
    }
  };
  const handelActivityCompleted = async () => {
    setCompleteActivityLoading(true)
    const { res, err } = await updatePaymentStatus(
      selectedData?.activityItem?.id,
      {
        payment_transaction: {
          status: "completed",
        },
      }
    );
    if (res) {
      setCompleteActivityLoading(false)
      setOpenCompleteActivity(false);
      getGroupReservationsList(filterObj);
    }
    setCompleteActivityLoading(false)
  };
  console.log(completeActivityLoading,'completeActivityLoading');
  const handelCancelReservation = async () => {
    const { res, err } = await updatePaymentStatus(
      selectedData?.activityItem?.id,
      {
        payment_transaction: {
          status: "cancelled",
        },
      }
    );
    if (res) {
      setOpenCancelReservations(false);
      getGroupReservationsList(filterObj);
    }
  };

  useEffect(() => {
    if (list) setListData(list);
  }, [list]);

  return (
    <Grid container  >
      <Grid container className="table-header mb-20px">
        <Grid md={0.5} textAlign="center">
          {t("reservations.id")}
        </Grid>
        <Grid md={1.3}>{t("reservations.reservationDate")}</Grid>
        <Grid sm={1}>{t("reservations.refNumber")}</Grid>
        <Grid sm={1.5}>{t("reservations.customerName")}</Grid>
        <Grid sm={0.7}>{t("reservations.seats")}</Grid>
        <Grid sm={1}>{t("reservations.totalPrice")}</Grid>
        <Grid sm={1}>{t("reservations.payment")}</Grid>
        <Grid sm={1}>{t("activity.paymentMethod")}</Grid>
        <Grid sm={1.5}>{t("reservations.paymentDateTime")}</Grid>
        <Grid sm={1.5}>{t("reservations.status")}</Grid>
        <Grid sm={1} paddingLeft={'20px'}>{t("reservations.action")}</Grid>
      </Grid>
      {list?.length === 0 && (
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "200px",
          }}
        >
          <img
            src={icons.NoReservations}
            alt="no-branches"
            style={{ display: "block", width: "125.8px", height: "124.9px" }}
          />
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: "bold",
              color: "#333132",
              mt: "25px",
            }}
          >
            {t("offer.thereAreNoRecords")}
          </Typography>
        </Grid>
      )}
      {listData?.map((activityItem, index) => {
        return (
          <Box
            key={index}
            width="100%"
            onClick={() =>
              setSelectedData({ activityItem: activityItem, index: index })
            }
          >
            {ReservationsPageListener ? (
              <ReservationsPageList
                setSelectedData={setSelectedData}
                activityItem={activityItem}
                setOpenConfirmPayment={setOpenConfirmPayment}
                setOpenRejectReservations={setOpenRejectReservations}
                setOpenCancelReservations={setOpenCancelReservations}
                setOpenCompleteActivity={setOpenCompleteActivity}
                setOpenConfirmReservations={setOpenConfirmReservations}
                setOpenReservationDetails={setOpenReservationDetails}
                ordersPrivilages={ordersPrivilages}
                reservationsDuration={reservationsDuration}
              />
            ) : (
              <OrdersList
                setSelectedData={setSelectedData}
                activityItem={activityItem}
                setOpenConfirmPayment={setOpenConfirmPayment}
                setOpenRejectReservations={setOpenRejectReservations}
                setOpenCancelReservations={setOpenCancelReservations}
                setOpenCompleteActivity={setOpenCompleteActivity}
                setOpenConfirmReservations={setOpenConfirmReservations}
                setOpenReservationDetails={setOpenReservationDetails}
                ordersPrivilages={ordersPrivilages}
                reservationsDuration={reservationsDuration}
              />
            )}
          </Box>
        );
      })}
      {count > 10 && (
        <Grid container justifyContent="center" mb={7.5} mt={5}>
          <CustomPagination
            count={Math.ceil(count / 10)}
            size="large"
            shape="rounded"
            page={page}
            handleChange={handlePageChange}
            className="pagination"
          />
        </Grid>
      )}
      {openConfirmReservations && (
        <ConfirmReservationModel
          open={openConfirmReservations}
          setOpen={setOpenConfirmReservations}
          handelConfirmReservation={handelConfirmReservation}
        />
      )}
      {openConfirmPayment && (
        <ConfirmPaymentModel
          open={openConfirmPayment}
          setOpen={setOpenConfirmPayment}
          handelConfirmPayment={handelConfirmPayment}
        />
      )}

      {openCancelReservations && (
        <CancelReservationsModel
          open={openCancelReservations}
          setOpen={setOpenCancelReservations}
          handelCancelReservations={handelCancelReservation}
        />
      )}
      {openRejectReservations && (
        <RejectReservationsModel
          open={openRejectReservations}
          setOpen={setOpenRejectReservations}
          handelRejectReservations={handelRejectReservations}
        />
      )}
      {openCompleteActivity && (
        <ActivityCompletedModel
          open={openCompleteActivity}
          setOpen={setOpenCompleteActivity}
          handelActivityCompleted={handelActivityCompleted}
          isLoading={completeActivityLoading}

        />
      )}

      {openReservationDetails && (
        <ReservationDetailsModel
          open={openReservationDetails}
          setOpen={setOpenReservationDetails}
          orderId={selectedData?.activityItem?.id}
          customerData={selectedData?.activityItem?.user_info}
        />
      )}
    </Grid>
  );
};

export default withTranslation()(DetailsList);
